import { siteTokens } from "../../config/config";
import userconfigs from "../../helpers/closures";

export const errorCheck = (error) => {
  return error?.response?.data?.error || "Network error"
}

export function handleResponse(response) {
  if (response.status !== 200) {
    const error = (response && response.message) || response.statusText;
    return Promise.reject(error);
  }
  return response.data.result;
  //});
}


export function handleJWTError() {
  siteTokens.map(site => {
    const currentHostName = window.location.hostname;
    if (currentHostName === site.url) {
      userconfigs.removeItem("jwt");
      userconfigs.removeItem(site.token);
      //console.log(site.token)
      window.location.reload();
    }
    return true;
  })
}