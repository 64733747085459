export const roleConstants = {
   

    ROLE_ADD_REQUEST: 'ROLE_ADD_REQUEST',
    ROLE_ADD_SUCCESS: 'ROLE_ADD_SUCCESS',
    ROLE_ADD_FAILURE: 'ROLE_ADD_FAILURE',

    ROLE_EDIT_REQUEST: 'ROLE_EDIT_REQUEST',
    ROLE_EDIT_SUCCESS: 'ROLE_EDIT_SUCCESS',
    ROLE_EDIT_FAILURE: 'ROLE_EDIT_FAILURE',

    ROLE_GETALL_REQUEST: 'ROLE_GETALL_REQUEST',
    ROLE_GETALL_SUCCESS: 'ROLE_GETALL_SUCCESS',
    ROLE_GETALL_FAILURE: 'ROLE_GETALL_FAILURE',

    ROLE_BYID_REQUEST: 'ROLE_BYID_REQUEST',
    ROLE_BYID_SUCCESS: 'ROLE_BYID_SUCCESS',
    ROLE_BYID_FAILURE: 'ROLE_BYID_FAILURE',

};