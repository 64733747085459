import React, { Component } from 'react';

import ReactCodeInput from 'react-code-input';

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      otp_value: null
    };
  }
  passOtp = () => {
    this.props.handleOtp(this.state.otp_value);
  }
  render() {
    return (
      <ReactCodeInput type='number' onChange={otp => this.props.handleOtp({otp})} fields={6} />
    )
  }
}