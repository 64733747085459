// get string for today, format: xxxx-xx-xx
export const getTodayString = (timestamp=false) => {
    const datetime = new Date();
    if (timestamp) return datetime.toISOString();
    const pad = (n) => (n < 10 ? "0"+n : n);
    return datetime.getFullYear() + "-" + pad(datetime.getMonth() + 1) + "-" + pad(datetime.getDate());
};

// convert json to string for Elastic Search
export const jsonToERP = (json, index, id) => {
    let result = "";
    try {
        let str = JSON.stringify(json);
        result = `{"index":{"_index":"${index}","_id":"${id}"}}\n${str}\n`;
    }
    catch(e) {
        console.warn("[jsonToERP]", e);
    }
    return result;
};

export const validateDateTime = (data) => {
    let dateRegexp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/
    dateRegexp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d+Z$/
    return dateRegexp.test(data);
    // return Date.parse(data);
};

export const formatDate = (data) => {
    let dateRegexp = /^\d{4}-\d{2}-\d{2}$/
    if (dateRegexp.test(data)) {
        const date = new Date(data);
        date.setTime(date.getTime() + date.getTimezoneOffset()*60*1000);
        return date.toDateString().substr(4);
    }
    dateRegexp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d+Z$|^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/
    if (dateRegexp.test(data)) {
        const date = new Date(data);
        return date.toDateString().substr(4);
    }
    return data;
};

export const formatDateTime = (data) => {
    if (validateDateTime(data)) {
        const date = new Date(data);
        return date.toString().substr(4, 20);
    }
    return data;
};

// sort for contracts by created_at
export const sortContracts = (contracts, sortKey, getValue) => {
    if (typeof contracts === "object" && contracts.length) {
        if (contracts[0].payload) {
            if (typeof getValue !== 'function') getValue = (data, key) => data[key];
            const val = getValue(contracts[0].payload, sortKey, {isString: true});
            let getVal = getValue;
            if (validateDateTime(val)) getVal = (data, key) => (new Date(getValue(data, key, {isString: true})));
            // console.log("[sortContracts]", val, validateDateTime(val), getVal(contracts[0].payload, sortKey));
            return contracts.sort((a, b) => (getVal(b.payload, sortKey) - getVal(a.payload, sortKey)));
        }
        return contracts;
    }
    return [];
};

// get template from contract
export const getTemplate = (contract) => (
    (typeof contract.templateId === 'string') ? (
        contract.templateId.split(':')[contract.templateId.split(':').length-1]
    ) : ""
);

// get list from payload of response by Template
export const getTemplateListFromResponse = (res, template) => {
    let result = [], contractIds = [];
    res.forEach(rr => {
        if (!!rr && typeof rr === 'object' && rr.length) rr.forEach(r => {
            if ('created' in r && getTemplate(r['created']) === template) {
                result.push({ ...r['created']['payload'], contractId: r['created']['contractId'] });
                contractIds.push(r['created']['contractId']);
            }
            else if ('archived' in r && getTemplate(r['archived']) === template) {
                const index = contractIds.indexOf(r['archived']['contractId']);
                if (index >= 0) {
                    result.splice(index, 1);
                    contractIds.splice(index, 1);
                }
            }
        });
    });
    return result;
};

/**
 * get UDI, Expiration Date, Lotcode from BarCode
 * e.g. (01)0801741148767(17)251212(10)EADT7 => {udi: "0801741148767", expiration: "2025-12-12", lotcode: "EADT7"}
 * e.g. 010761332702693117231028100079555D =>   {udi: "7613327026931", expiration: "2023-10-28", lotcode: "0079555D"}
 * e.g. 01076131530715941726021710T226341AC =>  {udi: "7613153071594", expiration: "2026-02-17", lotcode: "T226341AC"}
 * @param {String} code 
 */
export const getDataFromCode = (code) => {
    let udi = '', expiration = '', lotcode = '', found;
    if (typeof code === 'string') {
        let regex = /\([\d]+\)([\w]+)/gm;
        for (let i = 0; i < 3; i ++) {
            found = regex.exec(code);
            if (!found) break;
            if (i === 0) udi = found[1];
            else if (i === 1 && found[1].length >= 6) 
                expiration = `20${found[1].substr(0,2)}-${found[1].substr(2,2)}-${found[1].substr(4,2)}`;
            else if (i === 2) lotcode = found[1];
        }
        if (udi === '' || expiration === '' || lotcode === '') {
            regex = /01[\d]*([\d]{13})17([\d]{6})10([\w]+)/gm;
            found = regex.exec(code);
            if (found && found.length > 3) {
                udi = found[1];
                expiration = `20${found[2].substr(0,2)}-${found[2].substr(2,2)}-${found[2].substr(4,2)}`;
                lotcode = found[3];
            }
        }
    }
    return {udi, expiration, lotcode};
};

/**
 * get query to search timestamp
 * @param {String} date 
 * @returns {Query}
 */
export const searchQueryTimestamp = (date) => ({
    "%lte": `${date}T23:59:59.999999Z`,
    "%gte": `${date}T00:00:00.000000Z`
});

/**
 * check if object is empty
 * @param {Object} obj 
 * @returns 
 */
export const isEmptyObject = (obj) => {
    return Object.keys(obj).every(k => {
        if (typeof obj[k] === 'string') return obj[k].length === 0;
        else if (typeof obj[k] === 'object') return isEmptyObject(obj[k]);
        return !obj[k];
    });
};
