import React, { Component } from 'react';
import { Button/* , Popover, PopoverBody */ } from 'reactstrap';
import { roleActions } from './../../../../Redux/_actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class ListContent extends Component {

    componentDidMount() {
        this.props.listRoles();
    }

    editRole(id) {
        this.props.history.push("/eoffice/Setting/edit-role/" + id);

    }

    render() {
        return (
            <>
                {this.props.roleList.map(role =>
                    <tr>
                        <td>
                            <div className="checkbox">
                                <input className="styled-checkbox" id="styled-checkbox-1" type="checkbox" value="value1" />
                                <label for="styled-checkbox-1"></label>
                            </div>
                        </td>
                        <td>{role.role_name}</td>
                        <td>{role.permission}</td>
                        <td className="nowrap">{role.isCustomPermission ? 'Yes' : 'No'}</td>
                        <td className="nowrap"><Button onClick={() => this.editRole(role._id)} className="btn-sm btn-round btn-edit"> <i className="fas fa-pencil-alt"></i> Edit</Button></td>
                    </tr>
                )}

            </>

        )
    }

}

const mapState = (state) => {
    const { roleList } = state.roles;

    return {
        roleList,
    };
}

const actionCreators = {
    listRoles: roleActions.listRoles
};

export default withRouter(connect(mapState, actionCreators)(ListContent));

