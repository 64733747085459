import React, { Component } from 'react';
import { Input, Form, FormGroup, Label, Button, Col, Row, CustomInput } from 'reactstrap';
import './CreateDepartment.scss';
import "react-datepicker/dist/react-datepicker.css";
import LeftPanelManager from '../../../components/LeftPanelManager/LeftPanelManager';
import validator from 'validator';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { departmentActions } from './../../../../Redux/_actions';
import { Multiselect } from 'multiselect-react-dropdown';



class CreateDepartment extends Component {

    constructor(props) {
        super(props);
        this.state = {
            deptForm: {
                department_name: '',
                contact_person_name: '',
                contact_person_email: '',
                contact_person_phone: '',
                dept_email_id: '',
                about: '',
                country_code: '+91',
                People: []
            },
            isdeptFormValid: false,
            isFormSubmitted: false,
            formError: {},
            dept_id: this.props.match.params.id || null,
            companyUsers: [],
            selectedPeaple: []
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
		if(this.props.isDepartmentAdded !== nextProps.isDepartmentAdded){
			this.props.history.push("/eoffice/Setting/department/list")
		}
		return true;
    }
    
    componentDidUpdate = async(prevProps, prevState) => {
        if (prevProps.departmanrData !== this.props.departmanrData) {
            const data = {...this.state.deptForm};
            const {department_name,contact_person_name, contact_person_email,contact_person_phone , dept_email_id, about, country_code, People} = this.props.departmanrData;
            data['department_name'] = department_name;
            data['contact_person_name'] = contact_person_name;
            data['contact_person_email'] = contact_person_email;
            data['contact_person_phone'] = contact_person_phone;
            data['dept_email_id'] = dept_email_id;
            data['about'] = about;
            data['country_code'] = country_code;
            const peopleObj = [];
            People.forEach(item => {
                let obj = {}
                obj.email = item;
                peopleObj.push(obj);
            });
            data['People'] = People;

            await this.setState({...this.state.deptForm, deptForm: data})
            await this.setState({...this.state, selectedPeaple: peopleObj})
        }

    }
    async handleUserInput(e) {
        const name = e.target.name;
        const value = e.target.value;
        const deptData = { ...this.state.deptForm };
        deptData[name] = value;
        await this.setState({ ...this.state.deptForm, deptForm: deptData });
        await this.vallidateForm();
    }

    componentDidMount() {
        this.props.getCompanyUsers();
        if(this.state.dept_id !== null){
            this.props.getDepartmentByid(this.state.dept_id)
        }
    }

    onSelect = (selectedList, selectedItem) => {
        const deptData = { ...this.state.deptForm };
        deptData['People'].push(selectedItem.email);
        this.setState({ ...this.state.deptForm, deptForm: deptData });
    }

    onRemove = async (selectedList, removedItem) =>{
        const deptData = { ...this.state.deptForm };
        const data = deptData['People'].filter(ele => ele!==removedItem.email);
        deptData['People'] = data;
        await this.setState({ ...this.state.deptForm, deptForm: deptData });
    }

    async submitDeptForm(event) {
        event.preventDefault();
        await this.setState({ isFormSubmitted: true });
        await this.vallidateForm();
        if (this.state.isdeptFormValid && this.state.isFormSubmitted) {
            if(this.state.dept_id === null){
                this.props.addDepartment(this.state.deptForm); 
            }else{
                this.props.editDepartment(this.state.deptForm, this.state.dept_id );
            }
        }
    }

    async vallidateForm() {
        if (this.state.isFormSubmitted) {
            let errorObj = {};
            const formData = this.state.deptForm;
            if (formData.department_name === '') {
                errorObj.department_name = 'Please enter depaerment name';
            }
            // if (formData.contact_person_name === '') {
            //     errorObj.contact_person_name = 'Please select contact person';
            // }
            if (formData.dept_email_id === '') {
                errorObj.dept_email_id = 'Please enter department email';
            }
            if (formData.dept_email_id !== '' && !validator.isEmail(formData.dept_email_id)) {
                errorObj.dept_email_id = 'Please enter valid email';
            }
            if (formData.about === '') {
                errorObj.about = 'Please enter about';
            }
            let formValid;
            if (Object.keys(errorObj).length === 0) {
                formValid = true;
            } else {
                formValid = false;
            }

            await this.setState({ formError: errorObj, isdeptFormValid: formValid });
        }
    }

    async changeContactPerson(e) {
        const value = e.target.value;
        const teamData = { ...this.state.deptForm };
        if (value !== '') {
            const selectedEmp = this.props.companyUsers.find(emp => emp.email === value);
            const teamData = { ...this.state.deptForm };
            teamData['contact_person_name'] = selectedEmp.name;
            teamData['contact_person_email'] = selectedEmp.email;
            teamData['contact_person_phone'] = selectedEmp.mobile;
            await this.setState({ ...this.state.deptForm, deptForm: teamData });
            await this.vallidateForm();
        }else{
            teamData['contact_person_name'] = '';
            teamData['contact_person_email'] = '';
            teamData['contact_person_phone'] = '';
            await this.setState({ ...this.state.deptForm, deptForm: teamData });
            await this.vallidateForm();
        }
      

    }

    render() {
        return (
            <div className="createDepart-container">
                <LeftPanelManager></LeftPanelManager>
                <div className="pageContentpanel">
                    <div className="createDepart-FormSection">
                        <h2 className="title">{this.state.dept_id === null ? 'Create' : 'Update'} Departments</h2>
                        <p className="sub-title">You could be a business, an event organizer, or a professional community. Please fill below detail.</p>
                        <Row className="formRow">
                            <Col sm="6">
                                <Form onSubmit={(event) => this.submitDeptForm(event)}>
                                    <FormGroup className="form-col">
                                        <Label className="title-label">Department Name </Label>
                                        <Input type="text" value={this.state.deptForm.department_name} onChange={(e) => this.handleUserInput(e)} name="department_name" placeholder="Describe Department Name" />

                                    </FormGroup>
                                    {this.state.isFormSubmitted && this.state.formError.hasOwnProperty('department_name') && <div className="error text-danger" >{this.state.formError.department_name}</div>}

                                    <FormGroup className="form-col">
                                        <Label className="title-label">Contact Person</Label>
                                        <CustomInput type="select" value={this.state.deptForm.contact_person_email} onChange={(e) => this.changeContactPerson(e)} name="contact_person_name" >
                                            <option value="">Select Contact Person</option>
                                            {this.props.companyUsers.map((mem, index) => (
                                                <option value={mem.email} key={index}>{mem.name} ({mem.email})</option>
                                            ))}
                                        </CustomInput>
                                    </FormGroup>
                                    {/* {this.state.isFormSubmitted && this.state.formError.hasOwnProperty('contact_person_name') && <div className="error text-danger" >{this.state.formError.contact_person_name}</div>} */}
                                    {/* <Row>
                                        <Col sm="6" style={{ paddingRight: '8px' }}>
                                            <FormGroup className="form-col">
                                                <Label className="title-label">Contact Person's Email</Label>
                                                <Input type="text" onChange={(e) => this.handleUserInput(e)} name="contact_person_email" placeholder="Enter Email Address" />
                                            </FormGroup>
                                            {this.state.isFormSubmitted && this.state.formError.hasOwnProperty('contact_person_email') && <div className="error text-danger" >{this.state.formError.contact_person_email}</div>}
                                        </Col>

                                        <Col sm="6" style={{ paddingLeft: '8px' }}>
                                            <FormGroup className="form-col">
                                                <Label className="title-label">Contact Person Phone Number</Label>
                                                <Input type="number" onChange={(e) => this.handleUserInput(e)} name="contact_person_phone" placeholder="Enter Phone Number" />
                                            </FormGroup>
                                            {this.state.isFormSubmitted && this.state.formError.hasOwnProperty('contact_person_phone') && <div className="error text-danger" >{this.state.formError.contact_person_phone}</div>}
                                        </Col>
                                    </Row> */}

                                    <FormGroup className="form-col">
                                        <Label className="title-label">Department Email ID</Label>
                                        <Input type="email" value={this.state.deptForm.dept_email_id} onChange={(e) => this.handleUserInput(e)} name="dept_email_id" placeholder="Enter Department Email Address" />
                                    </FormGroup>
                                    {this.state.isFormSubmitted && this.state.formError.hasOwnProperty('dept_email_id') && <div className="error text-danger" >{this.state.formError.dept_email_id}</div>}

                                    <FormGroup className="form-col">
                                        <Label className="title-label">About</Label>
                                        <Input type="textarea" value={this.state.deptForm.about}  onChange={(e) => this.handleUserInput(e)} name="about" className="form-control-textarea" placeholder="Enter About Company detail" />
                                    </FormGroup>
                                    {this.state.isFormSubmitted && this.state.formError.hasOwnProperty('about') && <div className="error text-danger" >{this.state.formError.about}</div>}

                                    <FormGroup className="form-col">
                                        <Label className="title-label"><div className=" circle-add rounded-circle bg-success"><i className="fas fa-plus"></i></div> Add People</Label>
                                        <Multiselect
                                            selectedValues={this.state.selectedPeaple}
                                            options={this.props.companyUsers} // Options to display in the dropdown
                                            onSelect={this.onSelect} // Function will trigger on select event
                                            onRemove={this.onRemove} // Function will trigger on remove event
                                            displayValue="email" // Property name to display in the dropdown options
                                        />
                                    </FormGroup>

                                    <Col sm="12" className="text-center mt-5">
                                        <Button className="btn-theme-primary" type="submit">{this.state.dept_id === null ? 'Create' : 'Update'}</Button>
                                    </Col>

                                </Form>
                            </Col>
                            <Col sm="6" className="img-section">
                                <img src="../images/eoffice/team-group-img.svg" className="img-fluid" alt=""  ></img>

                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        )
    }

}

const mapState = (state) => {
    const { teamData } = state.teamsInfo;
    const { departmentList, companyUsers, isDepartmentAdded, departmanrData } = state.department;

    return {
        teamData,
        departmentList,
        companyUsers,
        isDepartmentAdded,
        departmanrData
    };
}

const actionCreators = {
    addDepartment: departmentActions.addDepartment,
    getCompanyUsers: departmentActions.getCompanyUsers,
    getDepartmentByid: departmentActions.getDepartmentByid,
    editDepartment: departmentActions.editDepartment
};

export default withRouter(connect(mapState, actionCreators)(CreateDepartment));

