import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { userSignUpActions } from '../../Redux/_actions'

import { Spinner } from 'reactstrap';

class VerifySignup extends Component {

  constructor(props) {
    super(props);
    this.state = {
      token: this.props.match.params.token,
      msg: "Please wait, we are verifing your account..."
    }
  }

  componentDidMount = () => {
    const { token } = this.state;
    if (token !== '') {
      this.props.verifyAccount(token)
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.verifyLoading !== this.props.verifyLoading) {
      this.setState({
        verified: this.props.verified,
        msg: this.props.msg
      })
    }
  }

  render() {
    const { msg } = this.state;
    const { verifyLoading, verified } = this.props;
    return (
      <div className="wave_wating_sctn">
        <div className="wave_waiting_contianer_msg">
          <div className="wait_msg">
            Verification Status: {verifyLoading ? <Spinner /> : verified ? <i className="fa text-success fa-check ml-2"></i> : <i className="fa text-danger fa-times ml-2"></i>}
            <span className="text-warning mt-2">{msg}</span>
          </div>

          <div className="wave_wait_timer text-center mt-5">
            <Link to="/login" className="btn btn-primary veridy_sign_btn">Sign In</Link>
          </div>
        </div>
      </div>
    )
  }
}

const mapState = (state) => {
  const { verifyLoading, verified, msg } = state.authRegistration;
  return {
    verifyLoading,
    verified,
    msg
  }
}

const actionCreators = {
  verifyAccount: userSignUpActions.verifyAccount
};


export default withRouter(connect(mapState, actionCreators)(VerifySignup));