/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import {  Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
// import { AiOutlineCloudUpload, AiFillDelete } from "react-icons/ai";
import { Formik } from "formik";
import * as yup from "yup";
import isEmpty from "is-empty";
// import { MdEdit } from "react-icons/md";

import Layout from "../../layoutSection";
import { webinarActions } from "../../Redux/_actions";
// import { InputFiled } from "../../Common/authFile";
import { confirmAlert } from "react-confirm-alert";
import ConfirmationPopup from "../../components/UI/ConfirmationPopup/confirmationPopup";
// import Spinner from "../../components/UI/Spinner/Spinner";
import BrandingForm from "./components/BrandingForm";

const validation = yup.object().shape({
  name: yup.string().required("please Enter the name!"),
});

const Branding = () => {
  const { webinarDetail, brandDeatail } = useSelector(
    (state) => state.webinar
  );
  const param = useParams();
  const webId = param?.id;
  const [initialValues, setInitialValue] = useState({
    name: brandDeatail?.brand_name || "",
    logo: "",
    banner: "",
  });
  const [edit, setEdit] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (webId && (!webinarDetail || webinarDetail?.meetingID !== webId)) {
      dispatch(webinarActions.webinarDetail(webId));
    }
  }, [dispatch, param.id, webinarDetail]);

  useEffect(() => {
    if (webId) {
      dispatch(webinarActions.webinarBrand(param.id));
    }
  }, [dispatch, param.id]);

  useMemo(() => {
    if (isEmpty(brandDeatail?.brand_name)) {
      setEdit(true);
    } else {
      setEdit(false);
    }
  }, [brandDeatail]);

  useMemo(() => {
    if (brandDeatail) {
      setInitialValue({ ...initialValues, name: brandDeatail?.brand_name });
    } else {
      setInitialValue({ ...initialValues, name: "" });
    }
  }, [brandDeatail]);

  const submitBrandingForm = (type, fieldValue) => {
    let fieldType = null;
    switch (type) {
      case "name": {
        fieldType = "brand_name";
        break;
      }
      case "logo": {
        fieldType = "brand_logo";
        break;
      }
      case "banner": {
        fieldType = "brand_banner";
        break;
      }
      default: {
        fieldType = "brand_name";
      }
    }

    if (fieldType === "brand_name") {
      let formData = {};
      formData.webinarID = webId;

      formData[fieldType] = fieldValue;
      dispatch(webinarActions.addBranding("text", formData));
    } else if (fieldType === "brand_logo" || fieldType === "brand_banner") {
      let fieldSetValue = fieldValue || null;
      let formImageData = new FormData();
      // formImageData.webinarID = webId;
      // formImageData.fieldType = fieldSetValue;
      formImageData.append("webinarID", webId);
      formImageData.append(fieldType, fieldSetValue);
      dispatch(webinarActions.addBranding("images", formImageData));
    }
  };

  const uploadData = (type, value, setFieldValue, setFieldError) => {
    let rules = { maxWidth: 400, minWidth: 200 };
    if (type === "banner") {
      rules = { maxWidth: 1100, minWidth: 600 };
    }

    let img = new Image();
    var imgwidth = 0;
    var _URL = window.URL || window.webkitURL;
    img.src = _URL.createObjectURL(value);
    img.onload = function () {
      imgwidth = this.width;
      if (value.size > 1048576) {
        setFieldError(type, "Upload image size be less then 1 MB.");
      } else if (
        parseInt(imgwidth) > rules.maxWidth ||
        parseInt(imgwidth) < rules.minWidth
      ) {
        setFieldError(
          type,
          "Upload image width should " +
            rules.minWidth +
            "px to " +
            rules.maxWidth +
            "px"
        );
      } else {
        submitBrandingForm(type, value);
        setFieldValue(type, value);
      }
    };
  };

  const deleteWebinarBrandConfirm = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmationPopup
            message={"Are you sure want to delete this webinar brand ?"}
            noBtnAction={onClose}
            noBtnText="No"
            yesBtnText="Yes, Delete AnyWay!"
            yesBtnAction={() =>
              dispatch(webinarActions.deletewebinarBrand(webId))
            }
          />
        );
      },
    });
  };

  return (
    <Layout
      title={webinarDetail?.name || "Branding"}
      goBack="/webinars"
      list="webinar"
      active="Branding"
    >
      <div className="container-fluid s_wave_content_part branding">
        <div className="sideBar mt-5 p-4 borderRadius d-flex">
          <Col lg={12}>
            <div className="row mb-4">
              <Col lg={2}>
                <div className="text-left">
                  {/* {edit ? (
                    <button
                      className="btnStyles  submitSubColor welcomeMsg mt-3"
                      onClick={() => handleSubmit()}
                    >
                      Save
                    </button>
                  ) : (
                    <button
                      className="btnStyles bg-warning welcomeMsg"
                      onClick={() => setEdit(true)}
                    >
                      <MdEdit /> Edit
                    </button>
                  )} */}
                </div>
              </Col>
              {/* <Col lg={4}>
                <div className="between text-left">
                  {!isEmpty(brandDeatail?.brand_name) && (
                    <Button
                      className="headerBtn btnCenter"
                      variant="danger"
                      onClick={() => deleteWebinarBrandConfirm()}
                    >
                      <AiFillDelete className="f_size_20 mr-1" /> Delete
                    </Button>
                  )}
                </div>
              </Col> */}
            </div>

            <Formik
              initialValues={initialValues}
              onSubmit={(value) => submitBrandingForm("name", value.name)}
              validationSchema={validation}
              enableReinitialize
            >
              <BrandingForm />
              {/* {({
                values,
                setFieldValue,
                handleSubmit,
                setFieldError,
                errors,
                touched,
              }) =>
                brandLoading ? (
                  <div className="text-center">
                    <Spinner />
                  </div>
                ) : (
                  <>
                    <div className="row">
                      <Col lg={4}>
                        <InputFiled
                          name="name"
                          label="Name"
                          placeholder="Enter the brand name"
                          labelClassName="f_size_16 text-muted"
                          containerClassName="w-100"
                          disabled={!edit}
                          // className={` inputBrandName welcomeMsg ${edit ? 'bgColorBodyCard' : 'bg-transparent f_size_26'} `}
                          className={` inputBrandName welcomeMsg bgColorBodyCard `}
                        />
                      </Col>
                      <Col lg={3}></Col>
                      <Col lg={3}>
                        <Form.Group className="mb-2 welcomeMsg">
                          <Form.Label column sm="2" className=" text-muted">
                            Logo
                          </Form.Label>
                          <Form.Control
                            type="file"
                            className="uploadInput"
                            id="brandLogo"
                            onChange={(event) =>
                              uploadData(
                                "logo",
                                event.currentTarget.files[0],
                                setFieldValue,
                                setFieldError
                              )
                            }
                          />
                          <label
                            className={`uploadLogo bgColorBodyCard  f_size_15 ${
                              values?.logo ? "activeSection" : ""
                            } ${errors?.logo ? "errorSection" : ""} `}
                            htmlFor="brandLogo"
                          >
                            <AiOutlineCloudUpload className="f_size_32 mr-2" />
                            Upload Logo
                          </label>
                        </Form.Group>
                        <small className="text-muted ml-3">
                          {" "}
                          Image width should be 200px to 400px{" "}
                        </small>
                        {errors.logo && (
                          <div className="text-danger f_size_11 ml-3">
                            {" "}
                            {errors.logo}{" "}
                          </div>
                        )}
                      </Col>
                    </div>

                    <Row className="mt-4">
                      <Col lg={10}>
                        <Form.Group className="mb-2 welcomeMsg">
                          <Form.Label column sm="2" className=" text-muted">
                            Banner
                          </Form.Label>
                          <Form.Control
                            type="file"
                            className="uploadInput"
                            id="brandBanner"
                            onChange={(event) =>
                              uploadData(
                                "banner",
                                event.currentTarget.files[0],
                                setFieldValue,
                                setFieldError
                              )
                            }
                          />
                          <label
                            className={`uploadLogo bgColorBodyCard f_size_15 ${
                              values?.banner ? "activeSection" : ""
                            } ${errors?.banner ? "errorSection" : ""}`}
                            htmlFor="brandBanner"
                          >
                            <AiOutlineCloudUpload className="f_size_32 mr-2" />
                            Upload Banner
                          </label>
                        </Form.Group>
                        <small className="text-muted ml-3">
                          {" "}
                          Image width should be 600px to 1100px
                        </small>
                        {errors.banner && (
                          <div className="text-danger f_size_11 ml-3">
                            {" "}
                            {errors.banner}{" "}
                          </div>
                        )}
                      </Col>
                    </Row>
                  </>
                )
              } */}
            </Formik>
          </Col>
        </div>
      </div>
    </Layout>
  );
};

export default Branding;
