import React, { Component } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import TalkContainer from './components/Talk/Talk';
import SideNavBar from './components/SideNavBar/SideNavBar';
import { userActions } from './../../../Redux/_actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import './style.scss';
import { geteOfficeModules } from './../../../helpers/helpers';
import userconfigs from './../../../helpers/closures';
import jwt_decode from "jwt-decode";

class LeftPanelManager extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activeTab: "1",
            leftOpenPanel: true,
            eOfficeModule: geteOfficeModules()
        }
    }

    toggle = (tab) => {
        const selectedTab = tab.toString();
        if (this.state.activeTab !== selectedTab) {
            this.setState({
                activeTab: selectedTab
            })
        }
    }

    getModuleAvtar(moduleName) {
        switch (true) {
            case moduleName === 'Deck':
                return '/images/icons/icon-user.svg';
            case moduleName === 'Talk':
                return '/images/icons/icon-chat.svg';
            case moduleName === 'Drive':
                return '/images/icons/icon-drive.svg';
            case moduleName === 'Calender':
                return '/images/icons/icon-calendar.svg';
            case moduleName === 'CRM':
                return '/images/icons/icon-task.svg';
            case moduleName === 'Task':
                return '/images/icons/icon-crm.svg';
            case moduleName === 'Setting':
                return '/images/icons/icon-setting.svg';
            case moduleName === 'Support':
                return '/images/icons/icon-support.svg';

        }
    }

    hidePanelHandler = () => {
        this.setState({
            leftOpenPanel: false
        })
    }

    showPanelHandler = () => {
        this.setState({
            leftOpenPanel: true
        })
    }

    async componentDidMount() {
        const uri = window.location.pathname.split('/');
        const activeIndex = this.state.eOfficeModule.findIndex(module => module.name === uri[2]);
        this.toggle(activeIndex + 1)
        let userInfo = jwt_decode(userconfigs.getItem('jwt'));
        console.log(userInfo)
        if (userInfo.hasOwnProperty('eOfficeModulePermission')) {
            await this.setState({ eOfficeModule: userInfo.eOfficeModulePermission });
        }
    }

    render() {
        const { activeTab, leftOpenPanel } = this.state;
        return (
            <div className={leftOpenPanel ? "leftPanelSection" : "leftPanelSection hideLeftPanel"}>
                <div className="leftPanelContainer">
                    <div className="leftnav-tabs">
                        <button className="btn-panelHandler btn-show" onClick={this.showPanelHandler}><i className="fas fa-angle-double-right"></i></button>
                        <button className="btn-panelHandler btn-hide" onClick={this.hidePanelHandler}><i className="fas fa-angle-double-left"></i></button>
                        <Nav tabs>
                            {this.state.eOfficeModule !== undefined && this.state.eOfficeModule.map((module, index) => {
                                return module.permission &&
                                    <NavItem key={index}>
                                        <NavLink
                                            className={classnames({ active: activeTab === index + 1 })}
                                            onClick={() => { this.toggle(index + 1); }}
                                        >
                                            <img src={this.getModuleAvtar(module.name)} alt="" /><br />
                                            <span>{module.name}</span>
                                        </NavLink>
                                    </NavItem>
                            }

                            )}
                            {/* <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === '1' })}
                                    onClick={() => { this.toggle('1'); }}
                                >
                                    <img src="../images/icons/icon-user.svg" alt="" />
                                    <span>Desk</span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === '2' })}
                                    onClick={() => { this.toggle('2'); }}
                                >
                                    <img src="../images/icons/icon-chat.svg" alt="" /><br />
                                    <span>Talk</span>
                                </NavLink>
                            </NavItem>

                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === '3' })}
                                    onClick={() => { this.toggle('3'); }}
                                >
                                    <img src="../images/icons/icon-drive.svg" alt="" /><br />
                                    <span>Drive</span>
                                </NavLink>
                            </NavItem>

                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === '4' })}
                                    onClick={() => { this.toggle('4'); }}
                                >
                                    <img src="../images/icons/icon-calendar.svg" alt="" /><br />
                                    <span>Calendar</span>
                                </NavLink>
                            </NavItem>

                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === '5' })}
                                    onClick={() => { this.toggle('5'); }}
                                >
                                    <img src="../images/icons/icon-task.svg" alt="" /><br />
                                    <span>Task</span>
                                </NavLink>
                            </NavItem>

                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === '6' })}
                                    onClick={() => { this.toggle('6'); }}
                                >
                                    <img src="../images/icons/icon-crm.svg" alt="" /><br />
                                    <span>CRM</span>
                                </NavLink>
                            </NavItem>

                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === '7' })}
                                    onClick={() => { this.toggle('7'); }}
                                >
                                    <img src="../images/icons/icon-setting.svg" alt="" /><br />
                                    <span>Setting</span>
                                </NavLink>
                            </NavItem>

                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === '8' })}
                                    onClick={() => { this.toggle('8'); }}
                                >
                                    <img src="../images/icons/icon-support.svg" alt="" /><br />
                                    <span>Support</span>
                                </NavLink>
                            </NavItem> */}

                        </Nav>
                    </div>
                    {leftOpenPanel ?
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="1">
                                <Row>
                                    <Col sm="12">
                                        <h4 className="text-white">Deck Contents here</h4>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="2">
                                <Row>
                                    <Col sm="12">
                                        <TalkContainer />
                                    </Col>

                                </Row>
                            </TabPane>
                            <TabPane tabId="3">
                                <Row>
                                    <Col sm="12">
                                        <h4 className="text-white">Drive Contents here</h4>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="4">
                                <Row>
                                    <Col sm="12">
                                        <h4 className="text-white">Calendra Contents here</h4>
                                    </Col>
                                </Row>
                            </TabPane>

                            <TabPane tabId="5">
                                <Row>
                                    <Col sm="12">
                                        <h4 className="text-white">Task Contents here</h4>
                                    </Col>
                                </Row>
                            </TabPane>

                            <TabPane tabId="6">
                                <Row>
                                    <Col sm="12">
                                        <h4 className="text-white">CRM Contents here</h4>
                                    </Col>
                                </Row>
                            </TabPane>

                            <TabPane tabId="7">
                                <Row>
                                    <Col sm="12">
                                        <SideNavBar activeIndex={(index) => this.toggle(index)}></SideNavBar>
                                    </Col>
                                </Row>
                            </TabPane>

                            <TabPane tabId="8">
                                <Row>
                                    <Col sm="12">
                                        <h4 className="text-white">Support Contents here</h4>
                                    </Col>
                                </Row>
                            </TabPane>
                        </TabContent>

                        :
                        ""
                    }
                </div>
            </div>
        )
    }
}

const mapState = (state) => {
    const { eOfficeAccessibility } = state.authentication;

    return {
        eOfficeAccessibility,
    };
}

const actionCreators = {
    getAccessibility: userActions.getAccessibility
};

export default withRouter(connect(mapState, actionCreators)(LeftPanelManager));

