import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { UncontrolledTooltip, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import Avatar from 'react-avatar';
import {shortContent} from '../../../../helpers/helpers';

import { webinarRoomActions, webinarActions } from '../../../../Redux/_actions';
import LoadingSpinner from '../../../../components/UI/Spinner/Spinner';
import {SSE} from '../../../../helpers/sse'
import userconfigs from '../../../../helpers/closures';
import {getServerURL} from '../../../../helpers/helpers';
const currentURL = getServerURL();
let attendeesInterval = null;
let isAttendeeRuning = false;
let source = null;

let moderatorSSEStart = true;

class Attendees extends Component {

    constructor(props){
        super(props);
        this.state = {
            activeTab : this.props.activeTab,
            webinarInfo:this.props.webinarInfo,
            webinarID : this.props.webinarID,
            token : this.props.token,
            searchText : null,
            attendeesArr : [],
            showLoader : true,
            isRuning : false
        }
    }

    componentDidMount=()=>{
      if(this.props.activeTab === '0'){
        this.executeSSEProcess(this.props.webinarID)
      }
    }


    componentDidUpdate(prevProps, prevState){


      if(prevProps.attendeesList !== this.props.attendeesList){
        this.setState({'showLoader' : false});
      }

      if(prevProps.webinarInfo !== this.props.webinarInfo){
        this.setState({
          webinarInfo:this.props.webinarInfo,
          webinarID:this.props.webinarID,
          token:this.props.token
        })
      }

      if(prevProps.webinarID !== this.props.webinarID){
        this.props.attendeesListFun(this.props.webinarID)
      }


      if(prevProps.permissionActionState !== this.props.permissionActionState){
        this.props.attendeesListFun(this.props.webinarID)
      }

      //console.log('innnnnnnnnnnnnnnnnnnn attendeeeeeeeeee', this.props.webinarInfo.user_type, prevProps.activeTab, this.props.activeTab)

      if(prevProps.activeTab !== this.props.activeTab){

        //console.log("isAttendeeRuning",isAttendeeRuning);
        //console.log("this.props.activeTab",this.props.activeTab);

        if(this.props.activeTab==="2"){
          if(isAttendeeRuning === false){
            isAttendeeRuning = true;

            // this.props.attendeesListFun(this.props.webinarID)
            // attendeesInterval = setInterval(()=>{
            //     this.props.attendeesListFun(this.props.webinarID)
            // }, 5000) 

            this.executeSSEProcess(this.props.webinarID);
          }
        }
        else
        {
          if(source!==null){
            source.close();
          }
          isAttendeeRuning = false;
          clearInterval(attendeesInterval)
        }
      }
      else if(this.props.activeTab==='0' && moderatorSSEStart === true){
        moderatorSSEStart = false;
        //console.log(this.props.webinarID)
        //this.executeSSEProcess(this.props.webinarID)
      }
    }

    executeSSEProcess = (wid) =>{
      
      let self = this;
      let payload = [];
        

      self.setState({'attendeesArr' : [],'showLoader' : true});
      let url = `${currentURL.apiURL}webinar/attendeeListSSE`;
      let requestJson = { "wid" : wid };

      source = new SSE(url, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
          },
        payload: JSON.stringify(requestJson),
        method: 'POST'
      });

      source.addEventListener('message', function(e) {
        self.setState({'showLoader' : false});
        payload = JSON.parse(e.data);
        if(payload.length > 0){
          let attendeeListOldArr = self.state.attendeesArr;
          let updateAttendeeListObj = [...attendeeListOldArr, ...payload];
          self.setState({'attendeesArr' : payload});
        }
      });
      source.stream();
    }


    componentWillUnmount = () =>{
      isAttendeeRuning = false; 
      if(source!==null){
        source.close();
      }
    }

    onChangeHandler = (event) => {
      const filedName = event.target.name;
      const filedValue = event.target.value;

      if(filedName==="attendees_search"){
          if(filedValue===null || filedValue===""){
              this.setState({'attendeesArr' : [], 'searchText' : null})
          }else{
              if( (this.props.attendeesList).length > 0 ){
                let attendeesData =  this.props.attendeesList.filter( attendees =>  {

                  let lowerName =  attendees['name'].toLowerCase();
                  let getValue = filedValue.toLowerCase();

                  return lowerName.includes(getValue)
                });
                this.setState({'attendeesArr' : attendeesData, 'searchText' : filedValue})
              }else{
                this.setState({'attendeesArr' : [], 'searchText' : filedValue})
              }

          }
      }

    }



    attendeePermission = (userID, accessAction) => {
      accessAction = parseInt(accessAction);
      let webinarID = this.state.webinarID;

      this.props.permissionAttendeeFun(webinarID, userID, accessAction)
    }


    render(){

    const { attendeesListLoading, attendeesList } = this.props;
    const {searchText, attendeesArr, showLoader, webinarInfo} = this.state;

    let user_type = webinarInfo.user_type;
    let isMeetRunning = webinarInfo.isMeetRunning || false;


    // let attendeesArrList = [];
    // if(searchText!==null){
    //     attendeesArrList = attendeesArr;
    // }else{
    //     attendeesArrList = attendeesList;
    // }


    let attendeesData = null;
    if(showLoader){
      return(<LoadingSpinner />)
    }else
    if(attendeesArr.length > 0)
    {


      attendeesData = attendeesArr.map( attendees => {

        let mdValue = attendees.value ? attendees.value : null;
        let avatarIcon = (<Avatar name={attendees.name} size="60" round="5px"  />);
        if(mdValue){
          let gravatarImage = "http://www.gravatar.com/avatar/"+mdValue+"?d=404";
          avatarIcon = (<Avatar src={gravatarImage} name={attendees.name} size="60" round="5px" />)
        }
        let accessActionBit = attendees.allowed || 0;
        let attendeesID = attendees._id;
        let uniqueKey = "attendees_"+attendeesID;

        let userActive = attendees.allowed || 0;
        let handRaise = attendees.handRaise || 0;

        let blockClass = userActive > 0 ? "user-active" : "user-inactive" ;
        return(
              <li key={uniqueKey} className={blockClass}>
                <div className="attende_outer" > 
                    {avatarIcon}

                    { user_type==="moderator" ?
                    <UncontrolledDropdown className="attendee_action_btn">
                      <DropdownToggle color="primary">
                          <i className="fas fa-ellipsis-v"></i>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-right">
                        { accessActionBit===1 ?
                        <DropdownItem onClick={() => this.attendeePermission(attendeesID, 0)}><i className="fas fa-ban mr-1"></i> Block</DropdownItem> :
                        <DropdownItem onClick={() => this.attendeePermission(attendeesID, 1)}><i className="fas fa-user-check mr-1"></i> Allow</DropdownItem> }
                        <DropdownItem onClick={() => this.attendeePermission(attendeesID, -1)}><i className="fas fa-user-times mr-1"></i> Remove</DropdownItem>
                        {isMeetRunning ? <DropdownItem onClick={() => this.attendeePermission(attendeesID, 2)}><i className="fas fa-sign-in-alt mr-1"></i> Join in Conference</DropdownItem> : null}
                      </DropdownMenu>
                    </UncontrolledDropdown> : null }
                    
                    <div className="attendee_name" id={uniqueKey}>{shortContent(attendees.name, 8)}</div>
                    <UncontrolledTooltip placement="bottom" target={uniqueKey}>
                      {attendees.name}
                    </UncontrolledTooltip>
                    { user_type!=="attendee" && handRaise ?
                    <span className="attendeehand_rise">
                        <i className="far fa-hand-paper"></i>
                    </span> : null }

                    {  user_type!=="attendee" && accessActionBit === 3 ?
                    <span className="attendeehand_rise">
                        <i className="fas fa-chalkboard-teacher"></i>
                    </span> : null }

                </div> 
            </li>
        )

      })


        return(
                <div className="tab-pane" id="attendees" role="tabpanel" aria-labelledby="attendees-tab">
                    <div className="row"> 
                       
                        <div className="col-md-12">
                           <div className="attendee_search_bx">
                              <input type="text" name="attendees_search" placeholder="Search attendees" value={searchText} onChange={(event) => this.onChangeHandler(event)}/>
                              <button className="d-none btn-link btn text-warning"><i className="fas fa-search"></i></button>
                           </div> 
                        </div>
                    </div>
                    <div className="attendee_list">
                        <ul>
                            {attendeesData}
                        </ul>
                    </div>
             </div>
    )
    }else{
      return( <div className="no_data_found_webinar">No attendee has been joined yet!</div>);
    }

    }

};


const stateToProps = (state) => {
  return {
    attendeesList : state.webinarRoom.attendeesList,
    attendeesListLoading : state.webinarRoom.attendeesListLoading,
    permissionActionState : state.webinarRoom.permissionActionState,
    permissionActionLoading : state.webinarRoom.permissionActionLoading,
    joiningWebinarLoading : state.webinar.joiningWebinarLoading,
    joiningWebinarStatus : state.webinar.joiningWebinarStatus
  }
}

const actionCreator = {
  attendeesListFun : webinarRoomActions.attendeesList,
  handRaiseFun : webinarRoomActions.handRaise,
  permissionAttendeeFun : webinarRoomActions.permissionAttendee,
  joinWebinarFun : webinarActions.joinWebinar
}

export default withRouter(connect(stateToProps, actionCreator)(Attendees));