import { homeConstants } from './../_constants';

const initialState = { loadingStatus: false, homeInfo: {} };

export function home(state = initialState, action) {
    switch (action.type) {
        
        case homeConstants.HOME_INFO_REQUEST:
            return {
                ...state,
                loadingStatus: true,
            }
        case homeConstants.HOME_INFO_SUCCESS:
            return {
                ...state,
                loadingStatus: false,
                homeInfo: action.resp
            }
        case homeConstants.HOME_INFO_FAILURE:
            return {
                ...state,
                loadingStatus: false,
                homeInfo: {}
            }
        default:
            return state
    }
}
