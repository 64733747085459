import React from 'react'
import { Link } from "react-router-dom";
const MyPin = () => {
    return (
        <div className="listSection">
            <div className="title"><span className="bg-naviblue"><img src="../images/icons/iconuser.svg" width="12" alt="" /></span>My Pin</div>
            <ul className="listContent">
                <li><Link><span className="dotcirle rounded-circle green"></span>Akash Kumar</Link></li>
                <li><Link ><span className="dotcirle rounded-circle green"></span>Bharti Yadav</Link></li>
                <li><Link><span className="dotcirle rounded-circle green"></span>Ritika V</Link></li>
                <li><Link ><span className="dotcirle rounded-circle green"></span>Sachin Kumar</Link></li>
            </ul>
        </div>
    )
}

export default MyPin;
