import { myconfig, siteTokens } from '../../config/config';
//import { authHeader } from '../_helpers';
import axios from 'axios';
import userconfigs from '../../helpers/closures'

import { errorCheck, handleJWTError } from './common'


export const eventServices = {
    eventList,
    limitEventList,
    addEventOnGoogle,
    meetingHistoryList,
    logout,
    gAuthURL,
    gAuthToken,
    removeGoogleCalendarAuth,
    getRecordingService
};

function getRecordingService(meetId) {
    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };
    let meetingId = {
        meetingId: meetId
    }
    return axios.post(`${myconfig.apiURL}meeting/getMultipleRecording`, meetingId, axiosRequestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
                // userconfigs.removeItem("jwt");
                // generateToken();
                // window.location.href = "/login";
            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}

function gAuthURL() {

    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };
    return axios.get(`${myconfig.apiURL}calendar/gAuthCalendarURL`, axiosRequestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
                // userconfigs.removeItem("jwt");
                // generateToken();
                // window.location.href = "/login";
            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}

function gAuthToken(code) {

    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };
    let verifyPost = {
        code: code
    }
    return axios.post(`${myconfig.apiURL}calendar/gAuthCalendarToken`, verifyPost, axiosRequestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
                // userconfigs.removeItem("jwt");
                // generateToken();
                // window.location.href = "/login";
            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}


function removeGoogleCalendarAuth() {

    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };


    return axios.get(`${myconfig.apiURL}calendar/removeGoogleCalendarAuth`, axiosRequestOptions)
        .then(handleResponse)
        .then(msg => {
            return msg;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
                // userconfigs.removeItem("jwt");
                // generateToken();
                // window.location.href = "/login";
            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}


function eventList() {

    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };
    return axios.get(`${myconfig.apiURL}calendar/listScheduleMeetings`, axiosRequestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
                // userconfigs.removeItem("jwt");
                // generateToken();
                // window.location.href = "/login";
            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}



function limitEventList(limitCount) {

    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };

    const postData = {
        "limit": limitCount
    }
    return axios.post(`${myconfig.apiURL}calendar/listScheduleMeetings`, postData, axiosRequestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
                // userconfigs.removeItem("jwt");
                // generateToken();
                // window.location.href = "/login";
            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}


function addEventOnGoogle(eventId) {

    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
            //'Token': 'Bearer '+userconfigs.getItem('GT')
        }
    };

    const postData = {
        "syncWith": "google",
        "eventId": eventId
    }

    return axios.post(`${myconfig.apiURL}calendar/syncMeeting`, postData, axiosRequestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        })
        .catch((error) => {
            if (error.response.status === 401) {
                userconfigs.removeItem("GT");
                return Promise.reject(401);
            } else
                if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520)) {
                    handleJWTError();
                    // userconfigs.removeItem("jwt");
                    // generateToken();
                    // window.location.href = "/login";
                } else {
                    return Promise.reject(errorCheck(error));
                }
        });
}



function meetingHistoryList(pageValue) {
    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };


    // const postData = {
    //     "page": pageValue || 1
    // }

    const postData = pageValue

    return axios.post(`${myconfig.apiURL}meeting/meetingList`, postData, axiosRequestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
                // userconfigs.removeItem("jwt");
                // generateToken();
                // window.location.href = "/login";
            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}


function logout() {
    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };

    let logoutPost = {
        fingerprint: userconfigs.getItem('fp')
    }

    return axios.post(`${myconfig.apiURL}logout`, logoutPost, axiosRequestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            userconfigs.setItem('jwt', user.token);

            return user.token;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
                // userconfigs.removeItem("jwt");
                // generateToken();
                // window.location.href = "/login";
            } else {
                return Promise.reject(errorCheck(error));
            }
        });;
}

function handleResponse(response) {

    if (response.status !== 200) {
        if (response.status === 401) {
            userconfigs.removeItem("GT");
            logout();
        }
        if (response.status === 408 || response.status === 753) {
            handleJWTError();
            // userconfigs.removeItem("jwt");
            // generateToken();
            // window.location.href = "/login";
        }

        const error = (response && response.message) || response.statusText;
        return Promise.reject(error);
    }

    return response.data.result;
}
