import { themeConstants } from "./../_constants";
import userconfigs from "../../helpers/closures";

const initialState = {
  theme: userconfigs.getItem("theme") !== "darkTheme",
};

export function themeChange(state = initialState, action) {
  switch (action.type) {
    case themeConstants.THEME_CHANGE:
      const theme = state.theme ? "darkTheme" : "lightTheme";
      userconfigs.setItem("theme", theme);
      return {
        theme: !state.theme,
      };
    default:
      return state;
  }
}
