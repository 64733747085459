import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { accountActions } from '../../Redux/_actions';

class NetworkError extends Component {


	constructor(props) {
		super(props);
		this.state = {
			reLoad : false
		}
	}

	componentDidUpdate(prevProps, prevState){

			if(!prevState.reLoad || prevState.reLoad !== this.state.reLoad){
				setInterval(() => {
					this.props.meetingsCount();
					this.setState({reLoad: !this.state.reLoad})
				}, 5000)
			}																																																																																				

	}

	render(){
		if(this.props.count){
			return <Redirect to='/' />
		}else{
			return(
				<h3>Network Error</h3>
			)
		}
	}
	

}

function mapState(state) {
  const {count } = state.accountsInfo;
  return {
    count
  };
}


const actionCreators = {
	meetingsCount: accountActions.meetingsCount
};

export default withRouter(connect(mapState, actionCreators)(NetworkError));