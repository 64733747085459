import React, { Component, useEffect, useState, useMemo } from "react";
import isEmpty from "is-empty";
import { Link, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import validator from "validator";
import Loader from "../../components/UI/Spinner/Spinner";
import Spinner from "../../components/UI/Spinner/Spinner";
//import Header from '../../Header/Header';
import jwt_decode from "jwt-decode";
import userconfigs from "../../helpers/closures";
import { tsToOnlyDate, tsToTime } from "../../helpers/helpers";
import "./register.css";
import { webinarActions } from "../../Redux/_actions";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { getDate } from "../../Common/constant";
import RegisterLayout from "../../layoutSection/registerHeader";
import RegisterHeader from "../../layoutSection/registerHeader";
import RightBarTab from "./rightBar";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        webinar_id: this.props.match.params.id,
        name: undefined,
        type: "email",
        value: undefined,
        password: "",
      },
      isSubmitted: false,
      errors: {},
      userInfo: {},
      list: {},
      filter: null,
    };
  }

  componentDidMount = () => {
    let userInfo = jwt_decode(userconfigs.getItem("jwt"));
    this.setState({
      userInfo: userInfo,
    });

    if (typeof userInfo.name !== "undefined") {
      let fields = this.state.fields;
      fields["name"] = userInfo.name;
      this.setState({ fields });
    }

    this.props.webinar_info(this.state.fields.webinar_id);
    this.props.webinarFields(this.state.fields.webinar_id);
  };

  handleChange(e) {
    const { name, value, checked } = e.target;
    let fields = this.state.fields;
    if (name === "type") {
      fields["value"] = "";
    }
    if (["X", "XII", "GRADUATE"].includes(name)) {
      const stateFields = this.state.fields.Education || {};
      if (checked) {
        stateFields[name] = true;
      } else {
        stateFields[name] = false;
      }
      fields["Education"] = stateFields;
    } else {
      fields[name] = value;
    }
    // if (name === "Gender") {
    //   const stateFields = this.state.fields.Gender || {};
    //   if (checked) {
    //     stateFields[name] = true;
    //   } else {
    //     stateFields[name] = false;
    //   }
    //   fields["Gender"] = stateFields;
    // }
    this.setState({ fields });
  }

  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    //Name
    if (!fields["name"]) {
      formIsValid = false;
      errors["name"] = "Name can't be empty!";
    }

    //Value
    if (!fields["value"]) {
      formIsValid = false;
      //errors["value"] = "Please provide the valid information!";
    }

    if (fields["type"] === "email") {
      if (
        fields["value"] !== undefined &&
        !validator.isEmail(fields["value"])
      ) {
        formIsValid = false;
        errors["value"] = "Email is not valid!";
      }
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  registerSubmit(e) {
    e.preventDefault();
    this.setState({ isSubmitted: true });
    if (this.handleValidation()) {
      this.props.register(this.state.fields);
    }
  }

  render() {
    const { userInfo, fields, errors, isSubmitted } = this.state;
    const {
      webinarInfo,
      infoError,
      infoLoading,
      registerResp,
      webinarFieldsResp,
    } = this.props;

    // console.log(
    //   JSON.stringify(webinarFieldsResp?.[0]?.custom_fields),
    //   "//////"
    // );
    if (infoError) {
      return <Redirect to="/" />;
    }
    if (infoLoading) {
      return (
        <div className="text-center">
          <Loader />
        </div>
      );
    } else {
      let bannerImage = null;
      if (webinarInfo?.brandData?.brand_banner) {
        bannerImage = {
          backgroundImage: `url(${webinarInfo.brandData.brand_banner})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        };
      } else {
        bannerImage = {
          backgroundImage: `url(${
            process.env.PUBLIC_URL + "/images/reg-bg-img.png"
          })`,
        };
      }
      return (
        <div>
          <RegisterHeader webinarInfo={webinarInfo} />

          <div className="wave_webinar_reg py-0" style={{ minHeight: "auto" }}>
            <div className="container-fluid reg_form_vlog">
              <div className="row">
                {/* ***************** left div start here */}

                <div className="col-lg-6 px-5 no_p_md pt-0">
                  <div
                    className="inner_from_part px-5 py-5"
                    style={{ verticalAlign: "middle" }}
                  >
                    {Object.keys(registerResp).length > 0 ? (
                      <div className="col-12 registrn_web_succsesmsg">
                        <h3 className="text-success">
                          Thank you for the registration.
                        </h3>
                        <p className="text_milky">
                          Details will forword to your registered detail.
                        </p>
                        <Link to="/" className="btn btn-primary mt-2">
                          <i className="fas fa-home"></i> Home
                        </Link>
                      </div>
                    ) : (
                      <>
                        <h2 className="text-white text-left mb-5 font-weight-light">
                          Registration Form
                        </h2>
                        <form onSubmit={this.registerSubmit.bind(this)}>
                          <div className="form-group reg_field">
                            <label>Name</label>
                            <input
                              type="text"
                              value={fields.name}
                              className={
                                isSubmitted && !fields.name
                                  ? "hasError form-control inputColorTheme"
                                  : "form-control inputColorTheme"
                              }
                              name="name"
                              placeholder="Enter Your Name"
                              onChange={this.handleChange.bind(this)}
                            />
                          </div>

                          <div className="form-group reg_field">
                            <label>Email</label>
                            <input
                              type="text"
                              value={fields.value}
                              className={
                                isSubmitted && !fields.value
                                  ? "hasError form-control inputColorTheme"
                                  : "form-control inputColorTheme"
                              }
                              name="value"
                              placeholder="Enter Your Email"
                              onChange={this.handleChange.bind(this)}
                            />
                            <span className="float-left text-danger">
                              {errors["value"]}
                            </span>
                          </div>

                          <div className="form-group reg_field">
                            <label>Password</label>
                            <input
                              type="password"
                              value={fields.password ? fields.password : ""}
                              className={
                                isSubmitted && !fields.password
                                  ? "hasError form-control inputColorTheme"
                                  : "form-control inputColorTheme"
                              }
                              name="password"
                              placeholder="Password"
                              onChange={this.handleChange.bind(this)}
                            />
                          </div>
                          {/* )} */}

                          {webinarFieldsResp?.[0]?.custom_fields &&
                            webinarFieldsResp?.[0]?.custom_fields?.map(
                              (field) => (
                                <>
                                  <div className="form-group reg_field">
                                    <label
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {field.name?.toLowerCase()}
                                    </label>
                                    {field.type === "dropdown" ? (
                                      <>
                                        <select
                                          className="register_select"
                                          name={field.name}
                                          required={field.required}
                                          onChange={this.handleChange.bind(
                                            this
                                          )}
                                        >
                                          <option key={"default"} value="">
                                            Select
                                          </option>
                                          {field.options?.map(
                                            (option, index) => {
                                              return (
                                                <>
                                                  {Object.keys(option).map(
                                                    (op) => (
                                                      <>
                                                        <option
                                                          key={index}
                                                          value={op}
                                                        >
                                                          {option[op]}
                                                        </option>
                                                      </>
                                                    )
                                                  )}
                                                </>
                                              );
                                            }
                                          )}
                                        </select>
                                      </>
                                    ) : [
                                        "checkbox",
                                        "textbox",
                                        "radio_button",
                                      ].includes(field.type) ? (
                                      <>
                                        <input
                                          required={field.required}
                                          type={
                                            field.type === "textbox"
                                              ? "text"
                                              : field.type === "radio_button"
                                              ? "radio"
                                              : field.type
                                          }
                                          className="form-control"
                                          // value={field.name}
                                          // className={
                                          //   isSubmitted && !fields.name
                                          //     ? "hasError form-control"
                                          //     : "form-control"
                                          // }
                                          name={field.name}
                                          onChange={this.handleChange.bind(
                                            this
                                          )}
                                        />
                                      </>
                                    ) : field.type === "education" ? (
                                      <div>
                                        {Object.keys(field.options).map(
                                          (op, index) => (
                                            <div className="row">
                                              <div className="col-lg-2">
                                                <label for={op}>
                                                  {field.options[op]}
                                                </label>
                                              </div>
                                              <div className="col-lg-6">
                                                <input
                                                  className="m-auto"
                                                  type="checkbox"
                                                  id={op}
                                                  key={index}
                                                  value={op}
                                                  name={op}
                                                  onChange={this.handleChange.bind(
                                                    this
                                                  )}
                                                />
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    ) : field.type === "gender" ? (
                                      <>
                                        {Object.keys(field.options).map(
                                          (op, index) => (
                                            <div className="row">
                                              <div className="col-lg-2">
                                                <label for={op}>
                                                  {field.options[op]}
                                                </label>
                                              </div>
                                              <div className="col-lg-6">
                                                <input
                                                  className="mt-1"
                                                  type="radio"
                                                  id={op}
                                                  key={index}
                                                  value={op}
                                                  name="Gender"
                                                  onChange={this.handleChange.bind(
                                                    this
                                                  )}
                                                />
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </>
                                    ) : null}
                                  </div>
                                </>
                              )
                            )}

                          <div className="form-group mt-3">
                            <button className="webinar_reg_button">
                              Register
                            </button>
                          </div>
                        </form>
                      </>
                    )}
                  </div>
                </div>

                {/* ***************** left div start here */}

                {/* ***************** right div start here */}
                <RightBarTab
                  webinarInfo={webinarInfo}
                  webinarID={this.state.fields.webinar_id}
                />

                {/* ***************** right div end here */}
              </div>
            </div>
          </div>
        </div>
        // </Layout>
      );
    }
  }
}

const mapState = (state) => {
  const {
    registerLoading,
    webinarInfo,
    infoError,
    infoLoading,
    registerResp,
    webinarFields,
  } = state.webinar;
  return {
    registerLoading,
    webinarInfo,
    infoError,
    infoLoading,
    registerResp,
    webinarFieldsResp: webinarFields,
  };
};

const actionCreators = {
  register: webinarActions.register,
  webinar_info: webinarActions.webinarInfo,
  webinarFields: webinarActions.webinarFields,
  //submitCreateConferenceForm: conferenceActions.submitCreateConferenceForm
};

export default withRouter(connect(mapState, actionCreators)(Register));
