import { userConstants } from "../_constants";

//let user = JSON.parse(localStorage.getItem('user'));
const initialState = {
  loggedIn: true,
  loggingIn: false,
  profileInfo: [],
  profileLoading: true,
  contactsInfo: [],
  contactsLoading: true,
  updateLoading: false,
  updated: false,
  changeLoading: false,
  changed: false,
  verificationSent: false,
  otpVerifyLoading: false,
  otpVerified: false,
  forgetLoader: false,
  resetLoader: false,
  resetPwd: false,
  authLoading: false,
  authSuccess: false,
  gAuthLoading: true,
  gAuthURL: null,
  gContactAuthLoading: true,
  gContactAuthURL: null,
  gAuthInfo: [],
  gContactAuthInfo: [],
  gAuthRemoved: false,
  gAuthRemoveLoading: false,
  gContactSyncLoading: false,
  gContactSyncInfo: [],

  upgradeLoading: false,
  upgradeError: false,
  upgradeRes: [],
  eOfficeAccessibility: "",
  userRemovedFromBusiness: false,
  emailSendError: false,
  otpError: false,
  profileError: false,
  mobileVerify: true,
  mobileCheckLoading: false,
};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.UPGRADE_REQUEST:
      return {
        ...state,
        upgradeLoading: true,
      };
    case userConstants.UPFRADE_SUCCESS:
      return {
        ...state,
        upgradeLoading: false,
        upgradeRes: action.resp,
      };
    case userConstants.UPGRADE_FAILURE:
      return {
        ...state,
        upgradeLoading: false,
        upgradeError: true,
      };
    case userConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user,
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        loggingIn: false,
        loggedIn: true,
        user: action.user,
      };
    case userConstants.LOGIN_FAILURE:
      return {
        loggingIn: false,
        loggedIn: false,
        user: null,
      };
    case userConstants.USER_REMOVE_FROM_BUSINESS_REQUEST:
      return {
        userRemovedFromBusiness: false,
      };
    case userConstants.USER_REMOVE_FROM_BUSINESS_SUCCESS:
      return {
        userRemovedFromBusiness: true,
      };
    case userConstants.USER_REMOVE_FROM_BUSINESS_FAILURE:
      return {
        userRemovedFromBusiness: false,
      };

    case userConstants.RESEND_EMAIL_REQUEST:
      return {
        emailSent: false,
        emailSendError: false,
      };
    case userConstants.RESEND_EMAIL_SUCCESS:
      return {
        emailSent: true,
      };
    case userConstants.RESEND_EMAIL_FAILURE:
      return {
        emailSent: false,
        emailSendError: true,
      };

    case userConstants.LOGIN_DETAILS_REQUEST:
      return {
        detailSent: false,
      };
    case userConstants.LOGIN_DETAILS_SUCCESS:
      return {
        detailSent: true,
      };
    case userConstants.LOGIN_DETAILS_FAILURE:
      return {
        detailSent: false,
      };
    case userConstants.USER_CONTACTS_REQUEST:
      return {
        ...state,
        contactsLoading: true,
        contactsInfo: [],
      };
    case userConstants.USER_CONTACTS_SUCCESS:
      return {
        ...state,
        contactsLoading: false,
        contactsInfo: action.resp,
      };
    case userConstants.USER_CONTACTS_FAILURE:
      return {
        ...state,
        contactsLoading: false,
        contactsInfo: [],
      };

    case userConstants.GOOGLE_CONTACT_SYNC_REQUEST:
      return {
        ...state,
        gContactSyncLoading: true,
        gContactSyncInfo: [],
      };

    case userConstants.GOOGLE_CONTACT_SYNC_SUCCESS:
      return {
        ...state,
        gContactSyncLoading: false,
        gContactSyncInfo: action.resp,
      };
    case userConstants.GOOGLE_CONTACT_SYNC_FAILURE:
      return {
        ...state,
        gContactSyncLoading: false,
        gContactSyncInfo: [],
      };

    case userConstants.USER_DETAILS_REQUEST:
      return {
        ...state,
        profileLoading: true,
        profileInfo: [],
      };
    case userConstants.USER_DETAILS_SUCCESS:
      return {
        ...state,
        profileLoading: false,
        profileInfo: action.resp,
      };
    case userConstants.USER_DETAILS_FAILURE:
      return {
        ...state,
        profileLoading: false,
        profileInfo: [],
      };

    case userConstants.GET_ACCESSIBILITY_REQUEST:
      return {
        ...state,
        profileLoading: true,
        eOfficeAccessibility: "",
      };
    case userConstants.GET_ACCESSIBILITY_SUCCESS:
      return {
        ...state,
        profileLoading: false,
        eOfficeAccessibility: action.resp,
      };
    case userConstants.GET_ACCESSIBILITY_FAILURE:
      return {
        ...state,
        profileLoading: false,
        eOfficeAccessibility: "",
      };

    case userConstants.PROFILE_UPDATE_REQUEST:
      return {
        ...state,
        updateLoading: true,
        updated: false,
        profileError: false,
      };
    case userConstants.PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        updated: action.mobile,
      };
    case userConstants.PROFILE_UPDATE_FAILURE:
      return {
        ...state,
        updateLoading: false,
        updated: false,
        profileError: true,
      };

    case userConstants.PASSWORD_UPDATE_REQUEST:
      return {
        ...state,
        changeLoading: true,
        changed: false,
      };
    case userConstants.PASSWORD_UPDATE_SUCCESS:
      return {
        ...state,
        changeLoading: false,
        changed: true,
      };
    case userConstants.PASSWORD_UPDATE_FAILURE:
      return {
        ...state,
        changeLoading: false,
        changed: false,
      };

    case userConstants.VERIFY_USERNAME_REQUEST:
      return {
        ...state,
        verificationSent: false,
      };
    case userConstants.VERIFY_USERNAME_SUCCESS:
      return {
        ...state,
        verificationSent: true,
      };
    case userConstants.VERIFY_USERNAME_FAILURE:
      return {
        ...state,
        verificationSent: false,
      };

    case userConstants.OTP_VERIFY_REQUEST:
      return {
        ...state,
        otpVerifyLoading: true,
        otpVerified: false,
        otpError: false,
      };
    case userConstants.OTP_VERIFY_SUCCESS:
      return {
        ...state,
        otpVerifyLoading: false,
        otpVerified: true,
        mobileVerify: true,
      };
    case userConstants.OTP_VERIFY_FAILURE:
      return {
        ...state,
        otpVerifyLoading: false,
        otpVerified: false,
      };

    case userConstants.FORGET_PASSWORD_REQUEST:
      return {
        ...state,
        forgetLoader: true,
      };
    case userConstants.FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        forgetLoader: false,
      };
    case userConstants.FORGET_PASSWORD_FAILURE:
      return {
        ...state,
        forgetLoader: false,
      };

    case userConstants.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        resetLoader: true,
        resetPwd: false,
      };
    case userConstants.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetLoader: false,
        resetPwd: true,
      };
    case userConstants.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        resetLoader: false,
        resetPwd: false,
      };

    case userConstants.AUTH_GOOGLE_REQUEST:
      return {
        authLoading: true,
        authSuccess: false,
      };
    case userConstants.AUTH_GOOGLE_SUCCESS:
      return {
        authLoading: false,
        authSuccess: true,
        user: action.user,
      };
    case userConstants.AUTH_GOOGLE_FAILURE:
      return {
        authLoading: false,
        authSuccess: false,
      };

    case userConstants.GOOGLE_AUTH_URL_REQUEST:
      return {
        ...state,
        gAuthLoading: true,
        gAuthURL: null,
      };
    case userConstants.GOOGLE_AUTH_URL_SUCCESS:
      return {
        ...state,
        gAuthLoading: true,
        gAuthURL: action.user,
      };
    case userConstants.GOOGLE_AUTH_URL_FAILURE:
      return {
        ...state,
        gAuthLoading: false,
        gAuthURL: null,
      };

    case userConstants.GOOGLE_CONTACT_AUTH_URL_REQUEST:
      return {
        ...state,
        gContactAuthLoading: true,
        gContactAuthURL: null,
      };
    case userConstants.GOOGLE_CONTACT_AUTH_URL_SUCCESS:
      return {
        ...state,
        gContactAuthLoading: true,
        gContactAuthURL: action.user,
      };
    case userConstants.GOOGLE_CONTACT_AUTH_URL_FAILURE:
      return {
        ...state,
        gContactAuthLoading: true,
        gContactAuthURL: null,
      };

    case userConstants.GOOGLE_AUTH_TOKEN_SUCCESS:
      return {
        ...state,
        gAuthLoading: false,
        gAuthInfo: action.user,
      };
    case userConstants.GOOGLE_AUTH_TOKEN_FAILURE:
      return {
        ...state,
        gAuthLoading: false,
        gAuthInfo: [],
      };

    case userConstants.GOOGLE_CONTACT_AUTH_TOKEN_SUCCESS:
      return {
        ...state,
        gContactAuthLoading: false,
        gContactAuthInfo: action.user,
      };
    case userConstants.GOOGLE_CONTACT_AUTH_TOKEN_FAILURE:
      return {
        ...state,
        gContactAuthLoading: false,
        gContactAuthInfo: [],
      };

    case userConstants.REMOVE_GOOGLE_AUTH_REQUEST:
      return {
        ...state,
        gAuthRemoveLoading: true,
        gAuthRemoved: false,
      };
    case userConstants.REMOVE_GOOGLE_AUTH_SUCCESS:
      return {
        ...state,
        gAuthRemoveLoading: false,
        gAuthRemoved: true,
      };
    case userConstants.REMOVE_GOOGLE_AUTH_FAILURE:
      return {
        ...state,
        gAuthRemoveLoading: false,
        gAuthRemoved: false,
      };

    case userConstants.LOGOUT:
      return {
        ...state,
        loggedIn: false,
        user: action.user,
      };
    case userConstants.CHECK_EMAIL_VERIFIED_REQ:
      return {
        ...state,
        mobileCheckLoading: true,
        mobileVerify: true,
      };
    case userConstants.CHECK_EMAIL_VERIFIED_SUCCESS:
      return {
        ...state,
        mobileCheckLoading: false,
        mobileVerify: action.user.isMobileVerified,
      };
    case userConstants.CHECK_EMAIL_VERIFIED_FAILURE:
      return {
        ...state,
        mobileCheckLoading: false,
        mobileVerify: true,
      };
    default:
      return state;
  }
}
