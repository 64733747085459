import React, { useState, useEffect } from "react";
import Layout from "../../layoutSection";
import "../styles.scss";
import { Button } from "reactstrap";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { FaUserPlus } from "react-icons/fa";
import jwt_decode from "jwt-decode";
import { useDispatch } from "react-redux";
import userconfigs from "../../helpers/closures";
import { userService } from "../../Redux/_services";
import UserTable from "./UserTable";
import UserModal from "./UserModal";
import { alertActions } from "../../Redux/_actions";
import { useHistory } from "react-router-dom";
// import Upgradeplansection from "../Upgradeplan/Upgradeplansection";


function UserManagment() {
  const [lgShow, setLgShow] = useState(false);
  const [userType, setUserType] = useState('Users');
  const [isCreate, setIsCreate] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const defaultData = { emails: '', package_type: '', department: '' };
  const [formData, setFormData] = useState(defaultData);
  const [planData, setPlanData] = useState([]);
  const [formMessages, setFormMessages] = useState([]);
  const dispatch = useDispatch();
  let webUser = jwt_decode(userconfigs.getItem("jwt"));
  const history = useHistory();
  const getPlanData = async () => {
    let response = await userService.handleGetList(`upgradeAccount/getAllPlans`);

    if (response !== true) {
      setPlanData(response);

    }

  }

  /**
   * This method will flush previous enter values on close modal
   */
  const closeModal = () => {
    setFormData(defaultData)
    setFormMessages([])
    setLgShow(false)
  }

  const handleSubmit = async () => {
    const  format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    if(format.test(formData.department)){
      dispatch(alertActions.error("Department should not accept special characters"));
      return false;
    }
    if (disabled === true) { return false; }
    setDisabled(true);
    formData.package_type = (webUser && webUser.plan_data) && webUser.plan_data.plan_type;
    let path = 'admin/hostuser/add';
    if (isCreate === false) { path = 'admin/hostuser/update'; }
    userService.postData(formData, path).then(response => {
      setDisabled(false);
      let status = false;
      response.forEach(function (element) {
        if (element.status === 0) { status = true; }
      })
      setFormMessages(response);
      if (status === false) {
        getPlanData();
        setFormData(defaultData);
        setTimeout(function () { setLgShow(false) }, 2000);
      }
    }).catch(err => {
      setDisabled(false);
    })
  }

  useEffect(() => {
    if(webUser && webUser?.hostRole === 'user'){
      return history.push('/')
    }
    getPlanData();
  }, []);

  const handleAction = async(status, data) => {
    if (status === false) {
      await setFormData(data);
    }
    setFormMessages([])
    setLgShow(true);
    setIsCreate(status);
    
  }

  return (
    <Layout title="User Management" active="User Management">
      <div className="container-fluid s_wave_content_part pl-3 pr-3 speakers">
        <UserModal lgShow={lgShow} setLgShow={setLgShow} closeModal={closeModal}  setFormData={setFormData} formData={formData} formMessages={formMessages} handleSubmit={handleSubmit} planData={planData} webUser={webUser} isCreate={isCreate} />


        {/* {(webUser && webUser.plan_data && webUser.plan_data.plan_type === "Basic") &&
          <Upgradeplansection />
        } */}

        <div className="row usermanagmentlist_main">
          <div className="col-md-12 mt-5 p-0">
            <Tabs defaultActiveKey="Manageuserlist" id="uncontrolled-tab-example" className="">
              <Tab eventKey="Manageuserlist" title="Users" tabClassName="sideBar welcomeMsg" >

                <div className="tableheadinglist mb-3">
                  <div className="dflex tablelistfilter">
                    <div className="form-group">
                      <select className="form-control set--inverse inputBgTheme welcomeMsg" onChange={(e) => { setUserType(e.target.value) }} value={userType}>
                        <option value="Users">All</option>
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>

                      </select>
                    </div>

                  </div>

                  <div className="rightsideadduser">
                    <Button variant="primary" onClick={() => handleAction(true)}> <FaUserPlus /> Add User</Button>
                  </div>


                </div>
                <UserTable userType={userType} lgShow={lgShow} handleEdit={handleAction} />

              </Tab>
              <Tab eventKey="Pendinglist" title="Pending" tabClassName="sideBar welcomeMsg" >
                <div className="tableheadinglist mb-3">
                  <div className="dflex tablelistfilter">

                  </div>
                </div>

                <UserTable userType={'Pending'} lgShow={lgShow} handleEdit={handleAction} />
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default UserManagment;
