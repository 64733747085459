import React, { Component } from "react";

import "bootstrap/dist/css/bootstrap.min.css";

import userconfigs from "../helpers/closures";
import { generateToken } from "../helpers/helpers";


import { connect } from "react-redux";

import { history } from "../Redux/_helpers";
import { alertActions, notificationActions } from "../Redux/_actions";
import "react-notifications-component/dist/theme.css";

import { ThemeProvider } from "styled-components";
import { lightTheme, darkTheme } from "../Theme/theme";
import { GlobalStyles } from "../Theme/global";
import { siteTokens } from "../config/config";
import Routes from "./../routes";


class App extends Component {
  constructor(props) {
    super(props);

    history.listen((location, action) => {
      // clear alert on location change
      this.props.clearAlerts();
    });

    this.state = {
      isLoaded: false,
      notified: true,
      isLoggedIn: false,
      theme: userconfigs.getItem("theme") || "lightTheme",
      homePopup: false,
    };
  }

  componentDidMount() {

    var jwtToken = "jwt"
    siteTokens.map((site) => {
      if (window.location.hostname === site.url) {
        jwtToken = site.token;
        if (
          typeof userconfigs.getItem(site.token) === "undefined" ||
          userconfigs.getItem(site.token) === "null" ||
          userconfigs.getItem(site.token) === null
        ) {
          generateToken();
        }
      }
    });
    let jwttkn = userconfigs.getItem(jwtToken);
    if (jwttkn !== null && jwttkn !== "null" && jwttkn !== "") {
      // let userInfo = jwt_decode(userconfigs.getItem('jwt'))
      // if(userInfo.loginStatus === 0 && (window.location.pathname !== '/login' && window.location.pathname !== '/')) {
      // 	window.location.replace("/login");
      // }
      let currURL = window.location.href;
      currURL = currURL.split("/");
      const eOfficeId = localStorage.getItem("eOfficeId");
      if (currURL[3] === "eoffice") {
        if (eOfficeId === undefined || eOfficeId === null) {
          window.location.replace("/");
        }
      }
    }
    // if(window.location.hostname === "wave.sarv.com"){
    // 	if (typeof userconfigs.getItem('whash') === "undefined" || userconfigs.getItem("whash") === 'null' || userconfigs.getItem("whash") === null) {
    // 		generateToken();
    // 	}
    // }
    // else if(window.location.hostname === "meity.sarv.com"){
    // 	if (typeof userconfigs.getItem('mhash') === "undefined" || userconfigs.getItem("mhash") === 'null' || userconfigs.getItem("mhash") === null) {
    // 		generateToken();
    // 	}
    // }
    // else if(window.location.hostname === "dev.wave.sarv.com" || window.location.hostname === "localhost"){
    // 	if (typeof userconfigs.getItem('dhash') === "undefined" || userconfigs.getItem("dhash") === 'null' || userconfigs.getItem("dhash") === null) {
    // 		generateToken();
    // 	}
    // }
    // else {

    // 	if (typeof userconfigs.getItem('jwt') !== "undefined" && userconfigs.getItem("jwt") !== null) {
    // 		let userInfo = jwt_decode(userconfigs.getItem('jwt'))

    // 		if((window.location.hostname === "wave.sarv.com" && userInfo.kid === "sarv/WaveApi") || (window.location.hostname === "meity.sarv.com" && userInfo.kid === "sarv/meity") || ((window.location.hostname === "dev.wave.sarv.com" || window.location.hostname === "localhost") && userInfo.kid === "sarv/DevWaveApi")){
    // 			if(userInfo.loginStatus === 1){
    // 				this.setState({
    // 					isLoggedIn: true,
    // 				})
    // 			}
    // 			else{
    // 				// let noHeaderPages = this.state.noHeaderPages;
    // 				// noHeaderPages.push("")
    // 				// noHeaderPages.push("waitingRoom")
    // 				// noHeaderPages.push("webinar_info")
    // 				// this.setState({
    // 				// 	noHeaderPages:noHeaderPages
    // 				// })
    // 			}
    // 			// this.setState({
    // 			// 	isLoaded: true
    // 			// })
    // 		}
    // 		else
    // 		{
    // 			generateToken();
    // 		}
    // 	}
    // }

    var interval = setInterval(() => {
      if (
        typeof userconfigs.getItem("jwt") !== "undefined" &&
        userconfigs.getItem("jwt") !== null &&
        userconfigs.getItem("jwt") !== "null"
      ) {
        // let noHeaderPages = this.state.noHeaderPages;
        // noHeaderPages.push(" ")
        // noHeaderPages.push("waitingRoom")
        // let userInfo = jwt_decode(userconfigs.getItem('jwt'))
        // if(userInfo.loginStatus === 0){
        // 	let noHeaderPages = this.state.noHeaderPages;
        // 	noHeaderPages.push("")
        // 	noHeaderPages.push("waitingRoom")
        // 	noHeaderPages.push("webinar_info")
        // 	this.setState({
        // 		noHeaderPages:noHeaderPages
        // 	})
        // }

        this.setState({
          isLoaded: true,
          //	noHeaderPages:noHeaderPages
        });
        clearInterval(interval);
      }
    }, 1000);

    if (
      typeof userconfigs.getItem("PU") === "undefined" ||
      userconfigs.getItem("PU") === null
    ) {
      this.setState({ homePopup: true });
      userconfigs.setItem("PU", new Date().getTime());
    } else {
      this.setState({ homePopup: false });
    }

    this.props.longPolling()
    this.notifIntervel = setInterval(() => {
      this.props.longPolling()
    }, 36000)

    // if (!("Notification" in window)) {
    //   console.log("This browser does not support desktop notification");
    // } else {
    //   if (Notification.permission === 'granted') {
    //     this.props.longPolling()
    //     this.notifIntervel = setInterval(() => {
    //       this.props.longPolling()
    //     }, 36000)
    //   } else {
    //     Notification.requestPermission().then((permission) => {
    //       this.props.longPolling()
    //       this.notifIntervel = setInterval(() => {
    //         this.props.longPolling()
    //       }, 36000)
    //     })
    //   }
    // }
  }

  componentWillUnmount() {
    clearInterval(this.notifIntervel)
  }

  // themeChange = (theme, e) => {
  //   if (theme !== "") {
  //     userconfigs.setItem("theme", theme);
  //     //if(theme === 'lightTheme'){
  //     this.setState({ theme: theme });
  //     //}
  //     //else{
  //     //	this.setState({theme:'darkTheme'})
  //     //}
  //   }
  // };

  render() {

    const { theme } = this.props.themeChange;
    return (
      <ThemeProvider theme={theme ? lightTheme : darkTheme}>
        <GlobalStyles />
        <Routes
          jwtDetails={userconfigs.getItem("jwt")}
          isLoaded={this.state.isLoaded}
        // theme={theme}
        // themeChange={this.themeChange}
        />
      </ThemeProvider>
    );
  }
}
function mapState(state) {
  const { alert, themeChange, authentication } = state;
  return { alert, themeChange, authentication };
}

const actionCreators = {
  clearAlerts: alertActions.clear,
  longPolling: notificationActions.longPolling,
};

const connectedApp = connect(mapState, actionCreators)(App);
export { connectedApp as App };
