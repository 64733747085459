import { myconfig, siteTokens } from '../../config/config';
//import { authHeader } from '../_helpers';
import axios from 'axios';
import userconfigs from '../../helpers/closures';
import { generateToken } from '../../helpers/helpers';
import { history } from "../_helpers";

import { errorCheck, handleJWTError } from './common'
import cookie from 'react-cookies';


export const userService = {
    login,
    loginWithOTP,
    getLoginOTP,
    resendEmailLink,
    loginDetails,
    userDetails,
    profileUpdate,
    changePassword,
    verifyProfile,
    verifyOtp,
    logout,
    forgetPassword,
    resetPassword,
    authGoogle,
    gAuthURL,
    gAuthToken,
    removeGoogleDriveAuth,
    userContacts,
    gContactAuthURL,
    gContactAuthToken,
    syncGoogleContact,
    upgradePlan,
    createUserAccount,
    uploadUserCsv,
    uploadProfilePhoto,
    editUserAccount,
    getAccessibility,
    getCsrf,
    removeUserFromBusiness,
    postData,
    checkValidation,
    getSingleUserById,
    deleteAdminUser,
    handleGetList,
    postFormData,
    getHostUserDetails,
    getHostUserByType,
    checkEmailVerified,
    getFormData,
    submitMeetRegForm
};

function login(username, password, token) {
    // const requestOptions = {
    //     method: 'POST',
    //     headers: { 'Content-Type': 'application/json' },
    //     body: JSON.stringify({ mobile, password })
    // };
    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };

    let loginPost = {
        userName: username,
        password: password,
        loginVia: "PWD",
        fingerprint: userconfigs.getItem('fp'),
        csrf: token || userconfigs.getItem('csrf')
    }
    // myconfig.apiURL = 'http://localhost:3001/'; 
    return axios.post(`${myconfig.apiURL}login`, loginPost, axiosRequestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (user.token) {
                userconfigs.setItem('jwt', user.token);
                userconfigs.setItem('auth', user.auth);
                localStorage.setItem('loggedInStatus', 1);
                userconfigs.setItem('account', true);
                return user.token;
            }
            else {
                return user
            }
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925 || error.response.status === 926)) {
                handleJWTError();
                // userconfigs.removeItem("jwt");
                // generateToken();
                // window.location.href = "/login";
            } else {
                return Promise.reject(errorCheck(error));
            }
            //return error.response.data.error;
        });
}

function removeUserFromBusiness(uuid) {
    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };

    return axios.post(`${myconfig.apiURL}Setting/import/contacts/remove-contact`, { removeUser: uuid }, axiosRequestOptions)
        .then(handleResponse)
        .then(msg => {
            return msg;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}

function uploadProfilePhoto(formData) {
    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };
    //myconfig.apiURL = 'http://localhost:3001/';

    return axios.post(`${myconfig.apiURL}company/upload_profile_photo`, formData, axiosRequestOptions)
        .then(handleResponse)
        .then(msg => {
            return msg;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}

function uploadUserCsv(formData) {
    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };
    //myconfig.apiURL = 'http://localhost:3001/';

    return axios.post(`${myconfig.apiURL}import/contacts/upload-csv`, formData, axiosRequestOptions)
        .then(handleResponse)
        .then(msg => {
            return msg;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}


function loginWithOTP(username, otp) {

    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };

    // get csrf form localstorage
    let csrf = userconfigs.getItem("csrf")
    console.log("csrf", csrf)

    let loginPost = {
        otp: otp,
        userName: username,
        fingerprint: userconfigs.getItem('fp'),
        csrf
    }

    return axios.post(`${myconfig.apiURL}validateOtp`, loginPost, axiosRequestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            userconfigs.setItem('jwt', user.token);
            userconfigs.setItem('auth', user.auth);
            userconfigs.setItem('account', true);

            return user.token;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
                // userconfigs.removeItem("jwt");
                // generateToken();
                // window.location.href = "/login";
            } else {
                return Promise.reject(errorCheck(error));
            }
            //return error.response.data.error;
        });;
}

function getLoginOTP(username) {

    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };

    let csrf = userconfigs.getItem("csrf")

    let loginPost = {
        userName: username,
        loginVia: "OTP",
        csrf
    }

    return axios.post(`${myconfig.apiURL}login`, loginPost, axiosRequestOptions)
        .then(handleResponse)
        .then(msg => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            //userconfigs.', user);

            return msg;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
                // userconfigs.removeItem("jwt");
                // generateToken();
                // window.location.href = "/login";
            } else {
                return Promise.reject(errorCheck(error));
            }
            //return error.response.data.error;
        });;
}


function logout() {
    // remove user from local storage to log user out
    //userconfigs.removeItem('jwt');
    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };

    let logoutPost = {
        fingerprint: userconfigs.getItem('fp')
    }

    return axios.post(`${myconfig.apiURL}logout`, logoutPost, axiosRequestOptions)
        .then(handleResponseLogout)
        .then(user => {
            console.log(user, "user")
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            userconfigs.setItem('jwt', user.token);
            localStorage.setItem('loggedInStatus', 0);
            userconfigs.setItem('auth', user.auth);
            userconfigs.setItem('account', true);
           // history.replace("/login")
            return user.token;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
                // userconfigs.removeItem("jwt");
                // generateToken();
                window.location.href = "/login";
            } else {
                return Promise.reject(errorCheck(error));
            }
        });;
}

function resendEmailLink(email) {

    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };

    let emailLinkPost = {
        email: email
    }

    return axios.post(`${myconfig.apiURL}resendEmailLink`, emailLinkPost, axiosRequestOptions)
        .then(handleResponse)
        .then(msg => {
            return msg;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
                // userconfigs.removeItem("jwt");
                // generateToken();
                // window.location.href = "/login";
            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}

function loginDetails(username) {

    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };

    let loginDetailPost = {
        userName: username
    }

    return axios.post(`${myconfig.apiURL}loginDetails`, loginDetailPost, axiosRequestOptions)
        .then(handleResponse)
        .then(msg => {
            return msg;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
                // userconfigs.removeItem("jwt");
                // generateToken();
                // window.location.href = "/login";
            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}

function userDetails(uuid) {

    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };
    //myconfig.apiURL = 'http://localhost:3001/';
    return axios.get(`${myconfig.apiURL}userDetails/${uuid}`, axiosRequestOptions)
        .then(handleResponse)
        .then(resp => {
            return resp;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
                // userconfigs.removeItem("jwt");
                // generateToken();
                // window.location.href = "/login";
            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}

function getAccessibility() {

    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };
    //myconfig.apiURL = 'http://localhost:3001/';
    const eOfficeID = localStorage.getItem('eOfficeId');

    return axios.get(`${myconfig.apiURL}getModuleAccessibilityAsPerRole/${eOfficeID}`, axiosRequestOptions)
        .then(handleResponse)
        .then(resp => {
            return resp;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}

function userContacts() {

    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };

    return axios.get(`${myconfig.apiURL}userContacts`, axiosRequestOptions)
        .then(handleResponse)
        .then(resp => {
            return resp;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}

function profileUpdate(postData) {

    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };

    postData.fingerprint = userconfigs.getItem('fp');
    postData.csrf = userconfigs.getItem('csrf');

    return axios.post(`${myconfig.apiURL}updateProfile`, postData, axiosRequestOptions)
        .then(handleResponse)
        .then(user => {
            userconfigs.setItem('jwt', user.token);
            return user.token;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
                // userconfigs.removeItem("jwt");
                // generateToken();
                // window.location.href = "/login";
            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}

function changePassword(postData) {

    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };

    return axios.post(`${myconfig.apiURL}changePassword`, postData, axiosRequestOptions)
        .then(handleResponse)
        .then(msg => {
            return msg;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
                // userconfigs.removeItem("jwt");
                // generateToken();
                // window.location.href = "/login";
            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}

function verifyProfile(username) {

    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };

    let verifyPost = {
        userName: username
    }

    return axios.post(`${myconfig.apiURL}verifyProfile`, verifyPost, axiosRequestOptions)
        .then(handleResponse)
        .then(msg => {
            return msg;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
                // userconfigs.removeItem("jwt");
                // generateToken();
                // window.location.href = "/login";
            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}


function verifyOtp(username, otp) {

    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };

    let loginPost = {
        otp: otp,
        userName: username,
        fingerprint: userconfigs.getItem('fp'),
        csrf: localStorage.getItem('csrf')
    }

    return axios.post(`${myconfig.apiURL}verifyOtp`, loginPost, axiosRequestOptions)
        .then(handleResponse)
        .then(user => {
            userconfigs.setItem('jwt', user.token);
            userconfigs.setItem('auth', user.auth);
            userconfigs.setItem('account', true);
            return user.token;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
                // userconfigs.removeItem("jwt");
                // generateToken();
                // window.location.href = "/login";
            } else {
                return Promise.reject(errorCheck(error));
            }
            //return error.response.data.error;
        });;
}

function forgetPassword(username) {
    console.log(myconfig.apiURL)

    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };

    let verifyPost = {
        userName: username
    }

    return axios.post(`${myconfig.apiURL}forgotPassword`, verifyPost, axiosRequestOptions)
        .then(handleResponse)
        .then(msg => {
            return msg;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
                // userconfigs.removeItem("jwt");
                // generateToken();
                // window.location.href = "/login";
            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}

function resetPassword(pwd, token) {

    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };

    let verifyPost = {
        newPassword: pwd,
        verificationToken: token
    }

    return axios.post(`${myconfig.apiURL}verifyForgotPwdLink`, verifyPost, axiosRequestOptions)
        .then(handleResponse)
        .then(msg => {
            return msg;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
                // userconfigs.removeItem("jwt");
                // generateToken();
                // window.location.href = "/login";
            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}

function authGoogle(postData) {

    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };

    let csrf = userconfigs.getItem("csrf")

    postData.fingerprint = userconfigs.getItem('fp');
    postData.csrf = userconfigs.getItem('csrf');

    return axios.post(`${myconfig.apiURL}authGoogle`, postData, axiosRequestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (user.token) {
                userconfigs.setItem('jwt', user.token);
                userconfigs.setItem('auth', user.auth);
                userconfigs.setItem('account', true);
            }
            else {
                return user
            }
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
                // userconfigs.removeItem("jwt");
                // generateToken();
                // window.location.href = "/login";
            } else {
                return Promise.reject(errorCheck(error));
            }
            //return error.response.data.error;
        });
}

function gAuthURL() {

    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };

    return axios.get(`${myconfig.apiURL}gAuthURL`, axiosRequestOptions)
        .then(handleResponse)
        .then(msg => {
            return msg;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
                // userconfigs.removeItem("jwt");
                // generateToken();
                // window.location.href = "/login";
            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}

function gAuthToken(code) {

    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };

    let verifyPost = {
        code: code
    }

    return axios.post(`${myconfig.apiURL}gAuthToken`, verifyPost, axiosRequestOptions)
        .then(handleResponse)
        .then(msg => {
            return msg;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
                // userconfigs.removeItem("jwt");
                // generateToken();
                // window.location.href = "/login";
            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}

function gContactAuthToken(code) {

    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };

    let verifyPost = {
        code: code
    }

    return axios.post(`${myconfig.apiURL}contact/gContactAuthToken`, verifyPost, axiosRequestOptions)
        .then(handleResponse)
        .then(msg => {
            return msg;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
                // userconfigs.removeItem("jwt");
                // generateToken();
                // window.location.href = "/login";
            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}

function syncGoogleContact() {

    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };

    return axios.get(`${myconfig.apiURL}contact/syncGoogleContacts`, axiosRequestOptions)
        .then(handleResponse)
        .then(msg => {
            return msg;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}

function upgradePlan(planData) {
    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };

    let data = {
        plan: 'business',
        validTill: 'month'
    }

    return axios.post(`${myconfig.apiURL}upgradeAccount`, data, axiosRequestOptions)
        .then(handleResponse)
        .then(msg => {
            return msg;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
                // userconfigs.removeItem("jwt");
                // generateToken();
                // window.location.href = "/login";
            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}


function gContactAuthURL() {

    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };


    return axios.get(`${myconfig.apiURL}contact/gContactAuthURL`, axiosRequestOptions)
        .then(handleResponse)
        .then(msg => {
            return msg;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
                // userconfigs.removeItem("jwt");
                // generateToken();
                // window.location.href = "/login";
            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}

function removeGoogleDriveAuth() {

    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };


    return axios.get(`${myconfig.apiURL}removeGoogleDriveAuth`, axiosRequestOptions)
        .then(handleResponse)
        .then(msg => {
            return msg;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
                // userconfigs.removeItem("jwt");
                // generateToken();
                // window.location.href = "/login";
            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}

function createUserAccount(userData) {


    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };

    const userAccountData = {
        userData
    }

    //myconfig.apiURL = 'http://localhost:3001/';

    return axios.post(`${myconfig.apiURL}Setting/import/contacts/add-manually`, userAccountData, axiosRequestOptions)
        .then(handleResponse)
        .then(msg => {
            return msg;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}

function editUserAccount(userData, uuid) {


    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };

    const userAccountData = {
        userData
    }

    //myconfig.apiURL = 'http://localhost:3001/';

    return axios.post(`${myconfig.apiURL}import/contacts/edit-contact/${uuid}`, userAccountData, axiosRequestOptions)
        .then(handleResponse)
        .then(msg => {
            return msg;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925) ) {
                handleJWTError();
                // userconfigs.removeItem("jwt");
                // generateToken();
                // window.location.href = "/login";
            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}

function getCsrf() {

    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };
    const csrf = userconfigs.getItem('csrf');
    if(csrf) axiosRequestOptions.params = { csrf }

    return axios.get(`${myconfig.apiURL}login`, axiosRequestOptions)
        .then(handleResponse)
        .then(msg => {
            userconfigs.setItem('csrf', msg.csrfToken);
            
            return msg;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}

function handleResponse(response) {
    //console.log("responseeeeeeeeeeeeee", response.data.result.token)
    //return response.text().then(text => {
    //const data = text && JSON.parse(text);
    if (response.status !== 200) {
        if (response.status === 401) {
            // auto logout if 401 response returned from api
            logout();
            //location.reload();
        }

        const error = (response && response.message) || response.statusText;
        return Promise.reject(error);
    }



    return response.data.result;
    //});
}

function handleResponseLogout(response) {
    //console.log("responseeeeeeeeeeeeee", response.data.result.token)
    //return response.text().then(text => {
    //const data = text && JSON.parse(text);
    if (response.status !== 200) {
        // if (response.status === 401) {
        //     // auto logout if 401 response returned from api
        //     logout();
        //     //location.reload();
        // }

        const error = (response && response.message) || response.statusText;
        return Promise.reject(error);
    }



    return response.data.result;
    //});
}



function postData(formData, apiPath) {
    let axiosRequestOptions = {
        headers: {
            "Content-Type": "application/json",
            Authorization: userconfigs.getItem("jwt"),
        },
    };


    return axios
        .post(`${myconfig.apiURL}${apiPath}`, formData, axiosRequestOptions)
        .then(handleResponse)
        .then((resp) => {
            return resp;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
                // userconfigs.removeItem("jwt");
                // generateToken();
                // window.location.href = "/login";
            } else {
                return Promise.reject(errorCheck(error));
            }
            //return error.response.data.error;
        });
}

function postFormData(formData, apiPath) {
    let axiosRequestOptions = {
        headers: {
            "Content-Type": "multipart/form-data",
            Authorization: userconfigs.getItem("jwt"),
        },
    };


    return axios
        .post(`${myconfig.apiURL}${apiPath}`, formData, axiosRequestOptions)
        .then(handleResponse)
        .then((resp) => {
            return resp;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}

function getSingleUserById(id) {
    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };

    return axios.get(`${myconfig.apiURL}admin/getAdminByID/${id}`, axiosRequestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        })
        .catch((error) => {
            return false;
        });
}

function deleteAdminUser(id) {

    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),

        }
    };

    let postData = {
        adminId: id
    }

    return axios.post(`${myconfig.apiURL}admin/delete`, postData, axiosRequestOptions)
        .then(handleResponse)
        .then(msg => {
            return msg;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
                // userconfigs.removeItem("jwt");
                // generateToken();
                // window.location.href = "/";
            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}

function checkValidation(formData) {
    let errors = formData.errors;
    let num = 0;
    Object.keys(errors).forEach(function (key) {
        if (typeof formData.formData[key] === "string" && formData.formData[key].trim() === "") {
            errors[key] = true;
            num++;
        } else {
            errors[key] = false;
        }
    });

    if (num === 0) { return false; } else { return errors; }
}
function handleGetList(url) {
    let axiosRequestOptions = {
        headers: {
            "Content-Type": "application/json",
            Authorization: userconfigs.getItem("jwt"),
        },
    };

    return axios
        .get(`${myconfig.apiURL}${url}`, axiosRequestOptions)
        .then(handleResponse)
        .then((dataResponse) => {
            return dataResponse
        })
        .catch((error) => {
            return false;
        });
}

function getHostUserDetails(url) {
    let axiosRequestOptions = {
        headers: {
            "Content-Type": "application/json",
            Authorization: userconfigs.getItem("jwt"),
        },
    };

    return axios.get(`${myconfig.apiURL}${url}`, axiosRequestOptions)
        .then(handleResponse)
        .then((dataResponse) => {
            return dataResponse

        })
        .catch((error) => {
            return false;
        });
}
function getHostUserByType(url, type = 'admin') {
    var data = JSON.stringify({ 'getBy': type });
    let axiosRequestOptions = {
        headers: {
            "Content-Type": "application/json",
            Authorization: userconfigs.getItem("jwt"),
            data: type
        },
    };

    return axios.get(`${myconfig.apiURL}${url}`, axiosRequestOptions)
        .then(handleResponse)
        .then((dataResponse) => {
            return dataResponse
        })
        .catch((error) => {
            return false;
        });
}


function checkEmailVerified(postData) {

    let axiosRequestOptions = {
        headers: {
            "Content-Type": "application/json",
            Authorization: userconfigs.getItem("jwt"),
        },
    };

    return axios.post(`${myconfig.apiURL}checkEmailVerifiedOrNot`, postData, axiosRequestOptions)
        .then(handleResponse)
        .then(resp => {
            return resp;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}


function getFormData(meetId) {

    let axiosRequestOptions = {
        headers: {
            "Content-Type": "application/json",
            Authorization: userconfigs.getItem("jwt"),
        },
    };

    const postData = { meetingId: meetId }

    return axios.post(`${myconfig.apiURL}meeting-registration-form`, postData, axiosRequestOptions)
        .then(handleResponse)
        .then(resp => {
            return resp;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}

function submitMeetRegForm(postData) {

    let axiosRequestOptions = {
        headers: {
            "Content-Type": "application/json",
            Authorization: userconfigs.getItem("jwt"),
        },
    };

    return axios.post(`${myconfig.apiURL}saveRegisterMeetingform `, postData, axiosRequestOptions)
        .then(handleResponse)
        .then(resp => {
            return resp;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}