import React from 'react'
import { Formik } from 'formik';
import { Col, Row, Form } from 'react-bootstrap'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as Yup from 'yup';
import Modal from '../layoutSection/Modal'
import { InputFiled } from '../Common/authFile';
import { AiOutlineCalendar } from 'react-icons/ai';

const today = new Date()

function spacify(str) {
  if (!str) {
    return "";
  }
  var result = str;
  if (str.length > 3 && !str.includes('-')) {
    result = `${result.substr(0, 3)}-${str.substr(3)}`
  }
  if (str.length === 9) {
    result = `${result.substr(0, 8)}-${str.substr(8)}`
  }
  return result;
}

const getMaxDate = (formdate) => {
  if (!formdate) {
    return today.getTime()
  }
  let maxDate = new Date(formdate).setDate(new Date(formdate).getDate() + 30)
  if (maxDate >= today.getTime()) {
    return today.getTime();
  }
  return maxDate;
}
const getMeetId = (value, setFieldValue, event) => {
  setFieldValue('meetId', spacify(value))
}

const validation = Yup.object().shape({
  title: Yup.string().matches(/^[a-zA-Z0-9 \s]+$/, "Please enter valid meeting title"),
  meetId: Yup.string().matches(/^[0-9-\s]+$/, "Please enter valid meeting id"),
})

const FilterModel = (props) => {
  const { show, toggle, initialValues, setInitialValues } = props
  return (
    <Modal show={show} toggle={toggle}
      label={<h6 className='welcomeMsg'>Search :<span className='text-muted ml-1'>What you are looking for?</span> </h6>}
      centered
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(value) => setInitialValues(value)}
        validationSchema={validation}
      >
        {
          ({ values, setFieldValue, handleSubmit }) => (
            <>
              <Row>
                <Col>
                  <InputFiled
                    name="title"
                    type="text"
                    label="Meeting Title"
                    className="form-control"
                    labelClassName="welcomeMsg"
                  />
                </Col>
                <Col>
                  <InputFiled
                    name="meetId"
                    type="text"
                    label="Meeting ID"
                    className="form-control"
                    labelClassName="welcomeMsg"
                    maxLength={12}
                    value={values.meetId}
                    onChange={(event) => getMeetId(event.target.value, setFieldValue, event)}
                  />
                </Col>
              </Row>
              <Row>
                <Col className=' filterReports'>
                  <div className='welcomeMsg f_size_14 ml-1 font-weight-light labelOpacity'>Form Date</div>
                  <div className='position-relative'>
                    <DatePicker
                      selected={values.fromDate}
                      onChange={(value) => {
                        setFieldValue('fromDate', value.getTime())
                        setFieldValue('toDate', getMaxDate(value))
                      }}
                      dateFormat="dd-MM-yyyy"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="Select...."
                      maxDate={new Date()}
                      placeholderText="Form Date"
                      locale="en"
                      className={"form-control inputColorTheme"}
                    />
                    <AiOutlineCalendar className='iconInput  f_size_17 welcomeMsg' />
                  </div>
                </Col>
                <Col className=' filterReports'>
                  <div className='welcomeMsg  ml-1 font-weight-light labelOpacity'>To Date</div>
                  <div className='position-relative'>
                    <DatePicker
                      selected={values.toDate}
                      onChange={(value) => setFieldValue('toDate', value.getTime())}
                      dateFormat="dd-MM-yyyy"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="Select...."
                      minDate={values.fromDate}
                      maxDate={getMaxDate(values.fromDate)}
                      placeholderText="To Date"
                      locale="en"
                      className={"form-control inputColorTheme"}
                    />
                    <AiOutlineCalendar className='iconInput  f_size_ welcomeMsg' />
                  </div>
                </Col>
              </Row>
              <div className='text-center mt-3'>
                <button className="headerBtn pl-lg-5 pr-lg-5 submitBtnColor text-white border-0 " onClick={() => handleSubmit()}> Search </button>
              </div>
            </>
          )
        }
      </Formik>
    </Modal>
  )
}

export default FilterModel