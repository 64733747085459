/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Row, Col, Button, Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Clock from "react-live-clock";
import moment from "moment";
import { month } from "../Common/constant";
import jwt_decode from "jwt-decode";
import userconfigs from "../helpers/closures";
import { eventActions, alertActions, conferenceActions } from "../Redux/_actions";
import JoinMeeting from "../Meetings/JoinMeeting";
import MeetCard from "./meetCard";
import Spinner from "../components/UI/Spinner/Spinner";
import Layout from "../layoutSection";
import { myconfig } from "../config/config";
import "./styles.scss";
// import Upgradeplansection from "../start/Upgradeplan/Upgradeplansection";
// import {
//   FaVideo,
//   FaUserPlus,
//   FaCalendarCheck,
//   FaRegClock,
// } from "react-icons/fa";

let win = null;

let webUser = null;

try {
  webUser = jwt_decode(userconfigs.getItem("jwt"));
} catch (error) { }

const BtnSectionJson = [
  {
    id: 1,
    label: "Start",
    icon: <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
      <path d="M31.1667 19.25V12.8333C31.1667 11.825 30.3417 11 29.3333 11H7.33333C6.325 11 5.5 11.825 5.5 12.8333V31.1667C5.5 32.175 6.325 33 7.33333 33H29.3333C30.3417 33 31.1667 32.175 31.1667 31.1667V24.75L35.365 28.9483C36.52 30.1033 38.5 29.2783 38.5 27.6467V16.335C38.5 14.7033 36.52 13.8783 35.365 15.0333L31.1667 19.25Z" fill="white" />
    </svg>,
    platform: "live",
  },
  {
    id: 2,
    label: "Join",
    icon: <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
      <path d="M5.49984 11C4.4915 11 3.6665 11.825 3.6665 12.8333V36.6667C3.6665 38.6833 5.3165 40.3333 7.33317 40.3333H31.1665C32.1748 40.3333 32.9998 39.5083 32.9998 38.5C32.9998 37.4917 32.1748 36.6667 31.1665 36.6667H9.1665C8.15817 36.6667 7.33317 35.8417 7.33317 34.8333V12.8333C7.33317 11.825 6.50817 11 5.49984 11ZM36.6665 3.66667H14.6665C12.6498 3.66667 10.9998 5.31667 10.9998 7.33334V29.3333C10.9998 31.35 12.6498 33 14.6665 33H36.6665C38.6832 33 40.3332 31.35 40.3332 29.3333V7.33334C40.3332 5.31667 38.6832 3.66667 36.6665 3.66667ZM32.9998 20.1667H27.4998V25.6667C27.4998 26.675 26.6748 27.5 25.6665 27.5C24.6582 27.5 23.8332 26.675 23.8332 25.6667V20.1667H18.3332C17.3248 20.1667 16.4998 19.3417 16.4998 18.3333C16.4998 17.325 17.3248 16.5 18.3332 16.5H23.8332V11C23.8332 9.99167 24.6582 9.16667 25.6665 9.16667C26.6748 9.16667 27.4998 9.99167 27.4998 11V16.5H32.9998C34.0082 16.5 34.8332 17.325 34.8332 18.3333C34.8332 19.3417 34.0082 20.1667 32.9998 20.1667Z" fill="white" />
    </svg>,
    platform: "live",
  },
  {
    id: 3,
    label: "Schedule",
    icon: <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.01202 5.97867H12.0382V2.66406C12.0382 2.19072 12.4242 1.80469 12.8976 1.80469H14.8346C15.308 1.80469 15.694 2.19072 15.694 2.66406V5.97867H28.3061V2.66406C28.3061 2.19072 28.6921 1.80469 29.1655 1.80469H31.1025C31.5758 1.80469 31.9619 2.19072 31.9619 2.66406V5.97867H37.9881C40.3054 5.97867 42.1954 7.8687 42.1954 10.186V12.8818H1.80469V10.186C1.80469 7.8687 3.69471 5.97867 6.01202 5.97867ZM42.1953 14.9444V37.988C42.1953 40.3053 40.3053 42.1953 37.988 42.1953H6.01202C3.69471 42.1953 1.80469 40.3053 1.80469 37.988V14.9444H42.1953ZM8.53162 19.9732V25.1922H13.7506V19.9732H8.53162ZM8.53162 30.9162V36.1353H13.7506V30.9162H8.53162ZM30.2494 30.9162V36.1353H35.4685V30.9162H30.2494ZM19.3905 30.9162V36.1353H24.6095V30.9162H19.3905ZM30.2494 19.9732V25.1922H35.4685V19.9732H30.2494ZM19.3905 19.9732V25.1922H24.6095V19.9732H19.3905Z" fill="white" />
    </svg>,
    path: "/schedule_meeting",
    platform: "live",
  },
  {
    id: 4,
    label: "Recordings",
    icon: <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
      <path d="M30.4148 16.5H27.4998V7.33333C27.4998 6.325 26.6748 5.5 25.6665 5.5H18.3332C17.3248 5.5 16.4998 6.325 16.4998 7.33333V16.5H13.5848C11.9532 16.5 11.1282 18.48 12.2832 19.635L20.6982 28.05C21.4132 28.765 22.5682 28.765 23.2832 28.05L31.6982 19.635C32.8532 18.48 32.0465 16.5 30.4148 16.5ZM9.1665 34.8333C9.1665 35.8417 9.9915 36.6667 10.9998 36.6667H32.9998C34.0082 36.6667 34.8332 35.8417 34.8332 34.8333C34.8332 33.825 34.0082 33 32.9998 33H10.9998C9.9915 33 9.1665 33.825 9.1665 34.8333Z" fill="white" />
    </svg>,
    path: "/reports",
    platform: "live",
  },
];

const filterList = (data) => {
  const plateForm = myconfig.env
  const result = data?.filter(item => {
    if (item?.platform) {
      if (!plateForm || plateForm === 'dev') {
        return item
      }
      else if ((plateForm === 'beta' && item?.platform === 'beta') || item?.platform === 'live') {
        return item
      } else if (plateForm === 'live' && item?.platform === 'live') {
        return item
      }

    } else {
      return item
    }
  })
  return result;
}

/* const CardSection = ({ data }) => {
  return (
    <Col lg={4} md={4} sm={4} className="customColumn">
      <Card className={`customCard  ${data.bgColorClass} `}>
        <div className="imgContainer">
          <img src={data.image} className="cardImage" alt="card" />
        </div>
        <div className="cardText">
          <h6>{data.label}</h6>
          <h4>{data.count}</h4>
        </div>
      </Card>
    </Col>
  );
}; */

const BtnSection = (data, history, setShowModal, dispatchRedux, loading) => {
  const [showLoader, setShowLoader] = useState(false);
  return (
    <div
      className="thumb-btn"
      key={`btnSection-${data.id}`}
      onClick={() => {
        if (data.path) {
          history.push(data.path);
        } else if (data.id === 2) {
          setShowModal(true);
        } else {
          setShowLoader(true);
          const currentDate = new Date();
          let formData = {
            startUTCTime: moment.utc().valueOf(),
            meetName: `Quick meeting ${currentDate.getDate()}${month[currentDate.getMonth()]
              }${currentDate.getFullYear()}`,
            room_type: false,
            participant: null,
            join_password: false,
            pwd: null,
          };
          dispatchRedux(
            conferenceActions.quickSubmitCreateConferenceForm(formData)
          );
        }
      }}
    >
      <div className={data.id === 1 ? 'thumb-btn-icon green' : "thumb-btn-icon"}>
        {(data.id === 1 && showLoader) ? <img alt="logo" src={process.env.PUBLIC_URL + "/images/loading.svg"} width="30" /> : data.icon}
      </div>

      <span className="thumb-btn-heading">
        {data.id === 1 && loading ? "Loading..." : data.label}
      </span>
    </div>
  );
};

const MeetingSection = () => {
  // const { count } = useSelector((state) => state.accountsInfo);
  const {
    location,
    meetingHistory,
    /* eventLimit,
    loading,
    gAuthLoading,
    syncLoading,
    event, */
    gAuthURL,
  } = useSelector((state) => state.event);
  const conference = useSelector((state) => state.conference);
  const dispatchRedux = useDispatch();
  const history = useHistory();
  const [state, dispatch] = useState({
    googleToken: null,
    reRender: false,
    google_token: false,
    gAuthURL: null,
  });
  const [showModal, setShowModal] = useState(false);
  // const [updatePlan, setUpdatePlan] = useState(true);

  const setState = (name, value) => {
    dispatch({ ...state, [name]: value });
  };


  useEffect(() => {
    const googleTo = userconfigs.getItem("GT");
    const googleAuthTime = userconfigs.getItem("GTT");

    setState("googleToken", googleTo);

    if (googleTo && googleAuthTime) {
      const authInterval = setInterval(() => {
        let lastAuthTime = new Date().getTime() - 1000 * 60 * 50; // 10 Min before time
        if (lastAuthTime > parseInt(googleAuthTime)) {
          setTimeout(() => {
            userconfigs.removeItem("GT");
            userconfigs.removeItem("GTT");
          }, 10000);
          dispatch({ ...state, googleToken: null, reRender: !state.reRender });
          clearInterval(authInterval);
        }
      }, 5000);
    }

    let hasValue = location?.hash;
    let hasAccess = hasValue?.indexOf("access_token=");
    let checkToken = userconfigs.getItem("GT") || null;
    if (hasAccess > -1) {
      let tokenSplit = hasValue.split("access_token=");
      let tokenVal = tokenSplit[1].split("&");
      tokenVal = tokenVal[0];
      userconfigs.setItem("GT", tokenVal);
      userconfigs.setItem("GTT", new Date().getTime());
      setState("google_token", tokenVal);
      if (win != null) {
        win.close();
      } else {
        window.open("", "_self").close();
      }
    } else if (checkToken === null) {
      setState("google_token", false);
    }

    dispatchRedux(eventActions.meetingHistoryList({ type: 'all', from: null, to: null, skip: 0, limit: 4 }));

    dispatchRedux(eventActions.gAuthURL());
    // dispatchRedux(eventActions.limitEventList(3));
    // dispatchRedux(accountActions.meetingsCount());
  }, [dispatchRedux]);

  const openGoogleAuth = () => {
    if (gAuthURL !== null) {
      if (typeof win === undefined) {
        let win;
      }
      if (win && !win.closed) {
        win.focus();
      } else {
        let x = window.screen.width / 2 - 550 / 2;
        //let y = 0;
        let REDIRECT = "meetings";
        let y = window.screen.height / 2 - 280 / 2;
        win = window.open(
          gAuthURL,
          "win",
          "width=550,height=560,left=" +
          x +
          ",top=" +
          y +
          ",resizable=1,menubar=yes"
        );
        win.focus();
        let self = this;
        var pollTimer = window.setInterval(function () {
          try {
            //console.log(win.document.URL);
            if (win.document.URL.indexOf(REDIRECT) != -1) {
              window.clearInterval(pollTimer);
              var url = win.document.URL;
              let gCode = gup(url, "code");
              //let tokenType = gup(url, 'token_type');
              //let expiresIn = gup(url, 'expires_in');
              win.close();
              if (
                gCode !== null &&
                gCode !== "null" &&
                gCode !== "undefined" &&
                typeof gCode !== undefined
              ) {
                dispatchRedux(eventActions.gAuthToken(gCode));
              }
            }
          } catch (e) {
            if (win.closed) {
              window.clearInterval(pollTimer);
              dispatchRedux(alertActions.error("Authorization Failed !"));
            }
          }
        }, 500);

        function gup(url, name) {
          name = name.replace(/[[]/, "[").replace(/[]]/, "]");
          var regexS = "[?&]" + name + "=([^&#]*)";
          var regex = new RegExp(regexS);
          var results = regex.exec(url);
          if (results == null) return "";
          else return results[1];
        }
      }
    }
  };

  const BtnGoogle = (data) => {
    if (data?.googleCalendarLink && typeof gAuthURL === "object") {
      return (
        <Button
          rel="noopener noreferrer"
          className=" btnSectionCard auth sideBarIcon welcomeMsg"
          href={data.googleCalendarLink}
          target="_blank"
        >
          Google Calendar
        </Button>
      );
    } else if (typeof gAuthURL === "object") {
      return (
        <Button
          className=" btnSectionCard auth sideBarIcon welcomeMsg"
          onClick={() =>
            dispatchRedux(eventActions.addEventOnGoogle(data?.meetingID))
          }
        >
          Add Event on Google
        </Button>
      );
    } else {
      return (
        <Button
          className=" btnSectionCard auth sideBarIcon welcomeMsg"
          onClick={() => openGoogleAuth()}
        >
          Auth Google
        </Button>
      );
    }
  };

  // const cardsJson = [
  //   {
  //     id: 1,
  //     label: "Total Meetings",
  //     image: "../images/total-meeting-card.svg",
  //     count: count?.totalMeet,
  //     bgColorClass: "TotalMeet",
  //   },
  //   {
  //     id: 2,
  //     label: "Webinar",
  //     image: "../images/webinar.png",
  //     count: count?.webinarCount,
  //     bgColorClass: "Webinar",
  //   },
  //   {
  //     id: 3,
  //     label: "Schedule Meetings",
  //     image: "../images/scheduleMeet.png",
  //     count: count?.scheduleMeetCount,
  //     bgColorClass: "ScheduleMeet",
  //   },
  // ];

  const toggleModal = () => setShowModal(!showModal);

  const PostSection = () => {
    // if (loading || gAuthLoading || syncLoading) {
    //   return (
    //     <div className="text-center">
    //       <Spinner />
    //     </div>
    //   );
    // } else
    if (meetingHistory?.length < 1) {
      return (
        <div className="text-center mt-5">
          <img src="../../images/noMeet.svg" alt="no Meet" className="w-75" />
          <h3 className="noMeets mt-3"> No Meeting records available! </h3>
        </div>
      );
    } else {
      return meetingHistory.map((data) => MeetCard(data, BtnGoogle));
    }
  };


  return (
    <Layout active="Home">
      <>
        {(conference.loading || conference.startMeetingLoading) && (
          <Modal
            show={conference.loading || conference.startMeetingLoading}
            contentClassName="modalLoading"
          >
            {" "}
            <Spinner />{" "}
          </Modal>
        )}
        {showModal && (
          <JoinMeeting
            isOpen={showModal}
            message="Join Your Meeting"
            updatePopUpState={() => toggleModal()}
            successHandler={() => toggleModal()}
            errorHandler={() => toggleModal()}
            history={history}
          />
        )}

        {/* {webUser &&
          webUser.plan_data &&
          webUser.plan_data.plan_type === "Basic" &&
          updatePlan && (
            <Upgradeplansection
              cancelPlanSection={() => setUpdatePlan(false)}
            />
          )} */}

        <div className="container-fluid MeetingContainer mt-3 dsdsssdsd">
          {/* <Row className="justify-content-around">
            {cardsJson.map((data) => (
              <CardSection data={data} />
            ))}
          </Row> */}

          <Row>
            <Col lg={6} className="text-center pt-100-lg">
              <div className="lh-40 dashobard-clock">
                <Clock
                  format={"h:mm A"}
                  ticking={true}
                  timezone={"Asia/Kolkata"}
                  className="clockSection welcomeMsg"
                />
                <div className="clock-day welcomeMsg">{moment().format('dddd')}, {new Date().getDate()} {moment().format('MMMM')} {moment().year()}</div>
              </div>
              <div className="thumb-btn-box m-auto">
                <div className="thumb-btn-block">
                  {filterList(BtnSectionJson).map((data) =>
                    BtnSection(
                      data,
                      history,
                      setShowModal,
                      dispatchRedux,
                      conference.loading
                    )
                  )}
                </div>
              </div>
            </Col>
            <Col lg={6} className="upcomingMeetContainer meet-list-block meetings-right">
              <div className="d-flex  justify-content-between align-items-center">
                <div className="d-flex align-items-end">
                  <h4 className="welcomeMsg meet-title upcomingMeetText">
                    {" "}
                    Meetings{" "}
                  </h4>
                </div>
              </div>
              <PostSection />
              <div className="text-center pt-4" onClick={() => window.location.href = '/meeting_history'}>
                <span className="text-center text-bdr-btn">View all</span>
              </div>
            </Col>

          </Row>
        </div>
      </>
    </Layout>
  );
};

export default MeetingSection;
