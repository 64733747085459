import React/* , { useState } */ from 'react';
import { Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Col, Row } from 'reactstrap';
import './ModuleForm.scss';


const CustomPermission = (props) => {
  // const [listData, setlistData] = useState(false);

  // const listShowHandler = () => setlistData(true);


  // const changeModulePermission = (name, p, index) => {
  //   console.log(name)
  // }


  return (
    <Modal isOpen={props.modal} toggle={props.showModal} className="CustomPermissionModal">
      <ModalHeader toggle={props.showModal}>Custom Permission</ModalHeader>
      <Form>
        <ModalBody className="CustomModalBody">

          <Row>
            {props.modulesPermissions.map((module, index) => (
              <Col sm="6">
                <FormGroup className="form-col">
                  <Row>
                    <Col sm="6"><Label className="title-label">{module.name}</Label></Col>
                    <Col sm="6" className="text-right">
                      <input type="checkbox" checked={module.permission} id="exampleCustomSwitch" onChange={() => props.changeModulePermission(module.name, module.permission, index)} name="module_permission" />
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            ))}

            <Col sm="12" className="text-center mt-3">
              <Button color="primary" className="btn-theme-primary" onClick={props.showModal}>Create</Button>
            </Col>

          </Row>
        </ModalBody>

      </Form>
    </Modal>

  );
}

export default CustomPermission;