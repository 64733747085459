import React from "react";
import { getDate } from "../../Common/constant";
import { /* tsToOnlyDate, */ tsToTime } from "../../helpers/helpers";
// import PropTypes from "prop-types";
// import "./styles.scss";
// import Spinner from "../../components/UI/Spinner/Spinner";

const RightBarTab = (props) => {
  const { webinarInfo, webinarID } = props;
  // let brandData = webinarInfo?.brandData;
  const CardSection = ({ data, index }) => {
    return (
      <>
        <div className="welcomeMsg">
          <img
            src={data.p}
            className="img-fluid card-img"
            style={{ width: "68px", height: "68px" }}
          />
        </div>
        <div className="welcomeMsg"> {data.n} </div>
        <div className="welcomeMsg dgns-text"> {data.d} </div>
        <div
          className="welcomeMsg bio-text"
          style={{ "white-space": "pre-wrap", "overflow-wrap": "break-word" }}
        >
          {" "}
          {data?.b}{" "}
        </div>
        {/* <div className="welcomeMsg"> {data?.mob} </div> */}
      </>
    );
  };
  const SpeakerList = () => {
    if (webinarInfo?.host?.length > 0) {
      return webinarInfo.host.map((data, index) => (
        <div
          key={`speakers-${index + 1}`}
          className="welcomeMsg col-lg-4 col-sm col-6"
        >
          <div
            className="card my-2 mx-3 pr-cardb welcomeMsg"
            style={{ width: "141.67px", height: "170px" }}
          >
            <div className="card-body text-center detailsSection sideBar py-2 welcomeMsg">
              {" "}
              <CardSection data={data} index={index} />{" "}
            </div>
          </div>
        </div>
      ));
    } else {
      return (
        <div className="row w-100 d-flex justify-content-center align-items-center">
          {" "}
          <div className="text-center mt-4 mb-4">
            <img src="../../images/Speaker.png" alt="no speaker" width={400} />
            <div className="resultNotFound  bg-transparent  f_size_32">
              No Speakers Available{" "}
            </div>{" "}
          </div>{" "}
        </div>
      );
    }
  };
  const RightBar = () => {
    let bannerImage = null;
    let start_date = webinarInfo?.startTS;
    let start_time = webinarInfo?.startTS;
    let end_time = webinarInfo?.endTS;

    let  startDate = new Date(start_time);
    let startTime = new Date(start_time);
    let endTime = new Date(end_time);
    if (webinarInfo?.brandData?.brand_banner) {
      bannerImage = {
        backgroundImage: `url(${webinarInfo.brandData.brand_banner})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      };
    } else {
      bannerImage = {
        backgroundImage: `url(${
          process.env.PUBLIC_URL + "/images/reg-bg-img.png"
        })`,
      };
    }
    return (
      <div className="col-lg-6 bg-cls-img h-100 pb-5" style={bannerImage}>
        <div className="row pt-5">
          <div className="col-lg-3 mb-2 col-sm col-6">
            <p className="lgn-label font-weight-light">Meeting Name</p>
            <p className="lgn-text font-weight-light">{webinarInfo?.name}</p>
          </div>
          <div className="col-lg-3 mb-2 col-sm col-6">
            <p className="lgn-label font-weight-light">Meeting ID</p>
            <p className="lgn-text font-weight-light">{webinarID}</p>
          </div>

          <div className="col-lg-6 mb-2 col-sm col-12">
            <div className="row">
              <div className="col-lg-6 text-left col-sm col-6">
                <p className="lgn-text font-weight-light border-right border-secondary">
                  <i className="far fa-calendar pr-2"></i>
                  {getDate(startDate)}
                </p>
                <p className="lgn-text font-weight-light border-right border-secondary">
                  <i className="far fa-clock pr-2"></i>
                  {tsToTime(new Date(startTime).getTime())}
                </p>
              </div>

              <div className="col-lg-6  text-left col-sm col-6">
                <p className="lgn-text font-weight-light">
                  <i className="far fa-calendar pr-2"></i>
                  {getDate(startDate)}
                </p>
                <p className="lgn-text font-weight-light">
                  <i className="far fa-clock pr-2"></i>
                  {tsToTime(new Date(endTime).getTime())}
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-12 mb-2">
            <p className="lgn-label font-weight-light">Host Name</p>
            <p className="lgn-text font-weight-light">
              {webinarInfo?.moderatorName ? webinarInfo.moderatorName : "admin"}
            </p>
          </div>
          <div className="col-lg-12 mb-2">
            <p className="lgn-label font-weight-light">Description</p>
            <p className="lgn-text font-weight-light">
              {webinarInfo?.description}
            </p>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-lg-12">
            <h4 className="text-white text-center mb-4">Speakers</h4>
          </div>
        </div>
        <div className="row d-flex justify-content-center align-items-center pb-5 mb-5">
          <SpeakerList />
        </div>
      </div>
    );
  };

  return <RightBar />;
};

export default RightBarTab;
