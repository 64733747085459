import { notificationConstants } from '../_constants';

const initialState = {
    downloadRecording: false
 };

export function notification(state = initialState, action) {
    switch (action.type) {
        case notificationConstants.LONG_POLLING_REQUEST:
            return {
                ...state
            };
        case notificationConstants.LONG_POLLING_SUCCESS:
            let resp = action.resp;
            let setDRecord  = false;
            if(resp.length){
                resp.map(item => {
                  if(item.event === 'mp4Recording'){
                      showNotif(item.mId);
                      setDRecord = true
                  }
                })
            }
            return {
                ...state,
                downloadRecording: setDRecord
            };
        case notificationConstants.LONG_POLLING_FAILURE:
            return {
                ...state
            };
        default:
            return state
    }
}


function showNotif(item) {
    const notification = new Notification(`Wave`, {
      body: `Recording available for meeting Id ${item}`,
      // icon: "/images/wave-one-line.png"
    })
  }