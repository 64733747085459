import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Avatar from 'react-avatar';
import { webinarRoomActions } from '../../../../Redux/_actions';
import LoadingSpinner from '../../../../components/UI/Spinner/Spinner';
import {SSE} from '../../../../helpers/sse'
import userconfigs from '../../../../helpers/closures';
import {getServerURL} from '../../../../helpers/helpers';
const currentURL = getServerURL();
let speakerInterval = null;
let isRuning = false
let source = null;

let moderatorSSEStart = true;

class Speakers extends Component {

	constructor(props){
		super(props);

		this.state = {
      webinarID : this.props.webinarID,
      token : this.props.token,
      activeTab : this.props.activeTab,
      showLoader : true,
      speakerListArr : []
      //isRuning : false
		}
	}

  componentDidMount=() => {
    // this.props.speakerListFun(this.props.webinarID)
    // speakerInterval = setInterval(()=>{
    //   //console.log('aaaaaaaaaas', prevProps.activeTab, this.props.activeTab)
    //     this.props.speakerListFun(this.props.webinarID)
    // }, 5000)
    //if(this.props.activeTab === '0'){
      this.executeSSEProcess(this.props.webinarID)
    //}

    // let self = this;
    // let payload = [];
    // let url = `${currentURL.apiURL}webinar/speakerListSSE`;
    // let requestJson = { "wid" : this.props.webinarID };

    // source = new SSE(url, {
    //     headers: {
    //       'Content-Type': 'application/json',
    //       'Authorization': userconfigs.getItem('jwt'),
    //     },
    //   payload: JSON.stringify(requestJson),
    //   method: 'POST'
    // });

    // source.addEventListener('message', function(e) {

    //   self.setState({'showLoader' : false});

    //   payload = JSON.parse(e.data);
    //   if(payload.length > 0){
    //     let speakerListOldArr = self.state.speakerListArr;
    //     let updateSpeakerListObj = [...speakerListOldArr, ...payload];
    //     // var objDiv = document.getElementById("speakerList");
    //     // objDiv.scrollTop = objDiv.scrollHeight;
    //     self.setState({'speakerListArr' : updateSpeakerListObj});

    //   }
    // });
    // source.stream();
  }

	componentDidUpdate(prevProps, prevState){

      if(prevProps.speakerList !== this.props.speakerList){
       this.setState({
         showLoader:false
       })
      } 

      if(prevProps.webinarID !== this.props.webinarID){
        this.props.speakerListFun(this.props.webinarID)
      }

      if(prevProps.activeTab !== this.props.activeTab){
        
        if(this.props.activeTab==="1"){
          //console.log('aaaaaaaaaas')
          //this.setState({isRuning : true});
          //this.props.speakerListFun(this.props.webinarID)
          if(isRuning === false){
            isRuning = true;
            // this.props.speakerListFun(this.props.webinarID)
            // speakerInterval = setInterval(()=>{
            //   //console.log('aaaaaaaaaas', prevProps.activeTab, this.props.activeTab)
            //     this.props.speakerListFun(this.props.webinarID)
            // }, 5000) 

            this.executeSSEProcess(this.props.webinarID);
          }
        }else
        {
          this.setState({'showLoader' : true});
          if(source!==null){
            //console.log("Closing Speakers Connetions")
            source.close();
          }

          isRuning = false;
          clearInterval(speakerInterval)
        
        }
      }
      else if(this.props.activeTab==='0' && moderatorSSEStart === true){
        moderatorSSEStart = false;
        //console.log(this.props.webinarID)
        //this.executeSSEProcess(this.props.webinarID)
      }

  }

  executeSSEProcess = (wid) => {

    let self = this;
    let payload = [];

    self.setState({'speakerListArr' : []});

    let url = `${currentURL.apiURL}webinar/speakerListSSE`;
    let requestJson = { "wid" : wid };

    source = new SSE(url, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': userconfigs.getItem('jwt'),
        },
      payload: JSON.stringify(requestJson),
      method: 'POST'
    });

    source.addEventListener('message', function(e) {

      self.setState({'showLoader' : false});

      payload = JSON.parse(e.data);
      if(payload.length > 0){
        let speakerListOldArr = self.state.speakerListArr;
        let updateSpeakerListObj = [...speakerListOldArr, ...payload];
        // var objDiv = document.getElementById("speakerList");
        // objDiv.scrollTop = objDiv.scrollHeight;
        self.setState({'speakerListArr' : updateSpeakerListObj});

      }
    });
    source.stream();
  }


  componentWillUnmount = () =>{
    isRuning = false;
    if(source!==null){
      source.close();
    }
  }


	render(){

    const { speakerListLoading, speakerList } = this.props;
    const { showLoader, speakerListArr } = this.state;

    //console.log('sssssssss',speakerListArr)
    let speakersData = null;
		//if(speakerListLoading && showLoader){
    if(showLoader){
      return(<LoadingSpinner />)
    }else
    if(speakerListArr.length > 0)
    {

      speakersData = speakerListArr.map( speaker => {

        let mdValue = speaker.value ? speaker.value : null;
        let avatarIcon = (<Avatar name={speaker.name} size="48" round="5px" />);
        if(mdValue){
          let gravatarImage = "http://www.gravatar.com/avatar/"+mdValue+"?d=404";
          avatarIcon = (<Avatar src={gravatarImage} name={speaker.name} size="48" round="5px" />)
        }
        
        let images = (<span className="speaker_avtar">{avatarIcon}</span>);
        if(speaker.profilePic){
            images = (<img src={speaker.profilePic} alt={speaker.name} />);
        }
        let uniqueKey = "speaker_"+speaker._id;
        return(
              <li key={uniqueKey}>
                  <div className="host_image">
                      {images}
                  </div>
                  <div className="host_detail">
                       <div className="host_name">{speaker.name}</div>
                       { speaker.designation ? <p className="host_position">{speaker.designation}</p> : null }
                  </div>
                 
                  { speaker.bio ? <p className="host_description">{speaker.bio}</p> : null }
              </li>
        )

      })


  		return(
  			<div className="tab-pane active " id="host" role="tabpanel" aria-labelledby="host-tab">
                <div className="host_list scrollbar">
                    <ul id="speakerList">
                        {speakersData}
                    </ul>
                </div>
             </div>
  		)
    }else{
      return( <div className="no_data_found_webinar">No speaker has been joined yet!</div>);
    }

	}

};


const stateToProps = (state) => {
  return {
    speakerList : state.webinarRoom.speakerList,
    speakerListLoading : state.webinarRoom.speakerListLoading
  }
}

const actionCreator = {
  speakerListFun : webinarRoomActions.speakerList
}

export default withRouter(connect(stateToProps, actionCreator)(Speakers));