import { teamConstants } from './../_constants';

const initialState = { loadingStatus: false, teamData: [], isTeamAdded: false, team: '' };

export function teamsInfo(state = initialState, action) {
    switch (action.type) {
        case teamConstants.TEAM_GETALL_REQUEST:
            return {
                ...state,
                loadingStatus: true
            }
        case teamConstants.TEAM_GETALL_SUCCESS:
            return {
                ...state,
                loadingStatus: false,
                teamData: action.resp
            }
        case teamConstants.TEAM_GETALL_FAILURE:
            return {
                ...state,
                loadingStatus: false
            }

        case teamConstants.TEAM_ADD_REQUEST:
            return {
                ...state,
                loadingStatus: true,
                isTeamAdded: false
            }
        case teamConstants.TEAM_ADD_SUCCESS:
            return {
                ...state,
                loadingStatus: false,
                isTeamAdded: true
            }
        case teamConstants.TEAM_ADD_FAILURE:
            return {
                ...state,
                loadingStatus: false,
                isTeamAdded: false
            }

        case teamConstants.TEAM_BYID_REQUEST:
            return {
                ...state,
                loadingStatus: true,
            }
        case teamConstants.TEAM_BYID_SUCCESS:
            return {
                ...state,
                loadingStatus: false,
                team:  action.resp
            }
        case teamConstants.TEAM_BYID_FAILURE:
            return {
                ...state,
                loadingStatus: false,
                team: ''
            }    
        default:
            return state
    }
}
