import { downloadRegCSV } from '../../helpers/helpers';
import { reportsConstants } from '../_constants';
import { reportService } from '../_services';
import { alertActions } from './';
//import { history } from '../_helpers';

export const reportActions = {
    getReport,
    getMeetingRecording,
    getRegisterReport
};

function getReport(params) {
    return dispatch => {
        dispatch(request({}));

        reportService.getReportDetails(params)
            .then(
                resp => {
                    dispatch(success(resp));
                },
                error => {
                    console.log(error);
                    if(error === "No more data available for started meeting!!"){
                        return dispatch(failure(error.toString()));
                    }
                    dispatch(alertActions.error(error.toString()));
                    dispatch(failure(error.toString()));
                }
            );
    };
    function request(resp) { return { type: reportsConstants.GET_REPORTS_REQUEST } }
    function success(resp) { return { type: reportsConstants.GET_REPORTS_SUCCESS, resp } }
    function failure(error) { return { type: reportsConstants.GET_REPORTS_FAILURE, error } }
}
function getRegisterReport(params) {
    return dispatch => {
        dispatch(request(params));

        reportService.getReportRegisterDetails(params)
            .then(
                (resp) => {
                    downloadRegCSV(resp);
                    dispatch(success(resp));
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                    dispatch(failure(error.toString()));
                }
            );
    };
    function request(resp) { return { type: reportsConstants.GET_REG_REPORTS_REQUEST, resp } }
    function success(resp) { return { type: reportsConstants.GET_REG_REPORTS_SUCCESS, resp } }
    function failure(error) { return { type: reportsConstants.GET_REG_REPORTS_FAILURE, error } }
}

function getMeetingRecording(params) {
    return dispatch => {
        dispatch(request({}));

        reportService.getMeetingRecording(params)
            .then(
                (resp) => {


                    dispatch(success(resp));
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                    dispatch(failure(error.toString()));
                }
            );
    };
    function request(resp) { return { type: reportsConstants.GET_MEETING_RECORDING_REQUEST } }
    function success(resp) { return { type: reportsConstants.GET_MEETING_RECORDING_SUCCESS, resp } }
    function failure(error) { return { type: reportsConstants.GET_MEETING_RECORDING_FAILURE, error } }
}