import React, { Component } from 'react';
import { CustomInput, Input, Form, FormGroup, Label, Button, Col } from 'reactstrap';
import validator from 'validator';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { companyActions } from './../../Redux/_actions';
import { companyService } from './../../Redux/_services';
import _, {debounce} from 'lodash';


import './style.scss';

class CompanyInfo extends Component {
    constructor() {
        super();
        this.state = {
            companyRegisterForm: {
                company_name: '',
                company_email: '',
                size: '',
                industry_type: '',
                country: '',
            },
            iscompanyRegisterFormValid: false,
            isFormSubmitted: false,
            formError: {},
            isEmailExist: undefined,
            isEmailCheckRequest: false
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
		if(this.props.registerCompanyId !== nextProps.registerCompanyId){
            localStorage.setItem('eOfficeId',nextProps.registerCompanyId)
            this.props.modalCloseHandler();
			this.props.history.push("/eoffice");
		}
		return true;
    }
    
    async handleUserInput(e) {
        const name = e.target.name;
        if(name === 'company_email'){
            this.setState({...this.state, isEmailCheckRequest: true, isEmailExist: undefined});
            this.checkEmailExitsOrNot(e.target.value)
        }
        const value = e.target.value;
        const formData = { ...this.state.companyRegisterForm };
        formData[name] = value;
        await this.setState({ ...this.state.companyRegisterForm, companyRegisterForm: formData });
        await this.vallidateForm();
    }

    checkEmailExitsOrNot = debounce(email => {
        let data = {};
        data.company_email = email;
        companyService.checkCompanyEmail(data).then(res => {
            if(res === 'Valid email'){
                this.setState({...this.state, isEmailExist: false, isEmailCheckRequest: false});
            }else{
                this.setState({...this.state, isEmailExist: true, isEmailCheckRequest: false})
            }
        }).catch(err => {
            console.log(err);
        })
    }, 1000);

    async submitForm(event) {
        event.preventDefault();
        await this.setState({ isFormSubmitted: true });
        await this.vallidateForm();
        if (this.state.iscompanyRegisterFormValid && this.state.isFormSubmitted) {
            this.props.registerCompany(this.state.companyRegisterForm)
        }
    }

    async vallidateForm() {
        if (this.state.isFormSubmitted) {
            let errorObj = {};
            const formData = this.state.companyRegisterForm;
            if (formData.company_name === '') {
                errorObj.company_name = 'Please enter company name';
            }
            if (formData.company_email === '') {
                errorObj.company_email = 'Please enter company email';
            }
            if (formData.company_email !== '' && !validator.isEmail(formData.company_email)) {
                errorObj.company_email = 'Please enter valid email';
            }
            if (formData.size === '') {
                errorObj.size = 'Please select company size';
            }
            if (formData.industry_type === '') {
                errorObj.industry_type = 'Please select industry type';
            }
            if (formData.country === '') {
                errorObj.country = 'Please select country';
            }
            let formValid;
            if (Object.keys(errorObj).length === 0) {
                formValid = true;
            } else {
                formValid = false;
            }

            await this.setState({ formError: errorObj, iscompanyRegisterFormValid: formValid });
        }
    }

    render() {
        return (
            <div className="companyInfo-Container">
            <button className="btn-close" onClick={this.props.modalCloseHandler}><i className="fas fa-times"></i></button>
                <div className="container">
                    <div className="row">

                        <div className="col-md-6">
                            <div className="col-md-12 text-left mb-4 pl-0"><img src="../../public/images/sarv-wave-white-bg.png" width="250" alt="" ></img></div>
                            <h2 className="title">Brief Your Company Information</h2>
                            <p className="sub-title mb-4">You could be a business, an event organizer, or a professional community.
                        Please fill below detail.</p>

                            <Form onSubmit={(event) => this.submitForm(event)}>

                                <FormGroup className="form-col">
                                    <Label className="title-label" >Company Name</Label>
                                    <Input type="text" onChange={(e) => this.handleUserInput(e)} name="company_name" placeholder="Describe company Name" />
                                </FormGroup>
                                {this.state.isFormSubmitted && this.state.formError.hasOwnProperty('company_name') && <div style={{ color: 'red' }}>{this.state.formError.company_name}</div>}


                                <FormGroup className="form-col">
                                    <Label className="title-label" >Email</Label>
                                    <Input type="email" onChange={(e) => this.handleUserInput(e)} name="company_email" placeholder="Enter Company Email Address" />
                                </FormGroup>
                                {this.state.isFormSubmitted && this.state.formError.hasOwnProperty('company_email') && <div style={{ color: 'red' }}>{this.state.formError.company_email}</div>}
                                {this.state.isEmailCheckRequest && <div style={{ color: 'red' }}>Cheking for email...</div>}
                                {this.state.isEmailExist === true && <div style={{ color: 'red' }}>Email has been already taken</div>}
                                {this.state.isEmailExist === false && <div style={{ color: 'green' }}>Valid email!!</div>}


                                <FormGroup className="form-col">
                                    <Label className="title-label">Company Size</Label>
                                    <CustomInput type="select" id="size" onChange={(e) => this.handleUserInput(e)} name="size" >
                                        <option value="">Select company size</option>
                                        <option value="1-50">1-50</option>
                                        <option value="50-200">50-200</option>
                                        <option value="200-500">200-500</option>
                                        <option value="500-1000">500-1000</option>
                                        <option value="1000-5000">1000-5000</option>
                                        <option value="5000-10000">5000-10000</option>
                                        <option value="10000-50000">10000-50000</option>
                                    </CustomInput>
                                </FormGroup>
                                {this.state.isFormSubmitted && this.state.formError.hasOwnProperty('size') && <div style={{ color: 'red' }}>{this.state.formError.size}</div>}


                                <FormGroup className="form-col">
                                    <Label className="title-label">Industry</Label>
                                    <CustomInput type="select" id="industry_type"  onChange={(e) => this.handleUserInput(e)} name="industry_type" >
                                        <option value="">Select Industry Name</option>
                                        <option value="Software Company">Software Company</option>
                                        <option value="AutoMobile">AutoMobile</option>
                                        <option value="Agriculture">Agriculture</option>
                                    </CustomInput>
                                </FormGroup>
                                {this.state.isFormSubmitted && this.state.formError.hasOwnProperty('industry_type') && <div style={{ color: 'red' }}>{this.state.formError.industry_type}</div>}


                                <FormGroup className="form-col">
                                    <Label className="title-label">Country</Label>
                                    <CustomInput type="select" id ="country" onChange={(e) => this.handleUserInput(e)} name="country" >
                                        <option value="">Select Country Name</option>
                                        <option value="India">India</option>
                                        <option value="UK"> UK</option>
                                        <option value="US">US</option>
                                        <option value="France">France</option>
                                    </CustomInput>
                                </FormGroup>
                                {this.state.isFormSubmitted && this.state.formError.hasOwnProperty('country') && <div style={{ color: 'red' }}>{this.state.formError.country}</div>}


                                <Col sm="12" className="text-center mt-4">
                                    <Button className="btn-theme-primary" type="submit">Save</Button>
                                </Col>

                            </Form>
                        </div>
                        <div className="col-md-6 img-rgt-flex">
                            <img src="../../images/eoffice/company-info-img.svg" className="img-fluid mt-5" alt="" ></img>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapState = (state) => {
    const { loadingStatus, registerCompanyId } = state.company;

    return {
        loadingStatus,
        registerCompanyId,
    };
}

const actionCreators = {
    registerCompany: companyActions.registerCompany,
};

export default withRouter(connect(mapState, actionCreators)(CompanyInfo));
