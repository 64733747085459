import React, { useState } from 'react';
import { Row, Col, Table, Button,  Popover,  PopoverBody, Form, FormGroup, Input} from 'reactstrap';
import { Link } from "react-router-dom";
import './ListUser.scss';
import LeftPanelManager from '../../../components/LeftPanelManager/LeftPanelManager';
import ListContent from './ListContent';
const ListUser = () => {
const [popoverOpen, setPopoverOpen] = useState(false);
const filterPopHover = () => setPopoverOpen(!popoverOpen);

    return (
        <div className="DepartmentList">
            <LeftPanelManager></LeftPanelManager>
            <div className="pageContentpanel">
            <div className="department-list-content">
            <Row>
                <Col sm="6"><h2 className="title">User List </h2></Col>
                <Col sm="6" className="text-right">
                    
            <Button id="FilterPopOver" type="button" className="btn btn-sm btn-action-filter  primary-action">
                <i className="fas fa-sort-amount-down-alt"></i>  Filter
              </Button>
              <Popover placement="bottom"  isOpen={popoverOpen} target="FilterPopOver" toggle={filterPopHover} className="filter-popover">
                <PopoverBody >
                <Form>
                <FormGroup>
                    <Row className="filter-row">
                    <Col sm="6" className="filter-box"><Input type="text" className="form-control-filter" placeholder="Name" /></Col>
                    <Col sm="6" className="filter-box"> <Input type="text" className="form-control-filter" placeholder=" Contact Number" /></Col>
                    <Col sm="6" className="filter-box"> <Input type="text" className="form-control-filter" placeholder="Email Address" /></Col>
                    <Col sm="6" className="filter-box"> <Input type="text" className="form-control-filter" placeholder="Role" /></Col>
                    <Col sm="12" className="filter-box text-center">
                        <Button className="mr-2 btn-filter btn-clear">Clear</Button>
                        <Button className="btn-theme btn-filter">Search</Button>
                    </Col>
                    </Row>
                </FormGroup>
                </Form>
                </PopoverBody>
              </Popover>
                    <Link to="/eoffice/Setting/create-user-account" className="btn btn-sm btn-theme primary-action" color="success">
                        <i className="fas fa-user-plus"></i> Add User
                    </Link>
                </Col>
            </Row>
            
                <div className="layout-table">
                   <Table className="table-list table">
                   <thead>
                       <tr>
                           <th className="check-col">
                               <div className="checkbox" style={{ marginTop: '10px' }}>
                                   <input className="styled-checkbox" id="styled-checkbox-1" type="checkbox" value="value1" />
                                   <label for="styled-checkbox-1"></label>
                               </div>
                           </th>
                           <th>Name</th>
                           <th className="nowrap">Contact Number</th>
                           <th>Email</th>
                           <th>Role</th>
                           <th>Team</th>
                           <th></th>
                       </tr>
                   </thead>
                   <tbody>
       
                  <ListContent />
       
                   </tbody>
               </Table>
                   
                </div>
            </div>
            </div>
        </div>
    )
}

export default ListUser;
