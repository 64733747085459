import { joinConstants } from "../_constants";
import { joinService } from "../_services";
import { alertActions } from "./";
import { conferenceActions } from "./conference.actions";
//import { history } from '../_helpers';

export const joinActions = {
  joinConference,
  attendeeJoinMeet,
  hostJoinMeet,
};

function joinConference(
  isWebinar,
  join_name,
  join_id,
  join_password,
  moderatorToken
) {
  return (dispatch) => {
    dispatch(request({ join_name }));

    joinService
      .join(isWebinar, join_name, join_id, join_password, moderatorToken)
      .then(
        (resp) => {
          dispatch(success(resp));
          if (typeof resp !== "object") {
            window.location.href = resp;
          }
        },
        (error) => {
          if (error.response.status === 917) {
            dispatch(conferenceActions.startMeeting(join_id, "meeting"));
            dispatch(success(error));
          } else {
            dispatch(alertActions.error(error.response.data.error.toString()));
            dispatch(failure(error.toString()));
          }
        }
      );
  };

  function request(resp) {
    return { type: joinConstants.JOIN_REQUEST, join_name };
  }
  function success(resp) {
    return { type: joinConstants.JOIN_SUCCESS, resp };
  }
  function failure(error) {
    return { type: joinConstants.JOIN_FAILURE, error };
  }
}

function hostJoinMeet(isWebinar, join_id, join_password) {
  return (dispatch) => {
    dispatch(request({}));

    joinService.hostJoinMeet(isWebinar, join_id, join_password).then(
      (resp) => {
        //window.location.href = resp
        if (typeof resp !== "object") {
          window.location.href = resp;
        }
        dispatch(success(resp));
        //console.log("actionsssssssssssss",typeof(resp))
        //history.push('/dashboard');
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error.toString()));
      }
    );
  };

  function request(resp) {
    return { type: joinConstants.HOST_JOIN_REQUEST };
  }
  function success(resp) {
    return { type: joinConstants.HOST_JOIN_SUCCESS, resp };
  }
  function failure(error) {
    return { type: joinConstants.HOST_JOIN_FAILURE, error };
  }
}

function attendeeJoinMeet(isWebinar, join_name, join_id, join_password) {
  return (dispatch) => {
    dispatch(request({ join_name }));

    joinService
      .attendeeJoinMeet(isWebinar, join_name, join_id, join_password)
      .then(
        (resp) => {
          //window.location.href = resp
          if (typeof resp !== "object") {
            window.location.href = resp;
          }
          dispatch(success(resp));
          //console.log("actionsssssssssssss",typeof(resp))
          //history.push('/dashboard');
        },
        (error) => {
          dispatch(alertActions.error(error.toString()));
          dispatch(failure(error.toString()));
        }
      );
  };

  function request(resp) {
    return { type: joinConstants.ATTENDEE_JOIN_REQUEST, join_name };
  }
  function success(resp) {
    return { type: joinConstants._SUCCESS, resp };
  }
  function failure(error) {
    return { type: joinConstants.ATTENDEE_JOIN_FAILURE, error };
  }
}
