import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Modal, ModalBody } from 'reactstrap';

import "./extModal.css"

class ExtModal extends Component {
	constructor(props) {
         super(props);
         this.state = {
            isNoiseCancelModal:this.props.extModalShow,
            isChromeBrowser:this.props.isChromeBrowser
         }
     }

     openNoiseCancel = () => {
        this.setState({
	        isNoiseCancelModal: !this.state.isNoiseCancelModal
	    })
    }

    componentDidUpdate = (prevProps,prevState) => {
    	if(prevProps.extModalShow !== this.props.extModalShow){
    		this.setState({isNoiseCancelModal:this.props.extModalShow, isChromeBrowser:this.props.isChromeBrowser})
    	}
    }

	render() {
		const {isNoiseCancelModal, isChromeBrowser} = this.state;

		//console.log('propppppssssssss',this.props)
		return (
			<Modal isOpen={isNoiseCancelModal} className="NsModal" >
                {/* <ModalHeader toggle={this.NoiseCanceltoggle}>Modal title</ModalHeader> */}
                <ModalBody>
                <div className="row"> 
                    <div className="col-md-3 text-center">
                    <img alt="Noise Free Conference" src="images/nc-addon/wave-addon-img.png" className="nsaddon-img" width="300" />
                    </div>
                    {/* Mozilla Browser content start */}
                    {!isChromeBrowser ?
	                    <div className="col-md-9 ns-rgt-content text-center">
	                        <div className="txt-title">Currently you are not on Chrome Browser</div>
	                        <h2>Switch your browser to <span className="colorYellow">Chrome</span> <img alt="Noise Free Conference" src="images/nc-addon/icon-chrome-browser.png" /> for <span className="colornavyblue">Noise Free Conference</span>  </h2>            
	                        <Link to="/" className="btn-continue" style={{color: "#7e7c82"}}><i className="fas fa-home"></i> Go To Home </Link> 
	                        <span className="txt-or">OR</span>
	                        <button onClick={this.props.extHandle} className="btn-continue">Continue in this browser <i className="fas fa-long-arrow-alt-right"></i></button>
	                       
	                    </div> 
                    :
	                    <div className="col-md-9 ns-rgt-content text-center">
	                        <div className="txt-title">Currently you are on Chrome <img alt="Noise Free Conference" src="images/nc-addon/icon-chrome-browser.png" /> Browser</div>
	                        <h2>To Enjoy <span className="colornavyblue">Noise Free Conference</span>  <span className="colorYellow btn-txt-download">Download</span> the extension </h2>
	                        <div className="col-md-12"><a href="https://chrome.google.com/webstore/detail/sarv-nc-lite/kgbcpooeiicongajmmkhhfelhjpnkccj?hl=en" target="_BLANK" className="btn btn-download"><i className="fas fa-download"></i> Download </a>
	                         <span className="txt-or">OR</span> 
	                         <button onClick={this.props.extHandle} className="btn-continue">Continue in this browser <i className="fas fa-long-arrow-alt-right"></i></button></div>
	                    </div>
	                }

                    {/* Mozilla Browser content start */}

                     {/* Chrome Browser content start */}
                     {/* <div className="col-md-9 ns-rgt-content text-center">
                        <div className="txt-title">Currently you are on Chrome <img alt="Noise Free Conference" src="images/nc-addon/icon-chrome-browser.png" /> Browser</div>
                        <h2>To Enjoy <span className="colornavyblue">Noise Free Conference</span>  <button className="colorYellow btn-txt-download">Download</button> the extension </h2>
                        <div className="col-md-12"><button className="btn btn-download"><i className="fas fa-download"></i> Download </button>
                         <span className="txt-or">OR</span> 
                         <button className="btn-continue">Continue in this browser <i className="fas fa-long-arrow-alt-right"></i></button></div>
                    </div> */}

                    {/* Chrome Browser content start */}

                </div>
                <div className="row"> 
                <div className="col-md-12 text-center"><h3 className="bottom-title">We mute in background noise for zero disturbance in meeting.</h3></div>
                <div className="col-md-12 text-center padlr-0">
                    <ul className="circle-list">
                        <li>
                            <div className="circle">
                                <img alt="Family" src="images/nc-addon/icon-family.png" width="69" />
                            </div>
                            Family
                        </li>

                        <li>
                            <div className="circle">
                                <img alt="Pet" src="images/nc-addon/icon-pet.png" width="44" style={{marginTop: "30px"}} />
                            </div>
                            Pet
                        </li>

                        <li>
                            <div className="circle">
                                <img alt="Children" src="images/nc-addon/icon-children.png" width="68" style={{marginTop: "15px"}} />
                            </div>
                            Children
                        </li>
                        <li>
                            <div className="circle">
                                <img alt="Traffic" src="images/nc-addon/icon-traffic.png" width="56" style={{marginTop: "30px"}} />
                            </div>
                            Traffic
                        </li>
                        <li>
                            <div className="circle">
                                <img alt="AC/Fans" src="images/nc-addon/icon-ac.png" width="58" style={{marginTop: "40px"}} />
                            </div>
                            AC/Fans
                        </li>
                        <li>
                            <div className="circle">
                                <img alt="Coffee Machine" src="images/nc-addon/icon-coffee-machine.png" width="42" />
                            </div>
                            Coffee Machine
                        </li>
                        <li>
                            <div className="circle">
                               <img alt="Universal" src="images/nc-addon/icon-univeral.png" width="42" style={{marginTop: "30px"}} />
                            </div>
                            Universal
                        </li>


                    </ul>
                    </div>
                </div>
                </ModalBody>
            </Modal>
		);

	}

}

export default ExtModal