import { alertConstants } from '../_constants';
import { Store } from 'react-notifications-component'

export function alert(state = {}, action) {
  switch (action.type) {
    case alertConstants.SUCCESS:
      return Store.addNotification({
        //title: 'Success',
        message: action.message,
        type: 'success',                         // 'default', 'success', 'info', 'warning'
        container: 'top-right',                // where to position the notifications
        animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
        animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
        dismiss: {
          duration: 10000,
          showIcon: true
        }
      })
    case alertConstants.ERROR:
      return Store.addNotification({
        //title: 'Error',
        //isMobile:true,
        message: action.message,
        type: 'danger',                         // 'default', 'success', 'info', 'warning'
        container: 'top-right',                // where to position the notifications
        animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
        animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
        dismiss: {
          duration: 10000,
          showIcon: true
        }
      })
    case alertConstants.INFO:
      return Store.addNotification({
        //title: 'Error',
        //isMobile:true,
        message: action.message,
        type: 'info',                         // 'default', 'success', 'info', 'warning'
        container: 'top-right',                // where to position the notifications
        animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
        animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
        dismiss: {
          duration: 10000,
          showIcon: true
        }
      })
    case alertConstants.CLEAR:
      return {};
    default:
      return state
  }
}