import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { Modal, ModalBody } from 'reactstrap';
// import { ReactMic } from 'react-mic';
import { tsToDate } from '../../../../../helpers/helpers'
import { connect } from 'react-redux';
import { alertActions, waitingRoomActions } from '../../../../../Redux/_actions';

class BroadcastMessages extends Component {
	
	constructor(props){
		super(props);
		this.state = {
			mid : this.props.mid,
            recordingModal: false,
            record:false,
            audioFile:"",
            recordData:{},
            msgInput:undefined,
            msgList:[],
            last_msg_time:"",
            showStop:false,
            showStart:true,
            isPaused:false
		}
	}

    closeModal(modal) {
        this.setState({
            [modal]: false,
            recordData: {},
            audioFile:""
        });
    }
    showModal(modal) {
        this.setState({
            [modal]: true
        });
    }

    startRecording = () => {
        this.setState({ 
            record: true,
            showStop:true 
        });

    }
     
    stopRecording = () => {
        this.setState({ 
            record: false,
            showStop: false 
        });
    }
     
    onData(recordedBlob) {
        //console.log('chunk of real-time data is: ', recordedBlob);
    }
     
    onStop(recordedBlob) {
        if(typeof recordedBlob.blobURL !== "undefined"){
            this.setState({
                audioFile:recordedBlob.blobURL,
                recordData: recordedBlob
            })
        }
        //console.log('recordedBlob is: ', recordedBlob);
    }

    recordBtnClick = (e) => {
        e.preventDefault();
        this.setState({
            recordingModal: true
        })
    }

    recordSubmit = (type,e) => {
        e.preventDefault();
        if(type === "audio"){
            if(Object.keys(this.state.recordData).length > 0){
                this.setState({
                    recordingModal:false,
                    recordData: {},
                    audioFile:""
                })
                this.props.broadcastMsg(this.state.mid,type,this.state.recordData)
            }
        }
        else{
            if(this.state.msgInput !== ""){
                this.setState({
                    msgInput: ""
                })
                this.props.broadcastMsg(this.state.mid,type,this.state.msgInput)
            }
        }
        let self = this;
        setTimeout(function(){ 
            self.props.getBroadcastMsg(self.state.mid,self.state.last_msg_time)
        }, 1000);
        
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        });
    }

    componentDidMount = () => {
        this.props.getBroadcastMsg(this.state.mid,this.state.last_msg_time)
    }

    componentDidUpdate = (prevProps,prevState) => {
        if(prevProps.getBroadcastRes !== this.props.getBroadcastRes){
            if(typeof this.props.getBroadcastRes.message_list !== "undefined") {
                let last_msg_time = this.state.last_msg_time;
                if(this.props.getBroadcastRes.message_list.length > 0) {
                    let msgList = [...this.state.msgList,...this.props.getBroadcastRes.message_list];
                    last_msg_time = this.props.getBroadcastRes.last_msg_time;
                        
                    this.setState({
                        msgList : msgList,
                        last_msg_time: last_msg_time
                    })
                    setTimeout(function(){ 
                        var objDiv = document.getElementById("chatList");
                        objDiv.scrollTop = objDiv.scrollHeight;
                    }, 300);
                }
            }
        }   
    }

	render() {
		const { audioFile, msgInput, msgList, showStop, isPaused } = this.state;

        let msgHtml = (<li  className='text-center text-secondary'><div  className="waveno_participaint_info">You didn't send any message yet.</div></li>)
        if(msgList.length > 0){
            msgHtml = msgList.map((msg,key) => {
                
                if(msg.type === "text"){
                    return (    
                        <li key={key}> 
                            <div  className="chat_text">
                                {msg.value}
                            </div>
                            <div  className="sender_detail">
                                 <span><i  className="far fa-clock"></i> {tsToDate(msg.created)}</span>
                            </div>
                        </li>
                    )
                }
                else{
                    return (
                        <li key={key}> 
                            <div  className="chat_text">
                                <audio controls src={msg.value} type="audio/mpeg" controlsList="nodownload">
                                    Your browser does not support the audio element.
                                </audio> 
                            </div>
                            <div  className="sender_detail">
                                 <span><i  className="far fa-clock"></i> {tsToDate(msg.created)}</span>
                            </div>
                        </li>
                    )

                }
                
            })
        }

        return (
			<div  className="col-xl-8 col-lg-7 col-md-6">
     			<div  className="prtcpnt_chat_block">
        			<h3><i  className="far fa-comment-alt"></i> Messages to Participiants</h3>
        			<ul id="chatList">
                        {msgHtml}
        			</ul> 
        			<div  className="chat_type_area">
          				<div  className="chat_type_bx"> 
                            <form onSubmit={this.recordSubmit.bind(this,'text')}>
            				    <input type="text" name="msgInput" value={msgInput} onChange={this.handleChange.bind(this)}  className="type_input float-left" placeholder="Type a message..." />
            				    <button  className="btn btn-link text-primary float-left" title="Send"><i  className="fas fa-paper-plane"></i></button>
                            </form>
                            <button onClick={this.recordBtnClick.bind(this)} title="Voice Message"  className="btn btn-link text-warning float-right"><i  className="fas fa-microphone-alt"></i></button>
          				</div>
        			</div>
    			</div>

                {/*---------Modal Start--------------*/}

                <Modal isOpen={this.state.recordingModal} toggle={this.closeModal.bind(this, 'recordingModal')}  className="Popup recording-modal-dialog">
                    <ModalBody>
                        <p  className="txt-msg"><i  className="fas fa-microphone-alt"></i> Send Voice Message</p>
                        <div  className="mt-2">
                            <form onSubmit={this.recordSubmit.bind(this,"audio")} enctype="multipart/form-data">
                                {/* <ReactMic
                                    record={this.state.record}
                                     className="sound-wave"
                                    pause={isPaused}
                                    onStop={this.onStop.bind(this)}
                                    onData={this.onData.bind(this)}
                                    visualSetting="sinewave"
                                    mimeType="audio/mp3"
                                    noiseSuppression={true}
                                    echoCancellation={true}
                                    autoGainControl={true}
                                    bitRate={256000}
                                    strokeColor="#ffc107"
                                    backgroundColor="#221e29" 
                                /> */}
 

                                <button title="Start Recording"  className="wave_record_btn mr-4 mt-2" onClick={this.startRecording} type="button"><i className="fas fa-microphone" aria-hidden="true"></i> Start Recording</button>
 
                                {showStop ? 
                                    <button title="Stop Recording"  className="wave_stop_btn mt-2" onClick={this.stopRecording} type="button"><i className="fas fa-stop" aria-hidden="true"></i> Stop Recording</button>
                                    :
                                    ""
                                }
                                <div  className="wave_audioPlayer mt-4">
                                    <label>Listen Recording</label>
                                    <audio controls src={audioFile} type="audio/mpeg" controlslist="nodownload">
                                          Your browser does not support the audio element.
                                    </audio>
                                </div>
                                <div  className="mt-4 pt-2">
                                    <button  className="btn btn-primary btn-sm"><i  className="fas fa-paper-plane mr-1"></i> Send Recording</button>
                                    <button type="button" onClick={this.closeModal.bind(this, 'recordingModal')}  className="btn btn-secondary btn-sm ml-2">Cancel</button>
                                </div>
                            </form>
                        </div>
                    </ModalBody>
                </Modal>
			</div>
		)
	}
}


function mapState(state) {
    const {getBroadcastloading, getBroadcastRes, listAttendeeLoading, listAttendeeRes} = state.waitingRoom
    return {
        getBroadcastloading,
        getBroadcastRes,
        listAttendeeLoading,
        listAttendeeRes
    };
}

const actionCreators = {
    broadcastMsg: waitingRoomActions.broadcastMsg,
    listAttendee: waitingRoomActions.listAttendee,
    getBroadcastMsg: waitingRoomActions.getBroadcastMsg,
    alertError:alertActions.error
};

export default withRouter(connect(mapState, actionCreators)(BroadcastMessages));