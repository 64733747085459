import React from "react";
import {
  /* AiFillSchedule, */
  AiFillHome,
  AiFillSetting,
  AiFillInfoCircle,
  AiFillFormatPainter,
  /*  AiOutlineDownload, */
} from "react-icons/ai";
import {
  FaHandshake,
  FaUsers,
  FaMicrophone,
  FaGripHorizontal,
  FaHistory,
} from "react-icons/fa";
import {
  MdWork,
  /*  MdCastConnected,
   MdOutlineBrandingWatermark, */
} from "react-icons/md";
import { RiPlantFill } from "react-icons/ri";
import { ImDownload } from "react-icons/im";

import userconfigs from "../helpers/closures";
import jwt_decode from "jwt-decode";
import { organization } from "../config/config";

const getJson = (list, param, mid) => {
  let webUser = jwt_decode(userconfigs.getItem("jwt"));
  //let user_type = webUser.user_type === "admin" ? "regular" : "admin";

  switch (list) {
    case "webinar": {
      const id = param?.id;
      return [
        {
          label: "Basic Info",
          icon: <AiFillInfoCircle />,
          path: `/webinars/${id}`,
          platform: "beta",
          active: `/webinars/${id}`
        },
        // {
        //   label: "Schedule",
        //   icon: <AiFillSchedule />,
        //   path: "/",
        // },
        {
          label: "Speakers",
          icon: <FaMicrophone />,
          path: `/webinars/${id}/speakers`,
          platform: "beta",
          active: `/webinars/${id}/speakers`
        },
        {
          label: "Participants",
          icon: <FaUsers />,
          path: `/webinars/${id}/participants`,
          platform: "beta",
          active: `/webinars/${id}/participants`
        },
        {
          label: "History",
          icon: <FaHistory />,
          path: `/webinars/${id}/history`,
          platform: "beta",
          active: `/webinars/${id}/history`
        },
        {
          label: "Options",
          icon: <FaGripHorizontal />,
          path: `/webinars/${id}/events`,
          platform: "beta",
          active: `/webinars/${id}/events`
        },
        // {
        //   label: "Live Stream",
        //   icon: <MdCastConnected />,
        //   path: `/webinars/${id}/live_stream`,
        // },
        {
          label: "Branding",
          icon: <AiFillFormatPainter />,
          path: `/webinars/${id}/branding`,
          platform: "beta",
          active: `/webinars/${id}/branding`
        },
      ];
    }
    case "meeting": {
      const id = param?.id;
      return [
        {
          label: "Basic Info",
          icon: <AiFillInfoCircle />,
          path: `/meeting_history/${id}${mid ? `?mid=${mid}` : ""}`,
          platform: "live",
          active: `/meeting_history/${id}`
        },
        {
          label: "Participants",
          icon: <FaUsers />,
          path: `/meeting_history/${id}/participants${mid ? `?mid=${mid}` : ""
            }`,
          platform: "live",
          active: `/meeting_history/${id}/participants`
        },
        {
          label: "Options",
          icon: <FaGripHorizontal />,
          path: `/meeting_history/${id}/options${mid ? `?mid=${mid}` : ""}`,
          platform: "live",
          active: `/meeting_history/${id}/options`
        },
        {
          label: "History",
          icon: <FaHistory />,
          path: `/meeting_history/${id}/history${mid ? `?mid=${mid}` : ""}`,
          platform: "live",
          active: `/meeting_history/${id}/history`
        },
      ];
    }
    default: {
      let menuArray = [
        {
          label: "Home",
          icon: <AiFillHome />,
          path: "/",
          platform: "live",
          active: '/'
        },
        {
          label: "Meeting",
          icon: <FaHandshake />,
          path: "/meeting_history",
          platform: "live",
          active: '/meeting_history'
        },
        // {
        //   label: "Webinar",
        //   icon: <FaUsers />,
        //   path: "/webinars",
        //   platform: "beta",
        // },
        // {
        //   label: "e-Office",
        //   icon: <MdWork />,
        //   path: "/e_office",
        //   platform: "dev",
        // },
        // {
        //   label: "Plan",
        //   icon: <RiPlantFill />,
        //   // path: "/select_plan",
        //   platform: "dev",
        // },
        {
          label: "Report",
          icon: <ImDownload />,
          path: "/reports",
          platform: "live",
          active: '/reports'
        },

      ];
      if (webUser.hasOwnProperty('hostRole') && webUser.hostRole.toLowerCase() === "user") { } else {
        if (organization !== "divya") {
          menuArray.push(
            {
              label: "Settings",
              icon: <AiFillSetting />,
              path: "/setting",
              platform: "live",
              active: '/setting'
            }
          )
        }

        menuArray.push(
          {
            label: "Users",
            icon: <RiPlantFill />,
            path: "/usermanagment",
            platform: "live",
            active: '/usermanagment'
          }
        );

      }
     /*  if (
        webUser.user_type === "super_admin" ||
        webUser.user_type === "admin"
      ) {
        menuArray.push({
          label: "Pending Request",
          icon: <FaUsers />,
          path: "/host-manage-users",
          platform: "live",
        });
        menuArray.push({
          label: "Manage Feedback",
          icon: <FaUsers />,
          path: "/manage-feedback",
        });
        menuArray.push({
          label: "Manage Users",
          icon: <FaUsers />,
          path: "/manage-users",
        });
      }  *//*else if (webUser.user_type === "admin") {
        menuArray.push({
          label: "Manage Feedback",
          icon: <FaUsers />,
          path: "/manage-feedback",
        });
        menuArray.push(
          {
            label: "Manage Users",
            icon: <FaUsers />,
            path: "/manage-users",
          });
        menuArray.push({
          label: "Record",
          icon: <FaUsers />, 
          path: "/record",
        });
      }*/  if (webUser.user_type === "regular") {
        menuArray.push({
          label: "Feedback",
          icon: <FaUsers />,
          path: "/user_feedback",
          platform: "live",
          active: '/user_feedback'
        });
      }

      return menuArray;
    }
  }
};

export default getJson;
