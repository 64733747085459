import React from "react";
import { Formik, FieldArray } from "formik";
import {  Form } from "react-bootstrap";
// import { FaUserPlus, FaTrash } from "react-icons/fa";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useDispatch } from "react-redux";
import * as yup from "yup";

import Modal from "../../../layoutSection/Modal";
import { InputFiled } from "../../../Common/authFile";
import { webinarActions } from "../../../Redux/_actions";

const validation = yup.object().shape({
  arr: yup.array().of(
    yup.object().shape({
      name: yup.string().required("Must enter the name!"),
      // type: yup.string(),
      // mobile: yup.string().when("type", {
      //   is: (value) => value === "mobile",
      //   then: yup.string().required("Must enter the mobile Number!"),
      // }),
      mobile: yup.string().required("Must enter the mobile Number!"),
      email: yup
        .string()
        .email("Please enter properly")
        .required("Must enter the mobile Number!"),

      // email: yup
      //   .string()
      //   .email("Please enter properly")
      //   .when("type", {
      //     is: (value) => value === "email",
      //     then: yup.string().required("Must enter the mobile Number!"),
      //   }),
    })
  ),
});

const AddHostForm = (props) => {
  const { show, toggle, wibId, type, onChangeDone, closeBtn } = props;
  const dispatch = useDispatch();
  const setVariable = {
    name: type?.n || "",
    type: type?.n ? "" : "mobile",
    email: type?.v || "",
    mobile: type?.mob || "",
    designation: type?.d || "",
    bio: type?.b || "",
    pic: type?.p || "",
    country_code: "",
    showPic: type?.p || "",
  };

  const initialValues = {
    arr: [
      {
        ...setVariable,
      },
    ],
  };
  const submitHandler = (values) => {
    let multiPart = false;
    let hostData = [];
    values.arr.map((hostValue, index) => {
      let hostInfo = {};
      let hostName = hostValue?.name || "";
      // let hostType = hostValue?.type || "";
      let hostEmail = hostValue?.email || "";
      let hostCountryCode = hostValue?.country_code || "";
      let hostMobile = hostValue?.mobile || "";
      let hostBio = hostValue?.bio || "";
      let hostPost = hostValue?.designation || "";
      let hostPhoto = hostValue?.pic || "";
      if (hostPhoto) {
        multiPart = true;
      }

      if (typeof type === "string") {
        // if (hostType === "email") {
        //   hostInfo = {
        //     name: hostName,
        //     type: hostType,
        //     email: hostEmail,
        //     bio: hostBio,
        //     designation: hostPost,
        //   };
        // } else {
        hostInfo = {
          name: hostName,
          // type: hostType,
          email: hostEmail,
          country_code: hostCountryCode,
          mobile: hostMobile,
          bio: hostBio,
          designation: hostPost,
        };
        // }
      } else {
        hostInfo = {
          name: hostName,
          email: hostEmail,
          bio: hostBio,
          country_code: hostCountryCode,
          mobile: hostMobile,
          designation: hostPost,
        };
      }

      hostData.push(hostInfo);
      return true;
    });

    let formData = null;

    if (multiPart) {
      formData = new FormData();
      formData.append("webinarID", wibId);
      if (typeof type !== "string") {
        formData.append("speakerID", type?._id);
      }
      if (typeof type === "string") {
        formData.append("hostArr", JSON.stringify(hostData));
      } else {
        formData.append("hostArr", JSON.stringify(hostData[0]));
      }

      values.arr.map((hostValue, index) => {
        let hostPhoto = hostValue.pic || "";
        let hostName = "";
        if (typeof type === "string") {
          hostName = "host_photo_1";
        } else {
          hostName = "speaker_photo";
        }

        if (hostPhoto && hostName) {
          formData.append(hostName, hostPhoto);
        }
        return true;
      });
    } else {
      formData = {};
      formData.webinarID = wibId;
      if (typeof type !== "string") {
        formData.speakerID = type?._id;
      }
      if (typeof type === "string") {
        formData.hostArr = JSON.stringify(hostData);
      } else {
        formData.hostArr = JSON.stringify(hostData[0]);
      }
    }
    if (typeof type === "string") {
      dispatch(webinarActions.addMoreHost(formData, multiPart));
    } else {
      dispatch(webinarActions.editSpeakerUpdate(formData, multiPart));
    }
    setTimeout(() => {
      onChangeDone(true);
    }, 1000);
  };

  const setImage = (setFieldValue, value, type, subType) => {
    setFieldValue(type, value);
    const reader = new FileReader();
    reader.readAsDataURL(value);
    reader.onload = (e) => setFieldValue(subType, e.target.result);
  };

  return (
    <Modal
      show={show}
      toggle={toggle}
      closeBtn={closeBtn}
      // label={
      //   typeof type === "string" ? (
      //     <h4 className="mb-0"> Add Speakers </h4>
      //   ) : (
      //     <h4 className="mb-0"> Update Speakers </h4>
      //   )
      // }
      size="lg"
      dialogClassName={`speakers-modal`}
      contentClassName="sideBar welcomeMsg modal-600 addSpeakers"
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(value) => submitHandler(value)}
        // onSubmit={(value) => submitAddHostForm(value)}
        validationSchema={typeof type === "string" && validation}
      >
        {({
          values,
          setFieldValue,
          handleSubmit,
          errors,
          touched,
          setFieldTouched,
        }) => (
          <div children="mt-2">
            <FieldArray
              name="arr"
              render={({ insert, remove, push }) => {
                return values?.arr?.map((data, index) => (
                  <div className="mb-2 mt-2 ">
                    <div className="text-left">
                      <button
                        type="submit"
                        onClick={() => handleSubmit()}
                        className="headerBtn welcomeMsg submitBtnColor border-0 mr-3 mt-1 btn"
                        style={{ width: "100px", height: "40px" }}
                      >
                        {typeof type === "string" ? "Submit" : "Update"}
                      </button>
                      <button
                        type="submit"
                        onClick={toggle}
                        className="headerBtn border-0 mr-3 mt-1 welcomeMsg btn sideBarIcon "
                        style={{
                          width: "100px",
                          height: "40px",
                          background: "#3D424E",
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        {/* <Form.Group >
												<Form.Label>Speakers Img</Form.Label>
												<Form.Control type="file" onChange={(event) => setFieldValue(`arr.${index}.pic`, event.currentTarget.files[0])} className="form-control" />
											</Form.Group> */}
                        <InputFiled
                          name={`arr.${index}.name`}
                          type="text"
                          label="Name"
                          labelClassName="text-lite"
                          placeholder=" Enter the name"
                          className="form-control"
                        />
                      </div>
                      <div className="col-md-4">
                        <InputFiled
                          name={`arr.${index}.email`}
                          type="text"
                          label="Email"
                          placeholder=" Enter the email"
                          labelClassName="text-lite"
                          className="form-control"
                        />
                      </div>
                      <div className="col-md-4">
                        <Form.Group className="mb-2  w-100">
                          <Form.Label className="f_size_14 ml-3">
                            Mobile Number
                          </Form.Label>
                          <PhoneInput
                            country="in"
                            name={`arr.${index}.mobile`}
                            inputClass="w-100"
                            enableSearch={true}
                            placeholder="Mobile No"
                            countryCodeEditable={false}
                            onChange={(value, country) => {
                              setFieldValue(
                                `arr.${index}.mobile`,
                                value.slice(country.dialCode.length)
                              );
                              setFieldValue(
                                `arr.${index}.country_code`,
                                country.dialCode
                              );
                            }}
                            inputProps={{
                              onBlur: () =>
                                setFieldTouched(`arr.${index}.mobile`, true),
                            }}
                          />
                          {/* {errors?.arr && touched?.arr && errors?.arr[index]?.mobile && touched?.arr[index].mobile && (
																<div className="error text-danger f_size_10">
																	{errors?.arr[index].mobile}
																</div>
															)} */}
                        </Form.Group>
                      </div>
                      <div className="col-md-4">
                        <InputFiled
                          name={`arr.${index}.designation`}
                          type="text"
                          label="Designation"
                          labelClassName="text-lite"
                          className="form-control"
                          placeholder=" Enter the Designation"
                        />
                      </div>
                      <div className="col-md-8">
                        <InputFiled
                          name={`arr.${index}.bio`}
                          type="text"
                          label="Bio"
                          placeholder=" Enter the Bio"
                          labelClassName="text-lite"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-12 text-center">
                      <div className="d-flex justify-content-center position-relative">
                        <div className="circle centerAlign colorOffDark">
                          {values?.arr && values?.arr[index]?.showPic ? (
                            <img
                              className="profile-pic"
                              src={values?.arr[index]?.showPic}
                              alt=""
                            />
                          ) : (
                            <i className="fa fa-user fa-5x"></i>
                          )}
                        </div>
                        <div className="p-image welcomeMsg">
                          <label
                            className="upload-button bg-primary"
                            htmlFor={`UseImage${index}`}
                          >
                            <i className="fa fa-camera "></i>
                          </label>
                          <Form.Control
                            type="file"
                            onChange={(event) =>
                              setImage(
                                setFieldValue,
                                event.currentTarget.files[0],
                                `arr.${index}.pic`,
                                `arr.${index}.showPic`
                              )
                            }
                            className="file-upload"
                            id={`UseImage${index}`}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ));
              }}
            />
          </div>
        )}
      </Formik>
    </Modal>
  );
};

export default AddHostForm;
