import React, { Component } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { companyActions } from './../../Redux/_actions';
import './style.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class InviteeConfirmation extends Component {
    constructor(props) {
        super(props);
    }

    toggle = () => {
        this.props.modalCloseHandler();
    }

    declineRequest = () => {
        let data = {
            cuid: this.props.cuid
        }
        this.props.decline(data);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.declined !== this.props.declined) {
            localStorage.removeItem('eOfficeId');
            this.props.history.push('/');
            this.props.modalCloseHandler();
        }
        if (prevProps.accepted !== this.props.accepted) {
            this.props.modalCloseHandlerAfterAccept();
        }
    }

    acceptRequest = () => {
        let data = {
            cuid: this.props.cuid
        }
        this.props.accept(data);
    }


    render() {
        return (
            <div>
                <Modal isOpen={this.props.isConfirmation} toggle={this.toggle} backdrop="static" size="lg" style={{ maxWidth: '95%', width: '55%' }} className="Popup viewRecordingModal " >
                    { }
                    <ModalBody>
                        {/* <p className="txt-msg"> {this.props.message}</p> */}
                        <button className="rounded-circle btn-close" onClick={this.toggle}><i className="fas fa-times"></i></button>

                        <div className="row viewRecordingRow">
                            You are invite by Sarv web to join eOffice
                        </div>
                        <button onClick={this.acceptRequest}>Accept</button>
                        <button onClick={this.declineRequest}>Reject</button>
                    </ModalBody>
                </Modal>
            </div>
        )
    }

}

const mapState = (state) => {
    const { declineLoading, declined, acceptLoading, accepted } = state.company;
    return {
        declined,
        declineLoading,
        accepted,
        acceptLoading
    }
}

const actionCreators = {
    decline: companyActions.decline,
    accept: companyActions.accept
};

export default withRouter(connect(mapState, actionCreators)(InviteeConfirmation));
