import React, { useEffect, useState } from "react";
// import {
//   AiFillRightCircle,
//   AiFillLeftCircle,
//   AiOutlineLeft,
//   AiOutlineRight
// } from "react-icons/ai";
import { useHistory, useParams } from "react-router";
import queryString from "query-string";
import PerfectScrollbar from 'react-perfect-scrollbar'
import CompanyInfo from "../eOffice/CompanyInfo/CompanyInfo";
import { companyService, joinService } from "./../Redux/_services";
import { alertActions } from "../Redux/_actions";
import { myconfig } from "../config/config";
import jwt_decode from "jwt-decode";
import userconfigs from "./../helpers/closures";
import { useDispatch, useSelector } from "react-redux";
import ListDepartments from "./../eOffice/modal/ListUserDepartments";
import InviteeConfirmation from "./../eOffice/modal/InviteeConfirmation";
import getJson from './sideBarJson'
import Images from '../images';
import { useLocation } from 'react-router-dom';

import "./styles.scss";

const ListSection = ({ data, history, keyIndex, toggle, active, checkEOffice , path}) => {
  return (
    <div className={`sideBarBtnContainer ${path === data.active && !toggle ? 'activeSection' : ''}`} title={data.label}>
      <button
        className={`btnBgLess ${path === data.active ? (toggle ? 'activeSectionToggle' : 'text-white') : 'welcomeMsg'}  `}
        onClick={() => data.label === 'e-Office' ? checkEOffice() : history?.push(data.path)}
        key={keyIndex}
      >
        {data.icon}
        <span className={`sidebar-nav-link`}>{data.label}</span>
      </button>
    </div>
  );
};

const Sidebar = (props) => {
  const location = useLocation()
  const { list, toggle, setToggle, active } = props;
  const [jwt, setJwt] = useState(jwt_decode(userconfigs.getItem("jwt")))
  const history = useHistory();
  const param = useParams();
  const [listSection, setListSection] = useState(null)
  const [isCompanyInfoModal, setIsCompanyInfoModal] = useState(false)
  const [eOfficeStates, setEOfficeState] = useState({
    eOfficeId: "",
    isJoining: false,
    isJoinDepartment: false,
    isNotAccepted: false,
    userDeptArr: [],
  })

  var mid = queryString.parse(history.location.search)?.mid;
  const dispatch = useDispatch();
  const { theme } = useSelector((state) => state.themeChange);

  useEffect(() => {
    window.addEventListener('storage', event => {
      const token = jwt_decode(userconfigs.getItem("jwt"));
      if (token?.loginStatus === 0) {
        window.location.reload();
      }
      setJwt(jwt_decode(userconfigs.getItem("jwt")))
    });

    return () => {
      window.removeEventListener('storage', event => {
        setJwt(jwt_decode(userconfigs.getItem("jwt")))
      });
    }
  }, [])

  useEffect(() => {
    if (window.outerWidth < 1201) {
      setToggle(true)
    }

    const plateForm = myconfig.env
    const data = getJson(list, param, mid)
    const result = data?.filter(item => {
      if (item?.platform) {
        if (!plateForm || plateForm === 'dev') {
          return item
        }
        else if ((plateForm === 'beta' && item?.platform === 'beta') || item?.platform === 'live') {
          return item
        } else if (plateForm === 'live' && item?.platform === 'live') {
          return item
        }

      } else {
        return item
      }
    })
    setListSection(result)
  }, [history, list, param, setToggle]);

  const modalCloseHandler = () => {
    setIsCompanyInfoModal(false)
  }

  const checkEOffice = async () => {
    await companyService.getCompanyBranding().then(
      (res) => {
        if (
          res &&
          res.hasOwnProperty("eOffice") &&
          res.eOffice.invite_status === "pending"
        ) {
          const setData = {
            isNotAccepted: true,
            userDeptArr: res.deparments,
            eOfficeId: res.eOffice.company_id,
            isJoining: eOfficeStates.isJoining,
            isJoinDepartment: eOfficeStates.isJoinDepartment,
          }
          setEOfficeState(setData)
          console.log(eOfficeStates);
          return;
        }
        if (res && res.hasOwnProperty("deparments")) {
          const setData = {
            isJoinDepartment: true,
            userDeptArr: res.deparments,
            eOfficeId: res.cuid,
            isNotAccepted: eOfficeStates.isNotAccepted,
            isJoining: eOfficeStates.isJoining,
          }
          setEOfficeState(setData)

        }

        if (res && res.hasOwnProperty("cuid")) {
          localStorage.setItem("eOfficeId", res.cuid);
          history.push("/eoffice");
        } else {
          if (res && res.hasOwnProperty("result")) {
            console.log("coming hereee");
            let userInfo = jwt_decode(userconfigs.getItem("jwt"));

            joinService.join(0, userInfo.name, res.result).then((result) => {
              history.push({
                pathname: "/eoffice",
                state: {
                  conf_join_url: result,
                },
              });
            });
          }
        }
      },
      (error) => {
        if (error === "You are not assign in any department. Talk with admin") {
          dispatch(alertActions.error(error))
          const setData = {
            isJoinDepartment: eOfficeStates.isJoinDepartment,
            userDeptArr: eOfficeStates.userDeptArr,
            eOfficeId: eOfficeStates.eOfficeId,
            isNotAccepted: eOfficeStates.isNotAccepted,
            isJoining: false,
          }
          setEOfficeState(setData)
        } else {
          setIsCompanyInfoModal(true);
          const setData = {
            isJoinDepartment: eOfficeStates.isJoinDepartment,
            userDeptArr: eOfficeStates.userDeptArr,
            eOfficeId: eOfficeStates.eOfficeId,
            isNotAccepted: eOfficeStates.isNotAccepted,
            isJoining: false,
          }
          setEOfficeState(setData)
        }
      },
    );
  }

  const closeHandler = () => {
    setEOfficeState({ ...eOfficeStates, isNotAccepted: false })
  };

  const modalCloseHandlerAfterAccept = async () => {
    const setData = {
      isJoinDepartment: eOfficeStates.isJoinDepartment,
      userDeptArr: eOfficeStates.userDeptArr,
      eOfficeId: eOfficeStates.eOfficeId,
      isNotAccepted: false,
      isJoining: eOfficeStates.isJoining,
    }
    setEOfficeState(setData)
    checkEOffice();
  };

  const joinConfrence = (conf_id) => {
    let userInfo = jwt_decode(userconfigs.getItem("jwt"));
    joinService.join(0, userInfo.name, conf_id).then((res) => {
      localStorage.setItem("eOfficeId", this.state.eOfficeId);
      const setData = { isJoining: false, isJoinDepartment: false }
      setEOfficeState({ ...eOfficeStates.setData })
      history.push({
        pathname: "/eoffice",
        state: {
          conf_join_url: res,
        },
      });
    });
  }

  return (
    <div className={`sideContainer sideBar`}>

      <button className="btnBgLess logo-btn" onClick={() => history.push('/')} >

        <img
          src={jwt?.logo || (theme ? Images.logoImgWhiteTheme : Images.logoImgBlackTheme)}
          alt="logo"
          width={120}
          className="img-fluid"
        />
      </button>
      <PerfectScrollbar >
        <div >
          {listSection?.map((data, index) => (
            <div className="w-100" key={`sideBar-${index}`} >
              <ListSection
                data={data}
                history={history}
                toggle={toggle}
                key={index}
                active={active}
                checkEOffice={checkEOffice}
                path={location.pathname}
              />
            </div>
          ))}
        </div>
      </PerfectScrollbar>
      {isCompanyInfoModal &&
        <CompanyInfo modalCloseHandler={modalCloseHandler}></CompanyInfo>
      }
      {eOfficeStates.isJoinDepartment && (
        <ListDepartments
          isJoining={eOfficeStates.isJoining}
          joinConfrence={(conf_id) => joinConfrence(conf_id)}
          isJoinDepartment={eOfficeStates.isJoinDepartment}
          modalCloseHandler={modalCloseHandler}
          departmentArr={eOfficeStates.userDeptArr}
        />
      )}
      {eOfficeStates.isNotAccepted && (
        <InviteeConfirmation
          isConfirmation={eOfficeStates.isNotAccepted}
          modalCloseHandler={closeHandler}
          modalCloseHandlerAfterAccept={modalCloseHandlerAfterAccept}
          cuid={eOfficeStates.eOfficeId}
        />
      )}
      <div
        className={`text-muted f_size_11 bottomSection copyright ${toggle ? "displayNone" : "transition5 "
          } `}
      >
        © 2022. Proudly Powered By <span className="welcomeMsg">SarvWave</span>
      </div>
    </div>
  );
};

export default Sidebar;
