import { alertActions } from './';
import { teamService } from './../_services';
import { teamConstants } from './../_constants';


export const teamActions = {
    getAllTeams,
    addTeam,
    getTeamById,
    editTeam

};

function getAllTeams() {
    return dispatch => {
        dispatch(request({}));

        teamService.getTeams()
            .then(
                resp => {
                    dispatch(success(resp))
                    //console.log("actions",resp)
                },
                error => dispatch(alertActions.error(error.toString()))
            );
    };

    function request(resp) { return { type: teamConstants.TEAM_GETALL_REQUEST } }
    function success(resp) { return { type: teamConstants.TEAM_GETALL_SUCCESS, resp } }
}

function getTeamById(id) {
    return dispatch => {
        dispatch(request({}));

        teamService.getTeamById(id)
            .then(
                resp => {
                    dispatch(success(resp))
                },
                (error) => {
                    dispatch(alertActions.error(error.toString()))
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(resp) { return { type: teamConstants.TEAM_BYID_REQUEST } }
    function success(resp) { return { type: teamConstants.TEAM_BYID_SUCCESS, resp } }
    function failure(error) { return { type: teamConstants.TEAM_BYID_FAILURE, error } }
}

function addTeam(teamData) {
    return dispatch => {
        dispatch(request({}));

        teamService.addTeam(teamData)
            .then(
                resp => {
                    dispatch(alertActions.success(resp.toString()));
                    dispatch(success(resp))
                },
                error => dispatch(alertActions.error(error.toString()))
            );
    };

    function request(resp) { return { type: teamConstants.TEAM_ADD_REQUEST } }
    function success(resp) { return { type: teamConstants.TEAM_ADD_FAILURE } }
    function success(resp) { return { type: teamConstants.TEAM_ADD_SUCCESS, resp } }
}


function editTeam(teamData, id) {
    return dispatch => {
        dispatch(request({}));

        teamService.editTeam(teamData, id)
            .then(
                resp => {
                    dispatch(alertActions.success(resp.toString()));
                    dispatch(success(resp))

                },
                error => dispatch(alertActions.error(error.toString()))
            );
    };

    function request(resp) { return { type: teamConstants.TEAM_EDIT_REQUEST } }
    function success(resp) { return { type: teamConstants.TEAM_EDIT_FAILURE } }
    function success(resp) { return { type: teamConstants.TEAM_EDIT_SUCCESS, resp } }
}