export const reportsConstants = {
  GET_REPORTS_REQUEST: 'GET_REPORTS_REQUEST',
  GET_REPORTS_SUCCESS: 'GET_REPORTS_SUCCESS',
  GET_REPORTS_FAILURE: 'GET_REPORTS_FAILURE',
  GET_REG_REPORTS_REQUEST: 'GET_REG_REPORTS_REQUEST',
  GET_REG_REPORTS_SUCCESS: 'GET_REG_REPORTS_SUCCESS',
  GET_REG_REPORTS_FAILURE: 'GET_REG_REPORTS_FAILURE',
  GET_MEETING_RECORDING_REQUEST: 'GET_MEETING_RECORDING_REQUEST',
  GET_MEETING_RECORDING_SUCCESS: 'GET_MEETING_RECORDING_SUCCESS',
  GET_MEETING_RECORDING_FAILURE: 'GET_MEETING_RECORDING_FAILURE',
};