import React, { Component } from "react";
import queryString from "query-string";
import { Form, Input, Button } from "reactstrap";
import { Link, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { webinarActions, alertActions } from "../../Redux/_actions";
import { Spinner } from "reactstrap";
import userconfigs from "../../helpers/closures";
import decode from "jwt-decode";
import LoadSpinner from "../../components/UI/Spinner/Spinner";
import "./JoinWebinar.css";
import RegisterHeader from "../../layoutSection/registerHeader";
import RightBarTab from "../Register/rightBar";

class AttendWebinar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wid: undefined,
      token: undefined,
      loggedIn: false,
      name: undefined,
      definedName: undefined,
      password: undefined,
      isFormSubmit: false,
    };
  }

  componentDidMount = () => {
    const query = queryString.parse(this.props.location.search);
    let userInfo = decode(userconfigs.getItem("jwt"));

    if (typeof query.wid === "undefined") {
      this.props.history.push("/");
    } else {
      this.setState({
        wid: query.wid,
      });

      if (typeof query.token !== "undefined") {
        this.setState({
          token: query.token,
        });
      }

      if (userInfo.loginStatus !== 0) {
        this.setState({
          loggedIn: true,
          name: userInfo.name,
          definedName: userInfo.name,
        });
      }
      if (query.wid !== "") {
        if (query.token !== "undefined") {
          this.props.webinarBitsFun(query.wid, "attendee", query.token);
          this.props.webinar_info(query.wid);
          this.props.webinarFields(query.wid);
        } else {
          this.props.webinarBitsFun(query.wid, "attendee");
          this.props.webinar_info(query.wid);
          this.props.webinarFields(query.wid);
        }
      }
    }
  };

  successHandler = (e) => {
    e.preventDefault();
    const { wid, token, name, definedName, password } = this.state;
    this.setState({
      isFormSubmit: true,
    });
    if (wid && definedName) {
      this.props.addToWebinarRoomFun(
        wid,
        token,
        "attendee",
        definedName,
        password
      );
      //console.log('submittteeeeeddddd',wid,name)
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    //const values = queryString.parse(this.props.location.search)
    //let userInfo = decode(userconfigs.getItem("jwt"));
    const { wid, token, definedName, password, loggedIn } = this.state;
    if (prevProps.webinarBits !== this.props.webinarBits) {
      if (this.props.webinarBits.isMeetingEnded === 1) {
        this.props.history.push({
          pathname: "/webinar_info",
          state: {
            wid: wid,
            wname: this.props.webinarBits.name,
            heading: "Webinar has already been ended.",
            msg: "The webinar you are trying to join has already ended, Please check your webinar link.",
          },
        });
      }
      if (this.props.webinarBits.authentic === true) {
        if (loggedIn !== 1) {
          this.props.history.push({
            pathname: "/webinar_info",
            state: {
              wid: wid,
              wname: this.props.webinarBits.name,
              heading: "This webinar is for signed in user only.",
              msg: "The webinar you are trying to join is required your sign in, please sign in first then try again.",
            },
          });
        }
      }
      let joinName = definedName;
      //console.log("222jjjjjjjjjj",this.props.webinarBits.joinName,joinName)
      if (typeof this.props.webinarBits.joinName !== "undefined") {
        //console.log("222jjjjjjjjjj",this.props.webinarBits.joinName,joinName)
        if (this.props.webinarBits.joinName !== "") {
          joinName = this.props.webinarBits.joinName;
        }
        //console.log("jjjjjjjjjj",joinName)
        this.setState({
          name: joinName,
          definedName: joinName,
        });

        if (
          this.props.webinarBits.webinarPwdBit === false &&
          joinName !== "" &&
          joinName !== undefined
        ) {
          //this.instantJoin(this.state.wid, this.props.webinarBits.speakerName);
          this.props.addToWebinarRoomFun(
            wid,
            token,
            "attendee",
            joinName,
            password
          );
        }
      }
    }

    if (prevProps.addedInWebinar !== this.props.addedInWebinar) {
      //userconfigs.setItem('webiName',this.state.name);
      this.props.history.push(
        "/webinar_waiting_room?wid=" +
          wid +
          "&token=" +
          this.props.webinarRoomResp
      );
      // this.props.history.push('/waiting_room_webinar?wid='+wid);
    }

    // if(this.props.error || (typeof this.props.joinError !== "undefined" && this.props.joinError)){
    // 	if(userInfo.loginStatus === 1){
    // 		this.props.history.push("/dashboard")
    // 	}
    // 	else{
    // 		this.props.history.push("/")
    // 	}
    // 	return true;
    // }

    // if(prevProps.conferenceBits !== this.props.conferenceBits){
    // 	if(userInfo.loginStatus === 0) {
    // 		this.props.history.push({
    // 		  pathname: '/webinar_message',
    // 		  state: { mid: this.props.conferenceBits.meetingID, mname:this.props.conferenceBits.name }
    // 		})
    // 	}
    // 	if(typeof userInfo.name !== "undefined"){
    //     	this.successHandler(values.mid,userInfo.name);
    //     }
    // }
  }

  render() {
    const { bitsLoding, webinarBits, addingInWebinar, webinarInfo } =
      this.props;
    const { mid, password, name, definedName, isFormSubmit } = this.state;
    // if(typeof url !== "undefined"){
    // 	if(typeof url.moderatorJoined !== 'undefined'){
    // 		return <Redirect to={"/waitingRoom/"+mid} />
    // 	}
    // }
    // if(bitsLoding){
    // 	return (
    // 		<div className="attendy_name_bx">
    //         	<div className="wxr_title">Getting information...
    //               <span><LoadSpinner /></span>
    //             </div>
    //         </div>
    // 	)
    // }
    return (
      // sarvwave_thme_bg
      <div>
        {/* middle_box_area */}
        <div>
          <div className="wave_logo_part">
            <Link to="/"></Link>
          </div>
          {bitsLoding ? (
            <div className="attendy_name_bx">
              <div className="wxr_title">
                Getting information...
                <span>
                  <LoadSpinner />
                </span>
              </div>
            </div>
          ) : webinarBits.webinarPwdBit || name === undefined || name === "" ? (
            <>
              <div>
                <RegisterHeader webinarInfo={webinarInfo} />

                <div
                  className="wave_webinar_reg py-0"
                  style={{ minHeight: "auto" }}
                >
                  <div className="container-fluid reg_form_vlog">
                    <div className="row">
                      {/* ***************** left div start here */}

                      <div className="col-lg-6 bg-cls-img h-100 pb-5">
                        <div
                          className="inner_from_part px-5 py-5"
                          style={{ verticalAlign: "middle" }}
                        >
                          <h2 className="text-white text-left font-weight-light">
                            Join Webinar
                            <span
                              className="text-white text-left mb-5 font-weight-light"
                              style={{
                                fontSize: "14px",
                                display: "block",
                                marginTop: "7px",
                              }}
                            >
                              {" "}
                              Please enter details to join the webinar
                            </span>
                          </h2>

                          <div className="wave_display_form">
                            <Form onSubmit={this.successHandler.bind(this)}>
                              <input
                                style={{ display: "none" }}
                                type="text"
                                name="fakeusernameremembered"
                              />
                              <input
                                style={{ display: "none" }}
                                type="password"
                                name="fakepasswordremembered"
                              />
                              <div className="form-group reg_field">
                                {name !== undefined && name !== "" ? (
                                  <Input
                                    className="form-control inputColorTheme"
                                    disabled={true}
                                    autoComplete="new-name"
                                    name="definedName"
                                    value={definedName}
                                    placeholder="Your Name "
                                  />
                                ) : (
                                  <Input
                                    className="form-control inputColorTheme"
                                    onChange={this.handleChange}
                                    autoComplete="new-name"
                                    name="definedName"
                                    value={definedName}
                                    placeholder="Your Name "
                                  />
                                )}
                                {isFormSubmit && !definedName && (
                                  <div className="help-block j_error_msg">
                                    Please enter your name !
                                  </div>
                                )}
                              </div>

                              {webinarBits.webinarPwdBit ? (
                                <div
                                  className={
                                    "form-group" +
                                    (isFormSubmit && !password
                                      ? " has-error"
                                      : "")
                                  }
                                >
                                  <Input
                                    className="form-control"
                                    autoComplete="new-password"
                                    type="password"
                                    name="password"
                                    value={password}
                                    onChange={this.handleChange}
                                    placeholder="Webinar Password"
                                  />
                                  {isFormSubmit && !password && (
                                    <div className="help-block j_error_msg">
                                      Please enter the password of this webinar
                                      !
                                    </div>
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                              {addingInWebinar ? (
                                <div className="form-group mb-2 mt-4 pt-2 text-center">
                                  <Button
                                    type="button"
                                    className="meeting_enter_btn"
                                    color="success"
                                  >
                                    <Spinner size="sm" color="light" /> Joining
                                    Webinar...
                                  </Button>
                                </div>
                              ) : (
                                <div className="form-group mb-2 mt-4 pt-2 text-center">
                                  <Button
                                    type="submit"
                                    className="meeting_enter_btn"
                                    color="success"
                                  >
                                    <i className="fas fa-sign-in-alt"></i> Enter
                                    in Webinar
                                  </Button>
                                </div>
                              )}
                            </Form>
                          </div>
                        </div>
                      </div>

                      {/* ***************** left div start here */}

                      {/* ***************** right div start here */}
                      <RightBarTab
                        webinarInfo={webinarInfo}
                        webinarID={this.state.wid}
                      />

                      {/* ***************** right div end here */}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="attendy_name_bx">
              <div className="wxr_title">
                Joining Webinar...
                <span>
                  <LoadSpinner text={""} />
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapState(state) {
  const {
    bitsLoding,
    webinarBits,
    webinarInfo,
    addingInWebinar,
    addedInWebinar,
    webinarRoomResp,
    webinarFields,
  } = state.webinar;
  return {
    bitsLoding,
    webinarBits,
    webinarInfo,
    addingInWebinar,
    addedInWebinar,
    webinarRoomResp,
    webinarFields,
  };
}
const actionCreators = {
  webinarBitsFun: webinarActions.webinarBits,
  addToWebinarRoomFun: webinarActions.addToWebinarRoom,
  webinar_info: webinarActions.webinarInfo,
  webinarFields: webinarActions.webinarFields,
  alertError: alertActions.error,
};

export default withRouter(connect(mapState, actionCreators)(AttendWebinar));
