import { joinConstants } from '../_constants';

const initialState = { 
  loadingStatus: false, 
  url: {},
  loadingStatusAttendee:false,
  urlAttendee:{},
  loadingStatusHost:false,
  urlHost:{} 
};
export function joinConference(state = initialState, action) {
  switch (action.type) {
    case joinConstants.JOIN_REQUEST:
      return {
        loadingStatus: true,
        url: {}
      };
    case joinConstants.JOIN_SUCCESS:
      return {
        loadingStatus: false,
        url: action.resp,
        joinError:false
      };
    case joinConstants.JOIN_FAILURE:
      return {
        loadingStatus:false,
        joinError:true
      };

      case joinConstants.HOST_JOIN_REQUEST:
      return {
        loadingStatusHost: true,
        urlHost: {}
      };
    case joinConstants.HOST_JOIN_SUCCESS:
      return {
        loadingStatusHost: false,
        urlHost: action.resp,
        joinErrorHost:false
      };
    case joinConstants.HOST_JOIN_FAILURE:
      return {
        loadingStatusHost:false,
        joinErrorHost:true
      };

    case joinConstants.ATTENDEE_JOIN_REQUEST:
      return {
        loadingStatusAttendee: true,
        urlAttendee: {}
      };
    case joinConstants.ATTENDEE_JOIN_SUCCESS:
      return {
        loadingStatusAttendee: false,
        urlAttendee: action.resp,
        joinErrorAttendee:false
      };
    case joinConstants.ATTENDEE_JOIN_FAILURE:
      return {
        loadingStatusAttendee:false,
        joinErrorAttendee:true
      };
      
    default:
      return state
  }
}