import React, { Component } from 'react';
import { Input, Form, FormGroup, Label, Button, Col, Row, CustomInput } from 'reactstrap';
import './CreateRole.scss';
import { getModuleAccessOnbasisOfRole } from './../../../../helpers/helpers';
import "react-datepicker/dist/react-datepicker.css";
import LeftPanelManager from '../../../components/LeftPanelManager/LeftPanelManager';
import CustomPermission from './components/CustomPermission/CustomPermission';
import { roleActions } from './../../../../Redux/_actions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';





class CreateRole extends Component {

    constructor(props) {
        super(props)
        this.state = {
            modal: false,
            roleForm: {
                role_name: '',
                permission: 'executive',
                isCustomPermission: false,
                modulesPermissions: getModuleAccessOnbasisOfRole('executive')
            },
            isFormSubmitted: false,
            formError: {},
            isFormValid: false,
        }
    }

    async handleUserInput(e) {
        const name = e.target.name;
        const value = e.target.value;
        const data = { ...this.state.roleForm };
        data[name] = value;
        if (name === 'permission') {
            data['modulesPermissions'] = getModuleAccessOnbasisOfRole(value)
        }
        await this.setState({ ...this.state.roleForm, roleForm: data });
        await this.vallidateForm();

    }

    shouldComponentUpdate(nextProps, nextState) {
		if(this.props.isRoleAdded !== nextProps.isRoleAdded){
			this.props.history.push("/eoffice/Setting/list-role")
		}
		return true;
    }

    async submitForm(event) {
        event.preventDefault();
        await this.setState({ isFormSubmitted: true });
        await this.vallidateForm();
        if (this.state.isFormValid && this.state.isFormSubmitted) {
            this.props.addRole(this.state.roleForm);
        }
    }

    async vallidateForm() {
        if (this.state.isFormSubmitted) {
            let errorObj = {};
            const formData = this.state.roleForm;
            if (formData.role_name === '') {
                errorObj.role_name = 'Please enter role';
            }

            let formValid;
            if (Object.keys(errorObj).length === 0) {
                formValid = true;
            } else {
                formValid = false;
            }

            await this.setState({ formError: errorObj, isFormValid: formValid });

        }
    }

    showModal = async () => {
        const data = { ...this.state.roleForm };
        data['isCustomPermission'] = !data.isCustomPermission;
        data['modulesPermissions'] = getModuleAccessOnbasisOfRole(this.state.roleForm.permission)
        await this.setState({ ...this.state.roleForm, roleForm: data });
        // await this.setState({ modal: !this.state.modal });
    }

    async changeModulePermission(mName, mPermission, index) {
        const data = { ...this.state.roleForm };
        const module_permissions = data.modulesPermissions;
        module_permissions[index].permission = !mPermission;
        data['modulesPermissions'] = module_permissions;
        await this.setState({ ...this.state.roleForm, roleForm: data });

    }

    render() {
        const { modal } = this.state
        return (
            <div className="createDepart-container">
                {this.state.roleForm.isCustomPermission && <CustomPermission
                    modal={modal}
                    showModal={this.showModal}
                    modulesPermissions={this.state.roleForm.modulesPermissions}
                    changeModulePermission={(name, permission, index) => this.changeModulePermission(name, permission, index)}
                />}

                <LeftPanelManager></LeftPanelManager>
                <div className="pageContentpanel">
                    <div className="createDepart-FormSection">
                        <h2 className="title">Create Role</h2>
                        <p className="sub-title">You could be a business, an event organizer, or a professional community. Please fill below detail.</p>
                        <Row className="formRow">
                            <Col sm="6">
                                <Form onSubmit={(event) => this.submitForm(event)}>
                                    <FormGroup className="form-col">
                                        <Label className="title-label">Role </Label>
                                        <Input type="text" name="role_name" onChange={(e) => this.handleUserInput(e)} placeholder="Enter Role" />
                                    </FormGroup>
                                    {this.state.isFormSubmitted && this.state.formError.hasOwnProperty('role_name') && <div className="error text-danger" >{this.state.formError.role_name}</div>}

                                    <FormGroup className="form-col">
                                        <Label className="title-label">Access Level</Label>
                                        <CustomInput type="select" onChange={(e) => this.handleUserInput(e)} name="permission" value={this.state.roleForm.permission}>
                                            <option value="admin">Admin</option>
                                            <option value="manager">Manager</option>
                                            <option value="lead">Team Lead</option>
                                            <option value="executive">Executive</option>

                                        </CustomInput>
                                    </FormGroup>

                                    <FormGroup className="form-col form-custom-checkbox">
                                        <Label className="title-label">Custom Permission</Label>
                                        <CustomInput type="checkbox" checked={this.state.roleForm.isCustomPermission} id="exampleCustomCheckbox" label="Click for permission" onClick={this.showModal} />
                                    </FormGroup>
                                    {this.state.roleForm.isCustomPermission &&
                                        <Row>
                                            {this.state.roleForm.modulesPermissions.map((module, index) => (
                                                <Col sm="6">
                                                    <FormGroup className="form-col">
                                                        <Row>
                                                            <Col sm="6"><Label className="title-label">{module.name}</Label></Col>
                                                            <Col sm="6" className="text-right">
                                                                <input type="checkbox" checked={module.permission} id="exampleCustomSwitch" onChange={() => this.changeModulePermission(module.name, module.permission, index)} name="module_permission" />
                                                            </Col>
                                                        </Row>
                                                    </FormGroup>
                                                </Col>
                                            ))}

                                        </Row>
                                    }
                                    <Col sm="12" className="text-center mt-5">
                                        <Button className="btn-theme-primary">Create</Button>
                                    </Col>

                                </Form>
                            </Col>
                            <Col sm="6" className="img-section">
                                <img src="../images/eoffice/role-graphic.svg" className="img-fluid" alt=""  ></img>

                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        )
    }
}



const mapState = (state) => {
    const { teamData } = state.teamsInfo;
    const { departmentList } = state.department;
    const { profileInfo } = state.authentication;
    const { isRoleAdded } = state.roles

    return {
        teamData,
        departmentList,
        profileInfo,
        isRoleAdded
    };
}

const actionCreators = {
    addRole: roleActions.addRole,
};

export default withRouter(connect(mapState, actionCreators)(CreateRole));
