import Images from "../images";

export const lightTheme = {
  body: "#EEEEEE !important",
  text: "#363537",
  textblck: "#000",
  toggleBorder: "#FFF",

  text_light: "#6a6c6e!important",
  text_milky: "#615d5d",
  text_90: "rgba(0,0,0,.7)",
  text_50: "rgba(0,0,0,.5)",

  theme_bg: "#fff",
  modalbg: "#fff",

  homepagebg: "#f2f0f0",

  hdr_logo: `url(${Images.logoImgWhiteTheme})`,

  headerbg: "#EDF0F5",
  header_shadow: "0 10px 10px -10px #44444478",
  menulinkcolor: "#000000",
  menulinkcolor_hover: "#70726f",
  drop_menu_hover: "#d2d1d2",
  blankAndWhite: "#000000",
  bgTheme: "#efeff1",
  borderColor: "#78767640",
  noteText: "#707789   ",
  stepFormSectionActive: "#c5c5c7",
  bgWhite: "#ffffff",
  SideBar: "#F9F9F9",
  SideBarIcon: "#D7D8DA",
  disabled: '#e9ecef',
  accordionHeader: '#adb5c7',
  meetCardBg: '#F9F9F9',
  meetListBlock: '#ffffff',
  meetCardBroder: '#EBEBEB',

  join_m_btnborder: "solid 1px #005BDD",
  join_m_btncolor: "#005BDD",
  join_m_btnbg: "#fff",

  skyopacity: ".3",

  login_btnbg: "#f3bb14",
  login_btncolor: "#fff",

  homepage_banner: "url(/images/homepage_banner_white.png)",

  login_bg: "hsla(216, 33%, 97%, 1)",
  login_page_shadow: "0 0px 6px hsla(0, 0%, 0%, 0.3)",

  dashboard_box_bg: "#edf0f5",
  dashboard_box_bg_s_mtng: "#fff",

  total_meeting_count_bg: "#B7F5ED75",
  total_s_meeting_bg: "#fd552c17",
  share_doc_count_bg: "#FFCEF087",
  total_conacts_bg: "#b8d5f84f",

  t_metting_c: "#30978A",
  t_sc_meeting_c: "#DB301E",
  t_sharddoc_c: "#ca1e60",
  t_contct_c: "#1d416c",

  t_metting_brdr: "#5ee3cb42",
  t_sc_meeting_brdr: "#fd552c3d",
  t_sharddoc_brdr: "#e414643b",
  t_contct_brdr: "#356bad40",

  counter_bx_brd_radius: "0px",
  alternate_font: "Comfortaa",

  db_sh_list_bg: "#E0E3E9C7",

  db_upcoming_bx_shadow: "0 0px 4px hsla(0, 0%, 0%, 0.3)",

  db_doc_td_brdr: "rgba(86, 82, 82, 0.26)",

  alert_popup_bg: "#fff",
  alert_popup_text: "#33303A",

  filemanager_main_bg: "#f8f8f8",
  filemngr_main_brder: "#ccc",
  filemngr_btn_bg: "#f8f8f8",
  tab_btn_hvr_bg: "#e6e6e6",
  tab_btn_hvr_brdr: "#8c8c8c",
  bg_282330: "#fff",
  bg_36323c: "#ddd",
  bg_1d1923: "#317ab9",
  shadw_none: "inherit",
  brdr_ffffff1f: "#e0e0e0",
  transparent_drk_mode: "inherit",
  bg_ffffff0a: "#f2f2f2",
  bg_f5f5f521: "#f5f5f5",
  brdr_dddddd26: "inherit",
  e_headertext: "#000",
  e_gridheader: "inherit",
  bg_00000029: "#f5f5f5",
  drkmode_shadow_none: "inherit",
  bg_382a972b: "#317ab9",
  bg_282330_hvr: "#f5f5f5",
  bg_list_evhover: "#f5f5f5",
  bg_ee_hvr: "#ddd",
  doc_thmb_brdr: "#ddd",
  doc_thmb_text: "#555",
  doc_thmb_actv_bg: "#317ab9",
  doc_thmb_hovr_bg: "#666",
  model_bg: "#fff",
  model_border: "#fff",
  model_hdr_txt: "#333",
  modal_close_txt: "#333",

  custom_modal_bg: "#fff",
  textmodal_heading: "#ce165e",
  share_code_brdr: "#100f0f30",
  sharecode_text: "#1758e3",

  meeting_btn_shadow: "0 3px 9px rgba(0, 0, 0, 0.3)",
  new_btn_hover_clr: "#30AB95",
  tr_hover_bg: "#eee",
  th_brdr: "#d7d9dc",
  tdbrdr: "#dee2e6",
  text_999: "#999",
  text_blue_white: "#1c77ba",
  f_weight_opp: "400",
  input_brdr_color: "#0000003b",
  upcoming_meeting_bg: "#2776dc",

  text_black_white: "#222",
  create_label: "#212529",
  toggle_bg: "#949494f0",
  toggle_activebg: "#64D482",
  toggle_label_txt: "#666",
  tab_border: "#aea7a7",
  brdr_only_whitetheme: "#aea7a7",
  tab_nav_active_bg: "#1758e3",
  tab_nav_bg: "#edf0f5",
  tab_nav_hovr_brdr: "#aea7a7",
  input_addon_brdr: "#0000003b #0000003b #0000003b transparent",
  tab_nav_active_brdr: "#1758e3",

  meeting_list_bg: "#edf0f5",
  text_orange: "#eb4809",
  meeting_detail_bg: "#edf0f5",
  detail_page_hr: "#4c4a4a21",
  m_innerbx_bg: "#00000017",
  calander_btn_bg: "#007bff",
  metting_date_txt: "#2783e6",
  metting_time_txt: "#e27f17",
  text_warning: "#e75938",
  invite_url_bg: "#34343530",
  copy_btn_bg: "#8E8E8E",
  copy_btn_txt: "#fff",
  modal_input_brdr: "#0000003b",
  recrd_video_bg: "#d0d0d0",

  text_blue_webinar: "#2085f2ed",
  spkrlist_brdr: "#201e1e0f",
  brand_input_brdr: "#0000003b",
  brand_input_bg: "#ffffffa6",
  custom_danger_bg: "#e52a3b",
  custom_primary_bg: "#0062cb",
  custom_warning_bg: "#e4ad08",
  text_l_orange_d_blue: "#1758e3",
  hst_blck_sprtr: "#4c4a4abf",
  contrycode_flg_bg: "#ddd",
  contrycode_srch_bg: "#fff",
  contrycode_listhover_bg: "#3b3a3a40",
  timezone_option_bg: "#eee",
  profile_bx_bg: "#edf0f5",
  ac_holdr_name: "#000",
  edit_profile_form_bg: "#ffffffb0",
  edit_profile_form_brdr: "#6f6e6e4a",
  no_share_dc_bg: "#dbdbdb",

  waiting_room_block_bg: "#edf0f5",
  msg_prtcpnt_bg: "#2E2A351F",
  msg_prtcpnt_color: "#413f3f",

  share_file_respo_td: "#edf0f5",
  webinarbg: "#fff",
  webinar_pg_brdr: "#85838333",
  text_active_webinartab: "#0052cc",
  speaker_bg: "#8685852e",
  attendee_srch_bg: "#fff",
  nodatabgwebinar: "#8c838321",
  webinar_chat_type_bg: "#d6d6d6",
  chat_bubble_bg: "#CBCBCC",
  question_text_color: "#0052cc",
  answer_bx_bg: "#ffffffba",
  wave_actin_bg: "#FFFFFF",
  dasbord_counter_bx_bg: "#FFFFFF",
  wave_actin_bg_hov: "#FFFFFF",
  note_list_txt: "#666",
  icon_info_bg: "#edf0f5",
  icon_info_bg_text: "#666",
  recording_col_bg: "#edf0f5",
  recording_icon_bg: "#969ba2",
  form_control_theme: "#ffffff",
  colorOffDark: "#f5f5f5",
  form_control_border: "#ccc",

  time_picker: "#ccc",
  btnPrim: '206,22,94',
  textMeetId: '#4BA892',
  textBdrBtn: '#3F3F3F',
  sidebarBorder: '#EBEBEB',
  smallCardBg: '#404351',
  textPrimary: '#CE165E',
};

export const darkTheme = {
  body: "#1F222B !important",
  text: "#FAFAFA",
  textblck: "#fff",
  toggleBorder: "#6B8096",
  themecolor: "#1e202d",
  wave_actin_bg: "#1e202d",
  wave_actin_bg_hov: "#0b0b14",

  text_milky: "#f8f9facf",
  text_light: "#f8f9fa !important",
  text_90: "rgba(256,256,256,.7)",
  text_50: "rgba(256,256,256,.5)",

  theme_bg: "#0b0b14",
  modalbg: "#1e202d",
  homepagebg: "#0b0b14",
  dasbord_counter_bx_bg: "#0b0b14",

  hdr_logo: `url(${Images.logoImgBlackTheme})`,
  // hdr_logo: "url(/images/sarv-wave-white-bg.png)",

  headerbg: "#151723",
  header_shadow: "none",
  menulinkcolor: "#FFF",
  menulinkcolor_hover: "#c4bfbf",
  drop_menu_hover: "#1d1923",

  blankAndWhite: "#FFFFFF",
  bgTheme: "#1e202d",
  borderColor: "#ffffff40",
  noteText: "#b2bede",
  stepFormSectionActive: "#4e4f59",
  bgWhite: "#1e202d",
  SideBar: "#282C35",
  SideBarIcon: "#3D424E",
  disabled: '#5c6273',
  accordionHeader: '#3D424E',

  join_m_btnborder: "solid 1px #fff",
  join_m_btncolor: "#fff",
  join_m_btnbg: "#0b0b14",

  skyopacity: "1",

  login_btnbg: "#fff",
  login_btncolor: "#0b0b14",

  homepage_banner: "url(/images/homepage_banner-indian.png)",

  login_bg: "#0b0b14",
  login_page_shadow: "0 3px 6px hsla(0, 0%, 0%, 0.1)",

  dashboard_box_bg: "#1e202d",
  dashboard_box_bg_s_mtng: "#1e202d",

  total_meeting_count_bg: "#0b0b14",
  total_s_meeting_bg: "#0b0b14",
  share_doc_count_bg: "#0b0b14",
  total_conacts_bg: "#0b0b14",

  t_metting_c: "#FAFAFA",
  t_sc_meeting_c: "#FAFAFA",
  t_sharddoc_c: "#FAFAFA",
  t_contct_c: "#FAFAFA",

  t_metting_brdr: "#5ee3cb",
  t_sc_meeting_brdr: "#fd552c",
  t_sharddoc_brdr: "#e41464",
  t_contct_brdr: "#356bad",

  counter_bx_brd_radius: "5px",
  alternate_font: "Roboto",

  db_sh_list_bg: "#191b27",

  db_upcoming_bx_shadow: "none",

  db_doc_td_brdr: "rgba(256,256,256,.1)",

  alert_popup_bg: "#1e202d",
  alert_popup_text: "#fff",

  filemanager_main_bg: "#1e202d",
  filemngr_main_brder: "#fefefe17",
  filemngr_btn_bg: "#27232f",
  tab_btn_hvr_bg: "#5057f0",
  tab_btn_hvr_brdr: "#5057f0",
  bg_282330: "#1e202d",
  bg_36323c: "#36323c",
  bg_1d1923: "#1e202d",
  shadw_none: "none",
  brdr_ffffff1f: "#ffffff1f",
  transparent_drk_mode: "transparent",
  bg_ffffff0a: "#ffffff0a",
  bg_f5f5f521: "#f5f5f521",
  brdr_dddddd26: "#dddddd26",
  e_headertext: "#fff",
  e_gridheader: "#ce165e",
  bg_00000029: "#00000029",
  drkmode_shadow_none: "none",
  bg_382a972b: "#382a972b",
  bg_282330_hvr: "#282330",
  bg_list_evhover: "#f5f5f521",
  bg_ee_hvr: "",
  doc_thmb_brdr: "#ffffff29",
  doc_thmb_text: "#d0d0d0",
  doc_thmb_actv_bg: "#382a972b",
  doc_thmb_hovr_bg: "#382a972b",
  model_bg: "#3c3d42",
  model_border: "#f8f8f817",
  model_hdr_txt: "#ffffffab",
  modal_close_txt: "#ffffff75",

  custom_modal_bg: "#33303A",
  textmodal_heading: "#fff",
  share_code_brdr: "#100f0f30",
  sharecode_text: "#fbb548",

  meeting_btn_shadow: "0 3px 20px rgba(0, 0, 0, 0.3)",
  new_btn_hover_clr: "#62ECD3",
  tr_hover_bg: "#1b1920",

  th_brdr: "#dee2e626",
  tdbrdr: "#dee2e614",
  text_999: "",
  text_blue_white: "#fff",
  f_weight_opp: "100",

  input_brdr_color: "#ffffff2b",
  upcoming_meeting_bg: "#211c1cc9",

  text_black_white: "#fff",
  create_label: "#ffffffd6",
  toggle_bg: "#f0f0f033",
  toggle_activebg: "#1cc98c",
  toggle_label_txt: "#c3c3c3",
  tab_border: "#ffffff21",
  brdr_only_whitetheme: "transparent",
  tab_nav_active_bg: "#5057f0",
  tab_nav_bg: "#ffffff12",
  tab_nav_hovr_brdr: "#ffffff21",
  input_addon_brdr: "#ffffff21 #ffffff21 #ffffff21 transparent",
  tab_nav_active_brdr: "#ffffff21 ",

  meeting_list_bg: "#282C35",
  text_orange: "#fba448",
  meeting_detail_bg: "#1e202d",
  detail_page_hr: "#fff3",
  m_innerbx_bg: "#ffffff17",
  calander_btn_bg: "transparent",
  metting_date_txt: "#8ce7fdd6",
  metting_time_txt: "#d2bb40",
  text_warning: "#ffc107",
  invite_url_bg: "#f0f8ff21",
  copy_btn_bg: "#FFFEFC",
  copy_btn_txt: "#6c5f5f",
  modal_input_brdr: "#ffffff9e",
  recrd_video_bg: "currentColor",

  text_blue_webinar: "#4da0faed",
  spkrlist_brdr: "#ffffff0f",
  brand_input_brdr: "#fff3",
  brand_input_bg: "#f0f8ff21",
  custom_danger_bg: "#dc3545ab",
  custom_primary_bg: "#007bff54",
  custom_warning_bg: "#ffc10782",
  text_l_orange_d_blue: "rgb(252, 144, 93)",
  hst_blck_sprtr: "#ffffff21",

  contrycode_flg_bg: "#1d1923",
  contrycode_srch_bg: "#26222c",
  contrycode_listhover_bg: "#f1f1f117",
  timezone_option_bg: "#1e202d",
  timezone_option_text: "#FFF",
  profile_bx_bg: "#1e202d",
  ac_holdr_name: "#f8f9facf",
  edit_profile_form_bg: "#ffffff0a",
  edit_profile_form_brdr: "#ffffff21",
  no_share_dc_bg: "#282330s",

  waiting_room_block_bg: "#0000005c",
  msg_prtcpnt_bg: "#2E2A35",
  msg_prtcpnt_color: "#868686",
  share_file_respo_td: "#1b1920",

  webinarbg: "#0b0b14 ",
  webinar_pg_brdr: "#dddddd12",
  text_active_webinartab: "#ffae14",
  speaker_bg: "#f2f2f20f",
  attendee_srch_bg: "#ffffff1f",
  nodatabgwebinar: "#ffffff12",
  webinar_chat_type_bg: "#151723",
  chat_bubble_bg: "#2E2A35",
  question_text_color: "#eda721",
  answer_bx_bg: "#ffffff17",
  icon_info_bg: "#4e505f",
  icon_info_bg_text: "#FFFFFF",
  recording_col_bg: "#272938",
  recording_icon_bg: "#42465e",
  form_control_theme: "#393e4a",
  form_control_border: "#464f60",
  colorOffDark: "#393e4a",
  meetCardBg: '#1F222B',
  meetListBlock: '#282C35',
  meetCardBroder: '#1f1f1f',
  btnPrim: '3,200,71',
  textMeetId: '#47aeb2',
  textBdrBtn: '#969696',
  sidebarBorder: '#414856',
  smallCardBg: '#404351',
  textPrimary: '#ffffff',
};
