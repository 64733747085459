import { webinarConstants } from "../_constants";
import { webinarService } from "../_services";
import { alertActions } from "./";

export const webinarActions = {
  schedule,
  webinarList,
  webinarDetail,
  webinarFields,
  register,
  updateWebinar,
  addMoreParticipant,
  deleteInviteUser,
  addMoreHost,
  webinarInfo,
  addBranding,
  webinarBrand,
  cancelWebinar,
  deleteWebinar,
  removeWebinarBrand,
  deletewebinarBrand,
  webinarBits,
  joinWebinar,
  joinAttendeeWebinar,
  addToWebinarRoom,
  removeWebinarSpeaker,
  editSpeakerDetail,
  editSpeakerUpdate,
  getAttendees,
  reInviteUser
};

function schedule(fields) {
  return (dispatch) => {
    dispatch(request({ fields }));

    webinarService.schedule(fields).then(
      (resp) => {
        dispatch(alertActions.success("Successfully Webinar scheduled!"));
        dispatch(success(resp));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error.toString()));
      }
    );
  };

  function request(resp) {
    return { type: webinarConstants.SCHEDULE_REQUEST, fields };
  }
  function success(resp) {
    return { type: webinarConstants.SCHEDULE_SUCCESS, resp };
  }
  function failure(error) {
    return { type: webinarConstants.SCHEDULE_FAILURE, error };
  }
}

function getAttendees(webinarId) {
  return (dispatch) => {
    dispatch(request());

    webinarService.getAttendees(webinarId).then(
      (resp) => {
        console.log(resp);
        dispatch(success(resp));
        // dispatch(alertActions.success("Successfully Webinar scheduled!"));
        //window.location.href = "/webinars/"+resp
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: webinarConstants.CSV_REQUEST };
  }
  function success(resp) {
    return { type: webinarConstants.CSV_SUCCESS, resp };
  }
  function failure(error) {
    return { type: webinarConstants.CSV_FAILURE, error };
  }
}

function webinarList(limit) {
  return (dispatch) => {
    dispatch(start("start"));

    webinarService.webinarList(limit).then(
      (resp) => {
        dispatch(success(resp));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error.toString()));
      }
    );
  };

  function start(start) {
    return { type: webinarConstants.LIST_REQUEST, start };
  }
  function success(resp) {
    return { type: webinarConstants.LIST_SUCCESS, resp };
  }
  function failure(error) {
    return { type: webinarConstants.LIST_FAILURE, error };
  }
}

function webinarDetail(webinarID) {
  return (dispatch) => {
    dispatch(request());

    webinarService.webinarDetail(webinarID).then(
      (resp) => {
        dispatch(success(resp));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        window.location.href = "/webinars";
        // dispatch(failure());
      }
    );
  };

  function request() {
    return { type: webinarConstants.DETAIL_REQUEST };
  }
  function success(resp) {
    return { type: webinarConstants.DETAIL_SUCCESS, resp };
  }
  //function failure() { return { type: webinarConstants.DETAIL_FAILURE } }
}

function webinarFields(webinarID) {
  return (dispatch) => {
    dispatch(request());

    webinarService.webinarFields(webinarID).then(
      (resp) => {
        dispatch(success(resp));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        // window.location.href = "/webinars";
        // dispatch(failure());
      }
    );
  };

  function request() {
    return { type: webinarConstants.FIELDS_REQUEST };
  }
  function success(resp) {
    return { type: webinarConstants.FIELDS_SUCCESS, resp };
  }
  //function failure() { return { type: webinarConstants.DETAIL_FAILURE } }
}

function register(fields) {
  return (dispatch) => {
    dispatch(request());

    webinarService.register(fields).then(
      (resp) => {
        dispatch(success(resp));
        dispatch(alertActions.success(resp));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error.toString()));
      }
    );
  };

  function request(fields) {
    return { type: webinarConstants.REGISTER_REQUEST, fields };
  }
  function success(resp) {
    return { type: webinarConstants.REGISTER_SUCCESS, resp };
  }
  function failure() {
    return { type: webinarConstants.REGISTER_FAILURE };
  }
}

function addMoreParticipant(formData) {
  return (dispatch) => {
    dispatch(start("Request"));
    webinarService.addMoreParticipant(formData).then(
      (response) => {
        dispatch(alertActions.success(response));
        dispatch(webinarActions.webinarDetail(formData.webinarID));
        dispatch(success(response));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error.toString()));
      }
    );
  };

  function start(start) {
    return { type: webinarConstants.ADD_PARTICIPANT_START };
  }
  function success(response) {
    return { type: webinarConstants.ADD_PARTICIPANT_SUCCESS, response };
  }
  function failure(error) {
    return { type: webinarConstants.ADD_PARTICIPANT_FAILURE };
  }
}

function addMoreHost(formData, multiPart) {
  return (dispatch) => {
    dispatch(start("Request"));
    webinarService.addMoreHost(formData, multiPart).then(
      (response) => {
        dispatch(alertActions.success(response));
        dispatch(success(response));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error.toString()));
      }
    );
  };

  function start(start) {
    return { type: webinarConstants.ADD_HOST_START };
  }
  function success(response) {
    return { type: webinarConstants.ADD_HOST_SUCCESS, response };
  }
  function failure(error) {
    return { type: webinarConstants.ADD_HOST_FAILURE };
  }
}

function removeWebinarSpeaker(spakerID) {
  return (dispatch) => {
    dispatch(start("Request"));
    webinarService.removeWebinarSpeaker(spakerID).then(
      (response) => {
        dispatch(alertActions.success(response));
        dispatch(success(response));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error.toString()));
      }
    );
  };

  function start(start) {
    return { type: webinarConstants.REMOVE_SPEAKER_START };
  }
  function success(response) {
    return { type: webinarConstants.REMOVE_SPEAKER_SUCCESS, spakerID };
  }
  function failure(error) {
    return { type: webinarConstants.REMOVE_SPEAKER_FAILURE };
  }
}

function editSpeakerDetail(spakerID) {
  return (dispatch) => {
    dispatch(start("Request"));
    webinarService.editSpeakerDetail(spakerID).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error.toString()));
      }
    );
  };

  function start(start) {
    return { type: webinarConstants.SPEAKER_DETAIL_START };
  }
  function success(response) {
    return { type: webinarConstants.SPEAKER_DETAIL_SUCCESS, response };
  }
  function failure(error) {
    return { type: webinarConstants.SPEAKER_DETAIL_FAILURE };
  }
}

function editSpeakerUpdate(formData, multipart) {
  return (dispatch) => {
    dispatch(start("Request"));
    webinarService.editSpeakerUpdate(formData, multipart).then(
      (response) => {
        dispatch(alertActions.success(response));
        dispatch(success(response));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error.toString()));
      }
    );
  };

  function start(start) {
    return { type: webinarConstants.SPEAKER_UPDATE_START };
  }
  function success(response) {
    return { type: webinarConstants.SPEAKER_UPDATE_SUCCESS, response };
  }
  function failure(error) {
    return { type: webinarConstants.SPEAKER_UPDATE_FAILURE };
  }
}

function webinarInfo(webinarID) {
  return (dispatch) => {
    dispatch(request());

    webinarService.webinarInfo(webinarID).then(
      (resp) => {
        dispatch(success(resp));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure());
      }
    );
  };

  function request() {
    return { type: webinarConstants.INFO_REQUEST };
  }
  function success(resp) {
    return { type: webinarConstants.INFO_SUCCESS, resp };
  }
  function failure() {
    return { type: webinarConstants.INFO_FAILURE };
  }
}

function addBranding(type, fields) {
  return (dispatch) => {
    dispatch(request());
    webinarService.addBranding(type, fields).then(
      (resp) => {
        dispatch(success(resp));
        dispatch(alertActions.success(resp));
        dispatch(webinarActions.webinarBrand(fields.webinarID));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error.toString()));
      }
    );
  };

  function request(fields) {
    return { type: webinarConstants.BRANDING_REQUEST, fields };
  }
  function success(resp) {
    return { type: webinarConstants.BRANDING_SUCCESS, resp };
  }
  function failure() {
    return { type: webinarConstants.BRANDING_FAILURE };
  }
}

function webinarBrand(webinarID) {
  return (dispatch) => {
    dispatch(request());

    webinarService.webinarBrand(webinarID).then(
      (resp) => {
        dispatch(success(resp));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        // dispatch(failure());
      }
    );
  };

  function request() {
    return { type: webinarConstants.BRAND_REQUEST };
  }
  function success(resp) {
    return { type: webinarConstants.BRAND_SUCCESS, resp };
  }
  function failure() {
    return { type: webinarConstants.BRAND_FAILURE };
  }
}

function cancelWebinar(webinarID) {
  return (dispatch) => {
    dispatch(start());
    webinarService.cancelWebinar(webinarID).then(
      (response) => {
        dispatch(success(response));
        dispatch(alertActions.success("Webinar Cancel Successfully!"));
        window.location.href = "/webinars";
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error.toString()));
      }
    );
  };

  function start(start) {
    return { type: webinarConstants.WEBINAR_CANCEL_START };
  }
  function success(response) {
    return { type: webinarConstants.WEBINAR_CANCEL_SUCCESS, response };
  }
  function failure(error) {
    return { type: webinarConstants.WEBINAR_CANCEL_FAILURE, error };
  }
}

function deleteWebinar(webinarID) {
  return (dispatch) => {
    dispatch(start());
    webinarService.deleteWebinar(webinarID).then(
      (response) => {
        dispatch(success(response));
        dispatch(alertActions.success("Webinar Deleted Successfully!"));
        window.location.href = "/webinars";
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error.toString()));
      }
    );
  };

  function start(start) {
    return { type: webinarConstants.WEBINAR_DELETE_START };
  }
  function success(response) {
    return { type: webinarConstants.WEBINAR_DELETE_SUCCESS, response };
  }
  function failure(error) {
    return { type: webinarConstants.WEBINAR_DELETE_FAILURE, error };
  }
}

function removeWebinarBrand(webinarID, keyField) {
  return (dispatch) => {
    dispatch(start());
    webinarService.removeWebinarBrand(webinarID, keyField).then(
      (response) => {
        dispatch(success(response));
        dispatch(alertActions.success("Webinar Image Removed!"));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error.toString()));
      }
    );
  };

  function start(start) {
    return { type: webinarConstants.BRAND_KEY_REMOVE_START };
  }
  function success(response) {
    return { type: webinarConstants.BRAND_KEY_REMOVE_SUCCESS, response };
  }
  function failure(error) {
    return { type: webinarConstants.BRAND_KEY_REMOVE_FAILURE, error };
  }
}

function deletewebinarBrand(webinarID) {
  return (dispatch) => {
    dispatch(start());
    webinarService.deletewebinarBrand(webinarID).then(
      (response) => {
        dispatch(success(response));
        dispatch(alertActions.success("Webinar Brand Deleted Successfully!"));
        dispatch(webinarActions.webinarBrand(webinarID));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error.toString()));
      }
    );
  };

  function start(start) {
    return { type: webinarConstants.BRAND_DELETE_START };
  }
  function success(response) {
    return { type: webinarConstants.BRAND_DELETE_SUCCESS, response };
  }
  function failure(error) {
    return { type: webinarConstants.BRAND_DELETE_FAILURE, error };
  }
}

function webinarBits(wid, user_type, token) {
  return (dispatch) => {
    dispatch(start());
    webinarService.webinarBits(wid, user_type, token).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error.toString()));
      }
    );
  };

  function start(wid, token) {
    return { type: webinarConstants.GET_BITS_START, wid, token };
  }
  function success(response) {
    return { type: webinarConstants.GET_BITS_SUCCESS, response };
  }
  function failure(error) {
    return { type: webinarConstants.GET_BITS_FAILURE, error };
  }
}

function joinAttendeeWebinar(wid, name, user_type, token) {
  return (dispatch) => {
    dispatch(request({ wid, name }));

    webinarService.joinAttendeeWebinar(wid, name, user_type, token).then(
      (resp) => {
        dispatch(success(resp));
        //window.location.href = resp
        if (typeof resp !== "object") {
          // let newTab = window.open();
          // newTab.location.href = resp;
          //if(user_type==="speaker"){
          window.location.href = resp;
          //}
        }
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error.toString()));
      }
    );
  };

  function request(wid, name) {
    return { type: webinarConstants.JOIN_REQUEST, wid, name };
  }
  function success(resp) {
    return { type: webinarConstants.JOIN_SUCCESS, resp };
  }
  function failure(error) {
    return { type: webinarConstants.JOIN_FAILURE, error };
  }
}

function joinWebinar(wid, name, user_type, token) {
  return (dispatch) => {
    dispatch(request({ wid, name }));

    webinarService.joinWebinar(wid, name, user_type, token).then(
      (resp) => {
        dispatch(success(resp));
        //window.location.href = resp
        if (typeof resp !== "object") {
          // let newTab = window.open();
          // newTab.location.href = resp;
          //if(user_type==="speaker"){
          window.location.href = resp;
          //}
        }
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error.toString()));
      }
    );
  };

  function request(wid, name) {
    return { type: webinarConstants.JOIN_REQUEST, wid, name };
  }
  function success(resp) {
    return { type: webinarConstants.JOIN_SUCCESS, resp };
  }
  function failure(error) {
    return { type: webinarConstants.JOIN_FAILURE, error };
  }
}

function addToWebinarRoom(wid, token, user_type, name, password) {
  return (dispatch) => {
    dispatch(start());
    webinarService.addToWebinarRoom(wid, token, user_type, name, password).then(
      (response) => {
        dispatch(success(response));
        //history.push('/webinar_room?wid='+wid+'&token='+token);
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error.toString()));
      }
    );
  };

  function start(wid, token) {
    return { type: webinarConstants.ADD_IN_ROOM_START, wid, token };
  }
  function success(response) {
    return { type: webinarConstants.ADD_IN_ROOM_SUCCESS, response };
  }
  function failure(error) {
    return { type: webinarConstants.ADD_IN_ROOM_FAILURE, error };
  }
}

function deleteInviteUser(participantID) {
  return (dispatch) => {
    dispatch(start("Request"));
    webinarService.removeWebinarParticipant(participantID).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error.toString()));
      }
    );
  };

  function start(start) {
    return { type: webinarConstants.DELETE_INVITE_START };
  }
  function success(response) {
    return { type: webinarConstants.DELETE_INVITE_SUCCESS, response };
  }
  function failure(error) {
    return { type: webinarConstants.DELETE_INVITE_FAILURE, error };
  }
}

function updateWebinar(fields) {
  return (dispatch) => {
    dispatch(request({ fields }));

    webinarService.update(fields).then(
      (resp) => {
        console.log(resp);
        dispatch(success(resp));
        dispatch(alertActions.success("Successfully Updated Webinar!"));
        //window.location.href = "/webinars/"+resp
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error.toString()));
      }
    );
  };

  function request(resp) {
    return { type: webinarConstants.UPDATE_REQUEST, fields };
  }
  function success(resp) {
    return { type: webinarConstants.UPDATE_SUCCESS, resp };
  }
  function failure(error) {
    return { type: webinarConstants.UPDATE_FAILURE, error };
  }
}

function reInviteUser(meetingId, value) {
  console.log(meetingId, "////////////");
  console.log(value, "////////////");

  return (dispatch) => {
    dispatch(start("Request"));

    webinarService.reInviteUser(meetingId, value).then(
      (resp) => {
        dispatch(success(resp));
        dispatch(alertActions.success("Reinvite Successfully !"));
        //window.location.href = "/webinars/"+resp
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error.toString()));
      }
    );
  };

  function start(start) {
    return { type: webinarConstants.RE_INVITE_START };
  }
  function success(resp) {
    return { type: webinarConstants.RE_INVITE_SUCCESS, resp };
  }
  function failure(error) {
    return { type: webinarConstants.RE_INVITE_FAILURE, error };
  }
}
