import React, {Component} from 'react';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Spinner, TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap';
import { webinarRoomActions } from '../../../../Redux/_actions';
import LoadingSpinner from '../../../../components/UI/Spinner/Spinner';
import {tsToTime} from '../../../../helpers/helpers';
import {SSE} from '../../../../helpers/sse'
import userconfigs from '../../../../helpers/closures'
import {getServerURL} from '../../../../helpers/helpers';
const currentURL = getServerURL();

let FAQInterval = null;
let isFAQRuning = false;
let source = null;

class Questions extends Component {

  constructor(props){
    super(props);
    this.state = {
      activeTab : this.props.activeTab,
      webinarInfo:this.props.webinarInfo,
      webinarID : this.props.webinarID,
      token : this.props.token,
      showLoader : true,
      questionValue : "",
      answerValue : "",
      isRuning : false,
      errorMessage : false,
      answerToggle : "",
      likeLoadingVal : "",
      activeQuestionTab : "1",
      faqAttendeeList : [],
      faqModeratorList : []
    }
  }

  componentDidMount=()=>{
    if(this.props.activeTab === '0'){
      let self = this;
      let payload = [];
      //this.executeSSEProcess(this.props.webinarID,this.props.token)
      self.setState({'faqModeratorList' : [],'showLoader' : true});
      let url = `${currentURL.apiURL}webinar/listModeratorQASSE`;
      let requestJson = { "wid" : this.props.webinarID };
      
      source = new SSE(url, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
          },
        payload: JSON.stringify(requestJson),
        method: 'POST'
      });
      source.addEventListener('message', function(e) {

        self.setState({'showLoader' : false});
        payload = JSON.parse(e.data);
        if(payload.length > 0){
          // let faqModeratorListOld = self.state.faqModeratorList;
          // let updateFaqModeratorList = [...faqModeratorListOld, ...payload];
          self.setState({'faqModeratorList' : payload});
        }
      });
      source.stream();
    }
  }

  componentDidUpdate(prevProps, prevState){

      const {webinarID, webinarInfo, token, activeTab} = this.props;

      const user_type = webinarInfo.user_type || "attendee";

      let pendingList = false; 
      if(prevProps.activeTab !== this.props.activeTab || prevState.activeQuestionTab !== this.state.activeQuestionTab){

        let self = this;
        let payload = [];

        if(prevState.activeQuestionTab !== this.state.activeQuestionTab){
            isFAQRuning = false;
            clearInterval(FAQInterval)
        }

        if(this.props.activeTab==="4" && user_type==="moderator" && this.state.activeQuestionTab==="1"){

          if(isFAQRuning === false){
            isFAQRuning = true;


            // this.props.moderatorFAQListFun(webinarID, pendingList);
            // FAQInterval = setInterval(()=>{
            //     this.props.moderatorFAQListFun(webinarID, pendingList);
            // }, 5000) 
            if(source!==null){
              source.close();
            }
            self.setState({'faqModeratorList' : [],'showLoader' : true});
            let url = `${currentURL.apiURL}webinar/listModeratorQASSE`;
            let requestJson = { "wid" : this.props.webinarID };
            if(pendingList){
              requestJson = { "wid" : this.props.webinarID, "qType" : 0 };
            }
            source = new SSE(url, {
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': userconfigs.getItem('jwt'),
                },
              payload: JSON.stringify(requestJson),
              method: 'POST'
            });
            source.addEventListener('message', function(e) {
              self.setState({'showLoader' : false});
              payload = JSON.parse(e.data);
              if(payload.length > 0){
                // let faqModeratorListOld = self.state.faqModeratorList;
                // let updateFaqModeratorList = [...faqModeratorListOld, ...payload];
                self.setState({'faqModeratorList' : payload});
              }
            });
            source.stream();

          }

        }else if(this.props.activeTab==="4" && user_type==="moderator" && this.state.activeQuestionTab==="2"){
          pendingList = true;
          if(isFAQRuning === false){
            isFAQRuning = true;
            // this.props.moderatorFAQListFun(webinarID, pendingList);
            // FAQInterval = setInterval(()=>{
            //     this.props.moderatorFAQListFun(webinarID, pendingList);
            // }, 5000) 

            if(source!==null){
              source.close();
            }
            self.setState({'faqModeratorList' : [],'showLoader' : true});
            let url = `${currentURL.apiURL}webinar/listModeratorQASSE`;
            let requestJson = { "wid" : this.props.webinarID };
            if(pendingList){
              requestJson = { "wid" : this.props.webinarID, "qType" : 0 };
            }
            source = new SSE(url, {
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': userconfigs.getItem('jwt'),
                },
              payload: JSON.stringify(requestJson),
              method: 'POST'
            });
            source.addEventListener('message', function(e) {

              self.setState({'showLoader' : false});
              payload = JSON.parse(e.data);
              if(payload.length > 0){
                // let faqModeratorListOld = self.state.faqModeratorList;
                // let updateFaqModeratorList = [...faqModeratorListOld, ...payload];
                self.setState({'faqModeratorList' : payload});
              }
            });
            source.stream();
          }

        }else if(this.props.activeTab==="4" && user_type!=="moderator"){
          if(isFAQRuning === false){
            isFAQRuning = true;
            // this.props.attendeesFAQListFun(webinarID, token);
            // FAQInterval = setInterval(()=>{
            //     this.props.attendeesFAQListFun(webinarID, token);
            // }, 5000) 
            this.executeSSEProcess(this.props.webinarID, token);
          }
        }
        else if(this.props.activeTab==="0" && user_type==="moderator" && this.state.activeQuestionTab==="1"){
          if(source!==null){
            source.close();
          }
          self.setState({'faqModeratorList' : [],'showLoader' : true});
          let url = `${currentURL.apiURL}webinar/listModeratorQASSE`;
          let requestJson = { "wid" : this.props.webinarID };
          
          source = new SSE(url, {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': userconfigs.getItem('jwt'),
              },
            payload: JSON.stringify(requestJson),
            method: 'POST'
          });
          source.addEventListener('message', function(e) {

            self.setState({'showLoader' : false});
            payload = JSON.parse(e.data);
            if(payload.length > 0){
              // let faqModeratorListOld = self.state.faqModeratorList;
              // let updateFaqModeratorList = [...faqModeratorListOld, ...payload];
              self.setState({'faqModeratorList' : payload});
            }
          });
          source.stream();
        }
        else if(this.props.activeTab==="0" && user_type==="moderator" && this.state.activeQuestionTab==="2"){
          if(source!==null){
            source.close();
          }
          self.setState({'faqModeratorList' : [],'showLoader' : true});
          let url = `${currentURL.apiURL}webinar/listModeratorQASSE`;
          //let requestJson = { "wid" : this.props.webinarID };
          //if(pendingList){
          let requestJson = { "wid" : this.props.webinarID, "qType" : 0 };
          //}
          source = new SSE(url, {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': userconfigs.getItem('jwt'),
              },
            payload: JSON.stringify(requestJson),
            method: 'POST'
          });
          source.addEventListener('message', function(e) {

            self.setState({'showLoader' : false});
            payload = JSON.parse(e.data);
            if(payload.length > 0){
              // let faqModeratorListOld = self.state.faqModeratorList;
              // let updateFaqModeratorList = [...faqModeratorListOld, ...payload];
              self.setState({'faqModeratorList' : payload});
            }
          });
          source.stream();
        }
        else
        {

          if(source!==null){
            source.close();
          }

          isFAQRuning = false;
          clearInterval(FAQInterval)
        
        }
      }



      if(user_type==="moderator" && prevProps.webinarID !== this.props.webinarID && activeTab==="4"){
        this.props.moderatorFAQListFun(webinarID, pendingList);
      }

      if(user_type!=="moderator" && prevProps.webinarID !== this.props.webinarID && activeTab==="4"){
        this.props.attendeesFAQListFun(webinarID, token);
      }


      if(prevProps.questionSubmitStatus !== this.props.questionSubmitStatus && user_type!=="moderator"){
         this.setState({"questionValue" : ""});
         this.props.attendeesFAQListFun(webinarID, token);
      }


      if(prevProps.answerSubmitStatus !== this.props.answerSubmitStatus && user_type==="moderator"){
         this.setState({"answerValue" : ""});
         this.props.moderatorFAQListFun(webinarID, pendingList);
      }


      if(user_type!=="moderator" && prevProps.likeSubmitStatus !== this.props.likeSubmitStatus && activeTab==="4"){
        this.setState({"likeLoadingVal" : "" })
        this.props.attendeesFAQListFun(webinarID, token);
      }



      if(prevProps.webinarInfo !== this.props.webinarInfo){
        this.setState({
          webinarInfo:this.props.webinarInfo,
          webinarID:this.props.webinarID,
          token:this.props.token
        })
      }


      if( (prevProps.faqAttendeeList !== this.props.faqAttendeeList) || (prevProps.faqModeratorList !== this.props.faqModeratorList)){
        this.setState({'showLoader' : false});
      }


  }

  executeSSEProcess = (wid,token) =>{
      
      let self = this;
      let payload = [];

      self.setState({'faqAttendeeList' : [],'showLoader' : true});
      let url = `${currentURL.apiURL}webinar/listAttendeeQASSE`;
      let requestJson = { "wid" : wid, "token" : token };
      
      source = new SSE(url, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
          },
        payload: JSON.stringify(requestJson),
        method: 'POST'
      });
      source.addEventListener('message', function(e) {
        self.setState({'showLoader' : false});
        payload = JSON.parse(e.data);
        if(payload.length > 0){
          // let faqAttendeeListOld = self.state.faqAttendeeList;
          // let updateFaqAttendeeList = [...faqAttendeeListOld, ...payload];
          self.setState({'faqAttendeeList' : payload});
        }
      });
      source.stream();

  }

  componentWillUnmount = () =>{
    isFAQRuning = false;
    if(source!==null){
      source.close();
    }
  }
  changeHandler = (event) => {
    event.preventDefault();
      const filedName = event.target.name;
      let fieldValue = event.target.value || "";

      if(filedName==="question"){
          this.setState({"questionValue" : fieldValue});
      }

      if(filedName==="answer"){
          this.setState({"answerValue" : fieldValue});
      }

  }

  submitQuestion = (event) => {
    event.preventDefault();
    let webinarID = this.state.webinarID;
    let webinarInfo = this.state.webinarInfo;
    let user_type = webinarInfo.user_type || "attendee";
    let token = this.state.token;
    let questionValue = this.state.questionValue;
    questionValue = questionValue.trim();

    if(questionValue && questionValue!=="" && user_type!=="moderator"){
          let formData = {};
          formData.wid = webinarID;
          formData.question = questionValue.trim();
          formData.token = token;
          this.props.questionSubmitAttendeeFun(formData);
    }

  }


  submitAnswer = (event, qID) => {

    event.preventDefault();
    let webinarID = this.state.webinarID;
    let webinarInfo = this.state.webinarInfo;
    let user_type = webinarInfo.user_type || "attendee";

    let answerValue = this.state.answerValue;
    answerValue = answerValue.trim();

    if(answerValue && answerValue!=="" && user_type==="moderator"){
          let formData = {};
          formData.wid = webinarID;
          formData.qid = qID;
          formData.answer = answerValue.trim();
          this.props.answerSubmitAttendeeFun(formData);
    }else{
      let errorMessage = "Answer is required field!"
      if(user_type!=="moderator"){
        errorMessage = "You have not permission!"
      }
      this.setState({errorMessage : errorMessage})
    }


  }


  toggleShow = (qID) => {

    this.setState({"answerToggle" : qID})
  }

  toggleHide = () => {
    this.setState({"answerToggle" : "", "answerValue" : ""})
  }

  likeFaq = (qID) => {
    let webinarID = this.state.webinarID;
    let token = this.state.token;

    let formData ={}
    formData.wid = webinarID;
    formData.token = token;
    formData.qid = qID;
    this.setState({"likeLoadingVal" : qID })
    this.props.likeFAQSubmitFun(formData);
  }


  starFaq = (qID) => {
    let webinarID = this.state.webinarID;
    let token = this.state.token;

    let formData ={}
    formData.wid = webinarID;
    formData.token = token;
    formData.qid = qID;

    this.props.starFAQSubmitFun(formData);
  }

  toggleTabs = (tabID) => {
    if (this.state.activeQuestionTab !== tabID) {
      this.setState({"activeQuestionTab": tabID})
    }
  }

  render(){

    const {webinarInfo, showLoader, questionValue, faqModeratorList, faqAttendeeList, errorMessage, token, answerToggle, likeLoadingVal, activeQuestionTab} = this.state;
    const { questionSubmitLoading, answerSubmitLoading, likeSubmitLoading} = this.props;

    const userType = webinarInfo.user_type || "attendee";
    const isUserActive = webinarInfo.allowed || 0;


    let faqList = [];

    if(userType==="moderator" && faqModeratorList.length > 0){
      faqList =  faqModeratorList;
    }else if(userType!=="moderator" && faqAttendeeList.length > 0){
      faqList = faqAttendeeList;
    }


    let faqUIList = null;
    if( showLoader ){
      return(<LoadingSpinner />)
    }else
    if(faqList.length > 0){
      let counter = 0;
      faqUIList = faqList.map(faqInfo => {
        counter++;
          let senderName = faqInfo.senderName || null;
          let senderId = faqInfo.senderId || null;
          let question = faqInfo.q || null;
          let answer = faqInfo.a || null;
          let created = faqInfo.created || null;
          created = created ? tsToTime(created) : null ;
          let faqID = faqInfo._id || null;
          let likes = faqInfo.likes || 0;
          
          return (


                <li key={faqID}>  
                   <div className="d-block question_time">
                        <span className="d-inline-block text_d_white">Question by <font className="text-light">{senderId===token ? "You" : senderName }</font></span>
                        <span className="d-inline-block float-right f_size_12 text-secondary">{created}</span>
                   </div>
                   <div className="d-block w-100">
                     <div className="question_block">
                        <div className="d-block">Que: {question}</div>
                        {answer ? <div className="answer_text">Ans: {answer}</div> : null }

                        {!answer && userType==="moderator" && answerToggle===faqID ?
                        <form>
                        <div className="answer_text_bx">
                          <textarea className="form-control" name="answer" placeholder="Type Your Answer" onChange={(event) => this.changeHandler(event)}></textarea>
                          { errorMessage ? <span>{errorMessage}</span> : null}
                          <div className="text-right d-block mt-1">
                            <button className="btn btn-secondary btn-sm f_size_12 mt-1 mr-2" type="reset" onClick={this.toggleHide}>Cancel</button>
                           { answerSubmitLoading ?  
                            <button className="btn btn-success btn-sm f_size_12 mt-1" disabled><Spinner size="sm" color="light" />Repling</button>
                           : <button className="btn btn-success btn-sm f_size_12 mt-1" type="submit" onClick={(e) => this.submitAnswer(e, faqID)}>Reply</button> }

                          </div>
                        </div></form> : null }

                          <div className="quesion_status_block">
                            <div className="d-inline-block">
                                { userType==="moderator" || userType==="speaker" || (userType==="attendee" && isUserActive!==1) ?
                                 <><button type="button" className="btn btn-link text-primary btn-sm f_size_14 p-1" disabled>
                                  <i className="fas fa-thumbs-up"></i> <span className="badge badge-light f_weight_normal">{likes}</span>
                                </button>
                                </> :
                                likeSubmitLoading && likeLoadingVal===faqID ?
                                <button type="button" className="btn btn-link text-primary btn-sm f_size_14 p-1" disabled>
                                  <Spinner size="sm" color="light" />{likes}
                                </button>
                                : <button type="button" className="btn btn-link text-primary btn-sm f_size_14 p-1" onClick={() => this.likeFaq(faqID)}>
                                  <i className="fas fa-thumbs-up"></i> <span className="badge badge-light f_weight_normal">{likes}</span>
                                </button>
                              }
                            </div>

                            <div className="d-inline-block float-right pt-1">
                                { !answer ? (<span className="badge badge-warning f_weight_normal f_size_10"> Pending</span>) : null }

                            </div>

                             <div className="question_action_block">
                               { userType==="moderator" && !answer ?
                               <UncontrolledDropdown className="attendee_action_btn">
                                <DropdownToggle color="primary" className="btn-sm">
                                    <i className="fas fa-ellipsis-v"></i>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-right">

                                  { answer===null ?
                                  <DropdownItem onClick={() => this.toggleShow(faqID)}><i className="fas fa-reply mr-1"></i> Reply</DropdownItem> : null }
                               
                                </DropdownMenu>
                              </UncontrolledDropdown> : null }
                            </div>
                         </div>
                     </div>
                   </div>
                </li>
          )

      });

      }else{
        faqUIList = <div className="no_data_found_webinar">No question has been asked yet!</div>;
      }
      return(

                <div className="tab-pane " id="question_tb" role="tabpanel" aria-labelledby="question-tab">
                   
                  {userType==="moderator" ?
                  <div>
                  <div className="tab_section-inner">
                   <Nav tabs className="nav nav-tabs nav-fill">
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeQuestionTab === '1' })}
                          onClick={() => { this.toggleTabs('1'); }}>   All 
                      </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeQuestionTab === '2' })}
                          onClick={() => { this.toggleTabs('2'); }} >  
                          Pending</NavLink>
                      </NavItem>
                    </Nav>
                  </div>

                  <div className="question_list">
                      <TabContent activeTab={activeQuestionTab}>
                        <TabPane tabId="1">
                          <ul className="scrollbar">
                            {faqUIList}
                          </ul>
                        </TabPane>
                        <TabPane tabId="2">
                          <ul className="scrollbar">
                            {faqUIList}
                          </ul>
                        </TabPane>
                     </TabContent>
                  </div>
                  </div>
                  
                  : <div className="question_list">
                        <ul className="scrollbar">
                            {faqUIList}
                        </ul>

                        {userType==="attendee"?

                          <div className="chat_type_area">
                          <div className="chat_type_bx">
                           { isUserActive ===1 ?
                            <form>
                              <input type="text" name="question" className="type_input" placeholder="Type a message..." value={questionValue} onChange={(event) => this.changeHandler(event)}/>
                              { questionValue ?
                                <button className="btn btn-link text-warning" type="submit" onClick={this.submitQuestion.bind(this)}><i className="far fa-paper-plane"></i></button> :
                                questionSubmitLoading ? 
                                <button className="btn btn-link text-warning" disabled><Spinner size="sm" color="light" /></button> :
                                <button className="btn btn-link text-warning" disabled><i className="far fa-paper-plane"></i></button>
                               }
                            </form> : <div className="block_message">You are block by moderator</div> }

                          </div>
                        </div> : null }
                    </div> }
                   
               </div>
      )

    

  }

};


const stateToProps = (state) => {
  return {
    questionSubmitLoading : state.webinarRoom.questionSubmitLoading,
    questionSubmitStatus : state.webinarRoom.questionSubmitStatus,
    questionSubmitError : state.webinarRoom.questionSubmitError,
    answerSubmitLoading : state.webinarRoom.answerSubmitLoading,
    answerSubmitStatus : state.webinarRoom.answerSubmitStatus,
    answerSubmitError : state.webinarRoom.answerSubmitError,
    faqModeratorLoading : state.webinarRoom.faqModeratorLoading,
    faqModeratorList : state.webinarRoom.faqModeratorList,
    faqModeratorError : state.webinarRoom.faqModeratorError,
    faqAttendeeLoading : state.webinarRoom.faqAttendeeLoading,
    faqAttendeeList : state.webinarRoom.faqAttendeeList,
    faqAttendeeError : state.webinarRoom.faqAttendeeError,
    likeSubmitLoading : state.webinarRoom.likeSubmitLoading,
    likeSubmitStatus : state.webinarRoom.likeSubmitStatus,
    likeSubmitError : state.webinarRoom.likeSubmitError
  }
}

const actionCreator = {
  questionSubmitAttendeeFun : webinarRoomActions.questionSubmitAttendee,
  answerSubmitAttendeeFun : webinarRoomActions.answerSubmitAttendee,
  moderatorFAQListFun : webinarRoomActions.moderatorFAQList,
  attendeesFAQListFun : webinarRoomActions.attendeesFAQList,
  likeFAQSubmitFun : webinarRoomActions.likeFAQSubmit,
  starFAQSubmitFun : webinarRoomActions.starFAQSubmit
}

export default withRouter(connect(stateToProps, actionCreator)(Questions));