import { alertActions } from './';
import { departmentService } from './../_services';
import { departmentConstants } from './../_constants';


export const departmentActions = {
    listDepartments,
    addDepartment,
    getCompanyUsers,
    getDepartmentByid,
    editDepartment

};

function listDepartments() {
    return dispatch => {
        dispatch(request({}));

        departmentService.listDepartments()
            .then(
                resp => {
                    dispatch(success(resp))
                    //console.log("actions",resp)
                },
                error => dispatch(alertActions.error(error.toString()))
            );
    };

    function request(resp) { return { type: departmentConstants.DEPARTMENT_GETALL_REQUEST } }
    function success(resp) { return { type: departmentConstants.DEPARTMENT_GETALL_SUCCESS, resp } }
}

function getCompanyUsers() {
    return dispatch => {
        dispatch(request({}));

        departmentService.getCompanyUsers()
            .then(
                resp => {
                    dispatch(success(resp))
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(resp) { return { type: departmentConstants.GET_COMPANY_USER_REQUEST } }
    function success(resp) { return { type: departmentConstants.GET_COMPANY_USER_SUCCESS, resp } }
    function failure(error) { return { type: departmentConstants.GET_COMPANY_USER_FAILURE, error } }
}


function getDepartmentByid(id) {
    return dispatch => {
        dispatch(request({}));

        departmentService.getDepartmentByid(id)
            .then(
                resp => {
                    dispatch(success(resp))
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(resp) { return { type: departmentConstants.DEPARTMENT_BYID_REQUEST } }
    function success(resp) { return { type: departmentConstants.DEPARTMENT_BYID_SUCCESS, resp } }
    function failure(error) { return { type: departmentConstants.DEPARTMENT_BYID_FAILURE, error } }
}

function addDepartment(deptData) {
    return dispatch => {
        dispatch(request({}));

        departmentService.addDepartment(deptData)
            .then(
                resp => {
                    dispatch(alertActions.success(resp.toString()));
                    dispatch(success(resp))
                },
                error => dispatch(alertActions.error(error.toString()))
            );
    };

    function request(resp) { return { type: departmentConstants.DEPARTMENT_ADD_REQUEST } }
    // function success(resp) { return { type: departmentConstants.DEPARTMENT_ADD_FAILURE } }
    function success(resp) { return { type: departmentConstants.DEPARTMENT_ADD_SUCCESS, resp } }
}

function editDepartment(deptData, id) {
    return dispatch => {
        dispatch(request({}));

        departmentService.editDepartment(deptData, id)
            .then(
                resp => {
                    dispatch(alertActions.success(resp.toString()));
                    dispatch(success(resp))
                },
                error => dispatch(alertActions.error(error.toString()))
            );
    };

    function request(resp) { return { type: departmentConstants.DEPARTMENT_EDIT_REQUEST } }
    // function success(resp) { return { type: departmentConstants.DEPARTMENT_EDIT_FAILURE } }
    function success(resp) { return { type: departmentConstants.DEPARTMENT_EDIT_SUCCESS, resp } }
}