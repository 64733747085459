import * as Divya from './divya';
import * as Sarv from './sarv';

import { organization } from '../config/config';

// Name of variable must be Same all <subFile>.js

const returnSection = () => {
  switch (organization) {
    case "divya":
      return Divya;
    default:
      return Sarv;
  }
}

export default returnSection();
