import React, {Component} from 'react';
import { Link, withRouter } from 'react-router-dom';
import "./WebinarRoom.css";
import LeftPanel from './components/LeftPanel';
import RightPanel from './components/RightPanel';
import queryString from 'query-string'
import { Modal, ModalHeader, ModalBody, Spinner } from 'reactstrap';
import { connect } from 'react-redux';
import { webinarRoomActions, alertActions, webinarActions, conferenceActions } from '../../Redux/_actions';
import LoadSpinner from '../../components/UI/Spinner/Spinner';
import userconfigs from '../../helpers/closures';
import {capitalize,getServerURL} from '../../helpers/helpers';
import decode from 'jwt-decode';
import Avatar from 'react-avatar';
import {SSE} from '../../helpers/sse';
import ModeratorRoom from './components/ModeratorRoom';
const currentURL = getServerURL();

let wdInterval;
let source = null;
class Room extends Component {

	constructor(props){
		super(props);
		const queryData = queryString.parse(this.props.location.search);
		this.state = {
			webinarID : queryData.wid || null,
			token : queryData.token || null,
			loggedIn:false,
			webinarInfo:{},
			showLoader:true,
			stopInterval:false,
			joinPopUp : false,
			joinContinueURL : null
		}
	}

	componentDidMount = () => {
		let userInfo = decode(userconfigs.getItem("jwt"));
		const {webinarID, token} = this.state;
		const speakerJoinKey = userconfigs.getItem('speaker');
		if(userInfo.loginStatus !== 0){
	      this.setState({
	        loggedIn: true
	      })
	    }
	   	
	   	if(webinarID && token){
	   		// this.props.webinarRoomDetailFun(webinarID, token)
	   		// wdInterval = setInterval(()=>{
	   		// 	this.props.webinarRoomDetailFun(webinarID, token) 
	   		// }, 3000);


	   		let self = this;
		    let payload = [];
		    let url = `${currentURL.apiURL}webinar/webinarRoomDetailSSE`;
		    let requestJson = { "wid" : webinarID, "token":token };

		    source = new SSE(url, {
		        headers: {
		          'Content-Type': 'application/json',
		          'Authorization': userconfigs.getItem('jwt'),
		        },
		      payload: JSON.stringify(requestJson),
		      method: 'POST'
		    });

		    source.addEventListener('message', function(e) {

		      self.setState({'showLoader' : false});

		      payload = JSON.parse(e.data);
		      //console.log("pppppppppp",payload)
		      if(Object.values(payload).length > 0){
		      	self.updateComponents(webinarID,token,payload)
		        // let speakerListOldArr = self.state.speakerListArr;
		        // let updateSpeakerListObj = [...speakerListOldArr, ...payload];
		        // // var objDiv = document.getElementById("speakerList");
		        // // objDiv.scrollTop = objDiv.scrollHeight;
		        // self.setState({'speakerListArr' : updateSpeakerListObj});
		        
		      }
		    });
		    source.stream();

		}
		else{
			this.props.history.push({
			  	pathname: '/webinar_info',
			  	state: { 
			  		wid: webinarID, 
			  		//wname: this.props.detailInfo.meetingName,
			  		heading: 'You are not a valid user.',
			  		msg: 'Please join the webinar first then try to enter in webinar room.' 
			  	}
			})
		}	
	}

	updateComponents = (webinarID,token,detailInfo) => {
		const speakerJoinKey = userconfigs.getItem('speaker');
		let utype = '';
		//console.log('ddddddddd',detailInfo)
		//if(prevProps.detailInfo !== this.props.detailInfo){
			this.setState({
				webinarInfo:detailInfo,
				showLoader:false
			})
			if(detailInfo.isMeetingEnded === 1){
				this.props.history.push({
				  	pathname: '/webinar_info',
				  	state: { 
				  		wid: webinarID, 
				  		wname: detailInfo.meetingName,
				  		heading: 'Webinar has already been ended.',
				  		msg: 'The webinar you are trying to join has already ended, Please check your webinar link.' 
				  	}
				})
			}

			if(detailInfo.allowed === -1){
				this.props.history.push({
				  	pathname: '/webinar_info',
				  	state: { 
				  		wid: webinarID, 
				  		wname: detailInfo.meetingName,
				  		heading: 'You have been removed.',
				  		msg: 'You have been removed by the moderator, please contact to moderator for any query.' 
				  	}
				})	
			}


			if(detailInfo.user_type==="attendee" && detailInfo.allowed===2){
		        let userType = detailInfo.user_type || "attendee";
		        let name = detailInfo.name || "User";

		        this.props.joinWebinarFun(webinarID, name, userType, token);
		        //this.setState({joinPopUp: true});
		    }

		    utype = detailInfo.user_type;


		    if(detailInfo.user_type==="speaker" && detailInfo.isMeetRunning===true && speakerJoinKey!==webinarID){

		    	userconfigs.setItem('speaker', webinarID);
		        //this.setState({joinPopUp: true});
		        this.joinMeetingAction();

		    }

			// if(typeof this.props.detailError !== "object"){
			// 	clearInterval(wdInterval)
			// }
		//}
	}

	componentWillUnmount = () =>{
		if(source!==null){
	        source.close();
	      }
	}

	componentDidUpdate(prevProps,prevState){

		const {webinarID, token} = this.state;
		if(this.state.webinarInfo.user_type === 'attendee' && prevProps.joiningWebinarStatus !== this.props.joiningWebinarStatus && this.props.joiningWebinarURL !== null){
			this.setState({joinPopUp: true, joinContinueURL : this.props.joiningWebinarURL});
			this.props.changeAllowBitAttendeeFun(webinarID, token)
		}

		if(this.state.webinarInfo.user_type === 'speaker' && prevProps.joiningWebinarStatus !== this.props.joiningWebinarStatus && this.props.joiningWebinarURL !== null ){
			this.setState({joinPopUp: false, joinContinueURL : this.props.joiningWebinarURL});
		}

		if(this.state.webinarInfo.user_type === 'moderator' && prevProps.meetingRedirect !== this.props.meetingRedirect && this.props.meetingRedirect !== null ){
			this.setState({joinPopUp: true, joinContinueURL : this.props.meetingRedirect});
		}

	}


	startMeetingFun = () => {
		let webinarID = this.state.webinarID;
		this.setState({startMeetingLoading:true});
		this.props.startMeetingFun(webinarID, "webinar");
	}

	joinMeetingAction = () => {

		let webinarID = this.state.webinarID;
		let token = this.state.token;
		//let webinarInfo = this.props.detailInfo;
		let webinarInfo = this.state.webinarInfo;
		let userType = webinarInfo.user_type || "attendee";
		let name = webinarInfo.name || "User";

		this.props.joinWebinarFun(webinarID, name, userType, token);
		
	}

	closeModal() {
      this.setState({
        'joinPopUp' : false
      });
    }


	render(){

		const {webinarID, token, webinarInfo, showLoader, joinContinueURL} = this.state;
		const { detailLoading, detailError, startMeetingLoading, joiningWebinarLoading } = this.props;
		let detailInfo = webinarInfo;
		let userType = webinarInfo.user_type || "attendee";
		//console.log('usertypeeeeeeeee',userType)
		let userName = webinarInfo.name || "User";
		let isMeetRunning = webinarInfo.isMeetRunning || false;
		//console.log("wwwwwwwwwwwW",webinarInfo)
		return(
			(detailLoading && showLoader) ? 
				<div className="container-fluid s_wave_content_part webinar_bg pl-0 pr-0">
					<span className="text-center"><LoadSpinner /></span>
				</div>
			:
				(typeof detailError !== "object") ?
					<div className="container-fluid s_wave_content_part webinar_bg pl-0 pr-0">
					    <h3>{detailError.toString()}</h3> 
					</div>
				:
					<div>
						<div className="srv_wave_db_hdr webinar_header">

							<Link to="/"><div className="s_wave_logo "></div></Link>
							<div className="webinar_title d_none_mob_view">{webinarInfo.meetingName}</div>
							<div className="d-inline-block f_size_14 text_d_white align-top mt-2 ml-4 d_none_mob_view">

								<span className="ml-2"> <i className="fas fa-users mr-1"></i>  {webinarInfo.attendeeCount !== undefined ? webinarInfo.attendeeCount : <Spinner size="sm" />} </span>
			                   	<span className="ml-4"> <i className="fas fa-chalkboard-teacher mr-1"></i> {webinarInfo.speakerCount !== undefined ? webinarInfo.speakerCount : <Spinner size="sm" />}</span>
							</div>
 							 
 							 
							<div className="d-inline-block float-right ml-3 ">
								<div className="webinar_user_detail_p mt-2"><Avatar color={Avatar.getRandomColor('sitebase', ['tomato', 'mediumseagreen', '#ce165e'])} name={userName} value="100%" size="25" round="50px" textSizeRatio={2.5}  /><span className="username_ellipsis"> {userName ? capitalize(userName) : null }</span> <font className="webinar_user_type">({userType ? capitalize(userType) : null })</font></div>
							</div>

							<div className="d-inline-block ml-5 align-top float-right mt-1 d_none_mob_view">
 							 	{userType==="moderator" && isMeetRunning===false  ?
									startMeetingLoading ? <button className="btn btn-primary f_size_12 btn-sm"><Spinner size="sm" /> Starting Webinar</button> :
									<button className="btn btn-primary btn-sm f_size_12" onClick={()=>{this.startMeetingFun()}}>Start Webinar</button> :
									(userType==="speaker" && isMeetRunning===true) || userType==="moderator" ? 
									joiningWebinarLoading ? <button className="btn btn-primary btn-sm f_size_12"><Spinner size="sm" /> Joining Webinar</button> :
									<button className="btn btn-primary btn-sm f_size_12"  onClick={this.joinMeetingAction}>Join Webinar</button> : null
								}
 							</div>

						</div>
						<div className="container-fluid s_wave_content_part webinar_bg webinar_stream pl-0 pr-0">
						    {(typeof webinarInfo.user_type !== undefined && webinarInfo.user_type === 'moderator') ?
								<div>
									<div className="webinar_Container mob-d-none">
										<ModeratorRoom webinarID={webinarID} token={token} webinarInfo={webinarInfo} />
									</div>

									<div className="webinar_right_part web-d-none">
								   		
								   		<RightPanel webinarID={webinarID} token={token} webinarInfo={webinarInfo} />
								   
								   </div>

								</div>
						    :
							    <div>
							    	<div className="webinar_stream_sec pl-0 pr-0"> 

								      	<LeftPanel webinarID={webinarID} token={token} webinarInfo={webinarInfo} />

								    </div>

								  
							    </div>
							}
						     
						</div>


						<Modal isOpen={this.state.joinPopUp} className="share_file_model join_webinar_modal">
			              <ModalHeader className="model-title-bold" >
			                
			              </ModalHeader>
			                  <ModalBody>
							  	<div className="join_modal-title"><span className="icon"><i className="fas fa-chalkboard-teacher"></i></span><br/> Join Webinar</div>
			                      <div className="file_sharng_sct text-center pl-2 pr-2">
			                       <p className="text-center text_milky f_weight_100 f_size_16 modal-text">
			                       	{detailInfo.user_type==="attendee" ? "Moderator wants to join you in conference. Please click on join button to join the webinar, and after clicking on it you'll be redirect to the meeting room in new tab" : "Please click on join button to join the webinar, and after clicking on it you'll be redirect to the meeting room in new tab"}
			                      </p>


			                       <div className="shrng_568 text-center">
			 							 	{joinContinueURL ? 
			 							 		userType==="moderator" || detailInfo.user_type==="attendee" ?
												<a href={joinContinueURL} target="_blank" className="btn btn-pink btn-sm f_size_16" onClick={this.closeModal.bind(this)}><i className="far fa-share-square"></i> Continue Webinar</a> :
												<a href={joinContinueURL} className="btn btn-pink btn-sm f_size_16" onClick={this.closeModal.bind(this)}>Continue Webinar</a>  : 

			 							 		userType==="moderator" && isMeetRunning===false  ?
												startMeetingLoading ? <button className="btn btn-pink f_size_16 btn-sm"><Spinner size="sm" /> Starting Webinar</button> :
												<button className="btn btn-pink btn-sm f_size_16" onClick={this.startMeetingFun}>Start Webinar</button> :
												(userType==="speaker" && isMeetRunning===true) || (detailInfo.user_type==="attendee" && detailInfo.allowed===2) || userType==="moderator" ? 
												joiningWebinarLoading ? <button className="btn btn-pink btn-sm f_size_16"><Spinner size="sm" /> Joining Webinar</button> :
												<button className="btn btn-pink btn-sm f_size_16"  onClick={this.joinMeetingAction}>Join Webinar</button> : null
											}
			                           
			                      </div>
			                   </div>
			                    </ModalBody>
			            </Modal>

					</div>

			
		)

	}

};


function mapState(state) {
  const {detailLoading,detailInfo,detailError} = state.webinarRoom
  return {
    detailLoading,
    detailInfo,
    detailError,
    updateJoinBitState : state.webinarRoom.updateJoinBitState,
    updateJoinBitLoading : state.webinarRoom.updateJoinBitLoading,
    joiningWebinarLoading : state.webinar.joiningWebinarLoading,
    joiningWebinarStatus : state.webinar.joiningWebinarStatus,
    joiningWebinarURL : state.webinar.joiningWebinarURL,
   	startMeetingLoading : state.conference.startMeetingLoading,
   	meetingRedirect : state.conference.meetingRedirect
  };
}
const actionCreators = {
  webinarRoomDetailFun: webinarRoomActions.webinarRoomDetail,
  alertError:alertActions.error,
  changeAllowBitAttendeeFun : webinarRoomActions.changeAllowBitAttendee,
  joinWebinarFun : webinarActions.joinWebinar,
  startMeetingFun : conferenceActions.startMeeting
};

export default withRouter(connect(mapState, actionCreators)(Room));