import React, { Component } from 'react';
import TalkContainer from './TalkContainer/TalkContainer';
import './style.scss';
import { JoinUrlContext } from './../context/JoinUrlContext';
class Container extends Component {
    constructor(props){
        super(props);        
        this.state  = {
           conf_join_url: this.props.location.state !== undefined ? this.props.location.state.conf_join_url : ''
        }
    }
    render() {
        return (
            <div className="eofficeContainer">
            <TalkContainer join_url = {this.state.conf_join_url}></TalkContainer>
            </div>
        )
    }
}

export default Container;
