import { userConstants } from '../_constants';
import { userSignUpService } from '../_services';
import { alertActions } from './';
import userconfigs from '../../helpers/closures';

export const userSignUpActions = {
    signup,
    verifyAccount
};

function signup(postData) {
    return dispatch => {
        dispatch(request({ postData }));

        userSignUpService.signup(postData)
            .then(
                resp => {
                    if (typeof resp === "object" && resp.token && postData.eStatus) {
                        userconfigs.setItem('jwt', resp.token);
                        userconfigs.setItem('auth', resp.auth);
                        localStorage.setItem('loggedInStatus', 1);
                        userconfigs.setItem('account', true);
                        return window.location.href = '/mobile_verification'
                    } else {
                        dispatch(alertActions.success(resp.toString()));
                        dispatch(success(resp));
                        window.location.href = '/login'
                    }

                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(postData) { return { type: userConstants.SIGNUP_REQUEST, postData } }
    function success(resp) { return { type: userConstants.SIGNUP_SUCCESS, resp } }
    function failure(error) { return { type: userConstants.SIGNUP_FAILURE, error } }
}

function verifyAccount(token) {
    return dispatch => {
        dispatch(request({ token }));

        userSignUpService.verifyAccount(token)
            .then(
                resp => {
                    dispatch(success(resp));
                    dispatch(alertActions.success(resp.toString()));

                },
                error => {
                    dispatch(failure(error.toString()));
                    //dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(token) { return { type: userConstants.VALIDATE_SIGNUP_REQUEST, token } }
    function success(resp) { return { type: userConstants.VALIDATE_SIGNUP_SUCCESS, resp } }
    function failure(error) { return { type: userConstants.VALIDATE_SIGNUP_FAILURE, error } }
}