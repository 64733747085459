import React from 'react'
import { Formik } from 'formik'
import { Form, Button, Row, Col } from 'react-bootstrap'
// import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import { FaArrowLeft } from 'react-icons/fa';
import Dropzone from "react-dropzone";
// import { ExportToCsv } from "export-to-csv";
import exportFromJSON from 'export-from-json';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'is-empty';
import * as Yup from 'yup';

import style from './styles.module.scss'
import { alertActions } from '../../../Redux/_actions'
import { csvToJSON } from '../../../Common/constant';


const email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const phone = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im

const AdvanceOption = [
  {
    id: '1',
    label: 'Waiting Room',
    variable: 'waitingRoom',
  },
  {
    id: '2',
    label: 'Camera Access Host Only',
    variable: 'hostCameraOnly',
  },
  {
    id: '3',
    label: 'Echo Test Audio',
    variable: 'echoTest',
  },
  {
    id: '5',
    label: 'Join Without Host',
    variable: 'joinWithoutHost',
  },
  {
    id: '6',
    label: 'Auto Record',
    variable: 'autoRecord',
  },
  {
    id: '4',
    label: 'Allow Start & Stop Recording',
    variable: 'stopAndStartRec',
  },
  // { <--- it Option hide on old version as well 
  //   id: '6',
  //   label: 'Only Registered',
  //   variable: 'onlyRegistered',
  // },
  // {
  //   id: '7',
  //   label: 'Auto Join Video',
  //   variable: 'autoJoinVideo',
  // },
  // {
  //   id: '8',
  //   label: 'Hide Presentation/Whiteboard',
  //   variable: 'hidePresentation',
  // },
]

const validation = Yup.object().shape({
  // contact: Yup.array().of(
  //   Yup.string().test('check', "One of Contact is not Right!", (value) => email.test(value) || phone.test(value))
  // ),
  participant: Yup.string().matches(/^\d{10}w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/ , "Please enter valid phone number and email!!"),
})


const downloadSimple = async () => {

  const file = [{
    contact: '+91-97XXXXXXXX'
  }, {
    contact: 'example@xxxxxxxx.com'
  }];

  // const options = {
  //   fieldSeparator: ",",
  //   quoteStrings: '"',
  //   decimalSeparator: ".",
  //   showLabels: true,
  //   showTitle: false,
  //   useTextFile: false,
  //   useBom: true,
  //   useKeysAsHeaders: true,
  //   filename: 'sample content'
  //   // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
  // };

  let fileName = "generated";
  const exportType = 'csv';
  await exportFromJSON({ file, fileName, exportType });
}



const StepThree = (props) => {
  const { setStepBack, setFormData, formData } = props
  const { contactsInfo } = useSelector(state => state.authentication)
  const { loading } = useSelector(state => state.schedule)

  const dispatch = useDispatch();

  const initialValues = {
    invite: formData?.invite || 'manually',
    contact: formData?.contact || [],
    fileName: formData?.fileName || '',
    waitingRoom: formData?.waitingRoom || false,
    hostCameraOnly: formData?.hostCameraOnly || false,
    echoTest: formData?.echoTest || false,
    joinWithoutHost: formData?.joinWithoutHost || false,
    autoRecord: formData?.autoRecord || false,
    stopAndStartRec: formData?.stopAndStartRec || true,
    // onlyRegistered: false,
    // autoJoinVideo : false,
    // hidePresentation: false
  }

  const filterContact = (key) => {
    const arr = contactsInfo;
    if (key !== '') {
      return arr.filter(t => t.contact.includes(key))
    }
    return arr;
  }

  const getUploadFile = (acceptedFiles, rejectedFiles, setFieldValue) => {
    if (!isEmpty(rejectedFiles)) {
      dispatch(alertActions.error(rejectedFiles[0].errors[0].message))
    }
    else {
      const reader = new FileReader();
      reader.readAsText(acceptedFiles[0]);
      reader.onload = () => {
        const text = reader.result;
        const csvToJson = csvToJSON(text);
        const result = []
        csvToJson.map((data) => {
          if (!isEmpty(data.contact)) {
            result.push(data.contact)
          }
        })
        if (isEmpty(result)) {
          dispatch(alertActions.error("upload file not have contact column!"))
        } else {
          setFieldValue('fileName', acceptedFiles[0].name)
          setFieldValue('contact', result)
        }
      };
    }
  }

  const setContact = (e, setFieldValue) => {
    let value = e.target.value
    let result = value.replace(/\r?\n/g, '/n');
    result = result.split('/n');
    result = result.filter(v => v != '');
    setFieldValue('contact', result)
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => setFormData(values)}
      enableReinitialize
      validationSchema={validation}
    >
      {
        ({ values, setFieldValue, errors, touched, handleSubmit, }) => (
          <>
            <h6 className='welcomeMsg'>Invite</h6>
            <Form.Group className='mb-2 w-100'>
              <Form.Check
                label={
                  <Row className='w-100 ' >
                    <Col lg={2} className='pr-0' >Add Manually</Col>
                    <Col lg={10}>
                      <textarea class="form-control" id="exampleFormControlTextarea1"
                        rows="2"
                        name="participant"
                        placeholder="Add email or mobile each in new line to invite"
                        onChange={(e) => setContact(e, setFieldValue)}
                      />
                      {/* touched.participant && */ errors.participant ? console.log("in error render") : null
                        // <div className="error text-danger f_size_13">{errors.participant}</div>
                        }
                      {/* <ReactTextareaAutocomplete
                        className={"form-control w-100 inputColorTheme welcomeMsg "}
                        name="participant" id="participant" placeholder="Add email or mobile each in new line to invite"
                        disabled={values.invite !== 'manually'}
                        onBlur={(event) => setContact(event.target.value, setFieldValue)}
                        loadingComponent={() => <span>Loading</span>}
                        minChar={0}
                        trigger={{
                          "@": {
                            dataProvider: token => {
                              return filterContact(token).map(({ contact }) => ({ contact }));
                            },
                            component: values.contact,
                            output: (item, trigger) => contactsInfo.length > 0 ? item.contact : ''
                          }
                        }}
                      /> */}
                      <div className='mt-3'>
                        <span className="text-warning d-block">Note: </span>
                        <span className="text_milky d-block f_size_13 mt-1">
                          1. Every invite detail should be in new line.
                        </span>
                        <span className="text_milky d-block f_size_13 mt-1">
                          2. Mobile number should be with country code ex. +91-97XXXXXXXX.
                        </span>
                      </div>
                    </Col>
                  </Row>
                }
                name="invite"
                type='radio'
                id="invite"
                value='manually'
                onClick={(event) => setFieldValue('invite', event.target.value)}
                className={`welcomeMsg mb-3 ${style.inviteManually}`}
                checked={values.invite === 'manually'}
              />
              
              <div className='text-right mb-1'>
                <Button className="submitBtnColor border-0" onClick={() => downloadSimple()} >Sample CSV</Button>
              </div>
              <Form.Check
                label={<div className='welcomeMsg ml2 d-flex' >Bulk
                  <button className='btnBgLess  '>
                    <Dropzone
                      accept={['.csv']}
                      disabled={values.invite !== 'fileUpload'}
                      onDrop={(acceptedFiles, rejectedFiles) =>
                        getUploadFile(acceptedFiles, rejectedFiles, setFieldValue)
                      }
                    >
                      {({ getRootProps, getInputProps, isDragActive }) => (
                        <div {...getRootProps()} className={`${style.bulkUpload} ${isEmpty(values.fileName) ? '' : style.successUpload}`}>
                          <input {...getInputProps()} />
                          <div className='mt-3 mb-3'>
                            {isEmpty(values.fileName) ? <div className='welcomeMsg'> Drag & Drop files to Upload
                              <br />
                              Yo can upload only valid csv here.
                              <br />
                              <div className='mt-2' >
                                <a className="btn btn-secondary submitBtnColorBorder">Browse CSV</a>
                              </div>
                              <br />
                            </div>
                              :
                              <div className='mt-4'>
                                {`${values.fileName} is successfully updated.`}
                              </div>
                            }
                          </div>
                          <p />
                        </div>
                      )}
                    </Dropzone>
                  </button>
                </div>
                }
                name="invite"
                type='radio'
                id="invite"
                value='fileUpload'
                checked={values.invite === 'fileUpload'}
                onClick={(event) => setFieldValue('invite', event.target.value)}
              />
            </Form.Group>

            <h6 className='welcomeMsg mt-3'>Option</h6>
            <div className='d-flex flex-wrap justify-content-center mt-3'>
              {
                AdvanceOption.map((data, index) =>
                  <Form.Group key={`advanceOption-${index}`} className='switch-check'>
                    <Form.Check
                      type="switch"
                      id={data.variable}
                      label={<div className='f_size_16 welcomeMsg pl-35' >{data.label}</div>}
                      checked={values[data.variable]}
                      onChange={() => setFieldValue(data.variable, !values[data.variable])}
                      className={data.variable == 'joinWithoutHost' && values.waitingRoom ? 'd-none' : ''}
                    />
                  </Form.Group>
                )
              }
            </div>

            <div className='bottomBorder mt-3 mb-2' />
            <div className='between'>
              <button className='btnBgLess welcomeMsg f_size_20' onClick={() => setStepBack(values)}>
                <FaArrowLeft />
              </button>
              <button onClick={() => handleSubmit()} disabled={loading} className="mr-2 border-0 pl-4 pr-4 headerBtn text-white submitBtnColor" >{loading ? 'Loading' : "Finish"}</button>
            </div>

          </>
        )
      }
    </Formik>
  )
}

export default StepThree
