import { waitingRoomConstants } from '../_constants';

const initialState = { 
  addUserloading: false , 
  listAttendeeLoading: true, 
  listAttendeeRes: [], 
  getBroadcastRes: [],
  attendeeRequestLoading:false,
  attendeeRequestRes: [],
  attendeeRequestError:false,
  attendeeJoinLoading:false,
  moderatorJoinLoading:false
};

export function waitingRoom(state = initialState, action) {
  switch (action.type) {
    case waitingRoomConstants.ADD_USER_REQUEST:
      return {
        addUserloading: true,
        addUserReq: action.resp
      };
    case waitingRoomConstants.ADD_USER_SUCCESS:
      return {
        addUserloading: false,
        addUserRes: action.resp,
        addUserError:false
      };
    case waitingRoomConstants.ADD_USER_FAILURE:
      return {
        addUserloading:false,
        addUserError:true
      };


    case waitingRoomConstants.BROADCAST_REQUEST:
      return {
        ...state,
        broadcastloading: true,
        broadcastReq: action.resp
      };
    case waitingRoomConstants.BROADCAST_SUCCESS:
      return {
        ...state,
        broadcastloading: false,
        broadcastRes: action.resp,
        broadcastError:false
      };
    case waitingRoomConstants.BROADCAST_FAILURE:
      return {
        ...state,
        broadcastloading:false,
        broadcastError:true
      };

    case waitingRoomConstants.GET_BROADCAST_REQUEST:
      return {
        ...state,
        getBroadcastloading: true,
        getBroadcastReq: action.resp
      };
    case waitingRoomConstants.GET_BROADCAST_SUCCESS:
      return {
        ...state,
        getBroadcastloading: false,
        getBroadcastRes: action.resp,
        getBroadcastError:false
      };
    case waitingRoomConstants.GET_BROADCAST_FAILURE:
      return {
        ...state,
        getBroadcastloading:false,
        getBroadcastError:true
      };

    case waitingRoomConstants.LIST_ATTENDEE_REQUEST:
      return {
        ...state,
        listAttendeeLoading: false,
        listAttendeeRes: []
      };
    case waitingRoomConstants.LIST_ATTENDEE_SUCCESS:
      return {
        ...state,
        listAttendeeLoading: false,
        listAttendeeRes: action.resp,
        listAttendeeError:false
      };
    case waitingRoomConstants.LIST_ATTENDEE_FAILURE:
      return {
        ...state,
        listAttendeeLoading:false,
        listAttendeeError:true
      };


    case waitingRoomConstants.ATTENDEE_REQUEST_REQUEST:
      return {
        ...state,
        attendeeRequestLoading: true,
        attendeeRequestRes: []
      };
    case waitingRoomConstants.ATTENDEE_REQUEST_SUCCESS:
      return {
        ...state,
        attendeeRequestLoading: false,
        attendeeRequestRes: action.resp,
        attendeeRequestError:false
      };
    case waitingRoomConstants.ATTENDEE_REQUEST_FAILURE:
      return {
        ...state,
        attendeeRequestLoading:false,
        attendeeRequestError:true
      };

    case waitingRoomConstants.ATTENDEE_JOIN_REQUEST:
      return {
        ...state,
        attendeeJoinLoading: true,
      };
    case waitingRoomConstants.ATTENDEE_JOIN_SUCCESS:
      return {
        ...state,
        attendeeJoinLoading: false,
        attendeeJoinRes: action.resp,
        attendeeJoinError:false
      };
    case waitingRoomConstants.ATTENDEE_JOIN_FAILURE:
      return {
        ...state,
        attendeeJoinLoading:false,
        attendeeJoinError:true
      };

    case waitingRoomConstants.MODERATOR_JOIN_REQUEST:
      return {
        ...state,
        moderatorJoinLoading: true,
      };
    case waitingRoomConstants.MODERATOR_JOIN_SUCCESS:
      return {
        ...state,
        moderatorJoinLoading: false,
        moderatorJoinRes: action.resp,
        moderatorJoinError:false
      };
    case waitingRoomConstants.MODERATOR_JOIN_FAILURE:
      return {
        ...state,
        moderatorJoinLoading:false,
        moderatorJoinError:true
      };  
    default:
      return state
  }
}