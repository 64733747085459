import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, Input, Form, FormGroup, Label, CustomInput, Col, Row } from 'reactstrap';
import './ModuleForm.scss';

const AddMoreFieldForm = (props) => {



  return (
    <Modal isOpen={props.modal} toggle={props.showModal} className="CustomModal">
      <ModalHeader toggle={props.showModal}>Create Custom Field</ModalHeader>
      <Form>
        <ModalBody className="CustomModalBody">

          <Row>
            <Col sm="6">
              <FormGroup className="form-col">
                <Label className="title-label">Field Name </Label>
                <Input type="text" placeholder="Enter Field Name Name" />
              </FormGroup>
            </Col>

            <Col sm="6">
              <FormGroup className="form-col">
                <Label className="title-label">Field Type</Label>
                <CustomInput type="select" name="customSelect" >
                  <option value="">Select Field Type</option>
                  <option>Text</option>
                  <option>Number</option>
                  <option>date</option>
                  <option>Textarea</option>
                  <option>dropdown</option>
                </CustomInput>
              </FormGroup>
            </Col>

            <Col sm="12">
              <Label className="title-label mb-2">DropDown Option </Label>
              <FormGroup className="form-col">
                <Input type="textarea" placeholder="Enter Field Name Name" className="form-control-textarea" />
              </FormGroup>
            </Col>
            <Col sm="12">
              <ul className="note-list">
                <li><span className="text-warning">Note:</span> Please enter list / format of field type.</li>
                <li>One value per row only alphanumeric characters and space allowed</li>
              </ul>
            </Col>

            <Col sm="12" className="text-center">
              <Button className="btn-theme-primary" onClick={props.showModal}>Create</Button>
            </Col>

          </Row>
        </ModalBody>

      </Form>
    </Modal>

  );
}

export default AddMoreFieldForm;