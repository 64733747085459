import React, { useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { AiOutlineDown } from "react-icons/ai";

import Layout from '../../layoutSection'
import { webinarActions } from '../../Redux/_actions'

const LiveStream = () => {
  const { webinarDetail } = useSelector(state => state.webinar)
  const param = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!webinarDetail || webinarDetail?.meetingID !== param.id) {
      dispatch(webinarActions.webinarDetail(param.id))
    }
  }, [dispatch, param.id, webinarDetail])

  return (
    <Layout title={webinarDetail?.name || 'Live Stream'} goBack="/webinars" list="webinar" active="Live Stream" >
      <div className="container-fluid s_wave_content_part pl-5 pr-4 liveStream">
        <h4 className="welcomeMsg">Live Stream</h4>
        <div className="welcomeMsg">Live stream your event to reach your audience on social media.</div>
        <div className="d-flex align-items-center  justify-content-center mt-5">
          <Button size="lg" > Add Stream Destination <AiOutlineDown /> </Button>
        </div>
      </div>
    </Layout>
  )
}

export default LiveStream;
