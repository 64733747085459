import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
function UserModal({ lgShow, setLgShow,closeModal, setFormData, formData, formMessages, handleSubmit, planData, webUser, isCreate = true }) {
  return (
    <Modal size="lg" show={lgShow} onHide={() => setLgShow(false)}
      aria-labelledby="example-modal-sizes-title-lg" className="addusermodel"
      contentClassName="sideBar welcomeMsg"
    >
      <Modal.Header  className="bg-transparent" >
        <Modal.Title id="example-modal-sizes-title-lg">
          {isCreate === true ? 'Add an User' : 'Update User'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {/* {isCreate === true && */}
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
              <Form.Label>Email Address</Form.Label>
              <Form.Control as="textarea" disabled={!isCreate} rows={3} className="inputBgTheme welcomeMsg" onChange={(e) => { setFormData({ ...formData, emails: e.target.value }) }} value={formData.email} placeholder="Use comma to separate multiple email address" />
            </Form.Group>
          {/* } */}

          {/* {['radio'].map((type) => (
            <div key={`inline-${type}`} className="mb-3">
              <div className="blockclass"><Form.Label className="text-muted">User Type</Form.Label></div>
              {planData && planData.map((obj, index) => (
                <Form.Check key={index} inline label={obj.plan_type} disabled={!webUser || !webUser.plan_data || webUser.plan_data.plan_type === obj.plan_type} name="group1" type={type} id={`inline-${index}-1`} onChange={(e) => { setFormData({ ...formData, package_type: obj.plan_type }) }} />
              ))}
            </div>
          ))} */}

          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="text-muted">Department</Form.Label>
            <Form.Control type="text" className="inputBgTheme welcomeMsg" placeholder="e.g. IT" onChange={(e) => { setFormData({ ...formData, department: e.target.value }) }} value={formData.department} />
          </Form.Group>
          {formMessages && formMessages.map((obj, index) => (
            <p key={index} className={obj.status === 0 ? 'response error' : 'response success'}>{obj.email} : {obj.message}</p>
          ))}
          <div className="text-right">
          <Button
                  variant="secondary"
                  className="mt-2 ml-2"
                  onClick={closeModal}
                >
                  Cancel
            </Button>
            <Button className="btn btn-pink btnadduser mt-4 mr-2 ml-2" onClick={handleSubmit}>{isCreate === true ? 'Add User' : 'Update User'}</Button>
           
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  )
}
export default UserModal;