import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Layout from '../../layoutSection';

import { conferenceActions } from '../../Redux/_actions';

import './styles.scss'

const MeetEntry = () => {
  const { meetingDetail, startMeetingLoading } = useSelector(state => state.conference)
  const paras = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    if (paras.id && (!meetingDetail || meetingDetail.meetingID !== paras.id)) {
      dispatch(conferenceActions.meetingDetail(paras.id))
    }
  }, [dispatch, meetingDetail, paras])


  return (
    <Layout title={meetingDetail?.name || 'Meeting'} active="Meeting Entry" list="meeting" goBack="/meeting_history" >
      <div className="secondSection sideBar mt-5 p-5 welcomeMsg">
        <label className="mb-0 ">Password</label>
        <div>{meetingDetail?.attendeePW}</div>

        <div className='mt-3'>
          <label className="mb-0 ">Registration form</label>
          <div className='f_size_16 text-muted' >People registering for the meeting will have to fill the below information.</div>


          <Button className='submitBtnColor border-0 mt-4 ml-4 pl-4 pr-4' > Registration</Button>

        </div>
      </div>
    </Layout>
  )
};

export default MeetEntry;
