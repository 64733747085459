import { myconfig } from '../../config/config';
import axios from 'axios';
import userconfigs from '../../helpers/closures';
import { handleJWTError, handleResponse } from './common';
import { siteTokens } from "../../config/config";
import { generateToken } from "../../helpers/helpers";
import jwtDecode from 'jwt-decode';


export const notificationService = {
    longPolling
};

function longPolling() {

    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };

    return axios.post(`${myconfig.apiURL}browser/notifications`, {}, axiosRequestOptions)
        .then(handleResponse)
        .then(resp => {
            return resp;
        })
        .catch(async (error) => {
            await hendleErrorCases(error);
            return Promise.reject(error);
        });
}

function hendleErrorCases(error) {
    return new Promise(resolve => {
        let status = error.response.status;
        let jwtTLName = siteTokens.find((item) => window.location.hostname === item.url).token || "jwt";
        let getJwt = userconfigs.getItem(jwtTLName);
        switch (status) {
            case 520:
                // User not logged In!
                if (!getJwt) return generateToken();
                let jwtData = jwtDecode(getJwt)
                if (jwtData || jwtData.exp) {
                    if (jwtData.exp * 1000 <= + new Date()) return handleJWTError();
                    break;
                }
                handleJWTError();
                break;

            case 925:
                // When concurrent login session found!
                handleJWTError();
                alert("Your account has been logged in on another device!");
                break;

            case 753:
                // In case of jwt expire!
                if (!getJwt) return generateToken();
                let jwtData1 = jwtDecode(getJwt)
                if (jwtData1 || jwtData.exp) {
                    if (jwtData1.exp * 1000 <= + new Date()) return handleJWTError();
                    break;
                }
                handleJWTError();
                break;
                
            default:
                console.log("@notification catch error", error)
                break;
        }
        resolve();
    })

}