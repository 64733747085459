import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import validator from 'validator'
import Loader from '../components/UI/Spinner/Spinner';
import decode from 'jwt-decode';
import userconfigs from '../helpers/closures';
import { userActions } from '../Redux/_actions';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';
import TimezoneSelect from 'react-timezone-select';
import './editProfile.css';
import Header from '../layoutSection/header'
import { Button } from 'react-bootstrap';
// import { Spinner } from 'reactstrap';
// import { getAllTimeZone } from '../helpers/helpers';
//import Header from '../Header/Header';

class ProfileEdit extends Component {

	constructor(props) {
		super(props);
		this.state = {
			userInfo: {},
			isSubmitted: false,
			errors: {},
			timeZoneObj: { value: "Asia/Calcutta", label: "(GMT+5:30) Chennai, Kolkata, Mumbai, New Delhi" }
		}

		this.handleChange = this.handleChange.bind(this);
		this.handleTimeZoneChange = this.handleTimeZoneChange.bind(this);
	}

	componentWillMount = () => {
		let webUser = decode(userconfigs.getItem('jwt'))
		if (webUser.loginStatus !== 1) {
			this.props.history.push('/login')
		}
	}

	componentDidMount = () => {
		this.props.userDetails()
	}

	componentDidUpdate = (prevProps, prevState) => {
		if (prevProps.profileInfo !== this.props.profileInfo) {
			this.setState({
				userInfo: this.props.profileInfo
			})
			if (this.props.profileInfo.hasOwnProperty('timezone')) {
				this.setState({
					timeZoneObj: this.props.profileInfo?.timezone ? this.props.profileInfo.timezone : this.state.timeZoneObj
				})
			}
		}
		if (prevProps.updated !== this.props.updated) {
			if (this.props.updated === true)
				this.props.history.push("/profile")
		}
	}

	handleChange(e) {
		const { name, value } = e.target;
		let fields = this.state.userInfo;

		if (name === "name") {

			var specials = /^[a-zA-Z0-9\s]*$/;
			if (specials.test(value)) {
				fields[name] = value;
				this.setState({ fields });
			}
		}
		else {
			fields[name] = value;
			this.setState({ fields });
		}
	}

	handleValidation() {
		let fields = this.state.userInfo;
		let errors = {};
		let formIsValid = true;

		//Name
		if (!fields["name"] || fields["name"].trim() === "") {
			formIsValid = false;
			errors["name"] = "Please enter your name!";
		}

		//Email
		if (!fields["email"]) {
			formIsValid = false;
			errors["email"] = "Please enter the email id!";
		}
		else if (!validator.isEmail(fields['email'])) {
			formIsValid = false;
			errors["email"] = "Email is not valid!";
		}

		this.setState({ errors: errors });
		return formIsValid;
	}

	profileSubmit(e) {
		e.preventDefault();
		this.setState({ isSubmitted: true })
		if (this.handleValidation()) {
			let userInfo = this.state.userInfo;
			let fields = {}
			fields.name = userInfo.name;
			fields.email = userInfo.email;
			fields.mobile = userInfo.mobile;
			fields.timezone = userInfo.mobile;
			fields.countryCode = userInfo.countryCode || "91";
			fields.countryId = userInfo.countryId || "in";
			fields.timezone = this.state.timeZoneObj;
			this.props.profileUpdate(fields)
		}
	}
	handleTimeZoneChange(e) {
		this.setState({ timeZoneObj: e })
	}

	render() {
		const { userInfo, errors, isSubmitted, timeZoneObj } = this.state;
		const { profileLoading, updateLoading } = this.props;

		return (
			<div>
				<Header goBack title="Update Profile" />
				<div className="container-fluid s_wave_content_part_schdule profile_page_sec pt-0 ">
					<div className="container user_profile_page">
						<div className="row mt-4">
							<div className="col-md-8">
								<div className="middle_6h_bx mt-3 sideBar borderRadius">
									{profileLoading ?
										<div className="text-center"><Loader /></div>
										:
										<div>
											<div className="row">
												<div className="col-sm-8">
													<span className="text_milky f_size_20"> <i className="fas fa-edit mr-1"></i>   Edit Profile </span>
												</div>	
												<div className="col-sm-4 text-right action_btn86">
													{/* <Link to="/profile"  className="btn btn-warning custom_btn btn-sm f_size_12"><i  className="fas fa-user-circle mr-1"></i> Profile</Link> */}
													<Button variant="outline-warning" onClick={() => this.props.history.push('/profile')}  > <i className="fas fa-user-circle mr-1"></i> Profile</Button>
												</div>
											</div>
											<hr />

											<div className="profile_udeatil">
												<form onSubmit={this.profileSubmit.bind(this)}>
													<ul>
														<li>
															<label>Name</label>
															<div className={"dtl_input45" + (isSubmitted && !userInfo.name ? ' has-error' : '')}>
																<input type="text" onChange={this.handleChange} value={userInfo.name} name="name" />
																{errors['name'] ? <span className="help-block error_msg_56">{errors['name']}</span> : ""}
															</div>
														</li>
														<li>
															<label>Email</label>
															<div className={"dtl_input45" + (isSubmitted && !userInfo.email ? ' has-error' : '')}>
																<input type="text" onChange={this.handleChange} disabled={userInfo.emailVerified} value={userInfo.email} name="email" />
																{errors['email'] ? <span className="help-block error_msg_56">{errors['email']}</span> : ""}
															</div>
														</li>


														<li className='d-flex customDatePicker'>
															<label>Mobile</label>
															<div  >
																<PhoneInput
																	country='in'
																	value={typeof userInfo.mobile !== "undefined" ? ((userInfo.countryCode || "91") + userInfo.mobile).toString() : ''}
																	inputClass="phone-input welcomeMsg inputColorTheme"
																	enableSearch={true}
																	disabled={userInfo.mobileVerified}
																	placeholder="Mobile No"
																	countryCodeEditable={false}
																	onChange={(value, country) => {
																		let fieldsObj = userInfo;
																		console.log(value)
																		fieldsObj.mobile = value.slice(country.dialCode.length);
																		fieldsObj.countryCode = country.dialCode
																		fieldsObj.countryId = country.countryCode
																		this.setState({ fieldsObj })
																	}}

																/>
															</div>
														</li>

														<li>
															<div className="timezone_input tzDropdown">
																<label className="webinar_field_title">Time Zone</label>
																<div className="dtl_input45">
																	<TimezoneSelect
																		value={timeZoneObj}
																		onChange={this.handleTimeZoneChange}
																	/>
																</div>
															</div>

														</li>

														<li className="text-center">
															<button className="btnStyles  custom_btn submitBtnColor text-white mt-3">{updateLoading ? 'Updating...' : 'Update Profile'}</button>
														</li>

													</ul>
												</form>
											</div>
										</div>
									}
								</div>
							</div>

							<div className="col-md-4 mt-3 text-center profile_page_img">
								<img src="images/image_agnt_prfl.png" />
							</div>
						</div>
					</div>
				</div>
			</div>
		);

	}
}


const mapState = (state) => {
	const { profileLoading, profileInfo, updateLoading, updated } = state.authentication;
	return {
		profileLoading,
		profileInfo,
		updateLoading,
		updated
	}
}

const actionCreators = {
	userDetails: userActions.userDetails,
	profileUpdate: userActions.profileUpdate
};


export default withRouter(connect(mapState, actionCreators)(ProfileEdit));