import React, { Component } from 'react';
import VideoConference from './components/VideoConference/VideoConference';
// import CenterBoxOne from './components/CenterBoxOne/CenterBoxOne';
// import CenterBoxTwo from './components/CenterBoxTwo/CenterBoxTwo';
import './style.scss';

 class CenterPanelManager extends Component {
    constructor(props){
        super(props)
    }
    render() {
        return (
            <div className="center-container">
            <VideoConference join_url ={this.props.join_url}/>

            <div className="row">
                {/* <div className="col-md-6"><CenterBoxOne /></div>
                <div className="col-md-6"><CenterBoxTwo /></div> */}
            </div>
            </div>
        )
    }
}

export default CenterPanelManager;
