import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { conferenceActions, alertActions } from "../Redux/_actions";
import { Spinner } from "reactstrap";
//import LoadSpinner from '../components/UI/Spinner/Spinner';
import WaitForModerator from "./components/WaitingRoom/WaitForModerator/waitForModerator";
import AttendeeWaitingRoom from "./components/WaitingRoom/AttendeeWaitingRoom/attendeeWaitingRoom";
import ModeratorWaitingRoom from "./components/WaitingRoom/ModeratorWaitingRoom/moderatorWaitingRoom";
import WaitForStartMeeting from "./components/WaitingRoom/WaitForStartMeeting/waitForStartMeeting";
import MessagesFromMeeting from "./components/WaitingRoom/MessagesFromMeeting/messagesFromMeeting";
import isEmpty from "is-empty";
import moment from "moment";

class WaitingRoom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mid: this.props.match.params.id,
      moderatorJoinedWR: undefined,
      checkModeratorCount: 21,
      moderatorToken: this.props.match.params.moderatorToken,
    };
  }

  componentDidMount = () => {
    this.props.meetingInfo(this.state.mid);

    // console.log(this.state.moderatorJoinedWR);

    // var interval = setInterval(() => {
    // 	if (this.props.conferenceBits.moderatorJoinedWR !== null && this.props.conferenceBits.moderatorJoinedWR) {
    // 		this.setState({
    // 			moderatorJoinedWR: true
    // 		})
    // 		clearInterval(interval);
    // 	}
    // 	else{
    // 		//this.props.meetingInfo(this.props.conferenceBits.meetingID)
    // 		this.setState({
    // 			checkModeratorCount: this.state.checkModeratorCount + 1
    // 		})
    // 	}
    // }, 5000);
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.conferenceBits !== this.props.conferenceBits) {
      // if(this.props.conferenceBits.isMeetingEnded === 1)
      // {
      // 	this.props.alertError("Sorry, This meeting has been ended !")
      // 	this.props.history.push("/")
      // }
      if (this.props.conferenceBits && this.props.conferenceBits.moderatorJoinedWR !== null && !this.props.conferenceBits.moderatorJoinedWR) {
        //this.props.meetingInfo(this.props.conferenceBits.meetingID)
        let mid = this.props.conferenceBits.meetingID;
        let self = this;
        setTimeout(function () {
          self.getMeetingInfo(mid);
        }, 1000);
        if (
          !this.props.conferenceBits.waitingRoom &&
          this.props.conferenceBits.hasModeratorJoined
        ) {
          this.setState({
            checkModeratorCount: this.state.checkModeratorCount - 1,
          });
        }
      }
    }
  }

  getMeetingInfo = (mid) => {
    this.props.meetingInfo(mid);
  };

  checkEndTime = (endTime) => {

    if (isEmpty(endTime)) {
      return false
    }

    const startDate = moment(endTime);
    const timeEnd = moment(new Date().getTime());
    const diff = timeEnd.diff(startDate);
    const diffDuration = moment.duration(diff);
    return parseInt(diffDuration.minutes()) <= 30;
  }

  render() {
    const { conferenceBits } = this.props;
    const { checkModeratorCount } = this.state;
    const { moderatorToken } = this.state;

    // console.log("innnn", attendeePW);
    // console.log("ccccccccc", conferenceBits);
    // console.log(this.props, ">>>>>>");

    if (
      conferenceBits !== null &&
      conferenceBits.moderatorJoinedWR !== 1 &&
      conferenceBits.isModerator !== 1 &&
      (conferenceBits.waitingRoom)
    ) {
      return (
        <WaitForModerator
          mname={
            conferenceBits !== null ? (
              conferenceBits.name
            ) : (
              <Spinner size="sm" color="light" />
            )
          }
          mid={
            conferenceBits !== null ? (
              conferenceBits.meetingID
            ) : (
              <Spinner size="sm" color="light" />
            )
          }
        />
      );
    } else if (conferenceBits !== null && !conferenceBits.isModerator && this.checkEndTime(conferenceBits.meetingEndTime) && conferenceBits.isMeetingEnded === 1) {
      return (
        <MessagesFromMeeting
          mname={
            conferenceBits !== null ? (
              conferenceBits.name
            ) : (
              <Spinner size="sm" color="light" />
            )
          }
          mid={
            conferenceBits !== null ? (
              conferenceBits.meetingID
            ) : (
              <Spinner size="sm" color="light" />
            )
          }
        />
      );
    } else if (
      conferenceBits !== null &&
      (conferenceBits.waitingRoom) &&
      (conferenceBits.moderatorJoinedWR === 1 || conferenceBits.isModerator === 1)
    ) {
      if (conferenceBits.isModerator) {
        return (
          <ModeratorWaitingRoom
            mname={
              conferenceBits !== null ? (
                conferenceBits.name
              ) : (
                <Spinner size="sm" color="light" />
              )
            }
            mid={
              conferenceBits !== null ? (
                conferenceBits.meetingID
              ) : (
                <Spinner size="sm" color="light" />
              )
            }
            meetingBits={conferenceBits !== null ? conferenceBits : {}}
          />
        );
      } else {
        return (
          <AttendeeWaitingRoom
            mname={
              conferenceBits !== null ? (
                conferenceBits.name
              ) : (
                <Spinner size="sm" color="light" />
              )
            }
            mid={
              conferenceBits !== null ? (
                conferenceBits.meetingID
              ) : (
                <Spinner size="sm" color="light" />
              )
            }
            meetingBits={conferenceBits !== null ? conferenceBits : {}}
          />
        );
      }
    } else if (conferenceBits !== null && !conferenceBits.waitingRoom) {
      // if (conferenceBits.attendeePW) {
      //   this.setState({ attendeePW: conferenceBits.attendeePW });
      // }
      return (
        <WaitForStartMeeting
          mname={
            conferenceBits !== null ? (
              conferenceBits.name
            ) : (
              <Spinner size="sm" color="light" />
            )
          }
          mid={
            conferenceBits !== null ? (
              conferenceBits.meetingID
            ) : (
              <Spinner size="sm" color="light" />
            )
          }
          isMeetRunning={conferenceBits.isMeetRunning}
          checkModeratorCount={checkModeratorCount}
          userPwdBit={conferenceBits.userPwdBit}
          attendeePW={conferenceBits.attendeePW}
          name={conferenceBits.attendeeName}
          isWebinar={conferenceBits.isWebinar || 0}
          moderatorToken={moderatorToken}
        />
      );
    } else {
      return (
        <div className="text-center">
          <img
            src="/images/loading.svg"
            width="300px"
            height="140px"
            alt="loader"
          />
        </div>
      );
    }
  }
}

function mapState(state) {
  //const {loadingStatus, joinError} = state.joinConference;
  const { conferenceBits, error } = state.conference;
  return {
    //loadingStatus,
    conferenceBits,
    error,
    //joinError
  };
}
const actionCreators = {
  //joinConference:joinActions.joinConference,
  meetingInfo: conferenceActions.meetingBits,
  alertError: alertActions.error,
};

export default withRouter(connect(mapState, actionCreators)(WaitingRoom));
