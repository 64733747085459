import React, {Component} from 'react';
// import classnames from 'classnames';
// import { TabContent, TabPane, Nav, NavItem, NavLink, Spinner } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Speakers from './RightComponents/Speakers';
import Attendees from './RightComponents/Attendees';
import Chat from './RightComponents/Chat';
import Questions from './RightComponents/Questions';
import { webinarRoomActions } from '../../../Redux/_actions';

class WebinarRoom extends Component{
    constructor(props){
        super(props);

        this.state = {
            activeTab : "0",
            webinarInfo:this.props.webinarInfo,
            webinarID : this.props.webinarID,
            token : this.props.token

        }
    }


    componentDidUpdate = (prevProps, prevState) => {
        let webinarInfo = this.state.webinarInfo;

        if(prevProps.webinarInfo !== this.props.webinarInfo){
            this.setState({
                webinarInfo:this.props.webinarInfo,
                webinarID:this.props.webinarID,
                token:this.props.token
            })
        }

        if(prevProps.handRaiseStatus !== this.props.handRaiseStatus){
            webinarInfo.handRaise = this.props.handRaiseStatus
        }
    }

    // toggleTabs = (tabID) => {
    //     if (this.state.activeTab !== tabID) {
    //         this.setState({"activeTab": tabID})
    //     }
    // }

    handRiseClick = (handRaiseBit) => {
      const { webinarID, token } = this.state;
      this.props.handRaiseFun(webinarID, token, handRaiseBit)
    }


    render(){

        const {webinarID, token, webinarInfo, activeTab} = this.state;
        // const { handRaiseLoading } = this.props;

        let handRaise = webinarInfo.handRaise || 0;

        let handRaiseClass = handRaise ? "hand_rise_btn active" : "hand_rise_btn" ;

        let userType = webinarInfo.user_type || "attendee";

        let isQuestion = webinarInfo.quesAns || false;

        return(
        <div>   

            <div className="tab-content webinar_tab_content">
                <div className="row">
                <div className="col-md-3 padlr-5">
                <div className="webinar_col speakerContent">
                    <h2 className="head-speaker"><i className="fas fa-bullhorn mr-1"></i> <font> Speakers </font></h2>
                    <Speakers webinarID={webinarID} token={token} activeTab={activeTab} webinarInfo={webinarInfo} />
                </div>
               </div>

               <div className="col-md-3 padlr-5">
               <div className="webinar_col attendeeContent">
                    <h2 className="head-attendee"><i className="fas fa-users">  </i> <font> Attendees </font></h2>
                    <Attendees webinarID={webinarID} token={token} activeTab={activeTab} webinarInfo={webinarInfo}  />
               </div>
               </div>

                <div className="col-md-3 padlr-5">
                  <div className="webinar_col chatContent">
                    <h2 className="head-chat"><i className="fas fa-comments"></i> <font>Chat</font></h2>
                    <Chat webinarID={webinarID} token={token} activeTab={activeTab} webinarInfo={webinarInfo}  />
                </div>
                </div>

                {isQuestion ?
                <div className="col-md-3 padlr-5">
                    <div className="webinar_col questionsContent">
                    <h2 className="head-question"><i className="fas fa-question-circle"></i>  <font>Questions </font></h2>
                    <Questions webinarID={webinarID} token={token} activeTab={activeTab} webinarInfo={webinarInfo} />
               </div> </div> : null }
             </div>
            </div>
        </div>
        )

    }

};

function mapState(state) {
  const {handRaiseStatus,handRaiseLoading} = state.webinarRoom
  return {
    handRaiseStatus,
    handRaiseLoading
  };
}
const actionCreators = {
    handRaiseFun : webinarRoomActions.handRaise
};



export default withRouter(connect(mapState, actionCreators)(WebinarRoom));
