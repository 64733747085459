/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import { Button, Modal, Col, Row, Form, InputGroup } from "react-bootstrap";
// import { Formik } from "formik";
// import { addYears } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import isEmpty from "is-empty";
import { AiOutlineSearch } from "react-icons/ai";
// import { DateRangePicker } from "react-dates"; //commented for vtest
// import "react-dates/initialize"; //commented for vtest
// import "react-dates/lib/css/_datepicker.css"; //commented for vtest

// import ReactPaginate from "react-paginate";
import {
  webinarActions,
  eventActions,
  conferenceActions,
  alertActions,
  userActions,
} from "../Redux/_actions";
import Spinner from "../components/UI/Spinner/Spinner";
import { tsToOnlyDate, tsToTime } from "../helpers/helpers";
import ConfirmationPopup from "../components/UI/ConfirmationPopup/confirmationPopup";
import Layout from "../layoutSection";
import CreateWebinar from "./createWebinar";
import { month, daysName } from "../Common/constant";
import VideoModal from '../Report/VideoRec'

import "./WebinarLists.css";

let colorIndex = 0;
const colorArr = [
  "#9D70FF",
  "#FE698D",
  "#61A0FF",
  "#F9AD67",
  "#7DDF86",
  "#4C4C4D",
  "#FD6868",
  "#FFD027",
  "#66E7B0",
];

const radioBtn = [
  { id: 1, label: "All", value: "all" },
  { id: 2, label: "Upcoming Webinar", value: "upcoming" },
  { id: 3, label: "Previous Webinar", value: "previous", class: "previousBtn" },
];

const getColor = () => {
  if (colorIndex === 8) {
    colorIndex = 0;
  } else {
    colorIndex += 1;
  }

  return colorArr[colorIndex];
};


const Webinar = (props) => {
  // const [itemOffset, setItemOffset] = useState(0);
  // const [pageCount, setPageCount] = useState(0);

  const [isVideoShow, setIsVideoShow] = useState(null)


  const webinarData = useSelector((state) => state.webinar);
  const conference = useSelector((state) => state.conference);

  const dispatch = useDispatch();

  const [isShowModal, setIsShowModal] = useState(false);
  const [isSearch, setIsSearch] = useState();
  const [listWeb, setListWeb] = useState(null);
  const [focus, setFocus] = useState(null);
  const [filter, setFilter] = useState({
    // title: "",
    startDate: null,
    endDate: null,
    type: "all",
    page: null,
    // pageCount: 0,
    // maxDate: momentPropTypes.momentObj,
  });
  const getDifferenceInDays = (filter) => {
    var start_date = new Date(filter.startDate);

    var end_date = new Date(filter.endDate);
    const diffTime = Math.abs(end_date - start_date);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays <= 30) {
      return true;
    } else {
      return false;
    }
  };

  const handleSearchChange = (e) => {
    setIsSearch(e.target.value);
  };

  useEffect(() => {
    if (filter.startDate && filter.endDate) {
      var data = getDifferenceInDays(filter);
      if (!data) {
        dispatch(alertActions.error("Please Select Less Than 30 Days"));
        return;
      }
    }

    dispatch(webinarActions.webinarList(filter));
  }, [dispatch, filter]);

  useEffect(() => {
    setListWeb(webinarData.webinarList.result);
  }, [webinarData.webinarList.result]);

  // useEffect(() => {
  //   setPageCount(webinarData.webinarList.count);
  // }, [webinarData.webinarList.count]);

  useEffect(() => {
    dispatch(eventActions.gAuthURL());
    dispatch(userActions.gContactAuthURL());
  }, []);
  useMemo(() => {
    if (!isEmpty(webinarData.webinarList)) {
      const { startDate, title, endDate, type } = filter;
      if (
        isEmpty(startDate) &&
        isEmpty(title) &&
        isEmpty(endDate) &&
        isEmpty(type)
      ) {
        setListWeb(webinarData.webinarList);
      } else {
        let result = webinarData.webinarList.result;

        if (!isEmpty(title)) {
          const res = result.filter((data) =>
            data?.name.toLowerCase().includes(title.toLowerCase())
          );
          result = res;
        }

        if (!isEmpty(startDate) && !isEmpty(endDate)) {
          let start = new Date(startDate._d);
          start = start.setDate(start.getDate() - 1);
          let end = new Date(endDate._d);
          end = end.setDate(end.getDate() + 1);
          const res = result.filter((data) => {
            return (
              start <= new Date(data?.start_date).getTime() &&
              end >= new Date(data?.start_date).getTime()
            );
          });
          result = res;
        }

        if (!isEmpty(type)) {
          const res = result.filter((data) => data?.type === type);
          result = res;
        }
        if (isEmpty(result)) {
          setListWeb("no result");
        } else {
          setListWeb(result);
        }
      }
    }
    setIsSearch("");
  }, [filter.title, filter.type, filter.endDate]);

  const openDetailPage = (webinarID) => {
    props.history.push("/webinars/" + webinarID);
  };
  // let totalPages = Math.ceil(webinarData.webinarList.count) / 20;
  const deleteMeetingConfirm = (meetingID) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmationPopup
            message={"Are you sure want to delete this webinar ?"}
            noBtnAction={onClose}
            noBtnText="No"
            yesBtnText="Yes, Delete AnyWay!"
            yesBtnAction={() =>
              dispatch(webinarActions.deleteWebinar(meetingID))
            }
          />
        );
      },
    });
  };

  const startWebinar = (id) => {
    if (id) {
      dispatch(conferenceActions.startMeeting(id, "webinar"));
    } else {
      dispatch(alertActions.error("something is not Right!"));
    }
  };
  // const handlePageClick = (event) => {
  //   let pageNumber = event.selected + 1;
  //   setFilter({ ...filter, page: pageNumber });
  //   const newOffset = (event.selected * 20) % pageCount;
  //   setItemOffset(newOffset);
  //  setIsSearch("");
  // };
  const PageInfo = () => {
    if (
      webinarData.listLoading ||
      (webinarData.webinarList.length > 0 &&
        listWeb != null &&
        listWeb.length === 0)
    ) {
      return (
        <div className="text-center">
          <Spinner />
        </div>
      );
    } else if (typeof listWeb === "object" && listWeb?.length > 0) {
      const dataFilter =
        isSearch === null || isSearch === undefined || isSearch.length < 0
          ? listWeb
          : listWeb.filter((webinarData) =>
            webinarData.name.toLowerCase().includes(isSearch.toLowerCase())
          );
      return dataFilter.map((webinarData) => {
        const currentDate = new Date().getTime();
        const webinarID = webinarData.meetingID || null;
        const title = webinarData.name || null;
        let isMeetingEnded = webinarData.isMeetingEnded || 0;
        let isCancelled = webinarData.isCancelled || 0;
        let hasRecording = webinarData.hasRecording || 0;
        const type = webinarData.type || null;

        let start_date = new Date(webinarData.startTS);
        let start_time = new Date(webinarData.startTS);
        let end_time = new Date(webinarData.endTS);


        let getStartDate = start_date
          ? tsToOnlyDate(new Date(start_date).getTime())
          : null;
        let getDate = start_date
          ? ("0" + new Date(start_date).getDate()).substr(-2)
          : null;
        let getMonth = start_date
          ? month[new Date(start_date).getMonth()]
          : null;
        let getDay = start_date
          ? daysName[new Date(start_date).getDay() - 1]
          : null;
        let getTime = start_time
          ? tsToTime(new Date(start_time).getTime())
          : null;
        let endTime = end_time ? tsToTime(new Date(end_time).getTime()) : null;
        const startDate = new Date(start_date);
        const startTime = new Date(start_time);


        const startTS = new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate(),
          startTime.getHours(),
          startTime.getMinutes(),
          startTime.getSeconds()
        );
        let typeData =
          type === "live" ? (
            <span className="w_type_live">
              <span className="text-muted" > Live Webinar </span>
              <br />
              <span className="f_size_15"> {webinarID} </span>
            </span>
          ) : (
            <span className="welcomeMsg w_type_recorded">
              <div className="text-muted" > Webinar Id </div>

              <div className="f_size_15 mt-2"> {webinarID} </div>
            </span>
          );

        let upcomingClass = null;
        if (isCancelled) {
          upcomingClass = "cancel_webinar";
        } else if (
          currentDate < new Date(startTS).getTime() &&
          !isMeetingEnded
        ) {
          upcomingClass = "upcoming_webinar_unique";
        }

        return (
          <>
            <li
              className={`${upcomingClass || ""} sideBar  welcomeMsg row p-2 mt-2 mx-auto`}
              key={webinarID}
            >
              <Col lg={5}>
                <div
                  className="m_start_date"
                  style={{ background: getColor() }}
                >
                  <font className="d-flex justify-content-center">
                    <i className="fas fa-calendar"> </i>
                    &nbsp; {getDate}
                    <span className="month_wave_nc"> {getMonth}</span>{" "}
                  </font>
                  <span className="">
                    {getDay}, <i className="fas fa-clock"></i> {getTime}
                  </span>
                </div>
                <div className="sch_meeting_othr_dtl">
                  <div className="meeting_title">{title}</div>
                  <div className="meeting_date_time">
                    <span>
                      <i className="far fa-calendar"></i> {getStartDate}{" "}
                      <i className="far fa-clock ml-1"></i> {getTime}
                    </span>
                    <span className="mr-1 ml-2">-</span>
                    <span>
                      <i className="far fa-clock ml-1"></i> {endTime}
                    </span>
                  </div>
                </div>
              </Col>

              <Col lg={2} className="col-sm col-12 text-center">
                <div className="meetId bg-transparent my-3">{typeData}</div>
              </Col>


              <Col
                className="schedule_actn_btn text-right col-sm col-12 "
                lg={5}
              >
                <div className="schedule_webinar_link_btn">
                  {!isCancelled ? (
                    <>
                      {!isMeetingEnded &&
                        currentDate > new Date(startTS).getTime() && currentDate < new Date(end_time).getTime() && (
                          <button
                            className="btn btn-webinar bg-primary text-white btnSectionCard btn-sm mr-2 mb-1"
                            onClick={() => startWebinar(webinarID)}
                          >
                            {" "}
                            Start webinar{" "}
                          </button>
                        )}

                      {!isMeetingEnded &&
                        currentDate > new Date(end_time).getTime() && hasRecording === 1 && (
                          <button
                            className="btn btn-webinar bg-success text-white btnSectionCard btn-sm mr-2 mb-1"
                            onClick={() => setIsVideoShow({ meetingId: webinarID })}
                          >
                            {" "}
                            View Recording{" "}
                          </button>
                        )}
                      {/* <button>
                      openGoogleAuth={() => openGoogleAuth(gContactAuthURL)}
                      synchGoogle=
                      {() => dispatch(eventActions.addEventOnGoogle(webinarID))}
                      {""}
                      Add To Google
                    </button> */}
                      <button
                        className="btn btn-webinar  sideBarIcon btnSectionCard welcomeMsg btn-sm mr-2"
                        onClick={() => openDetailPage(webinarID)}
                      >
                        Webinar Detail
                      </button>
                    </>
                  ) : (
                    <button
                      className="btn btn-webinar btnSectionCard text-white btn-sm bg-danger "
                      onClick={() => deleteMeetingConfirm(webinarID)}
                    >
                      Delete Webinar
                    </button>
                  )}
                </div>
              </Col>
            </li>
          </>
        );
      });
    } else {
      return (
        <div className="resultNotFound sideBarIcon welcomeMsg">
          No Webinar Available{" "}
        </div>
      );
    }
  };

  const setState = (event) => {
    setFilter({ ...filter, [event.target.name]: event.target.value });
  };
  const { gContactAuthInfo, gContactAuthURL, gContactSyncLoading } =
    useSelector((state) => state.authentication);
  const openGoogleAuth = (auth) => {
    let win;
    // win = window.open(googleAuthURL.authURL, "win", "width=750,height=660,top=70,left=100,resizable=1,menubar=yes");
    // win.focus();
    // setInterval(function () {
    //     if (win.closed) {
    //         window.location.reload();
    //         clearInterval(win);
    //     }
    // }, 500);
    if (auth !== null) {
      if (typeof win === undefined) {
        let win;
      }
      if (win && !win.closed) {
        win.focus();
      } else {
        let x = window.screen.width / 2 - 550 / 2;
        //let y = 0;
        let REDIRECT = "meetings";
        let y = window.screen.height / 2 - 280 / 2;
        win = window.open(
          auth,
          "win",
          "width=550,height=560,left=" +
          x +
          ",top=" +
          y +
          ",resizable=1,menubar=yes"
        );
        win.focus();
        var pollTimer = window.setInterval(function () {
          try {
            if (win.document.URL.indexOf(REDIRECT) !== -1) {
              window.clearInterval(pollTimer);
              var url = win.document.URL;
              let gCode = gup(url, "code");
              //let tokenType = gup(url, 'token_type');
              //let expiresIn = gup(url, 'expires_in');
              win.close();
              if (
                gCode !== null &&
                gCode !== "null" &&
                gCode !== "undefined" &&
                typeof gCode !== undefined
              ) {
                dispatch(eventActions.gAuthToken(gCode));
              }
            }
          } catch (e) {
            if (win.closed) {
              window.clearInterval(pollTimer);
              dispatch(alertActions.error("Authorization Failed !"));
            }
          }
        }, 500);

        function gup(url, name) {
          name = name.replace(/[[]/, "[").replace(/[]]/, "]");
          var regexS = "[?&]" + name + "=([^&#]*)";
          var regex = new RegExp(regexS);
          var results = regex.exec(url);
          if (results == null) return "";
          else return results[1];
        }
      }
    }
  };

  const LeftHeader = () => (
    <>
      {gContactAuthInfo && gContactAuthInfo?.length === 0 ? (
        <Button
          className="newWebinar-btn submitSubColor submitSubColor-md border-0 mr-0 px-2 btn btn-primary"
          onClick={() => openGoogleAuth(gContactAuthURL)}
        >
          {gContactSyncLoading ? (
            "Loading..."
          ) : (
            <>
              <i className="fa fa-address-book mr-1"></i>
              <span className="left-header-btn"> Authorized to Google </span>
            </>
          )}
        </Button>
      ) : null}
      <Button
        className="newWebinar-btn submitBtnColor submitBtnColor-md border-0 px-2 mr-0 ml-2 btn btn-primary"
        onClick={() => setIsShowModal(true)}
      >
        <i className="far fa-calendar-plus mr-2" />
        <span className="left-header-btn">Schedule Webinar</span>
      </Button>
    </>
  );

  return (
    <Layout title={<LeftHeader />} active="Webinar">
      <div>
        {conference.startMeetingLoading && (
          <Modal
            show={conference.startMeetingLoading}
            contentClassName="modalLoading"
          >
            <Spinner />
          </Modal>
        )}
        {isShowModal && (
          <CreateWebinar
            show={isShowModal}
            toggle={() => setIsShowModal(false)}
          />
        )}
        <div className="container-fluid s_wave_content_part webinar">
          <Row className="justify-content-between webinar mt-3">
            <Form.Group className="searchSectionWebinar">
              <InputGroup hasValidation>
                <InputGroup.Text className="welcomeMsg sideBar inputGroup">
                  <AiOutlineSearch className="f_size_26" />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Search by Webinar Title"
                  // onChange={(event) => setState(event)}
                  name="title"
                  className="searchInput welcomeMsg sideBar h55 searchInputFilter form-control webinarSearchSection"
                  aria-describedby="inputGroupPrepend"
                  value={isSearch}
                  onChange={handleSearchChange}
                  // value={filter.title}
                  style={{ width: "178px" }}
                />
                <InputGroup.Text className="sideBar inputGroupCancel">
                  {!isEmpty(isSearch) && (
                    <button
                      className="btnBgLess welcomeMsg text-right"
                      onClick={() => setIsSearch("")}
                      style={{ position: "absolute", right: "7px" }}
                    >
                      X
                    </button>
                  )}
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>

            <Form.Group className="radioBtnWeb webinarsTopBtnsGroup">
              {radioBtn?.map((data) => (
                <button
                  className={` meetSectionUpload    ${filter.type === data.value
                    ? "submitBtnColor text-white"
                    : "sideBar welcomeMsg"
                    } ${data.class || ''}  `}
                  onClick={() =>
                    setFilter({
                      ...filter,
                      type: data.value,
                      page: 1,
                    })
                  }
                  key={`radio-${data.id}`}
                >
                  {data.label}
                </button>
              ))}
            </Form.Group>
            {/* <DateRangePicker
              startDate={filter.startDate}
              startDatePlaceholderText="From"
              endDatePlaceholderText="To"
              startDateId="startingDate"
              endDate={filter.endDate}
              endDateId="EndingDate"
              onDatesChange={({ startDate, endDate }) => {
                //let datas = getDifferenceInDays(startDate, endDate);
                setFilter({
                  ...filter,
                  startDate,
                  endDate,
                  page: 1,
                });
              }}
              focusedInput={focus}
              onFocusChange={(focusedInput) => setFocus(focusedInput)}
              monthFormat="MMMM YYYY"
              showClearDates
              readOnly
              daySize={30}
              isOutsideRange={() => false}
            /> */}
          </Row>
          <div className="wave_webinar_list pt-3">
            <ul>
              <PageInfo />
            </ul>
            {/* <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              onPageChange={handlePageClick}
              pageRangeDisplayed={pageCount}
              pageCount={totalPages}
              previousLabel="<"
              renderOnZeroPageCount={null}
              className="paginate-m welcomeMsg "
              activeClassName={"active"}
            /> */}
          </div>
        </div>
        {
          !isEmpty(isVideoShow) && <VideoModal data={isVideoShow} toggle={() => setIsVideoShow(null)} />
        }
      </div>
    </Layout>
  );
};

export default Webinar;
