import { myconfig, siteTokens } from "../../config/config";
//import { authHeader } from '../_helpers';
import axios from "axios";
import userconfigs from "../../helpers/closures";
import { generateToken } from "../../helpers/helpers";

import { handleResponse, errorCheck, handleJWTError } from './common'

export const joinService = {
  join,
  hostJoinMeet,
  attendeeJoinMeet,
};

function join(isWebinar, join_name, join_id, join_password, moderatorToken) {
  let axiosRequestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: userconfigs.getItem("jwt"),
    },
  };

  let loginPost = {
    meetingId: join_id,
    name: join_name,
  };
  if (join_password !== "undefiend") {
    loginPost.pwd = join_password;
  }
  if (moderatorToken !== "undefiend" && moderatorToken !== null) {
    loginPost.moderatorToken = moderatorToken;
  }

  let api_url = `${myconfig.apiURL}meeting/joinMeet`;
  if (isWebinar === 1) {
    api_url = `${myconfig.apiURL}webinar/joinWebinar`;
  }

  return axios
    .post(api_url, loginPost, axiosRequestOptions)
    .then(handleResponse)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
        handleJWTError();
      } else {
        return Promise.reject(error);
      }
    });
}

function hostJoinMeet(isWebinar, join_id, join_password) {
  let axiosRequestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: userconfigs.getItem("jwt"),
    },
  };

  let loginPost = {
    meetingId: join_id,
    //name: join_name
  };
  if (join_password !== "undefiend") {
    loginPost.pwd = join_password;
  }

  let api_url = `${myconfig.apiURL}itba/startSchMeet`;
  // if(isWebinar === 1){
  //     api_url = `${myconfig.apiURL}webinar/joinWebinar`
  // }

  return axios
    .post(api_url, loginPost, axiosRequestOptions)
    .then(handleResponse)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      if (error.response.status === 408) {
        userconfigs.removeItem("jwt");
        generateToken();
        window.location.href = "/login";
      } else {
        return Promise.reject(errorCheck(error));
      }
      //return error.response.data.error;
    });
}

function attendeeJoinMeet(isWebinar, join_name, join_id, join_password) {
  let axiosRequestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: userconfigs.getItem("jwt"),
    },
  };

  let loginPost = {
    meetingId: join_id,
    name: join_name,
  };
  if (join_password !== "undefiend") {
    loginPost.pwd = join_password;
  }

  let api_url = `${myconfig.apiURL}itba/joinSchMeet`;
  // if(isWebinar === 1){
  //     api_url = `${myconfig.apiURL}webinar/joinWebinar`
  // }

  return axios
    .post(api_url, loginPost, axiosRequestOptions)
    .then(handleResponse)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      if (error.response.status === 408) {
        userconfigs.removeItem("jwt");
        generateToken();
        window.location.href = "/login";
      } else {
        return Promise.reject(errorCheck(error));
      }
      //return error.response.data.error;
    });
}
