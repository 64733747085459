/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AiOutlineEdit, AiOutlineShareAlt, AiTwotoneDelete } from 'react-icons/ai';
import { FaUserPlus } from 'react-icons/fa';
import { Button } from 'react-bootstrap'
import isEmpty from 'is-empty';
import { confirmAlert } from 'react-confirm-alert';

import Layout from '../../layoutSection'
import Table from '../../layoutSection/table';
import { conferenceActions } from '../../Redux/_actions';
import Spinner from '../../components/UI/Spinner/Spinner';
import { AddParticipants } from './Modal'
import ShareModal from '../../layoutSection/Modal/shareModal';
import { myconfig } from '../../config/config';
import ConfirmationPopup from '../../components/UI/ConfirmationPopup/confirmationPopup';



const MeetingParticipants = () => {
  const dispatch = useDispatch();
  const { meetingDetail, loading } = useSelector(state => state.conference)
  const paras = useParams()
  const [modal, setModal] = useState({
    addParticipants: false,
    shareURl: null,
  })

  useEffect(() => {
    if (paras.id && meetingDetail?.meetingID !== paras.id) {
      dispatch(conferenceActions.meetingDetail(paras.id))
    }
  }, [])

  const setStateModal = (type, value) => {
    if (value) {
      setModal({ ...modal, [type]: value })
    } else {
      setModal({ ...modal, [type]: !modal[type] })
    }
  }


  const getHeader = () => {
    return <tr>
      <th>Invited</th>
      {meetingDetail?.isMeetingEnded !== 1 && <th>Action</th>}
    </tr>
  }

  const removeParticipant = (participantID, meetId) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (<ConfirmationPopup
          message={"Are you sure want to remove this Participant ?"}
          noBtnAction={onClose}
          noBtnText="No"
          yesBtnText="Yes, Remove AnyWay!"
          yesBtnAction={() => dispatch(conferenceActions.deleteInviteUser(participantID, meetId))}
        />
        )
      }
    });
  }

  const GetRow = () => {
    // if (loading) {
    //   return <tr> <td colSpan={2}> <div className="text-center"><Spinner /></div> </td> </tr>
    // } else
    if (meetingDetail?.participant?.length > 0) {
      return meetingDetail?.participant?.map(data =>
        <tr key={`member-${data._id}`} >
          <td>{data.v}</td>
          {meetingDetail?.isMeetingEnded !== 1 && <td className="text-center noteText"> <button className="btnBgLess f_size_22 share" onClick={() => setStateModal("shareURl", `${myconfig.serverURL}/join_meeting?mid=${paras.id}${data.p ? `&token=${data.p}` : ''}`)}>
            <AiOutlineShareAlt /> </button>  <button className="btnBgLess f_size_22 text-warning mx-3" onClick={() => dispatch(conferenceActions.reInviteUser(data.mid, data.v))}><i className="fas fa-redo f_size_15"></i> </button>
            <button className="btnBgLess text-danger f_size_22" onClick={() => removeParticipant(data._id, meetingDetail.meetingID)}><AiTwotoneDelete /> </button>  </td>}
        </tr >
      )
    } else {
      return <tr> <td colSpan={2}><div className="resultNotFound  bg-transparent welcomeMsg f_size_16">No Participants Available </div> </td> </tr>
    }
  }


  return (
    <Layout title={meetingDetail?.name || 'Participants'} active="Participants" list="meeting" goBack="/meeting_history" >
      <div className='participants'>
        <div className='between'>
          <h5 className='welcomeMsg' >Participants</h5>
          <Button className="headerBtn submitBtnColor border-0 mr-3 mt-1" onClick={() => setStateModal('addParticipants')}>
            <FaUserPlus className="mr-2 f_size_18" />
            Add Participants
          </Button>
        </div>
        <div className='mt-4'>
          <Table
            headers={getHeader()}
            className="text-center br12"
          >
            <GetRow />
          </Table>
        </div>
        {
          modal.addParticipants && <AddParticipants
            show={modal.addParticipants}
            toggle={() => setStateModal('addParticipants')}
            meetId={paras.id}
            onSubmit={(value) => dispatch(conferenceActions.addMoreParticipant(value))}
          />
        }
        {
          !isEmpty(modal.shareURl) && <ShareModal
            show={!isEmpty(modal.shareURl)}
            toggle={() => setModal({ ...modal, shareURl: null })}
            shareURl={modal.shareURl}
          />
        }
      </div>
    </Layout>
  )
}

export default MeetingParticipants
