export const settingConstants = {
  BRANDING_GET_REQ: 'BRANDING_GET_REQ',
  BRANDING_GET_SUCCESS: 'BRANDING_GET_SUCCESS',
  BRANDING_GET_FAILURE: 'BRANDING_GET_FAILURE',

  BRANDING_DELETE_REQ: 'BRANDING_DELETE_REQ',
  BRANDING_DELETE_SUCCESS: 'BRANDING_DELETE_SUCCESS',
  BRANDING_DELETE_FAILURE: 'BRANDING_DELETE_FAILURE',

  BRANDING_REQ: 'BRANDING_REQ',
  BRANDING_SUCCESS: 'BRANDING_SUCCESS',
  BRANDING_FAILURE: 'BRANDING_FAILURE',
};
