export const userConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    USER_REMOVE_FROM_BUSINESS_REQUEST: 'USERS_REMOVE_FROM_BUSINESS_REQUEST',
    USER_REMOVE_FROM_BUSINESS_SUCCESS: 'USERS_REMOVE_FROM_BUSINESS_SUCCESS',
    USER_REMOVE_FROM_BUSINESS_FAILURE: 'USERS_REMOVE_FROM_BUSINESS_FAILURE',

    PROFILE_PHOTO_REQUEST: 'PROFILE_PHOTO_REQUEST',
    PROFILE_PHOTO_SUCCESS: 'PROFILE_PHOTO_SUCCESS',
    PROFILE_PHOTO_FAILURE: 'PROFILE_PHOTO_FAILURE',

    LOGIN_OTP_REQUEST: 'LOGIN_OTP_REQUEST',
    LOGIN_OTP_SUCCESS: 'LOGIN_OTP_SUCCESS',
    LOGIN_OTP_FAILURE: 'LOGIN_OTP_FAILURE',

    SIGNUP_REQUEST: 'USERS_SIGNUP_REQUEST',
    SIGNUP_SUCCESS: 'USERS_SIGNUP_SUCCESS',
    SIGNUP_FAILURE: 'USERS_SIGNUP_FAILURE',

    VALIDATE_SIGNUP_REQUEST: 'USERS_VALIDATE_SIGNUP_REQUEST',
    VALIDATE_SIGNUP_SUCCESS: 'USERS_VALIDATE_SIGNUP_SUCCESS',
    VALIDATE_SIGNUP_FAILURE: 'USERS_VALIDATE_SIGNUP_FAILURE',

    RESEND_EMAIL_REQUEST: 'RESEND_EMAIL_REQUEST',
    RESEND_EMAIL_SUCCESS: 'RESEND_EMAIL_SUCCESS',
    RESEND_EMAIL_FAILURE: 'RESEND_EMAIL_FAILURE',

    LOGIN_DETAILS_REQUEST: 'LOGIN_DETAILS_REQUEST',
    LOGIN_DETAILS_SUCCESS: 'LOGIN_DETAILS_SUCCESS',
    LOGIN_DETAILS_FAILURE: 'LOGIN_DETAILS_FAILURE',

    USER_DETAILS_REQUEST: 'USER_DETAILS_REQUEST',
    USER_DETAILS_SUCCESS: 'USER_DETAILS_SUCCESS',
    USER_DETAILS_FAILURE: 'USER_DETAILS_FAILURE',

    USER_CONTACTS_REQUEST: 'USER_CONTACTS_REQUEST',
    USER_CONTACTS_SUCCESS: 'USER_CONTACTS_SUCCESS',
    USER_CONTACTS_FAILURE: 'USER_CONTACTS_FAILURE',

    PROFILE_UPDATE_REQUEST: 'PROFILE_UPDATE_REQUEST',
    PROFILE_UPDATE_SUCCESS: 'PROFILE_UPDATE_SUCCESS',
    PROFILE_UPDATE_FAILURE: 'PROFILE_UPDATE_FAILURE',

    PASSWORD_UPDATE_REQUEST: 'PASSWORD_UPDATE_REQUEST',
    PASSWORD_UPDATE_SUCCESS: 'PASSWORD_UPDATE_SUCCESS',
    PASSWORD_UPDATE_FAILURE: 'PASSWORD_UPDATE_FAILURE',

    VERIFY_USERNAME_REQUEST: 'VERIFY_USERNAME_REQUEST',
    VERIFY_USERNAME_SUCCESS: 'VERIFY_USERNAME_SUCCESS',
    VERIFY_USERNAME_FAILURE: 'VERIFY_USERNAME_FAILURE',

    OTP_VERIFY_REQUEST: 'OTP_VERIFY_REQUEST',
    OTP_VERIFY_SUCCESS: 'OTP_VERIFY_SUCCESS',
    OTP_VERIFY_FAILURE: 'OTP_VERIFY_FAILURE',

    FORGET_PASSWORD_REQUEST: 'FORGET_PASSWORD_REQUEST',
    FORGET_PASSWORD_SUCCESS: 'FORGET_PASSWORD_SUCCESS',
    FORGET_PASSWORD_FAILURE: 'FORGET_PASSWORD_FAILURE',

    RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
    RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',

    AUTH_GOOGLE_REQUEST: 'AUTH_GOOGLE_REQUEST',
    AUTH_GOOGLE_SUCCESS: 'AUTH_GOOGLE_SUCCESS',
    AUTH_GOOGLE_FAILURE: 'AUTH_GOOGLE_FAILURE',

    GOOGLE_AUTH_URL_REQUEST: 'GOOGLE_AUTH_URL_REQUEST',
    GOOGLE_AUTH_URL_SUCCESS: 'GOOGLE_AUTH_URL_SUCCESS',
    GOOGLE_AUTH_URL_FAILURE: 'GOOGLE_AUTH_URL_FAILURE',

    GOOGLE_CONTACT_AUTH_URL_REQUEST: 'GOOGLE_CONTACT_AUTH_URL_REQUEST',
    GOOGLE_CONTACT_AUTH_URL_SUCCESS: 'GOOGLE_CONTACT_AUTH_URL_SUCCESS',
    GOOGLE_CONTACT_AUTH_URL_FAILURE: 'GOOGLE_CONTACT_AUTH_URL_FAILURE',

    GOOGLE_AUTH_TOKEN_SUCCESS: 'GOOGLE_AUTH_TOKEN_SUCCESS',
    GOOGLE_AUTH_TOKEN_FAILURE: 'GOOGLE_AUTH_TOKEN_FAILURE',

    GOOGLE_CONTACT_AUTH_TOKEN_SUCCESS: 'GOOGLE_CONTACT_AUTH_TOKEN_SUCCESS',
    GOOGLE_CONTACT_AUTH_TOKEN_FAILURE: 'GOOGLE_CONTACT_AUTH_TOKEN_FAILURE',

    GOOGLE_CONTACT_SYNC_REQUEST: 'GOOGLE_CONTACT_SYNC_REQUEST',
    GOOGLE_CONTACT_SYNC_SUCCESS: 'GOOGLE_CONTACT_SYNC_SUCCESS',
    GOOGLE_CONTACT_SYNC_FAILURE: 'GOOGLE_CONTACT_SYNC_FAILURE',

    REMOVE_GOOGLE_AUTH_REQUEST: 'REMOVE_GOOGLE_AUTH_REQUEST',
    REMOVE_GOOGLE_AUTH_SUCCESS: 'REMOVE_GOOGLE_AUTH_SUCCESS',
    REMOVE_GOOGLE_AUTH_FAILURE: 'REMOVE_GOOGLE_AUTH_FAILURE',

    UPGRADE_REQUEST: 'UPGRADE_REQUEST',
    UPFRADE_SUCCESS: 'UPFRADE_SUCCESS',
    UPGRADE_FAILURE: 'UPGRADE_FAILURE',

    ADD_USER_ACCOUNT_REQUEST: 'ADD_USER_ACCOUNT_REQUEST',
    ADD_USER_ACCOUNT_SUCCESS: 'ADD_USER_ACCOUNT_SUCCESS',
    ADD_USER_ACCOUNT_FAILURE: 'ADD_USER_ACCOUNT_FAILURE',

    EDIT_USER_ACCOUNT_REQUEST: 'EDIT_USER_ACCOUNT_REQUEST',
    EDIT_USER_ACCOUNT_SUCCESS: 'EDIT_USER_ACCOUNT_SUCCESS',
    EDIT_USER_ACCOUNT_FAILURE: 'EDIT_USER_ACCOUNT_FAILURE',

    UPLOAD_CSV_REQUEST: 'UPLOAD_CSV_REQUEST',
    UPLOAD_CSV_SUCCESS: 'UPLOAD_CSV_SUCCESS',
    UPLOAD_CSV_FAILURE: 'UPLOAD_CSV_FAILURE',

    CHECK_EMAIL_VERIFIED_REQ: 'CHECK_EMAIL_VERIFIED_REQ',
    CHECK_EMAIL_VERIFIED_SUCCESS: 'CHECK_EMAIL_VERIFIED_SUCCESS',
    CHECK_EMAIL_VERIFIED_FAILURE: 'CHECK_EMAIL_VERIFIED_FAILURE',

    GET_ACCESSIBILITY_REQUEST: 'GET_ACCESSIBILITY_REQUEST',
    GET_ACCESSIBILITY_SUCCESS: 'GET_ACCESSIBILITY_SUCCESS',
    GET_ACCESSIBILITY_FAILURE: 'GET_ACCESSIBILITY_FAILURE',

    LOGOUT: 'USERS_LOGOUT'

};
