import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { daysName } from '../../Common/constant';
import { Spinner } from 'reactstrap';

import { checkDateTimeFromNow, getlocalDateTimeFromUTC, timeToIndiaTime, tsToOnlyDate, tsToTime } from '../../helpers/helpers';
import { useDispatch } from 'react-redux';
import { myconfig } from '../../config/config';
import { conferenceActions } from '../../Redux/_actions';

let colorIndex = 0;
const colorArr = ['#9D70FF', '#FE698D', '#61A0FF', '#F9AD67', '#7DDF86', '#4C4C4D', '#FD6868', '#FFD027', '#66E7B0']
const month = ["Jan", "Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];

const createDateFormate = (timeStmp, formate) => {

  timeStmp = parseInt(timeStmp);
  var currentDate = new Date(timeStmp);
  var date = "0" + currentDate.getDate();
  var months = "0" + (currentDate.getMonth() + 1);
  var year = currentDate.getFullYear();

  if (formate === 2) {

    timeStmp = parseInt(timeStmp);

    var formattedDate = date.substr(-2) + '-' + months.substr(-2) + '-' + year;

    return formattedDate;


  } else {
    timeStmp = parseInt(timeStmp);

    var formattedDateName = date.substr(-2) + ' ' + month[months] + ', ' + year;

    return formattedDateName;
  }
}

const getColor = () => {
  if (colorIndex === 8) {
    colorIndex = 0
  } else {
    colorIndex += 1
  }

  return colorArr[colorIndex]
}

const MeetListCard = (props) => {
  const { meetingInfo, history, gAuthURL, syncLoading, openGoogleAuth, synchGoogle, deleteMeetingConfirm, gAuthTokenVerified, showRecode, event, AllType, deleteMeetingConfirmRecurring } = props

  // const currentDate = new Date().getTime();
  // const meetID = data.meetingID || null;
  // const title = data.name || null;
  // let start_date = data.start_date ? data.start_date : data?.startTS;
  // let start_time = data.start_time ? data.start_time : data?.startTS;
  // let end_time = data?.end_time ? data?.end_time : data?.meetingEndTime;
  // let isMeetingEnded = data.isMeetingEnded || 0;

  // let getStartDate = start_date ? tsToOnlyDate(start_date) : null;
  // let getDate = start_date ? ("0" + new Date(start_date).getDate()).substr(-2) : null;
  // let getMonth = start_date ? month[new Date(start_date).getMonth()] : null;
  // let getDay = start_date ? daysName[new Date(start_date).getDay() - 1] : null;
  // let getTime = start_time ? tsToTime(new Date(start_time).getTime()) : null;
  // let endTime = end_time ? tsToTime(new Date(end_time).getTime()) : null;
  // const startDate = new Date(start_date)
  // const startTime = new Date(start_time)
  // const startTS = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), startTime.getHours(), startTime.getMinutes(), startTime.getSeconds())

  const dispatch = useDispatch()

  let startMonth = null;
  let startDate = null;
  let syncGoogle = null
  let startTime = null
  let startYear = null
  let getDay = null
  let getMonth = null
  let getDate = null
  let getTime = null
  let getStartDate = null
  let getNewEndTime = null
  let getNewEndDate = null

  let meetingStartDate = null
  let meetingStartTime = null
  let meetingEndDateVal = null;
  let meetingEndTimeVal = null;
  let meetName = null

  let isScheduled = meetingInfo?.meetingdetails[0]?.isScheduled || 0;
  let isCancelled = meetingInfo?.isCancelled || 0;

  let isMeetEditable = false;

  const startMeet = () => {
    if (meetingInfo?.meetingdetails[0]?.waitingRoom === 1) {
      const redirectPath = myconfig.serverURL + "/join_meeting?mid=" + meetingInfo.meetingID;
      window.location.href = redirectPath;
    } else {
      dispatch(conferenceActions.startMeeting(meetingInfo.meetingID, 'meeting'));
    }
  }


  const getURL = () => {
    if (event?.id === meetingInfo.meetingID && event?.events) {
      return event.events
    }
    return meetingInfo?.meetingdetails[0]?.googleCalendarLink
  }


  let className = "general_meeting_unique";

  let currentDate = new Date().getTime();
  if (true) {

    let startTS = meetingInfo.mstartTs || 0;
    meetName = meetingInfo?.meetingdetails[0]?.name


    const isNotPassed = checkDateTimeFromNow(startTS);
    if (isNotPassed) {
      isMeetEditable = true
    }

    if (AllType && currentDate < startTS) {
      className = "schedule_meeting_unique";
    }


    if (meetingInfo?.reCurringType === 'notFixed') {
      className = "schedule_meeting_unique_notFixed";
    }
    // converting time to time stamp start 
    let start_date = new Date(meetingInfo?.mstartTs);
    getStartDate = start_date
      ? tsToOnlyDate(new Date(start_date).getTime())
      : null;
    getDate = start_date
      ? ("0" + new Date(start_date).getDate()).substr(-2)
      : null;
    getMonth = start_date
      ? month[new Date(start_date).getMonth() + 1]
      : null;
    getDay = start_date
      ? daysName[new Date(start_date).getDay() - 1]
      : null;
    getTime = start_date
      ? tsToTime(new Date(start_date).getTime())
      : null;

    // converting time to time stamp end


    // let startDateVal = meetingInfo.m_start_date ? (meetingInfo.m_start_date+"").split("-"):meetingInfo.m_start_date;
    // if (startDateVal) {
    //   startDate = startDateVal[2];
    //   startMonth = month[parseInt(startDateVal[1])];
    //   startYear = startDateVal[0]
    // }

    // let startTimeVal = meetingInfo.m_start_time ? (meetingInfo.m_start_time+"").split(":"):meetingInfo.m_start_date;
    // if (startTimeVal) {
    //   startTime = timeToIndiaTime(startTimeVal[0], startTimeVal[1]);
    // }


    // let meetingStartTimeVal =startTimeVal;
    // if (meetingStartTimeVal) {
    //   meetingStartDate = meetingInfo.m_start_date
    //   meetingStartTime = timeToIndiaTime(meetingStartTimeVal[0], meetingStartTimeVal[1]);
    // }

    // if (meetingInfo.hasOwnProperty('startUTC') && meetingInfo.startUTC !== null) {
    //   const { date, time } = getlocalDateTimeFromUTC(meetingInfo.startTS)
    //   meetingStartDate = date;
    //   meetingStartTime = time;
    //   startTime = time;
    //   startDateVal = meetingStartDate?.split("-");
    //   startDate = startDateVal[2];
    //   startMonth = month[parseInt(startDateVal[1])];

    // }

    if (meetingInfo?.mendTs) {
      let end_date = new Date(meetingInfo?.mendTs);
      getNewEndDate = end_date
        ? tsToOnlyDate(new Date(end_date).getTime())
        : null;
      getNewEndTime = end_date
        ? tsToTime(new Date(end_date).getTime())
        : null;
    } else {
      if (meetingInfo.isMeetingEnded && meetingInfo.meetingEndTime) {

        meetingEndDateVal = createDateFormate(meetingInfo.meetingEndTime, 2)
        const yy = meetingEndDateVal?.split('-')[2]
        meetingEndTimeVal = tsToTime(meetingInfo.meetingEndTime);
        if (yy === '1970') {
          let meetingEndTime = meetingInfo.m_end_time?.split(":");
          if (meetingEndTime) {
            meetingEndDateVal = meetingInfo.m_end_date
            meetingEndTimeVal = timeToIndiaTime(meetingEndTime[0], meetingEndTime[1])
          }
        }
      } else if (meetingInfo.m_end_time && meetingInfo.m_end_date) {
        let newEndTime = meetingInfo.m_end_date + ' ' + meetingInfo.m_end_time + ":00";
        let endTs = new Date(newEndTime).getTime();
        if (!endTs) {
          let meetingDate = meetingInfo.m_end_date?.split("-");
          endTs = meetingDate[2] + "-" + meetingDate[1] + "-" + meetingDate[0] + ' ' + meetingInfo.m_end_time + ":00";
          endTs = new Date(endTs).getTime();

        }

        getNewEndDate = endTs
          ? tsToOnlyDate(endTs)
          : null;

        getNewEndTime = endTs
          ? tsToTime(endTs)
          : null;
        let meetingEndTime = meetingInfo.m_end_time?.split(":");
        if (meetingEndTime) {
          meetingEndDateVal = meetingInfo.m_end_date
          meetingEndTimeVal = timeToIndiaTime(meetingEndTime[0], meetingEndTime[1])
        }
      }
      if (meetingInfo.hasOwnProperty('endUTC') && meetingInfo.endUTC !== null) {
        const { date, time } = getlocalDateTimeFromUTC(meetingInfo.endUTC)
        meetingEndDateVal = date;
        meetingEndTimeVal = time;
      }
    }




    if (!syncLoading || syncLoading !== meetingInfo.meetingID) {
      if ((meetingInfo?.meetingdetails[0]?.googleCalendarLink && typeof gAuthURL === "object") || (event?.id === meetingInfo.meetingID && event?.events)) {
        syncGoogle = <a rel="noopener noreferrer" className="btnMeet sideBarIcon welcomeMsg btn-sm mr-1 borderRadius " href={getURL()} target="_blank">Google Calendar</a>
      } else if (typeof gAuthURL === "object" || gAuthTokenVerified) {
        syncGoogle = (<button className="btnMeet sideBarIcon welcomeMsg btn-sm mr-1 borderRadius " onClick={() => synchGoogle()}>Add to Google</button>);
      } else {
        syncGoogle = (<button className="btnMeet sideBarIcon welcomeMsg btn-sm mr-1 borderRadius" onClick={() => openGoogleAuth()} >Auth to Google</button>); //-> onClick={props.authGoogle}
      }
    }
    else {
      syncGoogle = (<button className="btnMeet sideBarIcon welcomeMsg btn-sm mr-1"><Spinner size="sm" /></button>);
    }

  }

  if (isCancelled) {
    className = "cancel_meeting";
  }

  return (
    <li className={`meetCard ${className || ''}  p-2 sideBar  welcomeMsg mt-3 liListSection`}  >
      <Col lg={5} className="pl-0">
        <div className="timeSection timeSectionRoom" style={{ background: getColor() }}>
          {meetingInfo?.reCurringType === 'notFixed' ?
            <h4>Open  Room</h4>
            :
            <>
              <font className="d-flex justify-content-center date">
                {getDate} <span className="month"> {getMonth}</span> </font>
              <span className="day" > {getDay} , {getTime}</span>
            </>
          }
        </div>
        <div className="details meetTitle" >
          <div className="title welcomeMsg">{meetName}</div>
          {meetingInfo?.reCurringType !== 'notFixed' &&
            <div className="time">
              <Row>
                <div className='border-right text-muted px-2' >
                  <div> <div className='d-flex'><i className="far fa-calendar"></i> <div className='startDateDiv'>{getStartDate}</div> </div> <div className='mt-1 d-flex' ><i className="far fa-clock "></i> <div className='startDateDiv'>{getTime}</div> </div> </div>
                </div>
                {(getNewEndDate || meetingEndDateVal) && <div className='text-muted  px-2 '>
                  <div> <div className='d-flex'><i className="far fa-calendar"></i> <div className='startDateDiv'>{getNewEndDate ? getNewEndDate : meetingEndDateVal} </div> </div> <div className='mt-1 d-flex' ><i className="far fa-clock "></i> <div className='startDateDiv'> {getNewEndTime ? getNewEndTime : meetingEndTimeVal} </div> </div></div>
                </div>}
              </Row>
            </div>
          }
        </div>

      </Col>

      <Col lg={2} className='text-center' > <div className="idSection bg-transparent meetingID"><div className='text-muted' >Meeting ID</div> <div className='f_size_15'>{meetingInfo.meetingID}</div> </div> </Col>

      <Col className="schedule_actn_btn text-right " lg={5}>
        {!isCancelled ?
          <div className="schedule_actn_btn">
            {(isMeetEditable || meetingInfo?.reCurringType === 'notFixed') && <button className="btnMeet  text-white btn-sm mr-1 bg-primary font-weight-bold borderRadius" onClick={() => startMeet(meetingInfo.meetingID)}>Start Meeting</button>}
            {isMeetEditable && meetingInfo?.reCurringType !== 'notFixed' && syncGoogle}
            {/* {isMeetEditable && <button  className="btnMeet sideBarIcon welcomeMsg btn-sm mr-1 borderRadius" onClick={() => editMeet(meetingInfo.meetingID)}>Edit Detail</button>} */}
            {meetingInfo.hasOwnProperty('hasRecording') && meetingInfo.hasRecording === 1 && <button className="btnMeet sideBarIcon welcomeMsg btn-sm mt-1 mr-1 borderRadius" onClick={() => showRecode(meetingInfo.meetingID)}>View Recording</button>}
            <button className="btnMeet sideBarIcon welcomeMsg btn-sm mt-1 borderRadius" onClick={() => history.push(`/meeting_history/${meetingInfo.meetingID}?mid=${meetingInfo._id}`)}>Meeting Details</button>
          </div> :
          <div className="schedule_actn_btn">
            <button className="btnMeet bg-danger text-white btn-sm mt-1 borderRadius" onClick={() => meetingInfo?.meetingdetails[0]?.isRecurring ? deleteMeetingConfirmRecurring(meetingInfo?.meetingID, meetingInfo?._id) : deleteMeetingConfirm(meetingInfo.meetingID)}>Delete Meeting</button>
          </div>}
      </Col>
    </li>
  )
};

export default MeetListCard
