import React from 'react';
import { Modal, ModalBody } from 'reactstrap';

const yesBtnClick = (props) => {
	props.yesBtnAction()
	props.noBtnAction()
}

const ConfirmationPopup = (props) => {
	return (<Modal isOpen={true} className="wconfirmation_dilog_bx">
		<ModalBody>
			<div className="file_sharng_sct">
				<div className="cnfmrtn_msg">{props.message}</div>
				<div className="around	 w-100 text-center">
					<button onClick={props.noBtnAction} className="btnStyles  btn-secondary">{props.noBtnText}</button>
					<button className="btnStyles btn-danger mr-3"
						onClick={() => {
							yesBtnClick(props);
						}}
					>
						{props.yesBtnText}
					</button>
				</div>
			</div>
		</ModalBody>
	</Modal>)
};

export default ConfirmationPopup;