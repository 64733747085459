import React, { useEffect, useState } from 'react'
import isEmpty from 'is-empty';

import Modal from '../../layoutSection/Modal'
import Spinner from "../../components/UI/Spinner/Spinner";

import './style.scss'
import { useDispatch, useSelector } from 'react-redux';
import { reportActions } from '../../Redux/_actions';

const ViewRecording = (props) => {
  const { data, toggle, recordings, meetId } = props
  const dispatch = useDispatch()
  const { meetRecord, meetRecordErr } = useSelector(state => state.report)

  const [url, setUrl] = useState(null)

  useEffect(() => {
    dispatch(reportActions.getMeetingRecording({ meetingId: data?.meetingId, mSubID: data?.mSubID }))
  }, [])

  useEffect(() => {
    if (meetRecord) {
      if (meetRecord?.recordData?.recording?.length > 1) {
        let recordingData = meetRecord?.recordData?.recording;
        let index = 0;
        let size = recordingData[0].size;
        for (let i = 1; i < recordingData?.length; i++) {
          if (+size < +recordingData[i].size) {
            index = i;
          }
        }
        setUrl(meetRecord?.recordData?.recording[index].playback?.format?.url)
      } else {
        setUrl(meetRecord?.recordData?.recording?.playback?.format?.url)
      }
    } else if (meetRecordErr) {
      toggle()
    }
  }, [meetRecord, meetRecordErr])

  return (
    <div className='viewRecording'>
      <Modal show={!isEmpty(data)} toggle={() => toggle()} contentClassName="viewRecordingRow"
        dialogClassName="dialogSection"
        label={<h4 className='mb-0'> View Recording</h4>}
      >
        <div className='mt-2 iframeSection'>
          {
            url ? <iframe src={url} title='video' width="100%" height="100%" className='border-0'></iframe> : <div className="text-center">
              <Spinner />
            </div>
          }
        </div>
      </Modal>
    </div>
  )
}

export default ViewRecording
