import React, { useState, useEffect, useCallback } from "react";
import { Formik} from "formik";
import { Link, useHistory } from "react-router-dom";
import { Row, Col, Spinner, Button, Form } from "react-bootstrap";
// import GoogleLogin from "react-google-login";
import GoogleLogin from "../CustomHooks/GoogleLogin";
import ReactCodeInput from "react-code-input";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "is-empty";
import MD5 from "crypto-js/md5";
import CountUp from "react-countup";
import * as Yup from "yup";
import decode from "jwt-decode";

import AuthLayout from "../layout";
import { InputFiled } from "../../Common/authFile";
import { userActions, alertActions } from "../../Redux/_actions";
import {
  google_config,
  passwordToBreak,
  encDataKey,
} from "../../config/config";
import userconfigs from "../../helpers/closures";
import { userService } from "./../../Redux/_services";
import ForgetPassword from "./forgetPasswordModal";
import "./style.scss";

const crypto = require("crypto-js");

let userInfo = null;
try {
  userInfo = decode(userconfigs.getItem("jwt"));
} catch (err) {
  // console.log(err);
}

const initialValues = {
  userName: "",
  password: "",
  Otp: "",
  isPassword: true,
};

const vaidation = Yup.object().shape({
  userName: Yup.string().required("Please Enter the Email/Phone!"),
  isPassword: Yup.boolean(),
  password: Yup.string().when("isPassword", {
    is: true,
    then: Yup.string().required(" Please Enter the password!"),
    otherwise: Yup.string(),
  }),
  Otp: Yup.string()
    .when("isPassword", {
      is: false,
      then: Yup.string().required(" Please Enter the OTP!"),
      otherwise: Yup.string(),
    })
    .test(
      "length",
      "Please Enter OTP properly",
      (val) => isEmpty(val) || val?.length === 6
    ),
});

const Login = (props) => {
  const { jwt} =  props;
  const [csrfToken, setCsrfToken] = useState(null);
  const [isDisable, setIsDisable] = useState(false);
  const [timer, setTimer] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const state = useSelector((state) => state.authentication);
  const dispatch = useDispatch();
  const history = useHistory()

  useEffect(() => {
    userService.getCsrf().then((res) => {
      setCsrfToken(res.csrfToken);
    });
    if (
      typeof userconfigs.getItem("gld") !== "undefined" &&
      userconfigs.getItem("gld") !== "null" &&
      userconfigs.getItem("gld") !== null
    ) {
      if (userconfigs.getItem("gld") === "true") {
        setIsDisable(true);
      }
    }
  }, []);

  // useEffect(() => {
  //   if(state.loggedIn){
  //     history.push('/')
  //   }
  // }, [state]);

  const handler = (values) => {
    if (values.isPassword) {
      setShowModal(true);
    } else {
      setTimer(true);
      dispatch(userActions.getLoginOTP(values.userName));
    }
  };

  const submitHandler = (values) => {
    if (values.isPassword) {
      let md5Hash = MD5(values.password).toString();
      md5Hash = md5Hash + passwordToBreak + csrfToken;
      const key = crypto.enc.Utf8.parse(encDataKey);
      md5Hash = crypto.AES.encrypt(md5Hash, key,
        {
          mode: crypto.mode.ECB,
          padding: crypto.pad.Pkcs7,
        }).toString();
      // md5Hash = crypto.AES.encrypt(
      //   JSON.stringify(md5Hash),
      //   encDataKey
      // ).toString();
      dispatch(userActions.login(values.userName, md5Hash, csrfToken));
    } else {
      dispatch(userActions.loginWithOTP(values.userName, values.Otp));
    }
  };

  const changeLoginType = (setFieldValue, values) => {
    if (!isEmpty(values.userName)) {
      if (values.isPassword) {
        setTimer(true);
        userService.getLoginOTP(values.userName).then(response => {
          setFieldValue("isPassword", !values.isPassword);
          dispatch(alertActions.success(response.toString()));
        }).catch(err => {
          dispatch(alertActions.error(err.toString()));
        })
        //dispatch(userActions.getLoginOTP(values.userName));
      } else {
        setTimer(false);
        setFieldValue("isPassword", true);
      }
      
    }
    if(isEmpty(values.userName) && values.isPassword){
      dispatch(alertActions.error('Please enter username'));
    }
  };

  const responseErrorGoogle = (response) => {
    if (response.error !== "idpiframe_initialization_failed") {
      if (response.error !== "popup_closed_by_user") {
        dispatch(alertActions.error(response.error));
      }
      userconfigs.setItem("gld", false);
    } else {
      dispatch(
        alertActions.info(
          "Please enable 3rd party cookies in your browser to continue login with google."
        )
      );
      setIsDisable(true);
      userconfigs.setItem("gld", true);
    }
  };

  const GoogleLayout = useCallback(
    () => (
      <GoogleLogin
        clientId={google_config.gClientID}
        buttonText="Login with Google"
        className="btn-default g-btn-custom"
        onSuccess={(response) =>
          dispatch(userActions.authGoogle(response.profileObj))
        }
        onFailure={(response) => responseErrorGoogle(response)}
        cookiePolicy={"single_host_origin"}
        theme={"dark"}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDisable]
  );

  return (
    <AuthLayout
      label="Login"
      subLabel="Login with"
      googleLayout={<GoogleLayout />}
      image="/images/login.jpg"
      logoIcon={jwt?.originLogo}
      goBack={() => history.goBack()}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => submitHandler(values)}
        validationSchema={vaidation}
      >
        {({ values, setFieldValue, handleSubmit, errors, touched }) => (
          <div className="w-100">
            <InputFiled
              name="userName"
              type="text"
              label="UserName"
              placeholder=" Email / phone"
              className="form-control"
              labelClassName="text-dark"
            />
            {values.isPassword ? (
              <InputFiled
                name="password"
                type="password"
                label="Password"
                placeholder="Password"
                className="form-control"
                labelClassName="text-dark"
              />
            ) : (
              <Form.Group className="otpContainer">
                <div>
                  <Form.Label className="f_size_14 ml-3">OTP </Form.Label>
                </div>
                <ReactCodeInput
                  type="number"
                  onChange={(otp) => setFieldValue("Otp", otp)}
                  fields={6}
                  className="OtpInput"
                />
                {touched.Otp && errors.Otp && (
                  <div className="error text-danger f_size_10">
                    {errors.Otp}
                  </div>
                )}
              </Form.Group>
            )}
            <Row className="justify-content-between m-2">
              <button
                className="labelBtn"
                onClick={() => changeLoginType(setFieldValue, values)}
              >
                {values.isPassword ? "Login with OTP" : "Login with Password"}
              </button>
              <button
                className="labelBtn"
                onClick={() => handler(values)}
                disabled={!values.isPassword && timer}
              >
                {values.isPassword ? (
                  "Forget Password"
                ) : timer ? (
                  <>
                    Resend OTP in :
                    <CountUp
                      end={0}
                      start={30}
                      duration={30}
                      onEnd={() => setTimer(false)}
                    />
                    sec
                  </> 
                ) : (
                  "Resend OTP"
                )}
              </button>
            </Row>

            {state?.user?.verified === false && state?.user?.type === "email" && (
              <div className="mt-2 alert alert-danger text-center col-12 p-0 pt-1 pb-1">
                <small className="pl-2 pr-2 ">
                  For verification link click to{" "}
                  <button
                    type="button"
                    onClick={() =>
                      !isEmpty(values.userName) &&
                      dispatch(userActions.resendEmailLink(values.userName))
                    }
                    className="btn btn-sm btn-danger p-0 pl-1 pr-1"
                  >
                    Resend Again
                  </button>
                </small>
              </div>
            )}

            <Row className="align-items-center mt-3 mb-1 ">
              <Col lg={6}>
                <Button
                  variant="primary"
                  onClick={() => handleSubmit()}
                  className="px-4"
                >
                  {state?.loggingIn || state?.authLoading ? (
                    <>
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      Loading...
                    </>
                  ) : (
                    " Login"
                  )}
                </Button>
              </Col>
              <Col lg={6} className="mt-2">
                <div className="f_size_14">
                  <b className="text-muted"> Don't have an account? </b>
                  <Link to="/signup"> Sign up here </Link>
                </div>
              </Col>
            </Row>
            {showModal && (
              <ForgetPassword
                isShow={showModal}
                toggle={() => setShowModal(false)}
              />
            )}
          </div>
        )}
      </Formik>
    </AuthLayout>
  );
};

export default Login;
