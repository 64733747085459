import React, { Component } from 'react';
import queryString from 'query-string'
import { Form, Input, Button } from 'reactstrap';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { webinarActions, alertActions } from '../../Redux/_actions';
import { Spinner } from 'reactstrap';
import userconfigs from '../../helpers/closures';
import decode from 'jwt-decode';
import LoadSpinner from '../../components/UI/Spinner/Spinner';
import "./JoinWebinar.css"

import Images from '../../images';
class JoinWebinar extends Component {

	constructor(props) {
		super(props);
		this.state = {
			wid: undefined,
			token: undefined,
			loggedIn: false,
			name: undefined,
			password: undefined,
			isFormSubmit: false,
		}
	} 4

	componentDidMount = () => {
		const query = queryString.parse(this.props.location.search)
		let userInfo = decode(userconfigs.getItem("jwt"));

		if (typeof query.wid === "undefined" || typeof query.token === "undefined") {
			this.props.history.push('/')
		}
		else {
			this.setState({
				wid: query.wid,
				token: query.token
			})

			if (userInfo.loginStatus !== 0) {
				this.setState({
					loggedIn: true
				})
			}
			if (query.wid !== "" && query.token !== "") {
				console.log("[joinWebinar] @god @componentDidMount ");
				this.props.webinarBitsFun(query.wid, 'speaker', query.token)
			}
		}
	}

	successHandler = (e) => {
		e.preventDefault();
		const { wid, token, name, password } = this.state;
		this.setState({
			isFormSubmit: true
		});
		if (wid && name && password) {
			console.log("[joinWebinar] @god @successHandler ");
			this.props.addToWebinarRoomFun(wid, token, "speaker", name, password)
			//console.log('submittteeeeeddddd',wid,name)
		}
	}

	handleChange = (e) => {
		const { name, value } = e.target;

		this.setState({
			[name]: value
		});

	}

	componentDidUpdate(prevProps, prevState) {
		//const values = queryString.parse(this.props.location.search)
		//let userInfo = decode(userconfigs.getItem("jwt"));
		const { wid, token, name, password, loggedIn } = this.state;
		if (prevProps.webinarBits !== this.props.webinarBits) {
			if (this.props.webinarBits.isMeetingEnded === 1) {
				this.props.history.push({
					pathname: '/webinar_info',
					state: {
						wid: wid,
						wname: this.props.webinarBits.name,
						heading: 'Webinar has already been ended.',
						msg: 'The webinar you are trying to join has already ended, Please check your webinar link.'
					}
				})
			}
			if (this.props.webinarBits.authentic === true) {
				if (loggedIn !== 1) {
					this.props.history.push({
						pathname: '/webinar_info',
						state: {
							wid: wid,
							wname: this.props.webinarBits.name,
							heading: 'This webinar is for signed in user only.',
							msg: 'The webinar you are trying to join is required your sign in, please sign in first then try again.'
						}
					})
				}
			}


			let joinName = name;
			if (typeof this.props.webinarBits.joinName !== undefined) {
				joinName = this.props.webinarBits.joinName;
				this.setState({
					name: this.props.webinarBits.joinName
				})

				if (this.props.webinarBits.webinarPwdBit === false) {
					//this.instantJoin(this.state.wid, this.props.webinarBits.speakerName);
					this.props.addToWebinarRoomFun(wid, token, "speaker", joinName, password)
				}
			}
		}
		// console.log("[JOinWebinar] @componentDidUpdate token :", this.props.webinarRoomResp);
		if (prevProps.addedInWebinar !== this.props.addedInWebinar) {
			//userconfigs.setItem('webiName',this.state.name);
			this.props.joinWebinarFun(wid, name, "speaker", this.props.webinarRoomResp);
			//this.props.history.push('/webinar_room?wid='+wid+'&token='+this.props.webinarRoomResp+'&speaker=true' )
			//this.props.history.push('/webinar_waiting?wid='+wid);
		}
	}

	render() {
		const { bitsLoding, webinarBits, addingInWebinar } = this.props;
		const { mid, password, isFormSubmit } = this.state;
		return (
			<div className="sarvwave_thme_bg">
				<div className="middle_box_area">
					<div className="wave_logo_part">
						<Link to="/">
							<img src={Images.logoImgBlackTheme} alt="logo" />
						</Link>
					</div>
					{bitsLoding ?
						<div className="attendy_name_bx">
							<div className="wxr_title">Getting information...
								<span><LoadSpinner /></span>
							</div>
						</div>
						:
						(webinarBits.webinarPwdBit) ?
							<div className="attendy_name_bx">
								<div className="wxr_title">Join Webinar
									<span>Please enter details to join the webinar</span>
								</div>
								<div className="wave_display_form">
									<Form onSubmit={this.successHandler.bind(this)}>
										<input style={{ display: "none" }} type="text" name="fakeusernameremembered" />
										<input style={{ display: "none" }} type="password" name="fakepasswordremembered" />
										<div className="form-group">
											<Input className="form-control" disabled={true} autoComplete="new-name" name="name" value={webinarBits.speakerName} placeholder="Your Name " />
										</div>

										<div className={'form-group' + (isFormSubmit && !password ? ' has-error' : '')}>
											<Input className="form-control" autoComplete="new-password" type="password" name="password" value={password} onChange={this.handleChange} placeholder="Webinar Password" />
											{
												isFormSubmit && !password &&
												<div className="help-block j_error_msg">Please enter the password of this webinar !</div>
											}

										</div>
										{addingInWebinar ?
											<div className="form-group mb-2 mt-4 pt-2 text-center">
												<Button type="button" className="meeting_enter_btn" color="success" >
													<Spinner size="sm" color="light" /> Joining Webinar...
												</Button>
											</div>
											:
											<div className="form-group mb-2 mt-4 pt-2 text-center">
												<Button type="submit" className="meeting_enter_btn" color="success" >
													<i className="fas fa-sign-in-alt"></i> Enter in Webinar
												</Button>
											</div>
										}

									</Form>
								</div>
							</div>
							:
							<div className="attendy_name_bx">
								<div className="wxr_title">Joining Webinar...
									<span><LoadSpinner text={""} /></span>
								</div>
							</div>
					}
				</div>
			</div>
		)
	}
}

function mapState(state) {
	const { bitsLoding, webinarBits, addingInWebinar, addedInWebinar, webinarRoomResp } = state.webinar
	return {
		bitsLoding,
		webinarBits,
		addingInWebinar,
		addedInWebinar,
		webinarRoomResp,
	};
}
const actionCreators = {
	webinarBitsFun: webinarActions.webinarBits,
	addToWebinarRoomFun: webinarActions.addToWebinarRoom,
	joinWebinarFun: webinarActions.joinWebinar,
	alertError: alertActions.error

};

export default withRouter(connect(mapState, actionCreators)(JoinWebinar));