import { myconfig, siteTokens } from '../config/config';
import userconfigs from './closures';
import axios from 'axios';
import Fingerprint from 'fingerprintjs2';
import moment from 'moment-timezone';
import { store } from 'react-notifications-component'
// import { ExportToCsv } from "export-to-csv";
import exportFromJSON from 'export-from-json';

export function generateToken() {
  if (typeof userconfigs.getItem('fp') === "undefined" || userconfigs.getItem("fp") === null) {
    var options = {}
    Fingerprint.getV18(options, function (result, components) {
      (async () => {
        userconfigs.setItem('fp', result)
        getToken(result);
      })();
    })
  }
  else {
    getToken(userconfigs.getItem('fp'));
  }

  function getToken(fp) {
    var postData = {
      fingerprint: fp,
    };

    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    axios.post(myconfig.apiURL + 'create_token', postData, axiosConfig)
      .then((res) => {
        if (typeof res.data.result.token != "undefined") {
          //decode token
          //csrf // set in cookis
          userconfigs.setItem("jwt", res.data.result.token)
          userconfigs.setItem('auth', res.data.result.auth);
          userconfigs.setItem('account', true);
        }
      })
      .catch((error) => {
        if (error === "Error: Network Error") {
          //window.location.replace(myconfig.front_url + "network_error");
        }
      })
  }
}

export function checkServerType() {

  const currentHostName = window.location.hostname;

  if (currentHostName === "localhost" || currentHostName === "127.0.0.1") {
    return "localhost";
  } else if (currentHostName === "wave.sarv.com" || currentHostName === "103.238.223.114") {
    return "dev";
  } else {
    return "live";
  }
}


export function getServerURL() {
  const currentProtocol = window.location.protocol;
  const currentHostName = window.location.hostname;
  const currentPort = window.location.port;
  //const siteTokens = siteTokens;
  var paths = {};

  siteTokens.map(site => {
    if (currentHostName === site.url) {
      paths.apiURL = site.apiURL;
      paths.env = site.env;
      // paths.streamURL = site.streamURL
    }
  })

  // if(currentHostName === "wave.sarv.com"){
  //   paths.apiURL = "https://api-wave.sarv.com/";
  //   paths.streamURL = "wss://kurento-wave.sarv.com:8443/lbVideo";
  // }
  // else if(currentHostName === "meity.sarv.com"){
  //   paths.apiURL = "https://a_master-meity.sarv.com/";
  //   paths.streamURL = "wss://kurento-wave.sarv.com:8443/lbVideo";
  // }
  // else{
  //   paths.apiURL = "https://a.dev.wave.sarv.com/" 
  //   paths.streamURL = "wss://kurento-wave.sarv.com:2083/lbVideo"
  // }

  if (currentPort) {
    paths.frontURL = currentProtocol + "//" + currentHostName + ":" + currentPort
  } else {
    paths.frontURL = currentProtocol + "//" + currentHostName
  }

  return paths

}

export function Timer(fn, t) {
  var timerObj = setInterval(fn, t);

  this.stop = function () {
    if (timerObj) {
      clearInterval(timerObj);
      timerObj = null;
    }
    return this;
  }

  // start timer using current settings (if it's not already running)
  this.start = function () {
    if (!timerObj) {
      this.stop();
      timerObj = setInterval(fn, t);
    }
    return this;
  }

  // start with new or original interval, stop current interval
  this.reset = function (newT = t) {
    t = newT;
    return this.stop().start();
  }
}

export function tsToDate(time) {
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  var d;
  if (time.toString().length > 10) {
    d = new Date(parseInt(time)).toLocaleString("en-US", { timeZone: "Asia/Kolkata" });
    d = new Date(d);
  }
  else {
    d = new Date(parseInt(time) * 1000).toLocaleString("en-US", { timeZone: "Asia/Kolkata" });
    d = new Date(d);
  }
  var hours = d.getHours();
  var minutes = d.getMinutes();
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = ("0" + minutes).slice(-2);
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return d.getDate() + ' ' + monthNames[(d.getMonth())] + " " + d.getYear().toString().substr(-2) + ", " + strTime
}

/**
 * 
 * @param {TimeStamp as numeric valye} timeStamp 
 * IT will return date and time ormate example 12 feb 21, 12:30 PM 
 */

export function getTimeStampToDateTime(timeStamp) {
  const tStamp = timeStamp.toString().length > 10 ? timeStamp : timeStamp * 1000;
  return moment(tStamp).format('DD MMM YY, HH:mm A');
}

export function tsToTime(time) {
  //const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  var d;
  if (time.toString().length > 10) {
    d = new Date(parseInt(time)).toLocaleString("en-US", { timeZone: "Asia/Kolkata" });
    d = new Date(d);
  }
  else {
    d = new Date(parseInt(time) * 1000).toLocaleString("en-US", { timeZone: "Asia/Kolkata" });
    d = new Date(d);
  }
  var hours = d.getHours();
  var minutes = d.getMinutes();
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = ("0" + minutes).slice(-2);
  var strTime = hours + ':' + minutes + ' ' + ampm;
  //return d.getDate() + ' ' + monthNames[(d.getMonth())] + " " + d.getYear().toString().substr(-2) + ", " + strTime
  return strTime;
}

/**
 * 
 * @param {*} timeStamp  It should be numeric type
 * This function will return time in 12:30 PM formate
 */

export function getTsToTime(timeStamp) {
  const tStamp = timeStamp.toString().length > 10 ? timeStamp : timeStamp * 1000;
  return moment(tStamp).format('HH:mm A');
}


export function timeToIndiaTime(hours, minutes) {
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = ("0" + minutes).slice(-2);
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}

export function tsToOnlyDate(time) {
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  var d;
  if (time.toString().length > 10) {
    d = new Date(parseInt(time)).toLocaleString("en-US", { timeZone: "Asia/Kolkata" });
    d = new Date(d);
  }
  else {
    d = new Date(parseInt(time) * 1000).toLocaleString("en-US", { timeZone: "Asia/Kolkata" });
    d = new Date(d);
  }
  // var hours = d.getHours();
  // var minutes = d.getMinutes();
  // var ampm = hours >= 12 ? 'pm' : 'am';
  // hours = hours % 12;
  // hours = hours ? hours : 12; // the hour '0' should be '12'
  // minutes = minutes < 10 ? '0' + minutes : minutes;
  // var strTime = hours + ':' + minutes + ' ' + ampm;
  return d.getDate() + ' ' + monthNames[(d.getMonth())] + " " + d.getYear().toString().substr(-2)
  //return strTime;  
}

/**This function is accept timestamp as param and return date formate example 12 feb 21 */

export function getDateToTimestamp(timeStamp) {
  const tStamp = timeStamp.toString().length > 10 ? timeStamp : timeStamp * 1000;
  return moment(tStamp).format('DD MMM YY');

}


export function msToTime(duration) {
  var seconds = parseInt((duration / 1000) % 60)
    , minutes = parseInt((duration / (1000 * 60)) % 60)
    , hours = parseInt((duration / (1000 * 60 * 60)) % 24);

  hours = (hours < 10) ? "0" + hours : hours;
  minutes = (minutes < 10) ? "0" + minutes : minutes;
  seconds = (seconds < 10) ? "0" + seconds : seconds;

  return hours + ":" + minutes + ":" + seconds;
}

/**
 * 
 * @param {Numeric timestamp} timeStamp 
 * It will return time in UTC
 */

export function getMsToTime(timeStamp) {
  return moment.utc(timeStamp).format('HH:mm:ss');
}


export function durationTime(startTime, endTime) {

  if (!startTime || !endTime) {
    return "00:00"
  }

  var s;
  if (startTime.toString().length > 10) {
    s = new Date(parseInt(startTime)).toLocaleString("en-US", { timeZone: "Asia/Kolkata" });
    s = new Date(s);
  }
  else {
    s = new Date(parseInt(startTime) * 1000).toLocaleString("en-US", { timeZone: "Asia/Kolkata" });
    s = new Date(s);
  }

  var e
  if (endTime.toString().length > 10) {
    e = new Date(parseInt(endTime)).toLocaleString("en-US", { timeZone: "Asia/Kolkata" });
    e = new Date(e);
  }
  else {
    e = new Date(parseInt(endTime) * 1000).toLocaleString("en-US", { timeZone: "Asia/Kolkata" });
    e = new Date(e);
  }
  let durationTime = null;
  if (e > s) {

    var sdate = new Date(s);
    var scurrentTime = sdate.getTime();

    var seconds = Math.floor((e - (scurrentTime)) / 1000);
    var minutes = Math.floor(seconds / 60);
    var hours = Math.floor(minutes / 60);
    var days = Math.floor(hours / 24);

    hours = hours - (days * 24);
    minutes = minutes - (days * 24 * 60) - (hours * 60);
    seconds = seconds - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60);

    durationTime = ("0" + hours).slice(-2) + ":" + ("0" + minutes).slice(-2) + ":" + ("0" + seconds).slice(-2);
  } else {
    durationTime = "00:00";
  }
  return durationTime;
}

/** This function will take numeric start/End Time as parameter and return difference between them*/

export function TimeDuration(sTime, eTime) {
  const startTime = sTime.toString().length > 10 ? sTime : sTime * 1000;
  const endTime = eTime.toString().length > 10 ? eTime : eTime * 1000;
  const startTimeObj = moment(startTime);
  const endTimeObj = moment(endTime);
  const duration = moment.duration(endTimeObj.diff(startTimeObj));
  const hours = ("0" + parseInt(duration.asHours())).slice(-2);
  const minutes = ("0" + (Math.floor(duration.asMinutes()) - parseInt(duration.asHours()) * 60)).slice(-2);
  const seconds = ("0" + (Math.floor(duration.asSeconds()) - parseInt(duration.asHours()) * 60 * 60)).slice(-2);
  let durationTime = `${hours}: ${minutes}: ${seconds}`;
  if (startTime > endTime) {
    durationTime = "00:00";
  }

  return durationTime;
}

export function truncate(str, no_words) {
  let wordLength = str.split(" ");
  let wordArr = str.split(" ").splice(0, no_words);
  if (wordLength.length > no_words) {
    wordArr = wordArr.join(" ");
    wordArr = wordArr + "...";
  } else {
    wordArr = wordArr.join(" ")
  }
  return wordArr;
}


export function shortContent(str, no_char) {
  let charLength = str.length;
  let shortString = str.slice(0, no_char);
  if (charLength > parseInt(no_char)) {
    return shortString = shortString + "...";
  } else {
    return shortString;
  }
}


export function nameDisplay(str) {
  let wordArr = str.split(" ");
  let secondChar = null;
  if (wordArr.length === 1) {
    let firstChar = str.charAt(0).toUpperCase();
    if (str.length > 1) {
      secondChar = str.charAt(1).toUpperCase();
    }
    return firstChar + secondChar;
  } else {
    let firstChar = str.charAt(0).toUpperCase();
    secondChar = wordArr[wordArr.length - 1].charAt(0).toUpperCase();
    return firstChar + secondChar;
  }
}


export function capitalize(str) {
  str = str.trim();
  str = str.split(" ");

  for (var i = 0, x = str.length; i < x; i++) {
    if (str[i][0] !== undefined) {
      str[i] = str[i][0].toUpperCase() + str[i].substr(1).toLowerCase();
    }
  }

  return str.join(" ");
}

export function getTimeGap(lastTime) {
  let currentTime = new Date().getTime();

  let remaningTime = parseInt(currentTime) - parseInt(lastTime);
  let getMinutes = 0;
  let getHours = 0;
  if (remaningTime > 0) {

    let convertTime = Math.floor(remaningTime / 1000);

    if (convertTime < 60) {

      // return convertTime+" seconds ago";
      return "Now";

    } else if (convertTime < 3600) {

      getMinutes = Math.floor(convertTime / 60);

      return getMinutes + " Minutes Ago";
    } else {

      getMinutes = Math.floor(convertTime / 60);
      getHours = Math.floor(getMinutes / 60);

      return getHours + " Hours Ago";
    }

  } else {
    return "Now";
  }

}
/**
 * 
 * @param {TimeStamp as numeric value} lastTime 
 * This function will return time of perticular timestamp from now
 */

export function getTimeGapFromNow(lastTime) {
  const currentTime = moment(new Date().getTime());
  const lTime = moment(lastTime);
  const duration = moment.duration(currentTime.diff(lTime));
  let timeGap = "Now";
  const GapInSeconds = Math.floor(duration.asSeconds());
  if (GapInSeconds >= 60) {
    timeGap = GapInSeconds >= 3600 ? `${Math.floor(GapInSeconds / 3600)} Hours Ago` : `${Math.floor(GapInSeconds / 60)} Minutes Ago`;
  }
  return timeGap;

}

export function checkDateTimeFromNow(meetingTs) {
  return new Date().getTime() < meetingTs
}

export function getlocalDateTimeFromUTC(timeStamp) {
  const meetingTs = timeStamp.toString().length > 10 ? timeStamp : timeStamp * 1000;
  let userTimeZone = moment.tz.guess();
  if (localStorage.getItem('timezone') !== null && localStorage.getItem('timezone') !== undefined) {
    userTimeZone = (JSON.parse(localStorage.getItem('timezone'))).value;
  }
  const date = moment.tz(moment(meetingTs), userTimeZone).format('DD-MM-YYYY')
  const time = moment.tz(moment(meetingTs), userTimeZone).format('hh:mm A');
  return {
    date,
    time
  }
}

export function getCurrentTimeZone() {
  return moment.tz.guess();
}

export function getAllTimeZone() {
  return moment.tz.names();
}


/**
 * 
 * @param {String} Type of notification
 * @param {String} title of notification 
 * @param {String} message on notification 
 */
export function alertMessage(type, title, message) {
  return store.addNotification({
    title: title,
    message: message,
    type: type,
    container: 'top-right',
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
      duration: 5000
    }
  })
}

/**
 * This function will retuen module accessibity on the basis of role
 * @param {String} role 
 */

export function getModuleAccessOnbasisOfRole(role) {
  switch (true) {

    case role === 'admin':
      return [{
        name: 'Deck',
        permission: true
      },
      {
        name: 'Talk',
        permission: true
      },
      {
        name: 'Drive',
        permission: true
      },
      {
        name: 'Calender',
        permission: true
      },
      {
        name: 'CRM',
        permission: true
      },
      {
        name: 'Task',
        permission: true
      },
      {
        name: 'Setting',
        permission: true
      },
      {
        name: 'Support',
        permission: true
      }];
    case role === 'manager':
      return [{
        name: 'Deck',
        permission: true
      },
      {
        name: 'Talk',
        permission: true
      },
      {
        name: 'Drive',
        permission: true
      },
      {
        name: 'Calender',
        permission: true
      },
      {
        name: 'CRM',
        permission: true
      },
      {
        name: 'Task',
        permission: false
      },
      {
        name: 'Setting',
        permission: false
      },
      {
        name: 'Support',
        permission: true
      }];
    case role === 'lead':
      return [{
        name: 'Deck',
        permission: true
      },
      {
        name: 'Talk',
        permission: true
      },
      {
        name: 'Drive',
        permission: true
      },
      {
        name: 'Calender',
        permission: true
      },
      {
        name: 'CRM',
        permission: false
      },
      {
        name: 'Task',
        permission: false
      },
      {
        name: 'Setting',
        permission: false
      },
      {
        name: 'Support',
        permission: true
      }];
    case role === 'executive':
      return [{
        name: 'Deck',
        permission: true
      },
      {
        name: 'Talk',
        permission: false
      },
      {
        name: 'Drive',
        permission: true
      },
      {
        name: 'Calender',
        permission: true
      },
      {
        name: 'CRM',
        permission: false
      },
      {
        name: 'Task',
        permission: false
      },
      {
        name: 'Setting',
        permission: false
      },
      {
        name: 'Support',
        permission: true
      }];
    default:
      return [{
        name: 'Deck',
        permission: false
      },
      {
        name: 'Talk',
        permission: false
      },
      {
        name: 'Drive',
        permission: false
      },
      {
        name: 'Calender',
        permission: false
      },
      {
        name: 'CRM',
        permission: false
      },
      {
        name: 'Task',
        permission: false
      },
      {
        name: 'Setting',
        permission: false
      },
      {
        name: 'Support',
        permission: false
      }];
  }
}

export function geteOfficeModules() {
  return [{
    name: 'Deck',
    permission: true
  },
  {
    name: 'Talk',
    permission: true
  },
  {
    name: 'Drive',
    permission: true
  },
  {
    name: 'Calender',
    permission: true
  },
  {
    name: 'CRM',
    permission: true
  },
  {
    name: 'Task',
    permission: true
  },
  {
    name: 'Setting',
    permission: true
  },
  {
    name: 'Support',
    permission: true
  }]
}


export async function downloadRegCSV(data) {
  if (data?.count > 0) {
    const file = [];
    data.data.map((item) => {
      file.push(item.customData)
    })

    // const options = {
    //   fieldSeparator: ",",
    //   quoteStrings: '"',
    //   decimalSeparator: ".",
    //   showLabels: true,
    //   useTextFile: false,
    //   useBom: true,
    //   useKeysAsHeaders: true,
    //   // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    // };
    // const csvExporter = new ExportToCsv(options);
    // csvExporter.generateCsv(file);
    let fileName = "generated";
    const exportType = 'csv';
    await exportFromJSON({ file, fileName, exportType });
  }
}