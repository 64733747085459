import React, { Component } from "react";
import { Form, Input, Button } from "reactstrap";
import queryString from "query-string";
import { Link, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  joinActions,
  conferenceActions,
  alertActions,
  waitingRoomActions,
} from "../../../Redux/_actions";
import { Spinner } from "reactstrap";
import userconfigs from "../../../helpers/closures";
import decode from "jwt-decode";
import LoadSpinner from "../../../components/UI/Spinner/Spinner";
import ExtModal from "./extModal";
import "./JoinMeeting.css";
import images from "../../../images";
import jwt_decode from "jwt-decode";

var jwt = {}

try {
  jwt = jwt_decode(userconfigs.getItem("jwt"))
} catch (e) {

}


class JoinMeetingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      stored_name: "",
      mid: undefined,
      password: undefined,
      moderatorToken: undefined,
      urlPwd: "",
      showPwd: false,
      dntAskPwd: false,
      confName: "",
      isFormSubmit: false,
      waitingRoom: undefined,
      isWebinar: 0,
      extModalShow: false,
      isChromeBrowser: false,
    };
  }

  componentDidMount = () => {
    this.joinMeetStart();
    // const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    // const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    // //console.log('iiiiiiiiiiissssss',isChrome)
    // if(!isMobile){
    // 	if(isChrome){
    // 		let isExtInstalled = true;
    // 		const url = "chrome-extension://kgbcpooeiicongajmmkhhfelhjpnkccj/wasm/sarv-processor.js";
    // 		var http = new XMLHttpRequest();
    // 		http.open('HEAD', url, false);
    //         try{
    // 	        http.send();
    // 	    }
    // 	    catch(e){
    // 	    	isExtInstalled = false
    // 	    }
    //         if (http.status === 200) {
    //             isExtInstalled = true;
    //         }

    // 		// window.chrome.runtime.sendMessage("kgbcpooeiicongajmmkhhfelhjpnkccj", 'version', response => {
    // 		//        if (!response) {
    // 		//          console.log('No extension');
    // 	 	//          return;
    // 		//        }
    // 		//        console.log('Extension version: ', response.version);
    // 		//    })

    // 		 if(!isExtInstalled){
    // 			this.setState({
    // 				extModalShow:true,
    // 				isChromeBrowser:true
    // 			})
    // 		}
    // 		else{
    // 			this.joinMeetStart();
    // 		}
    // 	}
    // 	else{
    // 		this.setState({
    // 			extModalShow:true,
    // 			isChromeBrowser:false
    // 		})
    // 	}
    // }
    // else{
    // 	this.joinMeetStart();
    // }
  };

  joinMeetStart = () => {
    const values = queryString.parse(this.props.location.search);

    if (typeof values.mid === "undefined") {
      this.props.history.push("/");
    } else {
      this.setState({
        mid: values.mid,
        moderatorToken: values.moderatorToken || undefined,
      });

      if (typeof values.token !== "undefined" && values.token !== "") {
        this.setState({
          urlPwd: values.token + this.props.location.hash,
          password: values.token + this.props.location.hash,
        });
      }

      let userInfo = decode(userconfigs.getItem("jwt"));

      if (userInfo.loginStatus !== 0) {
        this.setState({
          name: userInfo.name,
        });
        //this.successHandler(values.mid,userInfo.name);
      } else {
        if (
          typeof userconfigs.getItem("u_" + values.mid) !== "undefined" &&
          userconfigs.getItem("u_" + values.mid) !== null &&
          userconfigs.getItem("u_" + values.mid) !== "null"
        ) {
          this.setState({
            stored_name: userconfigs.getItem("u_" + values.mid),
          });
        } else if (typeof values.name !== "undefined" && values.name !== "") {
          this.setState({
            stored_name: values.name,
          });
        }
      }
      this.props.meetingInfo(values.mid);
    }
  };

  extHandle = () => {
    this.setState({
      extModalShow: false,
    });
    this.joinMeetStart();
  };

  successHandler = (
    isWebinar,
    showPwd,
    waitingRoomBit,
    mid,
    name,
    password,
    moderatorToken = null
  ) => {
    // console.log(isWebinar);
    // console.log(showPwd);
    // console.log(waitingRoomBit);
    // console.log(mid);
    // console.log(this.state.name);
    // console.log(this.state.password);
    if (typeof this.state.waitingRoom !== "undefined") {
      waitingRoomBit = this.state.waitingRoom;
    }

    if (typeof showPwd === "undefined") {
      showPwd = this.state.showPwd;
    }

    if (typeof password === "undefined") {
      password = this.state.password;
    }
    //return false;
    if (
      typeof this.state.name !== "undefined" &&
      typeof this.state.mid !== "undefined"
    ) {
      mid = this.state.mid;
      name = this.state.name;
      // password = this.state.password;
    }

    if (this.state.moderatorToken) {
      moderatorToken = this.state.moderatorToken;
    }

    // alert(moderatorToken, "test");

    if (name !== "") {
      userconfigs.setItem("u_" + mid, name);
    }
    if (password !== "") {
      userconfigs.setItem("u_p" + mid, password);
    }

    this.setState({
      isFormSubmit: true,
    });

    if (showPwd) {
      if (name === "") {
        name = this.state.stored_name;
      }
      if (mid && name && password) {
        if (waitingRoomBit) {
          this.props.addUserToWaitingRoom(
            name,
            mid,
            password,
            moderatorToken ? moderatorToken : null,
            isWebinar ? isWebinar : null
          );
        } else {
          this.props.joinConference(
            isWebinar,
            name,
            mid,
            password,
            moderatorToken ? moderatorToken : null
          );
        }
      }
    } else {
      if (name === "") {
        name = this.state.stored_name;
      }
      if (mid && name) {
        if (waitingRoomBit) {
          this.props.addUserToWaitingRoom(
            name,
            mid,
            password ? password : null,
            moderatorToken ? moderatorToken : null,
            isWebinar ? isWebinar : null
          );
        } else {
          this.props.joinConference(
            isWebinar,
            name,
            mid,
            password ? password : null,
            moderatorToken ? moderatorToken : null
          );
        }
      }
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "name") {
      var specials = /^[a-zA-Z0-9\s]*$/;
      if (specials.test(value)) {
        this.setState({
          [name]: value,
        });
      }
    } else {
      this.setState({
        [name]: value,
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const values = queryString.parse(this.props.location.search);
    // alert(JSON.stringify(values));

    let userInfo = decode(userconfigs.getItem("jwt"));

    if (
      this.props.error ||
      (typeof this.props.joinError !== "undefined" && this.props.joinError) ||
      (typeof this.props.addUserError !== "undefined" &&
        this.props.addUserError)
    ) {
      if (
        this.props.conferenceBits?.userPwdBit === 0 ||
        !this.props.conferenceBits?.userPwdBit
      ) {
        if (userInfo.loginStatus === 1) {
          this.props.history.push("/");
        } else {
          this.props.history.push("/");
        }
      }
      return true;
    }

    if (prevProps.conferenceBits !== this.props.conferenceBits) {
      if (
        this.props.conferenceBits.only_registered &&
        userInfo.loginStatus === 0
      ) {
        this.props.history.push({
          pathname: "/guide",
          state: {
            mid: this.props.conferenceBits.meetingID,
            moderatorToken: this.props.conferenceBits.moderatorToken,
            mname: this.props.conferenceBits.name,
          },
        });
      }

      let waitingRoomBit = 0;
      if (
        typeof this.props.conferenceBits.waitingRoom !== "undefined" &&
        this.props.conferenceBits.waitingRoom
      ) {
        this.setState({
          waitingRoom: this.props.conferenceBits.waitingRoom,
        });
        waitingRoomBit = this.props.conferenceBits.waitingRoom;
      }
      // if(this.props.conferenceBits.isModerator){
      //     	//this.successHandler(values.mid,userInfo.name);
      //     	this.props.alertError("Please start the conference first !")
      //     	this.props.history.push("/start_meeting?mid="+values.mid)
      //     	return true;
      //   	}

      this.setState({
        showPwd: this.props?.conferenceBits?.userPwdBit,
        dntAskPwd: this.props?.conferenceBits?.isModerator,
        confName: this.props.conferenceBits.name,
        isWebinar: this.props.conferenceBits.isWebinar || 0,
      });

      if (
        !this.props?.conferenceBits?.userPwdBit ||
        this.props?.conferenceBits.isModerator
      ) {
        if (
          (typeof userInfo.name !== "undefined" ||
            this.state.stored_name !== "") &&
          this.props.conferenceBits.userPwdBit === 0
        ) {
          this.successHandler(
            this.props.conferenceBits.isWebinar,
            this.props.conferenceBits.userPwdBit,
            waitingRoomBit,
            values.mid,
            userInfo.name,
            this.state.moderatorToken
          );
        } else {
          if (
            this.props.conferenceBits.isModerator &&
            this.props.conferenceBits.userPwdBit === 1
          ) {
            //anil

            // ---params

            // isWebinar,
            //   showPwd,
            //   waitingRoomBit,
            //   mid,
            //   name,
            //   password,
            //   (moderatorToken = null);
            ///---end params
            this.successHandler(
              this.props.conferenceBits.isWebinar,
              0,
              waitingRoomBit,
              values.mid,
              userInfo.name,
              null,
              this.state.moderatorToken
            );
          }
        }
      }
      if (
        (typeof userInfo.name !== "undefined" ||
          this.state.stored_name !== "") &&
        this.props.conferenceBits.userPwdBit === 1 &&
        this.state.password !== undefined &&
        values.token !== ""
      ) {
        this.successHandler(
          this.props.conferenceBits.isWebinar,
          this.props.conferenceBits.userPwdBit,
          waitingRoomBit,
          values.mid,
          userInfo.name,
          values.token + this.props.location.hash,
          this.state.moderatorToken
        );
      }
    }
  }

  render() {
    const { loadingStatus, addUserRes, url, confLoading } = this.props;
    const {
      showPwd,
      dntAskPwd,
      name,
      urlPwd,
      mid,
      stored_name,
      moderatorToken,
    } = this.state;
    let userInfo = decode(userconfigs.getItem("jwt"));

    if (typeof addUserRes !== "undefined" && addUserRes !== "") {
      if (moderatorToken) {
        return <Redirect to={"/waitingRoom/" + mid + "/" + moderatorToken} />;
      } else {
        return <Redirect to={"/waitingRoom/" + mid} />;
      }
    }

    if (typeof url !== "undefined") {
      if (typeof url.moderatorJoined !== "undefined") {
        //this.setState.moderatorToken
        if (moderatorToken) {
          return <Redirect to={"/waitingRoom/" + mid + "/" + moderatorToken} />;
        } else {
          return <Redirect to={"/waitingRoom/" + mid} />;
        }
      }
    }

    return (
      <div className="sarvwave_thme_bg">
        <div className="middle_box_area ">
          {/* <div className="wave_logo_part">
            <Link to="/"></Link>
          </div> */}
          <div className="text-center">
            <img
              src={this.props?.conferenceBits?.logo || images.logoImgWhiteTheme}
              alt="logo"
              width={150}
            />
          </div>
          {(userInfo.loginStatus === 0 && stored_name === "" && !confLoading) ||
            (showPwd === 1 &&
              dntAskPwd === 0 &&
              urlPwd === "" &&
              !confLoading) ? (
            <div className="attendy_name_bx">
              <div className="wxr_title">
                Join Meeting
                <span>Please enter details to join the conference</span>
              </div>
              <div className="wave_display_form">
                <Form>
                  <input
                    style={{ display: "none" }}
                    type="text"
                    name="fakeusernameremembered"
                  />
                  <input
                    style={{ display: "none" }}
                    type="password"
                    name="fakepasswordremembered"
                  />
                  {userInfo.loginStatus === 1 || stored_name !== "" ? (
                    <div className="form-group">
                      <Input
                        className="form-control"
                        autoComplete="new-name"
                        name="name"
                        value={this.state.name || stored_name}
                        placeholder="Your Name "
                      />
                    </div>
                  ) : (
                    <div
                      className={
                        "form-group" +
                        (this.state.isFormSubmit && !this.state.name
                          ? " has-error"
                          : "")
                      }
                    >
                      <Input
                        className="form-control"
                        autoComplete="new-name"
                        name="name"
                        value={this.state.name}
                        onChange={this.handleChange}
                        placeholder="Your Name "
                      />
                      {this.state.isFormSubmit && !name && (
                        <div className="help-block j_error_msg">
                          Please enter your name !
                        </div>
                      )}
                    </div>
                  )}
                  {showPwd && !dntAskPwd ? (
                    <div
                      className={
                        "form-group" +
                        (this.state.isFormSubmit && !this.state.password
                          ? " has-error"
                          : "")
                      }
                    >
                      <Input
                        className="form-control"
                        autoComplete="new-password"
                        type="password"
                        name="password"
                        value={this.state.password}
                        onChange={urlPwd === "" ? this.handleChange : ""}
                        placeholder="Meeting Password"
                      />
                      {this.state.isFormSubmit &&
                        !this.state.password &&
                        urlPwd === "" && (
                          <div className="help-block j_error_msg">
                            Please enter the password of this conference !
                          </div>
                        )}
                    </div>
                  ) : (
                    ""
                  )}
                  {!loadingStatus ? (
                    <div className="form-group mb-2 mt-4 pt-2 text-center">
                      <Button
                        className="meeting_enter_btn"
                        color="success"
                        onClick={() =>
                          this.successHandler(this.state.isWebinar)
                        }
                      >
                        <i className="fas fa-sign-in-alt"></i> Enter in Meeting
                      </Button>
                    </div>
                  ) : (
                    <div className="form-group mb-2 mt-4 pt-2 text-center">
                      <Button
                        className="meeting_enter_btn"
                        type="button"
                        color="success"
                      >
                        <Spinner size="sm" color="light" /> Joining...
                      </Button>
                    </div>
                  )}
                </Form>
              </div>
            </div>
          ) : (
            <div className="attendy_name_bx">
              <div className="wxr_title">
                Joining Meeting
                <span>
                  <LoadSpinner text={""} />
                </span>
              </div>
            </div>
          )}

          {(this.props?.conferenceBits?.logo) && <div className="text-center welcomeMsg f_size_11 mt-3">
            Proudly Powered By
            <img
              src={images.logoImgWhiteTheme}
              alt="logo"
              width={120}
            />
          </div>}
        </div>

        <ExtModal
          extModalShow={this.state.extModalShow}
          extHandle={this.extHandle}
          isChromeBrowser={this.state.isChromeBrowser}
        />
      </div>
    );
  }
}

function mapState(state) {
  const { loadingStatus, joinError, url } = state.joinConference;
  const { conferenceBits, error, confLoading } = state.conference;
  const { addUserRes, addUserError } = state.waitingRoom;

  return {
    loadingStatus,
    conferenceBits,
    error,
    joinError,
    addUserRes,
    addUserError,
    url,
    confLoading,
  };
}
const actionCreators = {
  joinConference: joinActions.joinConference,
  meetingInfo: conferenceActions.meetingBits,
  addUserToWaitingRoom: waitingRoomActions.addUser,
  alertError: alertActions.error,
};

export default withRouter(connect(mapState, actionCreators)(JoinMeetingPage));
