import { webinarRoomConstants } from '../_constants';

const initialState = { 
	speakerList : [],
	speakerListLoading : false,
	speakerListError : false,

	attendeesList : [],
	attendeesListLoading : false,
	attendeesListError : false,

	submitChat : false,
	submitChatLoading : false,
	submitChatError : false,

	checkList : [],
	checkListLoading : false,
	checkListError : false,

	handRaiseLoading : false,
	handRaiseError : false,
	handRaiseStatus : false,

	detailLoading:true,
	detailInfo:{},
	detailError:{},

	permissionActionState:false,
	permissionActionLoading:false,
	permissionActionError:false,

	addingInWebinar:false,
  	addedInWebinar:false,
  	webinarRoomResp:{},

  	questionSubmitLoading : false,
  	questionSubmitStatus : false,
  	questionSubmitError : false,

  	answerSubmitLoading : false,
  	answerSubmitStatus : false,
  	answerSubmitError : false,

  	faqModeratorLoading : false,
  	faqModeratorList : [],
  	faqModeratorError : false,

  	faqAttendeeLoading : false,
  	faqAttendeeList : [],
  	faqAttendeeError : false,

  	likeSubmitLoading : false,
  	likeSubmitStatus : false,
  	likeSubmitError : false,

  	starSubmitLoading : false,
  	starSubmitStatus : false,
  	starSubmitError : false,

  	updateJoinBitState:false,
	updateJoinBitLoading:false,
	updateJoinBitError:false,

	peerResult:{},
	peerError:{}
}

export function webinarRoom(state = initialState, action) {
	switch(action.type){
		case webinarRoomConstants.SPEAKER_LIST_REQUEST:
	      return {
	        ...state,
	        speakerListLoading: true,
	        speakerListError : false
	      };
	    case webinarRoomConstants.SPEAKER_LIST_SUCCESS:
	      return {
	        ...state,
	        speakerListLoading: false,
	        speakerList:action.resp,
	        speakerListError : false
	      };
	    case webinarRoomConstants.SPEAKER_LIST_FAILURE:
	      return {
	        ...state,
	        speakerListLoading: false,
	        speakerList:[],
	        speakerListError : true
	      };
	    case webinarRoomConstants.ATTENDEES_LIST_REQUEST:
	      return {
	        ...state,
	        attendeesListLoading: true,
	        attendeesListError : false
	      };
	    case webinarRoomConstants.ATTENDEES_LIST_SUCCESS:
	      return {
	        ...state,
	        attendeesListLoading: false,
	        attendeesList:action.resp,
	        attendeesListError : false
	      };
	    case webinarRoomConstants.ATTENDEES_LIST_FAILURE:
	      return {
	        ...state,
	        attendeesListLoading: false,
	        attendeesList:[],
	        attendeesListError : true
	      };
	    case webinarRoomConstants.SUBMIT_CHAT_REQUEST:
	      return {
	        ...state,
	        submitChat: false,
	        submitChatLoading : true,
	        submitChatError : false,
	      };
	    case webinarRoomConstants.SUBMIT_CHAT_SUCCESS:
	      return {
	        ...state,
	        submitChatLoading : false,
	        submitChat: true,
	        submitChatError : false
	      };
	    case webinarRoomConstants.SUBMIT_CHAT_FAILURE:
	      return {
	        ...state,
	        submitChatLoading : false,
	        submitChat: false,
	        submitChatError : true
	      };
	    case webinarRoomConstants.CHAT_LIST_REQUEST:
	      return {
	        ...state,
	        checkListLoading : true,
	        checkListError : false
	      };
	    case webinarRoomConstants.CHAT_LIST_SUCCESS:
	      return {
	        ...state,
	        checkListLoading : false,
	        checkList: action.resp,
	        checkListError : false
	      };
	    case webinarRoomConstants.CHAT_LIST_FAILURE:
	      return {
	        ...state,
	        checkListLoading : false,
	        checkList: [],
	        checkListError : true
	      };
	    case webinarRoomConstants.HAND_RAISE_REQUEST:
	      return {
	        ...state,
	        handRaiseLoading : true,
	        handRaiseStatus : action.req,
	        handRaiseError : false
	      };
	    case webinarRoomConstants.HAND_RAISE_SUCCESS:
	      return {
	        ...state,
	        handRaiseLoading : false,
	        handRaiseStatus : action.resp,
	        handRaiseError : false
	      };
	    case webinarRoomConstants.HAND_RAISE_FAILURE:
	      return {
	        ...state,
	        handRaiseLoading : false,
	        handRaiseStatus : action.resp,
	        handRaiseError : true
	      };

	    case webinarRoomConstants.ROOM_DETAIL_REQUEST:
	      return {
	        ...state,
	        detailLoading:true,
			//detailInfo:{},
			//detailError:{}
	      };
	    case webinarRoomConstants.ROOM_DETAIL_SUCCESS:
	      return {
	        ...state,
	        detailLoading:false,
			detailInfo:action.resp,
			detailError:{}
	      };
	    case webinarRoomConstants.ROOM_DETAIL_FAILURE:
	      return {
	        ...state,
	        detailLoading:false,
			detailInfo:{},
			detailError:action.error
	      };
	    case webinarRoomConstants.PERMISSION_ACTION_REQUEST:
	      return {
	        ...state,
	        permissionActionState:false,
			permissionActionLoading:true,
			permissionActionError:false
	      };
	    case webinarRoomConstants.PERMISSION_ACTION_SUCCESS:
	      return {
	        ...state,
	        permissionActionState:true,
			permissionActionLoading:false,
			permissionActionError:false
	      };
	    case webinarRoomConstants.PERMISSION_ACTION_FAILURE:
	      return {
	        ...state,
	        permissionActionState:false,
			permissionActionLoading:false,
			permissionActionError:true
	      };

	    case webinarRoomConstants.MODERATOR_JOIN_REQUEST:
	      return {
	        ...state,
	        addingInWebinar:true,
		  	addedInWebinar:false,
		  	webinarRoomResp:{},
	      };
	    case webinarRoomConstants.MODERATOR_JOIN_SUCCESS:
	      return {
	        ...state,
	        addingInWebinar: true,
        	webinarRoomResp:action.resp,
        	addedInWebinar:true
	      };
	    case webinarRoomConstants.MODERATOR_JOIN_FAILURE:
	      return {
	        ...state,
	        addingInWebinar:false,
	        webinarRoomResp:{},
	        addedInWebinar:false
	      };
	    case webinarRoomConstants.QUESTION_SUBMIT_REQUEST:
	      return {
	        ...state,
	        questionSubmitLoading:true,
		  	questionSubmitStatus:false,
		  	questionSubmitError:false
	      };
	    case webinarRoomConstants.QUESTION_SUBMIT_SUCCESS:
	      return {
	        ...state,
	        questionSubmitLoading:false,
		  	questionSubmitStatus:true,
		  	questionSubmitError:false
	      };
	    case webinarRoomConstants.QUESTION_SUBMIT_FAILURE:
	      return {
	        ...state,
	        questionSubmitLoading:false,
		  	questionSubmitStatus:false,
		  	questionSubmitError:true
	      };
	    case webinarRoomConstants.ANSWER_SUBMIT_REQUEST:
	      return {
	        ...state,
	        answerSubmitLoading:true,
		  	answerSubmitStatus:false,
		  	answerSubmitError:false
	      };
	    case webinarRoomConstants.ANSWER_SUBMIT_SUCCESS:
	      return {
	        ...state,
	        answerSubmitLoading:false,
		  	answerSubmitStatus:true,
		  	answerSubmitError:false
	      };
	    case webinarRoomConstants.ANSWER_SUBMIT_FAILURE:
	      return {
	        ...state,
	        answerSubmitLoading:false,
		  	answerSubmitStatus:false,
		  	answerSubmitError:true
	      };
	    case webinarRoomConstants.FAQ_MODERATOR_REQUEST:
	      return {
	        ...state,
	        faqModeratorLoading:true,
		  	faqModeratorError:false
	      };
	    case webinarRoomConstants.FAQ_MODERATOR_SUCCESS:
	      return {
	        ...state,
	        faqModeratorLoading:false,
        	faqModeratorList:action.resp,
		  	faqModeratorError:false
	      };
	    case webinarRoomConstants.FAQ_MODERATOR_FAILURE:
	      return {
	        ...state,
	        faqModeratorLoading:false,
        	faqModeratorList:[],
		  	faqModeratorError:true
	      };
	    case webinarRoomConstants.FAQ_ATTENDEE_REQUEST:
	      return {
	        ...state,
	        faqAttendeeLoading:true,
		  	faqAttendeeError:false
	      };
	    case webinarRoomConstants.FAQ_ATTENDEE_SUCCESS:
	      return {
	        ...state,
	        faqAttendeeLoading:false,
        	faqAttendeeList:action.resp,
		  	faqAttendeeError:false
	      };
	    case webinarRoomConstants.FAQ_ATTENDEE_FAILURE:
	      return {
	        ...state,
	        faqAttendeeLoading:false,
        	faqAttendeeList:[],
		  	faqAttendeeError:true
	      };


	     case webinarRoomConstants.LIKE_FAQ_REQUEST:
	      return {
	        ...state,
	        likeSubmitLoading:true,
		  	likeSubmitStatus:false,
		  	likeSubmitError:false
	      };
	    case webinarRoomConstants.LIKE_FAQ_SUCCESS:
	      return {
	        ...state,
	        likeSubmitLoading:false,
		  	likeSubmitStatus:true,
		  	likeSubmitError:false
	      };
	    case webinarRoomConstants.LIKE_FAQ_FAILURE:
	      return {
	        ...state,
	        likeSubmitLoading:false,
		  	likeSubmitStatus:false,
		  	likeSubmitError:true
	      };


	    case webinarRoomConstants.STAR_FAQ_REQUEST:
	      return {
	        ...state,
	        starSubmitLoading:true,
		  	starSubmitStatus:false,
		  	starSubmitError:false
	      };
	    case webinarRoomConstants.STAR_FAQ_SUCCESS:
	      return {
	        ...state,
	        starSubmitLoading:false,
		  	starSubmitStatus:true,
		  	starSubmitError:false
	      };
	    case webinarRoomConstants.STAR_FAQ_FAILURE:
	      return {
	        ...state,
	        starSubmitLoading:false,
		  	starSubmitStatus:false,
		  	starSubmitError:true
	      };

	    case webinarRoomConstants.CHANGE_BIT_REQUEST:
	      return {
	        ...state,
	        updateJoinBitState:false,
			updateJoinBitLoading:true,
			updateJoinBitError:false
	      };
	    case webinarRoomConstants.CHANGE_BIT_SUCCESS:
	      return {
	        ...state,
	        updateJoinBitState:true,
			updateJoinBitLoading:false,
			updateJoinBitError:false
	      };
	    case webinarRoomConstants.CHANGE_BIT_FAILURE:
	      return {
	        ...state,
	        updateJoinBitState:false,
			updateJoinBitLoading:false,
			updateJoinBitError:true
	      };

	    case webinarRoomConstants.PEER_DATA_REQUEST:
	      return {
	        ...state,
	        //peerResult:{}
	      };
	    case webinarRoomConstants.PEER_DATA_SUCCESS:
	      return {
	        ...state,
	        peerResult:action.resp,
	        peerError:{}
	      };
	    case webinarRoomConstants.PEER_DATA_FAILURE:
	      return {
	        ...state,
	        peerResult:{},
	        peerError:action.error
	      };  
	    default:
	    	return {
	    		...state
	    	}

	}
};