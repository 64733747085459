import React from 'react'

const CountNumber = (props) => {
    let getAllInfo =  props.getAllInfo ; 

    return (
        <div className="container-fluid intro-section text-center">
        <hr className="line"/>
       <div className="container intro-container">
          <h2 className="heading-md">Few of our milestones</h2>
         <div className="intro-box-section">
        <div className="intro-col color-perpal">
          <div className="col-bold-txt">{getAllInfo.totalUser}</div>
          <div className="col-txt">Proud Users</div>
        </div>
    
        <div className="intro-col color-blue">
          <div className="col-bold-txt">{getAllInfo.totalWebinar}</div>
          <div className="col-txt">Successful Webinars</div>
        </div>
    
        <div className="intro-col color-orange">
          <div className="col-bold-txt">{getAllInfo.totalMeeting}</div>
          <div className="col-txt">Interactive Meetings</div>
        </div>
    
        <div className="intro-col color-green">
          <div className="col-bold-txt">{/*getAllInfo.totalClient*/}58</div>
          <div className="col-txt">Delighted Clients</div>
        </div>
    
        <div className="intro-col color-red" >
          <div className="col-bold-txt">{getAllInfo.liveMeeting}/{getAllInfo.liveWebinar}</div>
          <div className="col-txt">Live Meeting/Webinars</div>
        </div>
      </div>
       </div>
     </div>
    )
}
export default CountNumber;