import React, { Component } from 'react';
import './style.scss';
// import JoinUrlContext from './../../../../../context/JoinUrlContext';

class VideoConference extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div className="videoConference">
                {this.props.join_url ? <iframe src={this.props.join_url} allow="camera;microphone" width="100%" height="750"></iframe> : <img src="../images/eoffice/video-conference-img.png" alt="" />}
            </div>
        )
    }
}

export default VideoConference;
