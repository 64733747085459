import { scheduleConstants } from '../_constants';

const initialState = {
  loading : false,
  scheduleLoading: false,
  schedule: null,
  error : false,
  scheduleDetail : null,
  deleteScheduleStatus : false,
  cancelScheduleStatus : false,
  IsScheduleEdited: false
}

export function schedule(state = initialState, action) {
  switch (action.type) {
    case scheduleConstants.SCHEDULE_START:
      return {
        ...state,
        loading: true,
        error : false  
      };
    case scheduleConstants.SCHEDULE_SUCCESS:
      return {
        ...state,
        loading: false,
        scheduleLoading: true,
        schedule: action.response,
        error : false 
      };
    case scheduleConstants.SCHEDULE_FAILURE:
      return {
        ...state,
        loading: false,
        scheduleLoading: false,
        error : true 
      };
    case scheduleConstants.SCHEDULE_DETAIL_START:
      return {
        ...state,
        loading: true,
        error : false  
      };
    case scheduleConstants.SCHEDULE_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        scheduleDetail: action.response,
        error : false 
      };
    case scheduleConstants.SCHEDULE_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error : true 
      };
    case scheduleConstants.SCHEDULE_DELETE_START:
      return {
        ...state,
        loading: true,
        deleteScheduleStatus:false,
        error : false,  
      };
    case scheduleConstants.SCHEDULE_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteScheduleStatus: true,
        error : false 
      };
    case scheduleConstants.SCHEDULE_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        deleteScheduleStatus:false,
        error : true 
      };
    case scheduleConstants.SCHEDULE_CANCEL_START:
      return {
        ...state,
        loading: true,
        cancelScheduleStatus:false,
        error : false,  
      };
    case scheduleConstants.SCHEDULE_CANCEL_SUCCESS:
      return {
        ...state,
        loading: false,
        cancelScheduleStatus: true,
        error : false 
      };
    case scheduleConstants.SCHEDULE_CANCEL_FAILURE:
      return {
        ...state,
        loading: false,
        cancelScheduleStatus:false,
        error : true 
      };

      case scheduleConstants.SCHEDULE_EDIT_START:
        return {
          ...state,
          loading: true,
          IsScheduleEdited:false,
          error : false,  
        };
      case scheduleConstants.SCHEDULE_EDIT_SUCCESS:
        return {
          ...state,
          loading: false,
          IsScheduleEdited: true,
          error : false 
        };
      case scheduleConstants.SCHEDULE_EDIT_FAILURE:
        return {
          ...state,
          loading: false,
          IsScheduleEdited:false,
          error : true 
        };  
    default:
      return state
  }
}