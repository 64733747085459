import React, { useState } from "react";
import PropTypes from "prop-types";

import SideBar from "./sidebar";
import Header from "./header";
import "./styles.scss";

const Layout = (props) => {
  const { children, list, title, goBack, jwt, headerLeft, active } = props;
  const [toggle, setToggle] = useState(false);

  return (
    <div className="layoutContainer layoutBg  d-flex">
      <SideBar
        toggle={toggle}
        setToggle={setToggle}
        list={list}
        active={active}
      />
      <div
        className={`${!toggle ? "ml256 " : "ml100 "} ${
          (window.outerWidth > 1201
            ? toggle
              ? "w-100Auto100"
              : "w-100Auto256"
            : "w-100",
          window.outerWidth > 400
            ? toggle
              ? "w-100"
              : "w-100Auto256"
            : "w-100")
        }`}
      >
        <Header
          jwt={jwt}
          title={title || null}
          goBack={goBack}
          headerLeft={headerLeft}
          setToggle={setToggle}
          toggle={toggle}
        />
        <div className="childrenContainer pr-0">{children}</div>
      </div>
    </div>
  );
};

Layout.propTypes = {
  list: PropTypes.string,
  title: PropTypes.string,
};

export default Layout;
