import { roleConstants } from './../_constants';

const initialState = { loadingStatus: false, roleList: [], isRoleAdded: false, roleData: '' };


export function roles(state = initialState, action) {
    switch (action.type) {
        case roleConstants.ROLE_ADD_REQUEST:
            return {
                ...state,
                isRoleAdded: false
            }
        case roleConstants.ROLE_ADD_SUCCESS:
            return {
                ...state,
                loadingStatus: false,
                isRoleAdded: true
            }
        case roleConstants.ROLE_ADD_FAILURE:
            return {
                ...state,
                loadingStatus: false,
                isRoleAdded: false
            }

        case roleConstants.ROLE_GETALL_REQUEST:
            return {
                ...state,
                roleList: []
            }
        case roleConstants.ROLE_GETALL_SUCCESS:
            return {
                ...state,
                loadingStatus: false,
                roleList: action.resp
            }
        case roleConstants.ROLE_GETALL_FAILURE:
            return {
                ...state,
                loadingStatus: false,
                roleList: []
            }

        case roleConstants.ROLE_BYID_REQUEST:
            return {
                ...state,
                roleData: ''
            }
        case roleConstants.ROLE_BYID_SUCCESS:
            return {
                ...state,
                loadingStatus: false,
                roleData: action.resp
            }
        case roleConstants.ROLE_BYID_FAILURE:
            return {
                ...state,
                loadingStatus: false,
                roleData: ''
            }
        default:
            return state
    }
}




