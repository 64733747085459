import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, Row, Col, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  FaEdit,
  FaVideo,
  FaWhatsapp,
  FaUsers,
  FaMicrophone,
} from "react-icons/fa";
import { InputFiled } from "../../Common/authFile";

import { MdContentCopy, MdOutlineEmail, MdTimer } from "react-icons/md";
import { AiOutlineCalendar, AiOutlineClockCircle } from "react-icons/ai";
import { IoRecording } from "react-icons/io5";
import { confirmAlert } from "react-confirm-alert";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Formik } from "formik";
import * as Yup from "yup";

import Layout from "../../layoutSection";
import {
  alertActions,
  webinarActions,
  conferenceActions,
} from "../../Redux/_actions";
import { webinarService } from "../../Redux/_services";

import Spinner from "../../components/UI/Spinner/Spinner";
import { getDate } from "../../Common/constant";
import { durationTime, tsToDate, tsToTime } from "../../helpers/helpers";
import { myconfig } from "../../config/config";
import ConfirmationPopup from "../../components/UI/ConfirmationPopup/confirmationPopup";

import "react-perfect-scrollbar/dist/css/styles.css";
import "./WebinarDetails.css";
import isEmpty from "is-empty";

let timer = "Coming....";

let endDt = new Date();
endDt.setHours(23);
endDt.setMinutes(59);

const sampleContainer = {
  width: "100%",
};
const BaseInfo = (props) => {
  const webinarId = props?.match?.params?.id;
  const dispatch = useDispatch();
  const { webinarDetail, detailLoading } = useSelector(
    (state) => state.webinar
  );
  const conference = useSelector((state) => state.conference);
  const [urls, setUrls] = useState({
    registrationUrl: "",
    joinHostURL: "",
  });
  const [timers, setTimers] = useState("Coming....");
  const [isEdited, setIsEdited] = useState(false);

  useEffect(() => {
    if (webinarId) {
      let registrationUrl =
        myconfig.serverURL + "/register_webinar/" + webinarId;
      let joinHostURL = myconfig.serverURL + "/attend_webinar?wid=" + webinarId;
      setUrls({ registrationUrl, joinHostURL });
      if (
        (!webinarDetail && !detailLoading) ||
        webinarDetail?.meetingID !== webinarId
      ) {
        dispatch(webinarActions.webinarDetail(webinarId));
      }
    }
  }, [detailLoading, webinarDetail, webinarId]);

  useEffect(() => {
    if (!isEmpty(webinarDetail)) {

      let  startDate = new Date(webinarDetail?.startTS);
        let startTime = new Date(webinarDetail?.startTS);
      const startTS = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate(),
        startTime.getHours(),
        startTime.getMinutes(),
        startTime.getSeconds()
      );
      if (new Date().getTime() < startTS.getTime()) {
        clearInterval(timer)
        timer = setInterval(() => getTimer(new Date(startTS).getTime()), 1000);
      }
    }
    if (webinarDetail) {
      setInitialValue(getInitial(webinarDetail));
    }
  }, [webinarDetail]);

  const getCopy = (url) => {
    dispatch(alertActions.success("Copied !!!"));
    navigator.clipboard.writeText(url);
  };

  const shareUrl = (url, type) => {
    const message = `Hello ,  Please Join From URL : ${url} Thank You`;
    if (type === "whatsapp") {
      return "whatsapp://send?text=" + message;
    } else {
      return "mailto:?Subject=Schedule Webinar&Body=" + message;
    }
  };

  const getTimer = (startedTime) => {
    startedTime = startedTime - 1000 * 60 * 15;
    var date = new Date();
    var currentTimestemp = date.getTime();
    var currentTime = date.getTime();
    // startedTime = startedTime - 1000*60*15;

    var seconds = Math.floor((startedTime - currentTime) / 1000);
    var minutes = Math.floor(seconds / 60);
    var hours = Math.floor(minutes / 60);
    var days = Math.floor(hours / 24);

    hours = hours - days * 24;
    minutes = minutes - days * 24 * 60 - hours * 60;
    seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;
    if (currentTimestemp >= startedTime) {
      clearInterval(timer);
      setTimers(null);

      return;
    } else {
      if(currentTimestemp <= (startedTime - 86400000)){
        let d =Math.round((startedTime-currentTimestemp ) / (1000*60*60*24));
        setTimers(d+" Days Left")

      }else{
      setTimers(
        ("0" + days).slice(-2) +
        ":" +
        ("0" + hours).slice(-2) +
        ":" +
        ("0" + minutes).slice(-2) +
        ":" +
        ("0" + seconds).slice(-2)
      );
      }
    }
  };

  const getInitial = (webinarDetail) => {
    // let start_date = webinarDetail?.start_date;
    // let start_time = webinarDetail?.start_time;
    // let end_time = webinarDetail?.end_time;

    let  startDate = new Date(webinarDetail?.startTS);
    let startTime = new Date(webinarDetail?.startTS);
    let endTime = new Date(webinarDetail?.endTS);
      
    return {
      name: webinarDetail?.name || "",
      startDate:startDate,
      startTime:startTime,
      endTime: endTime,
      description: webinarDetail?.description || "",
    };
  };
  const validation = Yup.object().shape({
    name: Yup.string().required("please Enter The Title!"),
    description: Yup.string().required(" Please Enter The Description!"),
    startDate: Yup.date().required("Please Select the Start Date!"),
    startTime: Yup.string().required("Please Select the Start Time!"),
    endTime: Yup.string().required("Please Select the End Time!"),
  });
  const [initialValue, setInitialValue] = useState(getInitial(webinarDetail));

  const submitHandler = (value) => {

    const startDate = new Date(value.startDate).getTime();
    const startTime = new Date(value.startTime).getTime();
    const endTime = new Date(value.endTime).getTime();


    const params = {
      meetingId: webinarId,
      name: value.name,
      description: value.description,
      startTS : startTime,
      endTS: endTime,
    };
    // dispatch(webinarActions.updateWebinar(params));
    webinarService
      .update(params)
      .then((resp) => {
        dispatch(alertActions.success("Successfully Updated Webinar!"));
        //window.location.href = "/webinars/"+resp
      })
      .then(
        (res) => dispatch(webinarActions.webinarDetail(webinarId)),
        (err) => dispatch(alertActions.error(err.toString()))
      );
    setIsEdited(!isEdited);
  };

  const getMin = (date) => {
    const today = new Date();
    const selectedDate = new Date(date);
    if (
      today.getDate() === selectedDate.getDate() &&
      today.getMonth() === selectedDate.getMonth() &&
      today.getFullYear() === selectedDate.getFullYear()
    ) {
      return new Date();
    }
    return "00:00";
  };

  const deleteMeetingConfirm = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmationPopup
            message={"Are you sure want to cancel this webinar ?"}
            noBtnAction={onClose}
            noBtnText="No"
            yesBtnText="Yes, Cancel AnyWay!"
            yesBtnAction={() =>
              dispatch(webinarActions.cancelWebinar(webinarId))
            }
          />
        );
      },
    });
  };

  const SecondSection = () => {
    if (webinarDetail.isMeetingEnded === 1) {
      return (
        <div className="secondSection bgColorBodyCard mt-5">
          <div className="between">
            <h5 className="welcomeMsg">Overview</h5>
            <div className="mb-3 f_size_14 text-muted">
              {" "}
              Duration :{" "}
              <span className="welcomeMsg">
                {webinarDetail?.report
                  ? durationTime(
                    webinarDetail?.report?.startTime,
                    webinarDetail?.report?.endTime
                  )
                  : "00:00"}
              </span>
            </div>
          </div>
          <Row className="mt-4">
            <Col
              className={`welcomeMsg ${webinarDetail?.recordData?.url ? "divider-Right" : ""
                } around f_size_17`}
              lg={webinarDetail?.recordData?.url ? 5 : 12}
            >
              <div className="text-center">
                <div className="mb-2 f_size_28 text-warning">
                  <FaMicrophone className="mr-1" />
                  Speaker
                </div>
                <div className="font-weight-bold f_size_40">
                  {webinarDetail?.report ? webinarDetail?.report?.hostCount : 0}
                </div>
              </div>
              <div className="text-center">
                <div className="mb-2 f_size_17 text-success f_size_28">
                  <FaUsers className="mr-1" />
                  Attendees
                </div>
                <div className="font-weight-bold f_size_40">
                  {webinarDetail?.report
                    ? webinarDetail?.report?.attendeeCount
                    : 0}
                </div>
              </div>
            </Col>

            {webinarDetail?.recordData?.url && (
              <Col className="welcomeMsg f_size_17" lg={7}>
                <div className="mb-3  text-lite">
                  <IoRecording /> Recording
                </div>
                <video width="100%" height="124" controls>
                  <source
                    src={webinarDetail?.recordData?.url}
                    type="video/mp4"
                  />
                </video>
              </Col>
            )}
          </Row>
        </div>
      );
    } else {
      return (
        <div className="secondSection bgColorBodyCard mt-5">
          <h5 className="welcomeMsg">Invite Team</h5>
          <Row className="mt-4">
            {webinarDetail?.registration === true ? (
              <>
                <div className="col-lg-12">
                  <div className="mb-3 mt-4 f_size_14 welcomeMsg pl-2">
                    {" "}
                    Registration URL
                  </div>
                </div>
                <div className="col-lg-12 row px-3">
                  <Col className="welcomeMsg " lg={7}>
                    <div className="regUrl  f_size_17">
                      <span className="truncate">{urls.registrationUrl}</span>
                      <button
                        className="btnBgLess  copyUrlBtn welcomeMsg"
                        onClick={() => getCopy(urls.registrationUrl)}
                      >
                        <MdContentCopy />
                      </button>
                    </div>
                  </Col>

                  <Col className="welcomeMsg mt-1" lg={5}>
                    <div className="mt-sm-0">
                      <Button
                        className="btnCss m-0  mr-3 mt-3 btn-sm"
                        variant="outline-success"
                        href={shareUrl(urls.registrationUrl, "whatsapp")}
                      >
                        <FaWhatsapp className="mr-1  f_size_20" /> Whatsapp
                      </Button>
                      <Button
                        className="btnCss m-0 btn-sm mt-3"
                        variant="outline-primary"
                        href={shareUrl(urls.registrationUrl, "mail")}
                      >
                        <MdOutlineEmail className="mr-1 f_size_20" /> Mail{" "}
                      </Button>
                    </div>
                  </Col>
                </div>
              </>
            ) : (
              ""
            )}
            <div className="col-lg-12">
              <div className="mb-3 mt-4 f_size_14 welcomeMsg pl-2">
                {" "}
                Without Registration URL
              </div>
            </div>
            <div className="col-lg-12 row url-invite-link webinar-url-link">
              <Col className="welcomeMsg px-0" lg={8} md={12} sm={12}>
                <div className="regUrl  f_size_17 mt-0">
                  {" "}
                  <span className="truncate">{urls.joinHostURL}</span>{" "}
                  <button
                    className="btnBgLess copyUrlBtn welcomeMsg"
                    onClick={() => getCopy(urls.joinHostURL)}
                  >
                    <MdContentCopy />{" "}
                  </button>{" "}
                </div>
              </Col>
              <Col className="welcomeMsg mt-1" lg={4} md={12} sm={12}>
                <div className="mt-sm-0 share-msg-links">
                  <Button
                    className="btnCss m-0 mr-3 btn-sm col-6"
                    variant="outline-success"
                    href={shareUrl(urls.joinHostURL, "whatsapp")}
                  >
                    <FaWhatsapp className="mr-1 f_size_20" /> Whatsapp{" "}
                  </Button>
                  <Button
                    className="btnCss m-0 btn-sm col-6"
                    variant="outline-primary"
                    href={shareUrl(urls.joinHostURL, "mail")}
                  >
                    <MdOutlineEmail className="mr-1 f_size_20" /> Mail{" "}
                  </Button>
                </div>
              </Col>
            </div>
          </Row>
        </div>
      );
    }
  };

  if (!webinarDetail) {
    return (
      <div className="text-center">
        <Spinner />
      </div>
    );
  } else {
    // let start_date = webinarDetail?.start_date;
    // let start_time = webinarDetail?.start_time;
    // let end_time = webinarDetail?.end_time;

    let startDate = new Date(webinarDetail?.startTS);
    let startTime = new Date(webinarDetail?.startTS);
    let endTime = new Date(webinarDetail?.endTS);
    let webinarPWD = webinarDetail.attendeePW;

    const startTS =webinarDetail?.startTS;
    return (
      <Layout
        title={webinarDetail?.name || "Basic info"}
        goBack="/webinars"
        list="webinar"
        active="Basic Info"
      >
        <div className="container s_wave_content_part pl-3 pr-4 baseInfo ">
          {conference.startMeetingLoading && (
            <Modal
              show={conference.startMeetingLoading}
              contentClassName="modalLoading"
            >
              <Spinner />{" "}
            </Modal>
          )}
          <Formik
            initialValues={initialValue}
            onSubmit={(value) => submitHandler(value)}
            validationSchema={validation}
            enableReinitialize
          >
            {({
              values,
              setFieldValue,
              handleSubmit,
              resetForm,
              errors,
              touched,
            }) => (
              <>
                <div className="detailsSection sideBar">
                  <div className="d-flex justify-content-between row px-3">
                    {/* <div className="d-flex justify-content-between f_size_15"> */}
                    {webinarDetail.endTS > new Date().getTime() ?
                    <>
                    {isEdited ? (
                      <div>
                        <button
                          type="submit"
                          className="submitBtnColor headerBtn editBtn"
                          style={{
                            border: "0px",
                            width: "104px",
                            color: "#fff",
                          }}
                          onClick={() => handleSubmit()}
                        >
                          {" "}
                          <span className="f_size_14"> Update </span>{" "}
                        </button>
                        &nbsp; &nbsp;
                        <button
                          className=" headerBtn editBtn welcomeMsg sideBarIcon mr-4"
                          style={{
                            width: "104px",
                            border: "none",
                          }}
                          onClick={() => {
                            setIsEdited(!isEdited);
                            resetForm();
                          }}
                        >
                          {" "}
                          <span className="f_size_14"> Cancel </span>{" "}
                        </button>
                      </div>
                    ) : (
                      <button
                        className="submitBtnColor headerBtn editBtn"
                        style={{
                          border: "0px",
                          width: "104px",
                          color: "#fff",
                        }}
                        onClick={() => setIsEdited(!isEdited)}
                      >
                        {" "}
                        <span className="f_size_14">
                          {" "}
                          <FaEdit className="mr-2 f_size_16" />
                          Edit{" "}
                        </span>{" "}
                      </button>
                    )}
              </>
                :("")
                }
                    {/* {webinarDetail?.type === "live" ? (
                <span className="w_type_live text-success  mb-3">
                  <i className="fas fa-podcast"></i> Live Webinar
                </span>
              ) : (
                <span className="w_type_recorded text-danger  mb-3">
                  <i className="fas fa-dot-circle"></i> Recorded
                </span>
              )} */}
                    <div className=" text-right f_size_15 ">
                      <span className="text-muted" title="created on">
                        {" "}
                        {`Create Date : ${tsToDate(webinarDetail.create_date)}`}
                      </span>
                    </div>
                  </div>

                  <div className="between my-3 row">
                    <div className="col-lg-4 col-md-4 col-6 webinar-text">
                      <label className=" text-lite mb-0  ">
                        {" "}
                        Webinar Name{" "}
                      </label>
                      <div className="title welcomeMsg wbinarWelcmName">
                        {/* <FaVideo className="f_size_28 mr-2" /> {webinarDetail?.name} */}
                        {isEdited ? (
                          <>
                          <InputFiled
                            name="name"
                            type="text"
                            className="inputBgTheme inputColorTheme welcomeMsg"
                          />
                          </>
                        ) : (
                          <>
                            {" "}
                            <FaVideo className="f_size_28 mr-2" />{" "}
                            {webinarDetail?.name}{" "}
                          </>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-6 webinar-text">
                      <label className=" text-lite mb-0">Webinar Id </label>
                      <div className="title welcomeMsg f_size_18 webMeetId">
                        {webinarDetail?.meetingID}
                      </div>
                    </div>

                    {webinarDetail.isMeetingEnded !== 1 && (
                      <div
                        className="mt-3 text-left col-lg-4 col-md-4 col-12 webinar-btn-txt d-flex align-items-center justify-content-center row"
                      // style={{ display: "contents" }}
                      >
                        {new Date().getTime() < new Date(endTime).getTime() ? (
                          <>
                        {startDate &&
                          new Date().getTime() < new Date(startTS).getTime() &&
                          timers ? (
                          <div
                            className="welcomeMsg timer"
                            style={{ display: "contents" }}
                          >
                            <MdTimer /> {timers}
                          </div>
                        ) : (
                          <Button
                            className="btnCss border-0 bg-sucess text-white btnFont col-sm-5 col-12"
                            variant="success"
                            onClick={() =>
                              dispatch(
                                conferenceActions.startMeeting(
                                  webinarId,
                                  "webinar"
                                )
                              )
                            }
                          >
                            Start
                          </Button>
                        )}

                        <Button
                          className="btnCss border-0 bg-danger text-white btnFont col-12 col-sm-5"
                          variant="danger"
                          onClick={() => deleteMeetingConfirm()}
                        >
                          {" "}
                          Cancel{" "}
                        </Button>
                        </>
                        ):(
                          <Button
                          className="btnCss border-0 bg-secondary text-white btnFont col-12 col-sm-5"
                          variant="danger"
                        >
                          {" "}
                          Webinar Ended{" "}
                        </Button>
                        )
                          }
                      </div>

                    )}
                  </div>
                  <div className="row  my-3">
                  <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      {isEdited ? (
                        <>
                          <Row className="start-new-date row">
                            <Col className="col-lg-12 col-md-12 col-sm-12 col-12">
                              <Form.Group className="mt-1">
                                <Form.Label className="f_size_14 ml-3">
                                  Start Date
                                </Form.Label>
                                <div className="position-relative">
                                  <DatePicker
                                    selected={values.startDate}
                                    onChange={(value) =>
                                      setFieldValue( "startDate", value )
                                    }
                                    dateFormat="dd-MM-yyyy"
                                    peekNextMonth
                                    autoComplete="off"
                                    dropdownMode="Select...."
                                    minDate={new Date()}
                                    name="start_date"
                                    className="form-control w-100 inputColorTheme"
                                  />
                                  <AiOutlineCalendar className="iconInput  f_size_20 welcomeMsg" />
                                </div>
                                {touched?.startDate && errors?.startDate && (
                                  <div className="error text-danger f_size_13">
                                    {errors?.startDate}
                                  </div>
                                )}
                              </Form.Group>
                            </Col>
                            <Col className="col-lg-6 col-md-12 col-sm-6 col-6">
                              <Form.Group className="mt-1">
                                <Form.Label className="f_size_14 ml-3">
                                  Start Time
                                </Form.Label>
                                <div className="position-relative">
                                  <DatePicker
                                    selected={values.startTime}
                                    onChange={(value) =>
                                      setFieldValue("startTime", value)
                                    }
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    minTime={getMin(values.startDate)}
                                    maxTime={endDt}
                                    timeCaption="Time"
                                    dateFormat="h:mm aa"
                                    autoComplete="off"
                                    className="form-control inputColorTheme w-100"
                                  />
                                  <AiOutlineClockCircle className="iconInput  f_size_20 welcomeMsg" />
                                </div>
                                {touched?.startTime && errors?.startTime && (
                                  <div className="error text-danger f_size_13">
                                    {errors?.startTime}
                                  </div>
                                )}
                              </Form.Group>
                            </Col>
                            <Col className="col-lg-6 col-md-12 col-sm-6 col-6">
                              <Form.Group className="mt-1">
                                <Form.Label className="f_size_14 ml-3">
                                  End Time
                                </Form.Label>
                                <div className="position-relative">
                                  <DatePicker
                                    selected={values.endTime}
                                    onChange={(value) =>
                                      setFieldValue("endTime", value)
                                    }
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    minTime={new Date(values.startTime)}
                                    maxTime={endDt}
                                    timeCaption="Time"
                                    dateFormat="h:mm aa"
                                    autoComplete="off"
                                    className="form-control inputColorTheme w-100"
                                  />
                                  <AiOutlineClockCircle className="iconInput  f_size_20 welcomeMsg" />
                                </div>
                                {touched?.endTime && errors?.endTime && (
                                  <div className="error text-danger f_size_13">
                                    {errors?.endTime}
                                  </div>
                                )}
                              </Form.Group>
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <>
                          <div className="row start-new-date mx-0 newMeetStartDate">
                            <label className="text-lite ">Date</label>
                            <div
                              className="title welcomeMsg  d-flex  align-items-center  webDate f-size-15"
                              title="webinar date"
                            >
                              <>
                                {`${getDate(
                                  startDate
                                )} ${tsToTime(
                                  new Date(startTime).getTime()
                                )} - ${tsToTime(
                                  new Date(endTime).getTime()
                                )} `}
                              </>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  {webinarDetail.webinarPwdBit
                            ? (
                    <div className="col-lg-4 col-md-4 col-6 ">
                      <label className="text-lite "> Password </label>
                      <div
                        className="title welcomeMsg  d-flex  align-items-center"
                        title="Password"
                      >
                        <PerfectScrollbar className="webMeetPass f_size_15 webDate title-lc">
                          {webinarPWD.toLowerCase()}
                        </PerfectScrollbar>
                      </div>
                    </div>)
                    :""
                    }
                    
                    <div className="col-lg-4"></div>
                  </div>

                  {(webinarDetail.description || isEdited > 0) && (
                    <>
                      <label className="mb-0 mt-3">Description </label>
                      <div className="welcomeMsg  mh75 f_size_15">
                        {isEdited > 0 ? (
                          <>
                            <Form.Control
                              as="textarea"
                              className="form-control w-100 inputColorTheme"
                              rows={3}
                              value={values.description}
                              onChange={(event) =>
                                setFieldValue("description", event.target.value)
                              }
                            />
                            {touched.description && errors.description && (
                              <div className="error text-danger f_size_13">
                                {errors.description}
                              </div>
                            )}
                          </>
                        ) : (
                          <PerfectScrollbar>
                            {webinarDetail.description}
                          </PerfectScrollbar>
                        )}
                      </div>
                    </>
                  )}
                  <SecondSection />
                </div>
              </>
            )}
          </Formik>
        </div>
      </Layout>
    );
  }
};

export default BaseInfo;
