import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import "./moderatorWaitingRoom.css"
import WaitingHeader from '../waitingHeader'
import { connect } from 'react-redux';
import { conferenceActions, waitingRoomActions } from '../../../../Redux/_actions';

import AttendeeList from './Components/attendeeList';
import BroadcastMessages from './Components/broadcastMessages'

class ModeratorWaitingRoom extends Component {
	
	constructor(props){
		super(props);
		this.state = {
			mid : this.props.mid,
			mname: this.props.mname,
			hostName: "",
			timeSpent:"00:00:00",
			hasStarted:false
		}
	}

	componentDidMount = () => {
		this.props.meetingInfo(this.state.mid);
	}

	componentDidUpdate = (prevProps,prevState) => {
		if(prevProps.conferenceBits !== this.props.conferenceBits){
			this.setState({
				hostName: this.props.conferenceBits.moderatorName
			})
		}
	}

	startMeetClick = (e) => {
		const {mid,hostName} = this.state;
		//if (window.confirm('Are you sure want to start the meeting ?')){
			this.props.moderatorJoin(mid,hostName);
		//}
	}

	getMeetingStatus = (data) => {
		//console.log('infooooooooooooooo',data)
		if(data !== "" && data !== "undefined"){
			this.setState({
				hasStarted:true
			})
			
			setInterval(() => {
				this.calculateDiff(data)
			}, 1000);
		}
	}

	calculateDiff = (startedTime) => {
		var date = new Date();
		var currentTime = date.getTime();

		var seconds = Math.floor((currentTime - (startedTime))/1000);
		var minutes = Math.floor(seconds/60);
		var hours = Math.floor(minutes/60);
		var days = Math.floor(hours/24);

		hours = hours-(days*24);
		minutes = minutes-(days*24*60)-(hours*60);
		seconds = seconds-(days*24*60*60)-(hours*60*60)-(minutes*60);

		this.setState({
			timeSpent: hours+":"+minutes+":"+seconds
		})
		//console.log("timeeeeeeeeeeeeee:", hours,minutes,seconds)
	}
	

	render() {
		const { mid, mname,hasStarted,timeSpent } = this.state;
		return (
			<div className="wave_wating_sctn">
    			
    			<WaitingHeader mname={mname} mid={mid} />
    			
    			<div className="hostwave_waiting_contianer">
        			<div className="row">
			            <div className="col-7">
			                <h3 className="wave_wait_rrom_title"><i className="fas fa-hourglass-half"></i> Waiting Room </h3>
			            </div>
			            <div className="col-5 text-right"> 
			            	{hasStarted ?
			            		<div className="row">
				            		<div className="col-12">
				            			<button onClick={this.startMeetClick.bind(this)} className="btn btn-primary mb-2 btn-sm">Conference Room</button>
				            		</div>
				            		<div className="col-12">
				            			<span className="text-warning text-right">Meeting Running Time: <i className="far fa-clock"></i> {timeSpent}</span>
				            		</div>
			            		</div>
			            	:
			                <button onClick={this.startMeetClick.bind(this)} className="btn btn-primary btn-sm">Conference Room</button>
			            	}
			            </div>
        			</div>

        			<div className="row mt-3">
						<AttendeeList getMeetingStatus={this.getMeetingStatus.bind(this)} mid={mid} />
 
            			<BroadcastMessages mid={mid} />
        			</div>
				</div>

	    		
	    		    
			</div>
		)
	}
}

function mapState(state) {
  //const {loadingStatus, joinError} = state.joinConference;
  const {conferenceBits,error} = state.conference
  return {
  	//loadingStatus,
    conferenceBits,
    error,
    //joinError
  };
}
const actionCreators = {
  //joinConference:joinActions.joinConference,
  meetingInfo:conferenceActions.meetingBits,
  moderatorJoin:waitingRoomActions.moderatorJoin
  //alertError:alertActions.error

};

export default withRouter(connect(mapState, actionCreators)(ModeratorWaitingRoom));