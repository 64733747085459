import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./attendeeWaitingRoom.css";
import WaitingHeader from "../waitingHeader";
import { Spinner } from "reactstrap";
import { connect } from "react-redux";
import { tsToDate } from "../../../../helpers/helpers";
import {
  conferenceActions,
  alertActions,
  waitingRoomActions,
} from "../../../../Redux/_actions";

let isTimerRunning = false;

class AttendeeWaitingRoom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mid: this.props.mid,
      mname: this.props.mname,
      meetingBits: this.props.meetingBits,
      msgList: [],
      last_msg_time: "",
      isAttendeeAllowed: -1,
      meetStartTimer: 20,
      isMeetRunning: false,
    };
  }

  componentDidMount = () => {
    this.props.getBroadcastMsg(this.state.mid, "");
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.getBroadcastRes !== this.props.getBroadcastRes) {
      if (
        typeof this.props.getBroadcastRes.bits.isAttendeeAllowed !== "undefined"
      ) {
        // if(this.props.getBroadcastRes.bits.isMeetingEnded === 1){
        // 	this.props.alertError("Sorry, This meeting has been ended !")
        // 	this.props.history.push("/")
        // }

        if (this.props.getBroadcastRes.bits.isAttendeeAllowed === 2) {
          this.props.alertError("Please join the meeting first.");
          this.props.history.push("/");
        } else if (this.props.getBroadcastRes.bits.isAttendeeAllowed === 0) {
          this.props.alertError(
            "Meeting host has declined your request to join this meeting."
          );
          this.props.history.push("/");
        } else if (
          this.props.getBroadcastRes.bits.isAttendeeAllowed === 1 &&
          this.props.getBroadcastRes.bits.isMeetRunning === true &&
          isTimerRunning === false
        ) {
          let self = this;
          isTimerRunning = true;
          var interval = setInterval(() => {
            if (self.state.meetStartTimer === 0) {
              self.setState({
                meetStartTimer: "Joining...",
              });
              clearInterval(interval);
            } else {
              self.setState({
                meetStartTimer: self.state.meetStartTimer - 1,
              });
            }
          }, 1000);

          if (this.props.getBroadcastRes.bits.isMeetRunning) {
            this.props.attendeeJoin(
              this.props.getBroadcastRes.bits.meetingID,
              this.props.getBroadcastRes.bits.attendeeName
            );
          }

          // setTimeout(function(){
          // 	self.props.attendeeJoin(self.props.getBroadcastRes.bits.meetingID, self.props.getBroadcastRes.bits.attendeeName)
          // }, 20000);
        }
        // else if(this.props.getBroadcastRes.bits.isAttendeeAllowed === -1 && this.props.getBroadcastRes.bits.hasModeratorJoined === 1){
        // 	this.props.alertError("Meeting has started.")
        // 	this.props.history.push("/")
        // }
      }
      let isAttendeeAllowed = -1;
      if (typeof this.props.getBroadcastRes.message_list !== "undefined") {
        if (
          typeof this.props.getBroadcastRes.bits.isAttendeeAllowed !==
          "undefined"
        ) {
          isAttendeeAllowed = this.props.getBroadcastRes.bits.isAttendeeAllowed;
        }
        if (
          typeof this.props.getBroadcastRes.bits.isMeetRunning !== "undefined"
        ) {
          this.setState({
            isMeetRunning: this.props.getBroadcastRes.bits.isMeetRunning,
          });
        }

        let mid = this.props.getBroadcastRes.bits.meetingID;
        let self = this;
        let last_msg_time = this.state.last_msg_time;
        if (this.props.getBroadcastRes.message_list.length > 0) {
          //console.log(prevProps.getBroadcastRes.message_list)
          let msgList = [
            ...this.state.msgList,
            ...this.props.getBroadcastRes.message_list,
          ];
          last_msg_time = this.props.getBroadcastRes.last_msg_time;
          //msgList.push(...this.props.getBroadcastRes.message_list)
          self.setState({
            msgList: msgList,
            last_msg_time: last_msg_time,
          });
        }

        setTimeout(function () {
          //if(self.props.getBroadcastRes.bits.hasModeratorJoined !== 1){
          self.getBroadcastMessages(mid, last_msg_time, isAttendeeAllowed);
          //}
          setTimeout(function () {
            var objDiv = document.getElementById("chatList");
            if (objDiv !== null) {
              objDiv.scrollTop = objDiv.scrollHeight;
            }
          }, 300);
        }, 3000);
      }
    }
  };

  getBroadcastMessages = (mid, last_msg_time, isAttendeeAllowed) => {
    this.setState({
      isAttendeeAllowed: isAttendeeAllowed,
    });
    this.props.getBroadcastMsg(mid, last_msg_time);
  };

  render() {
    const {
      mid,
      mname,
      meetingBits,
      msgList,
      isAttendeeAllowed,
      isMeetRunning,
    } = this.state;

    let msgHtml = (
      <li className="text-center text-secondary">
        <div className="waveno_message_info">No Message.</div>
      </li>
    );
    if (msgList.length > 0) {
      msgHtml = msgList.map((msg, key) => {
        if (msg.type === "text") {
          return (
            <li key={key}>
              <div className="chat_text">{msg.value}</div>
              <div className="sender_detail">
                <span>
                  <i className="far fa-clock"></i> {tsToDate(msg.created)}
                </span>
              </div>
            </li>
          );
        } else {
          return (
            <li key={key}>
              <div className="chat_text">
                <audio
                  controls
                  src={msg.value}
                  type="audio/mpeg"
                  controlsList="nodownload"
                >
                  Your browser does not support the audio element.
                </audio>
              </div>
              <div className="sender_detail">
                <span>
                  <i className="far fa-clock"></i> {tsToDate(msg.created)}
                </span>
              </div>
            </li>
          );
        }
      });
    }

    let waitingMsgHtml = (
      <h2 className="waiging_msgh">
        Please wait, the meeting host will let you in soon.
      </h2>
    );
    if (isAttendeeAllowed === 1) {
      if (isMeetRunning) {
        waitingMsgHtml = (
          <h2 className="waiging_msgh text-warning">
            {" "}
            <Spinner color="light" /> Joining the meeting...
          </h2>
        );
      } else {
        waitingMsgHtml = (
          <h2 className="waiging_msgh_join">
            {" "}
            You have been granted by the meeting host to join this meeting,
            please wait you'll be redirected to the conference while the meeting
            host start the meeting.
          </h2>
        );
      }
    }

    return (
      <div className="wave_wating_sctn">
        <WaitingHeader mname={mname} mid={mid} />
        <div className="wave_waiting_contianer">
          <h3 className="wave_wait_rrom_title">
            <i className="fas fa-hourglass-half"></i> Waiting Room
          </h3>
          <div className="row col_reverse_mobview">
            <div className="col-md-5">
              <div className="chat_block">
                <h3>
                  Messages From Host{" "}
                  <span>
                    {meetingBits.moderatorName
                      ? meetingBits.moderatorName
                      : "Host Name"}
                  </span>
                </h3>
                <ul id="chatList">{msgHtml}</ul>
              </div>
            </div>
            <div className="col-md-7">
              <div className="waiting_banner">
                <img src="/images/sarv_Wave_waiting.png" alt="rightImage" />
              </div>

              {waitingMsgHtml}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapState(state) {
  const { getBroadcastRes, getBroadcastError } = state.waitingRoom;
  return {
    getBroadcastRes,
    getBroadcastError,
  };
}
const actionCreators = {
  meetingInfo: conferenceActions.meetingBits,
  getBroadcastMsg: waitingRoomActions.getBroadcastMsg,
  attendeeJoin: waitingRoomActions.attendeeJoin,
  alertError: alertActions.error,
};

export default withRouter(
  connect(mapState, actionCreators)(AttendeeWaitingRoom)
);
