import { myconfig, siteTokens } from '../../config/config';
import axios from 'axios';
import userconfigs from '../../helpers/closures';
import { alertActions } from './../_actions';

import { handleResponse, errorCheck, handleJWTError } from './common'

export const teamService = {
    getTeams,
    addTeam,
    getTeamById,
    editTeam
};

function getTeams() {

    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };
    //myconfig.apiURL = 'http://localhost:3001/';

    return axios.get(`${myconfig.apiURL}Setting/team/getAll`, axiosRequestOptions)
        .then(handleResponse)
        .then(resp => {
            return resp;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();

            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}

function getTeamById(id) {

    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };
    //myconfig.apiURL = 'http://localhost:3001/';

    return axios.get(`${myconfig.apiURL}Setting/team/get/${id}`, axiosRequestOptions)
        .then(handleResponse)
        .then(resp => {
            return resp;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();

            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}

function addTeam(teamData) {
    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };
    //myconfig.apiURL = 'http://localhost:3001/';

    return axios.post(`${myconfig.apiURL}Setting/team/addTeam`, teamData, axiosRequestOptions)
        .then(handleResponse)
        .then(msg => {
            return msg;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}

function editTeam(teamData, id) {
    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };
    //myconfig.apiURL = 'http://localhost:3001/';
    const eOfficeId = localStorage.getItem('eOfficeId');

    return axios.post(`${myconfig.apiURL}Setting/team/edit/${id}/${eOfficeId}`, teamData, axiosRequestOptions)
        .then(handleResponse)
        .then(msg => {
            return msg;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}

