import React from 'react'
import { Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import '../css/treetable.scss'

const TableContainer = (props) => {
  const { headers, children, className } = props
  const { theme } = useSelector(state => state.themeChange)

  return (
    <Table hover responsive variant={theme ? "" : 'dark'} className={`${theme ? "sideBar" : ''} transition03  treetable ${className || ''}`}  >
      <thead>
        {headers}
      </thead>
      <tbody>
        {children}
      </tbody>
    </Table>
  )
}

export default TableContainer
