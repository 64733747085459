import React from 'react';
import './Lang/i18n'
import ReactDOM from 'react-dom';
import './index.css';
import { App } from './App/App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { store } from './Redux/_helpers';


ReactDOM.render(
  <React.StrictMode>
    <Provider key={1} store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
