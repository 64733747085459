import React from 'react'
import { Link } from "react-router-dom";
const Colleagues = () => {
    return (
        <div className="listSection">
            <div className="title"><span className="bg-perpal"><img src="../images/icons/iconuser.svg" width="12" alt="" /></span>Colleagues</div>
            <ul className="listContent">
                <li><Link>Most unread Message <span className="count">15</span></Link></li>
                <li><Link>Mohit Kumar <span className="count">2</span></Link></li>
                <li><Link>Recent Access <span className="count">15</span></Link></li>
                <li><Link>Recent Access2 <span className="count">15</span></Link></li>
            </ul>
        </div>
    )
}

export default Colleagues;
