import React, { useEffect } from 'react'
import jwt_decode from "jwt-decode";

import userconfigs from '../helpers/closures';
import { companyService, joinService } from '../Redux/_services';
import { useDispatch } from 'react-redux';
import { alertActions } from '../Redux/_actions';

const E_Office = (props) => {

  const dispatch = useDispatch()

  useEffect(() => {
    companyInfoModal()
  }, [])

  const companyInfoModal = async () => {
    await companyService.getCompanyBranding().then(
      (res) => {
        if (res && res.hasOwnProperty("deparments")) {
          // this.setState({
          //   isJoinDepartment: true,
          //   userDeptArr: res.deparments,
          //   eOfficeId: res.cuid,
          // });
        }
        if (
          res &&
          res.hasOwnProperty("company_id") &&
          res.hasOwnProperty("invite_status") &&
          res.invite_status === "pending"
        ) {
          // this.setState({
          //   isNotAccepted: true,
          //   userDeptArr: res.deparments,
          //   eOfficeId: res.company_id,
          // });
          // return;
        }
        if (res && res.hasOwnProperty("cuid")) {
          localStorage.setItem("eOfficeId", res.cuid);
          this.props.history.push("/eoffice");
        } else {
          if (res && res.hasOwnProperty("result")) {
            let userInfo = jwt_decode(userconfigs.getItem("jwt"));

            joinService.join(0, userInfo.name, res.result).then((result) => {
              localStorage.setItem("eOfficeId", this.state.eOfficeId);
              this.props.history.push({
                pathname: "/eoffice",
                state: {
                  conf_join_url: result,
                },
              });
            });
          }
        }
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        props.history.push('/')
      },
    );
  };

  return (

    <div>HEllo</div>
  )
}

export default E_Office