import React from 'react';
import userconfigs from './helpers/closures';
import Loader from './components/UI/Spinner/Spinner'
export default function CountSection(props) {
  let token = props.match.params.token
  if (token !== "" && token !== "undefined") {
    userconfigs.setItem('jwt', token)
    //props.history.push('/')
    setTimeout(function () { window.location.href = '/' }, 3000);

    return (
      <div className="text-center"><Loader /></div>
    )
  }
  else {
    return (
      <div className="text-center"><h3>Invalid Token</h3></div>
    )
  }
}