import React, { useState } from 'react';
import { Nav, NavItem,  Collapse } from 'reactstrap';
import { Link } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import userconfigs from './../../../../../helpers/closures';

import './sideNavBar.scss';
const SideNavBar = (props) => {
    const [collapsedOne, setCollapsedOne] = useState(true);
    const [collapsedTwo, setCollapsedTwo] = useState(true);
    const [collapsedThree, setCollapsedThree] = useState(true);
    const [collapsedFour, setCollapsedFour] = useState(true);

    const toggleNavbarOne = () => setCollapsedOne(!collapsedOne);
    const toggleNavbarTwo = () => setCollapsedTwo(!collapsedTwo);
    const toggleNavbarThree = () => setCollapsedThree(!collapsedThree);
    const toggleNavbarFour = () => setCollapsedFour(!collapsedFour);

    let userInfo = jwt_decode(userconfigs.getItem('jwt'));

    return (
        <div className="sideBarPanel">
            <div className="sideBar-Profile">
                <div className="user-profile-img">{userInfo.name.charAt(0) || ''}</div>
                <div className="user-info">
                    <span>Welcome</span><br />
                    {userInfo.name || ''}

            </div>
            </div>
            <Nav vertical className="sideNavBar">
                <NavItem >
                    <Link to="/eoffice/Setting/profile"><i className="fas fa-user"></i> Profile </Link>
                </NavItem>
                <NavItem >
                    <Link to="/eoffice/Setting/company-branding"><i className="fas fa-building"></i> Company Branding </Link>
                </NavItem>
                <NavItem onClick={toggleNavbarOne}>
                    <Link to="/eoffice/Setting/create-department"> <i className="fas fa-user-cog"></i> Department <span className="dropdown-arrow"><i className="fas fa-chevron-down"></i></span></Link>

                    <Collapse isOpen={!collapsedOne} navbar>
                        <Nav navbar className="subNavbar">
                            <NavItem>
                                <Link to="/eoffice/Setting/create-department"><i className="fas fa-circle"></i> Create Department</Link>
                            </NavItem>
                            <NavItem >
                                <Link to="/eoffice/Setting/department/list"><i className="fas fa-circle"></i> Department List</Link>
                            </NavItem>
                        </Nav>
                    </Collapse>

                    <Nav vertical>

                    </Nav>
                </NavItem>
                <NavItem onClick={toggleNavbarTwo}>
                    <Link to="/eoffice/Setting/create-team"><i className="fas fa-users"></i> Team <span className="dropdown-arrow"><i className="fas fa-chevron-down"></i></span></Link>

                    <Collapse isOpen={!collapsedTwo} navbar>
                        <Nav navbar className="subNavbar">
                            <NavItem >
                                <Link to="/eoffice/Setting/create-team"><i className="fas fa-circle"></i> Create Team</Link>
                            </NavItem>
                            <NavItem >
                                <Link to="/eoffice/Setting/team/list"><i className="fas fa-circle"></i> Team List</Link>
                            </NavItem>
                        </Nav>
                    </Collapse>

                    <Nav vertical>
                    </Nav>
                </NavItem>


                <NavItem onClick={toggleNavbarThree}>
                    <Link to="/eoffice/Setting/Create-role"><i className="fas fa-user-check"></i> Role <span className="dropdown-arrow"><i className="fas fa-chevron-down"></i></span></Link>

                    <Collapse isOpen={!collapsedThree} navbar>
                        <Nav navbar className="subNavbar">
                            <NavItem >
                                <Link to="/eoffice/Setting/Create-role"><i className="fas fa-circle"></i> Create Role</Link>
                            </NavItem>
                            <NavItem >
                                <Link to="/eoffice/Setting/list-role"><i className="fas fa-circle"></i> Role List</Link>
                            </NavItem>
                        </Nav>
                    </Collapse>

                    <Nav vertical>
                    </Nav>
                </NavItem>

                <NavItem onClick={toggleNavbarFour}>
                    <Link to="/eoffice/Setting/create-user-account"><i className="fas fa-user-cog"></i> User <span className="dropdown-arrow"><i className="fas fa-chevron-down"></i></span></Link>

                    <Collapse isOpen={!collapsedFour} navbar>
                        <Nav navbar className="subNavbar">
                            <NavItem >
                                <Link to="/eoffice/Setting/create-user-account"><i className="fas fa-circle"></i> Create User Account </Link>
                            </NavItem>
                            <NavItem > 
                                <Link to="/eoffice/Setting/user/list"><i className="fas fa-circle"></i> User List</Link>
                            </NavItem>
                        </Nav>
                    </Collapse>

                    <Nav vertical>
                    </Nav>
                </NavItem>

            </Nav>
        </div>

    )
}

export default SideNavBar;
