import { myconfig, siteTokens } from '../../config/config';
import axios from 'axios';
import userconfigs from '../../helpers/closures'
import { generateToken } from '../../helpers/helpers';

import { errorCheck, handleJWTError } from './common'

export const scheduleServices = {
    createSchedule,
    scheduleDetail,
    deleteSchedule,
    cancelSchedule,
    logout,
    editSchedule,
};

function createSchedule(formData) {

    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };
    return axios.post(`${myconfig.apiURL}calendar/scheduleMeeting`, formData, axiosRequestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
                // userconfigs.removeItem("jwt");
                // generateToken();
                // window.location.href = "/login";
            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}

function editSchedule(formData) {

    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };
    return axios.post(`${myconfig.apiURL}calendar/editScheduleMeeting`, formData, axiosRequestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}


function scheduleDetail(scheduleID) {

    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };

    let postData = {
        "meetingId": scheduleID
    }
    return axios.post(`${myconfig.apiURL}calendar/getSchMeetingInfo`, postData, axiosRequestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
                // userconfigs.removeItem("jwt");
                // generateToken();
                // window.location.href = "/login";
            } else
                if (error.response.status === 586) {
                    window.location.href = "/upcoming_meeting";
                } else {
                    return Promise.reject(errorCheck(error));
                }
        });
}



function deleteSchedule(scheduleID, mid) {

    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };

    let postData = {
        "meetingId": scheduleID,
        "subMid": mid
    }
    return axios.post(`${myconfig.apiURL}meeting/deleteMeeting`, postData, axiosRequestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
                // userconfigs.removeItem("jwt");
                // generateToken();
                // window.location.href = "/login";
            } else
                if (error.response.status === 586) {
                    window.location.href = "/upcoming_meeting";
                } else {
                    return Promise.reject(errorCheck(error));
                }
        });
}



function cancelSchedule(scheduleID, mid) {

    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };

    let postData = {
        "meetingId": scheduleID,
        "subMid": mid
    }
    return axios.post(`${myconfig.apiURL}meeting/cancelMeeting`, postData, axiosRequestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
                // userconfigs.removeItem("jwt");
                // generateToken();
                // window.location.href = "/login";
            } else
                if (error.response.status === 586) {
                    window.location.href = "/meeting_history";
                } else {
                    return Promise.reject(errorCheck(error));
                }
        });
}


function logout() {
    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };

    let logoutPost = {
        fingerprint: userconfigs.getItem('fp')
    }

    return axios.post(`${myconfig.apiURL}logout`, logoutPost, axiosRequestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            userconfigs.setItem('jwt', user.token);

            return user.token;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
                // userconfigs.removeItem("jwt");
                // generateToken();
                // window.location.href = "/login";
            } else {
                return Promise.reject(errorCheck(error));
            }
        });;
}

function handleResponse(response) {

    if (response.status !== 200) {
        if (response.status === 401) {
            logout();
        }
        if (response.status === 408 || error.response.status === 753) {
            handleJWTError();
            // userconfigs.removeItem("jwt");
            // generateToken();
            // window.location.href = "/login";
        }

        const error = (response && response.message) || response.statusText;
        return Promise.reject(error);
    }

    return response.data.result;
}


