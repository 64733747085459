import React from 'react';
import { Row, Col, Table/* , Button, Popover, PopoverBody, Form, FormGroup, Input */ } from 'reactstrap';
import { Link } from "react-router-dom";
import './ListUser.scss';
import LeftPanelManager from '../../../components/LeftPanelManager/LeftPanelManager';
import ListContent from './ListContent';
const ListUser = () => {
    // const [popoverOpen, setPopoverOpen] = useState(false);
    // const filterPopHover = () => setPopoverOpen(!popoverOpen);

    return (
        <div className="DepartmentList">
            <LeftPanelManager></LeftPanelManager>
            <div className="pageContentpanel">
                <div className="department-list-content">
                    <Row>
                        <Col sm="6"><h2 className="title">Role List </h2></Col>
                        <Col sm="6" className="text-right">

                            <Link to="/eoffice/Setting/Create-role" className="btn btn-sm btn-theme primary-action" color="success">
                                <i className="fas fa-user-plus"></i> Add Role
                            </Link>
                        </Col>
                    </Row>

                    <div className="layout-table">
                        <Table className="table-list table">
                            <thead>
                                <tr>
                                    <th className="check-col">
                                        <div className="checkbox" style={{ marginTop: '10px' }}>
                                            <input className="styled-checkbox" id="styled-checkbox-1" type="checkbox" value="value1" />
                                            <label for="styled-checkbox-1"></label>
                                        </div>
                                    </th>
                                    <th>Role Name</th>
                                    <th className="nowrap">Access Level</th>
                                    <th>Custom Permission</th>

                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>

                                <ListContent />

                            </tbody>
                        </Table>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ListUser;
