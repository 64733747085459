import { webinarRoomConstants } from '../_constants';
import { webinarRoomService } from '../_services';
import { alertActions } from './';

export const webinarRoomActions = {
    speakerList,
    attendeesList,
    submitChat,
    chatList,
    handRaise,
    webinarRoomDetail,
    permissionAttendee,
    moderatorToWR,
    questionSubmitAttendee,
    answerSubmitAttendee,
    moderatorFAQList,
    attendeesFAQList,
    likeFAQSubmit,
    starFAQSubmit,
    changeAllowBitAttendee,
    webinarPeerData
};

function speakerList(meetingID) {
    return dispatch => {
        dispatch(request({ meetingID }));

        webinarRoomService.speakerList(meetingID)
            .then(
                resp => {
                    dispatch(success(resp));
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(resp) { return { type: webinarRoomConstants.SPEAKER_LIST_REQUEST, resp } }
    function success(resp) { return { type: webinarRoomConstants.SPEAKER_LIST_SUCCESS, resp } }
    function failure(error) { return { type: webinarRoomConstants.SPEAKER_LIST_FAILURE, error } }
}

function attendeesList(webinarID, fromTime = null) {
    return dispatch => {
        dispatch(request({ webinarID }));

        webinarRoomService.attendeesList(webinarID, fromTime)
            .then(
                resp => {
                    dispatch(success(resp));
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(resp) { return { type: webinarRoomConstants.ATTENDEES_LIST_REQUEST, resp } }
    function success(resp) { return { type: webinarRoomConstants.ATTENDEES_LIST_SUCCESS, resp } }
    function failure(error) { return { type: webinarRoomConstants.ATTENDEES_LIST_FAILURE, error } }
}


function handRaise(webinarID, userID, handRaiseAction) {
    return dispatch => {
        if (handRaiseAction === 1) {
            dispatch(request(0));
        }
        else {
            dispatch(request(1));
        }


        webinarRoomService.handRaise(webinarID, userID, handRaiseAction)
            .then(
                resp => {
                    dispatch(success(handRaiseAction));
                    if (handRaiseAction === 1) {
                        dispatch(alertActions.success("Hand Raised !"));
                    } else {
                        dispatch(alertActions.success("Hand Down !"));
                    }
                },
                error => {
                    if (handRaiseAction === 1) {
                        dispatch(failure(0));
                    }
                    else {
                        dispatch(failure(1));
                    }

                    dispatch(alertActions.error(error.toString()));
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(req) { return { type: webinarRoomConstants.HAND_RAISE_REQUEST, req } }
    function success(resp) { return { type: webinarRoomConstants.HAND_RAISE_SUCCESS, resp } }
    function failure(error) { return { type: webinarRoomConstants.HAND_RAISE_FAILURE, error } }
}

function submitChat(formData) {
    return dispatch => {
        dispatch(request("request"));

        webinarRoomService.submitChat(formData)
            .then(
                resp => {
                    dispatch(success(resp));
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(resp) { return { type: webinarRoomConstants.SUBMIT_CHAT_REQUEST, resp } }
    function success(resp) { return { type: webinarRoomConstants.SUBMIT_CHAT_SUCCESS, resp } }
    function failure(error) { return { type: webinarRoomConstants.SUBMIT_CHAT_FAILURE, error } }
}


function chatList(webinarID) {
    return dispatch => {
        dispatch(request("request"));

        webinarRoomService.chatList(webinarID)
            .then(
                resp => {
                    dispatch(success(resp));
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(resp) { return { type: webinarRoomConstants.CHAT_LIST_REQUEST, resp } }
    function success(resp) { return { type: webinarRoomConstants.CHAT_LIST_SUCCESS, resp } }
    function failure(error) { return { type: webinarRoomConstants.CHAT_LIST_FAILURE, error } }
}

function webinarRoomDetail(wid, token) {
    return dispatch => {
        dispatch(request("request"));

        webinarRoomService.webinarRoomDetail(wid, token)
            .then(
                resp => {
                    dispatch(success(resp));
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(wid, token) { return { type: webinarRoomConstants.ROOM_DETAIL_REQUEST, wid, token } }
    function success(resp) { return { type: webinarRoomConstants.ROOM_DETAIL_SUCCESS, resp } }
    function failure(error) { return { type: webinarRoomConstants.ROOM_DETAIL_FAILURE, error } }
}

function permissionAttendee(wid, token, action) {
    return dispatch => {
        dispatch(request("request"));

        webinarRoomService.permissionAttendee(wid, token, action)
            .then(
                resp => {
                    dispatch(success(resp));
                    if (resp && action === 1) {
                        dispatch(alertActions.success("Successfuly Allow User!"));
                    } else if (resp && action === 0) {
                        dispatch(alertActions.success("Successfuly Block User!"));
                    } else if (resp && action === -1) {
                        dispatch(alertActions.success("Successfuly Remove User!"));
                    } else if (resp && action === 2) {
                        dispatch(alertActions.success("Successfuly Allow For Join User!"));
                    } else {
                        dispatch(alertActions.success("Successfuly Action Update!"));
                    }
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(wid, token) { return { type: webinarRoomConstants.PERMISSION_ACTION_REQUEST, wid, token } }
    function success(resp) { return { type: webinarRoomConstants.PERMISSION_ACTION_SUCCESS, resp } }
    function failure(error) { return { type: webinarRoomConstants.PERMISSION_ACTION_FAILURE, error } }
}



function changeAllowBitAttendee(wid, token) {
    return dispatch => {
        dispatch(request("request"));

        webinarRoomService.changeAllowBitAttendee(wid, token)
            .then(
                resp => {
                    dispatch(success(resp));
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(wid, token) { return { type: webinarRoomConstants.CHANGE_BIT_REQUEST, wid, token } }
    function success(resp) { return { type: webinarRoomConstants.CHANGE_BIT_SUCCESS, resp } }
    function failure(error) { return { type: webinarRoomConstants.CHANGE_BIT_FAILURE, error } }
}

function moderatorToWR(wid) {
    return dispatch => {
        dispatch(request({ wid }));

        webinarRoomService.moderatorToWR(wid)
            .then(
                resp => {
                    dispatch(success(resp));
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(wid) { return { type: webinarRoomConstants.MODERATOR_JOIN_REQUEST, wid } }
    function success(resp) { return { type: webinarRoomConstants.MODERATOR_JOIN_SUCCESS, resp } }
    function failure(error) { return { type: webinarRoomConstants.MODERATOR_JOIN_FAILURE, error } }
}



function questionSubmitAttendee(postData) {
    return dispatch => {
        dispatch(request("request"));

        webinarRoomService.questionSubmitAttendee(postData)
            .then(
                resp => {
                    dispatch(success(resp));
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(wid) { return { type: webinarRoomConstants.QUESTION_SUBMIT_REQUEST, wid } }
    function success(resp) { return { type: webinarRoomConstants.QUESTION_SUBMIT_SUCCESS, resp } }
    function failure(error) { return { type: webinarRoomConstants.QUESTION_SUBMIT_FAILURE, error } }
}


function answerSubmitAttendee(postData) {
    return dispatch => {
        dispatch(request("request"));

        webinarRoomService.answerSubmitAttendee(postData)
            .then(
                resp => {
                    dispatch(success(resp));
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(wid) { return { type: webinarRoomConstants.ANSWER_SUBMIT_REQUEST, wid } }
    function success(resp) { return { type: webinarRoomConstants.ANSWER_SUBMIT_SUCCESS, resp } }
    function failure(error) { return { type: webinarRoomConstants.ANSWER_SUBMIT_FAILURE, error } }
}


function moderatorFAQList(wid, qType) {
    return dispatch => {
        dispatch(request({ wid }));

        webinarRoomService.moderatorFAQList(wid, qType)
            .then(
                resp => {
                    dispatch(success(resp));
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(wid) { return { type: webinarRoomConstants.FAQ_MODERATOR_REQUEST, wid } }
    function success(resp) { return { type: webinarRoomConstants.FAQ_MODERATOR_SUCCESS, resp } }
    function failure(error) { return { type: webinarRoomConstants.FAQ_MODERATOR_FAILURE, error } }
}


function attendeesFAQList(wid, token) {
    return dispatch => {
        dispatch(request({ wid }));

        webinarRoomService.attendeesFAQList(wid, token)
            .then(
                resp => {
                    dispatch(success(resp));
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(wid) { return { type: webinarRoomConstants.FAQ_ATTENDEE_REQUEST, wid } }
    function success(resp) { return { type: webinarRoomConstants.FAQ_ATTENDEE_SUCCESS, resp } }
    function failure(error) { return { type: webinarRoomConstants.FAQ_ATTENDEE_FAILURE, error } }
}


function likeFAQSubmit(postData) {
    return dispatch => {
        dispatch(request("request"));

        webinarRoomService.likeFAQSubmit(postData)
            .then(
                resp => {
                    dispatch(success(resp));
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(wid) { return { type: webinarRoomConstants.LIKE_FAQ_REQUEST, wid } }
    function success(resp) { return { type: webinarRoomConstants.LIKE_FAQ_SUCCESS, resp } }
    function failure(error) { return { type: webinarRoomConstants.LIKE_FAQ_FAILURE, error } }
}


function starFAQSubmit(postData) {
    return dispatch => {
        dispatch(request("request"));

        webinarRoomService.starFAQSubmit(postData)
            .then(
                resp => {
                    dispatch(success(resp));
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(wid) { return { type: webinarRoomConstants.STAR_FAQ_REQUEST, wid } }
    function success(resp) { return { type: webinarRoomConstants.STAR_FAQ_SUCCESS, resp } }
    function failure(error) { return { type: webinarRoomConstants.STAR_FAQ_FAILURE, error } }
}

function webinarPeerData(wid, lastTime) {

    return dispatch => {
        dispatch(request({ wid }));

        webinarRoomService.webinarPeerData(wid, lastTime)
            .then(
                resp => {
                    dispatch(success(resp));
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(resp) { return { type: webinarRoomConstants.PEER_DATA_REQUEST, resp } }
    function success(resp) { return { type: webinarRoomConstants.PEER_DATA_SUCCESS, resp } }
    function failure(error) { return { type: webinarRoomConstants.PEER_DATA_FAILURE, error } }
}