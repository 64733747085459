import { settingConstants } from './../_constants';

const initialState = { loading: false, update: null, errorUpdating: false };

export function setting(state = initialState, action) {
  switch (action.type) {
    case settingConstants.BRANDING_REQ:
      return {
        ...state,
        loading: true,
        update: null,
        errorUpdating: false,
      }
    case settingConstants.BRANDING_SUCCESS:
      return {
        ...state,
        loading: false,
        update: action.resp,
        errorUpdating: false,
      }
    case settingConstants.BRANDING_FAILURE:
      return {
        ...state,
        loading: false,
        update: null,
        errorUpdating: false,
      }
    case settingConstants.BRANDING_GET_REQ:
      return {
        ...state,
        loading: true,
        update: null,
        errorUpdating: false,
      }
    case settingConstants.BRANDING_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        update: action.resp,
        errorUpdating: false,
      }
    case settingConstants.BRANDING_GET_FAILURE:
      return {
        ...state,
        loading: false,
        update: null,
        errorUpdating: false,
      }
    default:
      return state
  }
}
