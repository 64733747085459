import React, { useEffect, useState } from "react";
import { Button, Table, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { confirmAlert } from "react-confirm-alert";
import ConfirmationPopup from "../../components/UI/ConfirmationPopup/confirmationPopup";

import {
  AiFillCheckCircle,
  AiOutlineShareAlt,
  AiOutlineReload,
  AiOutlineUserDelete,
  AiFillCloseCircle,
  AiTwotoneDelete,
} from "react-icons/ai";
import { myconfig } from "../../config/config";

import { AddParticipants } from "../../MeetingsSection/MeetDetails/Modal";
import { FaUserPlus, FaDownload } from "react-icons/fa";
import isEmpty from "is-empty";
import validation from "validator";
import { Formik, FieldArray } from "formik";
import ShareModal from "../../layoutSection/Modal/shareModal";
import Layout from "../../layoutSection";
import { webinarActions } from "../../Redux/_actions";
import Spinner from "../../components/UI/Spinner/Spinner";
import { durationTime, tsToDate, msToTime } from "../../helpers/helpers";
import Modal from "../../layoutSection/Modal";

import "../../layoutSection/css/treetable.scss";

const getRole = (type) => {
  switch (type) {
    case "MODERATOR": {
      return "Admin";
    }
    case "HOST": {
      return "Speaker";
    }
    case "VIEWER": {
      return "Attendee";
    }
    default: {
      return "Attendee";
    }
  }
};

// const initialValues = {
//   tag: [],
//   tagInput: "",
// };

const Participants = () => {
  const { webinarDetail, detailLoading } = useSelector(
    (state) => state.webinar
  );
  const { theme } = useSelector((state) => state.themeChange);
  const param = useParams();
  const dispatch = useDispatch();
  const [modal, setModal] = useState({
    addParticipants: false,
    shareURl: null,
  });

  useEffect(() => {
    if (!webinarDetail || webinarDetail?.meetingID !== param.id) {
      dispatch(webinarActions.webinarDetail(param.id));
    }
  }, []);

  // const submitTag = (values, setFieldValue, setFieldError) => {
  //   if (values.tag.includes(values.tagInput)) {
  //     setFieldError("tagInput", "data is already available");
  //   } else if (
  //     (values.tagInput.includes("@") && values.tagInput.includes(".")) ||
  //     (!isNaN(parseInt(values.tagInput)) && values.tagInput.length > 9)
  //   ) {
  //     let temp = values.tagInput;
  //     if (temp.includes(" ")) {
  //       temp = temp.split(" ");
  //       temp = temp.filter((e) => e);
  //     }
  //     setFieldValue("tag", values.tag.concat(temp));
  //     setFieldValue("tagInput", "");
  //   } else {
  //     setFieldError("tagInput", "enter data is not Email or Phone");
  //   }
  // };
  const setStateModal = (type, value) => {
    if (value) {
      setModal({ ...modal, [type]: value });
    } else {
      setModal({ ...modal, [type]: !modal[type] });
    }
  };

  const submitHandler = (value) => {
    let formData = {};
    formData.webinarID = param.id;
    formData.participantArr = value.participantArr;
    return formData;
  };

  // const ButtonSection = () => {
  //   if (webinarDetail?.isMeetingEnded === 1) {
  //     return (
  //       <button className="btnStyles bg-warning welcomeMsg">
  //         {" "}
  //         <FaDownload className="f_size_20 mr-1" /> Download CSV{" "}
  //       </button>
  //     );
  //   } else {
  //     return (
  //       <div>
  //         <button
  //           className="btnStyles mr-2 submitBtnColor welcomeMsg"
  //           onClick={() => setAddModal(true)}
  //         >
  //           {" "}
  //           <FaUserPlus className="f_size_20 mr-1" /> Add participants{" "}
  //         </button>
  //         <button className="btnStyles bg-warning welcomeMsg">
  //           {" "}
  //           <FaDownload className="f_size_20 mr-1" /> Download CSV{" "}
  //         </button>
  //       </div>
  //     );
  //   }
  // };

  const RowSection = () => {
    if (detailLoading && !webinarDetail) {
      return (
        <tr>
          {" "}
          <td colSpan={2}>
            {" "}
            <div className="text-center">
              <Spinner />
            </div>{" "}
          </td>{" "}
        </tr>
      );
    } else if (
      webinarDetail?.participant &&
      webinarDetail?.participant?.length > 0 &&
      webinarDetail?.isMeetingEnded !== 1
    ) {
      return webinarDetail?.participant.map((data) => (
        <tr key={`RowSection-${data.wid}`}>
          <td>{data.v}</td>
          <td>
            {data.n ? (
              <AiFillCheckCircle className="text-success f_size_22" />
            ) : (
              <span>
                <AiOutlineReload className="text-danger f_size_22 mr-1" />
                <button
                  className="btnBgLess f_size_22 share"
                  onClick={() =>
                    setStateModal(
                      "shareURl",
                      `${myconfig.serverURL}/join_meeting?mid=${param.id}${
                        data.p ? `&token=${data.p}` : ""
                      }`
                    )
                  }
                >
                  <AiOutlineShareAlt />{" "}
                </button>{" "}
                <button
                  className="btnBgLess text-danger f_size_22"
                  onClick={() => removeParticipant(data._id)}
                >
                  <AiOutlineUserDelete />{" "}
                </button>{" "}
                <button
                className="btnBgLess f_size_22 text-warning mx-3"
                onClick={() =>
                  dispatch(webinarActions.reInviteUser(data.wid, data.v))
                }
              >
                <i className="fas fa-redo f_size_15"></i>{" "}
              </button>
              </span>
            )}
          </td>
        </tr>
      ));
    } else if (webinarDetail?.report?.participants) {
      const keys = Object.keys(webinarDetail?.report?.participants);
      return keys.map((key, index) => {
        const data = webinarDetail?.report?.participants[key];
        return (
          <tr key={`RowSection-${data.uid}`}>
            <td>{index + 1}</td>
            <td>{data.name}</td>
            <td>{getRole(data.role)}</td>
            <td>
              <div>{msToTime(data.duration)}</div>
            </td>
            <td>{tsToDate(data.joined)}</td>
            <td>{tsToDate(data.left)}</td>
            <td>{data?.emojiCount || 0}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          {" "}
          <td colSpan={6}>
            <div className="resultNotFound  bg-transparent welcomeMsg f_size_16">{`No ${
              webinarDetail?.isMeetingEnded === 1 ? "Attenders" : "Participants"
            } Available `}</div>{" "}
          </td>{" "}
        </tr>
      );
    }
  };

  const TableSection = () => {
    return (
      <Table
        hover
        variant={theme ? "" : "dark"}
        className={`${theme ? "sideBar" : ""} transition03 treetable`}
      >
        <thead>
          {webinarDetail?.isMeetingEnded === 1 ? (
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Role</th>
              <th>Duration</th>
              <th>Joined</th>
              <th>Left</th>
              <th>Emoji</th>
            </tr>
          ) : (
            <tr>
              <th>Invited</th>
              <th>Action</th>
            </tr>
          )}
        </thead>
        <tbody>
          <RowSection />
        </tbody>
      </Table>
    );
  };

  const getHeader = () => {
    return (
      <tr>
        <th>Invited</th>
        {webinarDetail?.isMeetingEnded !== 1 && <th>Action</th>}
      </tr>
    );
  };

  const removeParticipant = (participantID) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmationPopup
            message={"Are you sure want to remove this Participant ?"}
            noBtnAction={onClose}
            noBtnText="No"
            yesBtnText="Yes, Remove AnyWay!"
            yesBtnAction={() =>
              dispatch(webinarActions.deleteInviteUser(participantID))
            }
          />
        );
      },
    });
  };

  const GetRow = () => {
    if (detailLoading) {
      return (
        <tr>
          {" "}
          <td colSpan={2}>
            {" "}
            <div className="text-center">
              <Spinner />
            </div>{" "}
          </td>{" "}
        </tr>
      );
    } else if (webinarDetail?.participant?.length > 0) {
      return webinarDetail?.participant?.map((data) => (
        <tr key={`member-${data._id}`}>
          <td>{data.v}</td>
          {webinarDetail?.isMeetingEnded !== 1 && (
            <td className="text-center noteText">
              {" "}
              <button
                className="btnBgLess f_size_22 share"
                onClick={() =>
                  setStateModal(
                    "shareURl",
                    `${myconfig.serverURL}/join_meeting?mid=${param.id}${
                      data.p ? `&token=${data.p}` : ""
                    }`
                  )
                }
              >
                <AiOutlineShareAlt />{" "}
              </button>{" "}
              <button
                className="btnBgLess f_size_22 text-warning mx-3"
                onClick={() =>
                  dispatch(webinarActions.reInviteUser(data.wid, data.v))
                }
              >
                <i className="fas fa-redo f_size_15"></i>{" "}
              </button>
              <button
                className="btnBgLess text-danger f_size_22"
                onClick={() => removeParticipant(data._id)}
              >
                <AiTwotoneDelete />{" "}
              </button>{" "}
            </td>
          )}
        </tr>
      ));
    } else {
      return (
        <tr>
          {" "}
          <td colSpan={2}>
            <div className="resultNotFound  bg-transparent welcomeMsg f_size_16">
              No Participants Available{" "}
            </div>{" "}
          </td>{" "}
        </tr>
      );
    }
  };

  return (
    <Layout
      title={webinarDetail?.name || "Participants"}
      goBack="/webinars"
      list="webinar"
      active="Participants"
    >
      <div className="container-fluid s_wave_content_part pl-3 pr-3 participants">
        <div className="between">
          <Button
            className="headerBtn submitBtnColor border-0 mr-3 mt-1"
            onClick={() => setStateModal("addParticipants")}
          >
            <FaUserPlus className="mr-2 f_size_18" />
            Add Participants
          </Button>
        </div>
        <div className="mt-5">
          <TableSection />
        </div>
        {modal.addParticipants && (
          <AddParticipants
            show={modal.addParticipants}
            toggle={() => setStateModal("addParticipants")}
            meetId={param.id}
            onSubmit={(value) => {
              dispatch(webinarActions.addMoreParticipant(submitHandler(value)));
            }}
          />
        )}
        {!isEmpty(modal.shareURl) && (
          <ShareModal
            show={!isEmpty(modal.shareURl)}
            toggle={() => setModal({ ...modal, shareURl: null })}
            shareURl={modal.shareURl}
          />
        )}
      </div>
    </Layout>
  );
};

export default Participants;
