import React, { Component } from 'react';
import "./Login.css"

import { Link, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import decode from 'jwt-decode';
import userconfigs from '../../helpers/closures';
import { userActions, alertActions } from '../../Redux/_actions';
import OtpField from '../../components/UI/OTP/OtpField';
import MD5 from "crypto-js/md5";
// import GoogleLogin from 'react-google-login';
import GoogleLogin from "../CustomHooks/GoogleLogin";
import { Spinner } from 'reactstrap';
import { google_config, passwordToBreak, encDataKey } from '../../config/config';

import JoinMeeting from '../../Meetings/JoinMeeting';
import { userService } from './../../Redux/_services';
const crypto = require("crypto-js");

//const crypto = require("crypto");

var interval = "";

class Login extends Component {

  constructor(props) {
    super(props);

    // reset login status
    //this.props.logout();

    this.state = {
      username: '',
      password: '',
      otp: '',
      otp_counter: 30,
      showOtpBtn: false,
      submitted: false,
      otp_input: false,
      pass_input: true,
      usernameBlank: false,
      verified: "",
      type: "",
      forgotClick: false,
      isDisable: false,
      csrf_token: '',
      isJoinNowModal: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOTPSubmit = this.handleOTPSubmit.bind(this);
    this.handleGetOTP = this.handleGetOTP.bind(this);
    this.handleEnterPassword = this.handleEnterPassword.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    if (name === 'username') {
      this.setState({
        verified: "",
        type: "",
        forgotClick: false
      })

      var specials = /^[a-zA-Z0-9@\-._\s]*$/;
      if (specials.test(value)) {
        this.setState({
          [name]: value
        });
      }
    }
    else {
      this.setState({
        [name]: value
      });
    }


  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({
      submitted: true,
      forgotClick: false
    });
    const { username, password } = this.state;

    let md5Hash = MD5(password).toString();
      md5Hash = md5Hash + passwordToBreak + this.state.csrf_token;
      const key = crypto.enc.Utf8.parse(encDataKey);
      md5Hash = crypto.AES.encrypt(md5Hash, key,
        {
          mode: crypto.mode.ECB,
          padding: crypto.pad.Pkcs7,
        }).toString();

    this.props.login(username, md5Hash, this.state.csrf_token)
  }

  handleOTPSubmit(e) {
    e.preventDefault();

    this.setState({
      submitted: true,
      forgotClick: false
    });
    const { username, otp } = this.state;
    if (username && otp) {
      this.props.loginWithOTP(username, otp);
    }
  }

  handleVerifyOtp = (otp) => {
    this.setState({
      otp: otp.otp
    })
  }

  // validatePhoneNumber = (number) => {
  //   let isValidPhoneNumber = validator.isMobilePhone(number)
  //   if (isValidPhoneNumber) {
  //     if (number.length !== 10) {
  //       isValidPhoneNumber = false;
  //     }
  //   }
  //   return (isValidPhoneNumber)
  // }

  handleEnterPassword(e) {
    e.preventDefault();
    //const {username} = this.state;
    // if (this.validatePhoneNumber(mobile, 'en-IN')) {
    //if (username !== "") {
    this.setState({
      otp_input: false,
      pass_input: true,
      usernameBlank: false,
      otp_counter: 30
    })
    this.countInterval('pass')
    //}
  }

  countInterval(type) {
    var counter = 30;
    if (type === 'pass') {
      this.handleInterval('stop', counter, interval)
    } else {
      interval = setInterval(() => {
        counter--;
        this.setState({
          otp_counter: counter
        })
        // Display 'counter' wherever you want to display it.

        this.handleInterval('continue', counter, interval);
      }, 1000);
    }
  }

  handleInterval(process, counter, interval) {
    if (process === 'stop' || counter === 0) {
      // Display a login box
      this.setState({
        showOtpBtn: true,
        otp_counter: 30
      });
      clearInterval(interval);

    }
  }

  handleGetOTP(e) {
    e.preventDefault();
    const { username } = this.state;
    // if (this.validatePhoneNumber(mobile, 'en-IN')) {
    if (username !== "") {
      this.setState({
        otp_input: true,
        pass_input: false,
        usernameBlank: false,
        showOtpBtn: false,
      })

      if (username) {
        this.props.getLoginOTP(username)
        this.countInterval('otp')
      }
    }
    else {
      this.setState({
        usernameBlank: true
      })
    }
  }

  resendVerification = (username, e) => {
    e.preventDefault();
    if (username !== "") {
      this.props.resendEmailLink(username)
    }
  }

  forgetPassword = () => {
    const { username } = this.state;
    if (username !== "") {
      this.props.loginDetails(username)
    }
    else {
      this.setState({
        forgotClick: true
      })
    }
  }

  forgetPasswordPage = () => {
    this.props.history.push("/forget_password")
  }

  // componentDidUpdate = (prevProps, prevState) => {
  //   if (prevProps.user !== this.props.user) {
  //     // if (typeof this.props.user !== "undefined" && typeof this.props?.user?.verified !== "undefined") {
  //     //   this.setState({
  //     //     verified: "no",
  //     //     type: this.props.user.type
  //     //   })
  //     // }
  //     else {
  //       this.setState({
  //         verified: "",
  //         type: ""
  //       })
  //     }
  //   }
  // }

  responseGoogle = (response) => {
    this.props.authGoogle(response.profileObj)
  }

  responseErrorGoogle = (response) => {
    if (response.error !== "idpiframe_initialization_failed") {
      if (response.error !== "popup_closed_by_user") {
        this.props.errorMsg(response.error)
      }
      userconfigs.setItem('gld', false)
    }
    else {
      console.log("Google Response:", response)
      this.props.infoMsg('Please enable 3rd party cookies in your browser to continue login with google.')
      //this.props.infoMsg(response.details)
      this.setState({ isDisable: true })
      userconfigs.setItem('gld', true)
    }
  }

  componentDidMount = () => {
    userService.getCsrf().then(res => {
      this.setState({ ...this.state, csrf_token: res.csrfToken })
    })
    if (typeof userconfigs.getItem('gld') !== "undefined" && userconfigs.getItem('gld') !== "null" && userconfigs.getItem('gld') !== null) {
      if (userconfigs.getItem('gld') === "true") {
        this.setState({ isDisable: true })
      }
    }
  }

  openJoinNow = () => {
    this.setState({
      isJoinNowModal: true
    })
  }

  updateJoinPopUpState = () => {
    this.setState({
      isJoinNowModal: false
    })
  }

  successJoinHandler = () => {
    this.setState({
      isJoinNowModal: false
    })
  }

  errorJoinHandler = () => {
    this.setState({
      isJoinNowModal: false
    })
  }

  render() {
    //const {  } = this.props;
    const { forgotClick, verified, type, username, password, otp, submitted, otp_input, pass_input, usernameBlank, showOtpBtn, isDisable } = this.state;
    const { loggingIn, authLoading } = this.props;

    console.log('disable', isDisable)

    let userInfo = decode(userconfigs.getItem('jwt'))

    if (userInfo.loginStatus === 1) {
      return <Redirect to='/meetings' />
    }
    else {

      return (

        <div className="divya_login_container">
          {
            this.state.isJoinNowModal ?
              <JoinMeeting
                isOpen={this.state.isJoinNowModal}
                message="Join Your Meeting"
                updatePopUpState={this.updateJoinPopUpState}
                successHandler={this.successJoinHandler}
                errorHandler={this.errorJoinHandler}
                history={this.props.history}
              /> :
              null
          }
          <div className="container-fluid divya-header">
            <div className="container">
              <div className="row divya-header-row">
                <div className="col-md-4 col-sm-4 col-4"><a href="https://www.digitalindia.gov.in/" target="_blank"><img src="divya/login/govt-india-digital-india-logo.png" width="80" alt="Digital India" /></a></div>
                <div className="col-md-4 col-sm-4 col-4 text-center"><Link to="/"><img src="divya/login/divya-logo-dark.png" width="110" alt="Digital India Video Yantriki Application" /></Link></div>
                <div className="col-md-4 col-sm-4 col-4 logo-right"><a href="https://negd.gov.in/" target="_blank"><img src="divya/login/logo-negd.png" width="100" alt="National e-Governance Division" /></a>
                </div>
              </div>
            </div>

          </div>

          <div className="container">
            <div className="row login-container">
              <div class="col-7 col-md-6 align-items-center justify-content-center login-left-img">
                <img src="divya/login/login-img.svg" class="img-fluid" alt="" />
              </div>
              <div className="col-5 col-md-6  col-12 align-self-center  align-self-end">
                <div className="lgn_frm_bx">
                  <div className="w_login_heading">
                    <h4>Login</h4>
                    <p>Use your credentials to access your account.</p>
                  </div>
                  <form name="form" onSubmit={otp_input ? this.handleOTPSubmit : this.handleSubmit}>

                    <div className="_wform_group">

                      <div className={'_wform_group_prepend' + (((submitted && !username) || forgotClick || usernameBlank) ? ' has-error' : '')}>
                        <span className="_wform__group_text">
                          <i className="fas fa-user"></i>
                        </span>
                        <input type="text" className="_wform_form_cntrl" name="username" value={username} onChange={this.handleChange} placeholder="Enter Email / Phone" />

                      </div>
                    </div>

                    {((submitted && !username && !usernameBlank) || forgotClick) ?
                      <div className="help-block error_msg_56">Username is required</div> : ""
                    }
                    {usernameBlank ? <div className="help-block error_msg_56">Provide a Valid Username.</div> : ""}

                    {pass_input ?
                      <div>
                        <div className="_wform_group mb-0">

                          <div className={'_wform_group_prepend' + (submitted && !password && !forgotClick ? ' has-error' : '')}>
                            <span className="_wform__group_text">
                              <i className="fas fa-lock"></i>
                            </span>
                            <input type="password" placeholder="Enter Password " className="_wform_form_cntrl" name="password" value={password} onChange={this.handleChange} />


                          </div>
                        </div>
                        {submitted && !password && !forgotClick && pass_input &&
                          <div className="help-block error_msg_56">Password is required</div>
                        }
                      </div>
                      : ""}



                    {otp_input ?
                      <div>
                        <div className="_wform_group">
                          <label htmlFor="otp">OTP <span>*</span></label>
                          <div className={'_wform_group_prepend' + (submitted && !password ? ' has-error' : '')}>
                            <span className="_wform__group_text">
                              <i className="fas fa-lock"></i>
                            </span>
                            <OtpField handleOtp={this.handleVerifyOtp.bind(this)} />


                          </div>
                        </div>
                        {submitted && !otp && otp_input &&
                          <div className="help-block error_msg_56 error_msg_56">OTP is required</div>}
                      </div>

                      : ""}



                    <div className="_grp_rw extrnal_link mt-0">
                      {pass_input ?

                        <div className="col-12 mt-2">
                          <div className="row">
                            <span className="col-6"><button type="button" className="btn btn-link p-0" onClick={this.handleGetOTP}> Login with OTP</button></span>
                            <span className="col-6 text-right"><button type="button" onClick={this.forgetPasswordPage} className="txt_theme btn btn-link p-0">Forgot Password?</button></span>
                          </div>
                        </div>
                        :
                        <div className="row col-sm-12">
                          <span className="col-sm-6" ><button type="button" className="btn btn-link p-0" onClick={this.handleEnterPassword}> Login with Password</button></span>
                          <span className="col-sm-6" ><div className="txt_theme btn btn-link p-0"> {showOtpBtn ? <button type="button" className="btn btn-link p-0" onClick={this.handleGetOTP}>Resend OTP</button> : <span className='resend_otp_timer btn btn-link p-0' style={{ fontSize: "12px", width: "100%" }}>Resend OTP in : {this.state.otp_counter}s </span>}</div></span>
                        </div>
                      }

                    </div>
                    {(verified !== "" && type === "email") ?
                      <div className="mt-0 alert alert-danger text-center col-12 p-0 pt-1 pb-1">
                        <small className="pl-2 pr-2 ">For verification link click to <button type="button" onClick={this.resendVerification.bind(this, username)} className="btn btn-sm btn-danger p-0 pl-1 pr-1">Resend Again</button></small>
                      </div>
                      : ""}

                    <div className="btn-flex mt-2">
                      {loggingIn || authLoading ?
                        <>
                          <button type="button" className="btn btn-primary btn-login" ><Spinner size="sm" /> Loging...</button>
                          <button type="button" className="btn btn-lg btn-outline-primary btn-join-meeting" onClick={() => this.openJoinNow()}> <i className="fas fa-video"></i> Join Meeting</button>
                        </>
                        :
                        <>
                          <button type="submit" className="btn btn-primary btn-login ">Login</button>
                          <button type="button" className="btn btn-lg btn-outline-primary btn-join-meeting" onClick={() => this.openJoinNow()}> <i className="fas fa-video"></i> Join Meeting</button>
                        </>
                      }




                      {/* 
                  {loggingIn || authLoading ? "" :
                      <>
                          <div className="text-center text-secondary or-text">OR</div>
                          <div className="text-center btn-google-signin">
                              <GoogleLogin
                                  clientId={google_config.gClientID}
                                  buttonText="Login With Google"
                                  className="btn btn-default"
                                  onSuccess={this.responseGoogle.bind(this)}
                                  onFailure={this.responseErrorGoogle}
                                  cookiePolicy={'single_host_origin'}
                                  disabled={isDisable}
                                  theme={'dark'}
                              />
                          </div>
                      </>
                  }

                 */}
                    </div>

                    {/* <div className="_grp_rw ftr_tag_log">
                     Don't have an account? <Link to="/signup" className="txt_theme"> Register here</Link>
                  </div> */}

                    <div className="row icons mt-5">
                      {/* <div className="col-7">
                  <button type="button" className="btn btn-lg btn-primary btn-join-meeting" onClick={() => this.openJoinNow()}> <i className="fas fa-video"></i> Join Meeting</button>
                </div> */}
                      <div className="col align-self-center d-flex justify-content-center">
                        <a href="#"><img src="divya/login/google-play-app.png" alt="" width="150" /></a>
                        <a href="#" className="ml-2"><img src="images/login/apple-app.png " width="150" alt="" /></a>
                      </div>

                    </div>

                  </form>
                </div>
              </div>

            </div>
            <div className="footer-bot-link">
              Powered by  <a href="https://wave.sarv.com/" target="_blank"> SARVWAVE </a>  in association with NeGD
            </div>
          </div>
        </div>

      )
    }
  }
}

function mapState(state) {
  const { loggingIn, user, authLoading, authSuccess } = state.authentication;
  return {
    loggingIn,
    user,
    authLoading,
    authSuccess
  };
}

const actionCreators = {
  login: userActions.login,
  getLoginOTP: userActions.getLoginOTP,
  loginWithOTP: userActions.loginWithOTP,
  resendEmailLink: userActions.resendEmailLink,
  loginDetails: userActions.loginDetails,
  logout: userActions.logout,
  authGoogle: userActions.authGoogle,
  errorMsg: alertActions.error,
  infoMsg: alertActions.info
};

const connectedLoginPage = withRouter(connect(mapState, actionCreators)(Login));
export { connectedLoginPage as Login };