import { myconfig, siteTokens } from '../../config/config';
import axios from 'axios';
import userconfigs from '../../helpers/closures';

import { handleResponse, errorCheck, handleJWTError } from './common'

export const settingService = {
  UpdateBranding,
  GetBranding,
  DeleteBranding
};

function GetBranding() {
  let axiosRequestOptions = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': userconfigs.getItem('jwt'),
    }
  };

  return axios.get(`${myconfig.apiURL}getSettings`, axiosRequestOptions)
    .then(handleResponse)
    .then(msg => {
      return msg;
    })
    .catch((error) => {
      if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
        handleJWTError();
      } else {
        return Promise.reject(errorCheck(error));
      }
    });
}


function UpdateBranding(data) {
  let axiosRequestOptions = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': userconfigs.getItem('jwt'),
    }
  };

  return axios.post(`${myconfig.apiURL}updateBranding`, data, axiosRequestOptions)
    .then(handleResponse)
    .then(user => {
      return user;
    })
    .catch((error) => {
      if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
        handleJWTError();
      } else {
        return Promise.reject(errorCheck(error));
      }
    });
}


function DeleteBranding(data) {
  let axiosRequestOptions = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': userconfigs.getItem('jwt'),
    }
  };

  return axios.post(`${myconfig.apiURL}deleteSettings`, data, axiosRequestOptions)
    .then(handleResponse)
    .then(msg => {
      return msg;
    })
    .catch((error) => {
      if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
        handleJWTError();
      } else {
        return Promise.reject(errorCheck(error));
      }
    });
}

