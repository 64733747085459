import { companyConstants } from './../_constants'

const initialState = {
  loadingStatus: false,
  registerCompanyId: '',
  logoURL: '',
  brandingData: {},
  declineLoading: false,
  declined: false,
  acceptLoading: false,
  accepted: false,
  exitLoading: false,
  exited: false
}

export function company (state = initialState, action) {
  switch (action.type) {
    case companyConstants.COMPANY_REGISTER_REQUEST:
      return {
        ...state,
        loadingStatus: true,
        isDepartmentAdded: false
      }
    case companyConstants.COMPANY_REGISTER_SUCCESS:
      return {
        ...state,
        loadingStatus: false,
        registerCompanyId: action.resp
      }
    case companyConstants.COMPANY_REGISTER_FAILURE:
      return {
        ...state,
        loadingStatus: false
      }

    case companyConstants.DECLINE_REQUEST:
      return {
        ...state,
        declineLoading: true,
        declined: false
      }

    case companyConstants.DECLINE_SUCCESS:
      return {
        ...state,
        declineLoading: false,
        declined: true
      }
    case companyConstants.DECLINE_FAILURE:
      return {
        ...state,
        declineLoading: false,
        declined: false
      }

    case companyConstants.EXIT_REQUEST:
      return {
        ...state,
        exitLoading: true,
        exited: false
      }

    case companyConstants.EXIT_SUCCESS:
      return {
        ...state,
        exitLoading: false,
        exited: true
      }
    case companyConstants.EXIT_FAILURE:
      return {
        ...state,
        exitLoading: false,
        exited: false
      }

    case companyConstants.ACCEPT_REQUEST:
      return {
        ...state,
        acceptLoading: true,
        accepted: false
      }

    case companyConstants.ACCEPT_SUCCESS:
      return {
        ...state,
        acceptLoading: false,
        accepted: true
      }
    case companyConstants.ACCEPT_FAILURE:
      return {
        ...state,
        acceptLoading: false,
        accepted: false
      }

    case companyConstants.COMPANY_LOGO_REQUEST:
      return {
        ...state,
        loadingStatus: true
      }

    case companyConstants.COMPANY_LOGO_REQUEST:
      return {
        ...state,
        loadingStatus: true
      }

    case companyConstants.COMPANY_LOGO_SUCCESS:
      return {
        ...state,
        loadingStatus: false,
        logoURL: action.resp
      }
    case companyConstants.COMPANY_LOGO_FAILURE:
      return {
        ...state,
        loadingStatus: false,
        logoURL: ''
      }

    case companyConstants.COMPANY_BRANDING_INFO_REQUEST:
      return {
        ...state,
        loadingStatus: true
      }
    case companyConstants.COMPANY_BRANDING_INFO_SUCCESS:
      return {
        ...state,
        loadingStatus: false,
        brandingData: action.resp
      }
    case companyConstants.COMPANY_BRANDING_INFO_FAILURE:
      return {
        ...state,
        loadingStatus: false,
        brandingData: {}
      }
    default:
      return state
  }
}
