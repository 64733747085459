import React, { Component } from 'react';
import DatePicker, { registerLocale } from "react-datepicker";
import { TabContent, TabPane, Nav, NavItem, NavLink, Spinner } from 'reactstrap';
import moment from 'moment';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import classnames from 'classnames';
//import Header from '../../../Header/Header';
import Button from '../../../../components/UI/Button/Button'
import { scheduleActions, conferenceActions } from '../../../../Redux/_actions';
import jwt_decode from "jwt-decode";
import userconfigs from '../../../../helpers/closures';
import "react-datepicker/dist/react-datepicker.css";

import "./../Schedule.css";
import en from "date-fns/locale/el";
registerLocale("en", en); 

class EditSchedule extends Component {

	constructor(props) {
		super(props);
		this.state = {
			userInfo: {},
			meetingInfo: {},
			scheduleForm: {
				meetName: {
					value: "",
					validation: {
						required: true,
						minLength: 5,
						maxLength: 50
					},
					error: null,
					valid: false
				},
				start_date: {
					value: null,
					validation: {
						required: true
					},
					error: null,
					valid: false
				},
				end_date: {
					value: null,
					validation: {
						required: true
					},
					error: null,
					valid: false
				},
				password: {
					value: null,
					validation: {
						required: true,
						minLength: 6,
						maxLength: 16
					},
					error: null,
					valid: true,
					hide: true
				},
				dynamic_password: {
					value: false,
					validation: {
						required: true
					},
					error: null,
					valid: true,
					hide: true
				},
				waiting_room: {
					value: false,
					validation: {
						required: true
					},
					error: null,
					valid: true
				},
				start_mute: {
					value: true,
					validation: {
						required: true
					},
					error: null,
					valid: true
				},
				camera_access: {
					value: false,
					validation: {
						required: true
					},
					error: null,
					valid: true
				},
				auto_record: {
					value: false,
					validation: {
						required: true
					},
					error: null,
					valid: true
				},
				allow_admin_unmute_user: {
					value: true,
					validation: {
						required: true
					},
					error: null,
					valid: true
				},
				allow_start_or_stop_recording: {
					value: true,
					validation: {
						required: true
					},
					error: null,
					valid: true
				},
				join_password: {
					value: false,
					validation: {
						required: true,
						rField: 'password'
					},
					error: null,
					valid: true
				},
				participant: {
					value: null,
					validation: {
						required: true
					},
					error: null,
					valid: true
				},
				check_audio: {
					value: false,
					validation: {
						required: true
					},
					error: null,
					valid: true
				},
				auto_share_webcam: {
					value: false,
					validation: {
						required: true
					},
					error: null,
					valid: true
				},
				hide_presentation: {
					value: true,
					validation: {
						required: true
					},
					error: null,
					valid: true
				},
				only_registered: {
					value: false,
					validation: {
						required: true
					},
					error: null,
					valid: true
				},
				join_without_host: {
					value: false,
					validation: {
						required: true
					},
					error: null,
					valid: true,
					hide: false
				}


			},
			isFormValid: false,
			loading: false,
			error: false,
			errorMessage: null,
			redirectToDashboard: false,
			activeTab: "1",
			isValidationShow: false
		}
	}



	checkValidation = (label, name, value, rules) => {

		let isValid = true;
		if (!rules) {
			return true;
		}

		let filedValue = null

		if (rules.match) {
			filedValue = this.state.scheduleForm[rules.match].value || null;
		}

		if (rules.required && value.trim() === "") {
			isValid = label + " is required field."
		} else if (rules.minLength && value.trim().length > 0 && (value.trim().length < rules.minLength)) {
			isValid = label + " must be at least " + rules.minLength + " characters long."
		} else if (rules.maxLength && value.trim().length > 0 && (value.trim().length > rules.maxLength)) {
			isValid = label + " can be max " + rules.maxLength + " characters long."
		} else if (rules.match && filedValue && filedValue !== value) {
			isValid = label + " is miss matched."
		} else {
			isValid = true;
		}

		return isValid;

	}
	shouldComponentUpdate(nextProps, nextState) {
		
		if (this.props.IsScheduleEdited !== nextProps.IsScheduleEdited) {
			this.props.history.push("/meeting_history/" + this.props.match.params.id)
		}
		return true;
	}

	componentDidMount() {
		const meetingID = this.props.match.params.id;
		this.setState({ 'meetingID': meetingID })
		this.props.meetingDetailFun(meetingID);

	}
	componentDidUpdate = (prevProps, prevState) => {
		if (prevProps.meetingDetail !== this.props.meetingDetail) {
			console.log(this.props.meetingDetail)
			if (this.props.meetingDetail !== null) {
				const { 
					name,
					startTS,
					autoStartRecording,
					only_registered,
					webcamsOnlyForModerator,
					join_without_host,
					waitingRoom,
					allowStartStopRecording,
					participant_count,
					participant,
					dynamic_password,
					end_date,
					end_time,
					join_password
				} = this.props.meetingDetail;
				
				let participantsInfo = null;
				if (participant_count > 0) {
					participant.forEach(item => {
						const isDashed = item.cc !== undefined ? '-' : '';
						const cc = item.cc !== undefined && item.cc.includes('+') ? item.cc : `+${item.cc}`
						if (participantsInfo !== null) {
							participantsInfo = item.v.includes('@') ? `${participantsInfo}
${item.v}`:`${participantsInfo}
${cc}${isDashed}${item.v}`
						} else {
							participantsInfo = item.v.includes('@') ? item.v : `${cc}${isDashed}${item.v}`;
						}
					});
				}
				this.setState({
					isFormValid: true,
					scheduleForm: {
						meetName: {
							value: name,
							validation: {
								required: true,
								minLength: 5,
								maxLength: 50
							},
							error: null,
							valid: true
						},
						start_date: {
							value: startTS.toString().length > 10 ? new Date(startTS) : new Date(startTS * 1000),
							validation: {
								required: true
							},
							error: null,
							valid: true
						},
						end_date: {
							value: end_date !== undefined && end_time !== "Invalid date" ? new Date(moment(end_date,'DD-MM-YYYY').format('YYYY-MM-DD')+' '+end_time) : null,
							validation: {
								required: true
							},
							error: null,
							valid: true
						},
						password: {
							value: null,
							validation: {
								required: true,
								minLength: 6,
								maxLength: 16
							},
							error: null,
							valid: true,
							hide: true
						},
						dynamic_password: {
							value: dynamic_password,
							validation: {
								required: true
							},
							error: null,
							valid: true,
							hide: !dynamic_password
						},
						waiting_room: {
							value: waitingRoom === 0 ? false : true,
							validation: {
								required: true
							},
							error: null,
							valid: true
						},
						start_mute: {
							value: true,
							validation: {
								required: true
							},
							error: null,
							valid: true
						},
						camera_access: {
							value: webcamsOnlyForModerator,
							validation: {
								required: true
							},
							error: null,
							valid: true
						},
						auto_record: {
							value: autoStartRecording,
							validation: {
								required: true
							},
							error: null,
							valid: true
						},
						allow_admin_unmute_user: {
							value: true,
							validation: {
								required: true
							},
							error: null,
							valid: true
						},
						allow_start_or_stop_recording: {
							value: allowStartStopRecording,
							validation: {
								required: true
							},
							error: null,
							valid: true
						},
						join_password: {
							value: join_password,
							validation: {
								required: true,
								rField: 'password'
							},
							error: null,
							valid: true
						},
						participant: {
							value: participantsInfo,
							validation: {
								required: true
							},
							error: null,
							valid: true
						},
						check_audio: {
							value: false,
							validation: {
								required: true
							},
							error: null,
							valid: true
						},
						auto_share_webcam: {
							value: false,
							validation: {
								required: true
							},
							error: null,
							valid: true
						},
						hide_presentation: {
							value: true,
							validation: {
								required: true
							},
							error: null,
							valid: true
						},
						only_registered: {
							value: only_registered,
							validation: {
								required: true
							},
							error: null,
							valid: true
						},
						join_without_host: {
							value: join_without_host,
							validation: {
								required: true
							},
							error: null,
							valid: true,
							hide: false
						}


					}
				})
			}
		}
	}


	onChangeHandler = (event) => {
		const filedName = event.target.name;
		const filedLabel = event.target.title;
		let filedValue = event.target.value;

		let specials = /^[a-zA-Z0-9\s]*$/;

		if (filedName === "meetName") {
			if (specials.test(filedValue)) {
				filedValue = filedValue;
			} else {
				filedValue = this.state.scheduleForm['meetName'].value;
			}
		}


		let toggleValue = null;
		let oldScheduleForm = { ...this.state.scheduleForm };

		let updateScheduleForm = { ...oldScheduleForm[filedName] }

		let toogleArray = [
			"waiting_room",
			"start_mute",
			"camera_access",
			"auto_record",
			"allow_admin_unmute_user",
			"allow_start_or_stop_recording",
			"join_password",
			"dynamic_password",
			"check_audio",
			"auto_share_webcam",
			"hide_presentation",
			"only_registered",
			"join_without_host"
		]

		if (toogleArray.includes(filedName) === true) {
			updateScheduleForm.value = updateScheduleForm.value === true ? false : true
			toggleValue = updateScheduleForm.value === true ? "on" : "off";
		} else {
			updateScheduleForm.value = filedValue;
		}


		if (filedName === "join_password" && toggleValue === "on") {
			let updateNewState = { ...oldScheduleForm['password'] }
			updateNewState.value = null;
			updateNewState.valid = false;
			updateNewState.hide = false;
			oldScheduleForm['password'] = updateNewState;

			let updateDynamicPass = { ...oldScheduleForm['dynamic_password'] }
			updateDynamicPass.value = false;
			updateDynamicPass.valid = true;
			updateDynamicPass.hide = false;
			oldScheduleForm['dynamic_password'] = updateDynamicPass;

			let updateParticipantPass = { ...oldScheduleForm['participant'] }
			updateParticipantPass.value = updateParticipantPass.value || null;
			updateParticipantPass.valid = true;
			oldScheduleForm['participant'] = updateParticipantPass;
		}
		if (filedName === "join_password" && toggleValue === "off") {
			let updateNewState = { ...oldScheduleForm['password'] }
			updateNewState.value = null;
			updateNewState.valid = true;
			updateNewState.hide = true;
			oldScheduleForm['password'] = updateNewState;

			let updateDynamicPass = { ...oldScheduleForm['dynamic_password'] }
			updateDynamicPass.value = false;
			updateDynamicPass.valid = true;
			updateDynamicPass.hide = true;
			oldScheduleForm['dynamic_password'] = updateDynamicPass;

			let updateParticipantPass = { ...oldScheduleForm['participant'] }
			updateParticipantPass.value = updateParticipantPass.value || null;
			updateParticipantPass.valid = true;
			oldScheduleForm['participant'] = updateParticipantPass;
		}




		if (filedName === "dynamic_password" && toggleValue === "on") {
			let updateNewState = { ...oldScheduleForm['password'] }
			updateNewState.value = null;
			updateNewState.valid = true;
			updateNewState.hide = true;
			oldScheduleForm['password'] = updateNewState;


			let updateParticipant = { ...oldScheduleForm['participant'] }
			updateParticipant.value = updateParticipant.value || null;
			updateParticipant.valid = updateParticipant.value ? true : false;
			oldScheduleForm['participant'] = updateParticipant;

		}
		if (filedName === "dynamic_password" && toggleValue === "off") {
			let updateNewState = { ...oldScheduleForm['password'] }
			updateNewState.value = null;
			updateNewState.valid = false;
			updateNewState.hide = false;
			oldScheduleForm['password'] = updateNewState;

			let updateParticipant = { ...oldScheduleForm['participant'] }
			updateParticipant.value = updateParticipant.value || null;
			updateParticipant.valid = true;
			oldScheduleForm['participant'] = updateParticipant;
		}

		if (filedName === "waiting_room" && toggleValue === "on") {
			let updateNewState = { ...oldScheduleForm['join_without_host'] }
			updateNewState.value = false;
			updateNewState.valid = true;
			updateNewState.hide = true;
			oldScheduleForm['join_without_host'] = updateNewState;
		}

		if (filedName === "waiting_room" && toggleValue === "off") {
			let updateNewState = { ...oldScheduleForm['join_without_host'] }
			updateNewState.value = false;
			updateNewState.valid = true;
			updateNewState.hide = false;
			oldScheduleForm['join_without_host'] = updateNewState;
		}


		let checkValidation = this.checkValidation(filedLabel, filedName, filedValue, updateScheduleForm.validation);
		if (checkValidation === true) {
			updateScheduleForm.valid = true
			updateScheduleForm.error = null
		} else {
			updateScheduleForm.valid = false
			updateScheduleForm.error = checkValidation
		}

		oldScheduleForm[filedName] = updateScheduleForm;

		let isValidForm = true;
		for (let key in oldScheduleForm) {
			//isValidForm = oldScheduleForm[key].valid && isValidForm
			if (toogleArray.includes(key) !== true) {
				isValidForm = oldScheduleForm[key].valid && isValidForm
			}
		}

		this.setState({ scheduleForm: oldScheduleForm, isFormValid: isValidForm });
	}

	checkStartDateGreaterThanFromNow(dateTime) {
		const date = moment(dateTime)
		const now = moment();
		if (now > date) {
			let scheduleForm = { ...this.state.scheduleForm };
			let startDate = { ...scheduleForm.start_date }
			startDate.tabIDvalid = false;
			startDate.error = "Start and End date time should be greater than Now";
			scheduleForm['start_date'] = startDate
			this.setState({ 'scheduleForm': scheduleForm, isFormValid: false })
			return false
		} else {
			return true
		 }
	}

	checkForEndDate(dateTime) {
		if(dateTime === null){
			let scheduleForm = { ...this.state.scheduleForm };
			let endDate = { ...scheduleForm.end_date }
			endDate.value = null;
			endDate.valid = false;
			endDate.error = "End date time should be grater then from Start Date";
			scheduleForm['end_date'] = endDate
			this.setState({ 'scheduleForm': scheduleForm, isFormValid: false })

			return false;
		}
		return true
	}



	submitEditScheduleForm = (event) => {

		event.preventDefault();

		if (this.state.isFormValid && this.checkStartDateGreaterThanFromNow(this.state.scheduleForm.start_date.value) && this.checkForEndDate(this.state.scheduleForm.end_date.value)) {

			this.setState({ 'isValidationShow': false })


			let startTimeStmp = null;
			if (this.state.scheduleForm['start_date'].value != null) {
				startTimeStmp = new Date(this.state.scheduleForm['start_date'].value).getTime();
			}

			let convertStartDate = moment(this.state.scheduleForm['start_date'].value).format('DD-MM-YYYY');

			let convertStartTime = moment(this.state.scheduleForm['start_date'].value).format("HH:mm");

			let convertEndDate = moment(this.state.scheduleForm['end_date'].value).format('DD-MM-YYYY');

			let convertEndTime = moment(this.state.scheduleForm['end_date'].value).format("HH:mm");

			let participantValue = null;
			if (this.state.scheduleForm['participant'].value !== null) {
				let textareaValue = this.state.scheduleForm['participant'].value;
				textareaValue = textareaValue.replace(/\r?\n/g, '/n');
				participantValue = textareaValue.split("/n")
			}
			

			let formData = {};
			formData.meetName = this.state.scheduleForm['meetName'].value;
			formData.meetingId = this.props.match.params.id;
			formData.start_date = convertStartDate
			formData.start_time = convertStartTime
			formData.end_date = convertEndDate
			formData.end_time = convertEndTime
			formData.password = this.state.scheduleForm['password'].value || null
			formData.room_type = this.state.scheduleForm['waiting_room'].value || false
			formData.join_password = this.state.scheduleForm['join_password'].value || false
			formData.dynamic_password = this.state.scheduleForm['dynamic_password'].value || false;
			formData.hide_presentation = this.state.scheduleForm['hide_presentation'].value || false
			formData.auto_share_webcam = this.state.scheduleForm['auto_share_webcam'].value || false
			formData.startTS = startTimeStmp;
			formData.participant = participantValue;
			formData.form_type = "basic";
			if (this.state.activeTab === "2") {
				formData.form_type = "advance";
				formData.allow_start_or_stop_recording = this.state.scheduleForm['allow_start_or_stop_recording'].value || false
				formData.allow_admin_unmute_user = this.state.scheduleForm['allow_admin_unmute_user'].value || false
				formData.auto_record = this.state.scheduleForm['auto_record'].value || false
				formData.camera_access = this.state.scheduleForm['camera_access'].value || false
				formData.start_mute = this.state.scheduleForm['start_mute'].value || false
				formData.check_audio = this.state.scheduleForm['check_audio'].value || false
				formData.hide_presentation = this.state.scheduleForm['hide_presentation'].value || false
				formData.auto_share_webcam = this.state.scheduleForm['auto_share_webcam'].value || false
				formData.only_registered = this.state.scheduleForm['only_registered'].value || false
				formData.join_without_host = this.state.scheduleForm['join_without_host'].value || false
			}

			if (this.state.activeTab === "1") {
				formData.allow_admin_unmute_user = true
				formData.allow_start_or_stop_recording = true
				formData.start_mute = true
			}

			
			this.props.editScheduleForm(formData);


		} else {
			this.setState({ 'isValidationShow': true })
		}


	}


	startDateInputChange = dateVal => {

		let currentTime = new Date().getTime();
		let selectTime = new Date(dateVal).getTime();
		let date = dateVal;
		if (currentTime > selectTime) {
			date = new Date(currentTime);
		}

		let endDateValue = this.state.scheduleForm['end_date'].value || null;

		let scheduleForm = { ...this.state.scheduleForm };
		let startDate = { ...scheduleForm.start_date }

		if (endDateValue == null && date == null) {

			startDate.value = null;
			scheduleForm['start_date'] = startDate
			this.setState({ 'scheduleForm': scheduleForm, isFormValid: false })

		} else if (endDateValue == null && date !== null) {

			startDate.value = date;
			startDate.valid = true
			startDate.error = null;
			scheduleForm['start_date'] = startDate


			let isValidForm = true;
			for (let key in scheduleForm) {
				isValidForm = scheduleForm[key].valid && isValidForm
			}
			this.setState({ 'scheduleForm': scheduleForm, isFormValid: isValidForm })


		} else if (endDateValue !== null && date === null) {

			startDate.value = null;
			scheduleForm['start_date'] = startDate

			this.setState({ 'scheduleForm': scheduleForm, isFormValid: false })

		} else {

			let startDateTime = new Date(date).getTime();
			let endDateTime = new Date(endDateValue).getTime();

			if (startDateTime < endDateTime) {

				startDate.value = date;
				startDate.valid = true
				startDate.error = null;
				scheduleForm['start_date'] = startDate
				let isValidForm = true;
				for (let key in scheduleForm) {
					isValidForm = scheduleForm[key].valid && isValidForm
				}
				this.setState({ 'scheduleForm': scheduleForm, isFormValid: isValidForm })

			} else {

				startDate.value = null;
				startDate.tabIDvalid = false;
				startDate.error = "Start date time should be less then from End Date and greater than Now";
				scheduleForm['start_date'] = startDate
				this.setState({ 'scheduleForm': scheduleForm, isFormValid: false })

			}


		}

	}

	endDateInputChange = dateVal => {

		//return false;

		let currentTime = new Date().getTime();
		let selectTime = new Date(dateVal).getTime();
		let date = dateVal;
		let startDateVal = this.state.scheduleForm['start_date'].value || null;

		let getStartTime = new Date(startDateVal).getTime();

		let getStartDateVal = new Date(startDateVal).getDate();
		let getStartMonthVal = new Date(startDateVal).getMonth() + 1;
		let getEndDateVal = new Date(dateVal).getDate();
		let getEndMonthVal = new Date(dateVal).getMonth() + 1;
		let getEndHoursVal = new Date(dateVal).getHours();
		let getEndMinutesVal = new Date(dateVal).getMinutes();


		if (currentTime > selectTime) {
			date = new Date(currentTime);
		} else
			if (getStartDateVal === getEndDateVal && getStartMonthVal === getEndMonthVal && getEndHoursVal === 0 && getEndMinutesVal === 0) {
				let dateFirst = new Date(dateVal).setHours(23, 59, 59);
				date = new Date(dateFirst);
			} else
				if (getStartDateVal === getEndDateVal && getStartMonthVal === getEndMonthVal && getEndHoursVal !== 0 && getEndMinutesVal !== 0) {
					let getEndHour = new Date(dateVal).getHours();
					let getEndMinutes = new Date(dateVal).getMinutes();
					let dateFirst = new Date(dateVal).setHours(getEndHour, getEndMinutes, 59);
					date = new Date(dateFirst);
				} else
					if (getStartTime > selectTime && getStartMonthVal === getEndMonthVal) {
						let dateFirst = new Date(startDateVal).setHours(getEndHoursVal, getEndMinutesVal, 0);
						date = new Date(dateFirst);
					}

		let scheduleForm = { ...this.state.scheduleForm };
		let endDate = { ...scheduleForm.end_date }

		if (startDateVal == null && date == null) {

			endDate.value = null;
			scheduleForm['end_date'] = endDate
			this.setState({ 'scheduleForm': scheduleForm, isFormValid: false })

		} else if (startDateVal == null && date !== null) {

			endDate.value = date;
			endDate.valid = true
			endDate.error = null;
			scheduleForm['end_date'] = endDate
			let isValidForm = true;
			for (let key in scheduleForm) {
				isValidForm = scheduleForm[key].valid && isValidForm
			}
			this.setState({ 'scheduleForm': scheduleForm, isFormValid: isValidForm })


		} else if (startDateVal !== null && date === null) {

			endDate.value = null;
			scheduleForm['end_date'] = endDate

			this.setState({ 'scheduleForm': scheduleForm, isFormValid: false })

		} else {

			let endDateTime = new Date(date).getTime();
			
			let startDateTime = new Date(startDateVal).getTime();

			if (startDateTime < endDateTime) {

				endDate.value = date;
				endDate.valid = true
				endDate.error = null;
				scheduleForm['end_date'] = endDate
				let isValidForm = true;
				for (let key in scheduleForm) {
					isValidForm = scheduleForm[key].valid && isValidForm
				}
				this.setState({ 'scheduleForm': scheduleForm, isFormValid: isValidForm })

			} else {

				endDate.value = null;
				endDate.valid = false;
				endDate.error = "End date time should be grater then from Start Date";
				scheduleForm['end_date'] = endDate
				this.setState({ 'scheduleForm': scheduleForm, isFormValid: false })

			}


		}

	}



	// getUserInfo = (userInfo) => {
	// 	this.setState({
	// 		userInfo:userInfo
	// 	})
	// }

	toggleTabs = (tabID) => {
		if (this.state.activeTab !== tabID) {
			this.setState({ "activeTab": tabID })
		}
	}



	render() {

		const { isValidationShow } = this.state;

		let endDtaeMin = new Date(this.state.scheduleForm['start_date'].value).setHours(23, 59, 59)
		let endDtaeMinValue = new Date(endDtaeMin);

		let userInfo = jwt_decode(userconfigs.getItem('jwt'))
		if (userInfo.loginStatus === 0) {
			return <Redirect to='/login' />
		}
		else {

			let submitButton = null
			if (this.props.loading) {
				submitButton = <Button  className="conference_schedule_button" disabled><Spinner size="sm" color="light" /> Updating....</Button>
			} else {
				submitButton = <Button  className="conference_schedule_button">Update Schedule Conference</Button>
			}



			let basicFrom = (<>
				<div className="row mt-3 csv_upload_sec">
					<div className="col-md-12">
						<label className="field_title">Invite</label>
						<textarea style={{ whiteSpace: "pre-line" }} className={!this.state.scheduleForm['participant'].valid && isValidationShow ? "hasError form-control" : "form-control"} name="participant" id="participant" value={this.state.scheduleForm['participant'].value} placeholder="Add email or mobile each in new line to invite" onChange={(event) => this.onChangeHandler(event)} >

						</textarea>
						<div className="mt-3">
							<span className="text-warning d-block f_size_14">Note: </span>
							<ul  className="note_list">
								<li>1. Every invite detail should be in new line.</li>
								<li>2. Mobile number should with country code <span>ex. +91-97XXXXXXXX</span>.</li>
								<li>3. Email id should be lowercase <span>ex: test@xxx.com</span>.</li>
							</ul>

						</div>
					</div>
				</div>

				<div  className="row mt-4">
					<div  className="col-md-3 col-sm-6">
						<div  className="form-group">
							<label  className="field_title">Start Time </label>
							<div  className="input-group">
								<DatePicker
									selected={this.state.scheduleForm['start_date'].value}
									onChange={this.startDateInputChange}
									dateFormat="dd-MM-yyyy h:mm aa"
									peekNextMonth
									showMonthDropdown
									showYearDropdown
									dropdownMode="Select...."
									minDate={new Date()}
									placeholderText="Start Date"
									showTimeSelect
									locale="en"
									timeIntervals={15}
									timeCaption="Time"
									 className={!this.state.scheduleForm['start_date'].valid && isValidationShow ? "hasError form-control" : "form-control"}
									name="start_date"
								/>

								<div  className="input-group-append">
									<span  className="input-group-text sch_dths" id="basic-addon2"><i  className="fas fa-calendar"></i></span>
								</div>
								{this.state.scheduleForm['start_date'].error ? <div  className="help-block error_msg_56">{this.state.scheduleForm['start_date'].error}</div> : null}
							</div>
						</div>

						<div  className="form-group">
							<label  className="field_title">End Time </label>
							<div  className="input-group">
								<DatePicker
									selected={this.state.scheduleForm['end_date'].value}
									onChange={this.endDateInputChange}
									dateFormat="dd-MM-yyyy h:mm aa"
									peekNextMonth
									showMonthDropdown
									showYearDropdown
									dropdownMode="Select...."
									locale="en"
									minDate={endDtaeMinValue}
									placeholderText="End Date"
									showTimeSelect
									timeIntervals={15}
									timeCaption="Time"
									 className={!this.state.scheduleForm['end_date'].valid && isValidationShow ? "hasError form-control" : "form-control"}
									name="end_date"
								/>

								<div  className="input-group-append">
									<span  className="input-group-text sch_dths" id="basic-addon2"><i  className="fas fa-calendar"></i></span>
								</div>
								{this.state.scheduleForm['end_date'].error ? <div  className="help-block error_msg_56">{this.state.scheduleForm['end_date'].error}</div> : null}
							</div>

						</div>
					</div>

					<div  className="col-md-3 col-sm-6">
						<div  className="form-group">
							<label  className="d-block field_title mob_inline655"> Waiting Room</label>
							<div  className="toggle_switch_option mob_view_right">
								<span  className="mr-2">No</span>
								<input  className="tgl tgl-light" id="waiting_room" title="Waiting Room" type="checkbox" name="waiting_room" checked={this.state.scheduleForm['waiting_room'].value} onChange={(event) => this.onChangeHandler(event)} />
								<label  className="tgl-btn" for="waiting_room"></label>
								<span  className="ml-2">Yes</span>
							</div>
							{this.state.scheduleForm['waiting_room'].error ? <div  className="help-block error_msg_56">{this.state.scheduleForm['waiting_room'].error}</div> : null}
						</div>
						{this.state.activeTab === "2" ?
							<>
								<div  className="form-group">
									<label  className="d-block field_title mob_inline655"> Echo Test Audio </label>
									<div  className="toggle_switch_option mob_view_right">
										<span  className="mr-2">No</span>
										<input  className="tgl tgl-light" id="check_audio" title="Echo Test Audio" name="check_audio" type="checkbox" checked={this.state.scheduleForm['check_audio'].value} onChange={(event) => this.onChangeHandler(event)} />
										<label  className="tgl-btn" for="check_audio"></label>
										<span  className="ml-2">Yes</span>
									</div>
									{this.state.scheduleForm['check_audio'].error ? <div  className="help-block error_msg_56">{this.state.scheduleForm['check_audio'].error}</div> : null}
								</div>


								<div  className="form-group">
									<label  className="d-block field_title mob_inline655"> Auto Record </label>
									<div  className="toggle_switch_option mob_view_right">
										<span  className="mr-2">No</span>
										<input  className="tgl tgl-light" id="auto_record" title="Auto Record" name="auto_record" type="checkbox" checked={this.state.scheduleForm['auto_record'].value} onChange={(event) => this.onChangeHandler(event)} />
										<label  className="tgl-btn" for="auto_record"></label>
										<span  className="ml-2">Yes</span>
									</div>
									{this.state.scheduleForm['auto_record'].error ? <div  className="help-block error_msg_56">{this.state.scheduleForm['auto_record'].error}</div> : null}
								</div>


								<div  className="d-none form-group">
									<label  className="d-block field_title mob_inline655"> Only Registered </label>
									<div  className="toggle_switch_option mob_view_right">
										<span  className="mr-2">No</span>
										<input  className="tgl tgl-light" id="only_registered" title="Only Registered" name="only_registered" type="checkbox" checked={this.state.scheduleForm['only_registered'].value} onChange={(event) => this.onChangeHandler(event)} />
										<label  className="tgl-btn" for="only_registered"></label>
										<span  className="ml-2">Yes</span>
									</div>
									{this.state.scheduleForm['only_registered'].error ? <div  className="help-block error_msg_56">{this.state.scheduleForm['only_registered'].error}</div> : null}
								</div>
							</> : null}

					</div>


					<>
						<div  className="col-md-3  col-sm-6">
							<div  className="d-none form-group">
								<label  className="d-block field_title mob_inline655"> Auto Join Video</label>
								<div  className="toggle_switch_option mob_view_right">
									<span  className="mr-2">No</span>
									<input  className="tgl tgl-light" id="auto_share_webcam" title="Auto Join Video" name="auto_share_webcam" type="checkbox" checked={this.state.scheduleForm['auto_share_webcam'].value} onChange={(event) => this.onChangeHandler(event)} />
									<label  className="tgl-btn" for="auto_share_webcam"></label>
									<span  className="ml-2">Yes</span>
								</div>
								{this.state.scheduleForm['auto_share_webcam'].error ? <div  className="help-block error_msg_56">{this.state.scheduleForm['auto_share_webcam'].error}</div> : null}
							</div>
							{this.state.activeTab === "2" ?
								<>
									<div  className="form-group">
										<label  className="d-block field_title mob_inline655"> Camera Access Host Only</label>
										<div  className="toggle_switch_option mob_view_right">
											<span  className="mr-2">No</span>
											<input  className="tgl tgl-light" id="camera_access" title="Camera Access Host Only" name="camera_access" type="checkbox" checked={this.state.scheduleForm['camera_access'].value} onChange={(event) => this.onChangeHandler(event)} />
											<label  className="tgl-btn" for="camera_access"></label>
											<span  className="ml-2">Yes</span>
										</div>
										{this.state.scheduleForm['camera_access'].error ? <div  className="help-block error_msg_56">{this.state.scheduleForm['camera_access'].error}</div> : null}
									</div>

									{/* <div  className="form-group">
										<label  className="d-block field_title mob_inline655"> Allow Host to Unmute Users</label>
										<div  className="toggle_switch_option mob_view_right">
											<span  className="mr-2">No</span>
											<input  className="tgl tgl-light" id="allow_admin_unmute_user" title="Allow Host to Unmute Users" name="allow_admin_unmute_user" type="checkbox" checked={this.state.scheduleForm['allow_admin_unmute_user'].value} onChange={(event) => this.onChangeHandler(event)} />
											<label  className="tgl-btn" for="allow_admin_unmute_user"></label>
											<span  className="ml-2">Yes</span>
										</div>
										{this.state.scheduleForm['allow_admin_unmute_user'].error ? <div  className="help-block error_msg_56">{this.state.scheduleForm['allow_admin_unmute_user'].error}</div> : null}
									</div> */}

									{this.state.scheduleForm['join_without_host'].hide === false ?
										<div  className="form-group">
											<label  className="d-block field_title mob_inline655"> Join Without Host </label>
											<div  className="toggle_switch_option mob_view_right">
												<span  className="mr-2">No</span>
												<input  className="tgl tgl-light" id="join_without_host" title="Join Without Host" name="join_without_host" type="checkbox" checked={this.state.scheduleForm['join_without_host'].value} onChange={(event) => this.onChangeHandler(event)} />
												<label  className="tgl-btn" for="join_without_host"></label>
												<span  className="ml-2">Yes</span>
											</div>
											{this.state.scheduleForm['join_without_host'].error ? <div  className="help-block error_msg_56">{this.state.scheduleForm['join_without_host'].error}</div> : null}
										</div> : null}

								</> : null}


						</div>

						<div  className="col-md-3  col-sm-6">
							<div  className="d-none form-group">
								<label  className="d-block field_title mob_inline655"> Hide Presentation/Whiteboard</label>
								<div  className="toggle_switch_option mob_view_right">
									<span  className="mr-2">No</span>
									<input  className="tgl tgl-light" id="hide_presentation" title="Hide Presentation/Whiteboard" name="hide_presentation" type="checkbox" checked={this.state.scheduleForm['hide_presentation'].value} onChange={(event) => this.onChangeHandler(event)} />
									<label  className="tgl-btn" for="hide_presentation"></label>
									<span  className="ml-2">Yes</span>
								</div>
								{this.state.scheduleForm['hide_presentation'].error ? <div  className="help-block error_msg_56">{this.state.scheduleForm['hide_presentation'].error}</div> : null}
							</div>
							{this.state.activeTab === "2" ?
								<>
									<div  className="d-none form-group">
										<label  className="d-block field_title mob_inline655"> Start Muted</label>
										<div  className="toggle_switch_option mob_view_right">
											<span  className="mr-2">No</span>
											<input  className="tgl tgl-light" id="start_mute" title="Start Mute" name="start_mute" type="checkbox" checked={this.state.scheduleForm['start_mute'].value} onChange={(event) => this.onChangeHandler(event)} />
											<label  className="tgl-btn" for="start_mute"></label>
											<span  className="ml-2">Yes</span>
										</div>
										{this.state.scheduleForm['start_mute'].error ? <div  className="help-block error_msg_56">{this.state.scheduleForm['start_mute'].error}</div> : null}
									</div>

									<div  className="form-group">
										<label  className="d-block field_title mob_inline655"> Only Registered </label>
										<div  className="toggle_switch_option mob_view_right">
											<span  className="mr-2">No</span>
											<input  className="tgl tgl-light" id="only_registered" title="Only Registered" name="only_registered" type="checkbox" checked={this.state.scheduleForm['only_registered'].value} onChange={(event) => this.onChangeHandler(event)} />
											<label  className="tgl-btn" for="only_registered"></label>
											<span  className="ml-2">Yes</span>
										</div>
										{this.state.scheduleForm['only_registered'].error ? <div  className="help-block error_msg_56">{this.state.scheduleForm['only_registered'].error}</div> : null}
									</div>

									<div  className="form-group">
										<label  className="d-block field_title mob_inline655"> Allow Recording</label>
										<div  className="toggle_switch_option mob_view_right">
											<span  className="mr-2">No</span>
											<input  className="tgl tgl-light" id="allow_start_or_stop_recording" title="Allow Recording" name="allow_start_or_stop_recording" type="checkbox" checked={this.state.scheduleForm['allow_start_or_stop_recording'].value} onChange={(event) => this.onChangeHandler(event)} />
											<label  className="tgl-btn" for="allow_start_or_stop_recording"></label>
											<span  className="ml-2">Yes</span>
										</div>
										{this.state.scheduleForm['allow_start_or_stop_recording'].error ? <div  className="help-block error_msg_56">{this.state.scheduleForm['allow_start_or_stop_recording'].error}</div> : null}
									</div> </> : null}


						</div>
					</>

				</div>

			</>
			);

			return (
				<>
					{/*<Header getUserInfo={this.getUserInfo.bind()}/>*/}
					<div  className="bg_container_sh_conf">
						<div  className="schedule_campaign_sec">
							<div  className="crate_campaing_sec">
								<h2><i  className="fas fa-clock"></i>Update Schedule Conference </h2>
								<form onSubmit={(event) => this.submitEditScheduleForm(event)} autoComplete="off">
									<input style={{ display: "none" }} type="text" name="fakeusernameremembered" />
									<input style={{ display: "none" }} type="password" name="fakepasswordremembered" />
									<div  className="row">
										<div  className="col-md-12">
											<div  className="form-group">
												<label  className="field_title">Meeting Title</label>
												<input type="text"  className={!this.state.scheduleForm['meetName'].valid && isValidationShow ? "hasError form-control" : "form-control"} title="Title" name="meetName" value={this.state.scheduleForm['meetName'].value} onChange={(event) => this.onChangeHandler(event)} autoComplete="new-title" />
											</div>
											{this.state.scheduleForm['meetName'].error ? <div  className="help-block error_msg_56">{this.state.scheduleForm['meetName'].error}</div> : null}


										</div>
									</div>



									<div  className="row mt-2 mb-4">
										<div  className="col-sm-4">
											<label  className="field_title mob_inline655">Meeting Password</label>
											<div  className="toggle_switch_option mob_view_right">
												<span  className="mr-2">Disable</span>
												<input  className="tgl tgl-light" id="join_password" title="Join Password" name="join_password" type="checkbox" checked={this.state.scheduleForm['join_password'].value} onChange={(event) => this.onChangeHandler(event)} />
												<label  className="tgl-btn" for="join_password"></label>
												<span  className="ml-2">Enable</span>
											</div>
										</div>

										{this.state.scheduleForm['dynamic_password'].hide === false ?

											<div  className="col-sm-4 mob_mt_10">
												<label  className="field_title mob_inline655">Dynamic Password</label>
												<div  className="toggle_switch_option mob_view_right">
													<span  className="mr-2">Disable</span>
													<input  className="tgl tgl-light" id="dynamic_password" title="Dynamic Password" name="dynamic_password" type="checkbox" checked={this.state.scheduleForm['dynamic_password'].value} onChange={(event) => this.onChangeHandler(event)} />
													<label  className="tgl-btn" for="dynamic_password"></label>
													<span  className="ml-2">Enable</span>
												</div>
											</div>
											: null}

										{this.state.scheduleForm['password'].hide === false ?
											<div  className="col-sm-4 pt-3">
												<input type="password"  className={!this.state.scheduleForm['password'].valid && isValidationShow ? "hasError form-control" : "form-control"} title="Password" placeholder="Enter Password" name="password" onChange={(event) => this.onChangeHandler(event)} autoComplete="new-password" />
												{this.state.scheduleForm['password'].error ? <div  className="help-block error_msg_56 mt-2">{this.state.scheduleForm['password'].error}</div> : null}
											</div> : null}


									</div>




									<Nav tabs  className="cs_sh_tab_link">
										<NavItem>
											<NavLink
												 className={classnames({ active: this.state.activeTab === '1' })}
												onClick={() => { this.toggleTabs('1'); }}
											>
												Basic Conference </NavLink>

										</NavItem>

										<NavItem>

											<NavLink
												 className={classnames({ active: this.state.activeTab === '2' })}
												onClick={() => { this.toggleTabs('2'); }}
											>
												Advance Conference </NavLink>
										</NavItem>


									</Nav>

									<TabContent  className="cs_sh_tab_content" activeTab={this.state.activeTab}>
										<TabPane tabId="1">
											{basicFrom}
										</TabPane>
										<TabPane tabId="2">
											{basicFrom}
										</TabPane>
									</TabContent>

									<div  className="row mt-5 pb-3">
										<div  className="col-md-12 text-center">
											{submitButton}
										</div>
									</div>

								</form>

							</div>

						</div>
					</div>

				</>

			);

		}
	}

}



const mapState = (state) => {
	const {
		loading,
		scheduleLoading,
		schedule,
		error,
		meetingId,
		IsScheduleEdited
	} = state.schedule;

	return {
		loading,
		scheduleLoading,
		schedule,
		error,
		meetingId,
		IsScheduleEdited,
		meetingDetail: state.conference.meetingDetail,
	};
}

const actionCreators = {
	editScheduleForm: scheduleActions.editScheduleForm,
	meetingDetailFun: conferenceActions.meetingDetail,
};


export default withRouter(connect(mapState, actionCreators)(EditSchedule));