import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import { connect } from 'react-redux';
import { alertActions, waitingRoomActions } from '../../../../../Redux/_actions';
//import LoadSpinner from '../../../../../components/UI/Spinner/Spinner';

class AttendeeList extends Component {
	
	constructor(props){
		super(props);
		this.state = {
			mid : this.props.mid,
			attendeeList:[],
			last_attendee_time:""
		}
	}

	componentDidMount = () => {
		this.props.listAttendee(this.state.mid,this.state.last_attendee_time)
	}

	approveClick = (obj,key, e) => {
		e.preventDefault();
		// let attendeeList = this.state.attendeeList
		// attendeeList[key].allowed = 0
		// this.setState({
		// 	attendeeList:attendeeList
		// })
		this.props.attendeeReqRes(obj.meetingId,1,obj.userId)
		//console.log("aproveeeee",obj,key)
	}

	declineClick = (obj,key,e) => {
		e.preventDefault();
		// let attendeeList = this.state.attendeeList
		// attendeeList.splice(key,1)
		// this.setState({
		// 	attendeeList:attendeeList
		// })
		this.props.attendeeReqRes(obj.meetingId,0,obj.userId)
		//console.log("declineeee",e.target.value)
	}

	componentDidUpdate = (prevProps,prevState) => {
        if(prevProps.listAttendeeRes !== this.props.listAttendeeRes){
            if(typeof this.props.listAttendeeRes !== "undefined"){
		        if(typeof this.props.listAttendeeRes.attendeeList !== "undefined") {
		        	let mid = this.state.mid;
					let self = this;
					let last_attendee_time = this.state.last_attendee_time;
		            if(this.props.listAttendeeRes.attendeeList.length > 0) {
		                //let attendeeList = [...this.state.attendeeList,...this.props.listAttendeeRes.attendeeList];
		                let attendeeList = this.props.listAttendeeRes.attendeeList;
		                last_attendee_time = this.props.listAttendeeRes.last_attendee_time;
		                    
		                this.setState({
		                    attendeeList : attendeeList,
		                    last_attendee_time: last_attendee_time
		                })
		            }

		            if(this.props.listAttendeeRes.startedTime !== ""){
		            	this.props.getMeetingStatus(this.props.listAttendeeRes.startedTime)
		            }

		            setTimeout(function(){ 
						self.getAttendeeList(mid,last_attendee_time)
					}, 3000);
		        }
		    }
        }   
    }

    getAttendeeList = (mid,last_msg_time) => {
		this.props.listAttendee(this.state.mid,"")
	}

	render() {
		const { attendeeList } = this.state;
		const {listAttendeeLoading} = this.props
		var attendeeHtml = "";
		if(attendeeList.length > 0) {
			attendeeHtml = attendeeList.map((user,key) => {
				if(user.allowed === 1){
					return (
						<li key={key} className="approve_partcpnt">
	                        <div className="prtcpnt_name">
	                           <span>{user.name.charAt(0).toUpperCase()}</span>
		                        {user.name}<small> - [ {user.user_type.toUpperCase()} ]</small>
	                        </div>
	                        <div className="prtcpnt_action">
	                            <span className="w_approve_btn"><i className="fas fa-check"></i> Approved</span>
	                        </div>
	                    </li>
	                )
				}
				else if(user.allowed === 3) {
					return (
						<li key={key} className="partcpnt_disconnect">
	                    	<div className="prtcpnt_name">
	                        	<span>{user.name.charAt(0).toUpperCase()}</span>
		                        {user.name}<small> - [ {user.user_type.toUpperCase()} ]</small>
	                        </div>
	                        <div className="prtcpnt_action">
	                            <span className="w_approve_btn"><i className="fas fa-exclamation-circle"></i> Disconnected</span>
	                        </div>
	                    </li>
					)
				}
				else if(user.allowed === 0) {
					return (
						<li key={key} className="partcpnt_disconnect text-danger">
	                    	<div className="prtcpnt_name">
	                        	<span>{user.name.charAt(0).toUpperCase()}</span>
		                        {user.name}<small> - [ {user.user_type.toUpperCase()} ]</small>
	                        </div>
	                        <div className="prtcpnt_action">
	                            <span className="w_approve_btn text-danger"><i className="fas fa-exclamation-circle"></i> Rejected</span>
	                        </div>
	                    </li>
					)
				}
				else{
					return(
						<li key={key}>
		                    <div  className="prtcpnt_name">
		                       	<span>{user.name.charAt(0).toUpperCase()}</span>
		                        {user.name}<small> - [ {user.user_type.toUpperCase()} ]</small>
		                    </div>
		                    <div  className="prtcpnt_action">
		                        <button onClick={this.approveClick.bind(this,user,key)}  className="btn w_approve_btn">Approve</button>
		                        <button value={user} onClick={this.declineClick.bind(this,user,key)}  className="btn w_decline_btn">Decline</button>
		                    </div>
		      			</li>
					)
				}	
			})
		}
		else{
			attendeeHtml = (<li  className="text-light text-center">
				<div  className="waveno_participaint_info">There's No Participants For Now</div>
			</li> )
		}

		
		return (
			<div  className="col-xl-4 col-lg-5 col-md-6">
    			<div  className="waiting_participiant_list">
        			<h3><font>{listAttendeeLoading ? <Spinner size="sm" color="light" /> : "("+attendeeList.length+")"}</font> Participants in Waiting</h3>
        			{listAttendeeLoading ? 
        				<div  className="text-center">
        					<img src="/images/loading.svg" width="300px" height="140px" alt="loader" />
        				</div>
        			:
    					<ul>
    						{attendeeHtml}
    					</ul> 
        				 
        			}
    			</div>  
			</div>
		)
	}
}

function mapState(state) {
    const {listAttendeeLoading, listAttendeeRes, attendeeRequestRes, attendeeRequestLoading,} = state.waitingRoom
    return {
        listAttendeeLoading,
        listAttendeeRes,
        attendeeRequestRes,
        attendeeRequestLoading
    };
}

const actionCreators = {
    listAttendee: waitingRoomActions.listAttendee,
    attendeeReqRes: waitingRoomActions.attendeeReqRes,
    alertError:alertActions.error
};

export default withRouter(connect(mapState, actionCreators)(AttendeeList));