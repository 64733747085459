import { waitingRoomConstants } from "../_constants";
import { waitingRoomService } from "../_services";
import { alertActions } from "./";
//import { history } from '../_helpers';

export const waitingRoomActions = {
  addUser,
  broadcastMsg,
  getBroadcastMsg,
  listAttendee,
  attendeeReqRes,
  attendeeJoin,
  moderatorJoin,
};

function addUser(name, meeting_id, meeting_password, modId, isWebinar) {
  return (dispatch) => {
    dispatch(request({ name }));

    waitingRoomService.addUser(name, meeting_id, meeting_password, modId).then(
      (resp) => {
        dispatch(success(resp));
        //window.location.href = resp
        //console.log("actionsssssssssssss",resp)
        //history.push('/waitingRoom');
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error.toString()));
      }
    );
  };

  function request(name) {
    return { type: waitingRoomConstants.ADD_USER_REQUEST, name };
  }
  function success(resp) {
    return { type: waitingRoomConstants.ADD_USER_SUCCESS, resp };
  }
  function failure(error) {
    return { type: waitingRoomConstants.ADD_USER_FAILURE, error };
  }
}

function broadcastMsg(mid, modId, type, data) {
  return (dispatch) => {
    dispatch(request({ mid }));

    waitingRoomService.broadcastMsg(mid, modId, type, data).then(
      (resp) => {
        dispatch(success(resp));
        //console.log("actionsssssssssssss",resp)
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error.toString()));
      }
    );
  };

  function request(name) {
    return { type: waitingRoomConstants.BROADCAST_REQUEST, mid };
  }
  function success(resp) {
    return { type: waitingRoomConstants.BROADCAST_SUCCESS, resp };
  }
  function failure(error) {
    return { type: waitingRoomConstants.BROADCAST_FAILURE, error };
  }
}

function getBroadcastMsg(mid, last_msg_time) {
  return (dispatch) => {
    dispatch(request({ mid }));

    waitingRoomService.getBroadcastMsg(mid, last_msg_time).then(
      (resp) => {
        dispatch(success(resp));
        //console.log("actionsssssssssssss",resp)
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error.toString()));
      }
    );
  };

  function request(name) {
    return { type: waitingRoomConstants.GET_BROADCAST_REQUEST, mid };
  }
  function success(resp) {
    return { type: waitingRoomConstants.GET_BROADCAST_SUCCESS, resp };
  }
  function failure(error) {
    return { type: waitingRoomConstants.GET_BROADCAST_FAILURE, error };
  }
}

function listAttendee(mid, last_attendee_time) {
  return (dispatch) => {
    dispatch(request({ mid }));

    waitingRoomService.listAttendee(mid, last_attendee_time).then(
      (resp) => {
        dispatch(success(resp));
        //console.log("actionsssssssssssss",resp)
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error.toString()));
      }
    );
  };

  function request(name) {
    return { type: waitingRoomConstants.LIST_ATTENDEE_REQUEST, mid };
  }
  function success(resp) {
    return { type: waitingRoomConstants.LIST_ATTENDEE_SUCCESS, resp };
  }
  function failure(error) {
    return { type: waitingRoomConstants.LIST_ATTENDEE_FAILURE, error };
  }
}

function attendeeReqRes(mid, action, attendeeId) {
  return (dispatch) => {
    dispatch(request({ mid }));

    waitingRoomService.attendeeReqRes(mid, action, attendeeId).then(
      (resp) => {
        dispatch(success(resp));
        //console.log("actionsssssssssssss",resp)
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error.toString()));
      }
    );
  };

  function request(name) {
    return { type: waitingRoomConstants.ATTENDEE_REQUEST_REQUEST, mid };
  }
  function success(resp) {
    return { type: waitingRoomConstants.ATTENDEE_REQUEST_SUCCESS, resp };
  }
  function failure(error) {
    return { type: waitingRoomConstants.ATTENDEE_REQUEST_FAILURE, error };
  }
}

function attendeeJoin(mid, name) {
  return (dispatch) => {
    dispatch(request({ mid }));

    waitingRoomService.attendeeJoin(mid, name).then(
      (resp) => {
        dispatch(success(resp));
        window.location.href = resp;
        //console.log("actionsssssssssssss",resp)
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error.toString()));
      }
    );
  };

  function request(name) {
    return { type: waitingRoomConstants.ATTENDEE_JOIN_REQUEST, mid };
  }
  function success(resp) {
    return { type: waitingRoomConstants.ATTENDEE_JOIN_SUCCESS, resp };
  }
  function failure(error) {
    return { type: waitingRoomConstants.ATTENDEE_JOIN_FAILURE, error };
  }
}

function moderatorJoin(mid, name) {
  return (dispatch) => {
    dispatch(request({ mid }));

    waitingRoomService.moderatorJoin(mid, name).then(
      (resp) => {
        dispatch(success(resp));
        //window.open(resp, '_blank');
        window.location.href = resp;
        //console.log("actionsssssssssssss",resp)
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error.toString()));
      }
    );
  };

  function request(name) {
    return { type: waitingRoomConstants.MODERATOR_JOIN_REQUEST, mid };
  }
  function success(resp) {
    return { type: waitingRoomConstants.MODERATOR_JOIN_SUCCESS, resp };
  }
  function failure(error) {
    return { type: waitingRoomConstants.MODERATOR_JOIN_FAILURE, error };
  }
}
