import React from 'react'
import { useDispatch } from 'react-redux';
import { alertActions } from '../../Redux/_actions';

import Modal from './index';

const ShareModal = (props) => {
  const { show, toggle, shareURl } = props
  const dispatch = useDispatch();
  console.log(shareURl)
  return (
    <Modal
      show={show}
      toggle={toggle}
      label={<h4 className='mb-0'>  <i className="far fa-share-square"></i>  Share Joining URL</h4>}
    >
      <div className="file_sharng_sct mt-3">
        <label className="text-muted "> Copy and share this link with your speaker.</label>
        <div className="shrng_568">
          <div className="shared_url bgColorBodyCard">
            <span>{shareURl}</span>
          </div>
          <div className="mt-3 text-right">
            <button
              variant="secondary"
              onClick={(e) => toggle()}
              className="mr-2 btnStyles welcomeMsg sideBarIcon"
            >
              Cancel
            </button>
            <button
              variant="primary"
              onClick={(e) => {
                dispatch(alertActions.success("Copied !!!"));
                navigator.clipboard.writeText(shareURl);
              }}
              className=" btnStyles  submitSubColor welcomeMsg"
            >
              <i className="far fa-copy"></i> Copy
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ShareModal
