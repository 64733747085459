import React, { useState, useEffect } from "react";
// import Layout from "../../layoutSection";
import "../styles.scss";
// import { Link, Button } from "reactstrap";
// import Upgradeplansection from "../Upgradeplan/Upgradeplansection";
// import Tabs from 'react-bootstrap/Tabs';
// import Tab from 'react-bootstrap/Tab';
// import Table from 'react-bootstrap/Table';
import {FaPen, FaLink/* , FaUserPlus */} from "react-icons/fa";
// import Dropdown from 'react-bootstrap/Dropdown';

import Modal from 'react-bootstrap/Modal';
// import Form from 'react-bootstrap/Form';
import { userService } from "../../Redux/_services";
import TreeTable from "../Grid/Table";
import { manageUSerColumn } from "../column";


  function UserTable({userType,lgShow,handleEdit}) {
    const[totalRecords,setTotalRecords]=useState(0);
    const[currentPage,setCurrentPage]=useState(1);
    const[selectedId,setSelectedId]=useState(0);
    const[message,setMessage]=useState('');
    const[disabled,setDisabled]=useState(false);
    const[show,setShow]=useState(false);
    const[usersList,setUsersList]=useState([]);
    const limit = 20;
    const handleNextPage = async(pageNumber)=>{
      let response = await userService.handleGetList(`admin/getMyHostUser/${pageNumber}/${limit}/${userType}`);
      if (response === false) {
       
        return;
      }
      setUsersList(response.data);
      setTotalRecords(response.count);
      setCurrentPage(pageNumber);
     }
     useEffect(()=>{
       if(lgShow === false){
        handleNextPage(currentPage);
       }
      
     },[lgShow,userType]);
     const handleAction = (row)=>{  }
     const handledeteAction = (row)=>{return true;}
     const handleRowEdit = (row)=>{ handleEdit(false,row);  }
     const handleRowDelete = (row)=>{ setSelectedId(row._id);setShow(true);  }
     const handleDelete = async()=>{
     
       if(disabled === true){return false;}
       setDisabled(true);
      let response = await userService.postData({id:selectedId},`admin/hostuser/deleteHostUser`);
      if (response !== false) {
        setTimeout(function(){setShow(false); setMessage(""); },3000)
        setMessage("Successsfully deleted! ");
        handleNextPage(1);
      }
      setDisabled(false);
     }
    return (
        <>
        <Modal show={show}  className="wconfirmation_dilog_bx1">
            <Modal.Body>
              <div className="cnfmrtn_msg">Are you sure want to remove this Participant ?</div>
              <div className="d-inline-block w-100 text-center">
                <button className="btnLg btn  btn-secondary mr-3" onClick={() =>{setShow(false)}}>No</button>
                <button className="btnLg btn btn-danger" onClick={() =>{handleDelete()}}>Yes</button>
              </div>
              {message && <p  className="response error">{message}</p>}
            </Modal.Body>
          </Modal>
        <TreeTable
          columns={manageUSerColumn}
          contracts={usersList}
          totalRecords={totalRecords}
          rowsPerPage={limit}
          page={currentPage}
          setPage={handleNextPage}
          actions={[
            ["Actions",[
              [["Edit", [<span  className="editicon-list"> <FaPen/> </span> ],(c) => handleAction], handleRowEdit],
              [["Delete", [<span  className="linkicon-list"> <FaLink/> </span>,<span  className="linkicon-list disabled"> <FaLink/> </span>],(c) => handledeteAction], handleRowDelete],
            ]]
          ]}
        />
        </>
    );
  }

export default UserTable;
