import React from 'react'
import { Row, Col, Form, Button, InputGroup } from 'react-bootstrap';
import { Formik } from 'formik';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimezoneSelect from "react-timezone-select";
import { AiOutlineCalendar, } from 'react-icons/ai';
import * as Yup from 'yup';

import { InputFiled } from '../../../Common/authFile'
import { daysName, week } from '../../../Common/constant'
import styles from './styles.module.scss'
import { tsToDate } from '../../../helpers/helpers';
import { setHours, setMinutes } from 'date-fns';
import isEmpty from 'is-empty';

var isRecurring = false;

const getWeek = (newValue, oldData, setFieldValue) => {
  let result = oldData
  if (oldData.includes(newValue)) {
    result = result.filter(data => data !== newValue)
  } else {
    result.push(newValue)
  }
  setFieldValue('weekly', result)
}

const getRepeatPart = (value) => {
  if (value === "monthly") {
    return "month"
  } else if (value === "weekly") {
    return "week"
  } else {
    return 'day'
  }
}

const getRepeatList = (value) => {
  if (value === "monthly") {
    return Array.from({ length: 3 }).map((data, index) => <option key={`repeatEvery-${index + 1}`} value={index + 1}>{index + 1}</option>);
  } else if (value === "weekly") {
    return Array.from({ length: 12 }).map((data, index) => <option key={`repeatEvery-${index + 1}`} value={index + 1}>{index + 1}</option>);
  } else {
    return Array.from({ length: 15 }).map((data, index) => <option key={`repeatEvery-${index + 1}`} value={index + 1}>{index + 1}</option>);
  }
}

export const ReCurring = (props) => {
  const { values, setFieldValue, errors, touched, className } = props
  return (
    <div className={`${className || 'bgColorBodyCard'}  p-3 mt-2`}>
      <Form.Group  >
        <Form.Label className="f_size_14 ml-3 welcomeMsg" >Recurrence </Form.Label>
        <select onChange={(event) => {
          const value = event.target.value;
          if (value === "notFixed") {
            isRecurring = false
          } else {
            isRecurring = true
          }
          setFieldValue('reCurringType', value)
        }} value={values.reCurringType} className={`form-control w-25 inputColorTheme ml-1 ${styles.recurrenceTypeSch}`}  >
          <option value="daily">Daily</option>
          <option value="weekly">Weekly</option>
          <option value="monthly">Monthly</option>
          <option value="notFixed">No Fixed Time </option>
        </select>
      </Form.Group>
      {
        values.reCurringType !== 'notFixed' &&
        <>
          <Form.Group  >
            <Form.Label className="f_size_14 ml-3 welcomeMsg" >Repeat every </Form.Label>
            <InputGroup className={`d-flex ${styles.repeatSection}`}>
              <select onChange={(event) => setFieldValue('repeatEvery', event.target.value)} value={values.repeatEvery} placeholder='Hr' className="form-control  inputColorTheme ml-1" aria-describedby="Day" >
                {getRepeatList(values.reCurringType)}
              </select>
              <InputGroup.Text id="Day" className={`${styles.inputIcon} inputColorTheme`} >{getRepeatPart(values.reCurringType)}</InputGroup.Text>
            </InputGroup>
          </Form.Group>

          {values.reCurringType === 'monthly' && <Form.Group className='welcomeMsg' >
            <Form.Label className="f_size_14 ml-3 welcomeMsg" >Occurs on </Form.Label>
            <div className={`${styles.monthSelection} align-items-center`}>
              <Form.Check
                type="radio"
                label={<Form.Group className='d-flex align-items-center'  >
                  <Form.Label className="f_size_14 welcomeMsg mb-0" >Day </Form.Label>
                  <InputGroup className='d-flex ml-3  '>
                    <select onChange={(event) => setFieldValue('OccursOn.value', event.target.value)} value={values.OccursOn.value} placeholder='Hr' className="form-control  inputColorTheme ml-1" aria-describedby="ofTheMonth" disabled={values.OccursOn.type !== 'days'} >
                      {Array.from({ length: 31 }).map((data, index) => <option key={`ofTheMonth-${index + 1}`} value={index + 1}>{index + 1}</option>)}
                    </select>
                    <InputGroup.Text id="ofTheMonth" className={`${styles.inputIcon} inputColorTheme`} >of the month</InputGroup.Text>
                  </InputGroup>
                </Form.Group>}
                id={`OnOccurs`}
                name="Occurs"
                value='days'
                checked={values.OccursOn.type === 'days'}
                onChange={(event) => {
                  setFieldValue('OccursOn.type', event.target.value)
                  setFieldValue('OccursOn.value', 1)
                }}
                className={styles.radioForm}

              />
              <Form.Check
                type="radio"
                label={<Form.Group className={` ${styles.disSelectionDd} align-items-center`}  >
                  <InputGroup className={`d-flex ${styles.margBottom} ml-2`}>
                    <select onChange={(event) => setFieldValue('OccursOn.value.week', event.target.value)} value={values.OccursOn.week} placeholder='Hr' className="form-control  inputColorTheme ml-1" aria-describedby="week" disabled={values.OccursOn.type !== 'onDays'} >
                      {week.map((data, index) => <option key={`weekTime-${index}`} value={data}>{data}</option>)}
                    </select>
                    <InputGroup.Text id="week" className={`${styles.inputIcon} inputColorTheme`} >week</InputGroup.Text>
                  </InputGroup>
                  <InputGroup className='d-flex ml-2  '>
                    <select onChange={(event) => setFieldValue('OccursOn.value.day', event.target.value)} value={values.OccursOn.day} placeholder='Hr' className="form-control  inputColorTheme ml-1" aria-describedby="Days" disabled={values.OccursOn.type !== 'onDays'}  >
                      {daysName.map((data, index) => <option key={`daysName-${index}`} value={data}>{data}</option>)}
                    </select>
                    <InputGroup.Text id="Days" className={`${styles.inputIcon} inputColorTheme`} >Day</InputGroup.Text>
                  </InputGroup>
                </Form.Group>}
                id={`OnOccurs`}
                name="Occurs"
                value='onDays'
                className={`${styles.radioForm} ${styles.radioFormMargin}`}
                checked={values.OccursOn.type === 'onDays'}
                onChange={(event) => {
                  setFieldValue('OccursOn.type', event.target.value)
                  setFieldValue('OccursOn.value', { week: 'First', day: 'MON' })
                }}
              />
            </div>
          </Form.Group>
          }
          {
            values.reCurringType === 'weekly' &&
            <>
              <Form.Label className="f_size_14 ml-3 welcomeMsg" >Occurs on</Form.Label>
              <div className={`${styles.OccursOn}`}>
                {daysName.map((data, index) => (
                  <Form.Group className="ml-3" key={`weekly-${data}`}>
                    <Form.Check type="checkbox" className='welcomeMsg' label={data} onChange={() => getWeek(data, values.weekly, setFieldValue)} checked={values.weekly.includes(data)} />
                  </Form.Group>
                ))}
              </div>
            </>
          }
          <Form.Group className='welcomeMsg' >
            <Form.Label className="f_size_14 ml-3 welcomeMsg" >End Date </Form.Label>
            <div className={`${styles.endDateTags} align-items-center`} >
              <Form.Check
                type="radio"
                label={<Form.Group  >
                  <div className={`position-relative datePicker d-flex align-items-center`}>
                    <div className="f_size_14 welcomeMsg mr-2" >By </div>
                    <DatePicker
                      selected={values.endType.type === 'by' && !isEmpty(values?.endType?.value) ? new Date(values.endType.value) : null}
                      showTimeSelect
                      timeFormat="p"
                      timeIntervals={15}
                      dateFormat="Pp"
                      onChange={(value) => setFieldValue('endType.value', value)}
                      className="form-control w-100 inputColorTheme"
                      disabled={values.endType.type === 'after'}
                      minDate={values?.startOn}
                      maxDate={new Date().setUTCFullYear(new Date().getFullYear() + 2)}
                    />
                    <AiOutlineCalendar className='iconInput  f_size_20 welcomeMsg' />
                  </div>
                  {
                    touched?.endType?.value && errors?.endType?.value && <div className="error text-danger f_size_13 ml-4">{errors?.endType?.value}</div>
                  }
                </Form.Group>}
                name="endDate"
                id='endDate'
                value="by"
                checked={values.endType.type === 'by'}
                onChange={(event) => {
                  setFieldValue('endType.type', event.target.value)
                  setFieldValue('endType.value', "")
                }}
                className={styles.radioForm}
              />
              <Form.Check
                type="radio"
                label={<Form.Group className='d-flex align-items-center'  >
                  <Form.Label className="f_size_14 welcomeMsg mb-0" >After </Form.Label>
                  <InputGroup className='d-flex ml-3  '>
                    <select onChange={(event) => setFieldValue("endType.value", event.target.value)} value={values.endType.value} placeholder='Hr' className="form-control  inputColorTheme ml-1" aria-describedby="Occurrences" disabled={values.endType.type !== 'after'} >
                      {Array.from({ length: 20 }).map((data, index) => <option key={`Occurrences-${index + 1}`} value={index + 1}>{index + 1}</option>)}
                    </select>
                    <InputGroup.Text id="Occurrences" className={`${styles.inputIcon} inputColorTheme`} >Occurrences</InputGroup.Text>
                  </InputGroup>
                </Form.Group>}
                name="endDate"
                id='endDate'
                value="after"
                checked={values.endType.type === 'after'}
                className={`ml-3 ${styles.radioForm} ${styles.radioFormAfter} ${errors?.endType?.value ? "mb-auto" : ''}`}
                onChange={(event) => {
                  setFieldValue('endType.type', event.target.value)
                  setFieldValue('endType.value', '1')
                }}
              />
            </div>
          </Form.Group>
        </>
      }
    </div >
  )
}


const validation = Yup.object().shape({
  title: Yup.string().required('please Enter title!').matches(/^[0-9aA-zZ\s]+$/, "Only alphabets are allowed for this field!").test("title", "Meeting title must be at least five characters", (value) => {
    if (isEmpty(value)) return true;
    return value.length > 4
  }),
  // description: Yup.string().required('please Enter description!'),
  description: Yup.string().matches(/^[a-zA-Z0-9 ,:!.@\s]*$/, "Only alphabets are allowed for this field!"),
  startOn: Yup.string().required('please Enter start Date!').test('length', "please enter a valid time", (value) => new Date(value).getTime() >= new Date().getTime()),
  reCurring: Yup.bool(),
  endType: Yup.object({
    type: Yup.string(),
    value: Yup.string().when("type", {
      is: (value) => value == "by" && isRecurring,
      then: Yup.string().required("please Enter the EndDate!")
    })
  })
})


const getLabels = (values) => {
  if (values.endType.value) {
    if (values.reCurringType === 'daily') {
      return <span className='ml-2 text-muted f_size_15' >{`Every ${values.repeatEvery} days, ${values.endType?.type === "by" ? `until ${tsToDate(new Date(values.endType.value).getTime())}` : `${values.endType.value} occurrence(s)`} `}</span>
    } else if (values.reCurringType === 'weekly') {
      return <span className='ml-2 text-muted f_size_15' >{`Every week on ${values.weekly.map(data => data)} , ${values.endType?.type === "by" ? `until ${tsToDate(new Date(values.endType.value).getTime())}` : `${values.endType.value} occurrence(s)`} `}</span>
    } else if (values.reCurringType === 'monthly') {
      return <span className='ml-2 text-muted f_size_15' >{`Every month on the ${values.OccursOn.type === 'days' ? `${values?.OccursOn?.value} of the month ` : `${values?.OccursOn?.value?.week} ${values?.OccursOn?.value?.day} `} , ${values.endType?.type === "by" ? `until ${tsToDate(new Date(values.endType.value).getTime())}` : `${values.endType.value} occurrence(s)`} `}</span>
    } else if (values.reCurringType === 'notFixed') {
      return <span className='ml-2 text-muted f_size_15'>Meet Anytime</span>
    }
  }
  return null
}

const getUpdateDuration = (setFieldValue, value) => {
  if (value === "0") {
    setFieldValue("duration.hr", value)
    setFieldValue("duration.min", "15")
  } else {
    setFieldValue("duration.hr", value)
  }
}


const StepFirst = (props) => {
  const { setFormData, formData } = props

  const initialValue = {
    title: formData?.title || '',
    description: formData?.description || '',
    startOn: formData?.startOn || setMinutes(new Date(), new Date().getMinutes() + 15),
    duration: { hr: formData?.duration?.hr || 1, min: formData?.duration?.min || 0 },
    timeZone: {
      value: formData?.timeZone?.value || "Asia/Kolkata",
      label: formData?.timeZone?.label || "(GMT+5:30) Chennai, Kolkata, Mumbai, New Delhi",
    },
    reCurring: formData?.reCurring || false,
    reCurringType: formData?.reCurringType || 'daily',
    repeatEvery: formData?.repeatEvery || 1,
    endType: { type: formData?.endType?.type || 'by', value: formData?.endType?.value || "" },
    OccursOn: { type: formData?.OccursOn?.type || 'days', value: formData?.OccursOn?.value || '1' },
    weekly: formData?.weekly || ['MON'],
  }

  return (
    <Formik
      initialValues={initialValue}
      onSubmit={(value) => setFormData(value)}
      validationSchema={validation}
      enableReinitialize
    >
      {
        ({ values, setFieldValue, errors, touched, handleSubmit, }) => (
          <>
            <InputFiled
              name="title"
              type="text"
              placeholder="e.g. Introduction"
              label='Meeting Title'
              className="form-control inputColorTheme"
              value={values.title}
            />

            <Form.Group className="mt-2" >
              <Form.Label className='welcomeMsg ml-3' >Description</Form.Label>
              <Form.Control as="textarea" className="form-control w-100 inputColorTheme" rows={3} value={values.description} onChange={event => setFieldValue('description', event.target.value)} />
              {touched.description && errors.description && (
                <div className="error text-danger f_size_13">{errors.description}</div>
              )}
            </Form.Group>
            <Row className="mt-2">
              <Col lg={6}>
                <Form.Group  >
                  <Form.Label className="f_size_14 ml-3 welcomeMsg" >Start On </Form.Label>
                  <div className={`position-relative datePicker`}>
                    <DatePicker
                      selected={values.startOn}
                      onChange={(value) => setFieldValue('startOn', value)}
                      showTimeSelect
                      dateFormat="MMMM d, yyyy h:mm aa"
                      // withPortal
                      className="form-control w-100 inputColorTheme"
                      minDate={new Date()}
                      maxDate={new Date().setUTCFullYear(new Date().getFullYear() + 2)}
                      timeFormat="p"
                      timeIntervals={15}
                    />
                    <AiOutlineCalendar className='iconInput  f_size_20 welcomeMsg' />
                  </div>
                  {
                    touched?.startOn && errors?.startOn && <div className="error text-danger f_size_13">{errors?.startOn}</div>
                  }
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group >
                  <Form.Label className="f_size_14 ml-3 welcomeMsg" >Duration </Form.Label>
                  <div className='d-flex'>
                    <InputGroup className='d-flex'>
                      <select onChange={(event) => getUpdateDuration(setFieldValue, event.target.value)} value={values.duration.hr} placeholder='Hr' className="form-control  inputColorTheme " aria-describedby="hour" >
                        {Array.from({ length: 25 }).map((data, index) => <option key={`duration-${index}`} value={index}>{index}</option>)}
                      </select>
                      <InputGroup.Text id="hour" className={`${styles.inputIcon} inputColorTheme `}>Hr</InputGroup.Text>
                    </InputGroup>
                    <InputGroup className='d-flex'>
                      <select onChange={(event) => setFieldValue("duration.min", event.target.value)} value={values.duration.min} placeholder='Hr' className="form-control  inputColorTheme ml-3" aria-describedby="minutes" >
                        {values.duration.hr !== "0" && <option value="0">0</option>}
                        <option value="15">15</option>
                        <option value="30">30</option>
                        <option value="45">45</option>
                      </select>
                      <InputGroup.Text id="minutes" className={`${styles.inputIcon} inputColorTheme`} >Min</InputGroup.Text>
                    </InputGroup>
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="mt-1 timezone_input" >
              <Form.Label className="f_size_14 ml-3 welcomeMsg" >Select Timezone</Form.Label>
              <div>
                <TimezoneSelect
                  value={values.timeZone}
                  onChange={(value) => setFieldValue('timeZone', value)}
                  className="inputColorTheme rounded"
                />
              </div>
            </Form.Group>
            <Form.Check
              type='checkbox'
              id={`Recurring`}
              label={<div>Recurring meeting  {values.reCurring && getLabels(values)}  </div>}
              className='welcomeMsg'
              checked={values.reCurring}
              onChange={() => {
                isRecurring = !values.reCurring
                setFieldValue('reCurring', !values.reCurring)
              }}
            />
            {
              values.reCurring && <ReCurring values={values} setFieldValue={setFieldValue} errors={errors} touched={touched} />
            }
            <div className='text-right mt-2 '>
              <button type='button' onClick={() => handleSubmit()} className="mr-2 border-0 pl-4 pr-4 headerBtn text-white submitBtnColor" >Continue</button>
            </div>

          </>
        )
      }
    </Formik >
  )
}

export default StepFirst
