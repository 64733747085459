export const scheduleConstants = {
    SCHEDULE_START: 'SCHEDULE_START',
    SCHEDULE_SUCCESS: 'SCHEDULE_SUCCESS',
    SCHEDULE_FAILURE: 'SCHEDULE_FAILURE',

    SCHEDULE_DETAIL_START: 'SCHEDULE_DETAIL_START',
    SCHEDULE_DETAIL_SUCCESS: 'SCHEDULE_DETAIL_SUCCESS',
    SCHEDULE_DETAIL_FAILURE: 'SCHEDULE_DETAIL_FAILURE',

    SCHEDULE_DELETE_START: 'SCHEDULE_DELETE_START',
    SCHEDULE_DELETE_SUCCESS: 'SCHEDULE_DELETE_SUCCESS',
    SCHEDULE_DELETE_FAILURE: 'SCHEDULE_DELETE_FAILURE',

    SCHEDULE_EDIT_START: 'SCHEDULE_EDIT_START',
    SCHEDULE_EDIT_SUCCESS: 'SCHEDULE_EDIT_SUCCESS',
    SCHEDULE_EDIT_FAILURE: 'SCHEDULE_EDIT_FAILURE',

    SCHEDULE_CANCEL_START: 'SCHEDULE_CANCEL_START',
    SCHEDULE_CANCEL_SUCCESS: 'SCHEDULE_CANCEL_SUCCESS',
    SCHEDULE_CANCEL_FAILURE: 'SCHEDULE_CANCEL_FAILURE'
};
