import React, {Component} from 'react';
import classnames from 'classnames';
import { TabContent, TabPane, Nav, NavItem, NavLink, Spinner } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Speakers from './RightComponents/Speakers';
import Attendees from './RightComponents/Attendees';
import Chat from './RightComponents/Chat';
import Questions from './RightComponents/Questions';
import { webinarRoomActions } from '../../../Redux/_actions';

class RightPanel extends Component{
	constructor(props){
		super(props);

		this.state = {
			activeTab : "1",
			webinarInfo:this.props.webinarInfo,
			webinarID : this.props.webinarID,
			token : this.props.token

		}
	}


	componentDidUpdate = (prevProps, prevState) => {
		let webinarInfo = this.state.webinarInfo;

		if(prevProps.webinarInfo !== this.props.webinarInfo){
			this.setState({
				webinarInfo:this.props.webinarInfo,
				webinarID:this.props.webinarID,
				token:this.props.token
			})
		}

		if(prevProps.handRaiseStatus !== this.props.handRaiseStatus){
			webinarInfo.handRaise = this.props.handRaiseStatus
		}
	}

	toggleTabs = (tabID) => {
		if (this.state.activeTab !== tabID) {
			this.setState({"activeTab": tabID})
		}
	}

	handRiseClick = (handRaiseBit) => {
      const { webinarID, token } = this.state;
      this.props.handRaiseFun(webinarID, token, handRaiseBit)
    }


	render(){

		const {webinarID, token, webinarInfo, activeTab} = this.state;
		const { handRaiseLoading } = this.props;

		let handRaise = webinarInfo.handRaise || 0;

		let handRaiseClass = handRaise ? "hand_rise_btn active" : "hand_rise_btn" ;

		let userType = webinarInfo.user_type || "attendee";

		let isQuestion = webinarInfo.quesAns || false;

		return(
		<div>	

	        <div className="tab_section">
	             
	                <Nav tabs className="nav nav-tabs nav-fill">
						<NavItem>
							<NavLink
								className={classnames({ active: activeTab === '1' })}
								onClick={() => { this.toggleTabs('1'); }}
							> 	<i className="fas fa-chalkboard-teacher"></i> 
								<font> Speakers </font> </NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								className={classnames({ active: activeTab === '2' })}
								onClick={() => { this.toggleTabs('2'); }}
							>   <i className="fas fa-users">  </i> 
								<font> Attendees </font></NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								className={classnames({ active: activeTab === '3' })}
								onClick={() => { this.toggleTabs('3'); }}

							>   <i className="fas fa-comments"></i> 
							    <font>Chat</font> </NavLink>
						</NavItem>
						{isQuestion ?
						<NavItem>
							<NavLink
								className={classnames({ active: activeTab === '4' })}
								onClick={() => { this.toggleTabs('4'); }}
							> <i className="fas fa-question-circle"></i> 
							 <font>Questions </font></NavLink>
						</NavItem> : null }

						
						{userType==="attendee" ?
						<NavItem className="hand_rise_sec">
						{ handRaiseLoading ?  <button className={handRaiseClass} ><Spinner size='sm' /> </button> :
                        <button className={handRaiseClass} onClick={() => this.handRiseClick(handRaise===1 ? 0 : 1)}><i className="far fa-hand-paper"></i></button> }
                        </NavItem>
                        : null
		            	} 

								
						
					</Nav>
	 
	              
	        </div>

	        <div className="tab-content webinar_tab_content">
	        	<TabContent activeTab={activeTab}>
	        	<TabPane tabId="1">
		           	<Speakers webinarID={webinarID} token={token} activeTab={activeTab} webinarInfo={webinarInfo} />
	           </TabPane>
	           <TabPane tabId="2">
	           		<Attendees webinarID={webinarID} token={token} activeTab={activeTab} webinarInfo={webinarInfo}  />
		       </TabPane>
		      	<TabPane tabId="3">
		      		<Chat webinarID={webinarID} token={token} activeTab={activeTab} webinarInfo={webinarInfo}  />
		        </TabPane>
		        {isQuestion ?
		        <TabPane tabId="4">
		        	<Questions webinarID={webinarID} token={token} activeTab={activeTab} webinarInfo={webinarInfo} />
	           </TabPane> : null }
	         </TabContent>
	        </div>
        </div>
		)

	}

};

function mapState(state) {
  const {handRaiseStatus,handRaiseLoading} = state.webinarRoom
  return {
  	handRaiseStatus,
  	handRaiseLoading
  };
}
const actionCreators = {
	handRaiseFun : webinarRoomActions.handRaise
};



export default withRouter(connect(mapState, actionCreators)(RightPanel));