import React, { Component } from "react";
import { withRouter/* , Redirect, Link */ } from "react-router-dom";
import { connect } from "react-redux";
//import Header from '../Header/Header';
import { webinarActions } from "../../Redux/_actions";
// import Spinner from "../../components/UI/Spinner/Spinner";
// import { tsToOnlyDate, tsToTime } from "../../helpers/helpers";
// import { confirmAlert } from "react-confirm-alert";
// import ConfirmationPopup from "../../components/UI/ConfirmationPopup/confirmationPopup";
// import jwt_decode from "jwt-decode";
// import userconfigs from "../../helpers/closures";
import "./HostRegister.css";
import { userService } from './../../Redux/_services';
import MD5 from "crypto-js/md5";
import AuthLayout from "../../Authorization/layout";
import { alertActions } from '../../Redux/_actions';
class HostRegister extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      firstname: "",
      middlename: "",
      lastname: "",
      email: "",
      password: "",
      confirmpassword: "",
      mobile: "",
      designation: "",
      department: "",
      usermode: "",
      selectedFile: null,
      isValidEmail: false,
      userDepartmentList: []
    };
    this.handleChange = this.handleChange.bind(this);
  }
  checkEmail = (email) => {
    const urlregex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line

    return urlregex.test(String(email).toLowerCase());
  };

  handleChange(e) {
    const { name, value } = e.target;
    if (name === "email") {
      this.setState({ isValidEmail: this.checkEmail(value) });
    }
    this.setState({
      [name]: value,
    });
  }
  componentWillMount() {
    this.getUserList();
  }
  async getUserList() {
    let response = await userService.getHostUserByType(`admin/adminuserlist/1/10000`);
    if (response === false) {
      this.props.errorMsg("Something went wrong");
      return;
    }
    this.setState({
      userDepartmentList: response.data
    });
  }

  async handleSubmit(e) {
    e.preventDefault();
    // if(this.state.submitted === true){return ;}
    this.setState({ submitted: true });
    const {
      firstname,
      middlename,
      lastname,
      email,
      password,
      confirmpassword,
      mobile,
      designation,
      department,
      usermode,
      isValidEmail,
      selectedFile
    } = this.state;
    if (
      firstname &&
      middlename &&
      lastname &&
      email &&
      isValidEmail &&
      password &&
      confirmpassword &&
      mobile &&
      designation &&
      department &&
      usermode &&
      selectedFile
    ) {
      let formData = new FormData();
      formData.append('firstname', firstname);
      formData.append('middlename', middlename);
      formData.append('lastname', lastname);
      formData.append('email', email);
      formData.append('password', MD5(password).toString());
      formData.append('confirmpassword', confirmpassword);
      formData.append('mobile', mobile);
      formData.append('designation', designation);
      formData.append('department', department);
      formData.append('usermode', usermode);
      formData.append('uploaded_file', selectedFile);

      try {
        console.log("In the submit", formData);
        let message = await userService.postFormData(formData, 'host-singup');
        message = "Successfully added new user!";
        this.setState({ submitted: false });
        this.props.successMsg(message);
        this.props.history.push('/');
        return false;
      } catch (error) {
        let errMsg = typeof error === "string" ? error : "Something went wrong!!";
        this.props.errorMsg(errMsg);
        return false;
      }
    }
  }
  goBack() {

  }

  handleFileChange = async (event) => {
    this.setState({ selectedFile: event.target.files[0] });
  }

  render() {
    const {
      submitted,
      firstname,
      middlename,
      lastname,
      email,
      password,
      confirmpassword,
      mobile,
      designation,
      department,
      usermode,
      isValidEmail,
      userDepartmentList,
    } = this.state;

    return (
      <AuthLayout
        label="Host register"
        image="/images/login.jpg"
        goBack={() => this.props.history.goBack()}
      >
        <div>
          <div className="register_lgn_frm_bx">
            <div className="w_login_heading">
              <h4>SignUp</h4>
              <p>Fill credentials to create your account.</p>
            </div>
            <form
              name="form"
              method="post"
              onSubmit={(e) => {
                this.handleSubmit(e);
              }}
            >
              <div className="row ml-0 mr-0">
                <div className="col-4 col-md-4  col-12 align-self-center align-self-end pd-0">
                  <div className="_wform_group">
                    <div className="_wform_group_prepend">
                      <input
                        type="text"
                        className="_wform_form_cntrl"
                        name="firstname"
                        value={firstname}
                        onChange={this.handleChange}
                        placeholder="First Name"
                      />
                    </div>
                    <div className="error_msg_host_register">
                      {submitted && !firstname ? "Firstname is required" : ""}
                    </div>
                  </div>
                </div>
                <div className="col-4 col-md-4  col-12 align-self-center align-self-end pd-0">
                  <div className="_wform_group">
                    <div className="_wform_group_prepend">
                      <input
                        type="text"
                        className="_wform_form_cntrl"
                        name="middlename"
                        value={middlename}
                        onChange={this.handleChange}
                        placeholder="Middle Name"
                      />
                    </div>
                    <div className="error_msg_host_register">
                      {submitted && !middlename ? "Middlename is required" : ""}
                    </div>
                  </div>
                </div>
                <div className="col-4 col-md-4  col-12 align-self-center align-self-end pd-0">
                  <div className="_wform_group">
                    <div className="_wform_group_prepend">
                      <input
                        type="text"
                        className="_wform_form_cntrl"
                        name="lastname"
                        value={lastname}
                        onChange={this.handleChange}
                        placeholder="Last Name"
                      />
                    </div>
                    <div className="error_msg_host_register">
                      {submitted && !lastname ? "Lastname is required" : ""}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row ml-0 mr-0">
                <div className="col-12 align-self-center  align-self-end pd-0">
                  <div className="_wform_group">
                    <div className="_wform_group_prepend">
                      <input
                        type="text"
                        className="_wform_form_cntrl"
                        name="email"
                        value={email}
                        onChange={this.handleChange}
                        placeholder="Email"
                      />
                    </div>
                    <div className="error_msg_host_register">
                      {submitted && !email
                        ? "Email is required"
                        : submitted && !isValidEmail
                          ? "Email is invalid"
                          : ""}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row ml-0 mr-0">
                <div className="col-6 col-md-6 col-12 align-self-center  align-self-end pd-0">
                  <div className="_wform_group">
                    <div className="_wform_group_prepend">
                      <input
                        type="password"
                        className="_wform_form_cntrl"
                        name="password"
                        value={password}
                        onChange={this.handleChange}
                        placeholder="Password"
                      />
                    </div>
                    <div className="error_msg_host_register">
                      {submitted && !password ? "Password is required" : ""}
                    </div>
                  </div>
                </div>

                <div className="col-6 col-md-6 col-12 align-self-center  align-self-end pd-0">
                  <div className="_wform_group">
                    <div className="_wform_group_prepend">
                      <input
                        type="password"
                        className="_wform_form_cntrl"
                        name="confirmpassword"
                        value={confirmpassword}
                        onChange={this.handleChange}
                        placeholder="Confirm Password"
                      />
                    </div>
                    <div className="error_msg_host_register">
                      {submitted && !confirmpassword
                        ? "Confirmpassword is required"
                        : ""}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row ml-0 mr-0">
                <div className="col-6 col-md-6 col-12 align-self-center  align-self-end pd-0">
                  <div className="_wform_group">
                    <div className="_wform_group_prepend">
                      <input
                        type="text"
                        className="_wform_form_cntrl"
                        name="mobile"
                        value={mobile}
                        onChange={this.handleChange}
                        placeholder="Phone Number"
                      />
                    </div>
                    <div className="error_msg_host_register">
                      {submitted && !mobile ? "Mobile is required" : ""}
                    </div>
                  </div>
                </div>

                <div className="col-6 col-md-6 col-12 align-self-center  align-self-end pd-0">
                  <div className="_wform_group">
                    <div className="_wform_group_prepend">
                      <input
                        type="text"
                        className="_wform_form_cntrl"
                        name="designation"
                        value={designation}
                        onChange={this.handleChange}
                        placeholder="designation"
                      />
                    </div>
                    <div className="error_msg_host_register">
                      {submitted && !designation
                        ? "Designation is required"
                        : ""}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row ml-0 mr-0">
                <div className="col-6 col-md-6 col-12 align-self-center  align-self-end pd-0">
                  <div className="_wform_group">
                    <div className="_wform_group_prepend">
                      <select
                        name="department"
                        id="department"
                        className="_wform_form_cntrl"
                        value={department}
                        onChange={this.handleChange}
                      >
                        <option value="" disabled className="gray-color">
                          Select Department
                        </option>
                        {userDepartmentList && userDepartmentList.map((obj, index) => (
                          <option value={obj.uuid} key={index}>{obj.deparment_name ? obj.deparment_name : obj.name}</option>
                        ))}
                      </select>
                    </div>
                    <div className="error_msg_host_register">
                      {submitted && !department ? "Department is required" : ""}
                    </div>
                  </div>
                </div>

                <div className="col-6 col-md-6 col-12 align-self-center  align-self-end pd-0">
                  <div className="_wform_group">
                    <div className="_wform_group_prepend">
                      <select
                        name="usermode"
                        id="usermode"
                        className="_wform_form_cntrl"
                        value={usermode}
                        onChange={this.handleChange}
                      >
                        <option value="" disabled className="gray-color">
                          Select Mode
                        </option>
                        <option value="meeting">Metting</option>
                        <option value="webinar">Webinar</option>
                      </select>
                    </div>
                    <div className="error_msg_host_register">
                      {submitted && !usermode ? "Usermode is required" : ""}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row ml-0 mr-0">
                <div className="col-12 align-self-center  align-self-end pd-0">
                  <div className="_wform_group">
                    <div className="_wform_group_prepend">
                      <input
                        type="file"
                        id="myfile"
                        name="myfile"
                        onChange={this.handleFileChange}
                        className="_wform_form_cntrl"
                      />{" "}
                      <span className="imp-mark">Only PDG, JPG, JPEG, PNG</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="btn-flex mt-2">
                <button type="submit" className="btn btn-primary btn-login ">
                  Signup
                </button>
              </div>
            </form>
          </div>
        </div>
      </AuthLayout>
    );
  }
}

const mapState = (state) => {
  return {
    listLoading: state.webinar.listLoading,
    webinarList: state.webinar.webinarList,
  };
};

const actionCreators = {
  webinarListFun: webinarActions.webinarList,
  deleteWebinarFun: webinarActions.deleteWebinar,
  errorMsg: alertActions.error,
  successMsg: alertActions.success
};

export default withRouter(connect(mapState, actionCreators)(HostRegister));
