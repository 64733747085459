/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, } from "react";
import { Button, Form, InputGroup, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "is-empty";
import * as Yup from 'yup';
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
// import DateTimeInput from 'react-datetime-picker/dist/DateTimeInput';
import { AiOutlineSearch } from "react-icons/ai";
import { confirmAlert } from "react-confirm-alert";
import Layout from "../../layoutSection";
import { eventActions, userActions, alertActions, scheduleActions, } from "../../Redux/_actions";
import Spinner from "../../components/UI/Spinner/Spinner";
import MeetCard from './meetCard'
import ConfirmationPopup from '../../components/UI/ConfirmationPopup/confirmationPopup'
import EditMeet from './EditMeet'
import CustomModal from "../../layoutSection/Modal";
import InfiniteScroll from 'react-infinite-scroller';
import "./styles.scss";
// import VideoRecording from './VideoRec'
// import CreateMeet from './CreateMeet/index_old'
// import DateTimePicker from 'react-datetime-picker';


var firstTimeDataGetting = false;

const MeetingList = (props) => {
  const dispatch = useDispatch();
  const { meetingHistory, meetIsLoading, gAuthURL, syncLoading, event, gAuthTokenVerified, meetingIsMore } = useSelector((state) => state.event);
  const { gContactAuthInfo, gContactAuthURL, gContactSyncLoading } = useSelector((state) => state.authentication);
  const [filter, setFilter] = useState({
    title: "",
    startDate: null,
    endDate: null,
    type: "",
    action: "all",
  });
  const [focus, setFocus] = useState(null);
  const [meetingList, setMeetingList] = useState([]);
  const [showModal, setShowModal] = useState({
    createMeet: false,
    editMeet: false,
    id: null,
    record: false,
  });
  // const baseClassName = 'react-datetimerange-picker';

  useEffect(() => {
    dispatch(eventActions.gAuthURL());
    dispatch(userActions.gContactAuthURL());
  }, []);

  useEffect(() => {
    const from = new Date(filter.startDate);
    const to = new Date(filter.endDate);
    dispatch(eventActions.meetingHistoryList({ type: filter.action, from: filter.startDate ? from.getTime() : null, to: filter.endDate ? to.getTime() : null, skip: 0, limit: 30 }));
  }, [filter.action, filter.endDate]);

  useEffect(() => {
    // setMeetingList(meetingHistory);
    const { title } = filter;
    filterByInput(title);
  }, [meetingHistory]);

  const filterByInput = (value) => {
    if (!isEmpty(meetingHistory)) {
      let result = meetingHistory;
      if (!isEmpty(value)) {
        const res = result.filter((data) =>
          data?.meetingdetails[0].name?.toLowerCase().includes(value.toLowerCase())
        );
        if (isEmpty(res)) {
          setMeetingList("No more result!");
        } else {
          setMeetingList(res);
        }
      } else {
        setMeetingList(meetingHistory);
      }
    }
  }

  const inputHandler = (value) => {
    setFilter({ ...filter, title: value });
    filterByInput(value)
  }

  const setState = (type) => {
    setShowModal({ ...showModal, [type]: !showModal[type] });
  };

  const openGoogleAuth = (auth) => {
    // win = window.open(googleAuthURL.authURL, "win", "width=750,height=660,top=70,left=100,resizable=1,menubar=yes");
    // win.focus();
    // setInterval(function () {
    //     if (win.closed) {
    //         window.location.reload();
    //         clearInterval(win);
    //     }
    // }, 500);
    if (auth !== null) {
      if (typeof win === undefined) {
        var win;
      }
      if (win && !win.closed) {
        win.focus();
      } else {
        let x = window.screen.width / 2 - 550 / 2;
        //let y = 0;
        let REDIRECT = "meetings";
        let y = window.screen.height / 2 - 280 / 2;
        win = window.open(
          auth,
          "win",
          "width=550,height=560,left=" +
          x +
          ",top=" +
          y +
          ",resizable=1,menubar=yes"
        );
        win.focus();
        var pollTimer = window.setInterval(function () {
          try {
            if (win.document.URL.indexOf(REDIRECT) !== -1) {
              window.clearInterval(pollTimer);
              var url = win.document.URL;
              let gCode = gup(url, "code");
              //let tokenType = gup(url, 'token_type');
              //let expiresIn = gup(url, 'expires_in');
              win.close();
              if (
                gCode !== null &&
                gCode !== "null" &&
                gCode !== "undefined" &&
                typeof gCode !== undefined
              ) {
                dispatch(eventActions.gAuthToken(gCode));
              }
            }
          } catch (e) {
            if (win.closed) {
              window.clearInterval(pollTimer);
              dispatch(alertActions.error("Authorization Failed !"));
            }
          }
        }, 500);

        function gup(url, name) {
          name = name.replace(/[[]/, "[").replace(/[]]/, "]");
          var regexS = "[?&]" + name + "=([^&#]*)";
          var regex = new RegExp(regexS);
          var results = regex.exec(url);
          if (results == null) return "";
          else return results[1];
        }
      }
    }
  };

  const deleteMeetingConfirm = (meetingID) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmationPopup
            message={"Are you sure want to delete this meeting ?."}
            noBtnAction={onClose}
            noBtnText="No"
            yesBtnText="Yes, Delete AnyWay!"
            yesBtnAction={() =>
              dispatch(scheduleActions.deleteSchedule(meetingID, filter.action))
            }
          />
        );
      },
    });
  };

  const deleteMeetingConfirmRecurring = (meetingID, mid) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <CustomModal
            show={true}
            toggle={() => onClose()}
            label={<h3> Are you sure want to delete this meeting ?. </h3>}
          >
            <div className="file_sharng_sct text-center">
              <div className="around mt-3	 w-100 text-center">
                <button
                  onClick={() => onClose()}
                  className="btnStyles  btn-secondary"
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    onClose()
                    dispatch(scheduleActions.deleteSchedule(meetingID, filter.action, mid))
                  }}
                  className="btnStyles  btn-secondary"
                >
                  Only this occurrences
                </button>
                <button
                  className="btnStyles btn-danger mr-3"
                  onClick={() => {
                    onClose()
                    dispatch(scheduleActions.deleteSchedule(meetingID, filter.action))
                  }}
                >
                  All occurrences
                </button>
              </div>
            </div>
          </CustomModal>
        );
      },
    });
  };

  const LeftHeader = () => (
    <>
      {!gAuthTokenVerified && !gAuthURL?.verified && gContactAuthInfo && gContactAuthInfo?.length === 0 && (
        <Button
          className="newWebinar-btn submitSubColor border-0 mr-2 font-weight-bold"
          onClick={() => openGoogleAuth(gAuthURL)}
        >
          {gContactSyncLoading ? (
            "Loading..."
          ) : (
            <>
              <i className="fa fa-address-book "></i>
              <span className="headerBtnLabel" > Authorize to Google </span>
            </>
          )}
        </Button>
      )}

      <Button
        className="newWebinar-btn border-0 font-weight-bold"
        variant="primary"
        onClick={() => props.history.push("/schedule_meeting")}
      >
        <i className="far fa-calendar-plus "> </i>
        <span className="headerBtnLabel ml-1">Schedule Meeting </span>
      </Button>
    </>
  );

  // const loader = (
  //   <div key="loader" className="text-center">
  //     <Spinner />
  //   </div>
  // );

  const callApiList = () => {
    if (!meetIsLoading && !firstTimeDataGetting) {
      const from = new Date(filter.startDate);
      const to = new Date(filter.endDate);
      const params = {
        type: filter.action,
        from: filter.startDate ? from.getTime() : null,
        to: filter.endDate ? to.getTime() : null,
        skip: meetingHistory?.length || 0,
        limit: 30
      }
      dispatch(eventActions.meetingHistoryList(params));
    }
  }

  // react-datetime-picker
  const [daterange, setOnChange] = useState([new Date((+new Date()) - 30 * 24 * 60 * 60 * 1000), new Date()]);
  const onChangeDateTime = (value) => {
    let startDate = null;
    let endDate = null;
    if (value) {
      startDate = +new Date(value[0]);
      endDate = +new Date(value[1]);
      setFilter({ ...filter, startDate, endDate })
      setOnChange(value)
    } else {
      // startDate = null;
      // endDate = null;
      setFilter({ ...filter, startDate, endDate })
    }

    setMeetingList([])
    // const params = {
    //   type: filter.action,
    //   from: startDate,
    //   to: endDate,
    //   skip: 0,
    //   limit: 30
    // }
    // dispatch(eventActions.meetingHistoryList(params));
  }

  const validation = Yup.object().shape({
    description: Yup.string().matches(/^[a-zA-Z0-9\s]*$/, "Invalid meeting title"),
  })

  function handleKeyPress(e) {
    let key = e.key;
    let regex = /[^a-zA-Z0-9 ]/g;
    if (regex.test(key)) {
      e.preventDefault();
    }
  }

  return (
    <Layout title={<LeftHeader />} active="Meeting"  >
      <div className="container-fluid s_wave_content_part meeting" onScroll={(event) => console.log(event, "it call")}>
        {/* {showModal.createMeet && (
          <CreateMeet
            show={showModal.createMeet}
            toggle={() => setState("createMeet")}
          />
        )} */}
        {showModal.editMeet && (
          <EditMeet
            show={showModal.editMeet}
            toggle={() => setState("editMeet")}
            id={showModal.id}
          />
        )}
        <Row className="justify-content-between webinar mt-3" validationSchema={validation}>
          <Form.Group className="searchSectionWebinar searchBarFilter">
            <InputGroup hasValidation>
              <InputGroup.Text className="welcomeMsg sideBar inputGroup">
                <AiOutlineSearch className="f_size_26" />
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Search by Meeting Title"
                onKeyPress={(e) => handleKeyPress(e)}
                onChange={(event) =>
                  inputHandler(event.target.value)
                }
                name="title"
                className="searchInput welcomeMsg sideBar h55 searchInputFilter"
                aria-describedby="inputGroupPrepend"
                value={filter.title}
              />
              <InputGroup.Text className="sideBar inputGroupCancel ">
                {!isEmpty(filter.title) && (
                  <button
                    className="btnBgLess welcomeMsg"
                    onClick={() => inputHandler("")}
                  >
                    X
                  </button>
                )}
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <Row className="filterButtonsRes">
            <button
              className={`meetSectionUpload allButton  ${filter.action === "all"
                ? "submitBtnColor text-white"
                : "sideBar welcomeMsg"
                } `}
              onClick={() => setFilter({ ...filter, action: "all" })}
            >
              All
            </button>
            <button
              className={`meetSectionUpload upcomingButton  ${filter.action === "upcoming"
                ? "submitBtnColor text-white"
                : "sideBar welcomeMsg"
                } `}
              onClick={() => setFilter({ ...filter, action: "upcoming" })}
            >
              Upcoming Meetings
            </button>
            <button
              className={`meetSectionUpload previousMeet ${filter.action === "previous"
                ? "submitBtnColor text-white"
                : "sideBar welcomeMsg"
                } `}
              onClick={() => setFilter({ ...filter, action: "previous" })}
            >
              Previous Meetings
            </button>
          </Row>
          <div className="dateRangePickerFormAndTo">
            {/* <DateTimeRangePicker
              startDate={filter.startDate}
              startDatePlaceholderText="From"
              endDatePlaceholderText="To"
              startDateId="startingDate"
              endDate={filter.endDate}

              endDateId="EndingDate"
              onDatesChange={({ startDate, endDate }) =>
                setFilter({ ...filter, startDate, endDate })
              }
              focusedInput={focus}
              onFocusChange={(focusedInput) => setFocus(focusedInput)}
              monthFormat="MMMM YYYY"
              showClearDates
              readOnly
              isOutsideRange={() => false}
            /> */}

            <DateTimeRangePicker
              onChange={(value) => onChangeDateTime(value)}
              value={daterange}
              // name='datetimerange'
              // format="y-MM-dd"
              format="dd/MM/y"
              className="react-daterange-picker br-10"
              // dayPlaceholder={"From"}
              // hourAriaLabel={"From"}
              rangeDivider={"--> "}
            />

          </div>
        </Row>
        <div className="meeting-list pt-3">
          <InfiniteScroll
            loadMore={() => callApiList()}
            hasMore={meetingIsMore}
            // loader={loader}
            initialLoad={meetIsLoading}
          >
            <ul className="pl-0">
              {
                (meetingHistory?.length > 0 && typeof meetingList !== "string") ?
                  meetingList.map((data, index) => (
                    <div className="meetCardsMargin" key={`meeting-${index}`}>
                      {data !== null &&
                        <MeetCard
                          meetingInfo={data}
                          history={props.history}
                          gAuthURL={gAuthURL}
                          syncLoading={syncLoading}
                          openGoogleAuth={() => openGoogleAuth(gAuthURL)}
                          gAuthTokenVerified={gAuthTokenVerified}
                          synchGoogle={() =>
                            dispatch(eventActions.addEventOnGoogle(data.meetingID))
                          }
                          deleteMeetingConfirm={(id) => deleteMeetingConfirm(id)}
                          deleteMeetingConfirmRecurring={(id, mid) => deleteMeetingConfirmRecurring(id, mid)}
                          editMeet={() =>
                            setShowModal({
                              ...showModal,
                              editMeet: !showModal.editMeet,
                              id: data.meetingID,
                            })
                          }
                          showRecode={() =>
                            setShowModal({
                              ...showModal,
                              record: !showModal.record,
                              id: data.meetingID,
                            })
                          }
                          event={event}
                          AllType={filter.action === "all"}
                        />}

                    </div>
                  ))
                  : (!meetingIsMore) ?
                    <div className="resultNotFound sideBarIcon welcomeMsg">
                      No Meeting Available
                    </div>
                    :
                    <div className="resultNotFound sideBarIcon welcomeMsg">
                      Searching for results...
                    </div>
              }
            </ul>
          </InfiniteScroll>
        </div>
      </div>
    </Layout>
  );
};

export default MeetingList;
