export const webinarConstants = {
  SCHEDULE_REQUEST: "SCHEDULE_REQUEST",
  SCHEDULE_SUCCESS: "SCHEDULE_SUCCESS",
  SCHEDULE_FAILURE: "SCHEDULE_FAILURE",

  LIST_REQUEST: "LIST_REQUEST",
  LIST_SUCCESS: "LIST_SUCCESS",
  LIST_FAILURE: "LIST_FAILURE",

  DETAIL_REQUEST: "DETAIL_REQUEST",
  DETAIL_SUCCESS: "DETAIL_SUCCESS",
  DETAIL_FAILURE: "DETAIL_FAILURE",

  FIELDS_REQUEST: "FIELDS_REQUEST",
  FIELDS_SUCCESS: "FIELDS_SUCCESS",
  FIELDS_FAILURE: "FIELDS_FAILURE",

  CSV_REQUEST: "CSV_REQUEST",
  CSV_SUCCESS: "CSV_SUCCESS",
  CSV_FAILURE: "CSV_FAILURE",

  REGISTER_REQUEST: "REGISTER_REQUEST",
  REGISTER_SUCCESS: "REGISTER_SUCCESS",
  REGISTER_FAILURE: "REGISTER_FAILURE",

  ADD_PARTICIPANT_START: "ADD_PARTICIPANT_START",
  ADD_PARTICIPANT_SUCCESS: "ADD_PARTICIPANT_SUCCESS",
  ADD_PARTICIPANT_FAILURE: "ADD_PARTICIPANT_FAILURE",

  INFO_REQUEST: "INFO_REQUEST",
  INFO_SUCCESS: "INFO_SUCCESS",
  INFO_FAILURE: "INFO_FAILURE",

  ADD_HOST_START: "ADD_HOST_START",
  ADD_HOST_SUCCESS: "ADD_HOST_SUCCESS",
  ADD_HOST_FAILURE: "ADD_HOST_FAILURE",

  BRANDING_REQUEST: "BRANDING_REQUEST",
  BRANDING_SUCCESS: "BRANDING_SUCCESS",
  BRANDING_FAILURE: "BRANDING_FAILURE",

  BRAND_REQUEST: "BRAND_REQUEST",
  BRAND_SUCCESS: "BRAND_SUCCESS",
  BRAND_FAILURE: "BRAND_FAILURE",

  WEBINAR_CANCEL_START: "WEBINAR_CANCEL_START",
  WEBINAR_CANCEL_SUCCESS: "WEBINAR_CANCEL_SUCCESS",
  WEBINAR_CANCEL_FAILURE: "WEBINAR_CANCEL_FAILURE",

  WEBINAR_DELETE_START: "WEBINAR_DELETE_START",
  WEBINAR_DELETE_SUCCESS: "WEBINAR_DELETE_SUCCESS",
  WEBINAR_DELETE_FAILURE: "WEBINAR_DELETE_FAILURE",

  BRAND_KEY_REMOVE_START: "BRAND_KEY_REMOVE_START",
  BRAND_KEY_REMOVE_SUCCESS: "BRAND_KEY_REMOVE_SUCCESS",
  BRAND_KEY_REMOVE_FAILURE: "BRAND_KEY_REMOVE_FAILURE",

  BRAND_DELETE_START: "BRAND_DELETE_START",
  BRAND_DELETE_SUCCESS: "BRAND_DELETE_SUCCESS",
  BRAND_DELETE_FAILURE: "BRAND_DELETE_FAILURE",

  GET_BITS_START: "GET_BITS_START",
  GET_BITS_SUCCESS: "GET_BITS_SUCCESS",
  GET_BITS_FAILURE: "GET_BITS_FAILURE",

  JOIN_REQUEST: "JOIN_REQUEST",
  JOIN_SUCCESS: "JOIN_SUCCESS",
  JOIN_FAILURE: "JOIN_FAILURE",

  ADD_IN_ROOM_START: "ADD_IN_ROOM_START",
  ADD_IN_ROOM_SUCCESS: "ADD_IN_ROOM_SUCCESS",
  ADD_IN_ROOM_FAILURE: "ADD_IN_ROOM_FAILURE",

  REMOVE_SPEAKER_START: "REMOVE_SPEAKER_START",
  REMOVE_SPEAKER_SUCCESS: "REMOVE_SPEAKER_SUCCESS",
  REMOVE_SPEAKER_FAILURE: "REMOVE_SPEAKER_FAILURE",

  SPEAKER_DETAIL_START: "SPEAKER_DETAIL_START",
  SPEAKER_DETAIL_SUCCESS: "SPEAKER_DETAIL_SUCCESS",
  SPEAKER_DETAIL_FAILURE: "SPEAKER_DETAIL_FAILURE",

  SPEAKER_UPDATE_START: "SPEAKER_UPDATE_START",
  SPEAKER_UPDATE_SUCCESS: "SPEAKER_UPDATE_SUCCESS",
  SPEAKER_UPDATE_FAILURE: "SPEAKER_UPDATE_FAILURE",
  WR_GET_BITS_SUCCESS: "WR_GET_BITS_SUCCESS",

  DELETE_INVITE_START: "DELETE_INVITE_START",
  DELETE_INVITE_SUCCESS: "DELETE_INVITE_SUCCESS",
  DELETE_INVITE_FAILURE: "DELETE_INVITE_FAILURE",

  UPDATE_REQUEST: "UPDATE_REQUEST",
  UPDATE_SUCCESS: "UPDATE_SUCCESS",
  UPDATE_FAILURE: "UPDATE_FAILURE",

  RE_INVITE_START : 'RE_INVITE_START',
  RE_INVITE_SUCCESS : 'RE_INVITE_SUCCESS',
  RE_INVITE_FAILURE : 'RE_INVITE_FAILURE',
};
