import { myconfig, siteTokens } from '../../config/config';
import axios from 'axios';
import userconfigs from '../../helpers/closures';
import { handleResponse, errorCheck } from './common'


export const accountService = {
  meetingsCount,
  sharedFiles,
  fileShare,
  deleteSharedFiles,
  attachFile
};

function meetingsCount() {

  let axiosRequestOptions = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': userconfigs.getItem('jwt'),
    }
  };

  return axios.get(`${myconfig.apiURL}meeting/meetingCounts`, axiosRequestOptions)
    .then(handleResponse)
    .then(resp => {
      return resp;
    })
    .catch((error) => {
      if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925) ) {
        handleJWTError();
        // userconfigs.removeItem("jwt");
        // generateToken();
        // window.location.href = "/login";
      } else {
        return Promise.reject(errorCheck(error));
      }
      //return error.response.data.error;
    });
}


function sharedFiles(limit) {

  let axiosRequestOptions = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': userconfigs.getItem('jwt'),
    }
  };

  let postData = {}

  if (limit) {
    postData = {
      "limit": limit
    }
  }

  return axios.post(`${myconfig.apiURL}drive/shareFileList`, postData, axiosRequestOptions)
    .then(handleResponse)
    .then(resp => {
      return resp;
    })
    .catch((error) => {
      if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
        handleJWTError();
        // userconfigs.removeItem("jwt");
        // generateToken();
        // window.location.href = "/login";
      } else {
        return Promise.reject(errorCheck(error));
      }
      //return error.response.data.error;
    });
}

function fileShare(path) {
  let axiosRequestOptions = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': userconfigs.getItem('jwt'),
    }
  };

  let axiosPostData = {
    filePath: path
  }

  return axios.post(`${myconfig.apiURL}drive/shareFile`, axiosPostData, axiosRequestOptions)
    .then(handleResponse)
    .then(url => {
      return url;
    })
    .catch((error) => {
      if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
        handleJWTError();
        // userconfigs.removeItem("jwt");
        // generateToken();
        // window.location.href = "/login";
      } else {
        return Promise.reject(errorCheck(error));
      }
    })
}


function deleteSharedFiles(sharedId) {

  let axiosRequestOptions = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': userconfigs.getItem('jwt'),
    }
  };

  let axiosPostData = {
    id: sharedId
  }

  return axios.post(`${myconfig.apiURL}drive/deleteSharedFile`, axiosPostData, axiosRequestOptions)
    .then(handleResponse)
    .then(resp => {
      return resp;
    })
    .catch((error) => {
      if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
        handleJWTError();
        // userconfigs.removeItem("jwt");
        // generateToken();
        // window.location.href = "/login";
      } else {
        return Promise.reject(errorCheck(error));
      }
    });
}

function attachFile(path) {
  let axiosRequestOptions = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': userconfigs.getItem('jwt'),
    }
  };

  let axiosPostData = {
    filePath: path
  }

  return axios.post(`${myconfig.apiURL}drive/conferenceFile`, axiosPostData, axiosRequestOptions)
    .then(handleResponse)
    .then(resp => {
      return resp;
    })
    .catch((error) => {
      if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
        handleJWTError();
        // userconfigs.removeItem("jwt");
        // generateToken();
        // window.location.href = "/login";
      } else {
        return Promise.reject(errorCheck(error));
      }
    })
}




function handleJWTError() {
  siteTokens.map(site => {
    const currentHostName = window.location.hostname;
    if (currentHostName === site.url) {
      userconfigs.removeItem("jwt");
      userconfigs.removeItem(site.token);
      //console.log(site.token)
      window.location.reload();
    }
    return true;
  })
}