import React from 'react'

const Presentation = () => {
    return (
        <div className="container-fluid pt-140 webinar-flow">

         <div className="container presentation-section position-relative">
            <div className="col-lg-12 text-center">
                    <h2 className="heading">Rich Presentation Feature With <span>Stable Build</span> </h2>
               </div>
             <div className="circle circle-one">Share Screen<br/> Without<br/> Whiteboard</div>
             <div className="circle circle-two">Easy presentation showcase</div>
             <div className="circle circle-three">More zoom on<br/> HD whiteboard</div>
             <div className="whiteboard-tollbar">
               <img src="images/homePage/whiteboard-tollbar.png"  alt="Whiteboard Tollbar"  />
             </div>
               <div className="col-lg-12 text-center mob-plr-0">
                 <img src="images/homePage/presentation-dashboard.png"  alt="Rich Presentation Feature" className="img-responsive presentaion-wireframe" />
               </div>
      
                <div className="circle circle-four">Simple and understandable Interface</div>
             <div className="circle circle-five">Collaborate in <br/>real-time</div>
           </div>
      
        
      
                  <div className="container mob-plr-0 webinar-feature">
                     <h2 className="text-center heading">What makes our  <span  style={{color:"#faca78"}}>Webinar Platform</span> different?</h2>
                      <div className="row  ">
                         <div className="col-lg-3 col-md-12 col-sm-3 col-12 " >
                           <ul className="list-item">
                             <li>
                               <img  src="images/homePage/icon-easy-browser.svg" alt="Easy Browser Access" width="50" />
                               <h6>Easy Browser Access</h6>
                               <p>The lightweight tool provides seamless experience to users as a web app </p>
                             </li>
       
                             <li>
                               <img  src="images/homePage/icon-data-saving.svg" alt="Data Saving" width="50" />
                               <h6>Data Saving</h6>
                               <p>Users can attend meetings even with weak internet connection using data saving.
                               </p>
                             </li>
       
                             <li>
                               <img  src="images/homePage/icon-secure.svg" alt="Secure Conference" width="50" />
                               <h6>Secure Conference</h6>
                               <p>End to End encryption, Complete data is encrypted before being transmitted over the Internet </p>
                             </li>
       
                           </ul>
                         </div>
                         <div className="col-lg-6 col-md-12 col-sm-6 col-12 text-center webinar-flow-mid" >
                           <img src="images/homePage/webinar-flow.png" alt="Webinar Platform Different" className="img-responsive webinar-flow-img" />
                           
                         </div>
                         <div className="col-lg-3 col-md-12 col-sm-3 col-12" >
                           <ul className="list-item">
                             <li>
                               <img  src="images/homePage/icon-video-conference.svg" alt="Clear HD Quality" width="50" />
                               <h6>Clear HD Quality</h6>
                               <p>Users will get to experience HD quality of videos while in meetings. </p>
                             </li>
       
                             <li>
                               <img  src="images/homePage/icon-report.svg" alt="Reporting & analytics" width="50" />
                               <h6>Reporting & analytics</h6>
                               <p>Post meeting reports and recordings are available to be downloaded.
                               </p>
                             </li>
       
                             <li>
                               <img  src="images/homePage/icon-host-control.svg" alt="Full-featured host controls" width="50" />
                               <h6>Full-featured host controls</h6>
                               <p>Host can control access to mic, presentation, screen-share and many others.
                               </p>
                             </li>
       
                           </ul>
                         </div>
                      </div>
                   </div>
               </div>
    )
}

export default Presentation;
