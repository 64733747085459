import React from 'react'

const Solution = () => {
    return (
        <div className="container-fluid bg-perpal webinar-solution position-relative">
        <div className="shape-devider shape-devider-top"></div>
       <div className="container my-5">               
             <div className="row col-reverse">
               <div className="col-md-6 col-12">
                  <div className="row">
                    <div className="col-md-6 col-12">
                      <div className="feature-col">
                       <img  src="images/homePage/raise-hand.svg" alt="Raise Hands" width="30" />
                       <h6 className="pt-3">Raise Hands</h6>
                       <p>Attendees can raise hands to share any inputs in the ongoing meeting. The speaker has the custom right to unmute them </p>
                    </div>
                  </div>

                   <div className="col-md-6 col-12">
                      <div className="feature-col">
                       <img  src="images/homePage/chat.svg" alt="Chat" width="30" />
                       <h6 className="pt-3">Chat</h6>
                       <p>Everyone present in the meeting will be able to initiate a conversation over text. It can be either public chat or privately with others. </p>
                    </div>
                  </div>

                   <div className="col-md-6 col-12">
                      <div className="feature-col">
                       <img  src="images/homePage/question.svg" alt="Q&As" width="40" />
                       <h6 className="pt-3">Q&As</h6>
                       <p>A section separate from the Chat will be present for Q&As to be asked in the meeting.
                       </p>
                    </div>
                  </div>

                   <div className="col-md-6 col-12">
                      <div className="feature-col">
                       <img  src="images/homePage/speaker.svg" alt="Raise Hands" width="40" />
                       <h6 className="pt-3">Speakers</h6>
                       <p>There will be one host but multiple speakers can be present as per the requirement of that meeting. Host can make any attendee as speaker.
                       </p>
                    </div>
                  </div>

                </div>

               </div> 

               <div className="col-md-6 col-12">
                <h2 className="text-center heading mt-90">Reliable and scalable <br/>Webinar Solution</h2>
                 <div className="col-md-12 col-12 mob-plr-0">
                      <div className="feature-col mt-5">
                       <h6 className="pt-3">Screen Share</h6>
                       <p>Another important feature will be to share the screen. Hosts can allow certain users to share the screen.
                       </p>
                    </div>
                  </div>
              </div>

             </div>
          
       </div>
        <div className="shape-devider shape-devider-bottom"></div>
      </div>
    )
}
export default Solution;
