import React from 'react'

const Connect = () => {
    return (
        <div className="container-fluid bgdark connect-section position-relative">
         <div className="shape-devider shape-devider-top"></div>
           <div className="container">
              <div className="row">
                 <div className="col-lg-6 col-md-6 col-sm-12 col-12" >
                   
                       <img src="images/homePage/dotted-img.svg" width="170" alt="Sarv wave Webinar" className="dotted-img-one" />
                       <h2 className="heading">Connect and Engage<br/><span>Audiences Everywhere</span>  </h2>
                       <img src="images/homePage/dotted-img.svg" width="170" alt="Sarv wave Webinar" className="dotted-img-two" />
                   
                 </div>
                 <div className="col-lg-6 col-md-6 col-sm-12 col-12 circle-rgt" >
                    <div className="circle circle-one">Training</div>
                    <div className="circle circle-two">Marketing</div>
                    <div className="circle circle-three">Goverment<br/>Communication</div>
                 </div>
              </div>
           </div>
        </div>
    )
}

export default Connect;
