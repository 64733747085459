import React, { Component } from 'react';
import "./waitForModerator.css"
import WaitingHeader from '../waitingHeader'

class WaitForModerator extends Component {
	
	constructor(props){
		super(props);
		this.state = {
			mid : this.props.mid,
			mname: this.props.mname
		}
	}


	render() {
		const { mid, mname } = this.state;
		return (
			<div className="wave_wating_sctn">
    			<WaitingHeader mname={mname} mid={mid} />
    			<div className="wave_waiting_contianer_msg">
			        <div className="wait_msg">
			           Thank you for joining meeting 
			           <span>Please wait, the meeting host will be joining the waiting room shortly.</span>
			      	</div>  

	        		<div className="wave_wait_timer text-center">
	            		<i className="far fa-clock"></i> Waiting For Meeting Host 
	        		</div>  
	    		</div>    
			</div>
		)
	}
}

export default WaitForModerator;