import { myconfig, siteTokens } from '../../config/config';
import axios from 'axios';
import userconfigs from '../../helpers/closures';
import { handleResponse, errorCheck, handleJWTError } from './common'

export const companyService = {
    registerCompany,
    saveCompanyInfo,
    uploadLogo,
    getCompanyBranding,
    decline,
    accept,
    exitBusiness,
    checkCompanyEmail
};

function registerCompany(formData) {
    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };
    //myconfig.apiURL = 'http://localhost:3001/';

    return axios.post(`${myconfig.apiURL}company/register`, formData, axiosRequestOptions)
        .then(handleResponse)
        .then(msg => {
            return msg;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}

function checkCompanyEmail(formData) {
    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };
    //myconfig.apiURL = 'http://localhost:3001/';

    return axios.post(`${myconfig.apiURL}company/checkemailexits`, formData, axiosRequestOptions)
        .then(handleResponse)
        .then(msg => {
            return msg;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}

function saveCompanyInfo(formData) {
    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };
    //myconfig.apiURL = 'http://localhost:3001/';

    return axios.post(`${myconfig.apiURL}Setting/company/branding/info`, formData, axiosRequestOptions)
        .then(handleResponse)
        .then(msg => {
            return msg;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}

function decline(formData) {
    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };

    //myconfig.apiURL = 'http://localhost:3001/';
    return axios.post(`${myconfig.apiURL}company/decline`, formData, axiosRequestOptions)
        .then(handleResponse)
        .then(msg => {
            return msg;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}

function accept(formData) {
    console.log(formData)
    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };

    //myconfig.apiURL = 'http://localhost:3001/';
    return axios.post(`${myconfig.apiURL}company/accept`, formData, axiosRequestOptions)
        .then(handleResponse)
        .then(msg => {
            return msg;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}

function exitBusiness(formData) {
    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };

    //myconfig.apiURL = 'http://localhost:3001/';
    return axios.post(`${myconfig.apiURL}company/exitbusiness`, formData, axiosRequestOptions)
        .then(handleResponse)
        .then(msg => {
            return msg;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}

function uploadLogo(formData) {
    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };
    //myconfig.apiURL = 'http://localhost:3001/';

    return axios.post(`${myconfig.apiURL}Setting/company/branding/logo`, formData, axiosRequestOptions)
        .then(handleResponse)
        .then(msg => {
            return msg;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}

function getCompanyBranding() {
    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };
    // myconfig.apiURL = 'http://localhost:3001/';

    return axios.get(`${myconfig.apiURL}Setting/company/branding/getInfo`, axiosRequestOptions)
        .then(handleResponse)
        .then(resp => {
            return resp;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();

            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}


