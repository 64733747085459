import React from "react";
import { Modal } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import PropTypes from "prop-types";

import "./styles.scss";

const ModalLayout = ({
  show,
  toggle,
  contentClassName,
  label,
  closeBtn,
  children,
  ...props
}) => {
  return (
    <Modal
      show={show}
      onHide={() => toggle()}
      {...props}
      className="customModal"
      contentClassName={`sideBar welcomeMsg modal-600 ${contentClassName || ""
        } mobileView`}
    >
      <Modal.Body>
        <div className={label ? "between" : "text-right"}>
          {label || ""}
          {closeBtn && (
            <button className="btnClose welcomeMsg" onClick={() => toggle()}>
              <AiOutlineClose />
            </button>
          )}
        </div>
        {children}
      </Modal.Body>
    </Modal>
  );
};

ModalLayout.propTypes = {
  show: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  // label: PropTypes.element,
  children: PropTypes.element.isRequired,
  closeBtn: PropTypes.bool,
  // size: PropTypes.string
};

ModalLayout.defaultProps = {
  closeBtn: true,
  // size: 'sm',
  // label: null
};

export default ModalLayout;
