import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { Modal, Form, Row, Col, Button } from "react-bootstrap";
import { Formik } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimezoneSelect from "react-timezone-select";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineCalendar, AiOutlineClockCircle } from "react-icons/ai";
import * as Yup from "yup";
import moment from 'moment-timezone';


import { InputFiled } from "../Common/authFile";
import { webinarActions } from "../Redux/_actions";
import "../layoutSection/Modal/styles.scss";

let endDt = new Date();
endDt.setHours(23);
endDt.setMinutes(59);

const initialValues = {
  title: "",
  description: "",
  startDate: new Date(),
  startTime: "",
  endTime: "",
  timeZone: {
    value: "Asia/Kolkata",
    label: "(GMT+5:30) Chennai, Kolkata, Mumbai, New Delhi",
  },
};

const validation = Yup.object().shape({
  title: Yup.string().required("please Enter The Title!"),
  description: Yup.string().required(" Please Enter The Description!"),
  startDate: Yup.date().required("Please Select the Start Date!"),
  startTime: Yup.string().required("Please Select the Start Time!"),
  endTime: Yup.string().required("Please Select the End Time!"),
});

const CreateWebinar = (props) => {
  const { show, toggle } = props;
  const { scheduleRedirect, scheduleId } = useSelector(
    (state) => state.webinar
  );
  const dispatch = useDispatch();
  const [isSubmit, setIsSubmit] = useState(false);

  const submitHandler = (value) => {
    // const startDate = new Date(value.startDate).getTime();
    let date = moment(value.startDate).format("YYYY-MM-DD")
    let timeStart = moment(value.startTime).format("HH:mm:ss")
    let timeEnd = moment(value.endTime).format("HH:mm:ss")

    const startTime = new Date(date + ' ' + timeStart).getTime();
    const endTime = new Date(date + ' ' + timeEnd).getTime();


    const params = {
      title: value.title,
      description: value.description,
      // change date/time format in database start
      // start_date : year+"-"+month+"-"+day,
      // start_time: startHour+":"+startMinutes,
      // end_time: endHour+":"+endMinutes,
      startTS: startTime,
      endTS: endTime,
      // change date/time format in database end

      timeZone: value.timeZone?.value,
      ///field
      webinar_type: "public",
      type: "live",
      occurs: "once",
      registration_type: "individual",
      interact_type: "standard",
      minStartTime: new Date(),
      minEndTime: new Date(),
      registration: false,
      webinarPwdBit: false,
      webinarPwd: Math.floor(Math.random() * 90000) + 10000,
      quesAns: true,
      authentic: false,
      recording: true,
      custom_fields: [],
    };

    dispatch(webinarActions.schedule(params));
    setIsSubmit(true);
  };

  const getMin = (date) => {
    const today = new Date();
    const selectedDate = new Date(date);
    if (
      today.getDate() === selectedDate.getDate() &&
      today.getMonth() === selectedDate.getMonth()
      // today.getFullYear() === today.getFullYear()
    ) {
      return new Date();
    }
    return "00:00";
  };

  return (
    <Modal
      show={show}
      onHide={() => toggle()}
      contentClassName="modalContentChangePlan sideBar welcomeMsg "
      dialogClassName="modal-600"
      className="createWebModal customModal"
    >
      {isSubmit && scheduleRedirect && scheduleId && (
        <Redirect to={`/webinars/${scheduleId}`} />
      )}
      <Modal.Header closeButton className="welcomeMsg">
        {" "}
        <h4>Create Webinar</h4>{" "}
      </Modal.Header>
      <Formik
        initialValues={initialValues}
        onSubmit={(value) => submitHandler(value)}
        validationSchema={validation}
      >
        {({ values, setFieldValue, handleSubmit, errors, touched }) => (
          <>
            <Modal.Body>
              <Row>
                <Col xs={12} md={6} className="d-flex align-item-center">
                  <img
                    src="../images/scheduleWeb.svg"
                    alt="images"
                    className="imgresponsive"
                  />
                </Col>
                <Col xs={12} md={6}>
                  <InputFiled
                    name="title"
                    type="text"
                    label="Title"
                    placeholder="Enter Webinar Title"
                    className="w-100"
                  />
                  <InputFiled
                    name="description"
                    type="text"
                    label="Description"
                    placeholder="Enter Webinar Description"
                    className="w-100"
                  />
                  {/* <Form.Group className="mb-3">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="description"
                      className="form-control w-100"
                      rows={3}
                      value={values.description}
                      onChange={(event) =>
                        setFieldValue("description", event.target.value)
                      }
                    />
                  </Form.Group> */}
                  <Form.Group className="mt-1">
                    <Form.Label className="f_size_14 ml-3">
                      Start Date
                    </Form.Label>
                    <div className="position-relative">
                      <DatePicker
                        selected={values.startDate}
                        onChange={(value) => setFieldValue("startDate", value)}
                        dateFormat="dd-MM-yyyy"
                        peekNextMonth
                        autoComplete="off"
                        dropdownMode="Select...."
                        minDate={new Date()}
                        name="start_date"
                        className="form-control w-100"
                      />
                      <AiOutlineCalendar className="iconInput  f_size_20 welcomeMsg" />
                    </div>
                    {touched?.startDate && errors?.startDate && (
                      <div className="error text-danger f_size_13">
                        {errors?.startDate}
                      </div>
                    )}
                  </Form.Group>
                  <Row>
                    <Col>
                      <Form.Group className="mt-1">
                        <Form.Label className="f_size_14 ml-3">
                          Start Time
                        </Form.Label>
                        <div className="position-relative">
                          <DatePicker
                            selected={values.startTime}
                            onChange={(value) =>
                              setFieldValue("startTime", value)
                            }
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            minTime={getMin(values.startDate)}
                            maxTime={endDt}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                            autoComplete="off"
                            className="form-control w-100"
                          />
                          <AiOutlineClockCircle className="iconInput  f_size_20 welcomeMsg" />
                        </div>
                        {touched?.startTime && errors?.startTime && (
                          <div className="error text-danger f_size_13">
                            {errors?.startTime}
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mt-1">
                        <Form.Label className="f_size_14 ml-3">
                          End Time
                        </Form.Label>
                        <div className="position-relative">
                          <DatePicker
                            selected={values.endTime}
                            onChange={(value) =>
                              setFieldValue("endTime", value)
                            }
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            minTime={new Date(values.startTime)}
                            maxTime={endDt}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                            autoComplete="off"
                            className="form-control w-100"
                          />
                          <AiOutlineClockCircle className="iconInput  f_size_20 welcomeMsg" />
                        </div>
                        {touched?.endTime && errors?.endTime && (
                          <div className="error text-danger f_size_13">
                            {errors?.endTime}
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>

                  <Form.Group className="mt-1 timezone_input">
                    <Form.Label className="f_size_14 ml-3 text-white">
                      Select Timezone
                    </Form.Label>
                    <div>
                      <TimezoneSelect
                        value={values.timeZone}
                        onChange={(value) => setFieldValue("timeZone", value)}
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <button
                variant="secondary"
                className="mr-2 btnLg welcomeMsg sideBarIcon"
                onClick={() => toggle()}
              >
                Cancel
              </button>
              <button
                onClick={() => handleSubmit()}
                className="mr-2 btnLg welcomeMsg submitSubColor"
              >
                Submit
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </Modal>
  );
};

export default CreateWebinar;
