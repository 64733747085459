import React from 'react'
import { Form } from 'react-bootstrap'

export const fieldTypes = [
  "Text", //-> Input Text Type
  "Checkbox", //-> Input Checkbox Type
  "Radio", //-> Input Radio Type
  "List", //-> Input Select Type
];

export const defaultFieldJson = [
  { id: 0, label: "Name", type: "Text", isRequired: true },
  { id: 1, label: "Email", type: "Text", isRequired: true },
  { id: 2, label: "Phone", type: "Text", isRequired: true },
];

export const InputSection = ({ item, deleteField, index }) => {
  switch (item.type) {
    case fieldTypes[0]: {
      return (
        < >
          <div className="d-flex justify-content-between " >
            <Form.Label className="ml-2 welcomeMsg text-capitalize ">
              {item.label}{item?.isRequired && <span className='text-danger'>*</span>}
            </Form.Label>
            {deleteField && (
              <button
                className="deletedBtn"
                onClick={() => deleteField(index)}
              >
                <i className="fas fa-trash dangerColor"></i>
              </button>
            )}
          </div>
          <Form.Control
            type="email"
            placeholder={`Enter your ${item.label}`}
            disabled
            className="form-control w-100 inputColorTheme mb-2"
          />
        </>
      );
    }
    case fieldTypes[1]: {
      return (
        <>
          <div className="d-flex justify-content-between">
            <Form.Label column className="welcomeMsg text-capitalize">
              {item.label}{item?.isRequired && <span className='text-danger'>*</span>}
            </Form.Label>
            {deleteField && (
              <button
                className="deletedBtn"
                onClick={() => deleteField(index)}
              >
                <i className="fas fa-trash dangerColor"></i>
              </button>
            )}
          </div>
          <div className="d-flex align-items-center">
            {item.option?.map((data) => (
              <>
                <Form.Control type="checkbox" className="w-auto h-auto ml-2" disabled />
                <Form.Label className="ml-2 mb-0 welcomeMsg text-capitalize">
                  {data.label}
                </Form.Label>
              </>
            ))}
          </div>
        </>
      );
    }
    case fieldTypes[2]: {
      return (
        <>
          <div className="d-flex justify-content-between">
            <Form.Label column className="welcomeMsg text-capitalize">
              {item.label}{item?.isRequired && <span className='text-danger'>*</span>}
            </Form.Label>
            {deleteField && (
              <button
                className="deletedBtn"
                onClick={() => deleteField(index)}
              >
                <i className="fas fa-trash dangerColor"></i>
              </button>
            )}
          </div>
          <div className="d-flex">
            {item.option?.map((data) => (
              <Form.Check
                type="radio"
                disabled
                label={data.label}
                name="formHorizontalRadios"
                id="formHorizontalRadios1"
                key={`option-radio-${data.label}`}
                className="welcomeMsg ml-2"
              />
            ))}
          </div>
        </>
      );
    }
    case fieldTypes[3]: {
      return (
        <>
          <div className="d-flex justify-content-between">
            <Form.Label column className="welcomeMsg text-capitalize">
              {item.label}{item?.isRequired && <span className='text-danger'>*</span>}
            </Form.Label>
            <button className="deletedBtn" onClick={() => deleteField(index)}>
              <i className="fas fa-trash dangerColor"></i>
            </button>
          </div>
          <div>
            <select type="select" className="selectSection">
              <option value="0">Choose...</option>
              {item.option?.map((data) => (
                <option
                  key={`option-select-${data.label}`}
                  className="text-capitalize"
                  value={data.label}
                >
                  {data.label}
                </option>
              ))}
            </select>
          </div>
        </>
      );
    }
    default: {
      return null;
    }
  }
};