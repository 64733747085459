import { alertActions } from './'
import { companyService } from './../_services'
import { companyConstants } from './../_constants'

export const companyActions = {
  registerCompany,
  saveCompanyInfo,
  uploadLogo,
  getCompanyBranding,
  decline,
  accept,
  exitBusiness,
  checkCompanyEmail
}

function registerCompany(formData) {
  return dispatch => {
    dispatch(request({}))

    companyService.registerCompany(formData).then(
      resp => {
        dispatch(alertActions.success('Company register successfully!'))
        dispatch(success(resp))
      },
      error => {
        dispatch(alertActions.error(error.toString()))
        dispatch(failure(error.toString()))
      }
    )
  }

  function request(resp) {
    return { type: companyConstants.COMPANY_REGISTER_REQUEST }
  }
  function success(resp) {
    return { type: companyConstants.COMPANY_REGISTER_SUCCESS, resp }
  }
  function failure(error) {
    return { type: companyConstants.COMPANY_REGISTER_FAILURE, error }
  }
}

function checkCompanyEmail(formData) {
  return dispatch => {
    dispatch(request({}))

    companyService.checkCompanyEmail(formData).then(
      resp => {
        dispatch(success(resp))
      },
      error => {
        dispatch(failure(error.toString()))
      }
    )
  }

  function request(resp) {
    return { type: companyConstants.COMPANY_CHECK_EMAIL_REQUEST }
  }
  function success(resp) {
    return { type: companyConstants.COMPANY_CHECK_EMAIL_SUCCESS, resp }
  }
  function failure(error) {
    return { type: companyConstants.COMPANY_CHECK_EMAIL_FAILURE, error }
  }
}

function decline(data) {
  return dispatch => {
    dispatch(request({}))

    companyService.decline(data).then(
      resp => {
        dispatch(alertActions.success('You successfully declined request!!'))
        dispatch(success(resp))
      },
      error => {
        dispatch(alertActions.error(error.toString()))
        dispatch(failure(error.toString()))
      }
    )
  }

  function request(resp) {
    return { type: companyConstants.DECLINE_REQUEST }
  }
  function success(resp) {
    return { type: companyConstants.DECLINE_SUCCESS, resp }
  }
  function failure(error) {
    return { type: companyConstants.DECLINE_FAILURE, error }
  }
}

function accept(data) {
  return dispatch => {
    dispatch(request({}))

    companyService.accept(data).then(
      resp => {
        dispatch(alertActions.success('You successfully accepted request!!'))
        dispatch(success(resp))
      },
      error => {
        dispatch(alertActions.error(error.toString()))
        dispatch(failure(error.toString()))
      }
    )
  }

  function request(resp) {
    return { type: companyConstants.ACCEPT_REQUEST }
  }
  function success(resp) {
    return { type: companyConstants.ACCEPT_SUCCESS, resp }
  }
  function failure(error) {
    return { type: companyConstants.ACCEPT_FAILURE, error }
  }
}

function exitBusiness(data) {
  return dispatch => {
    dispatch(request({}))

    companyService.decline(data).then(
      resp => {
        dispatch(alertActions.success('You successfully exited from business'))
        dispatch(success(resp))
      },
      error => {
        dispatch(alertActions.error(error.toString()))
        dispatch(failure(error.toString()))
      }
    )
  }

  function request(resp) {
    return { type: companyConstants.EXIT_REQUEST }
  }
  function success(resp) {
    return { type: companyConstants.EXIT_SUCCESS, resp }
  }
  function failure(error) {
    return { type: companyConstants.EXIT_FAILURE, error }
  }
}

function getCompanyBranding() {
  return dispatch => {
    dispatch(request({}))

    companyService.getCompanyBranding().then(
      resp => {
        dispatch(success(resp))
      },
      error => {
        dispatch(alertActions.error(error.toString()))
        dispatch(failure(error.toString()))
      }
    )
  }

  function request(resp) {
    return { type: companyConstants.COMPANY_BRANDING_INFO_REQUEST }
  }
  function success(resp) {
    return { type: companyConstants.COMPANY_BRANDING_INFO_SUCCESS, resp }
  }
  function failure(error) {
    return { type: companyConstants.COMPANY_BRANDING_INFO_FAILURE, error }
  }
}

function saveCompanyInfo(formData) {
  return dispatch => {
    dispatch(request({}))

    const brandingData = JSON.parse(JSON.stringify(formData))
    brandingData.address.forEach(item => {
      delete item.errorObj
    })

    const data = {
      data: brandingData,
      comId: localStorage.getItem('eOfficeId')
    }

    companyService.saveCompanyInfo(data).then(
      resp => {
        dispatch(alertActions.success('Info saved successfully!'))
        dispatch(success(resp))
      },
      error => {
        dispatch(alertActions.error(error.toString()))
        dispatch(failure(error.toString()))
      }
    )
  }

  function request(resp) {
    return { type: companyConstants.COMPANY_INFO_REQUEST }
  }
  function success(resp) {
    return { type: companyConstants.COMPANY_INFO_SUCCESS, resp }
  }
  function failure(error) {
    return { type: companyConstants.COMPANY_INFO_FAILURE, error }
  }
}

function uploadLogo(formData) {
  return dispatch => {
    dispatch(request({}))
    const data = {
      csv: formData
    }
    console.log(formData)

    companyService.uploadLogo(formData).then(
      resp => {
        dispatch(success(resp))
        // dispatch(alertActions.success('Info saved successfully!'));
      },
      error => {
        dispatch(alertActions.error(error.toString()))
        dispatch(failure(error.toString()))
      }
    )
  }

  function request(resp) {
    return { type: companyConstants.COMPANY_LOGO_REQUEST }
  }
  function success(resp) {
    return { type: companyConstants.COMPANY_LOGO_SUCCESS, resp }
  }
  function failure(error) {
    return { type: companyConstants.COMPANY_LOGO_FAILURE, error }
  }
}
