import React from 'react';
import AccordionWrapper from './components/AccordionWrapper'; 
import AccordionItem from './components/AccordionItem';

import './Faq.css';
function Faq() {

  const data = [
    {
      "title": "What are the benefits of a webinar?",
      "description": "Since webinars are easily accessible online, they allow you to connect with more people. Webinars enable attendees to participate in your presentation from anywhere in the world, so long as they have access to the internet. Without the challenges of travel and room space, you can reach and engage a broad audience quickly and efficiently. Additionally, webinars can be recorded and made available for interested people to view even after the live event has ended. On-demand webinars can become part of your company’s valuable ongoing content offering."
    },
    {
      "title": "Does a webinar have to be live?",
      "description": "Being a complete browser based solution application is not a must to run the solution. All it needs to run is a browser and internet connection.<br/>All features will be available on all platforms [mobile operating system (Android and IOS), web (Chrome, Firefox, Safari and Internet Explorer), desktop operating system (Windows, Mac, Linux)]"
    },
    {
      "title": "Are webinars effective?",
      "description": "No, all you need to take care of is the time zone. Schedule a meeting that is comfortable for both ends or to all the participants. Through VoiP connectivity, there will be no international charges or extra pay of any kind. Everything is the same as usual."
    }
    
  ];

  return (
<>
    <div className="container-fluid bgdark schedule-webinar">
    <div className="shape-devider shape-devider-bottom2"></div>
 </div>

 <div className="container-fluid faq">
 <div className="container">
    <div className="row">
       <div className="col-lg-12">
          <h3 className="heading">Get answers to all of your FAQ questions.</h3>
       </div>
       <div className="col-lg-10 mx-auto">

       <div className="FaqContent">
       <div className="content">
           <AccordionWrapper>
             {data.map((item, index) => (
               <AccordionItem key={index} index={index} title={item.title} description={item.description} />
             ))}
           </AccordionWrapper>
       </div>
     </div>
            
     <div className="col-lg-12 text-center"><a href="https://sarv.com/sarv-wave/faq.html" target="_blank" className="btn btn-view-more bg-perpal">View More</a></div>
       
       </div>

    </div>
 </div>
</div>
</>
  );
}

export default Faq; 
