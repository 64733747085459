import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import "./messagesFromMeeting.css"
import WaitingHeader from '../waitingHeader'

class MessagesFromMeeting extends Component {
	
	constructor(props){
		super(props);
		this.state = {
			mid : this.props.mid,
			mname: this.props.mname
		}
	}


	render() {
		const { mid, mname } = this.state;
		return (
			<div className="wave_wating_sctn">
    			<WaitingHeader mname={mname} mid={mid} />
    			<div className="wave_waiting_contianer_msg">
			        <div className="wait_msg">
			           This meeting has already been ended. 
			           <span className="text-warning">Please check your meeting id or create a new meeting.</span>
			      	</div>  

	        		<div className="wave_wait_timer text-center">
	            		<Link className="btn btn-primary pl-4 pr-4" to="/"><i className="fas fa-home"></i>Home</Link> 
	        		</div>  
	    		</div>    
			</div>
		)
	}
}

export default MessagesFromMeeting;