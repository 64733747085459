export const upgradeConstants = {
    LIST_PLANS_REQUEST: 'LIST_PLANS_REQUEST',
    LIST_PLANS_SUCCESS: 'LIST_PLANS_SUCCESS',
    LIST_PLANS_FAILURE: 'LIST_PLANS_FAILURE',

    LIST_ADDONS_REQUEST: 'LIST_ADDONS_REQUEST',
    LIST_ADDONS_SUCCESS: 'LIST_ADDONS_SUCCESS',
    LIST_ADDONS_FAILURE: 'LIST_ADDONS_FAILURE',

    PAYMENT_VERIFICATION_REQUEST: 'PAYMENT_VERIFICATION_REQUEST',
    PAYMENT_VERIFICATION_SUCCESS: 'PAYMENT_VERIFICATION_SUCCESS',
    PAYMENT_VERIFICATION_FAILURE: 'PAYMENT_VERIFICATION_FAILURE',

    LIST_LICENCE_REQUEST: 'LIST_LICENCE_REQUEST',
    LIST_LICENCE_SUCCESS: 'LIST_LICENCE_SUCCESS',
    LIST_LICENCE_FAILURE: 'LIST_LICENCE_FAILURE',

    ASSIGN_LICENCE_REQUEST: 'ASSIGN_LICENCE_REQUEST',
    ASSIGN_LICENCE_SUCCESS: 'ASSIGN_LICENCE_SUCCESS',
    ASSIGN_LICENCE_FAILURE: 'ASSIGN_LICENCE_FAILURE',

};