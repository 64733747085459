import { eventConstants } from '../_constants';

const initialState = {
  loading: false,
  error: false,
  event: null,
  eventLimit: [],

  meetIsLoading: false,
  meetingHistory: [],
  googleError: null,

  syncLoading: false,
  syncWithGoogle: false,

  gAuthLoading: true,
  gAuthURL: null,

  gAuthTokenLoading: false,
  gAuthTokenVerified: false,

  gcAuthRemoved: false,
  gcAuthRemoveLoading: false,

  recordingLoading: false,
  recordingError: false,
  recordingData: [],
  gAuthError: false,
  meetingIsMore: true,
}

export function event(state = initialState, action) {
  switch (action.type) {
    case eventConstants.GET_RECORDING_START:
      return {
        ...state,
        recordingLoading: true
      }
    case eventConstants.GET_RECORDING_FAILURE:
      return {
        ...state,
        recordingError: true,
        recordingLoading: false
      }
    case eventConstants.GET_RECORDING_SUCCESS:
      return {
        ...state,
        recordingError: false,
        recordingLoading: false,
        recordingData: action.events

      }

    //Google Auth URL Constants    
    case eventConstants.GAUTH_URL_START:
      return {
        ...state,
        gAuthLoading: true,
        gAuthURL: null,
        gAuthError: false,
      };
    case eventConstants.GAUTH_URL_SUCCESS:
      return {
        ...state,
        gAuthLoading: false,
        gAuthURL: action.events,
        gAuthError: false,
      };
    case eventConstants.GAUTH_URL_FAILURE:
      return {
        ...state,
        gAuthLoading: false,
        gAuthURL: null,
        gAuthError: true,
      };

    //Google Auth TOKEN Constants    
    case eventConstants.GAUTH_TOKEN_START:
      return {
        ...state,
        gAuthTokenLoading: true,
        gAuthTokenVerified: false
      };
    case eventConstants.GAUTH_TOKEN_SUCCESS:
      return {
        ...state,
        gAuthTokenLoading: false,
        gAuthTokenVerified: true
      };
    case eventConstants.GAUTH_TOKEN_FAILURE:
      return {
        ...state,
        gAuthTokenLoading: false,
        gAuthTokenVerified: false
      };

    case eventConstants.EVENT_START:
      return {
        ...state,
        syncLoading: action.start,
        syncWithGoogle: false,
        googleError: null,
        event: null
      };
    case eventConstants.EVENT_SUCCESS:
      return {
        ...state,
        syncLoading: false,
        syncWithGoogle: true,
        googleError: null,
        event: { events: action.events, id: action.eventId }
      };
    case eventConstants.EVENT_FAILURE:
      return {
        ...state,
        syncLoading: false,
        syncWithGoogle: false,
        googleError: action.error,
        event: null
      };
    case eventConstants.EVENT_GOOGLE_START:
      return {
        ...state,
        loading: true,
        error: false
      };
    case eventConstants.EVENT_GOOGLE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false
      };
    case eventConstants.EVENT_GOOGLE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      };
    case eventConstants.EVENT_LIMIT_START:
      return {
        ...state,
        loading: true,
        error: false
      };
    case eventConstants.EVENT_LIMIT_SUCCESS:
      return {
        ...state,
        loading: false,
        syncLoading: false,
        error: false,
        eventLimit: action.events
      };
    case eventConstants.EVENT_LIMIT_FAILURE:
      return {
        ...state,
        loading: false,
        syncLoading: false,
        error: true
      };
    case eventConstants.MEETING_HISTORY_START:
      return {
        ...state,
        // loading: true,
        meetIsLoading: true,
        error: false
      };
    case eventConstants.MEETING_HISTORY_SUCCESS:
      var data = action.events;
      if (action?.pageVal?.skip && action.pageVal.skip !== 0 && state?.meetingHistory?.length > 0) {
        data = state.meetingHistory.concat(action.events)
      }
      return {
        ...state,
        meetIsLoading: false,
        syncLoading: false,
        error: false,
        meetingHistory: data,
        meetingIsMore: action.events?.length == 30,
      };
    case eventConstants.MEETING_HISTORY_FAILURE:
      return {
        ...state,
        loading: false,
        syncLoading: false,
        error: true,
        meetIsLoading: false,
        meetingIsMore: false,
      };

    case eventConstants.REMOVE_GOOGLE_CALENDAR_START:
      return {
        ...state,
        gcAuthRemoveLoading: true,
        gcAuthRemoved: false
      };
    case eventConstants.REMOVE_GOOGLE_CALENDAR_SUCCESS:
      return {
        ...state,
        gcAuthRemoveLoading: false,
        gcAuthRemoved: true
      }
    case eventConstants.REMOVE_GOOGLE_CALENDAR_FAILURE:
      return {
        ...state,
        gcAuthRemoveLoading: false,
        gcAuthRemoved: false
      }

    default:
      return state
  }
}
