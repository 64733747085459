import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from '../_reducers';


let midware = applyMiddleware(
	thunkMiddleware
)

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {	
	const loggerMiddleware = createLogger();
	midware = applyMiddleware(
		thunkMiddleware,
		loggerMiddleware
	)
}

export const store = createStore(
    rootReducer,
    midware
);