import React, { Component } from 'react';
import { withTranslation } from 'react-i18next'
import "./ForgetPassword.css"

import { Link, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import decode from 'jwt-decode';
import userconfigs from '../../helpers/closures';
import { userActions } from '../../Redux/_actions';
import MD5 from "crypto-js/md5";
import { Spinner } from 'reactstrap';
//const crypto = require("crypto");

var interval = "";

class ResetPassword extends Component {

  constructor(props) {
    super(props);

    this.state = {
      newPwd: '',
      confPwd: '',
      errors:{},
      token:this.props.match.params.token,
      submitted:false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const {name, value} = e.target;
    this.setState({
      [name]: value
    });
  }

  handleValidation() {
    let newPwd = this.state.newPwd;
    let confPwd = this.state.confPwd;
    let errors = {};
    let formIsValid = true;

    //Password
    if (!newPwd) {
        formIsValid = false;
        errors["newPwd"] = "Please enter the password!";
    }
    else if(newPwd.length < 6){
        formIsValid = false;
        errors["newPwd"] = "Password should be atlease 6 charater long!";    
    }else if (confPwd !== newPwd) {
        formIsValid = false;
        errors["confPwd"] = "Confirm password should be same as Password!";
    }
    
    this.setState({ errors: errors });
    return formIsValid;
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({
      submitted: true,
    });
    if (this.handleValidation()) {
      const {newPwd, token} = this.state;
      if (newPwd && token) {
        var md5Hash = MD5(newPwd).toString()
        this.props.resetPassword(md5Hash, token);
      }
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if(prevProps.resetPwd !== this.props.resetPwd){
      this.props.history.push('/login');
    }
  }

  render() {
    const { newPwd,confPwd, submitted, errors } = this.state;
    const { resetLoader } = this.props;
   
    let userInfo = decode(userconfigs.getItem('jwt'))

    if(userInfo.loginStatus === 1){
      return <Redirect to='/' />
    }
    else {

    return (

      <div className="wave_login_container">
    <div className="login_form_sec">
          <div className="lgn_frm_bx">
                <div className="lgn_logo">
                  <Link to="/">
                    <img alt="logo" src={process.env.PUBLIC_URL + "/images/sarv-wave-logo.png"} />
                  </Link>
                </div>
                <div className="w_login_heading">
                    <h4>Reset Password</h4>
                    <p className="mb-10">Enter your new password.</p>
                </div>
                <form name="form" onSubmit={this.handleSubmit}>

                    <div className="_wform_group">
                         <div className={'_wform_group_prepend' + ((submitted && !newPwd) ? ' has-error' : '')}>
                              <span className="_wform__group_text">
                                  <i className="fas fa-user"></i>
                              </span>
                              <input type="password" className="_wform_form_cntrl" name="newPwd" value={newPwd} onChange={this.handleChange} placeholder="New Password" />
                              
                         </div>
                         {errors['newPwd'] ? <span className="help-block error_msg_56">{errors['newPwd']}</span>:""}
                    </div>

                    <div className="_wform_group">
                         <div className={'_wform_group_prepend' + ((submitted && !confPwd) ? ' has-error' : '')}>
                              <span className="_wform__group_text">
                                  <i className="fas fa-key"></i>
                              </span>
                              <input type="password" className="_wform_form_cntrl" name="confPwd" value={confPwd} onChange={this.handleChange} placeholder="Confirm Password" />
                              
                         </div>
                         {errors['confPwd'] ? <span className="help-block error_msg_56">{errors['confPwd']}</span>:""}
                    </div>
      
                  <div className="_grp_rw">
                    {resetLoader ? 
                      <button type="button" className="submisn_btn btn "><Spinner size="sm" /> Updating...</button>
                    :
                      <button type="submit" className="submisn_btn btn btn-success">Update</button>
                    }
                  </div>

                  <div className="_grp_rw ftr_tag_log">
                     Don't have an account? <Link to="/signup" className="txt_theme"> Register hereddddd</Link>
                  </div>

                </form>
          </div>
    </div>
</div>

    )
  }
  }
}

function mapState(state) {
  const {resetLoader, resetPwd } = state.authentication;
  return {
    resetLoader,
    resetPwd
  };
}

const actionCreators = {
  resetPassword: userActions.resetPassword,
};

export default withRouter(withTranslation()(connect(mapState, actionCreators)(ResetPassword)));