import React from "react";
const dateChange = (timestamp) => {
  let d = new Date(timestamp * 1000);
  return d.getDate() + "-" + (d.getMonth() + 1) + "-" + d.getFullYear();
}
export const userColumn = [
   ["Name","name"],
   ["Email","email"],
   ["Mobile","mobile"],
   ["Register Date","create_date",{
    type: "render",
    render: (param) => (
      <>
      {dateChange(param.value)}
      </>
    )
   }],
   ["User Status","status",{
    type: "render",
    render: (param) => (
      <>
      {param.value === 0 ? (
                              <label className="badge badge-success">
                                Active
                              </label>
                            ) : (
                              <label className="badge badge-danger">
                                InActive
                              </label>
                            )}{" "}
      
      </>),
    options: {
      width: 130,

    },
  }]
];

export const feedbackColumn = [
  ["Name","name"],
  ["Email","email"],
  ["Mobile","mobile"],
  ["Type","feedback_type"],
  ["Message","message"]
];
export const hostUserColumn = [
  ["Name","name"],
  ["Email","email"],
  ["Mobile","mobile"],
];
export const pendingUserColumn = [
  ["Name","name"],
  ["Email","email"],
  ["Mobile","mobile"],
  ["Register Date","created_date"]
];

export const manageUSerColumn = [
  ["Email/Name ID","email"],
  ["Name","name"],
  ["Role","role"],
  ["Type","type"],
  ["Status","status",{
   type: "render",
   render: (param) => (
    <>
    {param.value === 'Active' ? (
                            <span className="btn btn-sm rounded-pill btn-success">
                              Active
                            </span>
                          ) : (
                            <span className= {param.value === 'Pending'? "btn btn-sm rounded-pill btn-warning" :"btn btn-sm rounded-pill btn-danger" }>
                              {param.value}
                            </span>
                          )}{" "}
    
    </>
   )
  }],
  ["Department","department"]
];