export const waitingRoomConstants = {
    ADD_USER_REQUEST: 'ADD_USER_REQUEST',
    ADD_USER_SUCCESS: 'ADD_USER_SUCCESS',
    ADD_USER_FAILURE: 'ADD_USER_FAILURE',

    BROADCAST_REQUEST: 'BROADCAST_REQUEST',
    BROADCAST_SUCCESS: 'BROADCAST_SUCCESS',
    BROADCAST_FAILURE: 'BROADCAST_FAILURE',
	
	GET_BROADCAST_REQUEST: 'GET_BROADCAST_REQUEST',
    GET_BROADCAST_SUCCESS: 'GET_BROADCAST_SUCCESS',
    GET_BROADCAST_FAILURE: 'GET_BROADCAST_FAILURE',

    LIST_ATTENDEE_REQUEST: 'LIST_ATTENDEE_REQUEST',
    LIST_ATTENDEE_SUCCESS: 'LIST_ATTENDEE_SUCCESS',
    LIST_ATTENDEE_FAILURE: 'LIST_ATTENDEE_FAILURE',

    ATTENDEE_REQUEST_REQUEST: 'ATTENDEE_REQUEST_REQUEST',
    ATTENDEE_REQUEST_SUCCESS: 'ATTENDEE_REQUEST_SUCCESS',
    ATTENDEE_REQUEST_FAILURE: 'ATTENDEE_REQUEST_FAILURE',

    ATTENDEE_JOIN_REQUEST: 'ATTENDEE_JOIN_REQUEST',
    ATTENDEE_JOIN_SUCCESS: 'ATTENDEE_JOIN_SUCCESS',
    ATTENDEE_JOIN_FAILURE: 'ATTENDEE_JOIN_FAILURE',

    MODERATOR_JOIN_REQUEST: 'MODERATOR_JOIN_REQUEST',
    MODERATOR_JOIN_SUCCESS: 'MODERATOR_JOIN_SUCCESS',
    MODERATOR_JOIN_FAILURE: 'MODERATOR_JOIN_FAILURE',
};
