import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import "./WebinarRoom.css";
import LeftPanel from './components/LeftPanel';
import queryString from 'query-string'
import { connect } from 'react-redux';
import userconfigs from '../../helpers/closures';
import decode from 'jwt-decode';
import { SSE } from '../../helpers/sse';
import { webinarRoomActions, alertActions, webinarActions/* , conferenceActions */ } from '../../Redux/_actions';
import {/* capitalize, */getServerURL } from '../../helpers/helpers';
// import RightPanel from './components/RightPanel';
// import { Modal, ModalHeader, ModalBody, Spinner } from 'reactstrap';
// import LoadSpinner from '../../components/UI/Spinner/Spinner';
// import Avatar from 'react-avatar';
// import ModeratorRoom from './components/ModeratorRoom';
const currentURL = getServerURL();

// let wdInterval;
let source = null
class Room extends Component {

	constructor(props) {
		super(props);
		const queryData = queryString.parse(this.props.location.search);
		this.state = {
			webinarID: queryData.wid || null,
			token: queryData.token || null,
			loggedIn: false,
			webinarInfo: {},
			showLoader: true,
			stopInterval: false,
			// joinPopUp : false,
			joinContinueURL: null
		}
	}

	componentDidMount = () => {
		let userInfo = decode(userconfigs.getItem("jwt"));
		const { webinarID, token } = this.state;
		// const speakerJoinKey = userconfigs.getItem('speaker');
		if (userInfo.loginStatus !== 0) {
			this.setState({
				loggedIn: true
			})
		}

		if (webinarID && token) {
			// this.props.webinarRoomDetailFun(webinarID, token)
			// wdInterval = setInterval(()=>{
			// 	this.props.webinarRoomDetailFun(webinarID, token) 
			// }, 3000);


			let self = this;
			let payload = [];
			let url = `${currentURL.apiURL}webinar/webinarRoomDetailSSE`;
			let requestJson = { "wid": webinarID, "token": token };

			source = new SSE(url, {
				headers: {
					'Content-Type': 'application/json',
					'Authorization': userconfigs.getItem('jwt'),
				},
				payload: JSON.stringify(requestJson),
				method: 'POST'
			});

			source.addEventListener('message', function (e) {

				self.setState({ 'showLoader': false });

				payload = JSON.parse(e.data);
				//console.log("pppppppppp",payload)
				if (Object.values(payload).length > 0) {
					self.updateComponents(webinarID, token, payload)
					// let speakerListOldArr = self.state.speakerListArr;
					// let updateSpeakerListObj = [...speakerListOldArr, ...payload];
					// // var objDiv = document.getElementById("speakerList");
					// // objDiv.scrollTop = objDiv.scrollHeight;
					// self.setState({'speakerListArr' : updateSpeakerListObj});

				}
			});
			source.stream();

		}
		else {
			this.props.history.push({
				pathname: '/webinar_info',
				state: {
					wid: webinarID,
					//wname: this.props.detailInfo.meetingName,
					heading: 'You are not a valid user.',
					msg: 'Please join the webinar first then try to enter in webinar room.'
				}
			})
		}
	}


	updateComponents = (webinarID, token, detailInfo) => {
		const speakerJoinKey = userconfigs.getItem('speaker');
		let utype = '';
		console.log('ddddddddd', detailInfo)
		//if(prevProps.detailInfo !== this.props.detailInfo){
		this.setState({
			webinarInfo: detailInfo,
			showLoader: false
		})
		if (detailInfo.isMeetingEnded === 1) {
			this.props.history.push({
				pathname: '/webinar_info',
				state: {
					wid: webinarID,
					wname: detailInfo.meetingName,
					heading: 'Webinar has already been ended.',
					msg: 'The webinar you are trying to join has already ended, Please check your webinar link.'
				}
			})
		}

		if (detailInfo.allowed === -1) {
			this.props.history.push({
				pathname: '/webinar_info',
				state: {
					wid: webinarID,
					wname: detailInfo.meetingName,
					heading: 'You have been removed.',
					msg: 'You have been removed by the moderator, please contact to moderator for any query.'
				}
			})
		}


		if (detailInfo.user_type === "attendee" && detailInfo.allowed === 2) {
			let userType = detailInfo.user_type || "attendee";
			let name = detailInfo.name || "User";
			console.log('webinarWaitingRoom user name ', name);
			//this.props.joinWebinarFun(webinarID, name, userType, token);
			//this.setState({joinPopUp: true});
		}

		utype = detailInfo.user_type;
		if (detailInfo.user_type === "attendee" && detailInfo.isMeetRunning === true) {
			// userconfigs.setItem('speaker', webinarID);
			//this.setState({joinPopUp: true});
			this.joinAttendeeMeetingAction();

		}

		if (detailInfo.user_type === "speaker" && detailInfo.isMeetRunning === true && speakerJoinKey !== webinarID) {

			userconfigs.setItem('speaker', webinarID);
			//this.setState({joinPopUp: true});
			this.joinMeetingAction();

		}

		// if(typeof this.props.detailError !== "object"){
		// 	clearInterval(wdInterval)
		// }
		//}
	}



	componentDidUpdate(prevProps, prevState) {

		const { webinarID, token } = this.state;
		// if(this.state.webinarInfo.user_type === 'attendee' && prevProps.joiningWebinarStatus !== this.props.joiningWebinarStatus && this.props.joiningWebinarURL !== null){
		// 	this.setState({joinPopUp: true, joinContinueURL : this.props.joiningWebinarURL});
		// 	this.props.changeAllowBitAttendeeFun(webinarID, token)
		// }

		// if(prevProps.wrWebinarBits!==this.props.wrWebinarBits){
		// 	console.log('wrWebinarBits ',wrWebinarBits);

		// 	if(wrWebinarBits){
		// 		if(wrWebinarBits.hasOwnProperty('isMeetingEnded')){

		// 		}
		// 	}
		// }
	}

	joinAttendeeMeetingAction = () => {

		let webinarID = this.state.webinarID;
		let token = this.state.token;
		//let webinarInfo = this.props.detailInfo;
		let webinarInfo = this.state.webinarInfo;
		let userType = webinarInfo.user_type || "attendee";
		let name = webinarInfo.name || "User";

		this.props.joinAttendeeWebinarFun(webinarID, name, userType, token);

	}

	joinMeetingAction = () => {

		let webinarID = this.state.webinarID;
		let token = this.state.token;
		//let webinarInfo = this.props.detailInfo;
		let webinarInfo = this.state.webinarInfo;
		let userType = webinarInfo.user_type || "attendee";
		let name = webinarInfo.name || "User";

		this.props.joinWebinarFun(webinarID, name, userType, token);

	}



	render() {

		const { webinarID, token, webinarInfo, showLoader } = this.state;
		const { detailLoading, detailError } = this.props;

		return (

			(typeof detailError !== "object") ?
				<div className="container-fluid s_wave_content_part webinar_bg pl-0 pr-0">
					<h3>{detailError.toString()}</h3>
				</div>
				:
				<div>

					<div className="container-fluid s_wave_content_part webinar_bg webinar_stream pl-0 pr-0">

						<div>
							<div className="webinar_stream_sec pl-0 pr-0">

								<LeftPanel webinarID={webinarID} token={token} webinarInfo={webinarInfo} />

							</div>


						</div>


					</div>
				</div>

		)

	}

};


function mapState(state) {
	const { detailLoading, detailInfo, detailError } = state.webinarRoom

	return {
		// wrWebinarBits,
		detailLoading,
		detailInfo,
		detailError,
		updateJoinBitState: state.webinarRoom.updateJoinBitState,
		updateJoinBitLoading: state.webinarRoom.updateJoinBitLoading,
		joiningWebinarLoading: state.webinar.joiningWebinarLoading,
		joiningWebinarStatus: state.webinar.joiningWebinarStatus,
		joiningWebinarURL: state.webinar.joiningWebinarURL,
		startMeetingLoading: state.conference.startMeetingLoading,
		meetingRedirect: state.conference.meetingRedirect
	};
}
const actionCreators = {
	webinarRoomDetailFun: webinarRoomActions.webinarRoomDetail,
	alertError: alertActions.error,
	changeAllowBitAttendeeFun: webinarRoomActions.changeAllowBitAttendee,
	joinWebinarFun: webinarActions.joinWebinar,
	joinAttendeeWebinarFun: webinarActions.joinAttendeeWebinar,

	//    webinarBits:webinarActions.WRwebinarBits,
};

export default withRouter(connect(mapState, actionCreators)(Room));