import { departmentConstants } from './../_constants';

const initialState = { loadingStatus: false, departmentList: [], companyUsers: [], isDepartmentAdded: false, departmanrData: '' };

export function department(state = initialState, action) {
    switch (action.type) {
        case departmentConstants.DEPARTMENT_GETALL_REQUEST:
            return {
                ...state,
                loadingStatus: true
            }
        case departmentConstants.DEPARTMENT_GETALL_SUCCESS:
            return {
                ...state,
                loadingStatus: false,
                departmentList: action.resp
            }
        case departmentConstants.DEPARTMENT_GETALL_FAILURE:
            return {
                ...state,
                loadingStatus: false,
                departmentList: []
            }

        case departmentConstants.DEPARTMENT_ADD_REQUEST:
            return {
                ...state,
                loadingStatus: true,
                isDepartmentAdded: false
            }
        case departmentConstants.DEPARTMENT_ADD_SUCCESS:
            return {
                ...state,
                loadingStatus: false,
                isDepartmentAdded: true
            }
        case departmentConstants.DEPARTMENT_ADD_FAILURE:
            return {
                ...state,
                loadingStatus: false,
                isDepartmentAdded: false
            }



        case departmentConstants.GET_COMPANY_USER_REQUEST:
            return {
                ...state,
                loadingStatus: true
            }
        case departmentConstants.GET_COMPANY_USER_SUCCESS:
            return {
                ...state,
                loadingStatus: false,
                companyUsers: action.resp
            }
        case departmentConstants.GET_COMPANY_USER_FAILURE:
            return {
                ...state,
                loadingStatus: false,
                companyUsers: []
            }

        case departmentConstants.DEPARTMENT_BYID_REQUEST:
            return {
                ...state,
                loadingStatus: true
            }
        case departmentConstants.DEPARTMENT_BYID_SUCCESS:
            return {
                ...state,
                loadingStatus: false,
                departmanrData: action.resp
            }
        case departmentConstants.DEPARTMENT_BYID_FAILURE:
            return {
                ...state,
                loadingStatus: false,
                departmanrData: ''
            }
        default:
            return state
    }
}
