import React, { Component } from "react";
import { Link, withRouter, Redirect } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import { connect } from "react-redux";
import { Spinner } from "reactstrap";
import { Button } from "react-bootstrap";

//import Header from '../Header/Header';
import Loader from "../components/UI/Spinner/Spinner";
import decode from "jwt-decode";
import userconfigs from "../helpers/closures";
//import {tsToOnlyDate, tsToTime} from '../helpers/helpers';
import Header from "../layoutSection/header";
import { userActions, eventActions } from "../Redux/_actions";
import OtpField from "../components/UI/OTP/OtpField";



class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: {},
      otp: undefined,
      otpModal: false,
      verifyType: "Mobile",
      selUsername: "",
      otpErr: false,
      gdAttach: false,
      gcAttach: false,
    };
  }

  handleChange(e) {
    const { name, value } = e.target;
    let fields = this.state.fields;
    fields[name] = value;
    this.setState({ fields });
  }

  componentWillMount = () => {
    let webUser = decode(userconfigs.getItem("jwt"));
    if (webUser.loginStatus !== 1) {
      this.props.history.push("/login");
    }
  };

  componentDidMount = () => {
    this.props.userDetails();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.profileInfo !== this.props.profileInfo) {
      this.setState({
        userInfo: this.props.profileInfo,
        gdAttach: this.props.profileInfo.gDriveAuth
          ? this.props.profileInfo.gDriveAuth
          : false,
        gcAttach: this.props.profileInfo.gCalendarAuth
          ? this.props.profileInfo.gCalendarAuth
          : false,
      });
    }

    if (prevProps.verificationSent !== this.props.verificationSent) {
      if (this.props.verificationSent === true) {
        this.setState({
          otpModal: true,
          otpErr: false,
          otp: undefined,
        });
      }
    }

    if (prevProps.otpVerified !== this.props.otpVerified) {
      if (this.props.otpVerified === true) {
        this.props.userDetails();
        this.setState({
          otpModal: false,
          otpErr: false,
          selUsername: "",
          otp: undefined,
        });
      }
    }

    if (prevProps.gAuthRemoved !== this.props.gAuthRemoved) {
      this.setState({
        gdAttach: false,
      });
    }

    if (prevProps.gcAuthRemoved !== this.props.gcAuthRemoved) {
      this.setState({
        gcAttach: false,
      });
    }
  };

  verifyUsername = (username, type, e) => {
    e.preventDefault();
    if (username !== "") {
      this.props.verifyProfile(username);
      this.setState({
        selUsername: username,
        verifyType: type,
        //otpModal:true
      });
    }
  };

  handleVerifyOtp = (otp) => {
    this.setState({
      otp: otp.otp,
    });
  };

  closeModal(modal) {
    this.setState({
      [modal]: false,
      otpErr: false,
      selUsername: "",
      otp: undefined,
    });
  }
  showModal(modal) {
    this.setState({
      [modal]: true,
    });
  }

  verifyOTP = (e) => {
    e.preventDefault();
    const { otp, selUsername } = this.state;
    //console.log('ooooooooo',otp)
    if (otp === undefined) {
      this.setState({
        otpErr: true,
      });
    } else {
      this.props.verifyOtp(selUsername, otp);
    }
  };

  render() {
    const { userInfo, otpModal, verifyType, otpErr, gdAttach, gcAttach } =
      this.state;
    const {
      profileLoading,
      otpVerifyLoading,
      gAuthRemoveLoading,
      gcAuthRemoveLoading,
    } = this.props;
    //console.log("uuuuu",profileLoading)
    return (
      <div>
        <Header goBack title="Profile Info" />
        <div className="container-fluid s_wave_content_part_schdule profile_page_sec pt-0 ml-2">
          <div className="container user_profile_page">
            <div className="row mt-4">
              <div className="col-md-8">
                <div className="middle_6h_bx borderRadius sideBar">
                  {profileLoading ? (
                    <div className="text-center">
                      <Loader />
                    </div>
                  ) : (
                    <div>
                      <div className="row">
                        <div className="col-sm-8">
                          <span className="text_milky f_size_20">
                            {" "}
                            <i className="fas fa-user-circle mr-1"></i>{" "}
                            {userInfo.name}{" "}
                          </span>
                        </div>
                        <div className="col-sm-4 text-right action_btn86">
                          {/* <Link
                            to="/edit_profile"
                            className="btn btn-warning custom_btn btn-sm f_size_12"
                          >
                            <i className="fas fa-edit mr-1"></i> Edit Profile
                          </Link> */}
                          <Button variant="outline-warning" onClick={() => this.props.history.push('/edit_profile')} >
                            <i className="fas fa-edit mr-1"></i> Edit Profile
                          </Button>
                        </div>
                      </div>
                      <hr />

                      <div className="profile_udeatil">
                        <ul>
                          <li>
                            <label>Email</label>

                            <div className="user_de_1">
                              {userInfo.email || "N/A"}
                              <span className="ml-3 f_size_12 float-right">
                                {userInfo.email !== null &&
                                  userInfo.email !== "" ? (
                                  userInfo.emailVerified === 1 ? (
                                    <span className=" text-success">
                                      <i className="fas fa-certificate"></i>{" "}
                                      Verified{" "}
                                    </span>
                                  ) : (
                                    <button
                                      type="button"
                                      onClick={this.verifyUsername.bind(
                                        this,
                                        userInfo.email,
                                        "Email ID",
                                      )}
                                      className="btn btn-primary btn-xs f_size_12"
                                    >
                                      Verify Email
                                    </button>
                                  )
                                ) : (
                                  ""
                                )}
                              </span>
                            </div>
                          </li>
                          <li>
                            <label>Mobile</label>

                            <div className="user_de_1">
                              {userInfo.mobile
                                ? "+" +
                                userInfo.countryCode +
                                "-" +
                                userInfo.mobile
                                : "N/A"}
                              <span className="ml-3 f_size_12 float-right">
                                {userInfo.mobile !== null &&
                                  userInfo.mobile !== "" ? (
                                  userInfo.mobileVerified === 1 ? (
                                    <span className=" text-success">
                                      <i className="fas fa-certificate"></i>{" "}
                                      Verified{" "}
                                    </span>
                                  ) : (
                                    <button
                                      type="button"
                                      onClick={this.verifyUsername.bind(
                                        this,
                                        userInfo.mobile,
                                        "Mobile",
                                      )}
                                      className="btn btn-primary btn-xs f_size_12"
                                    >
                                      Verify Mobile
                                    </button>
                                  )
                                ) : (
                                  ""
                                )}
                              </span>
                            </div>
                          </li>

                          <li>
                            <label>Timezone</label>

                            <div className="user_de_1">
                              {userInfo.hasOwnProperty("timezone")
                                ? userInfo?.timezone?.value
                                : "Asia/Kolkata"}
                            </div>
                          </li>

                          <li>
                            <label>Password</label>
                            <div className="user_de_1">
                              <input
                                type="Password"
                                value="1234556789"
                                name=""
                                className="show_only45"
                              />
                              <Link
                                to="/change_password"
                                className="btn btn-xs f_size_12 custom_btn btn-primary float-right"
                              >
                                Change Password
                              </Link>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <hr />

                      <div className="row mt-4">
                        <div className="col-sm-8">
                          <span className="text_milky f_size_20">
                            {" "}
                            <i className="fab fa-google-drive mr-1"></i> Google
                            Drive{" "}
                          </span>
                        </div>
                        <div className="col-sm-4 text-right action_btn86">
                          {gdAttach ? (
                            gAuthRemoveLoading ? (
                              <button className="btn btn-danger custom_btn btn-sm f_size_12">
                                <Spinner size="sm" className="mr-1" /> Remove
                              </button>
                            ) : (
                              <button
                                className="btn btn-danger custom_btn btn-sm f_size_12"
                                onClick={this.props.removeGoogleDriveAuth}
                              >
                                <i className="fa fa-trash mr-1"></i> Remove
                              </button>
                            )
                          ) : (
                            <span className="text-light">N/A</span>
                          )}
                        </div>
                      </div>

                      <div className="row mt-4">
                        <div className="col-sm-8">
                          <span className="text_milky f_size_20">
                            {" "}
                            <i className="fas fa-calendar-alt mr-1"></i> Google
                            Calendar{" "}
                          </span>
                        </div>
                        <div className="col-sm-4 text-right action_btn86">
                          {gcAttach ? (
                            gcAuthRemoveLoading ? (
                              <button className="btn btn-danger custom_btn btn-sm f_size_12">
                                <Spinner size="sm" className="mr-1" /> Remove
                              </button>
                            ) : (
                              <button
                                className="btn btn-danger custom_btn btn-sm f_size_12"
                                onClick={this.props.removeGoogleCalendarAuth}
                              >
                                <i className="fa fa-trash mr-1"></i> Remove
                              </button>
                            )
                          ) : (
                            <span className="text-light">N/A</span>
                          )}
                        </div>
                      </div>

                      {/*----------------Mobile Verification Message Popup start here ----------------*/}
                      <Modal
                        isOpen={otpModal}
                        className="wconfirmation_dilog_bx"
                      >
                        <ModalBody>
                          <div className="file_sharng_sct">
                            <form>
                              <div className="cnfmrtn_msg">
                                <p>
                                  Enter the OTP that we have sent on your{" "}
                                  {verifyType}
                                </p>
                                <div className="form-group col-lg-12 otp-modal-form">
                                  <div className="text-light otp_verify_email">
                                    <OtpField
                                      handleOtp={this.handleVerifyOtp.bind(
                                        this,
                                      )}
                                    />
                                    {otpErr ? (
                                      <small
                                        className="help-block error_msg_56 f_size_16 pl-0 mt-0 mb-0"
                                        ref="mob_err"
                                      >
                                        Please enter the OTP.
                                      </small>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="d-inline-block w-100 text-center">
                                <button
                                  type="button"
                                  onClick={() => this.closeModal("otpModal")}
                                  className="btn btn-secondary btn-sm mr-2"
                                >
                                  Cancel
                                </button>
                                {otpVerifyLoading ? (
                                  <button
                                    type="button"
                                    className="btn btn-primary btn-sm mr-2"
                                  >
                                    <Spinner size="sm" color="light" />{" "}
                                    Verifing...
                                  </button>
                                ) : (
                                  <button
                                    onClick={this.verifyOTP.bind(this)}
                                    className="btn btn-primary btn-sm mr-2"
                                  >
                                    Verify {verifyType}
                                  </button>
                                )}
                              </div>
                            </form>
                          </div>
                        </ModalBody>
                      </Modal>
                    </div>
                  )}
                </div>
              </div>

              <div className="col-md-4 mt-3 text-center profile_page_img">
                <img src="images/image_profile_up.png" alt="profileImage" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapState = (state) => {
  const {
    profileLoading,
    profileInfo,
    verificationSent,
    otpVerifyLoading,
    otpVerified,
    gAuthRemoveLoading,
    gAuthRemoved,
  } = state.authentication;
  const { gcAuthRemoveLoading, gcAuthRemoved } = state.event;
  return {
    profileLoading,
    profileInfo,
    verificationSent,
    otpVerifyLoading,
    otpVerified,
    gAuthRemoveLoading,
    gAuthRemoved,
    gcAuthRemoveLoading,
    gcAuthRemoved,
  };
};

const actionCreators = {
  userDetails: userActions.userDetails,
  removeGoogleDriveAuth: userActions.removeGoogleDriveAuth,
  verifyProfile: userActions.verifyProfile,
  verifyOtp: userActions.verifyOtp,
  removeGoogleCalendarAuth: eventActions.removeGoogleCalendarAuth,
};

export default withRouter(connect(mapState, actionCreators)(Profile));
