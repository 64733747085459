import React/* , { useState } */ from "react";
const HeaderCell = ({ title/* , search, isSearch = false  */ }) => {

  // const [anchorEl, setAnchorEl] = useState(null);
  // const [value, setValue] = useState(0);

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  // const open = Boolean(anchorEl);

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  return (
    <>
      {title}

    </>
  );

};

export const renderHeaderCell = (option) => {
  if (option) {
    if (typeof option.search !== 'undefined') {
      return (param) => (
        <HeaderCell
          title={param.field}
          search={option.search}
        // isSearch={option.isSearch} 
        />
      );
    }
  }
  return (param) => (param.field);
};