/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Form, Modal } from 'react-bootstrap'
import PerfectScrollbar from 'react-perfect-scrollbar'

import Layout from '../../layoutSection'
import { alertActions, conferenceActions } from '../../Redux/_actions';
import isEmpty from 'is-empty';
import { conferenceServices } from '../../Redux/_services';
import Spinner from '../../components/UI/Spinner/Spinner';

const Options = () => {

  const dispatch = useDispatch();
  const [hoverId, setHoverId] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const { meetingDetail, loading } = useSelector(state => state.conference)
  const [update, setUpdate] = useState({
    waitingRoom: false,
    webcamsOnlyForModerator: false,
    check_audio: false,
    allowStartStopRecording: false,
    autoStartRecording: false,
  })
  const paras = useParams()

  useEffect(() => {
    dispatch(conferenceActions.meetingDetail(paras.id))
  }, [paras.id])

  useEffect(() => {
    if (meetingDetail?.meetingID === paras?.id) {
      setUpdate({
        waitingRoom: meetingDetail?.waitingRoom == 1,
        webcamsOnlyForModerator: meetingDetail?.webcamsOnlyForModerator,
        "userdata-sarv_skip_check_audio": !meetingDetail?.['userdata-sarv_skip_check_audio'],
        join_without_host: meetingDetail?.join_without_host,
        allowStartStopRecording: meetingDetail?.allowStartStopRecording,
        autoStartRecording: meetingDetail?.autoStartRecording,
      })
    }
  }, [meetingDetail])


  const optionJson = [
    {
      id: 1,
      img: 'waitingRoom.png',
      title: 'Waiting Room',
      dis: `WAVE provides you with the functionality to create a waiting room for early attendees. Use this feature to control the joining of participants in the meeting. `,
      variable: 'waitingRoom',
    },
    {
      id: 2,
      img: 'cameraAccess.png',
      title: 'Camera Access',
      dis: `The host has access to participants cameras and microphones and can disable or enable without their consent to maintain the discipline of the meeting.`,
      variable: 'webcamsOnlyForModerator',
    },
    {
      id: 3,
      img: 'echoTest.png',
      title: 'Echo Test Audio',
      dis: `Noises and echoes during the meeting are very frustrating. Run an echo audio test before your meetings that will detect any kind of noise or echo.`,
      variable: 'userdata-sarv_skip_check_audio',
    },
    {
      id: 4,
      img: 'joinWithoutHost.png',
      title: 'Join Without Host',
      dis: `Sometimes you might need to start the conference even without the host. WAVE lets you start a conference without the host and record for future use.`,
      variable: 'join_without_host',
    },
    {
      id: 5,
      img: 'startAndStopRec.png',
      title: 'Allow Start & Stop Record',
      dis: `A host can allow moderator to Start & Stop recording the meeting for future training or usage. If this is enabled, no one can stop recording during meeting.`,
      variable: 'allowStartStopRecording',
    },
    {
      id: 6,
      img: 'autoRec.png',
      title: 'Auto Record',
      dis: `A host can allow participants to record the meeting for future training or usage. Recordings are saved in the cloud that you can download later.`,
      variable: 'autoStartRecording',
    }
  ]


  const getMeetUpdate = (value) => {
    setIsLoading(true)
    conferenceServices.updateMeeting({
      meetingId: meetingDetail?.meetingID,
      metaData: { [value]: !update[value] }
    })
      .then(
        (res) => {
          setUpdate({ ...update, [value]: !update[value] })
          setIsLoading(false)
        },
        (err) => {
          dispatch(alertActions.error(err.toString()))
          setIsLoading(false)
        }
      )
  }

  return (
    <Layout title={meetingDetail?.name || 'Options'} active="Options" list="meeting" goBack="/meeting_history" >
      <div className="container-fluid s_wave_content_part  ">
        {/* {isLoading && (
          <Modal show={isLoading} contentClassName="modalLoading">
            <Spinner />
          </Modal>
        )} */}
        <div className="detailsSection w-100 sideBar welcomeMsg  Options ">
          <div className='cardOptions'>
            {
              optionJson.map(data => <span className='w-100' >
                <div className='tagContainer bgColorBodyCard position-relative mt-3 cardWidth-100' onMouseEnter={() => setHoverId(data.id)} onMouseLeave={() => setHoverId(null)} >
                  <div className='between switch-check options' >
                    <div>{data.title}</div>
                    <Form.Check
                      type="switch"
                      id={`custom-switch-${data.id}`}
                      className="switchBtn"
                      label={<div className=''></div>}
                      checked={update[data.variable]}
                      onChange={() => getMeetUpdate(data.variable)}
                    />
                  </div>
                  {hoverId === data.id ?
                    <div className='f_size_12 mt-2' >
                      {data.dis}
                    </div>
                    : <div className='text-center'>
                      <img src={`../../images/${data.img}`} alt={data.title} className="ImagesSection" />
                    </div>}
                </div>
              </span>)
            }
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Options
