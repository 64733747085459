export const eventConstants = {
    
    GAUTH_URL_START: 'GAUTH_URL_START',
    GAUTH_URL_SUCCESS: 'GAUTH_URL_SUCCESS',
    GAUTH_URL_FAILURE: 'GAUTH_URL_FAILURE',

    GAUTH_TOKEN_START: 'GAUTH_TOKEN_START',
    GAUTH_TOKEN_SUCCESS: 'GAUTH_TOKEN_SUCCESS',
    GAUTH_TOKEN_FAILURE: 'GAUTH_TOKEN_FAILURE',

    REMOVE_GOOGLE_CALENDAR_START: 'REMOVE_GOOGLE_CALENDAR_START',
    REMOVE_GOOGLE_CALENDAR_SUCCESS: 'REMOVE_GOOGLE_CALENDAR_SUCCESS',
    REMOVE_GOOGLE_CALENDAR_FAILURE: 'REMOVE_GOOGLE_CALENDAR_FAILURE',

    GET_RECORDING_START: 'GET_RECORDING_START',
    GET_RECORDING_SUCCESS: 'GET_RECORDING_SUCCESS',
    GET_RECORDING_FAILURE: 'GET_RECORDING_FAILURE',

    EVENT_START: 'EVENT_START',
    EVENT_SUCCESS: 'EVENT_SUCCESS',
    EVENT_FAILURE: 'EVENT_FAILURE',

    EVENT_LIMIT_START: 'EVENT_LIMIT_START',
    EVENT_LIMIT_SUCCESS: 'EVENT_LIMIT_SUCCESS',
    EVENT_LIMIT_FAILURE: 'EVENT_LIMIT_FAILURE',

    EVENT_GOOGLE_START : 'EVENT_GOOGLE_START',
    EVENT_GOOGLE_SUCCESS : 'EVENT_GOOGLE_SUCCESS',
    EVENT_GOOGLE_FAILURE : 'EVENT_GOOGLE_FAILURE',

    MEETING_HISTORY_START: 'MEETING_HISTORY_START',
    MEETING_HISTORY_SUCCESS: 'MEETING_HISTORY_SUCCESS',
    MEETING_HISTORY_FAILURE: 'MEETING_HISTORY_FAILURE'

};
