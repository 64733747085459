import React, { Component } from 'react';
import { Button/* , Popover, PopoverBody  */ } from 'reactstrap';
import { departmentActions, userActions } from './../../../../Redux/_actions';
import { withRouter } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import ConfirmationPopup from './../../../../components/UI/ConfirmationPopup/confirmationPopup'
import { connect } from 'react-redux';

class ListContent extends Component {
    // const [teampopoverOpen, setteamPopoverOpen] = useState(false);
    // const teamPopHover = () => setteamPopoverOpen(!teampopoverOpen);

    componentDidMount() {
        this.props.getCompanyUsers();
    }

    editUser(id) {
        this.props.history.push("/eoffice/Setting/edit-user/" + id);

    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.userRemovedFromBusiness !== this.props.userRemovedFromBusiness) {
            this.props.getCompanyUsers();
        }
    }

    removeFromBusiness(userid) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (<ConfirmationPopup
                    message={"Are you sure want to remove this contact ?"}
                    noBtnAction={onClose}
                    noBtnText="No"
                    yesBtnText="Yes, Delete AnyWay!"
                    yesBtnAction={() => this.props.removeUserFromBusiness(userid)}
                />
                )
            }
        });
    }

    render() {
        return (
            <>
                {this.props.companyUsers.map(user =>
                    <tr>
                        <td>
                            <div className="checkbox">
                                <input className="styled-checkbox" id="styled-checkbox-1" type="checkbox" value="value1" />
                                <label for="styled-checkbox-1"></label>
                            </div>
                        </td>
                        <td>{user.name}</td>
                        <td>{user.mobile}</td>
                        <td className="nowrap">{user.email}</td>
                        <td className="nowrap">{user.roleData !== null && user.roleData.role_name || '-'}</td>
                        <td>
                            {user.hasOwnProperty('team') && user.team.map(team => {
                                return (
                                    <span className="tagname">{team.team_name || team}</span>
                                )
                            })}


                        </td>
                        <td className="nowrap"><Button onClick={() => this.editUser(user.uuid)} className="btn-sm btn-round btn-edit"> <i className="fas fa-pencil-alt"></i> Edit</Button></td>
                        <td className="nowrap"><Button onClick={() => this.removeFromBusiness(user.uuid)} className="btn-sm btn-round btn-edit"> <i className="fas fa-pencil-alt"></i>Remove</Button></td>
                    </tr>
                )}

            </>

        )
    }

}

const mapState = (state) => {
    const { companyUsers } = state.department;
    const { userRemovedFromBusiness } = state.authentication;

    return {
        companyUsers,
        userRemovedFromBusiness
    };
}

const actionCreators = {
    getCompanyUsers: departmentActions.getCompanyUsers,
    removeUserFromBusiness: userActions.removeUserFromBusiness
};

export default withRouter(connect(mapState, actionCreators)(ListContent));

