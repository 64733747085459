import React, { Component } from 'react';
import LeftPanelManager from '../components/LeftPanelManager/LeftPanelManager';
import CenterPanelManager from '../components/CenterPanelManager/CenterPanelManager';
// import RightPanelManager from '../components/RightPanelManager/RightPanelManager';

import './style.scss';
class TalkContainer extends Component {
    constructor(props){
        super(props)
    }
    render() {
        return (
            <div className="talkContainer">
            <LeftPanelManager /> 
            <div className="centerPanelSection">
                <CenterPanelManager join_url ={this.props.join_url}/>
                </div>
                {/* <RightPanelManager />   */}
            </div>
        )
    }
}

export default TalkContainer;
