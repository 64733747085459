export const companyConstants = {

    COMPANY_REGISTER_REQUEST: 'COMPANY_REGISTER_REQUEST',
    COMPANY_REGISTER_SUCCESS: 'COMPANY_REGISTER_SUCCESS',
    COMPANY_REGISTER_FAILURE: 'COMPANY_REGISTER_FAILURE',

    COMPANY_CHECK_EMAIL_REQUEST: 'COMPANY_CHECK_EMAIL_REQUEST',
    COMPANY_CHECK_EMAIL_SUCCESS: 'COMPANY_CHECK_EMAIL_SUCCESS',
    COMPANY_CHECK_EMAIL_FAILURE: 'COMPANY_CHECK_EMAIL_FAILURE',

    COMPANY_INFO_REQUEST: 'COMPANY_INFO_REQUEST',
    COMPANY_INFO_SUCCESS: 'COMPANY_INFO_SUCCESS',
    COMPANY_INFO_FAILURE: 'COMPANY_INFO_FAILURE',

    COMPANY_BRANDING_INFO_REQUEST: 'COMPANY_BRANDING_INFO_REQUEST',
    COMPANY_BRANDING_INFO_SUCCESS: 'COMPANY_BRANDING_INFO_SUCCESS',
    COMPANY_BRANDING_INFO_FAILURE: 'COMPANY_BRANDING_INFO_FAILURE',

    COMPANY_LOGO_REQUEST: 'COMPANY_LOGO_REQUEST',
    COMPANY_LOGO_SUCCESS: 'COMPANY_LOGO_SUCCESS',
    COMPANY_LOGO_FAILURE: 'COMPANY_LOGO_FAILURE',

    DECLINE_REQUEST: 'DECLINE_REQUEST',
    DECLINE_SUCCESS: 'DECLINE_SUCCESS',
    DECLINE_FAILURE: 'DECLINE_FAILURE',

    ACCEPT_REQUEST: 'ACCEPT_REQUEST',
    ACCEPT_SUCCESS: 'ACCEPT_SUCCESS',
    ACCEPT_FAILURE: 'ACCEPT_FAILURE',

    EXIT_REQUEST: 'EXIT_REQUEST',
    EXIT_SUCCESS: 'EXIT_SUCCESS',
    EXIT_FAILURE: 'EXIT_FAILURE'
};