import React from 'react';
import './FooterBottom.css';

const FooterBottom = () => {
    return (  
  <div className="container-fluid footer footerbottomContainer">
  <div className="container">
  <div className="col-lg-9 mx-auto">
        <div className="footer-row">
            <div className="footer-logo">
                Developed & Maintained By <a href="https://wave.sarv.com/" target="_blank"><img src="images/homePage/sarv-wave-logo.png" width="180" alt="Sarv Wave Video Conferencing" /></a>
            </div>
            <div className="footer-divider"></div>
            <div className="footer-logo">
            In Association with <a href="https://negd.gov.in/" target="_blank"><img src="images/homePage/logo-negd.png"  alt="National e-Governance Division" /></a>
        </div>
        </div>
  </div>

  </div>

</div>
    )
}

export default FooterBottom;
