/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Modal, Spinner, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import {
	userActions,
	alertActions,
} from "../../Redux/_actions";

import {
	userSignUpService,
} from "../../Redux/_services";

const VerifySignup = (props) => {
	const { email, isShow, goLogin } = props;
	const [timeOut, setTimeOut] = useState(false)
	const state = useSelector(state => state.authentication)
	const dispatch = useDispatch();

	useEffect(() => {
		if (!timeOut) {
			getWaitingTime()
		}
	}, [timeOut])

	useEffect(() => {
		if (state.emailSent) {
			setTimeOut(false)
		} else if (state.emailSendError) {
			goLogin();
		}
	}, [state.emailSent, state.emailSendError])


	const getWaitingTime = () => {
		const parmas = { email: email }
		const checkVerification = setInterval(() => {
			userSignUpService.checkEmailVerified(parmas)
				.then(
					resp => {
						console.log(resp, " it fomr data")
						if (resp?.isEmailVerified) {
							dispatch(alertActions.success('Email successfully verified!'))
							clearInterval(checkVerification)
							goLogin()
						}
					},
					error => {
						console.log('err', error)
					}
				);
		}, 5000)// --> 5 Sec 

		setTimeout(() => {
			clearInterval(checkVerification)
			setTimeOut(true)
		}, 600000)// --> 10M 
	}

	return (
		<Modal show={isShow} onHide={() => console.log('')} contentClassName="sideBar welcomeMsg" >
			<Modal.Header  >
				<i className="fas fa-check-circle text-success "></i>
				<h6 className="ml-2 welcomeMsg"> A verification link has been sent to your email account.</h6>
			</Modal.Header>

			<Modal.Body className='welcomeMsg'>
				{
					timeOut ?
						<div className="mt-2  text-center col-12 p-0 pt-1 pb-1  d-flex justify-content-between">
							<div className="pl-2 pr-2 ">
								Session timeout

							</div>
							<Button
								variant="danger"
								onClick={() =>
									dispatch(userActions.resendEmailLink(email))
								}
							>
								Resend Again
							</Button>
						</div>
						:
						<div>
							please click on the link that has just been sent to your email to
							verify you email and complete the registration process.
							<Spinner animation="border" variant="secondary" size="sm" className="ml-2" />
						</div>}
			</Modal.Body>
		</Modal>
	)
}

VerifySignup.propTypes = {
	email: PropTypes.string.isRequired,
	isShow: PropTypes.bool.isRequired,
	goLogin: PropTypes.func.isRequired
}

export default VerifySignup