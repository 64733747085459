import { myconfig, siteTokens } from "../../config/config";
import axios from "axios";
import userconfigs from "../../helpers/closures";
import { generateToken } from "../../helpers/helpers";

import { handleResponse, errorCheck, handleJWTError } from './common'

export const webinarRoomService = {
  speakerList,
  attendeesList,
  submitChat,
  chatList,
  handRaise,
  webinarRoomDetail,
  permissionAttendee,
  moderatorToWR,
  questionSubmitAttendee,
  answerSubmitAttendee,
  moderatorFAQList,
  attendeesFAQList,
  likeFAQSubmit,
  starFAQSubmit,
  changeAllowBitAttendee,
  webinarPeerData,
};

function speakerList(webinarID) {
  let axiosRequestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: userconfigs.getItem("jwt"),
    },
  };

  let postData = {
    wid: webinarID,
  };

  return axios
    .post(
      `${myconfig.apiURL}webinar/speakerList`,
      postData,
      axiosRequestOptions
    )
    .then(handleResponse)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
        handleJWTError();
        // userconfigs.removeItem("jwt");
        // generateToken();
        // window.location.href = "/login";
      } else {
        return Promise.reject(errorCheck(error));
      }
    });
}

function attendeesList(webinarID, fromTime) {
  let axiosRequestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: userconfigs.getItem("jwt"),
    },
  };

  let postData = { wid: webinarID };
  if (fromTime) {
    postData = { wid: webinarID, from: fromTime };
  }

  return axios
    .post(
      `${myconfig.apiURL}webinar/attendeeList`,
      postData,
      axiosRequestOptions
    )
    .then(handleResponse)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
        handleJWTError();
        // userconfigs.removeItem("jwt");
        // generateToken();
        // window.location.href = "/login";
      } else {
        return Promise.reject(errorCheck(error));
      }
    });
}

function handRaise(webinarID, userID, handRaiseAction) {
  let axiosRequestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: userconfigs.getItem("jwt"),
    },
  };

  let postData = {
    wid: webinarID,
    token: userID,
    hAction: handRaiseAction,
  };

  return axios
    .post(`${myconfig.apiURL}webinar/handAction`, postData, axiosRequestOptions)
    .then(handleResponse)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
        handleJWTError();
        // userconfigs.removeItem("jwt");
        // generateToken();
        // window.location.href = "/login";
      } else {
        return Promise.reject(errorCheck(error));
      }
    });
}

function submitChat(formData) {
  let axiosRequestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: userconfigs.getItem("jwt"),
    },
  };

  return axios
    .post(
      `${myconfig.apiURL}webinar/webinarRoomChat`,
      formData,
      axiosRequestOptions
    )
    .then(handleResponse)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
        handleJWTError();
        // userconfigs.removeItem("jwt");
        // generateToken();
        // window.location.href = "/login";
      } else {
        return Promise.reject(errorCheck(error));
      }
    });
}

function chatList(webinarID) {
  let axiosRequestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: userconfigs.getItem("jwt"),
    },
  };

  let postData = {
    wid: webinarID,
  };

  return axios
    .post(
      `${myconfig.apiURL}webinar/listWebinarChats`,
      postData,
      axiosRequestOptions
    )
    .then(handleResponse)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
        handleJWTError();
        // userconfigs.removeItem("jwt");
        // generateToken();
        // window.location.href = "/login";
      } else {
        return Promise.reject(errorCheck(error));
      }
    });
}

function webinarRoomDetail(wid, token) {
  let axiosRequestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: userconfigs.getItem("jwt"),
    },
  };

  let postData = {
    wid: wid,
    token: token,
  };

  return axios
    .post(
      `${myconfig.apiURL}webinar/webinarRoomDetail`,
      postData,
      axiosRequestOptions
    )
    .then(handleResponse)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
        handleJWTError();
        // userconfigs.removeItem("jwt");
        // generateToken();
        // window.location.href = "/login";
      } else {
        return Promise.reject(errorCheck(error));
      }
    });
}

function permissionAttendee(wid, token, action) {
  let axiosRequestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: userconfigs.getItem("jwt"),
    },
  };

  let postData = {
    wid: wid,
    token: token,
    action: action,
  };

  return axios
    .post(
      `${myconfig.apiURL}webinar/webinarUserAction`,
      postData,
      axiosRequestOptions
    )
    .then(handleResponse)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
        handleJWTError();
        // userconfigs.removeItem("jwt");
        // generateToken();
        // window.location.href = "/login";
      } else {
        return Promise.reject(errorCheck(error));
      }
    });
}

function changeAllowBitAttendee(wid, token) {
  let axiosRequestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: userconfigs.getItem("jwt"),
    },
  };

  let postData = {
    wid: wid,
    token: token,
  };

  return axios
    .post(
      `${myconfig.apiURL}webinar/webinarBitUpdate`,
      postData,
      axiosRequestOptions
    )
    .then(handleResponse)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
        handleJWTError();
        // userconfigs.removeItem("jwt");
        // generateToken();
        // window.location.href = "/login";
      } else {
        return Promise.reject(errorCheck(error));
      }
    });
}

function moderatorToWR(wid) {
  let axiosRequestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: userconfigs.getItem("jwt"),
    },
  };

  let postData = {
    wid: wid,
    fingerprint: userconfigs.getItem("fp"),
  };

  return axios
    .post(
      `${myconfig.apiURL}webinar/moderatorToWR`,
      postData,
      axiosRequestOptions
    )
    .then(handleResponse)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
        handleJWTError();
        // userconfigs.removeItem("jwt");
        // generateToken();
        // window.location.href = "/login";
      } else {
        return Promise.reject(errorCheck(error));
      }
    });
}
//sdgfdg

function questionSubmitAttendee(postData) {
  let axiosRequestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: userconfigs.getItem("jwt"),
    },
  };

  return axios
    .post(
      `${myconfig.apiURL}webinar/askQuestion`,
      postData,
      axiosRequestOptions
    )
    .then(handleResponse)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
        handleJWTError();
        // userconfigs.removeItem("jwt");
        // generateToken();
        // window.location.href = "/login";
      } else {
        return Promise.reject(errorCheck(error));
      }
    });
}

function answerSubmitAttendee(postData) {
  let axiosRequestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: userconfigs.getItem("jwt"),
    },
  };

  return axios
    .post(
      `${myconfig.apiURL}webinar/ansQuestion`,
      postData,
      axiosRequestOptions
    )
    .then(handleResponse)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      if (error.response.status === 408) {
        handleJWTError();
        // userconfigs.removeItem("jwt");
        // generateToken();
        // window.location.href = "/login";
      } else {
        return Promise.reject(errorCheck(error));
      }
    });
}

function moderatorFAQList(wid, qType) {
  let axiosRequestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: userconfigs.getItem("jwt"),
    },
  };

  let postData = {
    wid: wid,
  };

  if (qType) {
    postData = {
      wid: wid,
      qType: 0,
    };
  }

  return axios
    .post(
      `${myconfig.apiURL}webinar/listModeratorQA`,
      postData,
      axiosRequestOptions
    )
    .then(handleResponse)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
        handleJWTError();
        // userconfigs.removeItem("jwt");
        // generateToken();
        // window.location.href = "/login";
      } else {
        return Promise.reject(errorCheck(error));
      }
    });
}

function attendeesFAQList(wid, token) {
  let axiosRequestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: userconfigs.getItem("jwt"),
    },
  };

  let postData = {
    wid: wid,
    token: token,
  };

  return axios
    .post(
      `${myconfig.apiURL}webinar/listAttendeeQA`,
      postData,
      axiosRequestOptions
    )
    .then(handleResponse)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
        handleJWTError();
        // userconfigs.removeItem("jwt");
        // generateToken();
        // window.location.href = "/login";
      } else {
        return Promise.reject(errorCheck(error));
      }
    });
}

function likeFAQSubmit(postData) {
  let axiosRequestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: userconfigs.getItem("jwt"),
    },
  };

  return axios
    .post(`${myconfig.apiURL}webinar/likeQA`, postData, axiosRequestOptions)
    .then(handleResponse)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      if (error.response.status === 408) {
        handleJWTError();
        // userconfigs.removeItem("jwt");
        // generateToken();
        // window.location.href = "/login";
      } else {
        return Promise.reject(errorCheck(error));
      }
    });
}

function starFAQSubmit(postData) {
  let axiosRequestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: userconfigs.getItem("jwt"),
    },
  };

  return axios
    .post(`${myconfig.apiURL}webinar/starQA`, postData, axiosRequestOptions)
    .then(handleResponse)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
        handleJWTError();
        // userconfigs.removeItem("jwt");
        // generateToken();
        // window.location.href = "/login";
      } else {
        return Promise.reject(errorCheck(error));
      }
    });
}

function webinarPeerData(wid, lastTime) {
  let axiosRequestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: userconfigs.getItem("jwt"),
    },
  };

  let postData = {
    wid: wid,
  };
  if (lastTime !== undefined) {
    postData.lastTime = lastTime;
  }

  return axios
    .post(
      `${myconfig.apiURL}webinar/webinarPeerData`,
      postData,
      axiosRequestOptions
    )
    .then(handleResponse)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
        handleJWTError();
        // userconfigs.removeItem("jwt");
        // generateToken();
        // window.location.href = "/login";
      } else {
        return Promise.reject(errorCheck(error));
      }
    });
}

