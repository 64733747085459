import { notificationConstants } from '../_constants';
import { notificationService } from '../_services';
// import { alertActions } from './';

export const notificationActions = {
    longPolling
};

function longPolling() {
    return dispatch => {
        dispatch(request({}));

        notificationService.longPolling()
            .then(
                resp => {
                    dispatch(success(resp))
                },
                error => {}
            );
    };

    function request(resp) { return { type: notificationConstants.LONG_POLLING_REQUEST} }
    function success(resp) { return { type: notificationConstants.LONG_POLLING_SUCCESS, resp } }
}
