import React from 'react'
import { Formik } from 'formik'
import { Button, Form } from 'react-bootstrap'
import PhoneInput from 'react-phone-input-2'
import "react-phone-input-2/lib/style.css";
import * as Yup from 'yup'

import Modal from '../../layoutSection/Modal'
import { InputFiled } from '../../Common/authFile'
import { ReCurring } from '../MeetingHistory/CreateMeet/stepFirst'


export const AddParticipants = (props) => {
  const { show, toggle, meetId, onSubmit } = props

  const initialValues = {
    type: 'mobile',
    email: '',
    mobile: '',
    countryCode: '',
  }

  const validation = Yup.object().shape({
    type: Yup.string().required(),
    email: Yup.string().email().when('type', {
      is: (value) => value === 'email',
      then: Yup.string().required("Must enter email address"),
      otherwise: Yup.string(),
    }),
    mobile: Yup.string().when('type', {
      is: (value) => value === 'mobile',
      then: Yup.string().required("Must enter mobile address"),
      otherwise: Yup.string(),
    }),
  })

  const submitHandler = (value) => {
    let setValue = "";
    if (value.type === "mobile") {
      setValue = "+" + value.countryCode + "-" + value.mobile;
    } else {
      setValue = value.email;
    }

    let participantArr = [];
    participantArr.push(setValue)

    let formData = {};
    formData.meetingId = meetId;
    formData.participantArr = participantArr;
    onSubmit(formData);
    toggle()
  }

  return (
    <Modal
      show={show}
      toggle={toggle}
      size="lg"
      label={<h4 className="mb-0 mr-2"> Add Participants</h4>}
    >

      <div className='mt-3 welcomeMsg formContainerAddParticipants'>
        <Formik
          initialValues={initialValues}
          onSubmit={(value) => submitHandler(value)}
          validationSchema={validation}
        >
          {
            ({ values, setFieldValue, errors, touched, setFieldTouched, handleSubmit }) => (
              <>
                <div className=''>
                  <button className={`btnBgLess mr-3 ${values.type === "mobile" ? 'activeType' : ' welcomeMsg'}`} onClick={() => setFieldValue('type', 'mobile')} >Mobile </button>
                  <button className={`btnBgLess ml-2 ${values.type === "email" ? 'activeType' : ' welcomeMsg'}`} onClick={() => setFieldValue('type', 'email')}> Email </button>
                </div>
                {
                  values.type === 'email' ?
                    <InputFiled
                      name="email"
                      type="text"
                      label="Email"
                      placeholder="Enter email "
                      className="form-control"
                      containerClassName=" mt-3"
                    />
                    :
                    <Form.Group className='mt-3'>
                      <Form.Label className="f_size_14 ml-3 ">Mobile Number</Form.Label>
                      <PhoneInput
                        country="in"
                        name="mobileNo"
                        inputClass="mobileInput welcomeMsg w-100"
                        enableSearch={true}
                        placeholder="Mobile No"
                        countryCodeEditable={false}
                        // value={`${values.countryCode}${values.mobileNo}`}
                        onChange={(value, country) => {
                          setFieldValue(
                            "mobile",
                            value.slice(country.dialCode.length)
                          );
                          setFieldValue("countryCode", country.dialCode);
                        }}
                        inputProps={{
                          onBlur: () => setFieldTouched("mobile", true),
                        }}
                      />
                      {errors.mobile && touched.mobile && (
                        <div className="error text-danger f_size_13">
                          {errors.mobile}
                        </div>
                      )}
                    </Form.Group>
                }
                <div className="mt-4">
                  <span className="text-warning d-block">Note: </span>
                  <span className="text_milky d-block f_size_13 mt-1">1. Mobile number should with country code Ex: +91-97XXXXXXXX</span>
                  <span className="text_milky d-block f_size_13 mt-1">2. Email id should be lowercase Ex: test@xxx.com</span>
                </div>
                <div className='text-center mt-4'>
                  <button className='headerBtn submitBtnColor border-0  text-white' type='submit' onClick={() => handleSubmit()} >  Add Participants </button>
                </div>
              </>
            )
          }
        </Formik>
      </div>
    </Modal >
  )
}



export const EditRecurring = (props) => {
  const { show, toggle, values, setFieldValue, errors, touched, getReset } = props
  return (
    <Modal show={show} toggle={toggle} label={<h3 className='welcomeMsg'>Recurring meeting</h3>} >
      <div className=''>
        <ReCurring values={values} setFieldValue={setFieldValue} errors={errors} touched={touched} className="bg-transparent" />
      </div>
      <div className='bottomBorder mb-3' />
      <div className='text-right'>
        <button className='headerBtn sideBar border-0  pl-4 pr-4 text-white mr-3' onClick={() => getReset()} > Cancel </button>
        <button className='headerBtn submitBtnColor border-0  pl-4 pr-4 text-white' onClick={() => toggle()} > Submit </button>
      </div>
    </Modal>
  )
}
