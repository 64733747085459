import { reportsConstants } from '../_constants';
// import { ExportToCsv } from "export-to-csv";

const initialState = {
  loadingReport: false,
  loadingReportReg: false,
  regReport: [],
  reportError: null,
  reportList: [],
  loadingRecord: false,
  meetRecord: null,
  meetRecordErr: null,
};

export function report(state = initialState, action) {
  switch (action.type) {
    case reportsConstants.GET_REPORTS_REQUEST:
      return {
        ...state,
        loadingReport: true,
        reportError: null,
      };
    case reportsConstants.GET_REPORTS_SUCCESS:
      return {
        ...state,
        loadingReport: false,
        reportList: action.resp,
        reportError: null,
      };
    case reportsConstants.GET_REPORTS_FAILURE:
      return {
        ...state,
        loadingReport: false,
        reportList: [],
        reportError: action.error,
      };
    case reportsConstants.GET_REG_REPORTS_REQUEST:
      return {
        ...state,
        loadingReportReg: action.resp,
        regReport: [],
      };
    case reportsConstants.GET_REG_REPORTS_SUCCESS:
      return {
        ...state,
        loadingReportReg: false,
        regReport: action.resp,
        reportError: null,
      };
    case reportsConstants.GET_REG_REPORTS_FAILURE:
      return {
        ...state,
        loadingReportReg: false,
        regReport: [],
        reportError: action.error,
      };
    case reportsConstants.GET_MEETING_RECORDING_REQUEST:
      return {
        ...state,
        loadingRecord: true,
        meetRecord: null,
        meetRecordErr: null,
      };
    case reportsConstants.GET_MEETING_RECORDING_SUCCESS:
      return {
        ...state,
        loadingRecord: true,
        meetRecord: action.resp,
        meetRecordErr: null,
      };
    case reportsConstants.GET_MEETING_RECORDING_FAILURE:
      return {
        ...state,
        loadingRecord: true,
        meetRecord: null,
        meetRecordErr: action.error,
      };
    default:
      return state
  }
}