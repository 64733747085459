import { myconfig, siteTokens } from "../../config/config";
//import { authHeader } from '../_helpers';
import axios from "axios";
import userconfigs from "../../helpers/closures";

import { handleResponse, errorCheck, handleJWTError } from './common'

export const waitingRoomService = {
    addUser,
    broadcastMsg,
    getBroadcastMsg,
    listAttendee,
    attendeeReqRes,
    attendeeJoin,
    moderatorJoin,
};

function addUser(name, meeting_id, meeting_password, modId, isWebinar) {
    let axiosRequestOptions = {
        headers: {
            "Content-Type": "application/json",
            Authorization: userconfigs.getItem("jwt"),
        },
    };

    let addUserPost = {
        name: name,
        meetingId: meeting_id,
    };
    if (meeting_password !== "undefiend") {
        addUserPost.pwd = meeting_password;
    }
    if (modId !== "undefiend") {
        addUserPost.modId = modId;
    }
    return axios
        .post(
            `${myconfig.apiURL}meeting/addUserToWR`,
            addUserPost,
            axiosRequestOptions
        )
        .then(handleResponse)
        .then((resp) => {
            return resp;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
                // userconfigs.removeItem("jwt");
                // generateToken();
                // window.location.href = "/login";
            } else {
                return Promise.reject(errorCheck(error));
            }
            //return error.response.data.error;
        });
}

function broadcastMsg(mid, type, data) {
    let axiosRequestOptions = {
        headers: {
            "Content-Type":
                type === "audio" ? "multipart/form-data" : "application/json",
            Authorization: userconfigs.getItem("jwt"),
        },
    };

    var apiURL = "";
    var broadcastPost;
    if (type === "audio") {
        broadcastPost = new FormData();
        broadcastPost.append("msgVal", data.blob);
        broadcastPost.append("meetingId", mid);
        broadcastPost.append("msgType", type);

        apiURL = `${myconfig.apiURL}meeting/broadcastWRAudioMsg`;
    } else {
        broadcastPost = {
            meetingId: mid,
            msgType: type,
            msgVal: data,
        };
        apiURL = `${myconfig.apiURL}meeting/broadcastWRMsg`;
    }

    return axios
        .post(apiURL, broadcastPost, axiosRequestOptions)
        .then(handleResponse)
        .then((resp) => {
            return resp;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
                // userconfigs.removeItem("jwt");
                // generateToken();
                // window.location.href = "/login";
            } else {
                return Promise.reject(errorCheck(error));
            }
            //return error.response.data.error;
        });
}

function getBroadcastMsg(mid, last_msg_time) {
    let axiosRequestOptions = {
        headers: {
            "Content-Type": "application/json",
            Authorization: userconfigs.getItem("jwt"),
        },
    };

    let getBroadcastPost = {
        meetingId: mid,
    };

    if (last_msg_time !== "") {
        getBroadcastPost.last_msg_time = last_msg_time;
    }

    return axios
        .post(
            `${myconfig.apiURL}meeting/listBroadcastWRMsg`,
            getBroadcastPost,
            axiosRequestOptions
        )
        .then(handleResponse)
        .then((resp) => {
            return resp;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
                // userconfigs.removeItem("jwt");
                // generateToken();
                // window.location.href = "/login";
            } else {
                return Promise.reject(errorCheck(error));
            }
            //return error.response.data.error;
        });
}

function listAttendee(mid, last_attendee_time) {
    let axiosRequestOptions = {
        headers: {
            "Content-Type": "application/json",
            Authorization: userconfigs.getItem("jwt"),
        },
    };

    let listAttendeePost = {
        meetingId: mid,
    };

    if (last_attendee_time !== "") {
        listAttendeePost.last_attendee_time = last_attendee_time;
    }

    return axios
        .post(
            `${myconfig.apiURL}meeting/listWRUsers`,
            listAttendeePost,
            axiosRequestOptions
        )
        .then(handleResponse)
        .then((resp) => {
            return resp;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
                // userconfigs.removeItem("jwt");
                // generateToken();
                // window.location.href = "/login";
            } else {
                return Promise.reject(errorCheck(error));
            }
            //return error.response.data.error;
        });
}

function attendeeReqRes(mid, action, attendeeId) {
    let axiosRequestOptions = {
        headers: {
            "Content-Type": "application/json",
            Authorization: userconfigs.getItem("jwt"),
        },
    };

    let getBroadcastPost = {
        meetingId: mid,
        action: action,
        attendeeId: attendeeId,
    };

    return axios
        .post(
            `${myconfig.apiURL}meeting/allowWRUser`,
            getBroadcastPost,
            axiosRequestOptions
        )
        .then(handleResponse)
        .then((resp) => {
            return resp;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
                // userconfigs.removeItem("jwt");
                // generateToken();
                // window.location.href = "/login";
            } else {
                return Promise.reject(errorCheck(error));
            }
            //return error.response.data.error;
        });
}

function attendeeJoin(mid, name) {
    let axiosRequestOptions = {
        headers: {
            "Content-Type": "application/json",
            Authorization: userconfigs.getItem("jwt"),
        },
    };

    let attendeeJoinPost = {
        meetingId: mid,
        name: name,
    };

    return axios
        .post(
            `${myconfig.apiURL}meeting/attendeeJoinMeet`,
            attendeeJoinPost,
            axiosRequestOptions
        )
        .then(handleResponse)
        .then((resp) => {
            return resp;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
                // userconfigs.removeItem("jwt");
                // generateToken();
                // window.location.href = "/login";
            } else {
                return Promise.reject(errorCheck(error));
            }
            //return error.response.data.error;
        });
}

function moderatorJoin(mid, name) {
    let axiosRequestOptions = {
        headers: {
            "Content-Type": "application/json",
            Authorization: userconfigs.getItem("jwt"),
        },
    };

    let moderatorJoinPost = {
        meetingId: mid,
    };

    return axios
        .post(
            `${myconfig.apiURL}meeting/startMeet`,
            moderatorJoinPost,
            axiosRequestOptions
        )
        .then(handleResponse)
        .then((resp) => {
            return resp;
        })
        .catch((error) => {
            if (error.response.status === 408) {
                handleJWTError();
                // userconfigs.removeItem("jwt");
                // generateToken();
                // window.location.href = "/login";
            } else {
                return Promise.reject(errorCheck(error));
            }
            //return error.response.data.error;
        });
}

