export const departmentConstants = {
    DEPARTMENT_GETALL_REQUEST: 'DEPARTMENT_GETALL_REQUEST',
    DEPARTMENT_GETALL_SUCCESS: 'DEPARTMENT_GETALL_SUCCESS',
    DEPARTMENT_GETALL_FAILURE: 'DEPARTMENT_GETALL_FAILURE',

    DEPARTMENT_ADD_REQUEST: 'DEPARTMENT_ADD_REQUEST',
    DEPARTMENT_ADD_SUCCESS: 'DEPARTMENT_ADD_SUCCESS',
    DEPARTMENT_ADD_FAILURE: 'DEPARTMENT_ADD_FAILURE',

    DEPARTMENT_EDIT_REQUEST: 'DEPARTMENT_EDIT_REQUEST',
    DEPARTMENT_EDIT_SUCCESS: 'DEPARTMENT_EDIT_SUCCESS',
    DEPARTMENT_EDIT_FAILURE: 'DEPARTMENT_EDIT_FAILURE',

    DEPARTMENT_BYID_REQUEST: 'DEPARTMENT_BYID_REQUEST',
    DEPARTMENT_BYID_SUCCESS: 'DEPARTMENT_BYID_SUCCESS',
    DEPARTMENT_BYID_FAILURE: 'DEPARTMENT_BYID_FAILURE',

    GET_COMPANY_USER_REQUEST: 'GET_COMPANY_USER_REQUEST',
    GET_COMPANY_USER_SUCCESS: 'GET_COMPANY_USER_SUCCESS',
    GET_COMPANY_USER_FAILURE: 'GET_COMPANY_USER_FAILURE'
};
