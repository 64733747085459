import React, { useState, useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Formik } from 'formik';
import * as Yup from 'yup';
import isEmpty from "is-empty";
import MD5 from "crypto-js/md5";

import Signupright from "../images/signup.jpg";
import PhoneInput from "react-phone-input-2";
import { userService } from "./../../Redux/_services";
import LoadingSpinner from '../../components/UI/Spinner/Spinner';
import AuthLayout from "../../Authorization/layout";
import { InputFiled } from "../../Common/authFile";
import Images from "../../images";

import "../styles.scss";
import {
  /* google_config, */
  passwordToBreak,
  encDataKey,
} from "../../config/config";
import { useDispatch, useSelector } from "react-redux";
import { userSignUpActions } from "../../Redux/_actions";

const crypto = require("crypto-js");

const validation = Yup.object().shape({
  name: Yup.string()
    .matches(/^[A-Za-z ]*$/, "Please enter valid name")
    .max(40)
    .required("Please enter your name!"),
  mobile: Yup.string()
    .required("Please enter the phone number!")
    .test(
      "len",
      "Phone number is incorrect!",
      (val) => isEmpty(val) || val.length === 10
    ),
  email: Yup.string()
    .email("Please enter the valid email id!")
    .required("Please enter the email id!"),
  password: Yup.string()
    .required('Please Enter your Password!')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is Required!"),
});


function HostInvitation(props) {
  const [userid, setUserId] = useState(props.match.params.userid);
  const [userExistRef, setUserExistRef] = useState(
    props.match.params.ref === "1" ? true : false
  );
  const [userEmail, setUserEmail] = useState(
    props.match.params.email ? props.match.params.email : ""
  );
  const [csrfToken, setCsrfToken] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const { signedUp } = useSelector(
    (state) => state.authRegistration
  );
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    if (userExistRef) {
      submitHandlerApi({ userid, email: userEmail })
    } else {
      setLoading(false);
      userService.getCsrf().then((res) => {
        setCsrfToken(res.csrfToken);
      });
    }
  }, [])

  useEffect(() => {
    if (signedUp) {
      submitHandlerApi({ userid, email: userEmail })
    }
  }, [signedUp])

  const submitHandlerApi = (payload) => {
    userService
      .postData(payload, 'admin/hostuser/acceptInvite')
      .then((res) => {
        setLoading(false);
        if (res === "Request Accepted successfully") {
          setUserEmail(true)
        }
        console.log("userService.postData", res);
      })
      .catch((error) => {
        setLoading(false);
        setUserExistRef(false);
      })
  }


  const signUpSubmit = (value) => {
    let md5Hash = MD5(value.password).toString();
    md5Hash = md5Hash + passwordToBreak + csrfToken;
    const key = crypto.enc.Utf8.parse(encDataKey);
    md5Hash = crypto.AES.encrypt(md5Hash, key,
      {
        mode: crypto.mode.ECB,
        padding: crypto.pad.Pkcs7,
      }).toString();

    value.password = md5Hash;
    value.eStatus = 1;
    delete value["confirmPassword"];
    dispatch(userSignUpActions.signup(value));
  };

  const initialValues = {
    name: "",
    email: userEmail,
    mobile: "",
    countryCode: "",
    countryId: "",
    password: "",
    confirmPassword: "",
  };

  if (isLoading) {
    return (<div className="text-center"><LoadingSpinner /></div>);
  }
  if (userExistRef) {
    return (
      <>
        <Container fluid className="hostinvitationMain">
          <div className="hostinvitationMain-inner">
            <Row>
              <Col className="text-center">
                <div className="logomaindiv">
                  <img src={Images.logoImgWhiteTheme} alt="logo" />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <div className="SignupLeftside">
                  <div className="signupformMain thanksyouinvite">
                    <h1>Thank You </h1> <span>for accepting invite!!</span>
                    <p className="subheading">We hope you LOVE our service.</p>
                  </div>
                </div>
                <div className="text-center">
                  <Link to="/">
                    Click here to Continue
                  </Link>
                </div>
              </Col>
              <Col lg={6}>
                <div className="rightsidesignup">
                  <img src={Signupright} alt="Signup Image" />
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </>
    );
  }
  return (
    <>
      <AuthLayout
        label="Sign Up"
        subLabel="Sign Up with"
        image="/images/signup.jpg"
        goBack={() => history.goBack()}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={(value) => signUpSubmit(value)}
          validationSchema={validation}
        >
          {({
            values,
            setFieldValue,
            handleSubmit,
            errors,
            touched,
            setFieldTouched,
          }) => (
            <>
              <InputFiled
                name="name"
                type="text"
                label="Name"
                placeholder="Enter you Name"
                className="form-control"
                labelClassName="text-dark"
              />
              <Row className="align-items-baseline">
                <Col lg={6}>
                  <InputFiled
                    name="email"
                    type="text"
                    label="Email"
                    className="form-control"
                    labelClassName="text-dark"
                    disabled
                  />
                </Col>
                <Col lg={6}>
                  <Form.Group className="mb-0 text-left">
                    <Form.Label className="f_size_14 ml-3 ">
                      Phone Number
                    </Form.Label>
                    <PhoneInput
                      country="in"
                      name="mobile"
                      inputClass="mobileInput bg-light"
                      enableSearch={true}
                      placeholder="Mobile No"
                      countryCodeEditable={false}
                      onChange={(value, country) => {
                        setFieldValue(
                          "mobile",
                          value.slice(country.dialCode.length)
                        );
                        setFieldValue("countryCode", country.dialCode);
                        setFieldValue("countryId", country.countryCode);
                      }}
                      inputProps={{
                        onBlur: () => setFieldTouched("mobile", true),
                      }}
                    />
                    {errors.mobile && touched.mobile && (
                      <div className="error text-danger f_size_10">
                        {errors.mobile}
                      </div>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <InputFiled
                    name="password"
                    type="password"
                    label="Password"
                    className="form-control"
                    labelClassName="text-dark"
                  />
                </Col>
                <Col lg={6}>
                  <InputFiled
                    name="confirmPassword"
                    type="password"
                    label="Confirm Password"
                    className="form-control"
                    labelClassName="text-dark"
                  />
                </Col>
              </Row>
              <Row className="align-items-center mt-3 mb-1 ">
                <Col lg={6}>
                  <Button
                    variant="primary"
                    onClick={() => handleSubmit()}
                    className="px-4"
                  >
                    Sign up
                  </Button>
                </Col>
                <Col lg={6} className="mt-2">
                </Col>
              </Row>
            </>
          )}
        </Formik>
      </AuthLayout>
    </>
  );
}

export default HostInvitation;
