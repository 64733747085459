import React, { Component } from 'react';
import { Form, Input, Modal, ModalBody } from 'reactstrap';
import { Button, } from 'react-bootstrap';
import decode from 'jwt-decode';
import { withRouter } from 'react-router-dom';
import userconfigs from '../helpers/closures';
import { connect } from 'react-redux';
import { joinActions } from '../Redux/_actions';
import { Spinner } from 'reactstrap';
import InputMask from 'react-input-mask';

import './JoinMeeting.css';

class JoinMeeting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: props.isOpen,
      isLoader: false,
      join_id: '',
      join_name: undefined,
      join_password: undefined,
      isFormSubmit: false,
      value: ""
    }
  }

  componentDidMount = () => {
    let userInfo = decode(userconfigs.getItem("jwt"));
    if (typeof userInfo.name !== "undefined") {
      this.setState({
        join_name: userInfo.name
      })
    }
  }

  toggle = () => {

    if (this.state.isLoader === false) {

      this.setState({
        modal: false
      });

      this.props.updatePopUpState();
    }

  }

  handleChange = (e) => {
    const { name, value } = e.target;

    var specials = /^[a-zA-Z0-9\-]*$/;
    if (specials.test(value)) {
      this.setState({
        [name]: value
      });
    }

  }

  successHandler = (e) => {
    e.preventDefault();
    const { join_id } = this.state;

    this.setState({
      isFormSubmit: true
    });

    if (join_id) {
      this.props.history.push('/join_meeting?mid=' + join_id);
      //this.props.joinConference(join_name,join_id)
    }
  }

  errorHandler = () => {
    this.props.errorHandler();
  }

  beforeMaskedValueChange = (newState, oldState, userInput) => {
    var { value } = newState;
    var selection = newState.selection;
    var cursorPosition = selection ? selection.start : null;

    // keep minus if entered by user
    if (value.endsWith('-') && userInput !== '-' && !this.state.value.endsWith('-')) {
      if (cursorPosition === value.length) {
        cursorPosition--;
        selection = { start: cursorPosition, end: cursorPosition };
      }
      value = value.slice(0, -1);
    }

    return {
      value,
      selection
    };
  }

  render() {
    const { loadingStatus } = this.props
    //console.log("loading",loadingStatus)
    return (
      <div>
        <Modal isOpen={this.state.modal} toggle={this.toggle} backdrop="static" className="Popup join_meeting_popup" contentClassName='br22Popup sideBar' >
          <ModalBody>
            <h3 className="txt-msg"> {this.props.message}</h3>
            <span className="sub_msg_detl89">To join an online meeting, enter the meeting ID provided by the organiser.</span>

            <Form onSubmit={this.successHandler}>
              {/*<div className={'_jform_group' + (this.state.isFormSubmit && !this.state.join_name ? ' has-error' : '')}>
                <Input className="joinName" name="join_name" value={this.state.join_name} onChange={this.handleChange} placeholder="Meeting Name " />
                {
                  this.state.isFormSubmit && !this.state.join_name &&
                  <div className="help-block j_error_msg">Join Name is required</div>
                }
              </div>*/}
              <div className={'_jform_group' + (this.state.isFormSubmit && !this.state.join_id ? ' has-error' : '')}>
                <InputMask className="joinId form-control" mask="999-9999-999" maskChar={null} placeholder="Enter Meeting ID" name="join_id" value={this.state.join_id} onChange={this.handleChange} beforeMaskedValueChange={this.beforeMaskedValueChange} />
                {/*<Input className="joinId" name="join_id" value={this.state.join_id} onChange={this.handleChange} placeholder="Meeting ID " />*/}
                {
                  this.state.isFormSubmit && !this.state.join_id &&
                  <div className="help-block j_error_msg">Join ID is required</div>
                }
              </div>
              {/*<div className={'_jform_group' + (this.state.isFormSubmit && !this.state.join_password ? ' has-error' : '')}>
                <Input className="joinPassword" autocomplete="false" name="join_pasword" value={this.state.join_password} type="password" onChange={this.handleChange} placeholder="Meeting Password " />
                {
                  this.state.isFormSubmit && !this.state.join_password &&
                  <div className="help-block j_error_msg">Join Password is required</div>
                }
              </div>*/}

              {!loadingStatus ?
                <div className="form_actn_btn69 text-center">
                  <Button className="headerBtn btn-danger" onClick={() => this.errorHandler()} >
                    Cancel
                  </Button>
                  <Button className="ml-2 headerBtn submitSubColor" type="submit" >
                    Join Now
                  </Button>
                </div>
                :
                <div className="form_actn_btn69">
                  <Button className="headerBtn btn-success" type="button" color="success" >
                    <Spinner size="sm" color="light" /> Joining...
                  </Button>
                </div>
              }


            </Form>


          </ModalBody>
        </Modal>
      </div>
    );
  }
}

function mapState(state) {
  //console.log('stateeeeeeeeeee',state)
  const { loadingStatus } = state.joinConference;
  return {
    loadingStatus
  };
}

const actionCreators = {
  joinConference: joinActions.joinConference
};

export default withRouter(connect(mapState, actionCreators)(JoinMeeting));
