import React, { Component } from 'react';
import { withTranslation } from 'react-i18next'
import "./ForgetPassword.css"

import { Link, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import decode from 'jwt-decode';
import userconfigs from '../../helpers/closures';
import { userActions } from '../../Redux/_actions';
import OtpField from '../../components/UI/OTP/OtpField';
import MD5 from "crypto-js/md5";
import { Spinner } from 'reactstrap';
import { organization } from '../../config/config'
//const crypto = require("crypto");

var interval = "";

class ForgetPassword extends Component {

  constructor(props) {
    super(props);

    this.state = {
      username: '',
      submitted: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({
      submitted: true,
    });
    const { username } = this.state;
    if (username) {
      this.props.forgetPassword(username);
    }
  }

  render() {
    const { username, submitted } = this.state;
    const { forgetLoader } = this.props;

    let userInfo = decode(userconfigs.getItem('jwt'))

    if (userInfo.loginStatus === 1) {
      return <Redirect to='/' />
    }
    else {

      return (

        <div className="wave_login_container">
          <div className="login_form_sec">
            <div className="lgn_frm_bx">
              <div className="lgn_logo">
                <Link to="/">
                  <img alt="logo" src="images/sarv-wave-logo.png" />
                </Link>
              </div>
              <div className="w_login_heading">
                <h4>Forget Password</h4>
                <p className="mb-10">Enter your registered Email/Mobile.</p>
              </div>
              <form name="form" onSubmit={this.handleSubmit}>

                <div className="_wform_group">
                  <div className={'_wform_group_prepend' + ((submitted && !username) ? ' has-error' : '')}>
                    <span className="_wform__group_text">
                      <i className="fas fa-user"></i>
                    </span>
                    <input type="text" className="_wform_form_cntrl" name="username" value={username} onChange={this.handleChange} placeholder="Email / Phone" />

                  </div>
                </div>

                {(submitted && !username) ?
                  <div className="help-block error_msg_56">Username is required</div> : ""
                }

                <div className="_grp_rw">
                  {forgetLoader ?
                    <button type="button" className="submisn_btn btn "><Spinner size="sm" /> Verifying...</button>
                    :
                    <button type="submit" className="submisn_btn btn btn-success">Verify</button>
                  }
                </div>

                {organization === "divya" ?
                  null
                  : <div className="_grp_rw ftr_tag_log">
                    Don't have an account? <Link to="/signup" className="txt_theme"> Register here</Link>
                  </div>
                }


              </form>
            </div>
          </div>
        </div>

      )
    }
  }
}

function mapState(state) {
  const { forgetLoader } = state.authentication;
  return {
    forgetLoader
  };
}

const actionCreators = {
  forgetPassword: userActions.forgetPassword,
};

export default withRouter(withTranslation()(connect(mapState, actionCreators)(ForgetPassword)));