import { webinarConstants } from "../_constants";

const initialState = {
  scheduleLoading: false,
  scheduleRedirect: false,
  scheduleId: null,
  scheduleResp: {},
  listLoading: false,
  webinarList: [],
  detailLoading: true,
  detailError: false,
  webinarDetail: null,
  fieldsLoading: true,
  fieldsError: false,
  webinarFields: null,
  registerLoading: false,
  registerResp: {},
  registerError: false,
  addParticipantLoading: false,
  isAddedParticipant: false,
  infoLoading: true,
  infoError: false,
  webinarInfo: {},
  addHostLoading: false,
  isAddedHost: false,
  addBrandLoading: false,
  updateBrand: false,
  brandLoading: false,
  brandDeatail: null,
  brandError: false,
  deleteWebinarStatus: false,
  cancelWebinarStatus: false,
  removedKey: false,
  deleteBrand: false,
  bitsLoding: true,
  webinarBits: {},
  wrWebinarBits: {},
  addingInWebinar: false,
  addedInWebinar: false,
  webinarRoomResp: {},
  joiningWebinarLoading: false,
  joiningWebinarStatus: false,
  joiningWebinarURL: null,

  removeSpeakerLoading: false,
  removeSpeakerStatus: false,
  removeSpeakerError: false,

  speakerDetailLoading: false,
  speakerDetailValue: {},
  speakerDetailError: false,

  speakerUpdateLoading: false,
  speakerUpdateStatus: false,
  speakerUpdateError: false,

  csvListLoading: false,
  csvList: [],
  csvListError: false,
};

export function webinar(state = initialState, action) {
  switch (action.type) {
    case webinarConstants.SCHEDULE_REQUEST:
      return {
        ...state,
        scheduleLoading: true,
        scheduleRedirect: false,
        scheduleResp: {},
      };
    case webinarConstants.SCHEDULE_SUCCESS:
      return {
        ...state,
        scheduleLoading: false,
        scheduleResp: action.resp,
        scheduleId: action.resp,
        scheduleRedirect: true,
        scheduleError: false,
      };
    case webinarConstants.SCHEDULE_FAILURE:
      return {
        ...state,
        scheduleLoading: false,
        scheduleRedirect: false,
        scheduleError: true,
      };
    case webinarConstants.LIST_REQUEST:
      return {
        ...state,
        webinarList: [],
        listLoading: true,
      };
    case webinarConstants.LIST_SUCCESS:
      return {
        ...state,
        listLoading: false,
        webinarList: action.resp,
      };
    case webinarConstants.LIST_FAILURE:
      return {
        ...state,
        webinarList: [],
        listLoading: false,
      };
    case webinarConstants.CSV_REQUEST:
      return {
        ...state,
        csvListLoading: true,
        csvList: [],
        csvListError: false,
      };
    case webinarConstants.CSV_SUCCESS:
      return {
        ...state,
        csvListLoading: false,
        csvList: action.resp,
      };
    case webinarConstants.CSV_FAILURE:
      return {
        ...state,
        csvListLoading: false,
        csvList: [],
        csvListError: true,
      };
    case webinarConstants.DETAIL_REQUEST:
      return {
        ...state,
        detailLoading: true,
        detailError: false,
        webinarDetail: null,
      };
    case webinarConstants.DETAIL_SUCCESS:
      return {
        ...state,
        detailLoading: false,
        webinarDetail: action.resp,
        detailError: false,
      };
    case webinarConstants.DETAIL_FAILURE:
      return {
        ...state,
        detailLoading: false,
        detailError: true,
        webinarDetail: null,
      };
    case webinarConstants.FIELDS_REQUEST:
      return {
        ...state,
        fieldsLoading: true,
        fieldsError: false,
        webinarFields: null,
      };
    case webinarConstants.FIELDS_SUCCESS:
      return {
        ...state,
        fieldsLoading: false,
        fieldsError: false,
        webinarFields: action.resp,
      };
    case webinarConstants.FIELDS_FAILURE:
      return {
        ...state,
        fieldsLoading: false,
        fieldsError: true,
        webinarFields: null,
      };
    case webinarConstants.REGISTER_REQUEST:
      return {
        ...state,
        registerLoading: true,
        registerResp: {},
        registerError: false,
      };
    case webinarConstants.REGISTER_SUCCESS:
      return {
        ...state,
        registerLoading: false,
        registerResp: action.resp,
        registerError: false,
      };
    case webinarConstants.REGISTER_FAILURE:
      return {
        ...state,
        registerLoading: false,
        registerResp: {},
        registerError: true,
      };
    case webinarConstants.ADD_PARTICIPANT_START:
      return {
        ...state,
        addParticipantLoading: true,
        isAddedParticipant: false,
      };
    case webinarConstants.ADD_PARTICIPANT_SUCCESS:
      return {
        ...state,
        addParticipantLoading: false,
        isAddedParticipant: true,
      };
    case webinarConstants.ADD_PARTICIPANT_FAILURE:
      return {
        ...state,
        addParticipantLoading: false,
        isAddedParticipant: false,
      };
    case webinarConstants.INFO_REQUEST:
      return {
        ...state,
        infoLoading: true,
        infoError: false,
        webinarInfo: {},
      };
    case webinarConstants.INFO_SUCCESS:
      return {
        ...state,
        infoLoading: false,
        webinarInfo: action.resp,
        infoError: false,
      };
    case webinarConstants.INFO_FAILURE:
      return {
        ...state,
        infoLoading: false,
        infoError: true,
        webinarInfo: {},
      };
    case webinarConstants.ADD_HOST_START:
      return {
        ...state,
        addHostLoading: true,
        isAddedHost: false,
      };
    case webinarConstants.ADD_HOST_SUCCESS:
      return {
        ...state,
        addHostLoading: false,
        isAddedHost: true,
      };
    case webinarConstants.ADD_HOST_FAILURE:
      return {
        ...state,
        addHostLoading: false,
        isAddedHost: false,
      };
    case webinarConstants.BRANDING_REQUEST:
      return {
        ...state,
        addBrandLoading: true,
        updateBrand: false,
      };
    case webinarConstants.BRANDING_SUCCESS:
      return {
        ...state,
        addBrandLoading: false,
        updateBrand: true,
      };
    case webinarConstants.BRANDING_FAILURE:
      return {
        ...state,
        addBrandLoading: false,
        updateBrand: false,
      };
    case webinarConstants.BRAND_REQUEST:
      return {
        ...state,
        brandLoading: true,
        brandError: false,
        brandDeatail: null,
      };
    case webinarConstants.BRAND_SUCCESS:
      return {
        ...state,
        brandLoading: false,
        brandError: false,
        brandDeatail: action.resp,
      };
    case webinarConstants.BRAND_FAILURE:
      return {
        ...state,
        brandLoading: false,
        brandError: true,
        brandDeatail: null,
      };
    case webinarConstants.WEBINAR_DELETE_START:
      return {
        ...state,
        loading: true,
        deleteWebinarStatus: false,
      };
    case webinarConstants.WEBINAR_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteWebinarStatus: true,
      };
    case webinarConstants.WEBINAR_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        deleteWebinarStatus: false,
      };
    case webinarConstants.WEBINAR_CANCEL_START:
      return {
        ...state,
        loading: true,
        cancelWebinarStatus: false,
      };
    case webinarConstants.WEBINAR_CANCEL_SUCCESS:
      return {
        ...state,
        loading: false,
        cancelWebinarStatus: true,
      };
    case webinarConstants.WEBINAR_CANCEL_FAILURE:
      return {
        ...state,
        loading: false,
        cancelWebinarStatus: false,
      };
    case webinarConstants.BRAND_KEY_REMOVE_START:
      return {
        ...state,
        removedKey: false,
      };
    case webinarConstants.BRAND_KEY_REMOVE_SUCCESS:
      return {
        ...state,
        removedKey: true,
      };
    case webinarConstants.BRAND_KEY_REMOVE_FAILURE:
      return {
        ...state,
        removedKey: false,
      };
    case webinarConstants.BRAND_DELETE_START:
      return {
        ...state,
        deleteBrand: false,
      };
    case webinarConstants.BRAND_DELETE_SUCCESS:
      return {
        ...state,
        deleteBrand: true,
      };
    case webinarConstants.BRAND_DELETE_FAILURE:
      return {
        ...state,
        deleteBrand: false,
      };

    case webinarConstants.GET_BITS_START:
      return {
        ...state,
        bitsLoding: true,
        webinarBits: {},
      };
    case webinarConstants.GET_BITS_SUCCESS:
      return {
        ...state,
        bitsLoding: false,
        webinarBits: action.response,
      };
    case webinarConstants.WR_GET_BITS_SUCCESS:
      return {
        ...state,
        bitsLoding: false,
        wrWebinarBits: action.response,
      };
    case webinarConstants.GET_BITS_FAILURE:
      return {
        ...state,
        bitsLoding: false,
        webinarBits: {},
      };

    case webinarConstants.JOIN_REQUEST:
      return {
        ...state,
        joiningWebinarLoading: true,
        joiningWebinarStatus: false,
        joiningWebinarURL: null,
      };
    case webinarConstants.JOIN_SUCCESS:
      return {
        ...state,
        joiningWebinarLoading: false,
        joiningWebinarStatus: true,
        joiningWebinarURL: action.resp,
      };
    case webinarConstants.JOIN_FAILURE:
      return {
        ...state,
        joiningWebinarLoading: false,
        joiningWebinarStatus: false,
        joiningWebinarURL: null,
      };

    case webinarConstants.ADD_IN_ROOM_START:
      return {
        ...state,
        addingInWebinar: true,
        webinarRoomResp: {},
        addedInWebinar: false,
      };
    case webinarConstants.ADD_IN_ROOM_SUCCESS:
      return {
        ...state,
        addingInWebinar: true,
        webinarRoomResp: action.response,
        addedInWebinar: true,
      };
    case webinarConstants.ADD_IN_ROOM_FAILURE:
      return {
        ...state,
        addingInWebinar: false,
        webinarRoomResp: {},
        addedInWebinar: false,
      };

    case webinarConstants.REMOVE_SPEAKER_START:
      return {
        ...state,
        removeSpeakerLoading: true,
        removeSpeakerStatus: false,
        removeSpeakerError: false,
      };
    case webinarConstants.REMOVE_SPEAKER_SUCCESS:
      const newWebinarDetail = state.webinarDetail?.host?.filter(data => data._id !== action.spakerID)
      return {
        ...state,
        removeSpeakerLoading: false,
        removeSpeakerStatus: true,
        removeSpeakerError: false,
        webinarDetail: newWebinarDetail
      };
    case webinarConstants.REMOVE_SPEAKER_FAILURE:
      return {
        ...state,
        removeSpeakerLoading: false,
        removeSpeakerStatus: false,
        removeSpeakerError: true,
      };

    case webinarConstants.SPEAKER_DETAIL_START:
      return {
        ...state,
        speakerDetailLoading: true,
        speakerDetailError: false,
      };
    case webinarConstants.SPEAKER_DETAIL_SUCCESS:
      return {
        ...state,
        speakerDetailLoading: false,
        speakerDetailValue: action.response,
        speakerDetailError: false,
      };
    case webinarConstants.SPEAKER_DETAIL_FAILURE:
      return {
        ...state,
        speakerDetailLoading: false,
        speakerDetailValue: {},
        speakerDetailError: true,
      };

    case webinarConstants.SPEAKER_UPDATE_START:
      return {
        ...state,
        speakerUpdateLoading: true,
        speakerUpdateStatus: false,
        speakerUpdateError: false,
      };
    case webinarConstants.SPEAKER_UPDATE_SUCCESS:
      return {
        ...state,
        speakerUpdateLoading: false,
        speakerUpdateStatus: true,
        speakerUpdateError: false,
      };
    case webinarConstants.SPEAKER_UPDATE_FAILURE:
      return {
        ...state,
        speakerUpdateLoading: false,
        speakerUpdateStatus: false,
        speakerUpdateError: true,
      };

    default:
      return state;
  }
}
