import { userConstants } from '../_constants';

//let user = JSON.parse(localStorage.getItem('user'));
const initialState = { otpMsg:"" };

export function otp(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_OTP_REQUEST:
      return {
        otpMsg: action.user
      };
    case userConstants.LOGIN_OTP_SUCCESS:
      return {
        user: action.user
      };
    case userConstants.LOGIN_OTP_FAILURE:
      return {
        error: action.error
      };
    default:
      return state
  }
}