import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import "./guide.css"
import WaitingHeader from '../WaitingRoom/waitingHeader'
import decode from 'jwt-decode';
import userconfigs from '../../../helpers/closures';

class Guide extends Component {
	
	constructor(props){
		super(props);
		this.state = {
			mid : this.props.history.location.state.mid,
			mname: this.props.history.location.state.mname
		}
	}

	componentDidMount = () => {
		let userInfo = decode(userconfigs.getItem("jwt"));
	    if(userInfo.loginStatus !== 0){
	    	this.props.history.push("/join_meeting?mid="+this.state.mid)
	    }

	}


	render() {
		//console.log(this.props)
		const { mid, mname } = this.state;
		return (
			<div className="wave_wating_sctn">
    			<WaitingHeader mname={mname} mid={mid} />
    			<div className="wave_waiting_contianer_msg">
			        <div className="wait_msg">
			           This meeting is for only registered users. 
			           <span className="text-warning mt-3">Please register yourself first or if you are already registered then first login and try again.</span>
			      	</div>  

	        		<div className="wave_wait_timer text-center">
	            		<Link className="btn btn-lg btn-primary mt-5" to='/signup'>Register</Link>
	        		</div>  
	    		</div>    
			</div>
		)
	}
}

export default Guide;