import { myconfig, siteTokens } from '../../config/config';
import axios from 'axios';
import userconfigs from '../../helpers/closures';

import { handleResponse, errorCheck, handleJWTError } from './common'

export const departmentService = {
    listDepartments,
    addDepartment,
    getCompanyUsers,
    getDepartmentByid,
    editDepartment
};

function listDepartments() {

    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };
    //myconfig.apiURL = 'http://localhost:3001/';

    return axios.get(`${myconfig.apiURL}Setting/department/listDepartments`, axiosRequestOptions)
        .then(handleResponse)
        .then(resp => {
            return resp;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();

            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}

function addDepartment(deptData) {
    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };
    //myconfig.apiURL = 'http://localhost:3001/';

    return axios.post(`${myconfig.apiURL}Setting/department/add`, deptData, axiosRequestOptions)
        .then(handleResponse)
        .then(msg => {
            return msg;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}

function editDepartment(deptData, id) {
    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };
    //myconfig.apiURL = 'http://localhost:3001/';

    return axios.post(`${myconfig.apiURL}Setting/department/edit/${id}`, deptData, axiosRequestOptions)
        .then(handleResponse)
        .then(msg => {
            return msg;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}

function getCompanyUsers() {
    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };
    //myconfig.apiURL = 'http://localhost:3001/';

    return axios.get(`${myconfig.apiURL}get_company_users`, axiosRequestOptions)
        .then(handleResponse)
        .then(resp => {
            return resp;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();

            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}

function getDepartmentByid(id) {
    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };
    //myconfig.apiURL = 'http://localhost:3001/';

    return axios.get(`${myconfig.apiURL}Setting/department/getByID/${id}`, axiosRequestOptions)
        .then(handleResponse)
        .then(resp => {
            return resp;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();

            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}

