import React, { useState } from "react";
// import { withRouter } from "react-router-dom";
// import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import Layout from "../../layoutSection";
import { userService } from "./../../Redux/_services";
import { alertActions } from "../../Redux/_actions";
import "../styles.scss";
import { useDispatch } from "react-redux";
// import { useHistory } from "react-router-dom";

const UserFeedback = (props) => {
  const [submitted, setSubmitted] = useState(false);
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({
    message: false,
    feedback_type: false,
  });
  const [formData, setFormData] = useState({
    message: "",
    feedback_type: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    formData[name] = value;
    setFormData({ ...formData });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    try {
      setSubmitted(false);

      let message = await userService.postData(
        formData,
        "feedback/addFeedback"
      );
      dispatch(alertActions.success(`${message}`));

      const updateState = {
        message: "",
        feedback_type: "",
      };
      setFormData({ ...updateState });
      return false;
    } catch (error) {
      dispatch(alertActions.error(`${error}`));
      return false;
    }
  };

  // const validation = Yup.object().shape({
  //   message: Yup.string().matches(
  //     /^[a-zA-Z0-9 ,:!.@\s]$/,
  //     "Only alphabets are allowed for this field!"
  //   ),
  // });

  return (
        <Layout title="User Feedback" active="Feedback">
          <div className="container s_wave_content_part pl-3 pr-4 baseInfo  ">
            <div className="detailsSection sideBar">
              <div className="row mlr2">
                <div className="col-lg-12 grid-margin ml-auto mr-auto form-parent">
                  <div className="custom--card">
                    <div className="card-body-black">
                      <div className="card-heading">
                        <h4 className="card-title welcomeMsg">
                          Share your Feedback
                        </h4>
                      </div>
                      <div className="mt-4 section1">
                        <form
                          className="usr-form"
                          method="post"
                          onSubmit={(e) => handleSubmit(e)}
                        >
                          <div className="form-group">
                            <div className="text-muted">Feedback Type</div>
                            <select
                              name="feedback_type"
                              id="feedback_type"
                              className="form-control inputColorTheme "
                              value={formData?.feedback_type}
                              onChange={(e) => handleChange(e)}
                            >
                              <option value="" disabled className="gray-color">
                                Select type
                              </option>
                              <option value="Design issue">Design issue</option>
                              <option value="Functional issue">
                                Functional issue
                              </option>
                              <option value="Poor Audio Quality">
                                Poor Audio Quality
                              </option>
                              <option value="Poor video Quality">
                                Poor video Quality
                              </option>
                              <option value="Other">Other</option>
                            </select>
                          </div>

                          <p className="form-group">
                            <div className="text-muted"> Message</div>
                            <textarea
                              rows="4"
                              cols="50"
                              className="form-control inputColorTheme"
                              name="message"
                              onChange={(e) => handleChange(e)}
                            />
                            <span className="error_msg_host_register">
                              {submitted && !!errors.message
                                ? "Message is required"
                                : ""}
                            </span>
                          </p>

                          <p className="form-group">
                            <Button
                              type="submit"
                              className="btnLg text-white borderRadius pl-5 pr-5"
                            >
                              Submit
                            </Button>
                          </p>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
  );
};

export default UserFeedback;
