import React, { useEffect, useState, useCallback } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { Formik, Form as FormikForm } from "formik";
import { Row, Col, Button, Form, Spinner } from "react-bootstrap";
import MD5 from "crypto-js/md5";
import decode from "jwt-decode";
// import GoogleLogin from "react-google-login";
import GoogleLogin from "../CustomHooks/GoogleLogin";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "is-empty";

import { InputFiled, MobileNumber } from "../../Common/authFile";
import { userService } from "../../Redux/_services";
import userconfigs from "../../helpers/closures";
import {
  google_config,
  passwordToBreak,
  encDataKey,
} from "../../config/config";
import {
  userSignUpActions,
  userActions,
  alertActions,
} from "../../Redux/_actions";
import AuthLayout from "../layout";
import VerifySignUp from "./verifySignup";

const crypto = require("crypto-js");

const initialValues = {
  name: "",
  email: "",
  mobile: "",
  countryCode: "",
  countryId: "",
  password: "",
  confirmPassword: "",
};

const validation = Yup.object().shape({
  name: Yup.string()
    .matches(/^[A-Za-z ]*$/, "Please enter valid name")
    .max(40)
    .required("Please enter your name!"),
  mobile: Yup.string()
    .required("Please enter the phone number!")
    .test(
      "len",
      "Phone number is incorrect!",
      (val) => isEmpty(val) || val.length === 10
    ),
  email: Yup.string()
    .email("Please enter the valid email id!")
    .required("Please enter the email id!"),
  password: Yup.string()
    .required('Please Enter your Password!')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is Required!"),
});

let userInfo = null;
try {
  userInfo = decode(userconfigs.getItem("jwt"));
} catch (err) {
  //console.log(err, "Error");
}

const SignUp = (props) => {
  const { jwt } = props
  const [csrfToken, setCsrfToken] = useState(null);
  const [isDisable, setIsDisable] = useState(false);
  const [email, setEmail] = useState("");
  const authRegistrationState = useSelector(
    (state) => state.authRegistration
  );
  const authenticationState  = useSelector( 
    (state) => state.authentication
  );
  const dispatch = useDispatch();
  const history =useHistory()

  useEffect(() => {
    userService.getCsrf().then((res) => {
      setCsrfToken(res.csrfToken);
    });
    if (
      typeof userconfigs.getItem("gld") !== "undefined" &&
      userconfigs.getItem("gld") !== "null" &&
      userconfigs.getItem("gld") !== null
    ) {
      if (userconfigs.getItem("gld") === "true") {
        setIsDisable(true);
      }
    }
  }, [props.history]);

  const signUpSubmit = (value) => {
    setEmail(value.email);
    const params = {...value}
    let md5Hash = MD5(params.password).toString();
    md5Hash = md5Hash + passwordToBreak + csrfToken;
    const key = crypto.enc.Utf8.parse(encDataKey);
    md5Hash = crypto.AES.encrypt(md5Hash, key,
      {
        mode: crypto.mode.ECB,
        padding: crypto.pad.Pkcs7,
      }).toString();

    params.password = md5Hash;
    delete params["confirmPassword"];
    dispatch(userSignUpActions.signup(params));
  };

  const responseErrorGoogle = (response) => { 
    if (response.error !== "idpiframe_initialization_failed") {
      if (response.error !== "popup_closed_by_user") {
        dispatch(alertActions.error(response.error));
      }
      userconfigs.setItem("gld", false);
    } else {
      dispatch(
        alertActions.info(
          "Please enable 3rd party cookies in your browser to continue login with google."
        )
      );
      setIsDisable(true);
      userconfigs.setItem("gld", true);
    }
  };

  const GoogleLayout = useCallback(
    () => (
      <GoogleLogin
        clientId={google_config.gClientID}
        buttonText="Sign up with Google"
        className="btn btn-default"
        onSuccess={(response) =>
          dispatch(userActions.authGoogle(response.profileObj))
        }
        onFailure={(response) => responseErrorGoogle(response)}
        cookiePolicy={"single_host_origin"}
        disabled={isDisable}
        theme={"dark"}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, isDisable]
  );

  return (
    <AuthLayout
      label="Sign Up"
      subLabel="Sign Up with"
      googleLayout={<GoogleLayout />}
      image="/images/signup.jpg"
      goBack={() => history.goBack()}
      logoIcon={jwt?.originLogo}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(value) => signUpSubmit(value)}
        validationSchema={validation}
      >
        {({ 
          value,
          setFieldValue,
          handleSubmit,
          errors,
          touched,
          setFieldTouched,
        }) => (
          <>
            <InputFiled
              name="name"
              type="text"
              label="Name"
              placeholder="Enter you Name"
              className="form-control"
              labelClassName="text-dark"
            />
            <Row className="align-items-baseline">
              <Col lg={6}>
                <InputFiled
                  name="email"
                  type="text"
                  label="Email"
                  placeholder="Enter You Email"
                  className="form-control"
                  labelClassName="text-dark"
                />
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-0 text-left">
                  <Form.Label className="f_size_14 ml-3 ">
                    Phone Number
                  </Form.Label>
                  <PhoneInput
                    country="in"
                    name="mobile"
                    inputClass="mobileInput bg-light"
                    enableSearch={true}
                    placeholder="Mobile No"
                    countryCodeEditable={false}
                    onChange={(value, country) => {
                      setFieldValue(
                        "mobile",
                        value.slice(country.dialCode.length)
                      );
                      setFieldValue("countryCode", country.dialCode);
                      setFieldValue("countryId", country.countryCode);
                    }}
                    inputProps={{
                      onBlur: () => setFieldTouched("mobile", true),
                    }}
                  />
                  {errors.mobile && touched.mobile && (
                    <div className="error text-danger f_size_10">
                      {errors.mobile}
                    </div>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <InputFiled
                  name="password"
                  type="password"
                  label="Password"
                  className="form-control"
                  labelClassName="text-dark"
                />
              </Col>
              <Col lg={6}>
                <InputFiled
                  name="confirmPassword"
                  type="password"
                  label="Confirm Password"
                  className="form-control"
                  labelClassName="text-dark"
                />
              </Col>
            </Row>
            <Row className="align-items-center mt-3 mb-1 ">
              <Col lg={6}>
                <Button
                  variant="primary"
                  onClick={() => handleSubmit()}
                  className="px-4"
                >
                  {authRegistrationState?.signupLoader || authenticationState?.authLoading ? (
                    <>
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      Loading...
                    </>
                  ) : (
                    " Sign up"
                  )}
                </Button>
              </Col>
              <Col lg={6}  className="mt-2">
                <div className="f_size_14">
                  <b className="text-muted"> Already registered? </b>
                  <Link to="/login"> Login Here </Link>
                </div>
              </Col>
            </Row>
            {!isEmpty(email) && authRegistrationState?.signedUp && !authRegistrationState.signupLoader
              && 
               <VerifySignUp
               email={email}
               isShow={authRegistrationState?.signedUp }
               goLogin={() => history.push("/login")}
             />
            }
            {authenticationState?.authSuccess && !authenticationState?.authLoading && <Redirect to="/" />}
          </>
        )}
      </Formik>
    </AuthLayout>
  );
};

export default SignUp;
