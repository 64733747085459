import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`

  body {
    background: ${({ theme }) => theme.body};
    // color: ${({ theme }) => theme.text};
    transition: all 0.15s linear;
  }

  .text-light{color:${({ theme }) => theme.text_light};}}
  .text_milky {color:${({ theme }) => theme.text_milky};}}
  .s_wave_logo{background-image:${({ theme }) => theme.hdr_logo};}
  .srv_wave_db_hdr {background: ${({ theme }) =>
    theme.headerbg}; transition: all 0.15s linear; box-shadow:${({ theme }) =>
      theme.header_shadow}; }

  .navbar-light .navbar-toggler{color:${({ theme }) => theme.text};}}
  .s_wave_menu li a {color:${({ theme }) => theme.menulinkcolor};}
  .drive-acc-nolink{ color :${({ theme }) => theme.blankAndWhite};}
  .welcomeMsg{ color :${({ theme }) => theme.blankAndWhite} !important;}
  .webinar input#startingDate, .webinar input#EndingDate { color :${({ theme }) => theme.blankAndWhite} !important;}
  .table-hover tbody tr:hover { color :${({ theme }) => theme.blankAndWhite} !important;}
  .bgTheme{ background-color : ${({ theme }) => theme.bgTheme} !important ; }

  .meet-card {
    background :${({ theme }) => theme.meetCardBg};
    border-color :${({ theme }) => theme.meetCardBroder};
  }
  .meet-list-block {
    background :${({ theme }) => theme.meetListBlock};
  }
  

  .bgThemeWhite{ background-color : ${({ theme }) =>
    theme.bgWhite} !important ;  box-shadow: ${({ theme }) =>
      theme.bgWhite === "#ffffff" ? "1px 4px 11px #c9c4c4" : ""} ;   }

  .bgThemeWhitePlan{ background-color : ${({ theme }) =>
    theme.bgWhite} !important ; }

  .inputBgTheme { background: ${({ theme }) =>
    theme.SideBar} !important ;   }

  .bottomBorder{  border-bottom: 1px solid ${({ theme }) =>
    theme.borderColor} !important;  }

  .usermanagmentlist_main .tab-content {
    border: 1px solid ${({ theme }) =>
    theme.borderColor} !important; 
  }
    
  .noteText{ color : ${({ theme }) => theme.noteText} !important}
  .listElements span{ color : ${({ theme }) => theme.blankAndWhite} !important ; font-family: "Roboto", sans-serif !important;}

  .sideBar{ background : ${({ theme }) => theme.SideBar} !important}

  .sideBarIcon{ background : ${({ theme }) => theme.SideBarIcon} !important}

  .activeSideBarDot{ border : 1px solid ${({ theme }) =>
    theme.SideBar} !important; }

  .stateFormActiveSection{ background-color :${({ theme }) =>
    theme.stepFormSectionActive} !important} }

  .stateFormSectionArrow{ border-left: 28px solid ${({ theme }) =>
    theme.SideBar} !important} }

  .stateFormActiveSectionArrow{ border-left: 28px solid ${({ theme }) =>
    theme.stepFormSectionActive} !important} }

  .shadowArrowSection{ border-left: 28px solid ${({ theme }) => theme.body}} }

  .bgColorBodyCard{  background: ${({ theme }) => theme.body};  }
  .accordionHeader{
    background-color: ${({ theme }) => theme.accordionHeader}; !important
  }

  .srv_wave_db_hdr ._user_link{color:${({ theme }) =>
    theme.menulinkcolor}!important;}
  .s_wave_menu li a:hover, .srv_wave_db_hdr ._user_link:hover{color:${({
      theme,
    }) => theme.menulinkcolor_hover}!important;}

  .user_dropdown_m{background:${({ theme }) => theme.headerbg};}
  .user_dropdown_m .dropdown-item{color:${({ theme }) => theme.menulinkcolor};}
  .user_dropdown_m .dropdown-item:hover{background:${({ theme }) =>
    theme.drop_menu_hover}; color:${({ theme }) =>
      theme.menulinkcolor}!important;}
  .user_dropdown_m .dropdown-item.active,  .user_dropdown_m .dropdown-item:active{color:${({
        theme,
      }) => theme.menulinkcoloracive};}
  .user_dropdown_m .dropdown-item a, .user_dropdown_m .dropdown-item a:hover{color:${({
        theme,
      }) => theme.menulinkcolor};}

  .bg_container_home, .login_sign_sec{background:${({ theme }) =>
    theme.homepagebg}; transition: all 0.15s linear; }
  .homepage_text_dec{color: ${({ theme }) => theme.text_90};}

  .home_logo_main{background-image:${({ theme }) => theme.hdr_logo};}

  .join_m_btn{background:${({ theme }) => theme.join_m_btnbg}; color:${({
      theme,
    }) => theme.join_m_btncolor}; border:${({ theme }) => theme.join_m_btnborder};}
  .wave_footer_strip img{opacity:${({ theme }) => theme.skyopacity};}
  .or_seprator{color:${({ theme }) => theme.text_50};}

  .login_sign_sec .wva_h_btn.login_b{background-color:${({ theme }) =>
    theme.login_btnbg}; color:${({ theme }) => theme.login_btncolor};}

  .welcome_banner{background-image:${({ theme }) => theme.homepage_banner};}

  .wave_login_container{background-color:${({ theme }) => theme.login_bg};}
  .wave_signup_container{background-color:${({ theme }) => theme.login_bg};}
  .lgn_frm_bx{box-shadow:${({ theme }) => theme.login_page_shadow};}

 // css for dashbaord page

  .s_wave_content_part{background: transparent;}

  .db_scheule_meeting_part{background:${({ theme }) =>
    theme.dashboard_box_bg_s_mtng}; box-shadow:${({ theme }) =>
      theme.db_upcoming_bx_shadow};}
  .w_recently_share_file_sec {background:${({ theme }) =>
    theme.dashboard_box_bg};}

  .s_wave_panel_hdng{color: ${({ theme }) => theme.text};}

  .dasbord_counter_bx.total_meeting{background:${({ theme }) =>
    theme.total_meeting_count_bg};}
  .dasbord_counter_bx.total_s_meeting{background:${({ theme }) =>
    theme.total_s_meeting_bg};}
  .dasbord_counter_bx.total_shared_docs{background:${({ theme }) =>
    theme.share_doc_count_bg};}
  .dasbord_counter_bx.total_conacts{background:${({ theme }) =>
    theme.total_conacts_bg};}

  .dasbord_counter_bx.total_meeting{border-color:${({ theme }) =>
    theme.t_metting_brdr};}
  .dasbord_counter_bx.total_s_meeting{border-color:${({ theme }) =>
    theme.t_sc_meeting_brdr};}
  .dasbord_counter_bx.total_shared_docs{border-color:${({ theme }) =>
    theme.t_sharddoc_brdr};}
  .dasbord_counter_bx.total_conacts{border-color:${({ theme }) =>
    theme.t_contct_brdr};}
  .dasbord_counter_col{background:${({ theme }) =>
    theme.dasbord_counter_bx_bg};}
  .dasbord_counter_col font {color: ${({ theme }) => theme.text};}

  .dasbord_counter_bx.total_meeting font{color: ${({ theme }) =>
    theme.t_metting_c};}
  .dasbord_counter_bx.total_s_meeting font{color: ${({ theme }) =>
    theme.t_sc_meeting_c};}
  .dasbord_counter_bx.total_shared_docs font{color: ${({ theme }) =>
    theme.t_sharddoc_c};}
  .dasbord_counter_bx.total_conacts font{color: ${({ theme }) =>
    theme.t_contct_c};}


  .dasbord_counter_bx font{font-family: ${({ theme }) => theme.alternate_font};}
  .dasbord_counter_bx span{font-family: ${({ theme }) => theme.alternate_font};}

  .s_wave_panel_hdng a.view_all_link{color: ${({ theme }) => theme.text_90};}

  .db_schedule_list li{background:${({ theme }) => theme.db_sh_list_bg};}
  .db_schedule_list li .meeting_title, .no_sh_meeting_available font{color: ${({
      theme,
    }) => theme.text};}
  .db_schedule_list li .meeting_time, .no_sh_meeting_available span{color: ${({
      theme,
    }) => theme.text_90};}

  .db_document_list thead tr td{color: ${({ theme }) => theme.text_90};}
  .db_document_list tbody tr td{color: ${({ theme }) =>
    theme.text}; border-top-color:${({ theme }) => theme.db_doc_td_brdr};}
  .db_document_list tbody tr td.doc_share_dtl,
  .db_document_list tbody tr td.doc_size, .db_document_list tbody tr td.doc_modified{color: ${({
      theme,
    }) => theme.text_90};}

  .wconfirmation_dilog_bx .modal-content{background:${({ theme }) =>
    theme.alert_popup_bg};}
  .wconfirmation_dilog_bx .cnfmrtn_msg{color: ${({ theme }) =>
    theme.alert_popup_text};}

  .noshareddoc{background:${({ theme }) => theme.no_share_dc_bg};}

  //css for drive page
  .s_wave_panel_hdng_drive{color:${({ theme }) => theme.text};}
  .control-section .e-toolbar .e-toolbar-items{background:${({ theme }) =>
    theme.filemanager_main_bg};}
  .control-section .e-filemanager{border-color:${({ theme }) =>
    theme.filemngr_main_brder};}
  .control-section .e-filemanager .e-toolbar{border-bottom-color:${({
      theme,
    }) => theme.filemngr_main_brder};}
  .e-toolbar .e-tbar-btn{background:${({ theme }) => theme.filemngr_btn_bg};}
  .e-toolbar .e-tbar-btn .e-icons.e-btn-icon{color: ${({ theme }) =>
    theme.text};}
  .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn-text{color: ${({
      theme,
    }) => theme.text};}

  .e-toolbar .e-hor-nav{background:${({ theme }) => theme.filemngr_btn_bg};}
  .e-toolbar .e-hor-nav:hover,   .e-toolbar .e-hor-nav:focus{background:${({
      theme,
    }) => theme.tab_btn_hvr_bg}!important; }
  .e-toolbar .e-icons{color: ${({ theme }) => theme.text};}
.e-btn, .e-css.e-btn{background:${({ theme }) =>
    theme.tab_btn_hvr_bg}!important;}

  .e-toolbar .e-tbar-btn:hover,
  .e-toolbar .e-tbar-btn:active,
  .e-toolbar .e-tbar-btn:focus{background:${({ theme }) =>
    theme.tab_btn_hvr_bg};  border-color:${({ theme }) =>
      theme.tab_btn_hvr_brdr};}

  .e-toolbar .e-tbar-btn:hover .e-icons.e-btn-icon,
  .e-toolbar .e-tbar-btn:active .e-icons.e-btn-icon,
  .e-toolbar .e-tbar-btn:focus .e-icons.e-btn-icon{color: ${({ theme }) =>
    theme.text};}

  .e-toolbar .e-tbar-btn:hover .e-icons.e-btn-icon, .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn-text,
  .e-toolbar .e-tbar-btn:active .e-icons.e-btn-icon, .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn-text{color: ${({
      theme,
    }) => theme.text};}

  .e-filemanager .e-toolbar .e-toolbar-items .e-toolbar-item .e-dropdown-btn.e-btn.e-tbar-btn:hover,
  .e-filemanager .e-toolbar .e-toolbar-items .e-toolbar-item .e-dropdown-btn.e-btn.e-tbar-btn:focus,
  .e-filemanager .e-toolbar .e-toolbar-items .e-toolbar-item .e-dropdown-btn.e-btn.e-tbar-btn:active,
  .e-filemanager .e-toolbar .e-toolbar-items .e-toolbar-item .e-dropdown-btn.e-btn.e-tbar-btn.e-active{background:${({
      theme,
    }) => theme.tab_btn_hvr_bg};  border-color:${({ theme }) =>
      theme.tab_btn_hvr_brdr}; }
  .e-filemanager .e-toolbar .e-toolbar-items .e-toolbar-item .e-dropdown-btn.e-btn.e-tbar-btn.e-active .e-btn-icon{color: ${({
        theme,
      }) => theme.text};}


  .e-navigation, .e-layout-content{background:${({ theme }) =>
    theme.bg_282330};}
  .e-splitter .e-split-bar.e-split-bar-horizontal{background:${({ theme }) =>
    theme.bg_36323c};}

  .e-treeview .e-list-text{color: ${({ theme }) => theme.text};}
  .e-filemanager .e-address .e-address-list-item:last-child .e-list-text{color: ${({
      theme,
    }) => theme.text};}
  .e-filemanager .e-address .e-icons{color: ${({ theme }) => theme.text_90};}

  .e-treeview .e-list-item.e-active > .e-fullrow:hover{box-shadow: ${({
      theme,
    }) => theme.shadw_none};}
  .e-filemanager .e-address{border-bottom-color:  ${({ theme }) =>
    theme.brdr_ffffff1f};}
  .e-grid .e-content, .e-grid .e-gridcontent, .e-grid .e-table{background: ${({
      theme,
    }) => theme.transparent_drk_mode};}
  .e-filemanager .e-grid .e-gridheader tr:first-child th{background: ${({
      theme,
    }) => theme.bg_ffffff0a};}
  .e-filemanager .e-grid .e-gridheader .e-headercell:not(.e-fe-grid-icon):hover{background: ${({
      theme,
    }) => theme.bg_ee_hvr};}
  .e-grid .e-gridheader{background: ${({ theme }) =>
    theme.bg_ffffff0a}; border-top-color: ${({ theme }) =>
      theme.brdr_dddddd26}; border-bottom-color: ${({ theme }) =>
        theme.brdr_dddddd26};}

  .e-filemanager .e-grid .e-gridheader .e-headercell .e-headertext{color:${({
          theme,
        }) => theme.e_headertext};}
  .e-grid .e-gridheader .e-icons:not(.e-icon-hide):not(.e-check):not(.e-stop){colorkey: "value", ${({
          theme,
        }) => theme.e_gridheader};}

  .e-grid.e-gridhover tr[role="row"]:not(.e-editedrow):hover .e-rowcell:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell){background: ${({
          theme,
        }) => theme.bg_00000029}; color: ${({ theme }) => theme.text};}
  .e-filemanager .e-grid .e-gridheader .e-headercell.e-fe-grid-icon .e-headercelldiv:hover{background: ${({
          theme,
        }) => theme.transparent_drk_mode};}
  .e-grid .e-rowcell:not(.e-editedbatchcell){color: ${({ theme }) =>
    theme.text_90};}
  .e-input-group:not(.e-disabled):active:not(.e-success):not(.e-warning):not(.e-error), .e-input-group.e-control-wrapper:not(.e-disabled):active:not(.e-success):not(.e-warning):not(.e-error)
      {border-color:  ${({ theme }) =>
    theme.transparent_drk_mode}; box-shadow: ${({ theme }) =>
      theme.drkmode_shadow_none};}

  .e-filemanager .e-grid td.e-active{background: ${({ theme }) =>
    theme.bg_382a972b};}

  .e-treeview .e-list-item.e-active > .e-fullrow, .e-treeview .e-list-item.e-active.e-hover > .e-fullrow{background: ${({
      theme,
    }) => theme.bg_1d1923};}
  .e-filemanager .e-address .e-address-list-item:not(:last-child) .e-list-text:hover{background: ${({
      theme,
    }) => theme.bg_282330_hvr};}
  .e-treeview .e-list-item.e-hover > .e-text-content .e-list-text{color: ${({
      theme,
    }) => theme.text}; }
  .e-treeview .e-list-item.e-hover > .e-fullrow{background: ${({ theme }) =>
    theme.bg_list_evhover}; border-color: ${({ theme }) =>
      theme.bg_list_evhover} }
  .e-filemanager .e-large-icons .e-large-icon{border-color: ${({ theme }) =>
    theme.doc_thmb_brdr};}
  .e-splitter.e-splitter-horizontal .e-pane, .e-splitter.e-splitter-vertical .e-pane{color:${({
      theme,
    }) => theme.doc_thmb_text};}
  .e-filemanager .e-large-icons .e-large-icon.e-active, .e-filemanager .e-large-icons .e-large-icon.e-active.e-hover{background:${({
      theme,
    }) => theme.doc_thmb_actv_bg};}
  .e-filemanager .e-large-icons .e-list-item.e-hover{background:${({ theme }) =>
    theme.doc_thmb_hovr_bg};}

  .e-dialog{background:${({ theme }) => theme.model_bg}; border-color:${({
      theme,
    }) => theme.model_border}; }
  .e-dialog .e-btn.e-dlg-closeicon-btn:hover span{color: ${({ theme }) =>
    theme.text_90};}
  .e-icon-dlg-close{color: ${({ theme }) => theme.modal_close_txt};}
  .e-dialog .e-dlg-header{color: ${({ theme }) => theme.model_hdr_txt};}
  .e-upload .e-upload-files .e-upload-file-list .e-file-container .e-file-name{color: ${({
      theme,
    }) => theme.text};}
  .e-upload .e-upload-files .e-upload-file-list .e-file-container .e-file-type{color: ${({
      theme,
    }) => theme.text};}
  .e-filemanager .e-dialog td{color: ${({ theme }) => theme.text};}
  .e-filemanager .e-dialog td:first-child{color: ${({ theme }) =>
    theme.text_90};}
.e-upload .e-upload-files .e-upload-file-list .e-file-container .e-progress-bar-text{color: ${({
      theme,
    }) => theme.text_90};}


  .share_file_model .modal-content{background:${({ theme }) =>
    theme.custom_modal_bg};}
  .share_file_model .modal-content .modal-title{color: ${({ theme }) =>
    theme.textmodal_heading};}
  .share_file_model .modal-content .file_sharng_sct label{color: ${({
      theme,
    }) => theme.text_90};}
  .share_file_model .modal-content .file_sharng_sct .f_share_code{border-color: ${({
      theme,
    }) => theme.share_code_brdr}; color: ${({ theme }) => theme.sharecode_text};}
  .share_file_model .modal-content .modal-header .close{color: ${({ theme }) =>
    theme.text};}



//css for create meeting dashbaord
.wave_actin_btn button, .wave_actin_btn .action-col {background: ${({
      theme,
    }) => theme.wave_actin_bg};}
.wave_actin_btn button:hover, .wave_actin_btn .action-col:hover {background: ${({
      theme,
    }) => theme.wave_actin_bg_hov};}
.bg_container_226{background: ${({ theme }) => theme.theme_bg};}
.wave_actin_btn button{color: ${({ theme }) => theme.text_90};}
.wave_actin_btn button{box-shadow:${({ theme }) => theme.meeting_btn_shadow};}
.wave_actin_btn button.b_new_confrence:hover{color: ${({ theme }) =>
    theme.new_btn_hover_clr};}
.schedule_meeting_list ul li{background:${({ theme }) =>
    theme.upcoming_meeting_bg};}
.note_list li {color:${({ theme }) => theme.note_list_txt};}
.wave_actin_btn .action-col .icon-info {background: ${({ theme }) =>
    theme.icon_info_bg}; color:${({ theme }) => theme.icon_info_bg_text};}
.wave_actin_btn .action-col .icon-info i {color:${({ theme }) =>
    theme.icon_info_bg_text};}
//css for recent share files page

.w_shared_doc_record thead tr th{color:${({ theme }) => theme.text_90};}
.w_shared_doc_record tbody tr td{color:${({ theme }) => theme.text_90};}
.w_shared_doc_record tbody tr:hover td{background:${({ theme }) =>
    theme.tr_hover_bg};}
.w_shared_doc_record thead tr th{border-color:${({ theme }) => theme.th_brdr}}
.w_shared_doc_record tbody tr td{border-color:${({ theme }) => theme.tdbrdr}}
.w_shared_doc_record tbody tr td.w_share_date i{color:${({ theme }) =>
    theme.text_999};}
.w_shared_doc_record tbody tr td.w_doc_name{color:${({ theme }) => theme.text};}


@media screen and (max-width:768px) {
table.w_shared_doc_record tbody tr{background:${({ theme }) =>
    theme.share_file_respo_td};}
table.w_shared_doc_record td::before{color:${({ theme }) => theme.text_50};}
table.w_shared_doc_record td{color:${({ theme }) => theme.text_90};}
}


// join meeting popup

.Popup .modal-content{background-color:${({ theme }) => theme.modalbg};}
.Popup .txt-msg{color:${({ theme }) => theme.text_blue_white};}
.sub_msg_detl89{color:${({ theme }) => theme.text_50};}
._jform_group .form-control{border-color:${({ theme }) =>
    theme.input_brdr_color}; color:${({ theme }) => theme.text}; font-weight:${({
      theme,
    }) => theme.f_weight_opp};}


// create and schedule meeting

.bg_container_cs_conf, .bg_container_sh_conf{background:${({ theme }) =>
    theme.theme_bg};}
.crate_campaing_sec h2{color:${({ theme }) => theme.text_black_white};}
.field_title{color:${({ theme }) => theme.create_label};}
.crate_campaing_sec .form-control{border-color:${({ theme }) =>
    theme.input_brdr_color}; color:${({ theme }) => theme.text};}
.crate_campaing_sec .sch_dths{border-color:${({ theme }) =>
    theme.input_addon_brdr};}
.tgl-light + .tgl-btn{background:${({ theme }) => theme.toggle_bg};}
.tgl-light:checked + .tgl-btn{background:${({ theme }) =>
    theme.toggle_activebg};}
.toggle_switch_option span{color:${({ theme }) => theme.toggle_label_txt};}
.cs_sh_tab_content{border-color:${({ theme }) => theme.tab_border};}
.cs_sh_tab_link .nav-item .nav-link.active{background:${({ theme }) =>
    theme.tab_nav_active_bg}; color:#fff !important; border-color:${({ theme }) =>
      theme.tab_nav_active_brdr}!important;}
.cs_sh_tab_link .nav-item .nav-link{background:${({ theme }) =>
    theme.tab_nav_bg}; color:${({ theme }) =>
      theme.text}!important; border-bottom-color:${({ theme }) =>
        theme.brdr_only_whitetheme};}
.cs_sh_tab_link .nav-item .nav-link:hover{border-color:${({ theme }) =>
    theme.tab_nav_hovr_brdr};}


// meeting list page
.wave_schedule_meeting_list ul li{background:${({ theme }) =>
    theme.meeting_list_bg};}
.wave_schedule_meeting_list ul li .meeting_title{color:${({ theme }) =>
    theme.textblck};}
.wave_schedule_meeting_list ul li .meeting_code{color:${({ theme }) =>
    theme.text_50};}
.wave_schedule_meeting_list ul li .meeting_date_time{color:${({ theme }) =>
    theme.text_90};}
.wave_schedule_meeting_list ul li.schedule_meeting_unique .meeting_date_time{color:${({
      theme,
    }) => theme.text_orange};}
.srv_welcome_msg_md {color:${({ theme }) => theme.textblck}}
// meeting detail page

.s_wave_content_part_schdule{background: transparent !important; margin-left : 60px}
.s_wave_content{background:${({ theme }) => theme.theme_bg};}
.schedule_meeting_detail{background:${({ theme }) => theme.meeting_detail_bg};}
.schedule_meeting_detail .field_title_sm{color:${({ theme }) => theme.text_90};}
.schedule_meeting_detail .sm_meeting_password{color:${({ theme }) =>
    theme.textblck}!important;}
.schedule_meeting_detail .sm_meeting_id{color:${({ theme }) => theme.text};}
.sc_invt_hdng{color:${({ theme }) => theme.text_blue_white};}
.schedule_meeting_detail hr{border-color:${({ theme }) =>
    theme.detail_page_hr};}
.waveinvite_usr_bx{background:${({ theme }) => theme.m_innerbx_bg};}
.calendar_int_btn{background:${({ theme }) => theme.calander_btn_bg};}
.schedule_meeting_detail .sm_meeting_time{color:${({ theme }) =>
    theme.metting_date_txt};}
.schedule_meeting_detail .sm_meeting_time font{color:${({ theme }) =>
    theme.metting_time_txt};}
.invite_usr_adrsd{color:${({ theme }) => theme.text};}
.share_invt_ico, .re_invt_ico, .dlt_invt_ico{color:${({ theme }) =>
    theme.text_90};}
.waveinvite_usr_bx .no_meeting_available.text-warning{color:${({ theme }) =>
    theme.text_warning}!important;}
.Sc_m_wave_s_sharing_d p{color:${({ theme }) => theme.text_90};}
.scskdj_0{background:${({ theme }) => theme.invite_url_bg}; color:${({
      theme,
    }) => theme.text};}
.scur_share_btn{background:${({ theme }) => theme.copy_btn_bg}; color:${({
      theme,
    }) => theme.copy_btn_txt};}
.wave_add_participaint_model .modal-content{background:${({ theme }) =>
    theme.alert_popup_bg};}
.wave_add_participaint_model .modal-content .add-prtcpnt_txts .form-control,
.wave_add_participaint_model .modal-content .add-prtcpnt_txts .form-control:hover,
.wave_add_participaint_model .modal-content .add-prtcpnt_txts .form-control:focus{border-color:${({
      theme,
    }) => theme.modal_input_brdr}; color:${({ theme }) => theme.text};}
.wave_add_participaint_model .modal-content .modal-header .close{color:${({
      theme,
    }) => theme.text_50};}
.sw_alrtn_dtl span{color:${({ theme }) => theme.text_90};}
.sw_alrtn_dtl font{color:${({ theme }) => theme.text};}
.swno_rcrdng_msg{color:${({ theme }) => theme.text_50};}
.wave_overview_tbl tr th{color:${({ theme }) =>
    theme.text_50}; border-color:${({ theme }) => theme.th_brdr}}
.wave_overview_tbl tr td{color:${({ theme }) => theme.text}; border-color:${({
      theme,
    }) => theme.tdbrdr}}
.rcrdng_video_box video{background:${({ theme }) => theme.recrd_video_bg}; }



// webinar list
.wave_webinar_list ul li{background:${({ theme }) => theme.meeting_list_bg};}
.wave_webinar_list ul li .meeting_title{color:${({ theme }) => theme.textblck};}
.wave_webinar_list ul li .meeting_date_time{color:${({ theme }) =>
    theme.text_90};}

.meeting-list .time {
  color:${({ theme }) =>
    theme.text_90};
}

.wave_webinar_list ul li.upcoming_webinar_unique .meeting_date_time{color:${({
      theme,
    }) => theme.text_blue_webinar};}

// webinar detail page
.webinar_detail_page{background:${({ theme }) => theme.meeting_detail_bg};}
.webinar_detail_page .field_title_sm{color:${({ theme }) => theme.text_90};}
.webinar_description{color:${({ theme }) => theme.text_90};}
.webinar_detail_page .sm_meeting_time{color:${({ theme }) =>
    theme.metting_date_txt};}
.webinar_detail_page .sm_meeting_time font{color:${({ theme }) =>
    theme.metting_time_txt};}
.webinar_detail_page .sm_meeting_id{color:${({ theme }) => theme.text};}
.webinar_detail_page hr{border-color:${({ theme }) => theme.detail_page_hr};}
.speaker_profile .speaker_name{color:${({ theme }) => theme.text};}
.speaker_profile .speaker_name font{color:${({ theme }) => theme.text_90};}
.speaker_contact_detail{color:${({ theme }) => theme.text};}
.speaker_bio{color:${({ theme }) => theme.text_90};}
.speaker_invite_list{border-color:${({ theme }) => theme.spkrlist_brdr}}
.add_host_bx .form-control{border-color:${({ theme }) =>
    theme.modal_input_brdr}; color:${({ theme }) => theme.text};}

.brand_info_form .form-group label{color:${({ theme }) => theme.text_90};}
.brand_name_edit .form-control{color:${({ theme }) =>
    theme.text}; border-color:${({ theme }) =>
      theme.brand_input_brdr}; background:${({ theme }) => theme.brand_input_bg};}
.custom_btn.btn-danger{background:${({ theme }) => theme.custom_danger_bg};}
.custom_btn.btn-primary{background:${({ theme }) => theme.custom_primary_bg};}
.custom_btn.btn-warning{background:${({ theme }) => theme.custom_warning_bg};}
.wave_add_participaint_model .modal-title{color:${({ theme }) =>
    theme.text_l_orange_d_blue};}}

.add_host_bx .react-tel-input{border-color:${({ theme }) =>
    theme.modal_input_brdr};}
.add_host_bx .react-tel-input .phone-input{color:${({ theme }) =>
    theme.text}!important;}
.host_block_sec hr{border-color:${({ theme }) => theme.hst_blck_sprtr};}
.add_host_bx .react-tel-input .selected-flag:hover, .dtl_input45 .react-tel-input .selected-flag:focus{background:${({
      theme,
    }) => theme.contrycode_flg_bg}!important;}
.add_host_bx .react-tel-input .flag-dropdown.open .selected-flag{background:${({
      theme,
    }) => theme.contrycode_flg_bg}!important;}
.add_host_bx .react-tel-input .country-list .search{background:${({ theme }) =>
    theme.contrycode_srch_bg};}
.add_host_bx .react-tel-input .country-list{background:${({ theme }) =>
    theme.contrycode_flg_bg};}
.add_host_bx .react-tel-input .country-list .country-name{color:${({ theme }) =>
    theme.text}}
.add_host_bx .react-tel-input .country-list .country:hover{background:${({
      theme,
    }) => theme.contrycode_listhover_bg}}

.invtregistration_status .esattendi_name, .customDatePicker .css-1uccc91-singleValue{color:${({ theme }) => theme.text};}
.customModal .modal-content .form-control, .customDatePicker .css-yk16xz-control , {background:${({ theme }) => theme.form_control_theme}; border-color: ${({ theme }) => theme.form_control_border}; color:${({ theme }) => theme.text};}
.inputColorTheme {background:${({ theme }) => theme.form_control_theme} !important ; border-color: ${({ theme }) => theme.form_control_border} !important; color:${({ theme }) => theme.text} !important;} 
.timezone_input  .inputColorTheme div   {background:${({ theme }) => theme.form_control_theme}; border-color: ${({ theme }) => theme.form_control_border}; color:${({ theme }) => theme.text};}
.colorOffDark {background:${({ theme }) => theme.colorOffDark}; }
.webinar .DateRangePickerInput, .webinar .DateInput, .webinar .DateInput_input{background:${({ theme }) => theme.SideBar};}
.webinar .DateRangePickerInput__withBorder {
  border-color:${({ theme }) => theme.SideBar};
}

.layoutContainer  .form-control:disabled{
  background-color: ${({ theme }) => theme.disabled};
}

.layoutContainer  .form-control:focus {
  background-color: ${({ theme }) => theme.disabled};
  color: ${({ theme }) => theme.blankAndWhite} !important;
  box-shadow: none;
}


// schedule webinar
.wavewebinar_title {color:${({ theme }) => theme.text_black_white};}
.schedule_webinar h2{color:${({ theme }) => theme.text_black_white};}
.webinar_field_title{color:${({ theme }) => theme.create_label};}
.schedule_webinar .form-control{border-color:${({ theme }) =>
    theme.input_brdr_color}; color:${({ theme }) => theme.text};}
.schedule_webinar .sch_dths{border-color:${({ theme }) =>
    theme.input_addon_brdr};}
.timezone_input .css-yk16xz-control, .timezone_input .css-yk16xz-control:hover{border-color:${({
      theme,
    }) => theme.input_brdr_color}; color:${({ theme }) => theme.text};}
.timezone_input .css-yk16xz-control .css-1uccc91-singleValue{color:${({
      theme,
    }) => theme.text};}
.timezone_input  .css-1pahdxg-control .css-1uccc91-singleValue{color:${({
      theme,
    }) => theme.text};}
.tzDropdown > .css-26l3qy-menu {color:${({ theme }) =>
    theme.timezone_option_text}; background:${({ theme }) =>
      theme.timezone_option_bg}!important;}
.tzDropdown .css-26l3qy-menu {color:${({ theme }) =>
    theme.text}; background:${({ theme }) => theme.timezone_option_bg}!important;}

// start meeting page
.dark_bg_sec{background:${({ theme }) => theme.theme_bg};}
.wave_jion_actn .ast_title{ color:${({ theme }) => theme.text};}
.start_meeting_invite_t{color:${({ theme }) => theme.text_50};}

.s_waveinvite_usr_bx{background:${({ theme }) => theme.m_innerbx_bg};}
.s_invite_usr_adrsd{color:${({ theme }) => theme.text};}
.sm_share_invt_ico{color:${({ theme }) => theme.text_90}}
.wave_numbr_56.wave_numbr_56 font{color:${({ theme }) => theme.text};}
.wave_s_sharing_d p{color:${({ theme }) => theme.text_90}}
.wave_jion_code span.skdj_0{background:${({ theme }) =>
    theme.invite_url_bg}; color:${({ theme }) => theme.text};}
.s_waveinvite_usr_bx .no_meeting_available {color:${({ theme }) =>
    theme.text_50}}
.wave_jion_code .ur_share_btn{background:${({ theme }) =>
    theme.copy_btn_bg}; color:${({ theme }) => theme.copy_btn_txt};}


// profile page
.middle_6h_bx{background:${({ theme }) => theme.profile_bx_bg}; }
.profile_udeatil ul li label{color:${({ theme }) => theme.text_90}}
.profile_udeatil ul li .user_de_1{color:${({ theme }) => theme.text}}
.profile_udeatil ul li .user_de_1 .show_only45{color:${({ theme }) =>
    theme.text}}
.middle_6h_bx .text_milky{color:${({ theme }) => theme.ac_holdr_name}}
.profile_udeatil ul li .dtl_input45 input{background:${({ theme }) =>
    theme.edit_profile_form_bg}; border-color:${({ theme }) =>
      theme.edit_profile_form_brdr}; color:${({ theme }) => theme.text}}
.dtl_input45 .react-tel-input{background:${({ theme }) =>
    theme.edit_profile_form_bg}; border-color:${({ theme }) =>
      theme.edit_profile_form_brdr}; }
.dtl_input45 .react-tel-input .phone-input{color:${({ theme }) =>
    theme.text}!important}
.dtl_input45 .react-tel-input .selected-flag:hover, .dtl_input45 .react-tel-input .selected-flag:focus{background:${({
      theme,
    }) => theme.contrycode_flg_bg}!important;}
.dtl_input45.react-tel-input .flag-dropdown.open .selected-flag{background:${({
      theme,
    }) => theme.contrycode_flg_bg}!important;}
.dtl_input45 .react-tel-input .country-list .search{background:${({ theme }) =>
    theme.contrycode_srch_bg};}
.dtl_input45 .react-tel-input .country-list{background:${({ theme }) =>
    theme.contrycode_flg_bg};}
.dtl_input45 .react-tel-input .country-list .country-name{color:${({ theme }) =>
    theme.text}}
.dtl_input45 .react-tel-input .country-list .country:hover{background:${({
      theme,
    }) => theme.contrycode_listhover_bg}}


// waiting room

.wave_wating_sctn{background:${({ theme }) => theme.theme_bg};}
.meeting_dtl_78 span font{color:${({ theme }) => theme.text}}
.waiting_participiant_list h3, .prtcpnt_chat_block h3, .chat_block h3{color:${({
      theme,
    }) => theme.text}}
.waiting_participiant_list, .prtcpnt_chat_block, .chat_block{background:${({
      theme,
    }) => theme.waiting_room_block_bg};}
.prtcpnt_chat_block .chat_type_bx{background:${({ theme }) =>
    theme.dashboard_box_bg_s_mtng}; }
.prtcpnt_chat_block .type_input{color:${({ theme }) => theme.text_90}}
.wave_audioPlayer label{color:${({ theme }) => theme.text_90}}
.prtcpnt_chat_block ul li .chat_text, .chat_block ul li .chat_text{background:${({
      theme,
    }) => theme.msg_prtcpnt_bg}; color:${({ theme }) => theme.msg_prtcpnt_color}}

.wave_wating_sctn{background:${({ theme }) => theme.theme_bg};}
.waiging_msgh, .wait_msg{color:${({ theme }) => theme.text}}
.waiting_participiant_list ul li .prtcpnt_name{color:${({ theme }) =>
    theme.text}}



// join meeting page

.sarvwave_thme_bg{background:${({ theme }) => theme.theme_bg};}
.sarvwave_thme_bg .middle_box_area .wave_logo_part a{background-image:${({
      theme,
    }) => theme.hdr_logo};}
.attendy_name_bx{background:${({ theme }) => theme.dashboard_box_bg};}
.wxr_title{color:${({ theme }) => theme.text}}
.wxr_title span{color:${({ theme }) => theme.text_90}}
.wave_display_form .form-control{border-color:${({ theme }) =>
    theme.input_brdr_color}; color:${({ theme }) => theme.text}; font-weight:${({
      theme,
    }) => theme.f_weight_opp};}




// webinar room page
.chat_sec .chat_type_area .btn-warning{color: ${({ theme }) =>
    theme.text_active_webinartab}!important}
.chat_sec ul li .sender_detail{color:${({ theme }) => theme.text_50}}
.chat_sec .chat_type_bx{ background:${({ theme }) =>
    theme.webinar_chat_type_bg}!important; border-color:${({ theme }) =>
      theme.webinar_pg_brdr};}
.chat_sec .type_input{color:${({ theme }) => theme.text_90};}
.chat_sec ul li .chat_text{background:${({ theme }) => theme.chat_bubble_bg}}
.chat_sec ul li .chat_text::after{border-color:transparent transparent ${({
        theme,
      }) => theme.chat_bubble_bg}} transparent}
.chat_sec ul li .chat_text{color:${({ theme }) => theme.text_90};}

.question_list .chat_type_bx{ background:${({ theme }) =>
    theme.webinar_chat_type_bg}!important; border-color:${({ theme }) =>
      theme.webinar_pg_brdr};}
.question_list .type_input{color:${({ theme }) => theme.text_90};}
.question_block{background:${({ theme }) => theme.chat_bubble_bg}; color: ${({
        theme,
      }) => theme.question_text_color}}
.question_action_block .btn-primary{color:${({ theme }) => theme.text_90};}
.question_block .answer_text_bx .form-control{background:${({ theme }) =>
    theme.answer_bx_bg}; color:${({ theme }) => theme.text};}
.question_block .answer_text{color:${({ theme }) => theme.text};}
.question_action_block .dropdown-menu{background:${({ theme }) =>
    theme.headerbg};}
.question_action_block .dropdown-menu .dropdown-item{color:${({ theme }) =>
    theme.text_90};}
.question_action_block .dropdown-menu .dropdown-item:hover{color:${({
      theme,
    }) => theme.text};}

.text_d_white{color:${({ theme }) => theme.text_90}}
.webinar_user_detail_p{color:${({ theme }) => theme.text_90}}
.webinar_user_type{color:${({ theme }) => theme.text}}
.webinar_bg{background:${({ theme }) => theme.webinarbg}!important;}

.webinar_right_part{background:${({ theme }) =>
    theme.homepagebg}; border-color:${({ theme }) => theme.webinar_pg_brdr};}
.webinar_right_part .tab_section{border-color:${({ theme }) =>
    theme.webinar_pg_brdr}}
.tab_section .nav-tabs li a{color:${({ theme }) => theme.text_90}}
.tab_section .nav-tabs li a:hover{color:${({ theme }) => theme.text}}
.tab_section .nav-tabs li a.active{   color: ${({ theme }) =>
    theme.text_active_webinartab}}



.host_list ul li .host_position{color:${({ theme }) => theme.text}}
.host_list ul li .host_description{color:${({ theme }) => theme.text_90}}
.host_list ul li{background:${({ theme }) => theme.speaker_bg};}
.attendee_search_bx input[type="text"]{color:${({ theme }) => theme.text};  }
.attendee_search_bx{background:${({ theme }) => theme.attendee_srch_bg};}
.attendee_list ul li .attende_outer .attendee_action_btn .dropdown-menu{background:${({
      theme,
    }) => theme.headerbg};}
.attendee_list ul li .attende_outer .attendee_action_btn .dropdown-menu .dropdown-item{color:${({
      theme,
    }) => theme.text_90};}
.attendee_list ul li .attende_outer .attendee_action_btn .dropdown-menu .dropdown-item:hover{color:${({
      theme,
    }) => theme.text};}

// View Multiple Recordings Modal

.viewRecordingModal .recording-col{background-color:${({ theme }) =>
    theme.recording_col_bg};}
.viewRecordingModal .recording-col .title-name{color:${({ theme }) =>
    theme.text}}
.viewRecordingModal .recording-col .icon-video {color:${({ theme }) =>
    theme.recording_icon_bg}}
.no_data_found_webinar{{background:${({ theme }) => theme.nodatabgwebinar};}

.react-datetimerange-picker__wrapper: ${({ theme }) => theme.text};}
.react-datetimerange-picker__wrapper{ background : ${({ theme }) => theme.SideBar} }
.react-datetimerange-picker__inputGroup__input { color :${({ theme }) => theme.blankAndWhite} !important;}
.react-datetimerange-picker__wrapper { color :${({ theme }) => theme.blankAndWhite} !important;}
.react-datetimerange-picker__button svg { stroke :${({ theme }) => theme.blankAndWhite} !important;}

.btn-primary-outline {
  background: rgba(${({ theme }) => theme.btnPrim}, .1);
  border: 1px solid rgba(${({ theme }) => theme.btnPrim}, 0.4);
  color: rgba(${({ theme }) => theme.btnPrim}, 1);
}
.btn-primary-outline:hover,
.btn-primary-outline:visited,
.btn-primary-outline:focus,
.btn-primary-outline:focus-visible,
.btn-primary-outline:active {
  background: rgba(${({ theme }) => theme.btnPrim}, .2);
  border: 1px solid rgba(${({ theme }) => theme.btnPrim}, 0.4);
  color: rgba(${({ theme }) => theme.btnPrim}, 1);
}
.btn-primary-outline-white {
  background: rgba(${({ theme }) => theme.btnPrim}, 0);
  border: 1px solid rgba(${({ theme }) => theme.btnPrim}, 0.4);
  color: rgba(${({ theme }) => theme.btnPrim}, 1);
}
.btn-primary-outline-white:hover,
.btn-primary-outline-white:visited,
.btn-primary-outline-white:focus,
.btn-primary-outline-white:focus-visible,
.btn-primary-outline-white:active {
  background: rgba(${({ theme }) => theme.btnPrim}, 0);
  border: 1px solid rgba(${({ theme }) => theme.btnPrim}, 0.4);
  color: rgba(${({ theme }) => theme.btnPrim}, 1);
}
.text-meet-id {
  color: ${({ theme }) => theme.textMeetId};
}
.text-bdr-btn {
  color: ${({ theme }) => theme.textBdrBtn};
}
.text-bdr-btn:before {
  border-color: ${({ theme }) => theme.textBdrBtn};
}

.sideContainer.sideBar, .sideContainer .bottomSection {
  background: ${({ theme }) => theme.meetListBlock}!important;
}
.sideContainer {
  border-right: 1px solid ${({ theme }) => theme.sidebarBorder};
}
.sideBarBtnContainer, .logo-btn {
  border-bottom: 1px solid ${({ theme }) => theme.sidebarBorder};
}
.thumb-btn-icon {
  background: ${({ theme }) => theme.smallCardBg};
}
.sideContainer span.welcomeMsg {
  color: ${({ theme }) => theme.textPrimary}!important;
  font-weight: 500;
}



`;
