import { myconfig, siteTokens } from '../../config/config';
import axios from 'axios';
import userconfigs from '../../helpers/closures';

import { errorCheck, handleResponse } from './common'

export const homeService = {
    getHomeInfo,
};


function getHomeInfo() {
    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': userconfigs.getItem('jwt'),
        }
    };
    //myconfig.apiURL = 'http://localhost:3001/';

    return axios.get(`${myconfig.apiURL}getHomeInfo`, axiosRequestOptions)
        .then(handleResponse)
        .then(resp => {
            return resp;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();

            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}


function handleJWTError() {
    siteTokens.map(site => {
        const currentHostName = window.location.hostname;
        if (currentHostName === site.url) {
            // userconfigs.removeItem("jwt");
            // userconfigs.removeItem(site.token);
            //console.log(site.token)
            window.location.reload();
        }
        return true;
    })
}
