import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Spinner } from 'reactstrap';
import { webinarRoomActions } from '../../../../Redux/_actions';
import LoadingSpinner from '../../../../components/UI/Spinner/Spinner';
import Avatar from 'react-avatar';
import {getTimeGap} from '../../../../helpers/helpers';
import {SSE} from '../../../../helpers/sse'
import userconfigs from '../../../../helpers/closures'
import {getServerURL} from '../../../../helpers/helpers';
const currentURL = getServerURL();
// let SSE = require("./sse/sse");

let chatInterval = null;
let isChatRuning = false;
let source = null;
class Chat extends Component {

  constructor(props){
    super(props);

    this.state = {
      activeTab : this.props.activeTab,
      webinarInfo:this.props.webinarInfo,
      webinarID : this.props.webinarID,
      token : this.props.token,
      showLoader : true,
      messageValue : "",
      isRuning : false,
      chatListArr : [],
      isScrollDown : true
    }
  }

  componentDidMount=()=>{
    if(this.props.activeTab === '0'){
      this.executeSSEProcess(this.props.webinarID)
    }
  }

  componentDidUpdate(prevProps, prevState){
      const webinarID = this.state.webinarID;

      if(prevProps.webinarInfo !== this.props.webinarInfo){
        this.setState({
          webinarInfo:this.props.webinarInfo,
          webinarID:this.props.webinarID,
          token:this.props.token
        })
      }


      if(prevProps.checkList !== this.props.checkList){
        this.setState({'showLoader' : false});
      }

      if(prevProps.submitChat !== this.props.submitChat && this.props.submitChat){
         this.setState({"messageValue" : ""});
         this.props.chatListFun(webinarID)
      }

      if(prevProps.checkList !== this.props.checkList && (this.props.checkList).length > 0){
          setTimeout(function(){
               var objDiv = document.getElementById("chatList");
               objDiv.scrollTop = objDiv.scrollHeight;

               
           }, 300);
      }

      if(prevProps.webinarID !== this.props.webinarID){
        this.props.chatListFun(this.props.webinarID)
      }

      if(prevProps.activeTab !== this.props.activeTab){

       
    
        
        if(this.props.activeTab==="3"){
          if(isChatRuning === false){
            isChatRuning = true;



            //this.props.chatListFun(this.props.webinarID)
            //this.callChatAPI(this.props.webinarID)

            // chatInterval = setInterval(()=>{
            //     this.props.chatListFun(this.props.webinarID)
            // }, 5000) 

            //source.addEventListener('status', function(e){ console.log('eeeeeeeee',e) });
   
            this.executeSSEProcess(this.props.webinarID);

          }
        }else
        {

          if(source!==null){
            source.close();
          }
          

          isChatRuning = false;
          clearInterval(chatInterval)
        
        }
      }

  }

  executeSSEProcess = (wid) =>{
      
      let self = this;
      let payload = [];
      
      self.setState({'chatListArr' : [],'showLoader' : true});

      let url = `${currentURL.apiURL}webinar/listWebinarChatsPost`;
      let requestJson = { "wid" : wid };

      source = new SSE(url, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
          },
        payload: JSON.stringify(requestJson),
        method: 'POST'
      });

      source.addEventListener('message', function(e) {
        self.setState({'showLoader' : false});
        payload = JSON.parse(e.data);
        if(payload.length > 0){
          let chatListOldArr = self.state.chatListArr;
          let updateChatListObj = [...chatListOldArr, ...payload];
          self.setState({'chatListArr' : updateChatListObj});
          if(self.state.isScrollDown){
            var objDiv = document.getElementById("chatList");
            objDiv.scrollTop = objDiv.scrollHeight;
            self.setState({"isScrollDown":true})
          }
        }
      });
      source.stream();

  }


  componentWillUnmount = () =>{
    isChatRuning = false;
    if(source!==null){
      source.close();
    }
  }

  changeHandler = (event) => {
    event.preventDefault();
      const filedName = event.target.name;
      let fieldValue = event.target.value || "";

      if(filedName==="message"){
          this.setState({"messageValue" : fieldValue});
      }

  }

  submitAttendeeChat = (event) => {
    event.preventDefault();
    let webinarID = this.state.webinarID;
    let token = this.state.token;
    let getMessage = this.state.messageValue;
    getMessage = getMessage.trim();

    if(getMessage && getMessage!==""){
          this.setState({"messageValue" : true});
          let formData = {};
          formData.wid = webinarID;
          formData.msgType = 0;
          formData.msgVal = getMessage.trim();
          formData.token = token;
          this.props.submitChatFun(formData);
    }


  }

  render(){

    const { submitChatLoading } = this.props;
    const {token, showLoader, messageValue, webinarInfo, chatListArr} = this.state;

    let userType = webinarInfo.user_type || "attendee";
    let isUserActive = webinarInfo.allowed || 0;

    let chatData = null;
    if(showLoader){
      return(<LoadingSpinner />)
    }else
    if(chatListArr.length > 0)
    {
    
    chatData = chatListArr.map((usersChat,key) => {


        let senderName = (token===usersChat.senderId) ? "You" : usersChat.senderName || "User";
        let message = usersChat.value;
        let created = usersChat.created;

        let avatarIcon = (<Avatar name={senderName} size="25" round="50px" textSizeRatio={2}  />);
        if(usersChat.senderProfilePic){
         // let gravatarImage = "http://www.gravatar.com/avatar/"+mdValue+"?d=404";
          avatarIcon = (<Avatar src={usersChat.senderProfilePic} name={senderName} size="25" round="50px"   />)
        }

        return (
              <li key={key}>
                  <div className="sender_detail">
                    <span>{avatarIcon}</span>  {senderName}
                  </div>
                  <div className="chat_text">
                     {message}
                  </div>
                   <span className="text-secondary f_size_11 pt-1 pr-2 d-block text-right">{getTimeGap(created)}</span>
              </li>
        );
    });

    }else{
      chatData = <div className="no_data_found_webinar">No Message</div>;
    }
    return(
      <div className="tab-pane " id="chat_tb" role="tabpanel" aria-labelledby="chat-tab"> 
                
                 <div className="chat_sec">
                      <ul id="chatList" className="scrollbar">
                          {chatData}
                      </ul>

                      <div className="chat_type_area">
                        <div className="chat_type_bx">

                        { (userType==="attendee" && isUserActive===1) || userType==="speaker" || userType==="moderator" ?
                          <form>
                          <input type="text" name="message" className="type_input" placeholder="Type a message..." value={messageValue} onChange={(event) => this.changeHandler(event)}/>
                          { messageValue ?
                            <button className="btn btn-link text-warning" type="submit" onClick={this.submitAttendeeChat.bind(this)}><i className="far fa-paper-plane"></i></button> :
                            submitChatLoading ? 
                            <button className="btn btn-link text-warning" disabled><Spinner size="sm" color="light" /></button> :
                            <button className="btn btn-link text-warning" disabled><i className="far fa-paper-plane"></i></button>
                           }
                          </form> : <div className="block_message"> You are block by moderator </div> }

                        </div>
                      </div>

                  </div>
                 
             </div>
    )

    

  }

};


const stateToProps = (state) => {
  return {
    submitChat : state.webinarRoom.submitChat,
    submitChatLoading : state.webinarRoom.submitChatLoading,
    checkList : state.webinarRoom.checkList,
    checkListLoading : state.webinarRoom.checkListLoading
  }
}

const actionCreator = {
  submitChatFun : webinarRoomActions.submitChat,
  chatListFun : webinarRoomActions.chatList
}

export default withRouter(connect(stateToProps, actionCreator)(Chat));