import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import { webinarRoomActions, webinarActions, conferenceActions } from '../../../Redux/_actions';


class LeftPanel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			webinarInfo: this.props.webinarInfo,
			webinarID: this.props.webinarID,
			token: this.props.token
		}
	}

	handRiseClick = (handRaiseBit) => {
		const { webinarID, token } = this.state;
		this.props.handRaiseFun(webinarID, token, handRaiseBit)
	}

	componentDidUpdate = (prevProps, prevState) => {

		// const { webinarID, token } = this.state;

		if (prevProps.webinarInfo !== this.props.webinarInfo) {

			this.setState({
				webinarInfo: this.props.webinarInfo,
				webinarID: this.props.webinarID,
				token: this.props.token
			})
		}
		if (prevProps.handRaiseStatus !== this.props.handRaiseStatus) {
			let webinarInfo = this.state.webinarInfo;
			webinarInfo.handRaise = this.props.handRaiseStatus
		}

		// if(this.props.detailInfo.user_type === 'attendee' && prevProps.joiningWebinarStatus !== this.props.joiningWebinarStatus){
		// 	this.props.changeAllowBitAttendeeFun(webinarID, token)
		// }
	}

	startMeetingFun = () => {
		let webinarID = this.state.webinarID;
		this.props.startMeetingFun(webinarID, "webinar");
	}

	joinMeetingAction = () => {

		let webinarID = this.state.webinarID;
		let token = this.state.token;
		let webinarInfo = this.state.webinarInfo;
		let userType = webinarInfo.user_type || "attendee";
		let name = webinarInfo.name || "User";

		this.props.joinWebinarFun(webinarID, name, userType, token);

	}

	render() {
		const { webinarInfo } = this.state;
		const { startMeetingLoading, joiningWebinarLoading } = this.props;


		let userType = webinarInfo.user_type || "attendee";
		// let userName = webinarInfo.name || "User";
		let isMeetRunning = webinarInfo.isMeetRunning || false;

		return (
			<div className="d-inline-block pl-3 pr-3 pb-3 w-100">
				<div className="row webinar_detail_mobile_view">
					<div className="col-md-12">
						<div className="webinar_title">
							{webinarInfo.meetingName}
						</div>

						<div className="d-inline-block float-right f_size_14 text_d_white">
							<span className="ml-2"> <i className="fas fa-users mr-1"></i>  {webinarInfo.attendeeCount !== undefined ? webinarInfo.attendeeCount : <Spinner size="sm" />} </span>
							<span className="ml-4"> <i className="fas fa-chalkboard-teacher mr-1"></i> {webinarInfo.speakerCount !== undefined ? webinarInfo.speakerCount : <Spinner size="sm" />}</span>
						</div>
					</div>
					<div className="col-md-12">
						<div className="d-block float-right mob-start-webinar">
							{userType === "moderator" && isMeetRunning === false ?
								startMeetingLoading ? <button className="btn btn-xs f_size_12 btn-primary"><Spinner size="sm" /> Starting Webinar</button> :
									<button className="btn btn-xs f_size_12 btn-primary" onClick={this.startMeetingFun}><i className="fas fa-chalkboard-teacher"></i> Start Webinar</button> :
								(userType === "speaker" && isMeetRunning === true) || userType === "moderator" ?
									joiningWebinarLoading ? <button className="btn btn-xs f_size_12 webinar_join_btn"><Spinner size="sm" /> Joining Webinar</button> :
										<button className="btn btn-xs f_size_12 webinar_join_btn" onClick={this.joinMeetingAction}><i className="fas fa-sign-in-alt"></i> Join Webinar</button> : null
							}
						</div>
					</div>

				</div>


				<div className="webinar_banner_sec">

					{webinarInfo.isMeetRunning === true ?
						webinarInfo.isModerator ?
							<div className="mt-5 mob-mt-2">
								<div className="d-block text-center webinar_mod_waiting_banner">
									<img alt="waiting_image" src="images/web-mod.png" />
								</div>
								<h3 className="webinar_mod_waiting_text mt-5">Thank You For <span className="txt-perpal">Joining Moderator</span>,  Your meeting is running, <br /> you can <span className="txt-yellow">control your webinar activity</span> from this room.</h3>
							</div>
							:
							null
						:
						<div className="mt-5 mob-mt-2">
							<div className="d-block text-center webinar_waiting_banner">
								<img alt="waiting_image" src="images/sarv_Wave_waiting.png" />
							</div>
							<h3 className="webinar_waiting_text mt-5">Thank You For Joining, <br /> Please Wait, The Webinar Will Begin Shortly</h3>
						</div>
					}

				</div>

			</div>

		)

	}

};
function mapState(state) {
	const { detailInfo, handRaiseStatus, handRaiseLoading, joiningWebinarLoading, joiningWebinarStatus, startMeetingLoading } = state.webinarRoom
	return {
		detailInfo,
		handRaiseStatus,
		handRaiseLoading,
		joiningWebinarLoading,
		joiningWebinarStatus,
		startMeetingLoading
	};
}
const actionCreators = {
	handRaiseFun: webinarRoomActions.handRaise,
	joinWebinarFun: webinarActions.joinWebinar,
	startMeetingFun: conferenceActions.startMeeting,
	changeAllowBitAttendeeFun: webinarRoomActions.changeAllowBitAttendee,
};

export default withRouter(connect(mapState, actionCreators)(LeftPanel));