import React, { Component } from 'react';
import { Link } from 'react-router-dom';
//import { Spinner } from 'reactstrap';
import "./WaitForModerator/waitForModerator.css"
//import Header from '../../../Header/Header';
import userconfigs from '../../../helpers/closures';
import decode from 'jwt-decode';

import Images from '../../../images';
import jwt_decode from "jwt-decode";
import { connect } from "react-redux";

var jwt = {}

try {
	jwt = jwt_decode(userconfigs.getItem("jwt"))
} catch (e) {

}


class WaitingHeader extends Component {

	constructor(props) {
		super(props);
		this.state = {
			mid: this.props.mid,
			mname: this.props.mname,
			isLoggedIn: false
		}
	}

	componentDidMount = () => {
		if (typeof userconfigs.getItem("jwt") !== "undefined" && userconfigs.getItem("jwt") !== null) {
			let userInfo = decode(userconfigs.getItem('jwt'))
			if (userInfo.loginStatus === 1) {
				this.setState({
					isLoggedIn: true
				})
			}
		}
	}

	render() {
		const { mid, mname, isLoggedIn } = this.state;
		return (
			<div className="row">
				<div className="col-sm-4">
					<div className="wave_logo_part">
						<Link to="/">
							<img src={this.props?.conferenceBits?.logo || Images.logoImgBlackTheme} alt="Sarv Wave" />
						</Link>
					</div>
				</div>
				<div className={"col-sm-8"}>
					<div className={"meeting_dtl_78 text-right"}>
						<span className="wr_meeting_name"><font>Meeting Name : </font>{mname}</span>
						<span className="wr_meeting_id"><font> Meeting ID :</font> {mid}</span>
					</div>
					{this.props?.conferenceBits?.logo && <div className=" f_size_11 text-right mt-3">
						Proudly Powered By
						<img
							src={Images.logoImgWhiteTheme}
							alt="logo"
							width={120}
						/>
					</div>}
				</div>

			</div>
		)
	}
}

function mapState(state) {
	const { conferenceBits, error, confLoading } = state.conference;
	return {
		conferenceBits,
	};
}
const actionCreators = {
};

export default connect(mapState, actionCreators)(WaitingHeader);
