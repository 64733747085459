import { conferenceConstants } from '../_constants';

const initialState = {
  loading: false,
  error: false,
  meetingId: null,
  redirectLink: null,
  meetingLoading: false,
  conferenceInfo: null,
  conferenceBits: null,
  confLoading: true,
  meetingRedirect: null,
  inviteLoading: false,
  meetingDetail: null,
  startMeetingLoading: false,
  reinviteLoading: false,
  addParticipantLoading: false,
  isCloseModel: false,

  allowConferenceLoading: false,
  allowConferenceStatus: false,
  allowConferenceError: false,
  subLoading: false,
  subMeeting: [],
}

function getFaviconEl() {
  return document.getElementById("favicon");
}

export function conference(state = initialState, action) {
  switch (action.type) {
    case conferenceConstants.CONFERENCE_CREATE_START:
      return {
        ...state,
        loading: true,
        error: false
      };
    case conferenceConstants.CONFERENCE_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        meetingId: action.meetingId
      };
    case conferenceConstants.CONFERENCE_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      };
    case conferenceConstants.MEETING_INFO_START:
      return {
        ...state,
        meetingLoading: true,
        error: false
      };
    case conferenceConstants.MEETING_INFO_SUCCESS:
      return {
        ...state,
        meetingLoading: false,
        error: false,
        conferenceInfo: action.meetingInfo
      };
    case conferenceConstants.MEETING_BIT_FAILURE:
      return {
        ...state,
        meetingLoading: false,
        error: true,
        conferenceInfo: {},
      };
    case conferenceConstants.MEETING_BIT_START:
      return {
        ...state,
        meetingLoading: true,
        error: false,
        confLoading: true
      };
    case conferenceConstants.MEETING_BIT_SUCCESS:
      const favicon = getFaviconEl();
      favicon.href = action.meetingInfo.favicon
      return {
        ...state,
        meetingLoading: false,
        error: false,
        conferenceBits: action.meetingInfo,
        confLoading: false
      };
    case conferenceConstants.MEETING_INFO_FAILURE:
      return {
        ...state,
        meetingLoading: false,
        error: true,
        confLoading: false
      };
    case conferenceConstants.START_MEETING_FAILURE:
      return {
        ...state,
        startMeetingLoading: false,
        error: true,
        meetingRedirect: null
      };
    case conferenceConstants.START_MEETING_START:
      return {
        ...state,
        startMeetingLoading: true,
        error: false,
        meetingRedirect: null
      };
    case conferenceConstants.START_MEETING_SUCCESS:
      return {
        ...state,
        startMeetingLoading: false,
        error: false,
        meetingRedirect: action.meetingInfo
      };
    case conferenceConstants.DELETE_INVITE_START:
      return {
        ...state,
        inviteLoading: true
      };
    case conferenceConstants.DELETE_INVITE_SUCCESS:
      return {
        ...state,
        inviteLoading: false
      };
    case conferenceConstants.MEETING_DETAIL_START:
      return {
        ...state,
        loading: true,
        error: false
      };
    case conferenceConstants.MEETING_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        meetingDetail: action.response,
        error: false
      };
    case conferenceConstants.MEETING_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      };
    case conferenceConstants.RE_INVITE_START:
      return {
        ...state,
        reinviteLoading: true
      };
    case conferenceConstants.RE_INVITE_SUCCESS:
      return {
        ...state,
        reinviteLoading: false
      };
    case conferenceConstants.RE_INVITE_FAILURE:
      return {
        ...state,
        reinviteLoading: false
      };
    case conferenceConstants.ADD_PARTICIPANT_START:
      return {
        ...state,
        addParticipantLoading: true,
        isCloseModel: false
      };
    case conferenceConstants.ADD_PARTICIPANT_SUCCESS:
      return {
        ...state,
        addParticipantLoading: false,
        isCloseModel: true
      };
    case conferenceConstants.ADD_PARTICIPANT_FAILURE:
      return {
        ...state,
        addParticipantLoading: false,
        isCloseModel: false
      };

    case conferenceConstants.ALLOW_CONFERENCE_START:
      return {
        ...state,
        allowConferenceLoading: true,
        allowConferenceStatus: false,
        allowConferenceError: false,
      };
    case conferenceConstants.ALLOW_CONFERENCE_SUCCESS:
      return {
        ...state,
        allowConferenceLoading: false,
        allowConferenceStatus: true,
        allowConferenceError: false,
      };
    case conferenceConstants.ALLOW_CONFERENCE_FAILURE:
      return {
        ...state,
        allowConferenceLoading: false,
        allowConferenceStatus: false,
        allowConferenceError: true,
      };
    case conferenceConstants.MEETING_SUB_LIST_START:
      return {
        ...state,
        loading: true,
        meetingDetail: []
      }
    case conferenceConstants.MEETING_SUB_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        subMeeting: action.response,
      }
    case conferenceConstants.MEETING_SUB_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        subMeeting: [],
      }
    default:
      return state
  }
}