import React, { Component } from 'react';
import { Input, Form, FormGroup, Label, Button, Col, Row } from 'reactstrap';
import PhoneInput from 'react-phone-input-2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { userActions } from './../../../../Redux/_actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';





class UserProfileForm extends Component {

    constructor() {
        super();
        this.state = {
            profileForm: {
                name: '',
                mobile: '',
                email: '',
                role: '',
                group: '',
                DOB: '',
                role_name: '',
                countryCode: '91',
                emailVerified: 0,
                timezone: '',
                countryId: 'in'
            }
        }
    }
    componentDidUpdate = async (prevProps, prevState) => {
        if (prevProps.userData !== this.props.userData) {
            if (Object.keys(this.props.userData).length) {
                console.log(this.props.userData)
                const data = { ...this.state.profileForm };
                data['name'] = this.props.userData.name || '';
                data['mobile'] = this.props.userData.mobile || '';
                data['email'] = this.props.userData.email || '';
                data['role'] = this.props.userData.role || '';
                data['group'] = this.props.userData.group || '';
                data['countryCode'] = this.props.userData.countryCode || '';
                data['DOB'] = this.props.userData.hasOwnProperty('DOB') ? new Date(this.props.userData.DOB) : '';
                data['emailVerified'] = this.props.userData.emailVerified || 0;
                data['timezone'] = this.props.userData.timezone || '';
                data['role_name'] = this.props.userData.hasOwnProperty('role_name') ? this.props.userData.role_name : '' || '';
                data['countryId'] = 'in';

                this.setState({ ...this.state.profileForm, profileForm: data });
            }
        }
    }

    async handleUserInput(e) {
        const name = e.target.name;
        const value = e.target.value;
        const formData = { ...this.state.profileForm };
        formData[name] = value;
        await this.setState({ ...this.state.profileForm, profileForm: formData });
        //await this.vallidateForm();
    }

    setDOB = async(date) => {
        const data = { ...this.state.profileForm };
        data['DOB'] = date;
        await this.setState({ ...this.state.profileForm, profileForm: data });
    }
    
    async mobileNumberChange(val, country) {
        const mobile = val.slice(country.dialCode.length);
        const code = country.dialCode;
        const formData = { ...this.state.profileForm };
        formData.countryCode = code;
        formData.mobile = mobile;
        await this.setState({ ...this.state.profileForm, profileForm: formData });
    }

    async submitForm(event) {
        event.preventDefault();
        this.props.profileUpdate(this.state.profileForm)
    }

    render() {
        return (
            <Form onSubmit={(event) => this.submitForm(event)}>
                <Row>
                    <Col sm="6" style={{ paddingRight: '8px' }}>
                        <FormGroup  className="form-col">
                            <Label  className="title-label">Name</Label>
                            <Input type="text" name="name" onChange={(e) => this.handleUserInput(e)} value={this.state.profileForm.name} autocomplete="off" />
                        </FormGroup>
                    </Col>

                    <Col sm="6" style={{ paddingLeft: '8px' }}>
                        <FormGroup  className="form-col">
                            <Label  className="title-label">Contact Number</Label>
                            <PhoneInput
                                value={this.state.profileForm.countryCode + this.state.profileForm.mobile}
                                inputClass="phone-input"
                                enableSearch={true}
                                placeholder="Mobile No"
                                countryCodeEditable={false}
                                onChange={(value, country) => this.mobileNumberChange(value, country)}

                            />
                        </FormGroup>
                    </Col>

                    <Col sm="6" style={{ paddingRight: '8px' }}>
                        <FormGroup  className="form-col">
                            {this.state.profileForm.emailVerified === 0 && <span  className="ml-3 f_size_12 float-right"><button type="button" className="btn btn-success btn-xs f_size_12">Verify Email</button></span>}
                            <Label  className="title-label">Email</Label>
                            <Input type="email" value={this.state.profileForm.email} />
                        </FormGroup>
                    </Col>

                    <Col sm="6" style={{ paddingRight: '8px' }}>
                        <FormGroup  className="form-col">
                            <Label  className="title-label">Role</Label>
                            <Input type="text" value={this.state.profileForm.role_name} autocomplete="off" />
                        </FormGroup>
                    </Col>

                    {/* <Col sm="6" style={{ paddingRight: '8px' }}>
                        <FormGroup  className="form-col">
                            <Label  className="title-label">Password</Label>
                            <Input type="password" value="12345678" autocomplete="off" />
                        </FormGroup>
                    </Col>

                    <Col sm="6" style={{ paddingRight: '8px' }}>
                        <FormGroup  className="form-col">
                            <Label  className="title-label">Confirm Password</Label>
                            <Input type="password" value="12345678" autocomplete="off" />
                        </FormGroup>
                    </Col> */}


                    <Col sm="6" style={{ paddingRight: '8px' }}>
                        <FormGroup  className="form-col">
                            <Label  className="title-label">Group</Label>
                            <Input type="text" value={this.state.profileForm.group} autocomplete="off" />
                        </FormGroup>
                    </Col>

                    <Col sm="6" style={{ paddingRight: '8px' }}>
                        <FormGroup  className="form-col mb-0 form-datepicker">
                            <Label  className="title-label">DOB</Label><br />

                            <div  className="input-group time_inputsas">
                                <DatePicker
                                    selected={this.state.profileForm.DOB}
                                    onChange={(date) => this.setDOB(date)}
                                    minTime={new Date(new Date())}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    autoComplete="off"
                                     className='form-control'
                                    name="DOB"
                                />
                                <div  className="input-group-append">
                                    <span  className="icon-date" id="basic-addon2"><i  className="far fa-clock"></i></span>
                                </div>
                            </div>


                        </FormGroup>
                    </Col>

                </Row>

                <Col sm="12"  className="text-center mt-5">
                    {/* <Button  className="btn-off-dark mr-3">Cancel</Button> */}
                    <Button  className="btn-theme-primary">Save</Button>
                </Col>

            </Form>
        )
    }
}

const mapState = (state) => {
	const { profileLoading, profileInfo, updateLoading, updated } = state.authentication;
	return {
		profileLoading,
		profileInfo,
		updateLoading,
		updated
	}
}

const actionCreators = {
	profileUpdate: userActions.profileUpdate
};

export default withRouter(connect(mapState, actionCreators)(UserProfileForm));
