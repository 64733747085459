import React, { Component } from 'react'
import { /* Button,  */Row } from 'reactstrap';
import { userActions } from './../../../../Redux/_actions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// import { Spinner } from 'reactstrap';

class ProfileHeader extends Component {
    constructor() {
        super();

        this.state = {
            profileForm: {
                file: '../images/avtar2.jpg'
            }
        }
    }

    componentDidUpdate = async (prevProps, prevState) => {
        if (this.props.userData !== undefined) {
            if (this.props.userData.hasOwnProperty('profile_photo')) {
                if (prevProps.userData.profile_photo !== this.props.userData.profile_photo) {
                    const data = { ...this.state.profileForm };
                    data['file'] = this.props.userData.profile_photo
                    this.setState({ ...this.state.profileForm, profileForm: data })
                }
            }
        }
    }

    handleLogoChange = async (event) => {
        let formImageData = new FormData();
        formImageData.append('profile_photo', event.target.files[0]);
        this.props.uploadProfilePhoto(formImageData);
        const formData = { ...this.state.profileForm };
        formData['file'] = URL.createObjectURL(event.target.files[0]);
        await this.setState({ ...this.state.profileForm, profileForm: formData });
    }
    render() {
        return (
            <Row className="profile-header">
                <div className="profile-lft">
                    <div ><img src={this.state.profileForm.file} alt="" className="profile-img" ></img></div>
                    <div className="custom_file_upload">
                        <input type="file" id="brand_logo" onClick={(event) => { event.target.value = null }} onChange={this.handleLogoChange} className="brand_logo" />
                        <label for="brand_logo" className="btn-upload"><i className="fas fa-pencil-alt"></i></label>
                    </div>
                </div>
                <div className="user-info">
                    <div className="user-name">{this.props.userData.hasOwnProperty('name') && this.props.userData.name[0].toUpperCase() + this.props.userData.name.substring(1) || ''}</div>
                    <div className="user-post">{this.props.userData.hasOwnProperty('role_name') ? this.props.userData.role_name : '' || ''}</div>
                    <div className="user-subtext"><i className="fas fa-envelope"></i> {this.props.userData.email || ''}</div>
                    <div className="user-subtext"><i className="fas fa-mobile-alt"></i> {this.props.userData.mobile || ''}</div>

                </div>

            </Row>
        )
    }

}


const mapState = (state) => {
    const { profileInfo } = state.authentication;
    return {
        profileInfo,
    }
}

const actionCreators = {
    uploadProfilePhoto: userActions.uploadProfilePhoto
};


export default withRouter(connect(mapState, actionCreators)(ProfileHeader));

