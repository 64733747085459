import React from 'react'
import {
  Container,
  Card,
  Row,
  Col,
} from "react-bootstrap";
import { organization, myconfig } from '../../config/config';

import './style.scss';

const AuthLayout = (props) => {
  const { children, label, subLabel, googleLayout, image, goBack, logoIcon } = props
  return (
    <div style= {{"background-image":"linear-gradient(to right bottom,#fdfdff,#eeeef7,#dde0ee, #ccd2e6,  #bac5de)"}}>
      <Container className="auth-layout">
        <Card className="innerCardAuth" >

          {goBack &&
            <button className="backIcon" onClick={() => goBack()}>
              <i className="fas fa-arrow-left icon"></i>
            </button>}
          <img src={organization === "divya" ? `${myconfig.serverURL}/divya/divya-logo-dark.png` : logoIcon || "/images/wave-one-line.png"} className="logoImg align-self-center pt-4" alt="logo" />
          <Row className="px-5 mt-3">
            <Col lg={6} className="containSection" >
              <h2 className="font-weight-bold headingText  mb-4">{label}</h2>
              {subLabel && <div className="pb-3 mt-4 subLabelContainer d-flex  justify-content-between">
                <div>
                  <span className="">________</span>
                  <span className="ml-3 position-relative subLabel">{subLabel}</span>
                </div>
                {
                  googleLayout &&
                  <div className='googleLayout'>
                    {googleLayout}
                  </div>
                }

              </div>}

              {children}
            </Col>
            <Col lg={6}>
              <div className=" imgContainer">
                <img src={image} className="w-100 " alt={label} />
              </div>
            </Col>
          </Row>
        </Card>
      </Container>
    </div>
  )
}



export default AuthLayout
