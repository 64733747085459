import React, { /* lazy, */ Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import jwt_decode from "jwt-decode";
import PropTypes from "prop-types";
import LoadSpinner from "../components/UI/Spinner/Spinner";
import { ReactNotifications } from "react-notifications-component";
import { organization } from "../config/config";

// Lazy load components on demand
import MobileRedirect from "../mobileRedirect";
import HomePage from "../HomePage/HomePage";
import { Login } from '../Authorization/Login/Login'// --> old One
import LoginSection from "../Authorization/Login/index";
import MobileVerification from "../Authorization/mobileVerification/index.jsx";
import ScheduleMeeting from "../MeetingsSection/MeetingHistory/CreateMeet";
import SignUp from "../Authorization/SignUp";
import ForgetPassword from "../Authorization/ForgetPassword/ForgetPassword";
import ResetPassword from "../Authorization/ForgetPassword/ResetPassword";
import JoinMeeting from "../Meetings/components/Conference/JoinMeeting";
import VerifySignup from "../Authorization/SignUp/VerfiyEmail";
import MeetingList from "../MeetingsSection/MeetingHistory/MeetingList";
import MeetingDetail from "../MeetingsSection/MeetDetails";
import RegistrationMeet from "../MeetingsSection/joinMeet/registration";
import MeetingOptions from "../MeetingsSection/MeetDetails/options";
import MeetingParticipants from "../MeetingsSection/MeetDetails/participants";
import MeetingEntry from "../MeetingsSection/MeetDetails/MeetEntry";
import WaitingRoom from "../Meetings/waitingRoom";
import Webinar from "../Webinar";
import BaseInfo from "../Webinar/Details/baseInfo";
import Branding from "../Webinar/Details/branding";
import HistoryMeet from "../MeetingsSection/MeetDetails/history";

import Options from "../Webinar/Details/options";
import Participants from "../Webinar/Details/participants";
import WebinarHistory from "../Webinar/Details/history";

import Speakers from "../Webinar/Details/speakersAndHosts";
import LiveStream from "../Webinar/Details/liveStream";

import RegisterWebinar from "../Webinar/Register/register";
import WebinarRoom from "../Webinar/WebinarRoom/Room";
import WebinarWaitingRoom from "../Webinar/WebinarRoom/WebinarWaitingRoom.js";

import NetworkError from "../components/Error/NetworkError";


import JoinWebinar from "../Webinar/Join/JoinWebinar";
import AttendWebinar from "../Webinar/Join/AttendWebinar";

import Profile from "../User/profile";
import ProfileEdit from "../User/profileEdit";
import ChangePassword from "../User/changePassword";
import EditSchedule from "./../Meetings/components/Schedule/edit";

import AttendeeJoinMeet from "../Meetings/components/Conference/AttendeeJoin";
import HostJoinMeet from "../Meetings/components/Conference/HostJoin";

import eoffice from "../eOffice/Container";

import CompanyBranding from "../eOffice/Setting/CompanyBranding/CompanyBranding";
import CreateDepartment from "../eOffice/Setting/Department/CreateDepartment/CreateDepartment";
import ListDepartment from "../eOffice/Setting/Department/ListDepartment/ListDepartment";
import CreateUserAccount from "../eOffice/Setting/User/CreateUserAccount/CreateUserAccount";
import UserProfile from "../eOffice/Setting/UserProfile/UserProfile";
import CreateTeam from "../eOffice/Setting/Team/CreateTeam/CreateTeam";
import EditTeam from "./../eOffice/Setting/Team/EditTeam/EditTeam";
import ListTeam from "../eOffice/Setting/Team/ListTeam/ListTeam";
import ListUser from "../eOffice/Setting/User/ListUser/ListUser";
import CreateRole from "../eOffice/Setting/Role/CreateRole/CreateRole";
import EditRole from "./../eOffice/Setting/Role/EditRole/EditRole";
import ListRole from "../eOffice/Setting/Role/ListRole/ListRole";
import E_Office from "../eOffice";

import Report from "../Report";
/*Start*/
import UserFeedback from "../start/feedback/UserFeedback";
import UserManagment from "../start/Usermanagment/Usermanagment";

import HostRegister from "../start/HostRegister/HostRegister";
import HostInvitation from "../start/HostInvitation/Hostinvitation";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";
import userconfigs from "../helpers/closures";
import MeetingSection from "../MeetingsSection";
import Setting from "../setting";
import Guide from "../Meetings/components/Guide/guide";
// import ScheduleDetail from "../Meetings/components/Schedule/ScheduleDetail";
// import SignUp from '../Authorization/SignUp/SignUp';// --> old One
// import Meetings from "../Meetings/Meetings";
// import Schedule from "../Meetings/components/Schedule/Schedule";
// import CreateConference from "../Meetings/components/Conference/Create";
// import ScheduleWebinar from "../Webinar/Schedule/schedule";
// import Dashboard from "../Dashboard/Dashboard";
// import Drive from "../Dashboard/Drive";
// import ShareFiles from "../Dashboard/ShareFiles/ShareFiles";

// import StartMeeting from "../Meetings/components/Conference/StartMeeting";
// import Upcoming from "../Meetings/Upcoming";
// import MeetingHistory from '../Meetings/components/MeetingHistory/MeetingHistory';
// import MeetingList from "../Meetings/components/MeetingHistory/MeetingList";
// import MeetingDetail from "../Meetings/components/MeetingHistory/MeetingDetail";
// import CreateMeet from "../MeetingsSection/MeetingHistory/CreateMeet/createSection";
// import WebinarLists from "../Webinar/WebinarLists";
// import WebinarDetails from "../Webinar/Details/WebinarDetails";
// import Messages from "../Webinar/Join/Components/Messages/Messages";

// import MessageForWebinar from "../Meetings/components/WaitingRoom/MessageForWebinar/messageForWebinar";
// import Upgrade from "../Upgrade/Upgrade";
// import SelectPlan from "./../Upgrade/SelectPlan/index";
// import BuyPlan from "./../Upgrade/BuyPlan/index";
// import ReviewSelection from "./../Upgrade/ReviewSelection";
// import ListLicence from "./../Licence/ListItem/index";
// import HostRecords from "../start/records/Host-Records";
// import ManageFeedback from "../start/ManageFeedback/ManageFeedback";
// import ManageUsers from "../start/ManageUsers/ManageUsers";
// import AddUser from "../start/ManageUsers/AddUser";
// import EditUser from "../start/ManageUsers/EditUser";
// import HostManageUsers from "../start/ManageUsers/HostManageUsers";
// import { useSelector } from "react-redux";

function getFaviconEl() {
  return document.getElementById("favicon");
}

const updateFavicon = () => {
  const favicon = getFaviconEl();
  const token = jwt_decode(userconfigs.getItem("jwt"));
  if (token?.favicon) {
    favicon.href = token.favicon;
  } else if (token?.originFavicon) {
    favicon.href = token.originFavicon;
  } else {
    favicon.href = "./favicon.ico"
  }
}

const AuthSection = () => {
  const [jwt] = useState(jwt_decode(userconfigs.getItem("jwt")))

  useEffect(() => {
    updateFavicon()
    window.addEventListener('storage', updateFavicon);
    return () => window.removeEventListener('storage', updateFavicon);
  }, [])


  if (jwt?.loginStatus) {
    return (
      <Switch>
        <Route
          exact
          path="/mobile_redirect/:token"
          component={MobileRedirect}
        />
        <Route path="/mobile_verification" > <MobileVerification jwt={jwt} /> </Route>
        <Route exact path="/" > <HomePage jwt={jwt} form="login" /> </Route>
        <Route exact path="/user_feedback" component={UserFeedback} />
        <Route exact path="/usermanagment" component={UserManagment} />
        {/* Start */}
        <Route exact path="/profile" component={Profile} />
        <Route exact path="/edit_profile" component={ProfileEdit} />
        <Route exact path="/change_password" component={ChangePassword} />
        <Route exact path="/meetings" component={MeetingSection} />
        <Route exact path="/schedule_meeting" component={ScheduleMeeting} />
        <Route exact path="/meeting_history" component={MeetingList} />
        <Route exact path="/meeting_history/:id" component={MeetingDetail} />
        <Route exact path="/meeting_history/:id/participants" component={MeetingParticipants}/>
        <Route exact path="/meeting_history/:id/entry" component={MeetingEntry} />
        <Route exact path="/meeting_history/:id/options" component={MeetingOptions}/>
        <Route exact path="/meeting_history/:id/history" component={HistoryMeet} />
        <Route exact path="/webinars" component={Webinar} />
        <Route exact path="/webinars/:id" component={BaseInfo} />
        <Route exact path="/webinars/:id/events" component={Options} />
        <Route exact path="/webinars/:id/speakers" component={Speakers} />
        <Route exact path="/webinars/:id/branding" component={Branding} />
        <Route exact path="/webinars/:id/live_stream" component={LiveStream} />
        <Route exact path="/webinars/:id/participants" component={Participants} />

        <Route exact path="/webinars/:id/history" component={WebinarHistory} />

        <Route exact path="/register_webinar/:id" component={RegisterWebinar} />
        <Route exact path="/join_webinar" component={JoinWebinar} />

        <Route exact path="/webinar_room" component={WebinarRoom} />
        <Route exact path="/reports" component={Report} />
        <Route exact path="/edit_schedule/:id" component={EditSchedule} />
        <Route exact path="/e_office" component={E_Office} />
        <Route exact path="/eoffice" component={eoffice} />
        <Route exact path="/eoffice/Setting/company-branding" component={CompanyBranding}/>
        <Route exact path="/eoffice/Setting/create-department" component={CreateDepartment} />
        <Route exact path="/eoffice/Setting/edit-department/:id" component={CreateDepartment} />
        <Route exact path="/eoffice/Setting/create-user-account" component={CreateUserAccount} />
        <Route exact path="/eoffice/Setting/edit-user/:id" component={CreateUserAccount}/>
        <Route exact path="/eoffice/Setting/department/list" component={ListDepartment}/>
        <Route exact path="/eoffice/Setting/profile" component={UserProfile} />
        <Route exact path="/eoffice/Setting/create-team" component={CreateTeam}/>
        <Route exact path="/eoffice/Setting/edit-team/:id" component={EditTeam} />
        <Route exact path="/eoffice/Setting/team/list" component={ListTeam} />
        <Route exact path="/eoffice/Setting/user/list" component={ListUser} />
        <Route exact path="/eoffice/Setting/Create-role"  component={CreateRole} />
        <Route exact path="/eoffice/Setting/edit-role/:id" component={EditRole}/>
        <Route exact path="/eoffice/Setting/list-role" component={ListRole} />
        <Route exact path="/verify/:token" component={VerifySignup} />
        <Route exact path="/setting" > <Setting jwt={jwt} /></Route>
        <Route exact path="/guide" component={Guide} />

        <Route > <HomePage jwt={jwt} /> </Route>
        {/* <Route exact path="/schedule_conference" component={Schedule} /> */}
        {/* <Route exact path="/create_conference" component={CreateConference} /> */}
        {/* <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/drive" component={Drive} /> */}
        {/* <Route exact path="/share_files" component={ShareFiles} /> */}
        {/* <Route exact path="/start_meeting" component={StartMeeting} /> */}

        {/* <Route exact path="/upcoming_meeting" component={Upcoming} /> */}
        {/* <Route exact path="/upcoming_meeting/:id" component={ScheduleDetail} /> */}
        {/* <Route exact path="/create_meeting" component={CreateMeet} /> */}
        {/* <Route exact path="/schedule_webinar" component={ScheduleWebinar} /> */}
        {/* <Route exact path="/webinars" component={WebinarLists} /> */}
        {/* <Route exact path="/manage-feedback" component={ManageFeedback} /> */}
        {/* <Route exact path="/record" component={HostRecords} /> */}

        {/* <Route exact path="/manage-users" component={ManageUsers} /> */}
        {/* <Route exact path="/user/add" component={AddUser} /> */}
        {/* <Route exact path="/host-manage-users" component={HostManageUsers} /> */}
        {/* <Route exact path="/user/:id/edit" component={EditUser} /> */}
        {/* <Route exact path="/webinars/:id" component={WebinarDetails} /> */}
        {/* <Route exact path="/webinar_info" component={Messages} /> */}
        {/* <Route exact path="/webinar_message" component={MessageForWebinar} /> */}
        {/* <Route exact path="/select_plan" component={SelectPlan} /> */}
        {/* <Route exact path="/buy_plan" component={BuyPlan} /> */}
        {/* <Route exact path="/review-plan" component={ReviewSelection} /> */}
        {/* <Route exact path="/list-licence" component={ListLicence} /> */}
      </Switch>
    );
  } else {
    return (
      <Switch>
        <Route exact path="/" >
          {organization === "sarv" ? <HomePage jwt={jwt} form="logout" /> : <Login jwt={jwt} />}
        </Route>
        <Route exact path="/login" >
          {organization === "sarv" ? <LoginSection jwt={jwt} /> : <Login />}
        </Route>
        <Route exact path="/forget_password" component={ForgetPassword} />
        <Route exact path="/signup" >
          <SignUp jwt={jwt} />
        </Route>
        <Route exact path="/verify/:token" component={VerifySignup} />
        <Route >
          {organization === "sarv" ? <LoginSection jwt={jwt} /> : <Login />}
        </Route>
      </Switch>
    );
  }
};

const Routes = (props) => {
  const { isLoaded/* , jwtDetails, mobile_verification */ } = props;

  if (!isLoaded) {
    return (
      <div className="d-flex justify-content-center mt-5">
        <LoadSpinner />
      </div>
    );
  } else {
    return (
      <div key="1">
        <Suspense
          fallback={
            <div className="d-flex justify-content-center mt-5">
              <LoadSpinner />
            </div>
          }
        >
          <ReactNotifications />
          <Router>
            <Switch>
              <Route exact path="/privacy-policy" component={PrivacyPolicy} />
              <Route exact path="/reset_password/:token" component={ResetPassword} />
              <Route exact path="/join_meeting" component={JoinMeeting} />
              <Route exact path="/register_meeting" component={RegistrationMeet} />
              <Route exact path="/attendee_join" component={AttendeeJoinMeet} />
              <Route exact path="/host_join" component={HostJoinMeet} />
              <Route exact path="/network_error" component={NetworkError} />
              <Route exact path="/waitingRoom/:id" component={WaitingRoom} />
              <Route exact path="/waitingRoom/:id/:moderatorToken" component={WaitingRoom} />
              <Route exact path="/attend_webinar" component={AttendWebinar} />
              <Route exact path="/host-register" component={HostRegister} />
              <Route exact path="/host_invitation/:userid/:email/:ref" component={HostInvitation}/>
              <Route exact path="/webinar_waiting_room" component={WebinarWaitingRoom} />
              <Route exact path="/register_webinar/:id" component={RegisterWebinar} />
              <AuthSection />
            </Switch>
          </Router>
        </Suspense>
      </div>
    );
  }
};

Route.propTypes = {
  isLoaded: PropTypes.bool,
  jwtDetails: PropTypes.object,
};

Route.defaultProps = {
  isLoaded: false,
  jwtDetails: {},
};

export default Routes;
