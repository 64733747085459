export const conferenceConstants = {
    CONFERENCE_CREATE_START: 'CONFERENCE_CREATE_START',
    CONFERENCE_CREATE_SUCCESS: 'CONFERENCE_CREATE_SUCCESS',
    CONFERENCE_CREATE_FAILURE: 'CONFERENCE_CREATE_FAILURE',

    MEETING_INFO_START : 'MEETING_INFO_START',
    MEETING_INFO_SUCCESS : 'MEETING_INFO_SUCCESS',
    MEETING_INFO_FAILURE : 'MEETING_INFO_FAILURE',

    MEETING_BIT_START : 'MEETING_BIT_START',
    MEETING_BIT_SUCCESS : 'MEETING_BIT_SUCCESS',
    MEETING_BIT_FAILURE : 'MEETING_BIT_FAILURE',

    START_MEETING_START : 'START_MEETING_START',
    START_MEETING_SUCCESS : 'START_MEETING_SUCCESS',
    START_MEETING_FAILURE : 'START_MEETING_FAILURE',

    DELETE_INVITE_START : 'DELETE_INVITE_START',
    DELETE_INVITE_SUCCESS : 'DELETE_INVITE_SUCCESS',

    MEETING_DETAIL_START: 'MEETING_DETAIL_START',
    MEETING_DETAIL_SUCCESS: 'MEETING_DETAIL_SUCCESS',
    MEETING_DETAIL_FAILURE: 'MEETING_DETAIL_FAILURE',


    MEETING_SUB_LIST_START: 'MEETING_SUB_LIST_START',
    MEETING_SUB_LIST_SUCCESS: 'MEETING_SUB_LIST_SUCCESS',
    MEETING_SUB_LIST_FAILURE: 'MEETING_SUB_LIST_FAILURE',

    RE_INVITE_START : 'RE_INVITE_START',
    RE_INVITE_SUCCESS : 'RE_INVITE_SUCCESS',
    RE_INVITE_FAILURE : 'RE_INVITE_FAILURE',

    ADD_PARTICIPANT_START : 'ADD_PARTICIPANT_START',
    ADD_PARTICIPANT_SUCCESS : 'ADD_PARTICIPANT_SUCCESS',
    ADD_PARTICIPANT_FAILURE : 'ADD_PARTICIPANT_FAILURE',

    ALLOW_CONFERENCE_START : 'ALLOW_CONFERENCE_START',
    ALLOW_CONFERENCE_SUCCESS : 'ALLOW_CONFERENCE_SUCCESS',
    ALLOW_CONFERENCE_FAILURE : 'ALLOW_CONFERENCE_FAILURE',
};
