import { userConstants } from '../_constants';

const initialState = {
    signupLoader: false,
    signedUp:false,
    verifyLoading:true,
    verified:false,
    msg:""
  };

export function authRegistration(state = initialState, action) {
  switch (action.type) {
    case userConstants.SIGNUP_REQUEST:
      return {
        signupLoader:true,
        signedUp: false
      };
    case userConstants.SIGNUP_SUCCESS:
      return {
        signupLoader:false,
        signedUp: true
      };
    case userConstants.SIGNUP_FAILURE:
      return {
        signupLoader:false,
        signedUp: false
      };

    case userConstants.VALIDATE_SIGNUP_REQUEST:
      return {
        verifyLoading:true,
        verified:false,
        msg:""
      };
    case userConstants.VALIDATE_SIGNUP_SUCCESS:
      return {
        verifyLoading:false,
        verified:true,
        msg:action.resp
      };
    case userConstants.VALIDATE_SIGNUP_FAILURE:
      return {
        verifyLoading:false,
        verified:false,
        msg:action.error
      }

    default:
      return state
  }
}