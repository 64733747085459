import jwt_decode from "jwt-decode";

import { userConstants } from "../_constants";
import { userService } from "../_services";
import { alertActions } from "./";
import { history } from "../_helpers";

export const userActions = {
  login,
  loginWithOTP,
  getLoginOTP,
  resendEmailLink,
  loginDetails,
  userDetails,
  profileUpdate,
  changePassword,
  verifyProfile,
  verifyOtp,
  logout,
  forgetPassword,
  resetPassword,
  authGoogle,
  gAuthURL,
  gAuthToken,
  removeGoogleDriveAuth,
  userContacts,
  gContactAuthURL,
  gContactAuthToken,
  syncGoogleContact,
  upgradePlan,
  createUserAccount,
  uploadUserCsv,
  uploadProfilePhoto,
  editUserAccount,
  getAccessibility,
  removeUserFromBusiness,
  checkEmailVerifiedOrNot,
};

function login(username, password, token) {
  return (dispatch) => {
    dispatch(request({ username }));
    userService.login(username, password, token).then(
      (user) => {
        if (typeof user !== "object") {
          dispatch(alertActions.success("Login Successful !"));
          dispatch(success(user));
          window.location.replace("/");
        } else {
          if (user.msg) {
            dispatch(alertActions.error(user.msg.toString()));
            dispatch(success(user));
          }
        }
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error.toString()));
      },
    );
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function removeUserFromBusiness(uuid) {
  return (dispatch) => {
    dispatch(request({ uuid }));

    userService.removeUserFromBusiness(uuid).then(
      (msg) => {
        dispatch(alertActions.success("Contact removed successfully !!"));
        dispatch(success(msg));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error.toString()));
      },
    );
  };

  function request(postData) {
    return { type: userConstants.USER_REMOVE_FROM_BUSINESS_REQUEST, postData };
  }
  function success(msg) {
    return { type: userConstants.USER_REMOVE_FROM_BUSINESS_SUCCESS, msg };
  }
  function failure(error) {
    return { type: userConstants.USER_REMOVE_FROM_BUSINESS_FAILURE, error };
  }
}

function uploadProfilePhoto(formData) {
  return (dispatch) => {
    dispatch(request({}));
    const data = {
      csv: formData,
    };
    console.log(formData);

    userService.uploadProfilePhoto(formData).then(
      (resp) => {
        dispatch(alertActions.success("Photo uploaded successfully!"));
        dispatch(success(resp));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error.toString()));
      },
    );
  };

  function request(resp) {
    return { type: userConstants.PROFILE_PHOTO_REQUEST };
  }
  function success(resp) {
    return { type: userConstants.PROFILE_PHOTO_SUCCESS, resp };
  }
  function failure(error) {
    return { type: userConstants.PROFILE_PHOTO_FAILURE, error };
  }
}

function getLoginOTP(username) {
  return (dispatch) => {
    dispatch(request({ username }));

    userService.getLoginOTP(username).then(
      (user) => {
        dispatch(alertActions.success("OTP sent successfully."));
        dispatch(success(user));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error.toString()));
      },
    );
  };

  function request(user) {
    return { type: userConstants.LOGIN_OTP_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_OTP_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_OTP_FAILURE, error };
  }
}

function loginWithOTP(username, otp) {
  return (dispatch) => {
    dispatch(request({ username }));

    userService.loginWithOTP(username, otp).then(
      (user) => {
        dispatch(alertActions.success("Login Successful !"));
        dispatch(success(user));
        window.location.replace("/");
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error.toString()));
      },
    );
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function logout() {
  return (dispatch) => {
    //dispatch(request({ fp }));

    userService.logout().then(
      (user) => {
        dispatch(success(user));
        window.location.href = "/login"
      },
      (error) => {
        //dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      },
    );
  };

  function success(user) {
    return { type: userConstants.LOGOUT, user };
  }
}

function resendEmailLink(email) {
  return (dispatch) => {
    dispatch(request({ email }));

    userService.resendEmailLink(email).then(
      (msg) => {
        dispatch(alertActions.success(msg.toString()));
        dispatch(success(msg));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error.toString()));
      },
    );
  };

  function request(email) {
    return { type: userConstants.RESEND_EMAIL_REQUEST, email };
  }
  function success(user) {
    return { type: userConstants.RESEND_EMAIL_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.RESEND_EMAIL_FAILURE, error };
  }
}

function loginDetails(username) {
  return (dispatch) => {
    dispatch(request({ username }));

    userService.loginDetails(username).then(
      (msg) => {
        dispatch(alertActions.success(msg.toString()));
        dispatch(success(msg));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error.toString()));
      },
    );
  };

  function request(username) {
    return { type: userConstants.LOGIN_DETAILS_REQUEST, username };
  }
  function success(msg) {
    return { type: userConstants.LOGIN_DETAILS_SUCCESS, msg };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_DETAILS_FAILURE, error };
  }
}

function userDetails(uuid = "") {
  return (dispatch) => {
    dispatch(request({}));
    userService.userDetails(uuid).then(
      (resp) => {
        dispatch(success(resp));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error.toString()));
      },
    );
  };
  function request(resp) {
    return { type: userConstants.USER_DETAILS_REQUEST, resp };
  }
  function success(resp) {
    return { type: userConstants.USER_DETAILS_SUCCESS, resp };
  }
  function failure(error) {
    return { type: userConstants.USER_DETAILS_FAILURE, error };
  }
}

function getAccessibility() {
  return (dispatch) => {
    dispatch(request({}));
    userService.getAccessibility().then(
      (resp) => {
        dispatch(success(resp));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error.toString()));
      },
    );
  };
  function request(resp) {
    return { type: userConstants.GET_ACCESSIBILITY_REQUEST, resp };
  }
  function success(resp) {
    return { type: userConstants.GET_ACCESSIBILITY_SUCCESS, resp };
  }
  function failure(error) {
    return { type: userConstants.GET_ACCESSIBILITY_FAILURE, error };
  }
}

function userContacts() {
  return (dispatch) => {
    dispatch(request({}));
    userService.userContacts().then(
      (resp) => {
        dispatch(success(resp));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error.toString()));
      },
    );
  };
  function request(resp) {
    return { type: userConstants.USER_CONTACTS_REQUEST, resp };
  }
  function success(resp) {
    return { type: userConstants.USER_CONTACTS_SUCCESS, resp };
  }
  function failure(error) {
    return { type: userConstants.USER_CONTACTS_FAILURE, error };
  }
}

function profileUpdate(postData, mobileForm) {
  return (dispatch) => {
    dispatch(request({ postData }));

    userService.profileUpdate(postData).then(
      (msg) => {
        dispatch(success(postData.mobile));
        dispatch(userActions.verifyProfile(postData.mobile))
        localStorage.setItem("timezone", JSON.stringify(postData.timezone));
        !mobileForm && dispatch(alertActions.success("Profile successfully updated."));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error.toString()));
      },
    );
  };
  function request(postData) {
    return { type: userConstants.PROFILE_UPDATE_REQUEST, postData };
  }
  function success(mobile) {
    return { type: userConstants.PROFILE_UPDATE_SUCCESS, mobile };
  }
  function failure(error) {
    return { type: userConstants.PROFILE_UPDATE_FAILURE, error };
  }
}

function changePassword(postData) {
  return (dispatch) => {
    dispatch(request({ postData }));

    userService.changePassword(postData).then(
      (msg) => {
        dispatch(success(msg));
        dispatch(alertActions.success(msg.toString()));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error.toString()));
      },
    );
  };

  function request(postData) {
    return { type: userConstants.PASSWORD_UPDATE_REQUEST, postData };
  }
  function success(msg) {
    return { type: userConstants.PASSWORD_UPDATE_SUCCESS, msg };
  }
  function failure(error) {
    return { type: userConstants.PASSWORD_UPDATE_FAILURE, error };
  }
}

function verifyProfile(username) {
  return (dispatch) => {
    dispatch(request({ username }));

    userService.verifyProfile(username).then(
      (msg) => {
        dispatch(alertActions.success(msg.toString()));
        dispatch(success(msg));
        
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error.toString()));
      },
    );
  };

  function request(username) {
    return { type: userConstants.VERIFY_USERNAME_REQUEST, username };
  }
  function success(msg) {
    return { type: userConstants.VERIFY_USERNAME_SUCCESS, msg };
  }
  function failure(error) {
    return { type: userConstants.VERIFY_USERNAME_FAILURE, error };
  }
}

function verifyOtp(username, otp) {
  return (dispatch) => {
    dispatch(request({ username, otp }));

    userService.verifyOtp(username, otp).then(
      (msg) => {
        dispatch(alertActions.success("Details verified successfully."));
        window.location.replace('/')
        dispatch(success(msg));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error.toString()));
      },
    );
  };

  function request(username, otp) {
    return { type: userConstants.OTP_VERIFY_REQUEST, username, otp };
  }
  function success(msg) {
    return { type: userConstants.OTP_VERIFY_SUCCESS, msg };
  }
  function failure(error) {
    return { type: userConstants.OTP_VERIFY_FAILURE, error };
  }
}

function forgetPassword(username) {
  return (dispatch) => {
    dispatch(request({ username }));

    userService.forgetPassword(username).then(
      (user) => {
        dispatch(alertActions.success(user));
        dispatch(success(user));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error.toString()));
      },
    );
  };

  function request(user) {
    return { type: userConstants.FORGET_PASSWORD_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.FORGET_PASSWORD_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.FORGET_PASSWORD_FAILURE, error };
  }
}

function resetPassword(pwd, token) {
  return (dispatch) => {
    dispatch(request({ pwd }));

    userService.resetPassword(pwd, token).then(
      (user) => {
        dispatch(alertActions.success(user));
        dispatch(success(user));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error.toString()));
      },
    );
  };

  function request(user) {
    return { type: userConstants.RESET_PASSWORD_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.RESET_PASSWORD_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.RESET_PASSWORD_FAILURE, error };
  }
}

function authGoogle(postData) {
  return (dispatch) => {
    dispatch(request({ postData }));

    userService.authGoogle(postData).then(
      (user) => {
        if (typeof user !== "object") {
          // dispatch(userActions.checkEmailVerifiedOrNot(postData.email))
          history.push('/');
          dispatch(success(user));
          window.location.replace("/");
          //window.location.href = "/dashboard"
        } else {
          if (user.msg) {
            dispatch(alertActions.error(user.msg.toString()));
            dispatch(success(user));
          }
        }
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error.toString()));
      },
    );
  };

  function request(user) {
    return { type: userConstants.AUTH_GOOGLE_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.AUTH_GOOGLE_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.AUTH_GOOGLE_FAILURE, error };
  }
}

function gAuthURL() {
  return (dispatch) => {
    dispatch(request({}));

    userService.gAuthURL().then(
      (user) => {
        dispatch(success(user));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error.toString()));
      },
    );
  };

  function request(user) {
    return { type: userConstants.GOOGLE_AUTH_URL_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.GOOGLE_AUTH_URL_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.GOOGLE_AUTH_URL_FAILURE, error };
  }
}

function gContactAuthURL() {
  return (dispatch) => {
    dispatch(request({}));

    userService.gContactAuthURL().then(
      (user) => {
        dispatch(success(user));
      },
      (error) => {
        // dispatch(alertActions.error(error.toString()));
        // dispatch(failure(error.toString()));
      },
    );
  };

  function request(user) {
    return { type: userConstants.GOOGLE_CONTACT_AUTH_URL_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.GOOGLE_CONTACT_AUTH_URL_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.GOOGLE_CONTACT_AUTH_URL_FAILURE, error };
  }
}

function gAuthToken(code) {
  return (dispatch) => {
    //dispatch(request({ code }));

    userService.gAuthToken(code).then(
      (user) => {
        dispatch(success(user));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error.toString()));
      },
    );
  };

  //function request() { return { type: userConstants.GOOGLE_AUTH_URL_REQUEST } }
  function success(user) {
    return { type: userConstants.GOOGLE_AUTH_TOKEN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.GOOGLE_AUTH_TOKEN_FAILURE, error };
  }
}

function gContactAuthToken(code) {
  return (dispatch) => {
    //dispatch(request({ code }));

    userService.gContactAuthToken(code).then(
      (user) => {
        dispatch(success(user));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error.toString()));
      },
    );
  };

  //function request(user) { return { type: userConstants.GOOGLE_CONTACT_SYNC_REQUEST, user } }
  function success(user) {
    return { type: userConstants.GOOGLE_CONTACT_AUTH_TOKEN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.GOOGLE_CONTACT_AUTH_TOKEN_FAILURE, error };
  }
}

function syncGoogleContact() {
  return (dispatch) => {
    dispatch(request({}));

    userService.syncGoogleContact().then(
      (user) => {
        dispatch(success(user));
        dispatch(alertActions.success("Contact Sync Successfully !!"));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error.toString()));
      },
    );
  };

  function request(user) {
    return { type: userConstants.GOOGLE_CONTACT_SYNC_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.GOOGLE_CONTACT_SYNC_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.GOOGLE_CONTACT_SYNC_FAILURE, error };
  }
}

function removeGoogleDriveAuth() {
  return (dispatch) => {
    dispatch(request({}));

    userService.removeGoogleDriveAuth().then(
      (user) => {
        dispatch(success(user));
        dispatch(alertActions.success(user.toString()));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error.toString()));
      },
    );
  };

  function request(user) {
    return { type: userConstants.REMOVE_GOOGLE_AUTH_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.REMOVE_GOOGLE_AUTH_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.REMOVE_GOOGLE_AUTH_FAILURE, error };
  }
}

function upgradePlan(planData) {
  return (dispatch) => {
    dispatch(start("start"));
    userService.upgradePlan(planData).then(
      (response) => {
        dispatch(success(response));
        dispatch(alertActions.success("Account Upgrated Successfully."));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error.toString()));
      },
    );
  };

  function start(start) {
    return { type: userConstants.UPGRADE_REQUEST, start };
  }
  function success(response) {
    return { type: userConstants.UPFRADE_SUCCESS, response };
  }
  function failure(error) {
    return { type: userConstants.UPGRADE_FAILURE, error };
  }
}

function createUserAccount(accountData) {
  return (dispatch) => {
    dispatch(request({}));

    userService.createUserAccount(accountData).then(
      (msg) => {
        dispatch(success(msg));
        dispatch(alertActions.success(msg.toString()));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error.toString()));
      },
    );
  };

  function request(user) {
    return { type: userConstants.ADD_USER_ACCOUNT_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.ADD_USER_ACCOUNT_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.ADD_USER_ACCOUNT_FAILURE, error };
  }
}

function editUserAccount(accountData, uuid) {
  return (dispatch) => {
    dispatch(request({}));

    userService.editUserAccount(accountData, uuid).then(
      (msg) => {
        dispatch(success(msg));
        dispatch(alertActions.success(msg.toString()));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error.toString()));
      },
    );
  };

  function request(user) {
    return { type: userConstants.EDIT_USER_ACCOUNT_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.EDIT_USER_ACCOUNT_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.EDIT_USER_ACCOUNT_FAILURE, error };
  }
}

function uploadUserCsv(accountData) {
  return (dispatch) => {
    dispatch(request({}));

    userService.uploadUserCsv(accountData).then(
      (msg) => {
        dispatch(success(msg));
        dispatch(alertActions.success(msg.toString()));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error.toString()));
      },
    );
  };

  function request(user) {
    return { type: userConstants.UPLOAD_CSV_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.UPLOAD_CSV_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.UPLOAD_CSV_FAILURE, error };
  }
}


function checkEmailVerifiedOrNot(email) {
  return (dispatch) => {
    dispatch(request({}));
    userService.checkEmailVerified({ email }).then(
      (res) => {
        if (res.isMobileVerified === false) {
          dispatch(alertActions.success("Login Successful but need to verify mobile number!"));
        }
        else {
          dispatch(alertActions.success("Login Successful !"));
          history.push('/')
        }
        dispatch(success(res));
      },
      (error) => {
        dispatch(alertActions.success("Login Successful !"));
        dispatch(failure(error.toString()));
      },
    );
  };

  function request(user) {
    return { type: userConstants.CHECK_EMAIL_VERIFIED_REQ, user };
  }
  function success(user) {
    return { type: userConstants.CHECK_EMAIL_VERIFIED_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.CHECK_EMAIL_VERIFIED_FAILURE, error };
  }
}
