import React, { useEffect } from 'react';
import { Accordion, Button, Card, ProgressBar } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { BiTimer } from "react-icons/bi";
import { MdDateRange } from 'react-icons/md';
import { AiOutlineUser } from 'react-icons/ai';
import { FiUsers } from "react-icons/fi";
// import { ExportToCsv } from "export-to-csv";
import Layout from '../../layoutSection';
import { conferenceActions, reportActions } from '../../Redux/_actions';
import Spinner from "../../components/UI/Spinner/Spinner";
import { durationTime, tsToDate } from '../../helpers/helpers';
import Table from '../../layoutSection/table';
import './styles.scss'
import moment from 'moment';
import isEmpty from 'is-empty';

const ListSection = ({ data, index }) => {
  console.log(data, "data")
  const startTime = data?.joinedUsers?.startTime;
  const endTime = data?.joinedUsers?.endTime;

  return (
    <div className='sideBar welcomeMsg mt-2 borderRadius'>
      <div className="d-flex resScreenDes w-100 justify-content-between p-3 ">
        <div className="duration text-center">
          <div className="h6 mb-0"> <BiTimer /> Duration </div>
          <div className='submitColor ' >{(startTime && endTime) ? durationTime(startTime, endTime) : moment.utc(parseInt(data?.duration)).format("HH:mm:ss")}</div>
        </div>
        <div className="host text-center">
          <div> <AiOutlineUser /> Host</div>
          <div className='font-weight-bold '>{data?.joinedUsers?.hostCount}</div>
        </div>
        <div className="attendess text-center">
          <div> <FiUsers /> Attendees</div>
          <div className='font-weight-bold ' >{data?.joinedUsers?.attendeeCount}</div>
        </div>
        <div className="date text-center">
          <div><MdDateRange /> Date</div>
          <div className='submitColor ' >{parseInt(data?.createTime) && tsToDate(parseInt(data?.createTime))}</div>
        </div>
      </div>
      {data?.joinedUsers?.attendeeCount > 0 &&
       <Accordion>
       <Card className="mt-2 accordionCard sideBar">
         <Card.Header className="p-0 m-0  ">
           <Accordion.Toggle
             as={Button}
             variant="link"
             eventKey={index}
             className="w-100 p-3 welcomeMsg d-flex justify-content-between f_size_16 accordionHeader "
           >
             <div>View Details</div>
             <div>
               <i className="fas fa-chevron-down" />
             </div>
           </Accordion.Toggle>
         </Card.Header>
         <Accordion.Collapse eventKey={index} >
           <Card.Body className="accordionCardBody bg-transparent p-0" >
             <Table
               className="w-100 mb-0"
               headers={<tr>
                 <th> Participant </th>
                 <th> Duration </th>
                 <th> Joined </th>
                 <th> Left </th>
                 <th>Emoji</th>
               </tr>}

             >
               {(data?.joinedUsers?.participants && Object.values(data.joinedUsers.participants).length > 0) ?
                 Object.values(data.joinedUsers.participants).map((item, index) => (
                   <tr key={`table-${index}`}>
                     <td>
                       <div> <span className="profileImg bgColorBodyCard welcomeMsg">{item?.name?.charAt(0).trim().toUpperCase()}</span> {item?.name} {item?.role === "MODERATOR" && <span className='text-muted ml-2'> ( Host )</span>} </div>
                     </td>
                     <td className=''>
                       {(!item?.joined && item?.left) ? durationTime(item?.joined, item?.left) : moment.utc(item?.duration).format("HH:mm:ss")}
                       {/* <ProgressBar now={59} variant="success" className='borderRadius' max={(startTime && endTime) ? moment(endTime).diff(moment(startTime)) : 100} /> */}
                     </td>
                     <td>
                       {item?.joined ? tsToDate(item?.joined) : '-'}
                     </td>
                     <td>
                       {item?.left ? tsToDate(item?.left) : '-'}
                     </td>
                     <td>
                       {item?.userEmoji || '0'}
                     </td>
                   </tr>
                 ))
                 :
                 <tr className='text-center'>
                   <td colSpan={5}> <h3> No participants </h3> </td>
                 </tr>
               }
             </Table>
           </Card.Body>
         </Accordion.Collapse>
       </Card>
     </Accordion>}
     
    </div >
  )
}

const History = (props) => {
  const { meetingDetail, loading, subLoading, subMeeting } = useSelector(state => state.conference);
  const { reportList, loadingReport, meetRecord } = useSelector(state => state.report)
  const paras = useParams();
  const dispatch = useDispatch()
  //const reportList = [{name:'deb',email:'dev',phone:'12223'}];
  useEffect(() => {
    if ((paras.id && subMeeting?.meetingInfo?.meetingID !== paras.id) || !subMeeting) {
      dispatch(conferenceActions.subMeetingList(paras.id))
    }
  }, [paras.id]);


  const GetHistory = () => {
    // if (subLoading) {
    //   return <div className="text-center">
    //     <Spinner />
    //   </div>
    // } else
    if (subMeeting?.getAllMeetingHistory && subMeeting?.getAllMeetingHistory?.length > 0) {
      return Object.values(subMeeting?.getAllMeetingHistory).map((data, index) =>
        <div key={`meeting-${1}`} className="welcomeMsg">
          <ListSection data={data} index={index + 1} />
        </div>
      )
    } else {
      return <div className="resultNotFound sideBarIcon welcomeMsg">No History Available </div>;
    }
  }

  return (
    <Layout title={subMeeting?.meetingInfo?.name || 'History'} active="History" list="meeting" goBack="/meeting_history" >
      <>
        <div className='between pl-5 pr-5 overViewCvBtn'>
          <h4 className='welcomeMsg'>Overview</h4>

          <Button className='submitBtnColor pl-5 pr-5 p-2 border-0 borderRadius' disabled={subMeeting?.getAllMeetingHistory && isEmpty(subMeeting.getAllMeetingHistory)} onClick={() => props.history.push(`/reports?meetId=${paras.id}`)} > Download CSV </Button>
        </div>
        <div className='mt-4 mb-4'>
          <GetHistory />
        </div>
      </>
    </Layout>
  );
};

export default History;
