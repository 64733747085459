import React, { Component } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import './style.scss';
// import { Spinner } from 'reactstrap';
class ListDepartments extends Component {
    constructor(props) {
        super(props);
    }

    toggle = () => {
        this.props.modalCloseHandler();
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.props.isJoinDepartment} toggle={this.toggle} backdrop="static" size="lg" style={{ maxWidth: '95%', width: '55%' }} className="Popup viewRecordingModal " >
                    { }
                    <ModalBody>
                        <p className="txt-msg"> {this.props.message}</p>
                        <button className="rounded-circle btn-close" onClick={this.toggle}><i className="fas fa-times"></i></button>

                        <div className="row viewRecordingRow">
                            {this.props.departmentArr.map((dept, index) =>
                                <div className="col-md-4 text-center" key={index}>
                                    <div className="recording-col">
                                        <div className="icon">
                                            <div className="dot"></div>
                                            <i className="fas fa-briefcase icon-briefcase"></i>
                                        </div>
                                        <div className="title-name">{dept.department_name}</div>
                                        <button className="btn btn-view btn-sm" onClick={() => this.props.joinConfrence(dept.confrence_id)}>Join  </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        )
    }

}

export default ListDepartments;