import { myconfig, siteTokens } from '../../config/config';
import axios from 'axios';
import userconfigs from '../../helpers/closures'
import { generateToken } from '../../helpers/helpers';

import { errorCheck, handleJWTError } from './common'

const axiosRequestOptions = {
    headers: {
        'Content-Type': 'application/json',
        'Authorization': userconfigs.getItem('jwt'),
    }
}

export const userSignUpService = {
    signup,
    verifyAccount,
    checkEmailVerified
};

function signup(postData) {

    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };
    if(!postData?.csrf){
        postData.csrf = userconfigs.getItem('csrf');
    }
    //myconfig.apiURL = 'http://localhost:3001/';
    return axios.post(`${myconfig.apiURL}register`, postData, axiosRequestOptions)
        .then(handleResponse)
        .then(resp => {
            return resp;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
                // userconfigs.removeItem("jwt");
                // generateToken();
                // window.location.href = "/login";
            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}

function verifyAccount(token) {

    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };

    return axios.get(`${myconfig.apiURL}verifyLink/${token}`, axiosRequestOptions)
        .then(handleResponse)
        .then(resp => {
            return resp;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
                // userconfigs.removeItem("jwt");
                // generateToken();
                // window.location.href = "/login";
            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}

function logout() {
    // remove user from local storage to log user out
    //userconfigs.removeItem('jwt');
}

function handleResponse(response) {

    if (response.status !== 200) {
        if (response.status === 401) {
            // auto logout if 401 response returned from api
            logout();
        }

        const error = (response && response.message) || response.statusText;
        return Promise.reject(error);
    }

    return response.data.result;
}



function checkEmailVerified(postData) {
    return axios.post(`${myconfig.apiURL}checkEmailVerifiedOrNot`, postData, axiosRequestOptions)
        .then(handleResponse)
        .then(resp => {
            return resp;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}