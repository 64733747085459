import { getServerURL } from '../helpers/helpers';

// ## important Note
// 1. Need to update sideBarJson.js for updating beta and dev which respect to platform.

export var organization = "divya" // ->  for divya use  -> "divya", for sarv use  -> "sarv"

export const siteTokens = [
  {
    url: "wave.sarv.com",
    token: "whashJwt",
    apiURL: "https://api-wave.sarv.com/",
    streamURL: "wss://kurento-wave.sarv.com:8443/lbVideo",
    env: 'live',
    subdomainExtension: ".sarv.com",
  },
  {
    url: "meity.sarv.com",
    token: "mhashJwt",
    apiURL: "https://a_master-meity.sarv.com/",
    streamURL: "wss://kurento-wave.sarv.com:8443/lbVideo",
    env: 'dev',
    subdomainExtension: ".sarv.com",
  },
  {
    url: "dev.wave.sarv.com",
    token: "dhashJwt",
    apiURL: "https://a.dev.wave.sarv.com/",
    streamURL: "wss://kurento-wave.sarv.com:2083/lbVideo",
    env: 'dev',
    subdomainExtension: ".sarv.com",
  },
  {
    url: "beta-wave.sarv.com",
    token: "bhash",
    apiURL: "https://api-beta-wave.sarv.com/",
    streamURL: "wss://kurento-wave.sarv.com:8443/lbVideo",
    env: 'beta',
    subdomainExtension: ".sarv.com",
  },
  {
    url: "design.wave.sarv.com",
    token: "dashashJwt",
    apiURL: "https://a.dev.wave.sarv.com/",
    streamURL: "wss://kurento-wave.sarv.com:2083/lbVideo",
    env: 'dev',
    subdomainExtension: ".sarv.com",
  },
  {
    url: "eoffice-dev.sarv.com",
    token: "edhashJwt",
    apiURL: "https://a.dev.wave.sarv.com/",
    streamURL: "wss://kurento-wave.sarv.com:2083/lbVideo",
    env: 'dev',
    subdomainExtension: ".sarv.com",
  },
  {
    url: "localhost",
    token: "lhashJwt",
    apiURL: organization ==="divya" ? "https://api-divya.divya.gov.in/" : "https://a.dev.wave.sarv.com/",
    streamURL: "wss://kurento-wave.sarv.com:2083/lbVideo",
    env: 'dev',
    subdomainExtension: "localhost",
  },
  {
    url: "ooredoo.sarvwave.com",
    token: "ohashJwt",
    apiURL: "https://api-wave.sarv.com/",
    streamURL: "wss://kurento-wave.sarv.com:8443/lbVideo",
    env: 'dev',
    subdomainExtension: ".sarv.com",
  },
  {
    url: 'divya.gov.in',
    token: 'dghash',
    apiURL: 'https://api-divya.divya.gov.in/',
    streamURL: 'wss://kurento-wave.sarv.com:8443/lbVideo',
    env: 'live',
    subdomainExtension: ".divya.gov.in",
  },
  {
    url: 'divya-dev.sarv.com',
    token: 'ddvhash',
    apiURL: 'https://api-divya-dev.sarv.com/',
    streamURL: 'wss://kurento-wave.sarv.com:8443/lbVideo',
    env: 'beta',
    subdomainExtension: ".sarv.com",
  }
]


const currentURL = getServerURL();

export const passwordToBreak = 'ghftdrd.3der';

export const encDataKey = 'dsfsdfsd@@$$7876gfgffbdfgd@@33##';

export const myconfig = {
  //API URL Dev
  //apiURL : "https://a.dev.wave.sarv.com/",
  serverURL: currentURL.frontURL,
  apiURL: currentURL.apiURL,
  // apiURL: "https://api-beta-wave.sarv.com/",
  env: currentURL.env,

  // streamURL: currentURL.streamURL

  //API URL Live
  //apiURL : "https://a.wave.sarv.com/"
}

export const google_config = {
  //call_back_url: "http://localhost:3000/meetings",
  //call_back_url: "https://dev.wave.sarv.com/meetings"
  call_back_url: currentURL.frontURL + "/meetings",
  //gClientID: "260883182254-4tv682qir9k5q5ib92cemqfk25fcacad.apps.googleusercontent.com"
  gClientID: "912740304686-g0eve7ad5797400kvglvdt650hhe5o2g.apps.googleusercontent.com"
}


export const googleAuthURL = {
  authURL: "https://accounts.google.com/o/oauth2/v2/auth?scope=https://www.googleapis.com/auth/calendar&include_granted_scopes=true&response_type=token&state=name&redirect_uri=" + currentURL.frontURL + "/meetings&client_id=260883182254-ugha7u7kgds297ttd82eqghs3hi7nrfq.apps.googleusercontent.com"
}




