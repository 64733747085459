import { alertActions } from './';
import { homeService } from './../_services';
import { homeConstants } from './../_constants';

export const homeActions = {
    getHomeInfo
};

function getHomeInfo() {
    return dispatch => {
        dispatch(request({}));

        homeService.getHomeInfo()
            .then(
                resp => {
                    dispatch(success(resp))
                },
                error => {
                    dispatch(alertActions.error(error.toString()))
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(resp) { return { type: homeConstants.HOME_INFO_REQUEST } }
    function success(resp) { return { type: homeConstants.HOME_INFO_SUCCESS, resp } }
    function failure(error) { return { type: homeConstants.HOME_INFO_FAILURE, error } }
}
