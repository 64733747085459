export const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];

export const daysName = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"]

export const week = ['First', 'Second', "Third", 'Fourth', "Last"]

export const getDate = (date) => {
  const result = new Date(date);
  return `${result.getDate()} ${month[result.getMonth()]}, ${result.getFullYear()}`
}


export const csvToJSON = (csvText) => { //-< csv Text is fileReader readasText onLoad response
  let lines = [];
  const linesArray = csvText.split('\n');
  linesArray.forEach((e) => {
    const row = e.replace(/[\s]+[,]+|[,]+[\s]+/g, ',').trim();
    lines.push(row);
  });

  lines.splice(lines.length - 1, 1);
  const result = [];
  const headers = lines[0].split(",");

  for (let i = 1; i < lines.length; i++) {

    const obj = {};
    const currentline = lines[i].split(",");

    for (let j = 0; j < headers.length; j++) {
      obj[headers[j]] = currentline[j];
    }
    result.push(obj);
  }

  return result;
}