import { myconfig, siteTokens } from '../../config/config';
//import { authHeader } from '../_helpers';
import axios from 'axios';
import userconfigs from '../../helpers/closures'
import { generateToken } from '../../helpers/helpers';

import { errorCheck } from './common'
import { history } from '../_helpers';

export const conferenceServices = {
    createConference,
    meetingInfo,
    meetingBits,
    startMeeting,
    deleteInviteUser,
    meetingDetail,
    reInviteUser,
    addMoreParticipant,
    allowConference,
    logout,
    subMeetingList,
    updateMeeting,
    editSubMeeting,
    updateSingleMeet
};

function createConference(formData) {

    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };
    return axios.post(`${myconfig.apiURL}meeting/createMeet`, formData, axiosRequestOptions)
        .then(handleResponse)
        .then(response => {
            return response.meetingId;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
                // userconfigs.removeItem("jwt");
                // generateToken();
                // window.location.href = "/login";
            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}

function meetingInfo(meetingId) {

    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };
    const postData = { "meetingId": meetingId }
    return axios.post(`${myconfig.apiURL}meeting/getMeetingInfo`, postData, axiosRequestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
                // userconfigs.removeItem("jwt");
                // generateToken();
                // window.location.href = "/login";
            } else {
                if (error.response.status === 586) {
                    window.location.href = "/"
                } else {
                    return Promise.reject(errorCheck(error));
                }
            }
        });
}

function meetingBits(meetingId, isWaitingRoom) {

    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };
    const postData = { "meetingId": meetingId, "isWaitingRoom": isWaitingRoom }
    return axios.post(`${myconfig.apiURL}meeting/meetingBits`, postData, axiosRequestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
                // userconfigs.removeItem("jwt");
                // generateToken();
                // window.location.href = "/login";
            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}


// function startMeeting(meetingId, meetingType){
//     var data = JSON.stringify({"wid":"848-6212-800"});

//     var config = {
//     method: 'post',
//     url: 'https://a.dev.wave.sarv.com/webinar/startWebinar',
//     headers: {
//     'Authorization': 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6InNhcnYvV2F2ZUFwaSJ9.eyJlbWFpbCI6Im1haGVuZHJhLnRAc2Fydi5jb20iLCJtb2JpbGUiOiIiLCJ1c2VyX3R5cGUiOiJyZWd1bGFyIiwibmFtZSI6Ik1haGVuZHJhIFRyaXBhdGhpIiwidXVpZCI6Ijg3MzM1NzExNTlwUVoiLCJsb2dpblN0YXR1cyI6MSwibG9jYWxpcCI6IjEwMy4yNTUuMTAxLjEwMyIsImZpbmdlcnByaW50IjoiNzJkM2FhYWZiYzRlNzI2Y2NmMzNjMDU0ZmYxYmYyNmYiLCJkZXZUeXBlIjoiUEMiLCJyb29tIjoiKiIsImlhdCI6MTYxODQ4OTk3NCwiZXhwIjoxNjQ5NTkzOTc0LCJhdWQiOiJodHRwczovL3dhdmUuc2Fydi5jb20iLCJpc3MiOiJTYXJ2IFdhdmUiLCJzdWIiOiJzb21lQHVzZXIuY29tIn0.nOF-tc_Qggg3AOJ5vk8z6FeIcKSqbwl8KRyP7f7tH-pMRvREmpDJ-1ROSlvoXixJB1d-uX2OFt4BeKgpE3RiaA',
//     'Content-Type': 'application/json'
//     },
//     data : data
//     };

//     return axios(config)
//     .then(function (response) {
//     console.log(JSON.stringify(response.data));
//     return response;
//     })
//     .catch(function (error) {
//     console.log(error);
//     return error;
//     });


// }

function startMeeting(meetingId, meetingType) {

    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };

    let host = `${myconfig.apiURL}meeting/startMeet`;
    if (meetingType === "webinar") {
        host = `${myconfig.apiURL}webinar/startWebinar`;
    }

    let postData = { "meetingId": meetingId }
    if (meetingType === "webinar") {
        postData = JSON.stringify({ "wid": meetingId });
    }

    return axios.post(host, postData, axiosRequestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
                // userconfigs.removeItem("jwt");
                // generateToken();
                // window.location.href = "/login";
            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}



function deleteInviteUser(meetingId) {

    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };

    let postData = {
        'meetingId': meetingId,
    }
    return axios.post(`${myconfig.apiURL}calendar/removeParticipant`, postData, axiosRequestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
                // userconfigs.removeItem("jwt");
                // generateToken();
                // window.location.href = "/login";
            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}



function reInviteUser(meetingId, value) {

    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };

    let postData = {
        'meetingId': meetingId,
        'inviteeVal': value
    }
    return axios.post(`${myconfig.apiURL}meeting/reInviteUser`, postData, axiosRequestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
                // userconfigs.removeItem("jwt");
                // generateToken();
                // window.location.href = "/login";
            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}


function allowConference(meetingId, participantID) {

    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };

    let postData = {
        'meetingId': meetingId,
        'token': participantID
    }
    return axios.post(`${myconfig.apiURL}meeting/inviteeBitUpdate`, postData, axiosRequestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
                // userconfigs.removeItem("jwt");
                // generateToken();
                // window.location.href = "/login";
            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}

function addMoreParticipant(formData) {

    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };


    return axios.post(`${myconfig.apiURL}meeting/addMeetingParticipant`, formData, axiosRequestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
                // userconfigs.removeItem("jwt");
                // generateToken();
                // window.location.href = "/login";
            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}




function logout() {
    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };

    let logoutPost = {
        fingerprint: userconfigs.getItem('fp')
    }

    return axios.post(`${myconfig.apiURL}logout`, logoutPost, axiosRequestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            userconfigs.setItem('jwt', user.token);

            return user.token;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
                // userconfigs.removeItem("jwt");
                // generateToken();
                // window.location.href = "/login";
            } else {
                return Promise.reject(errorCheck(error));
            }
        });;
}

function handleResponse(response) {
    if (response.status !== 200) {
        if (response.status === 401) {
            logout();
        }
        if (response.status === 408) {
            userconfigs.removeItem("jwt");
            generateToken();
            window.location.href = "/login";
        }

        const error = (response && response.message) || response.statusText || response.error;
        return Promise.reject(error);
    }

    return response.data.result;
}


function meetingDetail(meetingId, mid) {

    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };

    let postData = {
        "meetingId": meetingId,
        mid,
    }
    return axios.post(`${myconfig.apiURL}meeting/getMeetingInfo`, postData, axiosRequestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
                // userconfigs.removeItem("jwt");
                // generateToken();
                // window.location.href = "/login";
            } else
                if (!error?.response?.status || error.response.status === 586) {
                    window.location.href = "/meeting_history";
                } else {
                    return Promise.reject(errorCheck(error));
                }
        });
}


function subMeetingList(meetingId) {
    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };

    let postData = {
        "meetingId": meetingId
    }
    return axios.post(`${myconfig.apiURL}meeting/getMeetingSummaryList`, postData, axiosRequestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
                // userconfigs.removeItem("jwt");
                // generateToken();
                // window.location.href = "/login";
            } else
                if (!error?.response?.status || error.response.status === 586) {
                    window.location.href = "/meeting_history";
                } else {
                    return Promise.reject(errorCheck(error));
                }
        });
}

function updateMeeting(postData) {
    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };

    return axios.post(`${myconfig.apiURL}calendar/updateMeeting/meta`, postData, axiosRequestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        })
        .catch((error) => {
            return Promise.reject(errorCheck(error));
        });
}


function editSubMeeting(postData) {
    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };

    return axios.post(`${myconfig.apiURL}calendar/updateMeeting`, postData, axiosRequestOptions)
        .then((resp) => handleResponse(resp))
        .then(response => {
            return response;
        })
        .catch((error) => {
            return Promise.reject(errorCheck(error));
        });
}

function updateSingleMeet(postData) {
    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };

    return axios.post(`${myconfig.apiURL}meeting/update-single-meeting`, postData, axiosRequestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        })
        .catch((error) => {
            return Promise.reject(errorCheck(error));
        });
}

function handleJWTError() {
    userconfigs.removeItem("jwt");
    generateToken();
    window.location.reload();
    // const currentHostName = window.location.hostname;
    // siteTokens.map(site=> {
    //     if(currentHostName === site.url){
    //         let token = site.token;
    //         //userconfigs.removeItem(token);
    //         console.log("ccccccccccccc",currentHostName, site)
    //         console.log("ccccccccccccc",token)

    //     }    
    //     return true;
    // })
}

