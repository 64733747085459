import { upgradeConstants } from './../_constants'

const initialState = {
  plans: [],
  addons: [],
  licences: [],
  plansLoading: false,
  licencesLoading: false,
  addonsLoading: false,
  paymentVerificationLoading: false,
  paymentVerified: false,
  assigned: false,
  assignedLoading: false
}

export function upgradeAccount(state = initialState, action) {
  switch (action.type) {
    case upgradeConstants.LIST_PLANS_REQUEST:
      return {
        ...state,
        plans: [],
        plansLoading: true
      }
    case upgradeConstants.LIST_PLANS_SUCCESS:
      return {
        ...state,
        plansLoading: false,
        plans: action.resp
      }
    case upgradeConstants.LIST_PLANS_FAILURE:
      return {
        ...state,
        plans: [],
        plansLoading: false
      }

    case upgradeConstants.LIST_LICENCE_REQUEST:
      return {
        ...state,
        licences: [],
        licencesLoading: true
      }
    case upgradeConstants.LIST_LICENCE_SUCCESS:
      return {
        ...state,
        licencesLoading: false,
        licences: action.resp
      }
    case upgradeConstants.LIST_LICENCE_FAILURE:
      return {
        ...state,
        licences: [],
        licencesLoading: false
      }

    case upgradeConstants.ASSIGN_LICENCE_REQUEST:
      return {
        ...state,
        assigned: false,
        assignedLoading: true
      }
    case upgradeConstants.ASSIGN_LICENCE_SUCCESS:
      return {
        ...state,
        assignedLoading: false,
        assigned: true
      }
    case upgradeConstants.ASSIGN_LICENCE_FAILURE:
      return {
        ...state,
        assigned: false,
        assignedLoading: false
      }

    case upgradeConstants.LIST_ADDONS_REQUEST:
      return {
        ...state,
        addons: [],
        addonsLoading: true
      }
    case upgradeConstants.LIST_ADDONS_SUCCESS:
      return {
        ...state,
        addonsLoading: false,
        addons: action.resp
      }
    case upgradeConstants.LIST_ADDONS_FAILURE:
      return {
        ...state,
        addons: [],
        addonsLoading: false
      }

    case upgradeConstants.PAYMENT_VERIFICATION_REQUEST:
      return {
        ...state,
        paymentVerified: false,
        paymentVerificationLoading: true
      }
    case upgradeConstants.PAYMENT_VERIFICATION_SUCCESS:
      return {
        ...state,
        paymentVerificationLoading: false,
        paymentVerified: true
      }
    case upgradeConstants.PAYMENT_VERIFICATION_FAILURE:
      return {
        ...state,
        paymentVerified: false,
        paymentVerificationLoading: false
      }

    default:
      return state
  }
}
