import React, { Component } from 'react';
import { CustomInput, Input, Form, FormGroup, Label, Button, Col, Row } from 'reactstrap';
import './CreateUserAccount.scss';
import "react-datepicker/dist/react-datepicker.css";
import AddMoreFieldForm from './components/AddMoreFieldForm/AddMoreFieldForm';
import LeftPanelManager from '../../../components/LeftPanelManager/LeftPanelManager';
import { teamActions, departmentActions, userActions, roleActions } from '../../../../Redux/_actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import validator from 'validator';
import { Multiselect } from 'multiselect-react-dropdown';
import PhoneInput from 'react-phone-input-2';
import MD5 from "crypto-js/md5";

class CreateUserAccount extends Component {

    constructor(props) {
        super(props);
        this.state = {
            createUserForm: {
                name: '',
                mobile: '',
                email: '',
                role: '',
                team: [],
                department: [],
                DOB: '',
                countryCode: '91',
                password_actual: MD5('fgfhgf@45gt').toString(),
                withoutHash: 'fgfhgf@45gt'

            },
            modal: false,
            selectedDept: [],
            selectedTeam: [],
            isAccountFormValid: false,
            user_uuid_id: this.props.match.params.id || null,
            isFormSubmitted: false,
            formError: {},
        }
    }

    async toggleModal() {
        await this.setState({ modal: !this.state.modal });
    }


    async handleUserInput(e) {
        const name = e.target.name;
        const value = e.target.value;
        const createUser = { ...this.state.createUserForm };
        createUser[name] = value;
        await this.setState({ ...this.state.createUserForm, createUserForm: createUser });
        await this.vallidateForm();

    }

    async vallidateForm() {
        if (this.state.isFormSubmitted) {
            let errorObj = {};
            const formData = this.state.createUserForm;
            if (formData.name === '') {
                errorObj.name = 'Please enter name';
            }

            if (formData.email === '') {
                errorObj.email = 'Please enter email';
            }
            if (formData.email !== '' && !validator.isEmail(formData.email)) {
                errorObj.email = 'Please enter valid email';
            }
            if (formData.mobile === '') {
                errorObj.mobile = 'Please enter contact number';
            }

            let formValid;
            if (Object.keys(errorObj).length === 0) {
                formValid = true;
            } else {
                formValid = false;
            }

            await this.setState({ formError: errorObj, isAccountFormValid: formValid });
        }
    }

    async setDOB(e) {
        const createUser = { ...this.state.createUserForm };
        createUser['DOB'] = e;
        await this.setState({ ...this.state.createUserForm, createUserForm: createUser });
    }

    componentDidUpdate = async (prevProps, prevState) => {
        if (prevProps.profileInfo !== this.props.profileInfo) {
            const data = { ...this.state.createUserForm };
            const { name, email, department, team, DOB, mobile, role, countryCode } = this.props.profileInfo;
            data['name'] = name || '';
            data['email'] = email || '';
            let deptArr = [];
            if (department !== undefined) {
                department.forEach(dept => {
                    let obj = {};
                    obj.department_name = dept.department_name || dept;
                    deptArr.push(obj);
                })
            }
            let teamArr = [];
            if (team !== undefined) {
                team.forEach(item => {
                    let obj = {};
                    obj.team_name = item.team_name || item;
                    teamArr.push(obj);
                })
            }

            data['department'] = department || [];
            data['team'] = team || [];
            data['DOB'] = DOB || '';
            data['mobile'] = mobile || '';
            data['role'] = role || '';
            data['countryCode'] = countryCode || '';
            await this.setState({ ...this.state.createUserForm, createUserForm: data });
            await this.setState({ ...this.state, selectedDept: deptArr });
            await this.setState({ ...this.state, selectedTeam: teamArr });
        }
    }

    componentDidMount() {
        this.props.getAllTeams();
        this.props.listDepartments();
        this.props.listRoles();
        if (this.state.user_uuid_id !== null) {
            this.props.userDetails(this.state.user_uuid_id)
        }
    }
    async submitUserAccount(event) {
        event.preventDefault();
        await this.setState({ isFormSubmitted: true });
        await this.vallidateForm();
        if (this.state.isAccountFormValid && this.state.isFormSubmitted) {
            if (this.state.user_uuid_id === null) {
                this.props.submitUserAccount(this.state.createUserForm);
            } else {
                this.props.editUserAccount(this.state.createUserForm, this.state.user_uuid_id);
            }

        }
    }

    onSelect = async (selectedList, selectedItem) => {
        const userData = { ...this.state.createUserForm };
        let obj = {};
        obj.team_id = selectedItem._id;
        obj.team_name = selectedItem.team_name;
        userData['team'].push(obj);
        await this.setState({ ...this.state.createUserForm, createUserForm: userData });
    }

    onRemove = async (selectedList, removedItem) => {
        const userData = { ...this.state.createUserForm };
        const data = userData['team'].filter(ele => ele.team_id !== removedItem._id);
        userData['team'] = data;
        await this.setState({ ...this.state.createUserForm, createUserForm: userData });
    }

    onSelectDept = async (selectedList, selectedItem) => {
        console.log(selectedItem)
        const userData = { ...this.state.createUserForm };
        let obj = {};
        obj.dept_id = selectedItem._id;
        obj.department_name = selectedItem.department_name;
        userData['department'].push(obj);
        await this.setState({ ...this.state.createUserForm, createUserForm: userData });
        console.log(this.state.createUserForm.department)
    }

    onRemoveDept = async (selectedList, removedItem) => {
        console.log(removedItem)
        const userData = { ...this.state.createUserForm };
        console.log(userData['department'])
        const data = userData['department'].filter(ele => ele.department_name !== removedItem.department_name);
        userData['department'] = data;
        await this.setState({ ...this.state.createUserForm, createUserForm: userData });

    }

    async mobileNumberChange(val, country) {
        const mobile = val.slice(country.dialCode.length);
        const code = country.dialCode;
        const userData = { ...this.state.createUserForm };
        userData['countryCode'] = code;
        userData['mobile'] = mobile;
        await this.setState({ ...this.state.createUserForm, createUserForm: userData });
        await this.vallidateForm();
    }

    importUserCsv = async (event) => {
        let formImageData = new FormData();
        formImageData.append('csv', event.target.files[0]);
        await this.props.uploadUserCsv(formImageData)
    }

    render() {
        return (
            <div className="createUserAccount">
                <LeftPanelManager></LeftPanelManager>
                <div className="pageContentpanel">
                    <div>
                        <AddMoreFieldForm
                            modal={this.state.modal}
                            showModal={() => this.toggleModal()}
                        />
                        <Col sm="12">
                            <Row>
                                <Col sm="6" className="pl-0">
                                    <h2 className="title">{this.state.user_uuid_id === null ? 'Create' : 'Update'} User Account </h2>
                                </Col>
                                <Col sm="6" className="text-right pr-0">
                                    <div className="custom_file_upload">
                                        <input type="file" id="brand_logo" onClick={(event) => { event.target.value = null }} className="brand_logo" onChange={this.importUserCsv} />
                                        <label for="brand_logo" className="btn-upload"><i className="fas fa-upload"></i> Upload CSV</label>
                                    </div>

                                    <Button className="btn btn-success btn-field" onClick={() => this.toggleModal()}>
                                        <i className="fas fa-plus-circle"></i> Add More Field
                                    </Button>
                                </Col>
                            </Row>


                        </Col>
                        <p className="sub-title">You could be a business, an event organizer, or a professional community. Please fill below detail.</p>
                        <Row className="formRow">
                            <Col sm="6" className="formColSection">
                                <Form onSubmit={(event) => this.submitUserAccount(event)}>
                                    <Row>
                                        <Col sm="6" style={{ paddingRight: '8px' }}>
                                            <FormGroup className="form-col">
                                                <Label className="title-label">Name</Label>
                                                <Input type="text" value={this.state.createUserForm.name} name="name" onChange={(e) => this.handleUserInput(e)} placeholder="Enter Your Name" />
                                            </FormGroup>
                                            {this.state.isFormSubmitted && this.state.formError.hasOwnProperty('name') && <div className="error text-danger" >{this.state.formError.name}</div>}
                                        </Col>

                                        <Col sm="6" style={{ paddingLeft: '8px' }}>
                                            <FormGroup className="form-col">
                                                <Label className="title-label">Contact Number</Label>
                                                <div className="dtl_input45">
                                                    <PhoneInput
                                                        value={this.state.createUserForm.countryCode + this.state.createUserForm.mobile}
                                                        inputClass="phone-input"
                                                        enableSearch={true}
                                                        placeholder="Mobile No"
                                                        countryCodeEditable={false}
                                                        onChange={(value, country) => this.mobileNumberChange(value, country)}

                                                    />
                                                </div>
                                            </FormGroup>
                                            {/* <FormGroup  className="form-col">
                                                <Label  className="title-label">Contact Number</Label>
                                                <Input type="number" name="mobile" onChange={(e) => this.handleUserInput(e)} placeholder="Enter Contact Number" />
                                            </FormGroup> */}
                                            {this.state.isFormSubmitted && this.state.formError.hasOwnProperty('mobile') && <div className="error text-danger">{this.state.formError.mobile}</div>}
                                        </Col>

                                        <Col sm="6" style={{ paddingRight: '8px' }}>
                                            <FormGroup className="form-col">
                                                {/* <Button  className="btn-sm btn-edit"  ><i  className="fas fa-pencil-alt"></i></Button> */}
                                                <Label className="title-label">Email</Label>
                                                <Input value={this.state.createUserForm.email} type="email" onChange={(e) => this.handleUserInput(e)} name="email" />
                                            </FormGroup>
                                            {this.state.isFormSubmitted && this.state.formError.hasOwnProperty('email') && <div className="error text-danger">{this.state.formError.email}</div>}
                                        </Col>

                                        <Col sm="6" style={{ paddingRight: '8px' }}>
                                            <FormGroup className="form-col">
                                                <Label className="title-label">Role</Label>
                                                <CustomInput type="select" value={this.state.createUserForm.role} onChange={(e) => this.handleUserInput(e)} name="role" >
                                                    <option value="">Select Role</option>
                                                    {this.props.roleList.map(role => (
                                                        <option value={role._id}>{role.role_name}</option>
                                                    ))}
                                                </CustomInput>
                                            </FormGroup>
                                        </Col>


                                        <Col sm="6" style={{ paddingRight: '8px' }}>
                                            <FormGroup className="form-col">
                                                <Label className="title-label">Team</Label>

                                                <Multiselect
                                                    selectedValues={this.state.selectedTeam}
                                                    options={this.props.teamData} // Options to display in the dropdown
                                                    onSelect={this.onSelect} // Function will trigger on select event
                                                    onRemove={this.onRemove} // Function will trigger on remove event
                                                    displayValue="team_name" // Property name to display in the dropdown options
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm="6" style={{ paddingRight: '8px' }}>
                                            <FormGroup className="form-col">
                                                <Label className="title-label">Department</Label>

                                                <Multiselect
                                                    selectedValues={this.state.selectedDept}
                                                    options={this.props.departmentList}
                                                    onSelect={this.onSelectDept}
                                                    onRemove={this.onRemoveDept}
                                                    displayValue="department_name"
                                                />
                                            </FormGroup>
                                        </Col>

                                        {/* <Col sm="6" style={{ paddingRight: '8px' }}>
                                            <FormGroup  className="form-col mb-0 form-datepicker">
                                                <Label  className="title-label">DOB</Label><br />
                                                <DatePicker
                                                    name="DOB"
                                                    selected={this.state.DOB}
                                                    onChange={(date) => this.setDOB(date)}
                                                    dateFormat="Pp"
                                                />
                                                <span  className="icon-date"><i  className="fas fa-calendar"></i></span>

                                            </FormGroup>
                                        </Col> */}


                                    </Row>

                                    <Col sm="12" className="text-center mt-5">
                                        {/* <Button  className="btn-off-dark mr-3" >Cancel</Button> */}
                                        <Button className="btn-theme-primary" type="submit">{this.state.user_uuid_id === null ? 'Create' : 'Update'}</Button>
                                    </Col>

                                </Form>
                            </Col>
                            <Col sm="6" className="img-section rgt-img-section">
                                <img src="../images/eoffice/create-team-img.svg" className="img-fluid" alt=""  ></img>

                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        )
    }


}


const mapState = (state) => {
    const { teamData } = state.teamsInfo;
    const { departmentList } = state.department;
    const { profileInfo } = state.authentication;
    const { roleList } = state.roles

    return {
        teamData,
        departmentList,
        profileInfo,
        roleList
    };
}

const actionCreators = {
    getAllTeams: teamActions.getAllTeams,
    listDepartments: departmentActions.listDepartments,
    submitUserAccount: userActions.createUserAccount,
    uploadUserCsv: userActions.uploadUserCsv,
    userDetails: userActions.userDetails,
    editUserAccount: userActions.editUserAccount,
    listRoles: roleActions.listRoles
};

export default withRouter(connect(mapState, actionCreators)(CreateUserAccount));
