import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import LoadingSpinner from "../../../components/UI/Spinner/Spinner";
import { webinarActions } from "../../../Redux/_actions";
import { confirmAlert } from "react-confirm-alert";
import ConfirmationPopup from "../../../components/UI/ConfirmationPopup/confirmationPopup";

class BrandingForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fileData: null,
      webinarID: null,
      brandingForm: {
        brand_name: {
          value: null,
          validation: {
            required: true,
          },
          error: null,
          valid: true,
        },
        brand_logo: {
          value: null,
          validation: {
            required: false,
            maxWidth: 400,
            minWidth: 200,
          },
          error: null,
          valid: true,
        },
        brand_banner: {
          value: null,
          validation: {
            required: false,
            maxWidth: 1100,
            minWidth: 600,
          },
          error: null,
          valid: true,
        },
      },
      isFormValid: false,
      isValidationShow: false,
      editBrandName: false,
      brandUpdated: false,
    };
  }

  componentDidMount = () => {
    const webinarID = this.props.match.params.id;
    this.setState({ webinarID: webinarID });

    this.props.webinarBrandFun(webinarID);
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      prevProps.updateBrand !== this.props.updateBrand ||
      prevProps.removedKey !== this.props.removedKey
    ) {
      const webinarID = this.props.match.params.id;
      this.setState({ webinarID: webinarID });
      this.props.webinarBrandFun(webinarID);
    }

    if (prevProps.deleteBrand !== this.props.deleteBrand) {
      const webinarID = this.props.match.params.id;
      let brandFrom = this.state.brandingForm;
      brandFrom["brand_name"].value = null;
      this.setState({ webinarID: webinarID, brandingForm: brandFrom });
      this.props.webinarBrandFun(webinarID);
    }
  };

  checkValidation = (label, name, value, rules, cbMain) => {
    let isValid = true;
    if (!rules) {
      return true;
    }

    if (
      (name === "brand_logo" || name === "brand_banner") &&
      typeof value !== "undefined"
    ) {
      let img = new Image();
      var imgwidth = 0;
      // var imgheight = 0;
      var _URL = window.URL || window.webkitURL;
      img.src = _URL.createObjectURL(value);

      if (
        value.type === "image/png" ||
        value.type === "image/jpeg" ||
        value.type === "image/jpg"
      ) {
        img.onload = function () {
          imgwidth = this.width;
          //imgheight = this.height;
          if (value.size > 1048576) {
            console.log("2");
            isValid = "Upload image size be less then 1 MB.";
          } else if (
            parseInt(imgwidth) > rules.maxWidth ||
            parseInt(imgwidth) < rules.minWidth
          ) {
            console.log("3");
            isValid =
              "Upload image width should " +
              rules.minWidth +
              "px to " +
              rules.maxWidth +
              "px";
          } else {
            console.log("4");
            isValid = true;
          }
          cbMain(isValid);
        };
      } else {
        isValid = "Upload Image should be jpeg,jpg or png.";
        cbMain(isValid);
      }
    } else {
      if (rules.required && value.trim() === "") {
        isValid = label + " is required field.";
      } else if (
        rules.minLength &&
        value.trim().length > 0 &&
        value.trim().length < rules.minLength
      ) {
        isValid =
          label + " must be at least " + rules.minLength + " characters long.";
      } else if (
        rules.maxLength &&
        value.trim().length > 0 &&
        value.trim().length > rules.maxLength
      ) {
        isValid =
          label + " can be max " + rules.maxLength + " characters long.";
      } else {
        isValid = true;
      }

      cbMain(isValid);
    }
  };

  onChangeHandler = (event) => {
    let self = this;

    const filedName = event.target.name;
    const filedLabel = event.target.title;
    let fieldValue = event.target.value;

    let oldBrandingForm = { ...this.state.brandingForm };

    let updateBrandingForm = { ...oldBrandingForm[filedName] };
    if (filedName === "brand_logo" || filedName === "brand_banner") {
      fieldValue = event.target.files[0];
      updateBrandingForm.value = fieldValue;
    } else {
      updateBrandingForm.value = fieldValue;
    }

    this.checkValidation(
      filedLabel,
      filedName,
      fieldValue,
      updateBrandingForm.validation,
      function (checkValidation) {
        if (checkValidation === true) {
          updateBrandingForm.valid = true;
          updateBrandingForm.error = null;
        } else {
          updateBrandingForm.valid = false;
          updateBrandingForm.error = checkValidation;
        }

        oldBrandingForm[filedName] = updateBrandingForm;

        let isValidForm = true;
        for (let key in oldBrandingForm) {
          isValidForm = oldBrandingForm[key].valid && isValidForm;
        }

        self.setState({
          brandingForm: oldBrandingForm,
          isFormValid: isValidForm,
        });

        if (
          isValidForm === true &&
          (filedName === "brand_logo" || filedName === "brand_banner")
        ) {
          self.submitBrandingForm(filedName, fieldValue, isValidForm);
        }
      }
    );
  };

  editFields = (fieldType) => {
    if (!this.state.editBrandName) {
      this.setState({ editBrandName: true });
    }
  };

  deleteWebinarBrandConfirm = (meetingID) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmationPopup
            message={"Are you sure want to delete this webinar brand ?"}
            noBtnAction={onClose}
            noBtnText="No"
            yesBtnText="Yes, Delete AnyWay!"
            yesBtnAction={() => this.props.deletewebinarBrand(meetingID)}
          />
        );
      },
    });
  };

  submitBrandingForm = (fieldType, fieldValue, isValidForm) => {
    this.setState({ editBrandName: false });
    isValidForm = isValidForm || this.state.isFormValid;
    if (isValidForm) {
      if (fieldType === "brand_name") {
        let fieldSetValue = this.state.brandingForm[fieldType].value || null;

        if (fieldSetValue === null) {
          let brandingForm = this.state.brandingForm;
          brandingForm["brand_name"].error = "Brand Name is required field.";
          this.setState({ brandingForm: brandingForm, isValidationShow: true });
        } else {
          this.setState({ isValidationShow: false });
        }

        let formData = {};
        formData.webinarID = this.state.webinarID;
        formData[fieldType] = fieldSetValue;

        this.props.brandingFormFun("text", formData);
      } else if (fieldType === "brand_logo" || fieldType === "brand_banner") {
        let fieldSetValue = fieldValue || null;
        let formImageData = new FormData();
        formImageData.append("webinarID", this.state.webinarID);
        formImageData.append(fieldType, fieldSetValue);

        this.props.brandingFormFun("images", formImageData);
      }
    } else {
      if (fieldType === "brand_name") {
        // let fieldSetValue = this.state.brandingForm[fieldType].value || null;
        let brandingForm = this.state.brandingForm;
        brandingForm["brand_name"].error = "Brand Name is required field.";
        this.setState({ brandingForm: brandingForm, isValidationShow: true });
      } else {
        this.setState({ isValidationShow: true });
      }
    }
  };

  render() {
    const { brandingForm, isValidationShow, editBrandName, webinarID } =
      this.state;
    const {
      brandLoading,
      brandError,
      brandDeatail,
      // addBrandLoading,
      removeWebinarBrand,
    } = this.props;

    let brandName = null;
    let brandLogo = null;
    let brandBanner = null;

    if (brandDeatail !== null && brandDeatail.brand_logo) {
      brandLogo = (
        <div className="brand_logo_img bg-light text-center">
          {" "}
          <img
            src={brandDeatail.brand_logo}
            alt="brand_logo"
            width="120"
          />{" "}
          {editBrandName ? (
            <button
              className="remove_brand_img"
              onClick={() => removeWebinarBrand(webinarID, "brand_logo")}
            >
              <i className="fas fa-trash-alt"></i>
            </button>
          ) : (
            ""
          )}{" "}
        </div>
      );
    }

    if (brandDeatail !== null && brandDeatail.brand_banner) {
      brandBanner = (
        <div className="brand_logo_img w-100 text-center">
          {" "}
          <img
            src={brandDeatail.brand_banner}
            alt="brand_banner"
            width="100%"
            height="200px"
          />{" "}
          {editBrandName ? (
            <button
              className="remove_brand_img"
              onClick={() => removeWebinarBrand(webinarID, "brand_banner")}
            >
              <i className="fas fa-trash-alt"></i>
            </button>
          ) : (
            ""
          )}{" "}
        </div>
      );
    }

    if (
      brandingForm.brand_name["value"] ||
      brandingForm.brand_name["value"] === ""
    ) {
      brandName = brandingForm.brand_name["value"];
    } else if (brandDeatail !== null && brandDeatail.brand_name) {
      brandName = brandDeatail.brand_name;
    }
    return (
      <>
        <div className="col-lg-12">
          <h5 className="sc_invt_hdng">
            {!editBrandName && brandDeatail?.brand_name ? (
              <button
                className="submitBtnColor border-0 headerBtn editBtn mb-3 text-white"
                style={{
                  width: "100px",
                  height: "40px",
                }}
                onClick={() => this.editFields("brand_name")}
              >
                <i className="fas fa-edit" />
                Edit{" "}
              </button>
            ) : (
              <button
                className="submitBtnColor border-0 headerBtn editBtn mb-3 text-white"
                style={{
                  width: "100px",
                  height: "40px",
                }}
                onClick={() =>
                  this.submitBrandingForm("brand_name", null, null)
                }
              >
                Done
              </button>
            )}
            {/* {brandDeatail !== null ? (
              <button
                className="remove_brand_img d-none"
                onClick={() => this.deleteWebinarBrandConfirm(webinarID)}
              >
                <i className="fas fa-trash-alt"></i>Delete Brand
              </button>
            ) : null} */}
          </h5>
        </div>

        <div className="Sc_m_wave_s_sharing_d">
          <div className="brand_info_form">
            {brandLoading && !brandError ? (
              <div className="text-center">
                <LoadingSpinner />
              </div>
            ) : (
              <>
                <div className="row">
                  {brandDeatail !== null &&
                    brandDeatail.brand_name &&
                    !editBrandName ? (
                    <>
                      <div className="col-lg-6 pl-4">
                        <div className="form-group">
                          <label> Name</label>
                          <div className=" welcomeMsg">
                            {brandDeatail.brand_name}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-lg-6 pl-4">
                        <div className="form-group brand_name_edit">
                          <label>Name</label>
                          <input
                            type="text"
                            value={brandName}
                            title="Brand Name"
                            className={
                              isValidationShow &&
                                !brandingForm["brand_name"].valid
                                ? "hasError form-control"
                                : "form-control"
                            }
                            name="brand_name"
                            onChange={(event) => this.onChangeHandler(event)}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  <div className="col-md-6 form-group  custom_file float-right text-right pr-5 brandLogoSection">
                    <label className="brandLogo" >Logo</label>
                    {brandDeatail?.brand_logo ? (
                      brandLogo
                    ) : (
                      <>
                        <input
                          type="file"
                          id="brand_logo"
                          className={
                            isValidationShow &&
                              !brandingForm["brand_logo"].valid
                              ? "hasError"
                              : ""
                          }
                          name="brand_logo"
                          onChange={(event) => this.onChangeHandler(event)}
                        />
                        <label
                          for="brand_logo"
                          className="sideBarIcon  btn-2 w-50 p-3"
                        >
                          <i className="fas fa-upload"></i> Upload Logo
                        </label>
                      </>
                    )}
                    {!brandingForm.brand_logo.valid ?
                      <small className="form-text text-danger">
                        {brandingForm.brand_logo.error}
                      </small>
                      : ""
                    }
                  </div>
                </div>

                <div className="col-md-12 form-group custom_file ">
                  <label>Banner</label>
                  {brandDeatail?.brand_banner ? (
                    brandBanner
                  ) : (
                    <>
                      <input
                        type="file"
                        id="brand_banner"
                        className={
                          isValidationShow &&
                            !brandingForm["brand_banner"].valid
                            ? "hasError"
                            : "brand_banner"
                        }
                        name="brand_banner"
                        onChange={(event) => this.onChangeHandler(event)}
                      />
                      <label
                        for="brand_banner"
                        className="sideBarIcon  btn-3 pt-5"
                      >
                        <i className="fas fa-upload"></i> Upload Banner
                      </label>
                    </>
                  )}
                  {!brandingForm.brand_banner.valid ?
                    <small className="form-text text-danger">
                      Image width should be 600px to 1100px
                    </small>
                    : ""
                  }
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    brandLoading: state.webinar.brandLoading,
    brandError: state.webinar.brandError,
    brandDeatail: state.webinar.brandDeatail,
    addBrandLoading: state.webinar.addBrandLoading,
    updateBrand: state.webinar.updateBrand,
    removedKey: state.webinar.removedKey,
    deleteBrand: state.webinar.deleteBrand,
  };
};

const actionCreators = {
  brandingFormFun: webinarActions.addBranding,
  webinarBrandFun: webinarActions.webinarBrand,
  removeWebinarBrand: webinarActions.removeWebinarBrand,
  deletewebinarBrand: webinarActions.deletewebinarBrand,
};

export default withRouter(
  connect(mapStateToProps, actionCreators)(BrandingForm)
);
