import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Button, Modal } from 'react-bootstrap';
import { FaUserAlt } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Formik } from 'formik'
import jwt_decode from "jwt-decode";
import * as Yup from 'yup';

import { alertActions, themeActions } from '../../Redux/_actions';
import styles from './styles.module.scss'
import { userService } from '../../Redux/_services';
import LoadSpinner from '../../components/UI/Spinner/Spinner';
import userconfigs from '../../helpers/closures';
import Images from '../../images';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const fieldTypes = [
  "Text", //-> Input Text Type
  "Checkbox", //-> Input Checkbox Type
  "Radio", //-> Input Radio Type
  "List", //-> Input Select Type
];

try {
  var userInfo = jwt_decode(userconfigs.getItem('jwt'));
} catch (e) {

}

const InputSection = ({ item, index, values, setFieldValue, errors, touched }) => {
  switch (item.type) {
    case fieldTypes[0]: {
      return (
        < div className='mb-2'>
          <Form.Label className="ml-2 welcomeMsg text-capitalize ">
            {item.label}{item?.isRequired && <span className='text-danger'>*</span>}
          </Form.Label>
          <Form.Control
            type="email"
            placeholder={`Enter your ${item.label}`}
            className="form-control w-100 inputColorTheme"
            value={values[item.label]}
            onChange={(event) => setFieldValue(item.label, event.target.value)}
            maxLength={item?.label === "Phone" && 10}
          />
          {touched?.hasOwnProperty(item?.label) && errors.hasOwnProperty(item?.label) && (
            <div className="error text-danger f_size_13">{errors[item?.label]}</div>
          )}
        </div>
      );
    }
    case fieldTypes[1]: {
      return (
        <>
          <Form.Label column className="welcomeMsg text-capitalize">
            {item.label}{item?.isRequired && <span className='text-danger'>*</span>}
          </Form.Label>
          <div className="d-flex align-items-center">
            {item.option?.map((data) => (
              <>
                <Form.Control type="checkbox" className="w-auto h-auto ml-2" value={values[`${item.label}.${data.label}`]} onChange={() => setFieldValue(`${item.label}.${data.label}`, !values[item.label][data.label])} />
                <Form.Label className="ml-2 mb-0 welcomeMsg text-capitalize">
                  {data.label}
                </Form.Label>
              </>
            ))}
          </div>
        </>
      );
    }
    case fieldTypes[2]: {
      return (
        <>
          <Form.Label column className="welcomeMsg text-capitalize">
            {item.label}{item?.isRequired && <span className='text-danger'>*</span>}
          </Form.Label>
          <div className="d-flex">
            {item.option?.map((data) => (
              <Form.Check
                type="radio"
                label={data.label}
                name="formHorizontalRadios"
                id="formHorizontalRadios1"
                value={values[data.label]}
                key={`option-radio-${data.label}`}
                className="welcomeMsg ml-2"
                onChange={() => setFieldValue(item.label, data.label)}
              />
            ))}
          </div>
        </>
      );
    }
    case fieldTypes[3]: {
      return (
        <>
          <Form.Label column className="welcomeMsg text-capitalize">
            {item.label}{item?.isRequired && <span className='text-danger'>*</span>}
          </Form.Label>
          <div>
            <select type="select" className="selectSection" value={values[item.label]} onChange={(event) => setFieldValue(item.label, event.target.value)} >
              {item.option?.map((data) => (
                <option
                  key={`option-select-${data.label}`}
                  className="text-capitalize"
                  value={data.label}
                >
                  {data.label}
                </option>
              ))}
            </select>
          </div>
        </>
      );
    }
    default: {
      return null;
    }
  }
};

const getInitialValues = (value) => {
  if (!value) {
    return {}
  }
  const result = {};
  for (let i = 0; i < value.length; i++) {
    const data = value[i]
    switch (data?.type) {
      case fieldTypes[0]: {
        const label = data?.label === "Phone" ? 'mobile' : data?.label
        if (userInfo && userInfo[label?.toLowerCase()]) {
          result[data?.label] = userInfo[label?.toLowerCase()];
        } else {
          result[data?.label] = "";
        }

        break;
      }
      case fieldTypes[1]: {
        result[data?.label] = {}
        for (let j = 0; j < data?.option?.length; j++) {
          const item = { [data?.option[j]?.label]: false }
          result[data?.label] = Object.assign(result[data?.label], item);
        }
        break;
      }
      case fieldTypes[2]: case fieldTypes[3]: {
        result[data?.label] = data?.option[0]?.label;
        break;
      }
      default: {
        result[data?.label] = "";
        break;
      }
    }
  }
  return result
}

const validation = Yup.object().shape({
  Name: Yup.string().required('it request!'),
  Email: Yup.string().email().required('it request!'),
  Phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('it request!'),
})

const Registration = (props) => {
  const [state, setState] = useState({})
  const [isLoaded, setIsLoaded] = useState(false)
  const [isSubmit, setIsSubmit] = useState(false)
  const dispatch = useDispatch();
  const { theme } = useSelector((state) => state.themeChange);

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    setIsLoaded(true);
    var value = queryString.parse(props.location.search);
    if (!value?.mid) {
      props.history.push('/')
    }
    try {
      const response = await userService.getFormData(value?.mid);
      if (!response.only_registered) {
        props.history.push(`/join_meeting?mid=${value?.mid}`)
      }
      response.meetId = value?.mid
      setState(response)
      setIsLoaded(false)
    } catch (error) {
      dispatch(alertActions.error(error.toString()));
      props.history.push('/')
    }

  }


  const submitData = async (value) => {
    const params = {
      email: value.Email,
      meetingID: state?.meetId,
      phone: value.Phone,
      name: value.Name,
      customData: value
    }

    try {
      const response = await userService.submitMeetRegForm(params)
      dispatch(alertActions.success('User is registered !'))
      setIsSubmit(true)
    } catch (error) {
      dispatch(alertActions.error(error.toString()))
    }

  }

  if (isLoaded || !state) {
    return <div className='text-center'>
      <LoadSpinner />
    </div>
  } else {
    return (
      <div className={styles.registrationSection}>
        <Col lg={8}  >
          <div className='p-5 mt-3'>
            <Formik
              initialValues={getInitialValues(state?.regCustomFields)}
              onSubmit={(values) => submitData(values)}
              validationSchema={validation}
            >
              {
                ({ values, setFieldValue, handleSubmit, errors, touched }) => (
                  <div className={styles.maxH100}>
                    <PerfectScrollbar>
                      {state?.regCustomFields?.map((data, index) => <InputSection item={data} setFieldValue={setFieldValue} values={values} index={index} errors={errors} touched={touched} />)}
                      <div className='text-center mt-5'>
                        <button className={`${styles.submitBtn} submitBtnColor`} onClick={() => handleSubmit()}> Register </button>
                      </div>
                    </PerfectScrollbar>
                  </div>
                )
              }
            </Formik>
          </div>
        </Col>
        <Col lg={4} className={`sideBar p-0`}>
          <div className={`${styles.leftSideHeader} bottomBorder`}>
            <img
              src={theme ? Images.logoImgWhiteTheme : Images.logoImgBlackTheme}
              alt="logo"
              width={100}
            />
            <div className='d-flex' >
              {theme ? (
                <button
                  className="text-light btn-link btn"
                  onClick={() => dispatch(themeActions.changeThemeColor())}
                >
                  <i className="fas fa-moon"></i>
                </button>
              ) : (
                <button
                  className="sunColor btn-link btn"
                  onClick={() => dispatch(themeActions.changeThemeColor())}
                >
                  <i className="fas fa-sun"></i>
                </button>
              )}
              <div className={`${styles.avatar} welcomeMsg`}>
                <FaUserAlt className='welcomeMsg' />
              </div>
            </div>
          </div>
          <div className='p-4'>
            <div>
              <div className='text-muted '> Meeting Name </div>
              <div className='welcomeMsg' >{state?.name}</div>
            </div>
            <div className='mt-3'>
              <div className='text-muted '> Meeting ID </div>
              <div className='welcomeMsg' >{state?.meetId}</div>
            </div>
            {state?.end_date && state?.start_date &&
              <Row className='mt-3'>
                <Col className='border-right welcomeMsg' lg={4} >
                  <div> <div><i className="far fa-calendar"></i> {state?.start_date}</div> <div className='mt-1' ><i className="far fa-clock "></i> {state?.start_time} </div> </div>
                </Col>
                <Col className='welcomeMsg' lg={4}>
                  <div> <div><i className="far fa-calendar"></i> {state?.end_date}</div> <div className='mt-1' ><i className="far fa-clock "></i> {state?.end_time} </div> </div>
                </Col>
              </Row>
            }

            <div className='mt-3'>
              <div className='text-muted '> Host Name </div>
              <div className='welcomeMsg' >{state?.moderatorName}</div>
            </div>

            {state?.description &&
              <div className='mt-3'>
                <div className='text-muted '> Description </div>
                <div className="welcomeMsg  mh75 f_size_15">
                  <PerfectScrollbar>
                    {state?.description}
                  </PerfectScrollbar>
                </div>
              </div>}

            <div className="text-right">
              <img
                src="../../images/registerPage.png"
                alt="logo"
                width={250}
              />
            </div>
            {isSubmit && <Modal show={isSubmit} contentClassName="sideBar">
              <Modal.Body className='text-center'>
                <h2 className='welcomeMsg'> Thank You! </h2>
                <div className='welcomeMsg'>your form is submitted.you will receive a confirmation email. </div>
              </Modal.Body>
              <Modal.Footer className='justify-content-center'>
                <Button className='w-50' onClick={() => props.history.push('/')} >Ok</Button>
              </Modal.Footer>
            </Modal>}
          </div>
        </Col>
      </div>
    )

  }
}

export default Registration