import { accountConstants } from '../_constants';
import { accountService } from '../_services';
import { alertActions } from './';

export const accountActions = {
    meetingsCount,
    sharedFiles,
    fileShare,
    deleteSharedFiles,
    attachFile
};

function meetingsCount() {
    return dispatch => {
        dispatch(request({}));

        accountService.meetingsCount()
            .then(
                resp => {
                    dispatch(success(resp))
                    //console.log("actions",resp)
                },
                error => dispatch(alertActions.error(error.toString()))
            );
    };

    function request(resp) { return { type: accountConstants.ACCOUNT_REQUEST} }
    function success(resp) { return { type: accountConstants.ACCOUNT_SUCCESS, resp } }
}


function sharedFiles(limit) {
    return dispatch => {
        dispatch(request({}));

        accountService.sharedFiles(limit)
            .then(
                resp => {
                    dispatch(success(resp))
                },
                error => dispatch(alertActions.error(error.toString()))
            );
    };

    function request(resp) { return { type: accountConstants.SHARED_REQUEST} }
    function success(resp) { return { type: accountConstants.SHARED_SUCCESS, resp } }
}



function deleteSharedFiles(sharedFileId) {
    return dispatch => {
        dispatch(request({}));

        accountService.deleteSharedFiles(sharedFileId)
            .then(
                resp => {
                    dispatch(success(resp))
                },
                error => {
                    dispatch(alertActions.error(error.toString()))
                }
            );
    };

    function request(resp) { return { type: accountConstants.DELETE_SHARED_REQUEST} }
    function success(resp) { return { type: accountConstants.DELETE_SHARED_SUCCESS } }
}


function fileShare(path) {
    return dispatch => {
        accountService.fileShare(path)
        .then(
            url => {
                dispatch(success(url))
            },
            error => dispatch(alertActions.error(error.toString()))
        );
    };

    function success(url) { return { type: accountConstants.FILE_SHARE_SUCCESS, url}}
}

function attachFile(path) {
    return dispatch => {
        accountService.attachFile(path)
        .then(
            resp => {
                dispatch(success(resp))
                if(typeof resp !== "undefined"){
                    dispatch(alertActions.success(resp.toString()));
                }
            },
            error => dispatch(alertActions.error(error.toString()))
        );
    };

    function success() { return { type: accountConstants.ATTACH_FILE_SUCCESS}}
}



