import React from 'react';

//import { Spinner } from 'reactstrap';

function LoadSpinner(props) {
	return (
		<div>
			{/*<div className="d-flex justify-content-center mt-5">
				"Loading..."
			</div>*/}

			<div className="col-sm-12">

				{/* <img src={process.env.PUBLIC_URL + "/images/loading.svg"} width="300px" height="140px" alt="loader" /> */}
			</div>
		</div>
	)
}

export default LoadSpinner;