import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import './UserProfile.scss';
import LeftPanelManager from '../../components/LeftPanelManager/LeftPanelManager';
import ProfileHeader from './component/ProfileHeader';
import UserProfileForm from './component/UserProfileForm';
import { userActions } from './../../../Redux/_actions';
import { connect } from 'react-redux';
import {  withRouter } from 'react-router-dom';



class UserProfile extends Component {
    // const [startDate, setStartDate] = useState(new Date());
    // const [modal, setModal] = useState(false);
    // const showModal = () => setModal(!modal);

    constructor(props) {
		super(props);
		this.state = {
			userInfo:{},
		}
    }

    componentDidMount = () => {
		this.props.userDetails()
    }
    
    componentDidUpdate = (prevProps, prevState) => {
        if(prevProps.profileInfo !== this.props.profileInfo){
            this.setState({userInfo:this.props.profileInfo})
        }
    }

    render(){


        return (
            <div  className="UserProfile">
                <LeftPanelManager></LeftPanelManager>
                <div  className="pageContentpanel">
                    <div>
                        <Col sm="12">
                            <Row >
                                <Col sm="6"  className="pl-0">
                                    <h2  className="title">Profile </h2>
                                </Col>
                                {/* <Col sm="6"  className="text-right pr-0">
                                    <Button  className="btn btn-theme btn-field">
                                        <i className="fas fa-pencil-alt"></i> Edit Profile
                                     </Button>
                                </Col> */}
                            </Row>
    
                        </Col>
    
                        <Col sm="12">
                            <ProfileHeader userData={this.state.userInfo}/>
                        </Col>
                       
                        <Row  className="formRow">
                            <Col sm="6"  className="formColSection">
                                <UserProfileForm userData={this.state.userInfo} />
                            </Col>
                            <Col sm="6"  className="img-section rgt-img-section">
                                <img src="../images/eoffice/create-team-img.svg"  className="img-fluid" alt=""  ></img>
    
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        )

    }

    
}


const mapState = (state) => {
	const { profileInfo } = state.authentication;
	return {
		profileInfo,
	}
}

const actionCreators = {
	userDetails: userActions.userDetails
};

export default withRouter(connect(mapState, actionCreators)(UserProfile));

