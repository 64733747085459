import React, { Component } from 'react';
import { Link, withRouter, Redirect } from 'react-router-dom';
import MD5 from "crypto-js/md5";
import { Spinner } from 'reactstrap';
import { connect } from 'react-redux';
import decode from 'jwt-decode';

import userconfigs from '../helpers/closures';
import { userActions } from '../Redux/_actions';
import Header from '../layoutSection/header';

import 'react-phone-input-2/lib/style.css'




class ChangePassword extends Component {

	constructor(props) {
		super(props);
		this.state = {
			fields: {
				oldPassword: undefined,
				newPassword: undefined,
				confirmPassword: undefined
			},
			isSubmitted: false,
			errors: {}
		}

		this.handleChange = this.handleChange.bind(this);
	}

	componentWillMount = () => {
		let webUser = decode(userconfigs.getItem('jwt'))
		if (webUser.loginStatus !== 1) {
			this.props.history.push('/login')
		}
	}


	componentDidUpdate = (prevProps, prevState) => {
		if (prevProps.changed !== this.props.changed) {
			window.location.href = "/profile"
		}
	}

	handleChange(e) {
		const { name, value } = e.target;
		let fields = this.state.fields;
		fields[name] = value;
		this.setState({ fields });
	}

	handleValidation() {
		let fields = this.state.fields;
		let errors = {};
		let formIsValid = true;

		//Old Password
		if (!fields['oldPassword']) {
			formIsValid = false;
			errors["oldPassword"] = "Please enter the current password!";
		}

		//Password
		if (!fields["newPassword"]) {
			formIsValid = false;
			errors["newPassword"] = "Please enter the new password!";
		}
		else if (fields["newPassword"].length < 6) {
			formIsValid = false;
			errors["newPassword"] = "Password should be atlease 6 charater long!";
		} else if (fields["confirmPassword"] !== fields["newPassword"]) {
			formIsValid = false;
			errors["confirmPassword"] = "Confirm password should be same as Password!";
		}

		this.setState({ errors: errors });
		return formIsValid;
	}

	passwordSubmit(e) {
		e.preventDefault();
		this.setState({ isSubmitted: true })
		if (this.handleValidation()) {

			let fields = this.state.fields;

			var oldPassword = MD5(fields.oldPassword).toString();

			// 	var oldcipher = crypto.createCipher('aes-256-cbc', "379f93ba5733057e837b92a6ea810342");
			// var oldPassword = oldcipher.update(fields.oldPassword, 'utf8', 'hex');
			// oldPassword += oldcipher.final('hex');

			var newPassword = MD5(fields.newPassword).toString();

			//     var oldcipher = MD5(oldPassword).toString()
			//     var newcipher = crypto.createCipher('aes-256-cbc', "379f93ba5733057e837b92a6ea810342");
			//     var newPassword = newcipher.update(fields.newPassword, 'utf8', 'hex');
			// newPassword += newcipher.final('hex');

			var confirmPassword = MD5(fields.confirmPassword).toString();
			// var confcipher = crypto.createCipher('aes-256-cbc', "379f93ba5733057e837b92a6ea810342");
			// var confirmPassword = confcipher.update(fields.confirmPassword, 'utf8', 'hex');
			//     	confirmPassword += confcipher.final('hex');

			fields.oldPassword = oldPassword;
			fields.newPassword = newPassword;
			fields.confirmPassword = confirmPassword;

			this.props.changePassword(fields)
		}
	}

	render() {
		const { fields, errors, isSubmitted } = this.state;
		const { changeLoading } = this.props;

		return (
			<div>
				<Header goBack title="Change Password" />
				<div  className="container-fluid s_wave_content_part_schdule profile_page_sec pt-0">
					<div  className="container user_profile_page">
						<div  className="row mt-4">
							<div  className="col-md-6">
								<div  className="middle_6h_bx mt-3">
									<div  className="row">
										<div  className="col-sm-8">
											<span  className="text_milky f_size_20"> <i  className="fas fa-edit mr-1"></i>   Change Password  </span>
										</div>
										<div  className="col-sm-4 text-right action_btn86">
											<Link to="/profile"  className="btn btn-warning custom_btn btn-sm f_size_12"><i  className="fas fa-user-circle mr-1"></i> Profile</Link>
										</div>
									</div>
									<hr />

									<div  className="profile_udeatil">
										<form onSubmit={this.passwordSubmit.bind(this)}>
											<ul>
												<li>
													<label>Old Password</label>
													<div  className={"dtl_input45" + (isSubmitted && !fields.oldPassword ? ' has-error' : '')}>
														<input type="password" onChange={this.handleChange} value={fields.oldPassword} name="oldPassword" />
														{errors['oldPassword'] ? <span  className="help-block error_msg_56">{errors['oldPassword']}</span> : ""}
													</div>
												</li>

												<li>
													<label>New Password</label>
													<div  className={"dtl_input45" + (isSubmitted && !fields.newPassword ? ' has-error' : '')}>
														<input type="password" onChange={this.handleChange} value={fields.newPassword} name="newPassword" />
														{errors['newPassword'] ? <span  className="help-block error_msg_56">{errors['newPassword']}</span> : ""}
													</div>
												</li>

												<li>
													<label>Confirm Password</label>
													<div  className={"dtl_input45" + (isSubmitted && !fields.confirmPassword ? ' has-error' : '')}>
														<input type="password" onChange={this.handleChange} value={fields.confirmPassword} name="confirmPassword" />
														{errors['confirmPassword'] ? <span  className="help-block error_msg_56">{errors['confirmPassword']}</span> : ""}
													</div>
												</li>

												<li  className="text-center">
													{changeLoading ?
														<button type="button" className="btn btn-primary custom_btn f_size_12 mt-3"><Spinner size="sm" color="light" />Updating...</button>
														:
														<button className="btn btn-primary custom_btn f_size_12 mt-3">Change Password</button>
													}
												</li>

											</ul>
										</form>
									</div>

								</div>
							</div>

							<div  className="col-md-6 mt-3 text-center profile_page_img">
								<img src="images/image_agnt_prfl.png" />
							</div>
						</div>
					</div>


				</div>
			</div>
		);

	}
}


const mapState = (state) => {
	const { changeLoading, changed } = state.authentication;
	return {
		changeLoading,
		changed
	}
}

const actionCreators = {
	changePassword: userActions.changePassword
};


export default withRouter(connect(mapState, actionCreators)(ChangePassword));