import React, { useState } from 'react'
import { Row, Col } from 'react-bootstrap';
import { FaCheck } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import moment from 'moment';
// import isEmpty from 'is-empty';


import Layout from '../../../layoutSection';
import StepFirst from './stepFirst';
import StepTwo from './stepTwo';
import StepThree from './stepThree';
import styles from './styles.module.scss'
import { scheduleActions } from '../../../Redux/_actions';

const ScheduleMeeting = (props) => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({})
  const dispatch = useDispatch();

  const submitData = (value) => {
    const startOn = new Date(formData.startOn)
    const start_date = `${("0" + startOn.getDate()).slice(-2)}-${("0" + (startOn.getMonth() + 1)).slice(-2)}-${startOn.getFullYear()}`;
    const start_time = `${("0" + startOn.getHours()).slice(-2)}:${("0" + startOn.getMinutes()).slice(-2)}`;
    const endTime = new Date(startOn.getFullYear(), startOn.getMonth(), startOn.getDate(), (startOn.getHours() + parseInt(formData.duration.hr)), (startOn.getMinutes() + parseInt(formData.duration.min)))
    const end_date = `${("0" + endTime.getDate()).slice(-2)}-${("0" + (endTime.getMonth() + 1)).slice(-2)}-${endTime.getFullYear()}`
    const end_time = `${("0" + endTime.getHours()).slice(-2)}:${("0" + endTime.getMinutes()).slice(-2)}`;

    const formSubmit = {
      meetName: formData.title,
      description: formData.description,
      start_date,
      start_time,
      startTS: startOn.getTime(),
      endTS: endTime.getTime(),
      scheduleDuration: formData.duration,
      end_date,
      end_time,
      form_type: "advance",
      startTimeStampUTC: moment.utc(start_date).unix(),
      reCurring: formData.reCurring,
      reCurringType: formData.reCurringType || false,
      join_password: formData.isPassword || false,
      only_registered: formData.isRegistration || false,
      regCustomFields: formData.formType || [],
      dynamic_password: formData.typePassword === 'dynamicPassword',
      password: formData.password || '',
      participant: value.contact,
      room_type: value.waitingRoom,
      allow_admin_unmute_user: true,
      allow_start_or_stop_recording: value.stopAndStartRec,
      auto_record: value.autoRecord,
      camera_access: value.hostCameraOnly,
      check_audio: value.echoTest,
      start_mute: true,
      scheduleMeetingSummary: {},
      join_without_host: value.joinWithoutHost,
    }

    if (formData.reCurring) {

      if (formData.reCurringType === 'daily') {
        formSubmit.scheduleMeetingSummary = {
          repeatEvery: formData.repeatEvery,
          endBy: formData.endType
        }
      }

      if (formData.reCurringType === 'weekly') {
        formSubmit.scheduleMeetingSummary = {
          repeatEvery: formData.repeatEvery,
          endBy: formData.endType,
          week: formData.weekly
        }
      }

      if (formData.reCurringType === 'monthly') {
        formSubmit.scheduleMeetingSummary = {
          repeatEvery: formData.repeatEvery,
          endBy: formData.endType,
          OccOccursOn: formData.OccursOn
        }
      }
    }
    dispatch(scheduleActions.submitEventForm(formSubmit))
  }


  return (
    <Layout title='Schedule Meeting' goBack >
      <div className={`w-75 m-auto  ${styles.scheduleMeetContainer}`}>
        <div className={`px-4 py-4 ${styles.scheduleMeetingPadding}`}>
          <Row className="mt-0">
            <Col lg={12}>
              <div className="d-flex mb-0 w-100 justify-content-center">
                <div
                  className={`${styles.step} inputBgTheme d-flex align-items-center ${step === 1 ? "stateFormActiveSection stateActiveSectionText" : ""
                    }`}
                >
                  <div className="welcomeMsg d-flex steps-block"><span className={`${styles.stepNumber} ${step > 1 ? 'bg-success' : ''}`}>{step > 1 ? <FaCheck /> : <>1</>}</span>
                    <label className={styles.stepsLabel}> Schedule</label>
                  </div>
                </div>
                <div
                  className={`${styles.arrow} stateFormSectionArrow ${step === 1 ? "stateFormActiveSectionArrow" : ""
                    }`}
                ></div>
                <div className={`position-relative ${styles.secondContain} w-100`}>
                  <div className={`${styles.arrowShadow} position-absolute shadowArrowSection`}></div>
                  <div
                    className={`${styles.step} step-two inputBgTheme ${step === 2 ? "stateFormActiveSection" : ""
                      }`}
                  >
                    <div className="welcomeMsg ml-3"><span className={`${styles.stepNumber} ${step > 2 ? 'bg-success' : ''}`}>{step > 2 ? <FaCheck /> : <>2</>}</span> <label className={styles.stepsLabel}> Meeting Entry</label> </div>
                  </div>
                </div>
                <div
                  className={`${styles.arrow} ${styles.secondContain}  position-relative stateFormSectionArrow ${step === 2 ? "stateFormActiveSectionArrow" : ""
                    }`}
                ></div>
                <div className={`position-relative ${styles.right24} w-100`}>
                  <div className={`${styles.arrowShadow} position-absolute  shadowArrowSection`}></div>
                  <div
                    className={`${styles.step} step-two inputBgTheme ${step === 3 ? "stateFormActiveSection" : ""
                      }`}
                  >
                    <div className="welcomeMsg ml-3"> <span className={styles.stepNumber}>3</span> <label className={styles.stepsLabel}> Invite &  Option </label>  </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <div className='sideBar p-4 mt-3 mr-4 br10'>
            {
              step === 1 && <StepFirst setFormData={(value) => {
                setFormData(Object.assign(formData, value))
                setStep(2)
              }} formData={formData} />
            }
            {
              step === 2 && <StepTwo setStepBack={(value) => {
                setStep(1)
                setFormData(Object.assign(formData, value))
              }}
                setStep={setStep}
                setFormData={(value) => {
                  setFormData(Object.assign(formData, value))
                  setStep(3)
                }} formData={formData} />
            }
            {
              step === 3 && <StepThree setStepBack={(value) => {
                setStep(2)
                setFormData(Object.assign(formData, value))
              }} setFormData={(value) => submitData(value)} formData={formData} />
            }
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ScheduleMeeting