import React, { Component } from 'react';
import { Button, Popover, PopoverBody } from 'reactstrap';
import { teamActions } from './../../../../Redux/_actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class ListContent extends Component {

    constructor() {
        super();
        this.state = {
            teampopoverOpen: false
        }
    }

    teamPopHover = async () => {
        await this.setState({ teampopoverOpen: !this.state.teampopoverOpen })
    }

    componentDidMount() {
        this.props.getAllTeams()
    }

    editTeam(id){
        this.props.history.push("/eoffice/Setting/edit-team/" + id);

    }

    render() {
        return (
            <>
                {this.props.teamData.map((list, index) =>
                    <tr key={index}>
                        <td>
                            <div className="checkbox">
                                <input className="styled-checkbox" id="styled-checkbox-1" type="checkbox" value="value1" />
                                <label for="styled-checkbox-1"></label>
                            </div>
                        </td>
                        <td><i className="fas fa-users"></i> {list.team_name}</td>
                        <td className="nowrap">{list.team_email_id || '-'}</td>
                        <td className="nowrap">{list.contact_person_name || '-'}</td>
                        <td>{list.contact_person_email || '-'}</td>
                        <td>
                            <ul className="team-group-list">
                                {list.People.length > 0 ? list.People.map((email, index) => {
                                    if (index <= 2) {
                                        return (
                                            <li>{email}</li>
                                        );
                                    }

                                }) : '-'}


                                {list.People.length > 3 && <li> <button id="teamListPopOver" type="button" className="btn btn-sm btn-team-more">
                                    <i className="fas fa-users"></i>  {list.People.length - 3} More
                </button>
                                </li>}

                            </ul>
                            {list.People.length > 3 && <Popover placement="bottom" isOpen={this.state.teampopoverOpen} target="teamListPopOver" toggle={this.teamPopHover} className="team-popover">
                                <PopoverBody >
                                    <ul className="team-group-list">
                                        {list.People.map((email, index) => {
                                            if (index > 2) {
                                                return (
                                                    <li>{email}</li>
                                                );
                                            }

                                        })}

                                    </ul>
                                </PopoverBody>
                            </Popover>}

                        </td>
                        <td className="nowrap"><Button onClick={() => this.editTeam(list._id)}  className="btn-sm btn-round btn-edit"> <i className="fas fa-pencil-alt"></i> Edit</Button></td>
                    </tr>)}

            </>

        )
    }
}

const mapState = (state) => {
    const { teamData } = state.teamsInfo;

    return {
        teamData,
    };
}

const actionCreators = {
    getAllTeams: teamActions.getAllTeams,
};

export default withRouter(connect(mapState, actionCreators)(ListContent));

