import React, { useEffect, useState } from "react";
// import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import Layout from "../../layoutSection";
import { webinarActions } from "../../Redux/_actions";
// import Entry from "./components/EntryPage";
import { Form, Modal } from "react-bootstrap";
import { conferenceServices } from "../../Redux/_services";
import { alertActions } from "../../Redux/_actions";
import Spinner from "../../components/UI/Spinner/Spinner";

const Options = () => {
  const { webinarDetail } = useSelector((state) => state.webinar);
  const param = useParams();

  const dispatch = useDispatch();
  const [hoverId, setHoverId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [update, setUpdate] = useState({
    quesAns: false,
    registration: false,
    only_registered: false,
    autoStartRecording: false,
    webinarPwdBit: false,
  });

  useEffect(() => {
    if (!webinarDetail || webinarDetail?.meetingID !== param.id) {
      dispatch(webinarActions.webinarDetail(param.id));
    }
  }, []);

  useEffect(() => {
    if (webinarDetail?.meetingID === param?.id) {
      setUpdate({
        quesAns: webinarDetail?.quesAns,
        registration: webinarDetail?.registration,
        only_registered: webinarDetail?.only_registered,
        autoStartRecording: webinarDetail?.autoStartRecording,
        webinarPwdBit: webinarDetail?.webinarPwdBit,
      });
    }
  }, [webinarDetail]);

  let optionJson = [
    {
      id: 1,
      img: "Q&A.svg",
      title: "Question and Answer",
      dis: `The question & answer (Q&A) feature for webinars allows attendees to ask questions during the webinar, and for the panelists, co-hosts, and host to answer their questions.
    Optionally, attendees can answer and upvote each other's questions.`,
      variable: "quesAns",
    },
    {
      id: 2,
      img: "RegistrationReq.svg",
      title: "Registration Required",
      dis: ` gd angdakjg d bgikjdag  kgdfkgknb ognd;kg Scheduling  d bgikjdag  kgdfkgknb ognd;kg Scheduling a w a webinar with registration requires your registrants to complete a brief form before receiving the link to join your webinar. This allows you to collect the names, email addresses, and other information from the registrants.`,
      btn: <button className="addFieldBtn text-white">Add Field</button>,
      variable: "registration",
    },
    {
      id: 3,
      img: "authUser.svg",
      title: "Only verified users can join",
      dis: `Only participants join which have account on wave.`,
      variable: "only_registered",
    },
    {
      id: 4,
      img: "Record.svg",
      title: "Record the webinar automatically",
      dis: `Automatic recording is an option that allows the host to start recording automatically when the meeting starts.`,
      variable: "autoStartRecording",
    },
    {
      id: 5,
      img: "webPassword.svg",
      title: "Webinar Password",
      dis: `If you were invited to attend someone else's webinar and are asked to enter a password when joining, it means that the organizer who scheduled the session has password protected it for added security.`,
      variable: "webinarPwdBit",
    },
  ];

  const getMeetUpdate = (value) => {
    setIsLoading(true);
    conferenceServices
      .updateMeeting({
        meetingId: webinarDetail?.meetingID,
        metaData: { [value]: !update[value] },
      })
      .then(
        (res) => {
          setUpdate({ ...update, [value]: !update[value] });
          setIsLoading(false);
        },
        (err) => {
          dispatch(alertActions.error(err.toString()));
          setIsLoading(false);
        }
      );
  };

  return (
    <Layout
      title={webinarDetail?.name || "Options"}
      goBack="/webinars"
      list="webinar"
      active="Options"
    >
      {/* <div className="container-fluid s_wave_content_part  Entry ">
        <Entry webinarDetail={webinarDetail} />
      </div> */}
      <div className="container-fluid s_wave_content_part  ">
        {isLoading && (
          <Modal show={isLoading} contentClassName="modalLoading">
            <Spinner />
          </Modal>
        )}
        <div className="detailsSection w-100 sideBar welcomeMsg  Options ">
          <div className="cardOptions">
            {optionJson.map((data) => (
              <span className="w-100">
                <div
                  className="tagContainer bgColorBodyCard position-relative mt-3 cardWidth-100"
                  onMouseEnter={() => setHoverId(data.id)}
                  onMouseLeave={() => setHoverId(null)}
                >
                  <div className="between">
                    <div>{data.title}</div>
                    <Form.Check
                      type="switch"
                      id={`custom-switch-${data.id}`}
                      className="switchBtn"
                      checked={update[data.variable]}
                      onChange={() => getMeetUpdate(data.variable)}
                    />
                  </div>
                  {hoverId === data.id ? (
                    <div className="f_size_12 mt-2 f-size-m">{data.dis}</div>
                  ) : (
                    <div className="text-center">
                      <img
                        src={`../../images/${data.img}`}
                        alt={data.title}
                        className="ImagesSection"
                      />
                    </div>
                  )}
                </div>
              </span>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Options;
