import React, { Component } from "react";
import "./waitForStartMeeting.css";
import { withRouter } from "react-router-dom";
import { Spinner } from "reactstrap";
import WaitingHeader from "../waitingHeader";
import { connect } from "react-redux";
import { joinActions } from "../../../../Redux/_actions";

class WaitForStartMeeting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mid: this.props.mid,
      mname: this.props.mname,
      name: this.props.name,
      isMeetRunning: this.props.isMeetRunning,
      checkModeratorCount: this.props.checkModeratorCount,
      attendeePW: this.props.attendeePW,
      moderatorToken: this.props.moderatorToken,
    };
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.checkModeratorCount !== this.props.checkModeratorCount) {
      if (this.props.isMeetRunning) {
        if (this.props.userPwdBit === 0) {
          this.props.joinConference(
            this.props.isWebinar,
            this.props.name,
            this.props.mid,
            this.props.attendeePW,
            this.props.moderatorToken
          );
        } else {
          this.props.joinConference(
            this.props.isWebinar,
            this.props.name,
            this.props.mid,
            this.props.attendeePW,
            this.props.moderatorToken
          );
        }
      } else {
        this.setState({
          checkModeratorCount: this.props.checkModeratorCount,
          isMeetRunning: this.props.isMeetRunning,
          moderatorToken: this.props.moderatorToken,
          attendeePW: this.props.attendeePW,
        });
      }
    }
  };

  render() {
    const {
      mid,
      mname,
      isMeetRunning,
      checkModeratorCount,
      userPwdBit,
      attendeePW,
      name,
      isWebinar,
      moderatorToken,
    } = this.state;

    return (
      <div className="wave_wating_sctn">
        <WaitingHeader mname={mname} mid={mid} />
        <div className="wave_waiting_contianer_msg">
          <div className="wait_msg">
            Thank you for joining meeting
            <span>Please wait, you'll be redirect to the meeting.</span>
          </div>

          <div className="wave_wait_timer text-center">
            {isMeetRunning ? (
              <span>
                <Spinner color="light" /> Joining the meeting...{" "}
              </span>
            ) : (
              <span>
                <i className="far fa-clock"></i> Waiting for meeting host to
                start the meeting.
              </span>
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapState(state) {
  return {};
}
const actionCreators = {
  joinConference: joinActions.joinConference,
};

export default withRouter(
  connect(mapState, actionCreators)(WaitForStartMeeting)
);
