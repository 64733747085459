import React, { useState } from 'react'
import { Row, Col, Form, Button, InputGroup } from 'react-bootstrap';
import { Formik } from 'formik';
import { FaArrowLeft } from "react-icons/fa";
import * as Yup from 'yup'

import { InputFiled } from '../../../Common/authFile'
import { fieldTypes, defaultFieldJson, InputSection } from './fieldSection'
import SetFieldModal from './setFieldModal';

import styles from './styles.module.scss'
import isEmpty from 'is-empty';

const validation = Yup.object().shape({
  isPassword: Yup.boolean().required(),
  typePassword: Yup.string().required(),
  password: Yup.string().when(['isPassword', 'typePassword'], {
    is: (isPassword, typePassword) => isPassword && typePassword === 'password',
    then: Yup.string().required('Must Enter Password!').test('length', "Password must be at least 6 characters long.", (value) => isEmpty(value) || value?.length > 5)
  })
})

const StepTwo = (props) => {
  const { setStep, setFormData, formData, setStepBack } = props
  const [registrationChecked, setRegistrationChecked] = useState(false)
  const [passwordnChecked, setPasswordChecked] = useState(false)

  const initialValues = {
    isPassword: passwordnChecked,
    typePassword: formData?.typePassword || 'password',
    password: formData?.password || '',
    isRegistration: registrationChecked,
    formType: formData?.formType || defaultFieldJson,
    customFieldModal: formData?.customData || false
  }

  const onChangeRegistration = (event) => {
    setRegistrationChecked(!registrationChecked)
    setPasswordChecked(false)
  }

  const onChangePaasword = (event) => {
    setPasswordChecked(!passwordnChecked)
    setRegistrationChecked(false)
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={(value) => setFormData(value)}
      validationSchema={validation}
    >
      {
        ({ values, setFieldValue, handleSubmit }) => (
          <>
            <h6 className='welcomeMsg mb-3'>Choose any one (optional)</h6>

            <h6 className='welcomeMsg'>Meeting Password</h6>
            <Form.Group>
              <Form.Check
                label={<div> Required {values.isPassword && <span className='ml-2 text-muted'> ( <span className='text-danger' >*</span> It can't be changed after the meeting is scheduled. ) </span>} </div>}
                type='checkbox'
                checked={passwordnChecked}
                onChange={(event) => {
                  setFieldValue('isPassword', true)
                  setFieldValue('isRegistration', false)
                  onChangePaasword(event)
                }}
                className='welcomeMsg'
                name="meetingEntry"
              />
            </Form.Group>
            {
              values.isPassword &&
              <div className='bgColorBodyCard  p-3 mt-2'>
                <Form.Group className='mb-2'>
                  <Form.Check
                    label="Dynamic Password"
                    name="password"
                    type='radio'
                    id="password"
                    value='dynamicPassword'
                    onChange={(event) => setFieldValue('typePassword', event.target.value)}
                    className='welcomeMsg mb-3'
                    checked={values.typePassword === 'dynamicPassword'}
                  />
                  <Form.Check
                    label={
                      <InputFiled
                        name="password"
                        type="text"
                        placeholder="password"
                        className="form-control inputColorTheme"
                        disabled={values.typePassword !== 'password'}
                      />}
                    name="password"
                    type='radio'
                    id="password"
                    value='password'
                    className={`${styles.radioForm}`}
                    checked={values.typePassword === 'password'}
                    onChange={(event) => setFieldValue('typePassword', event.target.value)}
                  />
                </Form.Group>
              </div>
            }
            <h6 className='welcomeMsg mt-4'>Meeting Registration</h6>
            <Form.Group>
              <Form.Check
                label={<div> Required {values.isRegistration && <span className='ml-2 text-muted'> ( <span className='text-danger' >*</span> It can't be changed after the meeting is scheduled. ) </span>} </div>}
                type='checkbox'
                checked={registrationChecked}
                onChange={(event) => {
                  setFieldValue('isRegistration', true)
                  setFieldValue('isPassword', false)
                  onChangeRegistration(event)
                }}
                className='welcomeMsg'
                name="meetingEntry"
              />
            </Form.Group>
            {
              values.isRegistration &&
              <div className='bgColorBodyCard  p-3 mt-2'>
                <h6 className='welcomeMsg mb-1'>Registration Form</h6>
                <div className='welcomeMsg f_size_13 mb-3'>People registering for the meeting will have to fill the below information.</div>
                {
                  values.formType.map((item) => <div key={`sampleInput-${item.id}`}> <InputSection item={item} /> </div>)
                }
                <button type='button' className="mt-2 border-0 pl-4 pr-4 headerBtn text-white submitBtnColor" >Registration</button>
                <div className='bottomBorder mt-3' />
                <div className='welcomeMsg f_size_13 mt-2'>If you want to add edit fields as per your expectation, click on bellow button.</div>
                <button type='button' className="mt-2 pl-4 pr-4 headerBtn submitBtnColorBorder" onClick={() => setFieldValue('customFieldModal', true)} >Customize Form</button>
                {
                  values.customFieldModal && <SetFieldModal show={values.customFieldModal} toggle={() => setFieldValue('customFieldModal', false)} setFiled={(value) => setFieldValue('formType', defaultFieldJson.concat(value))} customData={values.formType} />
                }
              </div>
            }
            <div className='mt-3 between'>
              <div>
                <button type='button' className='btnBgLess welcomeMsg f_size_20' onClick={() => setStepBack(values)}>
                  <FaArrowLeft />
                </button>
                <Button type='button' variant="outline-secondary" className='welcomeMsg ml-4' onClick={() => setStep(3)}>
                  Skip
                </Button>
              </div>
              <button type='button' onClick={() => handleSubmit()} className="mr-2 border-0 pl-4 pr-4 headerBtn text-white submitBtnColor" >Continue</button>
            </div>
          </>
        )
      }
    </Formik>
  )
}

export default StepTwo
