export const accountConstants = {
    ACCOUNT_REQUEST: 'ACCOUNT_REQUEST',
    ACCOUNT_SUCCESS: 'ACCOUNT_SUCCESS',
    ACCOUNT_FAILURE: 'ACCOUNT_FAILURE',

    SHARED_REQUEST: 'SHARED_REQUEST',
    SHARED_SUCCESS: 'SHARED_SUCCESS',
    SHARED_FAILURE: 'SHARED_FAILURE',

    FILE_SHARE_SUCCESS: 'FILE_SHARE_SUCCESS',

    ATTACH_FILE_SUCCESS: 'ATTACH_FILE_SUCCESS',

    DELETE_SHARED_REQUEST: 'DELETE_SHARED_REQUEST',
    DELETE_SHARED_SUCCESS: 'DELETE_SHARED_SUCCESS',
    DELETE_SHARED_FAILURE: 'DELETE_SHARED_FAILURE',
};
