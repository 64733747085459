/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useEffect, useState, memo } from "react";
import { Redirect } from "react-router";
import { Formik } from "formik";
import { Button, Form } from "react-bootstrap";
import ReactCodeInput from "react-code-input";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import decode from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import CountUp from "react-countup";
import isEmpty from "is-empty";
import * as Yup from 'yup';

import AuthLayout from "../layout";
import userconfigs from "../../helpers/closures";
import { userActions } from "../../Redux/_actions";
import './style.scss'
import { RiCreativeCommonsZeroLine } from "react-icons/ri";

var userInfo = null;
try {
  userInfo = decode(userconfigs.getItem("jwt"));
} catch (err) {
  // console.log(err);
}

const validation = Yup.object().shape({
  mobileNo: Yup.string()
  .required("Please enter the phone number!")
  .test(
    "len",
    "Phone number is incorrect!",
    (val) => isEmpty(val) || val.length === 10
  ),
  otp: Yup.string()
    .test(
      "length",
      "Please Enter OTP 6 digit properly",
      (val) => isEmpty(val) || val?.length === 6
    ),
})

const MobileVerification = (props) => {
  const {jwt} =  props;
  const state = useSelector((state) => state.authentication);
  const dispatch = useDispatch();
  const [userData, setUserData] = useState({})
  const [initialValues, setInitialValues] = useState({
    mobileNo: "",
    otp: "",
    step: 1,
    countryCode: "91",
    countryId: "in",
    isCount: true,
  });


  useEffect(() => {
    try {
      var auth = decode(state.user)
        setUserData(auth);  
    } catch (err) {
      setUserData(jwt);  
    }
    // if ( !auth || auth?.loginStatus !== 1) {
    //   props.history.push("/");
    // }
    if (auth?.mobile) {
      setInitialValues({ ...initialValues, mobileNo: auth.mobile });
    }else if(jwt?.mobile){
      setInitialValues({ ...initialValues, mobileNo:  jwt.mobile });
    }

  }, []);


  useMemo(()=>{
    if( state.verificationSent){
      setInitialValues({...initialValues,step : 2})
    }
  },[state.verificationSent])

  const setPhoneNumber = (values, setFieldValue) => {
    console.log(userData , " it testing Herer")
    if(!isEmpty(values.mobileNo) ){
    if(!userData?.mobile ||  userData?.mobile !== values.mobileNo){
      const params = {
        name : userData.name,
        email :  userData.email,
        mobile: values.mobileNo,
        countryCode: values.countryCode,
        countryId: values.countryId,
      }
      setInitialValues({ ...initialValues,mobileNo : values.mobileNo });
      dispatch(userActions.profileUpdate(params, true))
    }
    else{
     dispatch(userActions.verifyProfile(values.mobileNo))
    }
  }
  };

  return (
    <AuthLayout label="Phone Verification" image="/images/otp_mobile.png" logoIcon={userInfo?.originLogo}>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) =>   dispatch(userActions.verifyOtp(values.mobileNo,values.otp)) }
        validationSchema={validation}
        enableReinitialize
      >
        {({
          values,
          setFieldValue,
          handleSubmit,
          errors,
          touched,
          setFieldTouched,
          resetForm
        }) => (
          <>
            <Form.Group >
              <Form.Label className="f_size_14 ml-3">Mobile Number</Form.Label>
              <PhoneInput
                country="in"
                name="mobileNo"
                inputClass="mobileInput bg-light text-dark"
                enableSearch={true}
                placeholder="Mobile No"
                countryCodeEditable={false}
                value={`${values.countryCode}${values.mobileNo}`}
                onChange={(value, country) => {
                  setFieldValue(
                    "mobileNo",
                    value.slice(country.dialCode.length)
                  );
                  setFieldValue("countryCode", country.dialCode);
                  setFieldValue("countryId", country.countryCode);
                }}
                inputProps={{
                  onBlur: () => setFieldTouched("mobileNo", true),
                  // disabled: values.step !== 1,
                }}
                disabled={values.step !== 1 }
              />
              {errors.mobileNo && touched.mobileNo && (
                <div className="error text-danger f_size_10">
                  {errors.mobileNo}
                </div>
              )}
            </Form.Group>
            {values.step === 1 ? (
              <Button
                variant="primary"
                onClick={() => setPhoneNumber(values, setFieldValue)}
                className="mt-3"
                disabled={values.mobileNo.length == 0}
              >
                Send OTP
              </Button>
            ) : (
              <>
                <Form.Group className="otpContainer">
                  <div>
                    <Form.Label className="f_size_14 ml-3">OTP </Form.Label>
                  </div>
                  <div className="d-flex justify-content-between">
                  <ReactCodeInput
                    type="number"
                    onChange={(otp) => setFieldValue("otp", otp)}
                    fields={6}
                    className="OtpInput"
                  />
                <button className="btnContainer" onClick={()=> {
                  dispatch(userActions.verifyProfile(values.mobileNo))
                  setFieldValue('isCount', true)
                }}
                  disabled={values.isCount}
                >    
                { values.isCount ? (
                  <>
                    Resend OTP in :
                    <CountUp
                      end={0}
                      start={30}
                      duration={30}
                      onEnd={() => setFieldValue('isCount' , !values.isCount)}
                    />
                    sec
                  </>
                ) : (
                  "Resend OTP"
                )}
                </button>
                </div>
                  { errors.otp && (
                    <div className="error text-danger f_size_10">
                      {errors.otp}
                    </div>
                  )}
                </Form.Group>
                <Button
                  variant="primary"
                  onClick={() => handleSubmit()}
                  disabled={values?.otp?.length < 6}
                >
                  {state.otpVerifyLoading ? 'Submitting..':  'Submit'}
                </Button>
                {
                  !state.otpVerifyLoading && state.otpError && resetForm()
                }
              </>
            )}
          </>
        )}
      </Formik>
    </AuthLayout>
  );
};

export default memo(MobileVerification);
