import moment from "moment";
import React from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { myconfig } from "../config/config";
import { conferenceActions } from "../Redux/_actions";
import "./styles.scss";
// import { tsToDate } from "../helpers/helpers";
// import { useHistory } from "react-router";

let seq = 0;

const getMonth = (data) => {
  const month = [
    "Jan",
    "Feb",
    "Mar",
    "April",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return month[/* `${parseInt(data) - 1}` */data];
};

const colorsCombination = () => {
  let returnColor = seq;
  const colors = ["#9D70FF", "#FE698D", "#61A0FF"];
  if (seq !== 2) {
    seq += 1;
  } else {
    seq = 1;
  }
  return colors[`${returnColor}`];
};

/* const getTime = (meetingDate, meetingTime) => {
  let endDate = meetingDate.split("-");
  let endTimeVal = meetingTime.split(":");
  const endDateTime = new Date(endDate[2], (endDate[1] - 1), endDate[0], endTimeVal[0], endTimeVal[1]).getTime()
  return tsToDate(endDateTime)
} */

const MeetCard = (data, BtnGoogle) => {
  const dispatch = useDispatch()
  const startMeet = (data) => {
    if (data?.meetingdetails[0]?.waitingRoom) {
      const redirectPath = myconfig.serverURL + "/join_meeting?mid=" + data?.meetingID;
      window.location.href = redirectPath;
    } else {
      dispatch(conferenceActions.startMeeting(data?.meetingID, "meeting"));
    }
  }

  const history = useHistory();
  return (
    <div className="meetCardContainer welcomeMsg meet-card">
      <div className="d-flex w-100">
        <div className="dateSection text-white" style={{ backgroundColor: colorsCombination() }}>
          <div className="lh-30">{Number(new Date(data?.mstartTs).getDate())}</div>
          <div className="time">{getMonth(new Date(data?.mstartTs).getMonth())}</div>
        </div>
        <div className="ml-3 idMeetSection w-100">
          <div className="textLimit meeting-title">{data?.meetingdetails[0]?.name}</div>
          <div className="d-flex justify-content-between">
            <div className="meet-card-infos">
              <div className="text-muted card-time">
                {(data?.m_start_time && data?.m_end_time ) && `${moment(+new Date().setHours(data?.m_start_time.split(":")[0], data?.m_start_time.split(":")[1], 0, 0)).format("hh:mm a")} to ${moment(+new Date().setHours(data?.m_end_time.split(":")[0], data?.m_end_time.split(":")[1], 0, 0)).format("hh:mm a")}`}
              </div>
              <div className="text-meet-id">Meeting ID: {data?.meetingID}
              </div>
            </div>
            <div className="meetCardBtnSection d-flex align-items-end">
              <Button variant="btn btn-primary-outline-white btn-sm"  onClick={() => history.push(`/meeting_history/${data?.meetingID}?mid=${data.meetingdetails[0]._id.replace(/['"]+/g, '')}`)}
              >View Details</Button>
              {(data?.mstartTs && new Date(data.mstartTs) >= new Date()) &&
                <Button variant="btn btn-primary-outline btn-sm" onClick={() => startMeet(data)}
                >Start</Button>
              }
              {/* {BtnGoogle ? BtnGoogle(data) : ''} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeetCard;
