import React from "react";
import "./Privacy.css";
const PrivacyPolicy = () => {
  return (
    <div className="privacy-content privacy-container">
      <div className="page-heading heading-container">
        <h1>Privacy Policy</h1>
      </div>
      <div className="content content-container">
        <p style={{ margin: "2px" }}>
          At Sarv Wave, one of our main priorities is the privacy of our
          visitors. This Privacy Policy document contains types of information
          that is collected and recorded by Sarv Wave and how we use it.
        </p>
        <p style={{ margin: "8px 2px 2px 2px" }}>
          If you have additional questions or require more information about our
          Privacy Policy, do not hesitate to contact us.
        </p>
        <h3>Safety Measures</h3>
        <p style={{ margin: "-5px 2px 2px 2px" }}>
          Sarv Wave has taken all the measures in the count to keep user data
          safe from all kinds of breaches.
        </p>
        <ul>
          <li>
            No one out of the invitation will be able to join the meeting.
          </li>
          <li>
            Random meeting code generation keeps the meeting hidden from random
            attacks.
          </li>
          <li>
            Moderators and Presenters can kick any unknown or unwanted user at
            any time.
          </li>
          <li>Only a host can allow a user to join the meeting.</li>
          <li>
            Host and moderator can mute, unmute, enable or disable cameras
            during or before the meeting.
          </li>
        </ul>
        <h3>Transparency</h3>
        <p style={{ margin: "-5px 2px 2px 2px" }}>
          By keeping you in the control, we make sure that we are respecting
          your privacy. With time our team of brilliant minds will keep evolving
          the security features and data protection standards.
        </p>
        <p style={{ margin: "4px" }}>
          We follow an unbending process for responding to requests made by the
          government for the customer data. We will disclose information about
          all the requests we receive from the government.
        </p>
        <p style={{ margin: "4px" }}>
          Sarv Wave is allowed to make any changes, at any time to this policy.
          Its user base will be informed about the same via emails or social
          handles.
        </p>
        <h3>Third-Party Privacy Policies</h3>
        <p style={{ margin: "-7px 2px 2px 2px" }}>
          Sarv Wave's Privacy Policy does not apply to other advertisers or
          websites. Thus, we are advising you to consult the respective Privacy
          Policies of these third-party ad servers for more detailed
          information. It may include their practices and instructions about how
          to opt-out of certain options.
        </p>
        <h3>Children's Information</h3>
        <p style={{ margin: "-7px 2px 2px 2px" }}>
          Another part of our priority is adding protection for children while
          using the internet. We encourage parents and guardians to observe,
          participate in, and/or monitor and guide their online activity.
        </p>
        <p style={{ margin: "4px" }}>
          Sarv Wave does not knowingly collect any Personal Identifiable
          Information from children under the age of 13. If you think that your
          child provided this kind of information on our App, we strongly
          encourage you to contact us immediately and we will do our best
          efforts to promptly remove such information from our records.
        </p>
        <h3>Online Privacy Policy Only</h3>
        <p style={{ margin: "-7px 2px 2px 2px" }}>
          This Privacy Policy applies only to our online activities and is valid
          for visitors to our App with regards to the information that they
          shared and/or collect in Sarv Wave. This policy is not applicable to
          any information collected offline or via channels other than this app.
          Our Privacy Policy was created with the help of the App Privacy Policy
          Generator from App-Privacy-Policy.com
        </p>
        <h3>Consent</h3>
        <p style={{ margin: "-7px 2px 150px 2px" }}>
          By using our app, you hereby consent to our Privacy Policy and agree
          to its Terms and Conditions.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
