import React from 'react';
import { Button } from 'reactstrap';

const button = (props) => (
    <Button
        disabled={props.disabled}
        // className={[classes.Button, classes[props.btnType]].join(' ')}
        onClick={props.clicked}
        className={props.className}
        name={props.name}
        type={props.type}
        >
        {props.children} 
    </Button>
);

export default button;