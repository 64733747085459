import { accountConstants } from '../_constants';

const initialState = { loadingStatus: false, shareLoading : false, sharedFiles : [], shareLoadingStatus:true, deleteShareLoading : false, deletedShared : false };

export function accountsInfo(state = initialState, action) {
  switch (action.type) {
    case accountConstants.ACCOUNT_REQUEST:
      return {
        ...state,
        loadingStatus: true,
        count: action.resp
      };
    case accountConstants.ACCOUNT_SUCCESS:
      return {
        ...state,
        loadingStatus: false,
        count: action.resp
      };
    case accountConstants.ACCOUNT_FAILURE:
      return {
        ...state,
        loadingStatus:false
      };
    case accountConstants.SHARED_REQUEST:
      return {
        ...state,
        shareLoading: true
      };
    case accountConstants.SHARED_SUCCESS:
      return {
        ...state,
        shareLoading: false,
        sharedFiles: action.resp
      };
    case accountConstants.SHARED_FAILURE:
      return {
        ...state,
        shareLoading:false
      };
    case accountConstants.FILE_SHARE_SUCCESS:
      return {
        ...state,
        shareLoadingStatus: false,
        shareURL: action.url
      };
    case accountConstants.DELETE_SHARED_REQUEST:
      return {
        ...state,
        deleteShareLoading: true,
        deletedShared : false
      };
    case accountConstants.DELETE_SHARED_SUCCESS:
      return {
        ...state,
        deleteShareLoading: false,
        deletedShared : true
      };
    case accountConstants.DELETE_SHARED_FAILURE:
      return {
        ...state,
        deleteShareLoading:false,
        deletedShared : false
      };
    case accountConstants.ATTACH_FILE_SUCCESS:
      return {
        ...state,
        resp:action.resp,
        attachFileLoading:false
      };
    default:
      return state
  }
}