import React, { Component } from 'react';
import Search from './Search';
import MyPin from './MyPin';
import Conversation from './Conversation';
import MutedConversation from './MutedConversation';
import Colleagues from './Colleagues';
import './style.scss';

 class TalkContainer extends Component {
    render() {
        return (
            <div className="leftScrollbar">
               <Search/>
               <MyPin/>
               <Conversation />
               <MutedConversation />
               <Colleagues />
           
            </div>
        )
    }
}

export default TalkContainer;
