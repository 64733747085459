import { myconfig, siteTokens } from '../../config/config';
import axios from 'axios';
import userconfigs from '../../helpers/closures';
import jwt_decode from "jwt-decode";

import { handleResponse, errorCheck, handleJWTError } from './common'



const getReports = (params) => {
    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };
    return axios.post(`${myconfig.apiURL}meeting/getMeetingReport`, params, axiosRequestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
                // userconfigs.removeItem("jwt");
                // generateToken();
                // window.location.href = "/login";
            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}

const getReportDetails = (params) => {
    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };

    const uuid = jwt_decode(userconfigs.getItem('jwt')).uuid;

    return axios.post(`${myconfig.apiURL}meeting/getMeetingHistoryByUserID/${uuid}`, params, axiosRequestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
                // userconfigs.removeItem("jwt");
                // generateToken();
                // window.location.href = "/login";
            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}
const getReportRegisterDetails = (params) => {
    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };

    const uuid = jwt_decode(userconfigs.getItem('jwt')).uuid;

    return axios.post(`${myconfig.apiURL}meeting/getAllMeetingRegisterUser`, params, axiosRequestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
                // userconfigs.removeItem("jwt");
                // generateToken();
                // window.location.href = "/login";
            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}
const getMeetingRecording = (params) => {
    let axiosRequestOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userconfigs.getItem('jwt'),
        }
    };

    return axios.post(`${myconfig.apiURL}meeting/getMeetingRecording`, params, axiosRequestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        })
        .catch((error) => {
            if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
                handleJWTError();
                // userconfigs.removeItem("jwt");
                // generateToken();
                // window.location.href = "/login";
            } else {
                return Promise.reject(errorCheck(error));
            }
        });
}

export const reportService = {
    getReports,
    getReportDetails,
    getMeetingRecording,
    getReportRegisterDetails
};   