import React, { Component } from "react";
// import "./HomePage.css";
import "./HomePageNew.css";
//import Header from '../Header/Header';
import userconfigs from "../helpers/closures";
import decode from "jwt-decode";
import { Link } from "react-router-dom";
import JoinMeeting from "../Meetings/JoinMeeting";
import MeetingSection from "../MeetingsSection";
// import MeetingSection from "../Meetings/Meetings";
import Faq from "./components/FAQ/Faq";
import Logos from "./components/Logos/";
import CountNumber from "./components/CountNumber/CountNumber";
import Solution from "./components/Solutions/Solution";
import Presentation from "./components/Presentation/Presentation";
import Connect from "./components/Connect/Connect";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import FooterBottom from "./components/FooterBottom/FooterBottom";
import LoadSpinner from "../components/UI/Spinner/Spinner";

import { homeActions } from "../Redux/_actions";
import isEmpty from "is-empty";

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isJoinNowModal: false,
      isLoggedIn: !!this.props.jwt.loginStatus,
    };
  }

  checkLogin = () => {
    let userInfo = decode(userconfigs.getItem("jwt"));
    if (userInfo.loginStatus === 0) {
      this.props.history.push("/login");
    } else {
      this.props.history.push("/create_conference");
    }
  };

  openJoinNow = () => {
    this.setState({
      isJoinNowModal: true,
    });
  };

  updateJoinPopUpState = () => {
    this.setState({
      isJoinNowModal: false,
    });
  };

  successJoinHandler = () => {
    this.setState({
      isJoinNowModal: false,
    });
  };

  errorJoinHandler = () => {
    this.setState({
      isJoinNowModal: false,
    });
  };

  componentDidMount = () => {
    // if (
    //   typeof userconfigs.getItem("jwt") !== "undefined" &&
    //   userconfigs.getItem("jwt") !== null
    // ) {
    //   let userInfo = decode(userconfigs.getItem("jwt"));
    //   if (userInfo.loginStatus === 1) {
    //     this.setState({
    //       isLoggedIn: true,
    //     });
    //   }
    // }
    console.log(this.props?.jwt?.isMVerified)
    if (this.props?.jwt?.isMVerified === 0) {
      this.props.history.replace("/mobile_verification")
    }

    this.props.getHomeInfo();
  };

  componentDidUpdate() {
    if (this.state.isLoggedIn !== !!this.props.jwt.loginStatus) {
      this.setState({ isLoggedIn: !!this.props.jwt.loginStatus })
    }

  }

  render() {
    const { isLoggedIn } = this.state;
    const { homeInfo, mobileCheckLoading } = this.props;

    // console.log("[Homepage] @god  @render homeInfo : ", homeInfo, this.props);
    if (mobileCheckLoading) {
      return (
        <div className="d-flex justify-content-center mt-5">
          <LoadSpinner />
        </div>
      )
    }
    else if (isLoggedIn) {
      return <MeetingSection />;
    } else {
      return (
        <>
          {this.state.isJoinNowModal ? (
            <JoinMeeting
              isOpen={this.state.isJoinNowModal}
              message="Join Your Meeting"
              updatePopUpState={this.updateJoinPopUpState}
              successHandler={this.successJoinHandler}
              errorHandler={this.errorJoinHandler}
              history={this.props.history}
            />
          ) : null}

          <div className="homepage-container">
            <div className="container-fluid header-fluid">
              <div className="container">
                <nav className="navbar navbar-expand-lg  nav-pad0 header">
                  <div className="col-lg-7 col-md-6 pl-0">
                    <a
                      href="#"
                      title="Digital India Video Yantriki Application"
                    >
                      <img
                        src={!isEmpty(this.props.jwt?.originLogo) ? this.props.jwt?.originLogo : "images/homePage/sarv-wave-white-bg.png"}
                        className={`logo ${!isEmpty(this.props.jwt?.originLogo) ? "w65" : ''} `}
                        width="250"
                        alt="logo"
                      />
                    </a>
                    {/*<a href="#"  title="Digital India Video Yantriki Application" ><img  src="images/homePage/divya-logo.png" className="logo" width="120" alt="logo" /></a>*/}
                    <button
                      className="navbar-toggler"
                      type="button"
                      data-toggle="collapse"
                      data-target="#navbarSupportedContent"
                      aria-controls="navbarSupportedContent"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <i className="fas fa-bars"></i>
                    </button>
                  </div>

                  <div className="col-lg-5 col-md-6 header-rgt-link text-right pl-0 pr-0">
                    <button
                      className="btn bg-primary text-white"
                      onClick={() => this.openJoinNow()}
                    >
                      <i className="fas fa-video"></i> Join Meeting
                    </button>
                    {isLoggedIn ? (
                      ""
                    ) : (
                      <>
                        <button
                          className="btn login-btn"
                          onClick={this.checkLogin}
                        >
                          Login
                        </button>
                        <Link to="/signup" className="btn signup-btn">
                          Sign up
                        </Link>
                        {/*<Link to="/host-register" className="btn bg-primary text-white">
                          Host Register
					</Link>*/}
                      </>
                    )}
                  </div>
                </nav>
              </div>
            </div>
            {/* Header End here */}
            {/*Webinar Top Content Start here*/}
            <div className="container-fluid bg header-slider-container">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-md-12 col-sm-12 col-12 position-relative">
                    <div className="Webinar-lft">
                      <img
                        src="images/homePage/dotted-box.svg"
                        width="100"
                        alt="#"
                        className="img-one"
                      />
                      <img
                        src="images/homePage/dotted-box.svg"
                        width="100"
                        alt="#"
                        className="img-two"
                      />

                      <h1>One tool for all your virtual</h1>
                      <div className="slideContent">
                        <div className="slideContainer">
                          <ul className="slide-container-list">
                            <li className="slide-item">Conferences</li>
                            <li className="slide-item">Webinars</li>
                            <li className="slide-item">Events </li>
                            <li className="slide-item">Conference</li>
                          </ul>
                        </div>
                      </div>
                      <div className="clear"></div>

                      <div className="header-top-content">
                        <p>
                          This is an all-in-one platform to host immersive
                          events and build real connections, online.
                        </p>
                        <div className="text-center">
                          <button
                            className="btn btn-pink"
                            onClick={this.checkLogin}
                          >
                            Get Started
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 col-12 text-right mob-plr-0 header-rgt-section position-relative">
                    <div className="">
                      <img
                        src="images/homePage/conference-panel.png"
                        className="img-responsive header-rgt-img"
                        alt="Sarv wave Webinar"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*Webinar Top Content End here*/}

            <Logos />
            <CountNumber getAllInfo={homeInfo} />
            <Solution />
            <Presentation />
            <Connect />
            <Faq></Faq>
            {/*<FooterContact />
                  <FooterBottomWave/>*/}
            <FooterBottom />
          </div>
        </>
      );
    }
  }
}

const mapState = (state) => {
  const { loadingStatus, homeInfo } = state.home;
  const { mobileVerify, mobileCheckLoading } = state.authentication

  return {
    loadingStatus,
    homeInfo,
    mobileVerify,
    mobileCheckLoading
  };
};

const actionCreators = {
  getHomeInfo: homeActions.getHomeInfo,
};

export default withRouter(connect(mapState, actionCreators)(HomePage));

// export default HomePage;
