import { conferenceConstants } from "../_constants";
import { conferenceServices } from "../_services";
import { alertActions } from "./";

export const conferenceActions = {
  submitCreateConferenceForm,
  meetingInfo,
  meetingBits,
  startMeeting,
  deleteInviteUser,
  meetingDetail,
  addMoreParticipant,
  reInviteUser,
  allowConference,
  quickSubmitCreateConferenceForm,
  subMeetingList,
  editMeet,
};

function submitCreateConferenceForm(formData) {
  return (dispatch) => {
    dispatch(start("start"));
    conferenceServices.createConference(formData).then(
      (meetingId) => {
        dispatch(alertActions.success("Conference Create Successfully"));
        dispatch(success(meetingId));
        //window.location.href = "/start_meeting?mid="+meetingId
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error.toString()));
      }
    );
  };

  function start(start) {
    return { type: conferenceConstants.CONFERENCE_CREATE_START, start };
  }
  function success(meetingId) {
    return { type: conferenceConstants.CONFERENCE_CREATE_SUCCESS, meetingId };
  }
  function failure(error) {
    return { type: conferenceConstants.CONFERENCE_CREATE_FAILURE, error };
  }
}

function quickSubmitCreateConferenceForm(formData) {
  return (dispatch) => {
    dispatch(start("start"));
    conferenceServices.createConference(formData).then(
      (meetingId) => {
        // dispatch(alertActions.success("Meeting Created Successfully"));
        dispatch(conferenceActions.startMeeting(meetingId, "meeting"));
        dispatch(success(meetingId));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error.toString()));
      }
    );
  };

  function start(start) {
    return { type: conferenceConstants.CONFERENCE_CREATE_START, start };
  }
  function success(meetingId) {
    return { type: conferenceConstants.CONFERENCE_CREATE_SUCCESS, meetingId };
  }
  function failure(error) {
    return { type: conferenceConstants.CONFERENCE_CREATE_FAILURE, error };
  }
}

function meetingInfo(meetingId) {
  return (dispatch) => {
    dispatch(start());
    conferenceServices.meetingInfo(meetingId).then(
      (meetingInfo) => {
        dispatch(success(meetingInfo));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure());
      }
    );
  };

  function start(start) {
    return { type: conferenceConstants.MEETING_INFO_START };
  }
  function success(meetingInfo) {
    return { type: conferenceConstants.MEETING_INFO_SUCCESS, meetingInfo };
  }
  function failure(error) {
    return { type: conferenceConstants.MEETING_INFO_FAILURE };
  }
}

function meetingBits(meetingId, isWaitingRoom = false) {
  return (dispatch) => {
    dispatch(start());
    conferenceServices.meetingBits(meetingId, isWaitingRoom).then(
      (meetingInfo) => {
        dispatch(success(meetingInfo));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error));
      }
    );
  };

  function start(start) {
    return { type: conferenceConstants.MEETING_BIT_START };
  }
  function success(meetingInfo) {
    return { type: conferenceConstants.MEETING_BIT_SUCCESS, meetingInfo };
  }
  function failure(error) {
    return { type: conferenceConstants.MEETING_BIT_FAILURE };
  }
}

function startMeeting(meetingId, meetingType) {
  return (dispatch) => {
    dispatch(start());
    conferenceServices.startMeeting(meetingId, meetingType).then(
      (meetingInfo) => {
        if (meetingType === "webinar") {
          // let newTab = window.open();
          // newTab.location.href = meetingInfo;
          // window.open(meetingInfo, '_blank');
          window.open(meetingInfo);
        } else {
          window.location.href = meetingInfo;
          // window.open(meetingInfo);
        }
        dispatch(success(meetingInfo));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure());
      }
    );
  };

  function start() {
    return { type: conferenceConstants.START_MEETING_START };
  }
  function success(meetingInfo) {
    return { type: conferenceConstants.START_MEETING_SUCCESS, meetingInfo };
  }
  function failure(error) {
    return { type: conferenceConstants.START_MEETING_FAILURE };
  }
}

function deleteInviteUser(meetingId, meetId) {
  return (dispatch) => {
    dispatch(start("Request"));
    conferenceServices.deleteInviteUser(meetingId).then(
      (response) => {
        dispatch(alertActions.success("Removed Successfully!!")); 
        dispatch(success(response));
        dispatch(conferenceActions.meetingDetail(meetId));
       
       
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function start(start) {
    return { type: conferenceConstants.DELETE_INVITE_START };
  }
  function success(response) {
    return { type: conferenceConstants.DELETE_INVITE_SUCCESS, response };
  }
}

function reInviteUser(meetingId, value) {
  return (dispatch) => {
    dispatch(start("Request"));
    conferenceServices.reInviteUser(meetingId, value).then(
      (response) => {
        dispatch(alertActions.success("Reinvite Successfully !"));
        dispatch(success(response));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error.toString()));
      }
    );
  };

  function start(start) {
    return { type: conferenceConstants.RE_INVITE_START };
  }
  function success(response) {
    return { type: conferenceConstants.RE_INVITE_SUCCESS, response };
  }
  function failure(error) {
    return { type: conferenceConstants.RE_INVITE_FAILURE };
  }
}

function allowConference(meetingId, participantID) {
  return (dispatch) => {
    dispatch(start("Request"));
    conferenceServices.allowConference(meetingId, participantID).then(
      (response) => {
        dispatch(alertActions.success("Participant Allow Successfully!"));
        dispatch(success(response));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error.toString()));
      }
    );
  };

  function start(start) {
    return { type: conferenceConstants.ALLOW_CONFERENCE_START };
  }
  function success(response) {
    return { type: conferenceConstants.ALLOW_CONFERENCE_SUCCESS, response };
  }
  function failure(error) {
    return { type: conferenceConstants.ALLOW_CONFERENCE_FAILURE };
  }
}

function addMoreParticipant(formData) {
  return (dispatch) => {
    dispatch(start("Request"));
    conferenceServices.addMoreParticipant(formData).then(
      (response) => {
        dispatch(alertActions.success(response));
        dispatch(conferenceActions.meetingDetail(formData.meetingId));
        dispatch(success(response));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error.toString()));
      }
    );
  };

  function start(start) {
    return { type: conferenceConstants.ADD_PARTICIPANT_START };
  }
  function success(response) {
    return { type: conferenceConstants.ADD_PARTICIPANT_SUCCESS, response };
  }
  function failure(error) {
    return { type: conferenceConstants.ADD_PARTICIPANT_FAILURE };
  }
}

function meetingDetail(meetingId, mid) {
  return (dispatch) => {
    dispatch(start());
    conferenceServices.meetingDetail(meetingId, mid).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error.toString()));
      }
    );
  };

  function start(start) {
    return { type: conferenceConstants.MEETING_DETAIL_START };
  }
  function success(response) {
    return { type: conferenceConstants.MEETING_DETAIL_SUCCESS, response };
  }
  function failure(error) {
    return { type: conferenceConstants.MEETING_DETAIL_FAILURE, error };
  }
}

function subMeetingList(meetingId) {
  return (dispatch) => {
    dispatch(start());
    conferenceServices.subMeetingList(meetingId).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error));
      }
    );
  };

  function start(start) {
    return { type: conferenceConstants.MEETING_SUB_LIST_START };
  }
  function success(response) {
    return { type: conferenceConstants.MEETING_SUB_LIST_SUCCESS, response };
  }
  function failure(error) {
    return { type: conferenceConstants.MEETING_SUB_LIST_FAILURE, error };
  }
}

function editMeet(props) {
  return (dispatch) => {
    dispatch(start());
    conferenceServices.editSubMeeting(props).then(
      (response) => {
        //dispatch(conferenceActions.meetingInfo(props.meetingId));
        dispatch(alertActions.success("!Meet is update successfully"));
        // if (props.type === "occurence") {
        //   window.location.href = "/meeting_history"
        // }
       dispatch(success(response));
      },
      (error) => {
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error));
      }
    );
  };

  function start(start) {
    return { type: conferenceConstants.MEETING_SUB_LIST_START };
  }
  function success(response) {
    return { type: conferenceConstants.MEETING_SUB_LIST_SUCCESS, response };
  }
  function failure(error) {
    return { type: conferenceConstants.MEETING_SUB_LIST_FAILURE, error };
  }
}
