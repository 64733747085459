import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Button, Col, Modal, Row, Form, InputGroup } from "react-bootstrap";
import { FaEdit, FaVideo, FaWhatsapp } from "react-icons/fa";
import { AiOutlineCalendar } from "react-icons/ai";
import { MdContentCopy, MdOutlineEmail } from "react-icons/md";
import PerfectScrollbar from "react-perfect-scrollbar";
import { confirmAlert } from "react-confirm-alert";
import { Formik } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import queryString from "query-string";
import * as Yup from 'yup';

import { durationTime, timeToIndiaTime, tsToDate } from "../../helpers/helpers";
import Layout from "../../layoutSection";
import {
  alertActions,
  conferenceActions,
  eventActions,
  scheduleActions,
} from "../../Redux/_actions";
import Spinner from "../../components/UI/Spinner/Spinner";
import { month } from "../../Common/constant";
import { myconfig } from "../../config/config";
import ConfirmationPopup from "../../components/UI/ConfirmationPopup/confirmationPopup";
import { EditRecurring } from "./Modal";
import "./styles.scss";
import { InputFiled } from "../../Common/authFile";
import moment from "moment";
import { conferenceServices } from "../../Redux/_services";
import CustomModal from "../../layoutSection/Modal";
import isEmpty from "is-empty";

const startTime = (meetingDate, meetingTime) => {
  let startDate = meetingDate.split("-");
  let startTimeVal = meetingTime.split(":");

  const startDateTime = new Date(startDate[0], (parseInt(startDate[1]) - 1), startDate[2], startTimeVal[0], startTimeVal[1])
  return tsToDate(startDateTime.getTime())
}

const shareUrl = (url, type) => {
  const message = `Hello ,  Please Join From URL : ${url} Thank You`;
  if (type === "whatsapp") {
    return "whatsapp://send?text=" + message;
  } else {
    return "mailto:?Subject=Schedule Webinar&Body=" + message;
  }
};

const getLabels = (reCurringType, values, recurringDays) => {
  if (reCurringType === "daily") {
    return (
      <span className="welcomeMsg f_size_15">{`Every ${values.repeatEvery
        } days, ${values.endBy?.type === "by"
          ? `until ${tsToDate(new Date(values.endBy?.value).getTime())}`
          : `${values.endBy?.value} occurrence(s)`
        } `}</span>
    );
  } else if (reCurringType === "weekly") {
    return (
      <span className="welcomeMsg f_size_15">{`Every week on ${values?.week?.map(
        (data) => data
      )} , ${values.endBy?.type === "by"
        ? `until ${tsToDate(new Date(values.endBy?.value).getTime())}`
        : `${values.endBy?.value} occurrence(s)`
        } `}</span>
    );
  } else if (reCurringType === "monthly") {
    return (
      <span className="welcomeMsg f_size_15">{`Every month on the ${values?.OccOccursOn?.type === "days"
        ? `${values?.OccOccursOn?.value} of the month `
        : `${values?.OccOccursOn?.value?.week} ${values?.OccOccursOn?.value?.day} `
        } , ${values?.endBy?.type === "by"
          ? `until ${tsToDate(new Date(values?.endBy?.value).getTime())}`
          : `${values?.endBy?.value} occurrence(s)`
        } `}</span>
    );
  } else if (reCurringType === "notFixed") {
    return <span className="welcomeMsg f_size_15">Meet Anytime</span>;
  }
};

const getInitial = (meetingDetail) => {
  return {
    name: meetingDetail?.name || "",
    startOn: meetingDetail?.metaResponse?.mstartTs
      ? new Date(meetingDetail?.metaResponse?.mstartTs)
      : new Date(),
    duration: meetingDetail?.scheduleDuration || { hr: 0, min: 0 },
    description: meetingDetail?.description || "",
    reCurring: meetingDetail?.isRecurring,
    reCurringType: meetingDetail?.recurringType || "daily",
    repeatEvery: 1,
    endType: meetingDetail?.scheduleMeetingSummary?.endBy || {
      type: "by",
      value: new Date(),
    },
    OccursOn: meetingDetail?.scheduleMeetingSummary?.OccOccursOn || {
      type: "days",
      value: "1",
    },
    weekly: meetingDetail?.scheduleMeetingSummary?.week || ["MON"],
    isConform: false,
  };
};

const MeetDetails = (props) => {
  const dispatch = useDispatch();
  const { meetingDetail, startMeetingLoading, error } = useSelector(
    (state) => state.conference
  );
  const { gAuthURL, event, gAuthError, gAuthTokenVerified } = useSelector((state) => state.event);
  const paras = useParams();
  const [meetUrl, setMeetUrl] = useState(
    `${myconfig.serverURL}/join_meeting?mid=${paras.id}`
  );
  const [initialValue, setInitialValue] = useState(getInitial(meetingDetail));
  const [isEdited, setIsEdited] = useState(0);
  const [isEditRecurring, setIsEditRecurring] = useState(false);
  var mid = queryString.parse(props.location.search)?.mid;

  useEffect(() => {
    if (paras.id && !error) {
      dispatch(conferenceActions.meetingDetail(paras.id, mid));
    }
    if (!gAuthURL && !gAuthError) {
      dispatch(eventActions.gAuthURL());
    }
  }, [paras]);

  useEffect(() => {
    if (meetingDetail && meetingDetail?.only_registered) {
      setMeetUrl(`${myconfig.serverURL}/register_meeting?mid=${paras.id}`);
    }
    if (meetingDetail) {
      setInitialValue(getInitial(meetingDetail));
    }
  }, [meetingDetail]);



  const updateApi = (formData) => {
    const startOn = new Date(formData.startOn);
    const start_date = `${("0" + startOn.getDate()).slice(-2)}-${(
      "0" +
      (startOn.getMonth() + 1)
    ).slice(-2)}-${startOn.getFullYear()}`;
    const start_time = `${("0" + startOn.getHours()).slice(-2)}:${(
      "0" + startOn.getMinutes()
    ).slice(-2)}`;
    const endTime = new Date(
      startOn.getFullYear(),
      startOn.getMonth(),
      startOn.getDate(),
      startOn.getHours() + parseInt(formData.duration.hr),
      startOn.getMinutes() + parseInt(formData.duration.min)
    );
    const end_date = `${("0" + endTime.getDate()).slice(-2)}-${(
      "0" +
      (endTime.getMonth() + 1)
    ).slice(-2)}-${endTime.getFullYear()}`;
    const end_time = `${("0" + endTime.getHours()).slice(-2)}:${(
      "0" + endTime.getMinutes()
    ).slice(-2)}`;

    const formSubmit = {
      meetingId: paras.id,
      meetName: formData.name,
      description: formData.description,
      start_date,
      start_time,
      startTS: startOn.getTime(),
      endTS: endTime.getTime(),
      scheduleDuration: formData.duration,
      end_date,
      end_time,
      form_type: "advance",
      startTimeStampUTC: moment.utc(start_date).unix(),
      room_type: meetingDetail?.waitingRoom,
      allow_admin_unmute_user: true,
      allow_start_or_stop_recording: meetingDetail?.allowStartStopRecording,
      auto_record: meetingDetail["autoStartRecording"],
      camera_access: meetingDetail["userdata-sarv_listen_only_mode"],
      check_audio: meetingDetail["userdata-sarv_skip_check_audio"],
      start_mute: true,
      // join_password: meetingDetail?.join_password || false,
      join_without_host: meetingDetail?.join_without_host,
      only_registered: meetingDetail?.only_registered || false,
      regCustomFields: formData.formType || [],
      dynamic_password: meetingDetail?.dynamic_password,
      password: meetingDetail?.attendeePW || "",
      participant: meetingDetail?.participant
        ? meetingDetail?.participant.map(({ v }) => v)
        : [],
    }
    if (isEdited === 2) {
      formSubmit.type = "occurence";
      formSubmit.reCurring = formData.reCurring;
      formSubmit.reCurringType = formData.reCurringType || false;

      if (formData.reCurring) {
        if (formData.reCurringType === "daily") {
          formSubmit.scheduleMeetingSummary = {
            repeatEvery: formData.repeatEvery,
            endBy: formData.endType,
          };
        }

        if (formData.reCurringType === "weekly") {
          formSubmit.scheduleMeetingSummary = {
            repeatEvery: formData.repeatEvery,
            endBy: formData.endType,
            week: formData.weekly,
          };
        }

        if (formData.reCurringType === "monthly") {
          formSubmit.scheduleMeetingSummary = {
            repeatEvery: formData.repeatEvery,
            endBy: formData.endType,
            OccOccursOn: formData.OccursOn,
          };
        }
      }
    } else {
      formSubmit.type = "single";
      formSubmit.id = mid
    }
   // dispatch(conferenceActions.editMeet(formSubmit));
   conferenceServices.editSubMeeting(formSubmit).then(response => {
    dispatch(alertActions.success("!Meet is update successfully"));
    setIsEdited(0);
    props.history.push('/meeting_history')
   }).catch(error => {
    dispatch(alertActions.error(error.toString()));
    setIsEdited(2);
   })
    
  };


  const deleteMeetingConfirm = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmationPopup
            message={
              <>
                {" "}
                <div>Are you sure want to cancel this meeting ?</div>{" "}
                {meetingDetail?.isRecurring
                  ? "It also cancel  all occurrence."
                  : ""}
              </>
            }
            noBtnAction={onClose}
            noBtnText="No"
            yesBtnText="Yes, Cancel AnyWay!"
            yesBtnAction={() =>
              dispatch(scheduleActions.cancelSchedule(paras.id))
            }
          />
        );
      },
    });
  };

  const UpdateMeeting = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <CustomModal
            show={true}
            toggle={() => onClose()}
            label={<h3> Are you sure want to update this meeting ? </h3>}
          >
            <div className="file_sharng_sct text-center">
              <div className="around mt-3	 w-100 text-center">
                <button
                  onClick={() => onClose()}
                  className="btnStyles  btn-secondary"
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    onClose();
                    setIsEdited(1);
                  }}
                  disabled={isEmpty(mid)}
                  className="btnStyles  btn-secondary"
                >
                  {meetingDetail?.isRecurring ? "Only this occurrences" : "Yes, Update"}
                </button>
                {meetingDetail?.isRecurring && <button
                  className="btnStyles btn-danger mr-3"
                  onClick={() => {
                    onClose();
                    setIsEdited(2);
                  }}
                >
                  All occurrences
                </button>}
              </div>
            </div>
          </CustomModal>
        );
      },
    });
  };


  const cancelMeeting = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <CustomModal
            show={true}
            toggle={() => onClose()}
            label={<h3> Are you sure want to cancel this meeting ? </h3>}
          >
            <div className="file_sharng_sct text-center">
              <div className="around mt-3	 w-100 text-center">
                <button
                  onClick={() => onClose()}
                  className="btnStyles  btn-secondary"
                >
                  Cancel
                </button>
                {meetingDetail?.isRecurring && <button
                  onClick={() => dispatch(scheduleActions.cancelSchedule(paras.id, mid))}
                  className="btnStyles  btn-secondary"
                >
                  Only this occurrences
                </button>}
                <button
                  className="btnStyles btn-danger mr-3"
                  onClick={() => dispatch(scheduleActions.cancelSchedule(paras.id))}
                >
                  {meetingDetail?.isRecurring ? "All occurrences" : "Yes, Cancel AnyWay!"}
                </button>
              </div>
            </div>
          </CustomModal>
        );
      },
    });
  };

  const UrlJson = [
    {
      id: 1,
      label: "Join URL",
      url: meetUrl,
    },
    {
      id: 2,
      label: "Join URL Without Password",
      url: `${meetUrl}&token=${meetingDetail?.attendeePW}`,
    },
    {
      id: 3,
      label: "Join As Moderator",
      url: `${meetUrl}${meetingDetail?.attendeePW ? `&token=${meetingDetail?.attendeePW}` : ""
        }${meetingDetail?.moderatorToken
          ? `&moderatorToken=${meetingDetail?.moderatorToken}`
          : ""
        }`,
    },
    {
      id: 4,
      label: "Invite Message",
      url: `Hello Please Join From URL : ${meetUrl} ${meetingDetail?.attendeePW
        ? `and Password : ${meetingDetail?.attendeePW}`
        : ""
        } Thank You `,
    },
  ];

  const GetURL = (data) => {
    if (data.id !== 2 || meetingDetail?.attendeePW) {
      return (
        <div key={`url-${data.id}`} className="mt-3">
          <label className="mb-0 ">{data.label}</label>
          <div className="d-flex urlSectionWithBtn align-items-center">
            <div className="url f_size_17  urlInputCopy">
              <span className={`truncate w-100`}> {data.url}</span>
              <button
                className="btnBgLess copyUrlBtn welcomeMsg"
                onClick={() => {
                  dispatch(alertActions.success("Copied !!!"));
                  navigator.clipboard.writeText(data.url);
                }}
              >
                <MdContentCopy />
              </button>
            </div>
            <Button
              className="btnCss m-0 ml-2 urlShareBtns"
              variant="outline-success"
              href={shareUrl(data.url, "whatsapp")}
            >
              <FaWhatsapp className="mr-1 f_size_20" /> Whatsapp
            </Button>
            <Button
              className="btnCss m-0 ml-2 urlShareBtns"
              variant="outline-primary"
              href={shareUrl(data.url, "mail")}
            >
              <MdOutlineEmail className="mr-1 f_size_20" /> Mail
            </Button>
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  };

  const startMeet = () => {
    if (meetingDetail?.waitingRoom) {
      const redirectPath = myconfig.serverURL + "/join_meeting?mid=" + paras.id;
      window.location.href = redirectPath;
    } else {
      dispatch(conferenceActions.startMeeting(paras.id, "meeting"));
    }
  };

  const PasswordType = () => {
    if (meetingDetail?.dynamic_password) {
      return (
        <>
          {" "}
          Dynamic Password{" "}
          {meetingDetail?.dynamic_password && (
            <span className="ml-2  f_size_12">
              {" "}
              (every invite have it on unique password )
            </span>
          )}{" "}
        </>
      );
    } else if (meetingDetail?.attendeePW) {
      return meetingDetail?.attendeePW;
    } else {
      return "No Required";
    }
  };

  const openGoogleAuth = () => {
    if (gAuthURL !== null) {
      if (typeof win === undefined) {
        var win;
      }
      if (win && !win.closed) {
        win.focus();
      } else {
        let x = window.screen.width / 2 - 550 / 2;
        //let y = 0;
        let REDIRECT = "meetings";
        let y = window.screen.height / 2 - 280 / 2;
        win = window.open(
          gAuthURL,
          "win",
          "width=550,height=560,left=" +
          x +
          ",top=" +
          y +
          ",resizable=1,menubar=yes"
        );
        win.focus();
        let self = this;
        var pollTimer = window.setInterval(function () {
          try {
            if (win.document.URL.indexOf(REDIRECT) != -1) {
              window.clearInterval(pollTimer);
              var url = win.document.URL;
              let gCode = gup(url, "code");
              //let tokenType = gup(url, 'token_type');
              //let expiresIn = gup(url, 'expires_in');
              win.close();
              if (
                gCode !== null &&
                gCode !== "null" &&
                gCode !== "undefined" &&
                typeof gCode !== undefined
              ) {
                dispatch(eventActions.gAuthToken(gCode));
              }
            }
          } catch (e) {
            if (win.closed) {
              window.clearInterval(pollTimer);
              dispatch(alertActions.error("Authorization Failed !"));
            }
          }
        }, 500);

        function gup(url, name) {
          name = name.replace(/[[]/, "[").replace(/[]]/, "]");
          var regexS = "[?&]" + name + "=([^&#]*)";
          var regex = new RegExp(regexS);
          var results = regex.exec(url);
          if (results == null) return "";
          else return results[1];
        }
      }
    }
  };

  const BtnGoogle = useCallback((data) => {
    if (
      meetingDetail?.isRecurring &&
      meetingDetail?.recurringType === "notFixed"
    ) {
      return null;
    }
    if (
      (data?.googleCalendarLink && typeof gAuthURL === "object") ||
      (event?.id === meetingDetail.meetingID && event?.events)
    ) {
      return (
        <Button
          rel="noopener noreferrer"
          className=" btnSectionCard auth sideBarIcon welcomeMsg f_size_16 font-weight-normal"
          href={
            data?.googleCalendarLink ? data?.googleCalendarLink : event.events
          }
          target="_blank"
        >
          Google Calendar
        </Button>
      );
    } else if (gAuthTokenVerified || gAuthURL && typeof gAuthURL === "object") {
      return (
        <Button
          className=" btnSectionCard auth sideBarIcon welcomeMsg f_size_16 font-weight-normal"
          onClick={() =>
            dispatch(eventActions.addEventOnGoogle(data?.meetingID))
          }
        >
          Add Event on Google
        </Button>
      );
    } else {
      return (
        <Button
          className=" btnSectionCard auth sideBarIcon welcomeMsg f_size_16 font-weight-normal"
          onClick={() => openGoogleAuth()}
        >
          Auth Google
        </Button>
      );
    }
  }, [event, gAuthURL, meetingDetail, gAuthTokenVerified]);

  return (
    <Layout
      title={meetingDetail?.name || "Meeting"}
      active="Basic Info"
      list="meeting"
      goBack="/meeting_history"
    >
      {!meetingDetail || meetingDetail.meetingID !== paras.id ? (
        <div className="text-center">
          <Spinner />
        </div>
      ) : (
        <div className="container s_wave_content_part pl-3 pr-4 meetingBaseInfo">
          {startMeetingLoading && (
            <Modal show={startMeetingLoading} contentClassName="modalLoading">
              <Spinner />
            </Modal>
          )}

          <Formik
            initialValues={initialValue}
            onSubmit={(value) => updateApi(value)}
            validationSchema={Yup.object().shape({
              name: Yup.string().required('please Enter title!').matches(/^[0-9aA-zZ\s]+$/, "Only alphabets are allowed for this field!").test("title", "Meeting name must be at least five characters", (value) => {
                if (isEmpty(value)) return true;
                return value.length > 4
              }),
            })}
            enableReinitialize
          >
            {({
              values,
              setFieldValue,
              errors,
              touched,
              setFieldTouched,
              resetForm,
              handleSubmit,
            }) => (
              <div className="detailsSection w-100 sideBar welcomeMsg">
                <div className="between text-capitalize f_size_15 text-muted">
                  <div>
                    {isEdited !== 0 && (
                      <button
                        className="submitBtnColorBorder headerBtn editBtn mr-4"
                        onClick={() => {
                          resetForm();
                          setIsEdited(0);
                        }}
                      >
                        <span className="f_size_16">Cancel</span>
                      </button>
                    )}
                    <button
                      className="submitBtnColor headerBtn editBtn mr-4"
                      onClick={() => {
                        isEdited !== 0 ? handleSubmit() : meetingDetail?.isRecurring ? UpdateMeeting() : setIsEdited(1)
                      }}
                      type="button"
                    >
                      <span className="f_size_16">
                        {isEdited !== 0 ? (
                          <> Update </>
                        ) : (
                          <>
                            <FaEdit className="mr-2 f_size_18" /> Edit
                          </>
                        )}
                      </span>
                    </button>
                    {BtnGoogle(meetingDetail)}
                  </div>
                  <div>Create Date : {tsToDate(meetingDetail.create_date)}</div>
                </div>
                <Row className="mt-3">
                  <Col lg={4} className="meetingTagSection">
                    <label className="mb-0">Meeting Name</label>
                    <div className="title welcomeMsg  d-flex  align-items-center justifyCenter">
                      {isEdited > 0 ? (
                        <InputFiled
                          name="name"
                          type="text"
                          className="inputBgTheme welcomeMsg"
                        />
                      ) : (
                        <div className="textBreak">
                          <FaVideo className="f_size_28 mr-2" />
                          {meetingDetail?.name}
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col lg={4} className="meetingTagSection">
                    <label className="mb-0">Meeting ID</label>
                    <div className="welcomeMsg  d-flex  align-items-center f_size_18 justifyCenter">
                      {meetingDetail.meetingID}
                    </div>
                  </Col>
                  <Col lg={4} className="text-right meetingButtonSection">
                    <button
                      className="btnCss border-0 bg-success text-white btnFont"
                      onClick={() => startMeet()}
                    >
                      Start Meeting
                    </button>
                    <button
                      className="btnCss border-0 mr-2 bg-danger text-white btnFont"
                      onClick={() => cancelMeeting()}
                    >
                      Cancel Meeting
                    </button>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col lg={3} className="meetingTagSection">
                    <label className="mb-0">Password</label>
                    <div className="welcomeMsg f_size_16">
                      <div className="f_size_15">
                        <PasswordType />
                      </div>
                    </div>
                  </Col>
                  <Col lg={3} className="p-0 meetingTagSection">
                    <label className="mb-0">Start On</label>
                    <div className="welcomeMsg  d-flex  align-items-center f_size_15 justifyCenter">
                      {isEdited > 0 ? (
                        <div className={`position-relative datePicker`}>
                          <DatePicker
                            selected={values.startOn}
                            onChange={(value) =>
                              setFieldValue("startOn", value)
                            }
                            showTimeSelect
                            dateFormat="MMMM d, yyyy h:mm aa"
                            withPortal
                            className="form-control w-100 inputColorTheme"
                            minDate={new Date()}
                            maxDate={new Date().setUTCFullYear(
                              new Date().getFullYear() + 2
                            )}
                          />
                          {/* <AiOutlineCalendar className='iconInput  f_size_20 welcomeMsg' /> */}
                          {touched?.startOn && errors?.startOn && (
                            <div className="error text-danger f_size_13">
                              {errors?.startOn}
                            </div>
                          )}
                        </div>
                      ) : meetingDetail?.metaResponse?.m_start_date &&
                        meetingDetail?.metaResponse?.m_start_time ? (
                        startTime(
                          meetingDetail?.metaResponse?.m_start_date,
                          meetingDetail?.metaResponse?.m_start_time
                        )
                      ) : (
                        tsToDate(meetingDetail?.startTS)
                      )}
                    </div>
                  </Col>
                  <Col lg={3} className="p-0 meetingTagSection">
                    {(meetingDetail?.scheduleDuration?.hr || isEdited > 0) && (
                      <>
                        <label className="mb-0">Duration</label>
                        <div className="welcomeMsg  d-flex  align-items-center f_size_15 justifyCenter">
                          {isEdited > 0 ? (
                            <div className="d-flex w-100">
                              <InputGroup className="d-flex">
                                <select
                                  onChange={(event) =>
                                    setFieldValue(
                                      "duration.hr",
                                      event.target.value
                                    )
                                  }
                                  value={values.duration.hr}
                                  placeholder="Hr"
                                  className="form-control  inputColorTheme "
                                  aria-describedby="hour"
                                >
                                  {Array.from({ length: 25 }).map(
                                    (data, index) => (
                                      <option
                                        key={`duration-${index}`}
                                        value={index}
                                      >
                                        {index}
                                      </option>
                                    )
                                  )}
                                </select>
                                <InputGroup.Text
                                  id="hour"
                                  className={` inputColorTheme `}
                                >
                                  Hr
                                </InputGroup.Text>
                              </InputGroup>
                              <InputGroup className="d-flex">
                                <select
                                  onChange={(event) =>
                                    setFieldValue(
                                      "duration.min",
                                      event.target.value
                                    )
                                  }
                                  value={values.duration.min}
                                  placeholder="Hr"
                                  className="form-control  inputColorTheme ml-3"
                                  aria-describedby="minutes"
                                >
                                  <option value="0">0</option>
                                  <option value="15">15</option>
                                  <option value="30">30</option>
                                  <option value="45">45</option>
                                </select>
                                <InputGroup.Text
                                  id="minutes"
                                  className={` inputColorTheme`}
                                >
                                  Min
                                </InputGroup.Text>
                              </InputGroup>
                            </div>
                          ) : (
                            `${meetingDetail?.scheduleDuration?.hr} Hours, ${meetingDetail?.scheduleDuration?.min} Min`
                          )}
                        </div>
                      </>
                    )}
                  </Col>
                  {(meetingDetail?.isRecurring || isEdited == 2) && (
                    <Col lg={3} className="pr-0 meetingTagSection">
                      {isEdited == 2 ? (
                        <Form.Check
                          label={
                            <label className="mb-0">
                              {" "}
                              Recurring meeting{" "}
                              {values.reCurring && (
                                <button
                                  className="submitBtnColor text-white borderRadius pl-3 pr-3 border-0 "
                                  onClick={() => setIsEditRecurring(true)}
                                >
                                  Edit
                                </button>
                              )}{" "}
                            </label>
                          }
                          name="group1"
                          type="checkbox"
                          checked={values.reCurring}
                          onChange={() =>
                            setFieldValue("reCurring", !values.reCurring)
                          }
                        />
                      ) : (
                        <label className="mb-0"> Recurring meeting </label>
                      )}

                      <div className="welcomeMsg  d-flex  align-items-center f_size_15 justifyCenter">
                        {meetingDetail?.isRecurring
                          ? getLabels(
                            meetingDetail.recurringType,
                            meetingDetail.scheduleMeetingSummary
                          )
                          : "No Recurring"}
                      </div>
                    </Col>
                  )}
                </Row>

                {(meetingDetail.description || isEdited > 0) && (
                  <>
                    <label className="mb-0 mt-3">Description </label>
                    <div className="welcomeMsg  mh75 f_size_15">
                      {isEdited > 0 ? (
                        <>
                          <Form.Control
                            as="textarea"
                            className="form-control w-100 inputColorTheme"
                            rows={3}
                            value={values.description}
                            onChange={(event) =>
                              setFieldValue("description", event.target.value)
                            }
                          />
                          {touched.description && errors.description && (
                            <div className="error text-danger f_size_13">
                              {errors.description}
                            </div>
                          )}
                        </>
                      ) : (
                        <PerfectScrollbar>
                          {meetingDetail.description}
                        </PerfectScrollbar>
                      )}
                    </div>
                  </>
                )}

                {!meetingDetail?.dynamic_password && (
                  <div className="secondSection w-100 bgColorBodyCard mt-5">
                    <h5 className="welcomeMsg">Invite Team</h5>
                    <div className="pl-3 mt-3">
                      {UrlJson.map((data) => GetURL(data))}
                    </div>
                  </div>
                )}
                {isEditRecurring && (
                  <EditRecurring
                    show={isEditRecurring}
                    toggle={() => setIsEditRecurring(false)}
                    values={values}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    getReset={() => {
                      resetForm();
                      setIsEditRecurring(false);
                    }}
                  />
                )}
              </div>
            )}
          </Formik>
        </div >
      )}
    </Layout >
  );
};

export default MeetDetails;
