import cookie from 'react-cookies';
import { siteTokens } from '../config/config';

var userconfigs = (function () {
    
    var item_val = "";

    const getItem = (item) => {
        const expires = new Date()
        expires.setDate(Date.now() + 86340)

        if (item === "jwt") {
            siteTokens.map((site) => {
                if (window.location.hostname === site.url) {
                    item_val = localStorage.getItem(site.token) || cookie.load(site.url);
                    if (item_val) {
                        if (!cookie.load(site.url)) {
                            cookie.save(site.token, item_val, { path: "/", maxAge: 86340, expires, domain: site.subdomainExtension })
                        }
                        if (!localStorage.getItem(site.token)) {
                            localStorage.setItem(site.token, item_val);
                        }
                    }
                }
            })
        } else {
            if (cookie.load(item) === null || typeof cookie.load(item) !== "undefined") {
                item_val = cookie.load(item);
                localStorage.setItem(item, item_val);
            }
            else {
                item_val = localStorage.getItem(item);
            }
        }
        return item_val;
    }






    // var getItem = function (item) { //->  //---> it Old  One -> 20 Dec
    //     //if (localStorage.getItem(item) == null || typeof localStorage.getItem(item) != "undefined") {
    //     const expires = new Date()
    //     expires.setDate(Date.now() + 2592000000)
    //     if (item === "jwt") {
    //         if (cookie.load('jwt') === null || typeof cookie.load('jwt') !== "undefined") {

    //             siteTokens.map(site => { 
    //                 if (window.location.hostname === site.url) {
    //                     if (cookie.load(site.token) === null || typeof cookie.load(site.token) !== "undefined") {
    //                         item_val = cookie.load(site.token);
    //                         cookie.save('jwt', item_val, { path: "/", maxAge: 2592000, expires, domain: site.url !== "localhost" ? ".sarv.com" : "localhost" })
    //                         localStorage.setItem('jwt', item_val);
    //                         localStorage.setItem(site.token, item_val);
    //                     }
    //                     else {
    //                         item_val = localStorage.getItem('jwt')
    //                         item_val = localStorage.getItem(site.token);
    //                         cookie.save(site.token, item_val, { path: "/", maxAge: 2592000, expires, domain: site.url !== "localhost" ? ".sarv.com" : "localhost" })
    //                         localStorage.setItem(site.token, item_val);
    //                     }
    //                 }
    //             })


    //             // siteTokens.map((site) => {
    //             //     if (window.location.hostname === site.url) {
    //             //         item_val = localStorage.getItem(site.token) || cookie.load(site.url);
    //             //         if (item_val) {
    //             //             if (!cookie.load(site.url)) {
    //             //                 cookie.save(site.token, item_val, { path: "/", maxAge: 2592000, expires, domain: site.url !== "localhost" ? ".sarv.com" : "localhost" })
    //             //             }
    //             //             if (!localStorage.getItem(site.token)) {
    //             //                 localStorage.setItem(site.token, item_val);
    //             //             }
    //             //         }
    //             //     }
    //             // })


    //             // if(window.location.hostname === "wave.sarv.com"){
    //             //     if (cookie.load('whash') === null || typeof cookie.load('whash') !== "undefined") {
    //             //         item_val = cookie.load('whash');
    //             //         cookie.save('jwt',item_val,{path:"/",maxAge: 2592000,expires,domain:".sarv.com"})
    //             //         localStorage.setItem('jwt', item_val);
    //             //         localStorage.setItem('whash', item_val);
    //             //     }
    //             //     else{
    //             //         item_val = localStorage.getItem('jwt') 
    //             //         cookie.save('whash',item_val,{path:"/",maxAge: 2592000,expires,domain:".sarv.com"})    
    //             //         localStorage.setItem('whash', item_val);  
    //             //     }
    //             // }
    //             // else if(window.location.hostname === "meity.sarv.com") {
    //             //     if (cookie.load('mhash') === null || typeof cookie.load('mhash') !== "undefined") {
    //             //         item_val = cookie.load('mhash');
    //             //         cookie.save('jwt',item_val,{path:"/",maxAge: 2592000,expires,domain:".sarv.com"})
    //             //         localStorage.setItem('jwt', item_val);
    //             //         localStorage.setItem('mhash', item_val);
    //             //     }
    //             //     else{
    //             //         item_val = localStorage.getItem('jwt') 
    //             //         cookie.save('mhash',item_val,{path:"/",maxAge: 2592000,expires,domain:".sarv.com"})    
    //             //         localStorage.setItem('mhash', item_val);  
    //             //     }
    //             // }
    //             // else{
    //             //     if (cookie.load('dhash') === null || typeof cookie.load('dhash') !== "undefined") {
    //             //         item_val = cookie.load('dhash');
    //             //         cookie.save('jwt',item_val,{path:"/",maxAge: 2592000,expires,domain:".sarv.com"})
    //             //         localStorage.setItem('jwt', item_val);
    //             //         localStorage.setItem('dhash', item_val);
    //             //     }
    //             //     else{
    //             //         item_val = localStorage.getItem('jwt') 
    //             //         cookie.save('dhash',item_val,{path:"/",maxAge: 2592000,expires,domain:".sarv.com"})    
    //             //         localStorage.setItem('dhash', item_val);  
    //             //     }
    //             // }
    //         }
    //         else {

    //             siteTokens.map(site => {
    //                 if (window.location.hostname === site.url) {
    //                     item_val = localStorage.getItem(site.token);
    //                     cookie.save('jwt', item_val, { path: "/", maxAge: 2592000, expires, domain: site.url !== "localhost" ? ".sarv.com" : "localhost" })
    //                 }

    //             })


    //             // if(window.location.hostname === "wave.sarv.com"){
    //             //     item_val = localStorage.getItem('whash');
    //             //     cookie.save('jwt',item_val,{path:"/",maxAge: 2592000,expires,domain:".sarv.com"})
    //             // }
    //             // else if(window.location.hostname === "meity.sarv.com") {
    //             //     item_val = localStorage.getItem('mhash');
    //             //     cookie.save('jwt',item_val,{path:"/",maxAge: 2592000,expires,domain:".sarv.com"})
    //             // }
    //             // else{
    //             //     item_val = localStorage.getItem('dhash');
    //             //     if(window.location.hostname === "localhost"){
    //             //         cookie.save('jwt',item_val,{path:"/",maxAge: 2592000,expires,domain:"localhost"})
    //             //     }
    //             //     else{
    //             //         cookie.save('jwt',item_val,{path:"/",maxAge: 2592000,expires,domain:".sarv.com"})
    //             //     }
    //             // }
    //         }
    //     }
    // else {
    //     if (cookie.load(item) === null || typeof cookie.load(item) !== "undefined") {
    //         item_val = cookie.load(item);
    //         localStorage.setItem(item, item_val);
    //     }
    //     else {
    //         item_val = localStorage.getItem(item);
    //     }
    // }
    // return item_val;
    // }

    var setItem = function (type, value) {

        const expires = new Date()
        expires.setDate(Date.now() + 86340)

        //site.url !== "localhost" ? ".sarv.com" : "localhost"

        if (type === 'jwt') {
            siteTokens.map(site => {
                if (window.location.hostname === site.url) {
                    cookie.save(site.token, value, { path: "/", maxAge: 86340, expires, domain: site.subdomainExtension })
                    localStorage.setItem(site.token, value);
                }
            })
        }
        else { // -->  it need here
            siteTokens.map(site => {
                if (window.location.hostname === site.url) {
                    cookie.save(type, value, { path: "/", maxAge: 86340, expires, domain: site.subdomainExtension })
                    localStorage.setItem(type, value);
                }
            })
        }

        // cookie.save(type, value);
        // if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
        //     if(type === "jwt"){
        //         cookie.save('dhash',value,{path:"/",maxAge: 2592000,expires,domain:"localhost"})    
        //         localStorage.setItem('dhash', value);
        //     }
        //     cookie.save(type,value,{path:"/",maxAge: 2592000,expires,domain:"localhost"})
        //     localStorage.setItem(type, value);
        //     //document.cookie = type + "=" + value + ";domain=localhost;path=/";
        // }
        // else {
        //     if(type === "jwt"){
        //         if(window.location.hostname === "wave.sarv.com"){
        //             cookie.save('whash',value,{path:"/",maxAge: 2592000,expires,domain:".sarv.com"})    
        //             localStorage.setItem('whash', value);
        //         }
        //         else if(window.location.hostname === "meity.sarv.com") {
        //             cookie.save('mhash',value,{path:"/",maxAge: 2592000,expires,domain:".sarv.com"})    
        //             localStorage.setItem('mhash', value);
        //         }
        //         else{
        //             cookie.save('dhash',value,{path:"/",maxAge: 2592000,expires,domain:".sarv.com"})    
        //             localStorage.setItem('dhash', value);
        //         }
        //     }
        //     cookie.save(type,value,{path:"/",maxAge: 2592000,expires,domain:".sarv.com"})
        //     localStorage.setItem(type, value);
        //     //document.cookie = type + "=" + value + ";domain=.sarv.com;path=/";
        // }
    }

    var removeItem = function (item) {
        if (item === 'jwt') {
            siteTokens.map(site => {
                if (window.location.hostname === site.url) {
                    cookie.remove(site.token, { path: "/", domain: site.subdomainExtension })
                    localStorage.removeItem(site.token);
                    cookie.remove(item, { path: "/", domain: site.subdomainExtension })
                    localStorage.removeItem(item);
                }
            })
        }
        cookie.remove(item, { path: "/" });
        localStorage.removeItem(item);
    }

    return {
        getItem: getItem,
        setItem: setItem,
        removeItem: removeItem
    }

})();

export default userconfigs;