import React, { useEffect, useState } from "react";
import { Dropdown/* , OverlayTrigger, Tooltip */ } from "react-bootstrap";
import { Link/* , withRouter */ } from "react-router-dom";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import jwt_decode from "jwt-decode";
import { TiArrowLeftThick } from "react-icons/ti";
import { GiHamburgerMenu } from "react-icons/gi";

import "./styles.scss";
import userconfigs from "../helpers/closures";
import {
  userActions,
 /*  joinActions,
  alertActions, */
  companyActions,
  themeActions,
} from "../Redux/_actions";
import ConfirmationPopup from "./../components/UI/ConfirmationPopup/confirmationPopup";

// let user
// try {
//   user = jwt_decode(userconfigs.getItem("jwt"));
// } catch (err) {
// }


const Header = (props) => {
  const { title, goBack, headerLeft, setToggle, toggle } = props;
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.authentication)
  const { theme } = useSelector((state) => state.themeChange);
  const history = useHistory()
  const [userData, setUser] = useState(null)

  useEffect(() => {
    if (!userData) {
      try {
        setUser(jwt_decode(user))
      } catch (err) {
        setUser(jwt_decode(userconfigs.getItem("jwt")))
      }
    }
  }, [user])




  const signOutClick = () => {
    if (
      typeof userconfigs.getItem("fp") !== "undefined" &&
      userconfigs.getItem("fp") !== null
    ) {
      dispatch(userActions.logout());
    } else {
      history.push("/")
    }
  };

  const exitBusiness = () => {
    let data = {
      cuid: localStorage.getItem("eOfficeId"),
    };
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmationPopup
            message={"Are you sure want to exit from this business ?"}
            noBtnAction={onClose}
            noBtnText="No"
            yesBtnText="Yes, Delete AnyWay!"
            yesBtnAction={() => dispatch(companyActions.decline(data))}
          />
        );
      },
    });
  };

  return (
    <div className="headerContainer ml-2 mr-2">
      <div className="d-flex align-items-start">
        {
          window.outerWidth < 1201 &&
          <button className="btnBgLess welcomeMsg" title="Click to go back" onClick={() => setToggle(!toggle)}> <GiHamburgerMenu className="f_size_22 mt-1" /> </button>
        }
        {
          goBack &&
          <button className="btnBgLess welcomeMsg" title="Click to go back" onClick={() => typeof goBack === 'string' ? history.push(goBack) : history.goBack()}> <TiArrowLeftThick className="f_size_22 mt-1" /> </button>
        }
        <h3 className={`ml-4  welcomeMsg   mt-0 font-weight-normal ${typeof (title) === "string" ? "headerTextLimit" : ""} `}>
          <span className="font-weight-bold text-capitalize">{title || 'Welcome'}</span> <span className="headerUserName" >{!title && userData?.name}</span>
        </h3>
      </div>
      <Dropdown className="dropDownRightSection dropDownPart">
        {headerLeft || ''}
        {theme ? (
          <button
            className="text-light btn-link btn"
            onClick={() => dispatch(themeActions.changeThemeColor())}
          >
            <i className="fas fa-moon"></i>
          </button>
        ) : (
          <button
            className="sunColor btn-link btn"
            onClick={() => dispatch(themeActions.changeThemeColor())}
          >
            <i className="fas fa-sun"></i>
          </button>
        )}

        <Dropdown.Toggle className="nameDropDown border-0 bg-transparent welcomeMsg f_size_14">
          {
            userData?.imageUrl ?
              <img src={userData.imageUrl} alt="logo" className="profileImg" /> :
              <span className="profileImg">{userData?.name?.charAt(0).trim().toUpperCase()}</span>
          }
          <label className="userNameCss">  {userData?.name}</label>
        </Dropdown.Toggle>

        <Dropdown.Menu className="optionDropDown sideBar">

          <Dropdown.Item className="welcomeMsg" >
            <Link to="/profile">
              <i className="fas fa-user-circle mr-1"></i> My Profile
            </Link>
          </Dropdown.Item>
          {localStorage.getItem("eOfficeId") !== undefined &&
            localStorage.getItem("eOfficeId") !== null && (
              <Dropdown.Item onClick={() => exitBusiness()} className="welcomeMsg">
                <Link to="#">
                  <i className="fas fa-user-circle mr-1"></i> Exit Business
                </Link>
              </Dropdown.Item>
            )}
          <Dropdown.Item onClick={() => signOutClick()} className="welcomeMsg">
            <i className="fas fa-sign-out-alt mr-1"></i> Logout
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default Header;
