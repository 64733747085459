import { alertActions } from './';
import { roleService } from './../_services';
import { roleConstants } from './../_constants';

export const roleActions = {
    addRole,
    listRoles,
    editRole,
    getRoleById

};

function editRole(roleData, id) {
    return dispatch => {
        dispatch(request({}));

        roleService.editRole(roleData, id)
            .then(
                resp => {
                    dispatch(alertActions.success(resp.toString()));
                    dispatch(success(resp))
                },
                error => dispatch(alertActions.error(error.toString()))
            );
    };

    function request(resp) { return { type: roleConstants.ROLE_EDIT_REQUEST } }
    function success(resp) { return { type: roleConstants.ROLE_EDIT_FAILURE } }
    function success(resp) { return { type: roleConstants.ROLE_EDIT_SUCCESS, resp } }
}

function addRole(roleData) {
    return dispatch => {
        dispatch(request({}));

        roleService.addRole(roleData)
            .then(
                resp => {
                    dispatch(alertActions.success(resp.toString()));
                    dispatch(success(resp))
                },
                error => dispatch(alertActions.error(error.toString()))
            );
    };

    function request(resp) { return { type: roleConstants.ROLE_ADD_REQUEST } }
    function success(resp) { return { type: roleConstants.ROLE_ADD_FAILURE } }
    function success(resp) { return { type: roleConstants.ROLE_ADD_SUCCESS, resp } }
}

function listRoles() {
    return dispatch => {
        dispatch(request({}));

        roleService.listRoles()
            .then(
                resp => {
                    dispatch(success(resp))
                    //console.log("actions",resp)
                },
                error => dispatch(alertActions.error(error.toString()))
            );
    };

    function request(resp) { return { type: roleConstants.ROLE_GETALL_REQUEST } }
    function success(resp) { return { type: roleConstants.ROLE_GETALL_SUCCESS, resp } }
}

function getRoleById(id) {
    return dispatch => {
        dispatch(request({}));

        roleService.getRoleByID(id)
            .then(
                resp => {
                    dispatch(success(resp))
                    //console.log("actions",resp)
                },
                error => dispatch(alertActions.error(error.toString()))
            );
    };

    function request(resp) { return { type: roleConstants.ROLE_BYID_REQUEST } }
    function success(resp) { return { type: roleConstants.ROLE_BYID_SUCCESS, resp } }
}

