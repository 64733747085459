import React from "react";
// import PropTypes from "prop-types";
import "./styles.scss";

const RegisterHeader = (props) => {
  const { webinarInfo } = props;
  let brandData = webinarInfo?.brandData;
  return (
    <section className="header-m">
      <div className="container-fluid">
        <div className="row py-3 d-flex align-items-center">
          <div className="col-lg-4 col-sm col-4">
            {brandData?.brand_logo ? (
              <div className="brand_logo">
                <img
                  src={brandData.brand_logo}
                  alt="brand_logo"
                  style={{ width: "90px", height: "90px", borderRadius: "50%" }}
                />{" "}
                {brandData?.brand_name ? (
                  <div className="brand_name" style={{ fontSize: "34px" }}>
                    {brandData.brand_name}
                  </div>
                ) : null}
              </div>
            ) : (
              <div>
                <img
                  src={process.env.PUBLIC_URL + "/images/wave-logo-sm.png"}
                  width="100"
                  alt="register_image"
                />
              </div>
            )}
          </div>
          <div className="col-lg-4  col-sm col-8">
            <h5 className="text-center text-white" style={{ fontSize: "34px" }}>
              {webinarInfo?.name}
            </h5>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RegisterHeader;
