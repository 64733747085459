import React, { Component } from 'react';
import { Input, Form, FormGroup, Label, Button, Col, Row } from 'reactstrap';
import './CompanyBrandingForm.scss';
import LeftPanelManager from '../../components/LeftPanelManager/LeftPanelManager';

// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { companyActions } from './../../../Redux/_actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import TimezoneSelect from 'react-timezone-select';
import PhoneInput from 'react-phone-input-2';
// import validator from 'validator';

class CompanyBranding extends Component {
    constructor() {
        super();
        this.state = {
            brandingForm: {
                file: null,
                logo: '',
                address: [{
                    title: '',
                    area: '',
                    state: '',
                    pin: '',
                    email1: '',
                    email2: '',
                    timezone: { value: "Asia/Calcutta", label: "(GMT+5:30) Chennai, Kolkata, Mumbai, New Delhi" },
                    contact1: '',
                    contact2: '',
                    countryCode1: '91',
                    countryCode2: '91',
                    errorObj: {}
                }],
                about: ''
            },
            isFormSubmitted: false,
            isBrandingFormValid: false,
            formError: {}

        }
    }

    componentDidMount() {
        this.props.getCompanyBranding();
    }

    async shouldComponentUpdate(nextProps, nextState) {
        if (this.props.logoURL !== nextProps.logoURL) {
            const formData = { ...this.state.brandingForm };
            formData['logo'] = nextProps.logoURL
            await this.setState({ ...this.state.brandingForm, brandingForm: formData });
            await this.vallidateForm();
        }
        return true;
    }
    componentDidUpdate = async (prevProps, prevState) => {
        if (prevProps.brandingData !== this.props.brandingData) {
            console.log(this.props.brandingData)
            const { about, addresses, logo } = this.props.brandingData;
            if (addresses !== undefined && addresses !== '') {
                await addresses.forEach(address => {
                    address.errorObj = {};
                });
            }

            const formData = { ...this.state.brandingForm };
            formData['file'] = logo || '';
            formData['about'] = about || '';
            formData['logo'] = logo || '';
            formData['address'] = addresses || [{
                title: '',
                area: '',
                state: '',
                pin: '',
                email1: '',
                email2: '',
                timezone: { value: "Asia/Calcutta", label: "(GMT+5:30) Chennai, Kolkata, Mumbai, New Delhi" },
                contact1: '',
                contact2: '',
                countryCode1: '91',
                countryCode2: '91',
                errorObj: {}
            }];
            await this.setState({ ...this.state.brandingForm, brandingForm: formData });
        }
    }

    handleLogoChange = async (event) => {
        let formImageData = new FormData();
        formImageData.append('csv', event.target.files[0]);
        this.props.uploadLogo(formImageData);
        const formData = { ...this.state.brandingForm };
        formData['file'] = URL.createObjectURL(event.target.files[0]);
        await this.setState({ ...this.state.brandingForm, brandingForm: formData });
    }

    async handleTimeZoneChange(e, index) {
        const formData = { ...this.state.brandingForm };
        formData.address[index].timezone = e;
        await this.setState({ ...this.state.brandingForm, brandingForm: formData });
    }

    async mobileNumberChange(val, country, index) {
        const mobile = val.slice(country.dialCode.length);
        const code = country.dialCode;
        const formData = { ...this.state.brandingForm };
        formData.address[index].countryCode1 = code;
        formData.address[index].contact1 = mobile;

        await this.setState({ ...this.state.brandingForm, brandingForm: formData });


    }

    async alternateMobileNumberChange(val, country, index) {
        const mobile = val.slice(country.dialCode.length);
        const code = country.dialCode;
        const formData = { ...this.state.brandingForm };
        formData.address[index].countryCode2 = code;
        formData.address[index].contact2 = mobile;

        await this.setState({ ...this.state.brandingForm, brandingForm: formData });


    }

    addMoreAddress = async () => {
        const Obj = {};
        Obj.title = '';
        Obj.area = '';
        Obj.state = '';
        Obj.pin = '';
        Obj.email1 = '';
        Obj.email2 = '';
        Obj.timezone = { value: "Asia/Calcutta", label: "(GMT+5:30) Chennai, Kolkata, Mumbai, New Delhi" };
        Obj.conatct1 = '';
        Obj.contact2 = '';
        Obj.countryCode1 = '91';
        Obj.countryCode2 = '91';
        Obj.errorObj = {};
        const formData = { ...this.state.brandingForm };
        formData.address.push(Obj);
        await this.setState({ ...this.state.brandingForm, brandingForm: formData });

    }
    async vallidateForm() {
        if (this.state.isFormSubmitted) {
            let errorObj = {};
            const formData = this.state.brandingForm;
            if (formData.file === '') {
                errorObj.logo = 'Please upload logo';
            }
            if (formData.about === '') {
                errorObj.about = 'Please enter about';
            }

            await formData.address.forEach((entry, index) => {
                if (entry.title === '') {
                    entry.errorObj.title = 'Please enter title'
                } else {
                    if (entry.hasOwnProperty('errorObj')) {
                        delete entry.errorObj.title
                    }
                }
                if (entry.pin === '') {
                    entry.errorObj.pin = 'Please enter pincode'
                } else {
                    if (entry.hasOwnProperty('errorObj')) {
                        delete entry.errorObj.pin
                    }
                }
                if (entry.area === '') {
                    entry.errorObj.area = 'Please enter area'
                } else {
                    if (entry.hasOwnProperty('errorObj')) {
                        delete entry.errorObj.area
                    }
                }
                if (entry.state === '') {
                    entry.errorObj.state = 'Please enter state'
                } else {
                    if (entry.hasOwnProperty('errorObj')) {
                        delete entry.errorObj.state
                    }
                }
                if (entry.email1 === '') {
                    entry.errorObj.email1 = 'Please enter primary email'
                } else {
                    if (entry.hasOwnProperty('errorObj')) {
                        delete entry.errorObj.email1
                    }
                }
            })

            let formValid;
            if (Object.keys(errorObj).length == 0 && formData.address.every(iteam => Object.keys(iteam.errorObj).length == 0)) {
                formValid = true;
            } else {
                formValid = false;
            }
            await this.setState({ ...this.state.brandingForm, brandingForm: formData, formError: errorObj, isBrandingFormValid: formValid });


        }
    }

    async removeAddress(index) {
        const formData = { ...this.state.brandingForm };
        formData.address.splice(index, 1);
        await this.setState({ ...this.state.brandingForm, brandingForm: formData });
    }

    async submitForm(event) {
        event.preventDefault();
        await this.setState({ isFormSubmitted: true });
        await this.vallidateForm();
        if (this.state.isBrandingFormValid && this.state.isFormSubmitted) {
            this.props.saveCompanyInfo(this.state.brandingForm);
        }
    }

    async handleUserInput(e, index = 0) {
        const name = e.target.name;
        const value = e.target.value;
        const formData = { ...this.state.brandingForm };
        if (name !== 'about') {
            formData.address[index][name] = value;
        } else {
            formData[name] = value;
        }
        await this.setState({ ...this.state.brandingForm, brandingForm: formData });
        await this.vallidateForm();
    }

    render() {

        return (
            <div className="contentContainer">
                <LeftPanelManager></LeftPanelManager>
                <div className="companyBranding-Content pageContentpanel">
                    <div>
                        <h2 className="title">Company Branding  </h2>
                        <p className="sub-title">You could be a business, an event organizer, or a professional community. Please fill below detail.</p>

                        <Form onSubmit={(event) => this.submitForm(event)}>
                            <div className="row">
                                <div className="col-md-6">
                                    <Label className="title-label mb-2">Company Logo</Label>
                                    <FormGroup className=" custom_file_control">
                                        <input type="file" id="brand_logo" onClick={(event) => { event.target.value = null }} className="brand_logo" onChange={this.handleLogoChange} />
                                        <label for="brand_logo" className="btn-upload"><i className="fas fa-upload"></i> <br />Upload Branding Logo</label>

                                    </FormGroup>
                                    {this.state.isFormSubmitted && this.state.formError.hasOwnProperty('logo') && <div style={{ color: 'red' }}>{this.state.formError.logo}</div>}
                                    <div className="uploaded-img">
                                        {this.state.brandingForm.file === null ? <p className="form-label-info">Image width should be Min size 100px X 100px to Max size 300px X 300px</p> :
                                            <img src={this.state.brandingForm.file} />}
                                    </div>

                                </div>

                                <div className="col-md-6">
                                    <Label className="title-label mb-2">Brief About Company</Label>
                                    <FormGroup className="comp-form-col">

                                        <Input type="textarea" onChange={(e) => this.handleUserInput(e)} name="about" value={this.state.brandingForm.about} placeholder="About" className="form-control-textarea" />
                                    </FormGroup>
                                    {this.state.isFormSubmitted && this.state.formError.hasOwnProperty('about') && <div style={{ color: 'red' }}>{this.state.formError.about}</div>}
                                </div>
                            </div>
                            <h3 className="title-md">Contact Information <Button className="btn-sm btn-success btn-add-more" onClick={this.addMoreAddress}><i className="fas fa-plus-circle"></i> Add More</Button></h3>
                            {this.state.brandingForm.address.map((address, index) =>
                                <div className="col-md-12 contact-info-section mb-4">
                                    <div className="row mb-2">
                                        <div className="col-md-6 col-6 form-legend-title" key={index}>Contact Address {index + 1} </div>
                                        <div className="col-md-6 col-6 text-right">

                                            {index >= 1 && <Button className="btn-sm btn-danger btn-add-more" onClick={() => this.removeAddress(index)}><i className="fas fa-times-circle"></i> Remove </Button>}
                                        </div>
                                    </div>


                                    <Row>
                                        <Col sm="4">
                                            <FormGroup className="form-col">
                                                <Label className="title-label">Title</Label>
                                                <Input type="text" value={this.state.brandingForm.address[index].title} onChange={(e) => this.handleUserInput(e, index)} name="title" placeholder="Enter Title Name" />
                                            </FormGroup>
                                            {this.state.isFormSubmitted && this.state.brandingForm.address[index].hasOwnProperty('errorObj') && this.state.brandingForm.address[index].errorObj.hasOwnProperty('title') && <div style={{ color: 'red' }}>{this.state.brandingForm.address[index].errorObj.title}</div>}
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup className="form-col">
                                                <Label className="title-label">Area</Label>
                                                <Input type="text" value={this.state.brandingForm.address[index].area} onChange={(e) => this.handleUserInput(e, index)} placeholder="Enter Area Loction Name" name="area" />
                                            </FormGroup>
                                            {this.state.isFormSubmitted && this.state.brandingForm.address[index].hasOwnProperty('errorObj') && this.state.brandingForm.address[index].errorObj.hasOwnProperty('area') && <div style={{ color: 'red' }}>{this.state.brandingForm.address[index].errorObj.area}</div>}
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup className="form-col">
                                                <Label className="title-label">State</Label>
                                                {/* <CustomInput type="select" name="customSelect" >
                                                    <option value="">Select State Name</option>

                                                </CustomInput> */}
                                                <Input type="text" value={this.state.brandingForm.address[index].state} onChange={(e) => this.handleUserInput(e, index)} placeholder="Enter state name" name="state" />

                                            </FormGroup>
                                            {this.state.isFormSubmitted && this.state.brandingForm.address[index].hasOwnProperty('errorObj') && this.state.brandingForm.address[index].errorObj.hasOwnProperty('state') && <div style={{ color: 'red' }}>{this.state.brandingForm.address[index].errorObj.state}</div>}

                                        </Col>

                                        <Col sm="4">
                                            <FormGroup className="form-col">
                                                <Label className="title-label">Pin</Label>
                                                <Input type="Number" value={this.state.brandingForm.address[index].pin} onChange={(e) => this.handleUserInput(e, index)} maxlength="6" placeholder="Enter Area Pin  Number" name="pin" />
                                            </FormGroup>
                                            {this.state.isFormSubmitted && this.state.brandingForm.address[index].hasOwnProperty('errorObj') && this.state.brandingForm.address[index].errorObj.hasOwnProperty('pin') && <div style={{ color: 'red' }}>{this.state.brandingForm.address[index].errorObj.pin}</div>}

                                        </Col>

                                        <Col sm="4">
                                            <FormGroup className="form-col">
                                                <Label className="title-label">Email Address 1</Label>
                                                <Input type="text" value={this.state.brandingForm.address[index].email1} onChange={(e) => this.handleUserInput(e, index)} placeholder="Enter Your Email Address" name="email1" />
                                            </FormGroup>
                                            {this.state.isFormSubmitted && this.state.brandingForm.address[index].hasOwnProperty('errorObj') && this.state.brandingForm.address[index].errorObj.hasOwnProperty('email1') && <div style={{ color: 'red' }}>{this.state.brandingForm.address[index].errorObj.email1}</div>}
                                        </Col>


                                        <Col sm="4">
                                            <FormGroup className="form-col">
                                                <Label className="title-label">Email Address 2</Label>
                                                <Input type="text" value={this.state.brandingForm.address[index].email2} onChange={(e) => this.handleUserInput(e, index)} placeholder="Enter Your Email Address" name="email2" />
                                            </FormGroup>
                                            {this.state.isFormSubmitted && this.state.brandingForm.address[index].hasOwnProperty('errorObj') && this.state.brandingForm.address[index].errorObj.hasOwnProperty('email2') && <div style={{ color: 'red' }}>{this.state.brandingForm.address[index].errorObj.email2}</div>}
                                        </Col>
                                        <Col sm="4">
                                            <FormGroup className="form-col mb-0 form-datepicker">
                                                <Label className="title-label">Time Zone</Label>

                                                <TimezoneSelect
                                                    value={this.state.brandingForm.address[index].timezone}
                                                    onChange={(e) => this.handleTimeZoneChange(e, index)}
                                                />

                                            </FormGroup>
                                        </Col>

                                        <Col sm="4">
                                            <FormGroup className="form-col mb-0">
                                                <Label className="title-label">Contact Number 1</Label>
                                                <div className="dtl_input45">
                                                    <PhoneInput
                                                        value={this.state.brandingForm.address[index].countryCode1 + this.state.brandingForm.address[index].contact1}
                                                        inputClass="phone-input"
                                                        enableSearch={true}
                                                        placeholder="Mobile No"
                                                        countryCodeEditable={false}
                                                        onChange={(value, country) => this.mobileNumberChange(value, country, index)}

                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>

                                        <Col sm="4">
                                            <FormGroup className="form-col mb-0">
                                                <Label className="title-label">Contact Number 2</Label>
                                                <div className="dtl_input45">
                                                    <PhoneInput
                                                        value={this.state.brandingForm.address[index].countryCode2 + this.state.brandingForm.address[index].contact2}
                                                        inputClass="phone-input"
                                                        enableSearch={true}
                                                        placeholder="Mobile No"
                                                        countryCodeEditable={false}
                                                        onChange={(value, country) => this.alternateMobileNumberChange(value, country, index)}

                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>


                                    </Row>

                                </div>
                            )}

                            <Col sm="12" className="text-center mt-5">
                                <Button className="btn-theme-primary">Save</Button>
                            </Col>
                        </Form>
                    </div>
                </div>
            </div>
        )
    }
}

const mapState = (state) => {
    const { loadingStatus, logoURL, brandingData } = state.company;

    return {
        loadingStatus,
        logoURL,
        brandingData
    };
}

const actionCreators = {
    uploadLogo: companyActions.uploadLogo,
    saveCompanyInfo: companyActions.saveCompanyInfo,
    getCompanyBranding: companyActions.getCompanyBranding
};


export default withRouter(connect(mapState, actionCreators)(CompanyBranding));

