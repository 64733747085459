import React from 'react'
import { Link } from "react-router-dom";
const Conversation = () => {
    return (
        <div className="listSection">
            <div className="title"><span className="bg-orange"><img src="../images/icons/iconuser.svg" width="12" alt="" /></span>Conversation</div>
            <ul className="listContent">
            <li><Link><span className="dotcirle rounded-circle green"></span>Akash Kumar</Link></li>
            <li><Link><span className="dotcirle rounded-circle yellow"></span>Bharti Yadav</Link></li>
            <li><Link><span className=""><img src="../images/icons/icon-mobile.svg" width="12" alt="" /></span>Ritika V</Link></li>
            <li><Link><span className="dotcirle rounded-circle green"></span>Sachin Kumar</Link></li>
            <li><Link><span><img src="../images/icons/icon-mobile.svg" width="12" alt="" /></span>Himanshu Porwal
            </Link></li>
            </ul>
        </div>
    )
}

export default Conversation;
