export const webinarRoomConstants = {
	SPEAKER_LIST_REQUEST: 'SPEAKER_LIST_REQUEST',
    SPEAKER_LIST_SUCCESS: 'SPEAKER_LIST_SUCCESS',
    SPEAKER_LIST_FAILURE: 'SPEAKER_LIST_FAILURE',

    ATTENDEES_LIST_REQUEST: 'ATTENDEES_LIST_REQUEST',
    ATTENDEES_LIST_SUCCESS: 'ATTENDEES_LIST_SUCCESS',
    ATTENDEES_LIST_FAILURE: 'ATTENDEES_LIST_FAILURE',

    SUBMIT_CHAT_REQUEST: 'SUBMIT_CHAT_REQUEST',
    SUBMIT_CHAT_SUCCESS: 'SUBMIT_CHAT_SUCCESS',
    SUBMIT_CHAT_FAILURE: 'SUBMIT_CHAT_FAILURE',

    CHAT_LIST_REQUEST: 'CHAT_LIST_REQUEST',
    CHAT_LIST_SUCCESS: 'CHAT_LIST_SUCCESS',
    CHAT_LIST_FAILURE: 'CHAT_LIST_FAILURE',

    HAND_RAISE_REQUEST: 'HAND_RAISE_REQUEST',
    HAND_RAISE_SUCCESS: 'HAND_RAISE_SUCCESS',
    HAND_RAISE_FAILURE: 'HAND_RAISE_FAILURE',

    ROOM_DETAIL_REQUEST: 'ROOM_DETAIL_REQUEST',
    ROOM_DETAIL_SUCCESS: 'ROOM_DETAIL_SUCCESS',
    ROOM_DETAIL_FAILURE: 'ROOM_DETAIL_FAILURE',
    
    PERMISSION_ACTION_REQUEST: 'PERMISSION_ACTION_REQUEST',
    PERMISSION_ACTION_SUCCESS: 'PERMISSION_ACTION_SUCCESS',
    PERMISSION_ACTION_FAILURE: 'PERMISSION_ACTION_FAILURE',

    CHANGE_BIT_REQUEST: 'CHANGE_BIT_REQUEST',
    CHANGE_BIT_SUCCESS: 'CHANGE_BIT_SUCCESS',
    CHANGE_BIT_FAILURE: 'CHANGE_BIT_FAILURE',

    MODERATOR_JOIN_REQUEST: 'MODERATOR_JOIN_REQUEST',
    MODERATOR_JOIN_SUCCESS: 'MODERATOR_JOIN_SUCCESS',
    MODERATOR_JOIN_FAILURE: 'MODERATOR_JOIN_FAILURE',

    QUESTION_SUBMIT_REQUEST: 'QUESTION_SUBMIT_REQUEST',
    QUESTION_SUBMIT_SUCCESS: 'QUESTION_SUBMIT_SUCCESS',
    QUESTION_SUBMIT_FAILURE: 'QUESTION_SUBMIT_FAILURE',

    ANSWER_SUBMIT_REQUEST: 'ANSWER_SUBMIT_REQUEST',
    ANSWER_SUBMIT_SUCCESS: 'ANSWER_SUBMIT_SUCCESS',
    ANSWER_SUBMIT_FAILURE: 'ANSWER_SUBMIT_FAILURE',

    FAQ_MODERATOR_REQUEST: 'FAQ_MODERATOR_REQUEST',
    FAQ_MODERATOR_SUCCESS: 'FAQ_MODERATOR_SUCCESS',
    FAQ_MODERATOR_FAILURE: 'FAQ_MODERATOR_FAILURE',

    FAQ_ATTENDEE_REQUEST: 'FAQ_ATTENDEE_REQUEST',
    FAQ_ATTENDEE_SUCCESS: 'FAQ_ATTENDEE_SUCCESS',
    FAQ_ATTENDEE_FAILURE: 'FAQ_ATTENDEE_FAILURE',

    LIKE_FAQ_REQUEST: 'LIKE_FAQ_REQUEST',
    LIKE_FAQ_SUCCESS: 'LIKE_FAQ_SUCCESS',
    LIKE_FAQ_FAILURE: 'LIKE_FAQ_FAILURE',

    STAR_FAQ_REQUEST: 'STAR_FAQ_REQUEST',
    STAR_FAQ_SUCCESS: 'STAR_FAQ_SUCCESS',
    STAR_FAQ_FAILURE: 'STAR_FAQ_FAILURE',

    PEER_DATA_REQUEST: 'PEER_DATA_REQUEST',
    PEER_DATA_SUCCESS: 'PEER_DATA_SUCCESS',
    PEER_DATA_FAILURE: 'PEER_DATA_FAILURE',
};