import { combineReducers } from "redux";

import { authentication } from "./authentication.reducer";
import { otp } from "./otp.reducer";
import { authRegistration } from "./authRegistration.reducer";
// import { registration } from './registration.reducer';
// import { users } from './users.reducer';
import { alert } from "./alert.reducer";
import { schedule } from "./schedule.reducer";
import { joinConference } from "./join.reducer";
import { conference } from "./conference.reducer";
import { event } from "./event.reducer";
import { accountsInfo } from "./account.reducer";
import { waitingRoom } from "./waitingRoom.reducer";
import { webinar } from "./webinar.reducer";
import { webinarRoom } from "./webinarRoom.reducer";
import { teamsInfo } from "./team.reducer";
import { department } from "./department.reducer";
import { company } from "./company.reducer";
import { roles } from "./role.reducer";
import { home } from "./home.reducer";
import { upgradeAccount } from "./upgrade.reducer";
import { themeChange } from "./theme.reducer";
import { report } from './reports.reducer';
import { setting } from "./setting.reducer";
import { notification } from "./browserNotif.reducer";

const rootReducer = combineReducers({
  authentication,
  authRegistration,
  schedule,
  conference,
  event,
  otp,
  alert,
  joinConference,
  accountsInfo,
  waitingRoom,
  webinar,
  webinarRoom,
  teamsInfo,
  department,
  company,
  roles,
  home,
  upgradeAccount,
  themeChange,
  report,
  setting,
  notification
});

export default rootReducer;
