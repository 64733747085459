import React, { Component } from 'react';
import { Input, Form, FormGroup, Label, Button, Col, Row, CustomInput } from 'reactstrap';
import './EditTeam.scss';
import "react-datepicker/dist/react-datepicker.css";
import LeftPanelManager from '../../../components/LeftPanelManager/LeftPanelManager';
import validator from 'validator';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { departmentActions, teamActions } from './../../../../Redux/_actions';
import { Multiselect } from 'multiselect-react-dropdown';


class CreateTeam extends Component {
    constructor(props) {
        super(props);
        this.state = {
            teamForm: {
                team_name: '',
                contact_person_name: '',
                contact_person_email: '',
                contact_person_phone: '',
                team_email_id: '',
                about: '',
                country_code: '+91',
                People: [],
                department: ''
            },
            isTeamFormValid: false,
            isFormSubmitted: false,
            selectedPeople: [],
            team_id: this.props.match.params.id || null,
            formError: {},
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.isTeamAdded !== nextProps.isTeamAdded) {
            this.props.history.push("/eoffice/Setting/team/list")
        }
        return true;
    }
    componentDidUpdate = async (prevProps, prevState) => {
        if (prevProps.team !== this.props.team) {
            console.log("sdfsdfsdf")
            const teamData = { ...this.state.teamForm };
            const { team_name, contact_person_name, contact_person_phone, contact_person_email, team_email_id, about, country_code, People, department } = this.props.team;
            teamData['team_name'] = team_name || '';
            teamData['contact_person_name'] = contact_person_name || '';
            teamData['contact_person_phone'] = contact_person_phone || '';
            teamData['contact_person_email'] = contact_person_email || '';
            teamData['team_email_id'] = team_email_id || '';
            teamData['about'] = about || '';
            teamData['country_code'] = country_code || '91';
            teamData['People'] = People || [];
            teamData['department'] = department || '';
            let selected = [];
            People.forEach(item => {
                let obj = {};
                obj.email = item;
                selected.push(obj);

            })
            await this.setState({ ...this.state.teamForm, teamForm: teamData });
            await this.setState({ ...this.state, selectedPeople: selected });
        }

    }

    componentDidMount() {
        this.props.getCompanyUsers();
        this.props.listDepartments();
        const team_id = this.state.team_id;
        if (team_id !== null) {
            this.props.getTeamById(team_id)
        }

    }

    async handleUserInput(e) {
        const name = e.target.name;
        const value = e.target.value;
        const teamData = { ...this.state.teamForm };
        teamData[name] = value;
        await this.setState({ ...this.state.teamForm, teamForm: teamData });
        await this.vallidateForm();
    }

    async vallidateForm() {
        if (this.state.isFormSubmitted) {
            let errorObj = {};
            const formData = this.state.teamForm;
            if (formData.team_name === '') {
                errorObj.team_name = 'Please enter team name';
            }
            // if (formData.contact_person_name === '') {
            //     errorObj.contact_person_name = 'Please select contact person';
            // }

            if (formData.team_email_id === '') {
                errorObj.team_email_id = 'Please enter team email';
            }
            if (formData.team_email_id !== '' && !validator.isEmail(formData.team_email_id)) {
                errorObj.team_email_id = 'Please enter valid email';
            }
            if (formData.about === '') {
                errorObj.about = 'Please enter about';
            }
            if (formData.department === '') {
                errorObj.department = 'Please select department of team';
            }
            let formValid;
            if (Object.keys(errorObj).length === 0) {
                formValid = true;
            } else {
                formValid = false;
            }

            await this.setState({ formError: errorObj, isTeamFormValid: formValid });
        }
    }

    onSelect = (selectedList, selectedItem) => {
        const teamData = { ...this.state.teamForm };
        teamData['People'].push(selectedItem.email);
        this.setState({ ...this.state.teamForm, teamForm: teamData });
    }

    onRemove = async (selectedList, removedItem) => {
        const teamData = { ...this.state.teamForm };
        const data = teamData['People'].filter(ele => ele !== removedItem.email);
        teamData['People'] = data;
        await this.setState({ ...this.state.teamForm, teamForm: teamData });
    }

    async submitTeamForm(event) {
        event.preventDefault();
        await this.setState({ isFormSubmitted: true });
        await this.vallidateForm();
        if (this.state.isTeamFormValid && this.state.isFormSubmitted) {
            this.props.editTeam(this.state.teamForm, this.state.team_id);
        }
    }

    async changeContactPerson(e) {
        const value = e.target.value;
        const teamData = { ...this.state.teamForm };
        if (value !== '') {
            const selectedEmp = this.props.companyUsers.find(emp => emp.email === value);
            const teamData = { ...this.state.teamForm };
            teamData['contact_person_name'] = selectedEmp.name;
            teamData['contact_person_email'] = selectedEmp.email;
            teamData['contact_person_phone'] = selectedEmp.mobile;
            await this.setState({ ...this.state.teamForm, teamForm: teamData });
            await this.vallidateForm();
        } else {
            teamData['contact_person_name'] = '';
            teamData['contact_person_email'] = '';
            teamData['contact_person_phone'] = '';
            await this.setState({ ...this.state.teamForm, teamForm: teamData });
            await this.vallidateForm();
        }


    }


    render() {
        return (
            <div className="createDepart-container">
                <LeftPanelManager></LeftPanelManager>
                <div className="pageContentpanel">
                    <div className="createDepart-FormSection">
                        <h2 className="title">Edit Team</h2>
                        <p className="sub-title">You could be a business, an event organizer, or a professional community. Please fill below detail.</p>
                        <Row className="formRow">
                            <Col sm="6">
                                <Form onSubmit={(event) => this.submitTeamForm(event)}>
                                    <FormGroup className="form-col">
                                        <Label className="title-label">Team Name </Label>
                                        <Input type="text" value={this.state.teamForm.team_name} name="team_name" onChange={(e) => this.handleUserInput(e)} placeholder="Describe Team Name" />
                                    </FormGroup>
                                    {this.state.isFormSubmitted && this.state.formError.hasOwnProperty('team_name') && <div className="error text-danger" >{this.state.formError.team_name}</div>}

                                    <FormGroup className="form-col">
                                        <Label className="title-label">Team Department</Label>
                                        <CustomInput type="select" value={this.state.teamForm.department} onChange={(e) => this.handleUserInput(e)} name="department" >
                                            <option value="">Select Department</option>
                                            {this.props.departmentList.map((dept, index) => (
                                                <option value={dept.department_name} key={index}>{dept.department_name}</option>
                                            ))}
                                        </CustomInput>
                                    </FormGroup>
                                    {this.state.isFormSubmitted && this.state.formError.hasOwnProperty('department') && <div className="error text-danger" >{this.state.formError.department}</div>}

                                    <FormGroup className="form-col">
                                        <Label className="title-label">Contact Person</Label>
                                        <CustomInput type="select" value={this.state.teamForm.contact_person_email} onChange={(e) => this.changeContactPerson(e)} name="contact_person_name" >
                                            <option value="">Select Contact Person</option>
                                            {this.props.companyUsers.map((mem, index) => (
                                                <option value={mem.email} key={index}>{mem.name} ({mem.email})</option>
                                            ))}
                                        </CustomInput>
                                    </FormGroup>
                                    {this.state.isFormSubmitted && this.state.formError.hasOwnProperty('contact_person_name') && <div className="error text-danger" >{this.state.formError.contact_person_name}</div>}

                                    <FormGroup className="form-col">
                                        <Label className="title-label">Team Email ID</Label>
                                        <Input type="email" value={this.state.teamForm.team_email_id} name="team_email_id" onChange={(e) => this.handleUserInput(e)} placeholder="Enter Team Email Address" />
                                    </FormGroup>
                                    {this.state.isFormSubmitted && this.state.formError.hasOwnProperty('team_email_id') && <div className="error text-danger" >{this.state.formError.team_email_id}</div>}


                                    <FormGroup className="form-col">
                                        <Label className="title-label">About</Label>
                                        <Input type="textarea" value={this.state.teamForm.about} name="about" onChange={(e) => this.handleUserInput(e)} className="form-control-textarea" placeholder="Enter About Team detail" />
                                    </FormGroup>
                                    {this.state.isFormSubmitted && this.state.formError.hasOwnProperty('about') && <div className="error text-danger" >{this.state.formError.about}</div>}


                                    <FormGroup className="form-col">
                                        <Label className="title-label"><div className=" circle-add rounded-circle bg-success"><i className="fas fa-plus"></i></div> Add People</Label>
                                        <Multiselect
                                            selectedValues={this.state.selectedPeople}
                                            options={this.props.companyUsers} // Options to display in the dropdown
                                            onSelect={this.onSelect} // Function will trigger on select event
                                            onRemove={this.onRemove} // Function will trigger on remove event
                                            displayValue="email" // Property name to display in the dropdown options
                                        />
                                    </FormGroup>


                                    <Col sm="12" className="text-center mt-5">
                                        <Button className="btn-theme-primary" type="submit">Update</Button>
                                    </Col>

                                </Form>
                            </Col>
                            <Col sm="6" className="img-section">
                                <img src="../images/eoffice/team-group-img.svg" className="img-fluid" alt=""  ></img>

                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        )
    }
}

const mapState = (state) => {
    const { companyUsers, departmentList } = state.department;
    const { isTeamAdded, team } = state.teamsInfo;

    return {
        companyUsers,
        isTeamAdded,
        departmentList,
        team
    };
}

const actionCreators = {
    getCompanyUsers: departmentActions.getCompanyUsers,
    listDepartments: departmentActions.listDepartments,
    getTeamById: teamActions.getTeamById,
    editTeam: teamActions.editTeam
};


export default withRouter(connect(mapState, actionCreators)(CreateTeam));
