/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Button} from "react-bootstrap";
import { FaUserAlt, FaUserPlus } from "react-icons/fa";
import {
  AiOutlineShareAlt,
  AiOutlineEdit,
  AiTwotoneDelete,
} from "react-icons/ai";
import { confirmAlert } from "react-confirm-alert";
import isEmpty from "is-empty";
import AddHostForm from "./components/AddHostForm";
import Layout from "../../layoutSection";
import { alertActions, webinarActions } from "../../Redux/_actions";
import Spinner from "../../components/UI/Spinner/Spinner";
import { myconfig } from "../../config/config";
import ConfirmationPopup from "../../components/UI/ConfirmationPopup/confirmationPopup";
import Modal from "../../layoutSection/Modal";
import "../../layoutSection/css/treetable.scss";

let typeModal = "add";

const Speakers = () => {
  const { webinarDetail, detailLoading } = useSelector(
    (state) => state.webinar
  );
  // const { theme } = useSelector((state) => state.themeChange);
  const [shareModalURL, setShareModalURL] = useState(null);
  const [addSpeakersModal, setAddSpeakersModal] = useState(false);
  const [list, setList] = useState(null);
  const [filter, setFilter] = useState(null);
  const param = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      param?.id &&
      (!webinarDetail || webinarDetail?.meetingID !== param.id)
    ) {
      dispatch(webinarActions.webinarDetail(param.id));
    }
  }, [dispatch, param.id, webinarDetail]);

  useMemo(() => {
    if (webinarDetail && webinarDetail.host) {
      setList(webinarDetail.host);
    }
  }, [webinarDetail]);

  useMemo(() => {
    if (webinarDetail?.host?.length > 0) {
      if (isEmpty(filter)) {
        setList(webinarDetail?.host);
      } else {
        const res = webinarDetail.host.filter(
          (data) =>
            data.n.includes(filter) ||
            data.b.includes(filter) ||
            data.v.includes(filter)
        );
        setList(res);
      }
    }
  }, [filter]);

  const removeSpeaker = (speakerID) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmationPopup
            message={"Are you sure want to remove this Participant ?"}
            noBtnAction={onClose}
            noBtnText="No"
            yesBtnText="Yes, Remove AnyWay!"
            yesBtnAction={() =>
              dispatch(webinarActions.removeWebinarSpeaker(speakerID))
            }
          />
        );
      },
    });
  };

  const CardSection = ({ data, index }) => {
    const joinURl = `${myconfig.serverURL}/join_webinar?wid=${param.id}&token=${data._id}`;

    return (
      <>
        <div className="welcomeMsg">
          {data.p ? <img src={data.p} className="img-fluid card-img" /> : <div className="img-fluid card-img w-100" >  <FaUserAlt className="f_size_90" /> </div>}
        </div>
        <div className="welcomeMsg"> {data.n} </div>
        <div className="welcomeMsg"> {data.d} </div>
        <div className="welcomeMsg"> {data?.v} </div>
        <div className="welcomeMsg"> {data?.mob} </div>
        {webinarDetail?.isMeetingEnded !== 1 && (
          <div className="text-center welcomeMsg">
            {" "}
            <button
              className="btnBgLess f_size_22 share btn-share"
              onClick={() => setShareModalURL(joinURl)}
            >
              <AiOutlineShareAlt />{" "}
            </button>{" "}
            <button
              className="btnBgLess f_size_22 text-warning mx-3 btn-edit"
              onClick={() => {
                setAddSpeakersModal(true);
                typeModal = data;
              }}
            >
              <AiOutlineEdit />{" "}
            </button>
            <button
              className="btnBgLess text-danger f_size_22 btn-remove"
              onClick={() => removeSpeaker(data._id)}
            >
              <AiTwotoneDelete />{" "}
            </button>{" "}
          </div>
        )}
      </>
    );
  };

  const SpeakerList = () => {
    if (detailLoading || (webinarDetail?.host?.length > 0 && !list)) {
      return (
        <tr>
          {" "}
          <td colSpan={6}>
            {" "}
            <div className="text-center">
              <Spinner />
            </div>{" "}
          </td>{" "}
        </tr>
      );
    } else if (list?.length > 0) {
      return list.map((data, index) => (
        <div key={`speakers-${index + 1}`} className="welcomeMsg col-lg-3">
          <div className="card my-4 pr-cardb welcomeMsg">
            <div className="card-body text-center detailsSection sideBar py-4 welcomeMsg">
              {" "}
              <CardSection data={data} index={index} />{" "}
            </div>
          </div>
        </div>
      ));
    } else {
      return (
        <div className="row w-100 d-flex justify-content-center align-items-center">
          {" "}
          <td colSpan={12}>
            <div className="text-center mt-4 mb-4">
              <img
                src="../../images/Speaker.png"
                alt="no speaker"
                width={400}
              />
              <div className="resultNotFound  bg-transparent  f_size_32">
                No Speakers Available{" "}
              </div>{" "}
            </div>{" "}
          </td>{" "}
        </div>
      );
    }
  };

  const LeftHeader = () => {
    if (webinarDetail?.isMeetingEnded !== 1) {
      return (
        <Button
          className="headerBtn submitBtnColor border-0 mr-3 mt-1 btn"
          style={{ width: "100px", height: "40px" }}
          onClick={() => {
            setAddSpeakersModal(true);
            typeModal = "add";
          }}
        >
          <FaUserPlus className="mr-2 f_size_16" />
          Add
        </Button>
      );
    } else {
      return null;
    }
  };

  return (
    <Layout
      list="webinar"
      goBack="/webinars"
      active="Speakers"
      title={webinarDetail?.name || "Speakers"}
    >
      <div className="container-fluid s_wave_content_part pl-3 pr-3 speakers">
        <Modal
          show={!isEmpty(shareModalURL)}
          toggle={() => setShareModalURL(null)}
          className="share_URl_model"
          contentClassName="sideBar welcomeMsg"
          label={
            <h4>
              <i className="far fa-share-square"></i> Share Joining URL{" "}
            </h4>
          }
          size="lg"
        >
          <div className="file_sharng_sct mt-4">
            <label className="text-muted ">
              {" "}
              Copy and share this link with your speaker.
            </label>
            <div className="shrng_568">
              <div className="shared_url bgColorBodyCard">{shareModalURL}</div>
              <div className="mt-3 text-right">
                <button
                  variant="secondary"
                  onClick={(e) => setShareModalURL(null)}
                  className="mr-2 btnStyles welcomeMsg sideBarIcon"
                >
                  Cancel
                </button>
                <button
                  variant="primary"
                  onClick={(e) => {
                    dispatch(alertActions.success("Copied !!!"));
                    navigator.clipboard.writeText(shareModalURL);
                  }}
                  className=" btnStyles  submitSubColor welcomeMsg"
                >
                  <i className="far fa-copy"></i> Copy
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {addSpeakersModal && (
          <AddHostForm
            closeBtn={false}
            show={addSpeakersModal}
            toggle={() => setAddSpeakersModal(false)}
            wibId={param?.id}
            type={typeModal}
            onChangeDone={(value) => {
              if (value) {
                dispatch(webinarActions.webinarDetail(param.id));
                setAddSpeakersModal(false);
              }
            }}
          />
        )}
        <div className="d-flex align-items-start justify-content-between">
          {/* <Form.Group className="searchSectionWebinar w-100 col-md-5" >
            <InputGroup hasValidation>
              <InputGroup.Text className="welcomeMsg sideBar inputGroupSearch border-0"><AiOutlineSearch className="f_size_26" /></InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Search"
                onChange={(event) => setFilter(event.target.value)}
                className="searchInput welcomeMsg sideBar h55 f_size_18"
                value={filter}
              />
              <InputGroup.Text className="sideBar inputGroupCancel border-0">
                {!isEmpty(filter) && <button className="btnBgLess welcomeMsg" onClick={() => setFilter("")}>
                  X
                </button>}
              </InputGroup.Text>
            </InputGroup>
          </Form.Group> */}

          <LeftHeader />
        </div>

        <div className="row">
          {/* <Table
            hover
            variant={theme ? "" : "dark"}
            className={`${theme ? "sideBar" : ""} transition03  treetable`}
          >
            <thead>
              <tr className="tableHeader">
                <th className="text-center">#</th>
                <th>Name</th>
                <th>Bio</th>
                <th>Phone </th>
                <th>Email</th>
                {webinarDetail?.isMeetingEnded !== 1 && (
                  <th className="text-center">Action</th>
                )}
              </tr>
            </thead>
            <tbody>
              <SpeakerList />
            </tbody>
          </Table> */}
          <SpeakerList />
        </div>
      </div>
    </Layout>
  );
};

export default Speakers;
