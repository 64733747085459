import React, { useEffect } from 'react'
import { Row, Col, Form, ListGroupItem, ListGroup, Button } from 'react-bootstrap';
import { Formik, FieldArray } from 'formik'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { FaPencilAlt, FaRegEye } from 'react-icons/fa';

import Modal from '../../../layoutSection/Modal'
import { InputFiled } from '../../../Common/authFile'
import { fieldTypes, InputSection } from './fieldSection';

import styles from './styles.module.scss'


const defaultObject = {
  label: '',
  type: fieldTypes[0],
  option: [{ label: '' }],
  isRequired: false,
}

const initialValues = {
  active: 0,
  result: [defaultObject],
}

const EditSection = ({ values, index, setFieldValue }) => (
  <>
    <Row>
      <Col lg={8}>
        <InputFiled
          name={`result[${index}].label`}
          type="text"
          label="Label Name"
          placeholder="label"
          className="form-control inputColorTheme"
        />
      </Col>
      <Col lg={4}>
        <Form.Group className='mb-0 mt-1 '>
          <Form.Label className="welcomeMsg f_size_14 ml-3">
            Field Type
          </Form.Label>
          <div>
            <select
              value={values.result[index].type}
              onChange={(event) => {
                setFieldValue(`result[${index}].type`, event.target.value);
              }}
              className="form-control inputColorTheme"
            >
              {fieldTypes.map((data) => (
                <option value={data} key={`fieldTypes-${data}`}>
                  {data}
                </option>
              ))}
            </select>
          </div>
        </Form.Group>
      </Col>
    </Row>
    {values.result[index].type !== fieldTypes[0] && (
      <Form.Group className='mb-0'>
        <Form.Label className="welcomeMsg mb-0">*Options</Form.Label>
        <FieldArray
          name={`result[${index}]option`}
          render={({ remove, push }) => {
            return (
              values.result[index].option.length > 0 &&
              values.result[index].option.map((item, indexSecond) => (
                <Row className='mt-1 align-items-center'>
                  <Col lg={10}>
                    <InputFiled
                      name={`result.${index}.option.${indexSecond}.label`}
                      type="text"
                      className="form-control inputColorTheme"
                      labelClassName="welcomeMsg"
                      placeholder="Enter Options"
                      containerClassName="formContainer"
                    />
                  </Col>
                  <Col lg={1}>
                    <Button
                      variant="outline-danger"
                      onClick={() => remove(indexSecond)}
                      disabled={values?.result[index]?.option.length === 1}
                    >
                      <i className="fas fa-trash"></i>
                    </Button>
                  </Col>
                </Row>
              ))
            );
          }}
        ></FieldArray>
        <Button
          className="mt-2"
          onClick={() =>
            setFieldValue(
              `result[${index}].option`,
              values.result[index].option.concat({ label: "" }),
            )
          }
        >
          <i className="fas fa-plus"></i>
        </Button>
      </Form.Group>)}
  </>
)

const SetFieldModal = (props) => {
  const { show, toggle, setFiled, customData } = props

  const initialValues = {
    active: 0,
    result: customData?.length > 3 ? customData.slice(3) : [defaultObject],
  }

  const setInputCustom = (value) => {
    let newId = 3
    const result = value?.filter(data => {
      if (data.label.trim() != "") {
        data.id = newId
        newId += 1
        return data
      }
    })

    setFiled(result)
    toggle()
  }

  return (
    <Modal
      show={show}
      toggle={() => toggle()}
      closeBtn={false}
    >
      <Formik
        initialValues={initialValues}
      >
        {({ values, setFieldValue }) =>
          <>
            <div className='between'>
              <h3>Customize Form</h3>
              <button className="mt-2 pl-4 pr-4 headerBtn submitBtnColorBorder" onClick={() => {
                setFieldValue('result', values.result.concat(defaultObject))
                setFieldValue('active', values.result.length)
              }} >Add Field</button>
            </div>
            <div className='bottomBorder mt-3 mb-2' />
            <div className={styles.fieldContainer}>
              <PerfectScrollbar>
                <FieldArray
                  name="result"
                  render={arrayHelpers => (
                    values?.result?.map((data, index) => (
                      <>
                        {index === values.active ?
                          <div className='bgColorBodyCard  p-3 mt-2 '>
                            <div className='between'>
                              <div className='text-success'> <FaPencilAlt /><span className='ml-2 h5'>Editor</span></div>
                              <Form.Group >
                                <Form.Check type="checkbox" className='welcomeMsg' label='Is Required' onChange={() => setFieldValue(`result[${index}].isRequired`, !values.result[index].isRequired)} checked={values.result[index].isRequired} />
                              </Form.Group>
                            </div>
                            <EditSection values={values} index={index} setFieldValue={setFieldValue} />
                          </div>
                          :
                          <div className='bgColorBodyCard  p-3 mt-2 between flex-wrap'>
                            <div className={`${styles.previewHeading} text-warning`}><FaRegEye /><span className='ml-1 h5'> Preview </span></div>
                            <div className={`btnBgLess w-100 text-left ${styles.inputFields}`}  >
                              <InputSection item={data} index={index} />
                            </div>
                            <div className='text-right'>
                              <Form.Group >
                                <Form.Check type="checkbox" className='welcomeMsg' label='Is Required' onChange={() => setFieldValue(`result[${index}].isRequired`, !values.result[index].isRequired)} checked={values.result[index].isRequired} />
                              </Form.Group>
                              <Button className='mr-2' onClick={() => setFieldValue('active', index)} ><i className="fa fa-pencil-alt text-white"></i></Button>
                              <Button variant="danger" onClick={() => {
                                setFieldValue('active', 0)
                                setFieldValue("result", values.result.filter((_, filterIndex) => filterIndex !== index))
                              }} ><i className="fas fa-trash text-white"></i></Button>
                            </div>
                          </div>
                        }
                      </>
                    ))
                  )
                  }
                />
              </PerfectScrollbar>
            </div>
            <div className='bottomBorder mt-2' />
            <div className='text-right mt-2'>
              <button onClick={() => toggle()} className="mr-2 btnBgLess pl-4 pr-4  welcomeMsg " >Cancel </button>
              <button onClick={() => setInputCustom(values.result)} className="mr-2 border-0 pl-4 pr-4 headerBtn text-white submitBtnColor" >Update Form</button>
            </div>
          </>
        }
      </Formik>
    </Modal>
  )
}

export default SetFieldModal
