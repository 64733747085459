import React, { Component } from 'react';
import './Logos.css';
const Logos = () => {     
        return (
            <div className="container-fluid logo-fluid">
            <div className="container">
            <ul className="logo-section">
            
                <li className="ml-0" id="Popover1">
                <div className="popover__content">
                    <div className="popover__title">Certin </div>
                        <p className="popover__message">The Indian Computer Emergency Response Team (CERT-IN) is the nodal agency to deal with cyber security threats like hacking and phishing
                        </p>
                    </div>
                    <a href="#" className="logo-sprite logoOne"></a>
                </li>

                <li>
                    <div className="popover__content">
                    <div className="popover__title">Meity </div>
                        <p className="popover__message">The Ministry of Electronics and Information Technology is an executive agency of the Union Government of the Republic of India.</p>
                    </div>
                    <a href="#" className="logo-sprite logoTwo"></a>
                </li>

                <li>
                <div className="popover__content">
                    <div className="popover__title">NeGD  </div>
                        <p className="popover__message">National E-Governance Division NEGD, Department Of Electronics And Information Technology
                        </p>
                </div>
                <a href="#" className="logo-sprite logoThree"></a>
                </li>

                <li>
                <div className="popover__content">
                    <div className="popover__title">CMMI   </div>
                        <p className="popover__message">Capability Maturity Model Integration is a process level improvement training and appraisal program.
                        </p>
                </div>
                <a href="#" className="logo-sprite logoFour"></a>
                </li>
                <li>
                <div className="popover__content">
                    <div className="popover__title">ISO    </div>
                        <p className="popover__message">The International Organization for Standardization is an international standard-setting body composed of representatives from various national standards organizations. 
                        </p>
                </div>
                <a href="#" className="logo-sprite logoFive"></a>
                </li>
                <li>
                <div className="popover__content">
                <div className="popover__title">Startup India    </div>
                    <p className="popover__message">Startup India is a Government of India <br/>flagship initiative to build<br/> Startups and nurture innovation. 
                    </p>
                </div>
                <a href="#" className="logo-sprite logoSix"></a>
                </li>
                <li className="mr-0"  >
                <div className="popover__content">
                <div className="popover__title">Digital India    </div>
                    <p className="popover__message">Digital India is a flagship programme of the Government of India with a vision to transform India into a digitally empowered society and knowledge economy. 
                    </p>
                </div>
                <a href="#" className="logo-sprite logoSeven"></a>
                </li>
            </ul>
            </div>
        </div>
        )
   
}

export default Logos;
