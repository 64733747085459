import React from 'react'
import { Link } from "react-router-dom";
const MutedConversation = () => {
    return (
        <div className="listSection">
            <div className="title"><span className="bg-red"><img src="../images/icons/icon-mute.svg" width="14" alt="" /></span>Muted Conversation</div>
            <ul className="listContent">
                <li><Link><span><img src="../images/icons/icon-mute.svg" width="13" className="icon-mute" alt="" /></span>Himanshu Porwal</Link></li>
            </ul>
        </div>
    )
}

export default MutedConversation;
