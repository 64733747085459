import { scheduleConstants } from '../_constants';
import { scheduleServices } from '../_services';
import { alertActions } from './';
import { eventActions } from './event.actions';

export const scheduleActions = {
    submitEventForm,
    scheduleDetail,
    deleteSchedule,
    cancelSchedule,
    editScheduleForm
};

function submitEventForm(formData) {
    return dispatch => {
        dispatch(start("start"));
        scheduleServices.createSchedule(formData)
            .then(
                response => {
                    dispatch(alertActions.success("Schedule Successfully Create"));
                    dispatch(success(response.meetingId));
                    let url = "/meeting_history/" + response.meetingId
                    if (response?.mid) {
                        url = "/meeting_history/" + response.meetingId + "?mid=" + response?.mid
                    }
                    window.location.href = url
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                    dispatch(failure(error.toString()));
                }
            );
    };

    function start(start) { return { type: scheduleConstants.SCHEDULE_START, start } }
    function success(response) { return { type: scheduleConstants.SCHEDULE_SUCCESS, response } }
    function failure(error) { return { type: scheduleConstants.SCHEDULE_FAILURE, error } }
}

function editScheduleForm(formData) {
    return dispatch => {
        dispatch(start("start"));
        scheduleServices.editSchedule(formData)
            .then(
                response => {
                    console.log(response);
                    dispatch(success(response.meetingId));
                    // dispatch(alertActions.success("Schedule Successfully edited"));
                    //window.location.href = "/meeting_history/"+response.meetingId

                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                    dispatch(failure(error.toString()));
                }
            );
    };

    function start(start) { return { type: scheduleConstants.SCHEDULE_EDIT_START, start } }
    function success(response) { return { type: scheduleConstants.SCHEDULE_EDIT_SUCCESS, response } }
    function failure(error) { return { type: scheduleConstants.SCHEDULE_EDIT_FAILURE, error } }
}


function scheduleDetail(scheduleId) {
    return dispatch => {
        dispatch(start());
        scheduleServices.scheduleDetail(scheduleId)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                    dispatch(failure(error.toString()));
                }
            );
    };

    function start(start) { return { type: scheduleConstants.SCHEDULE_DETAIL_START } }
    function success(response) { return { type: scheduleConstants.SCHEDULE_DETAIL_SUCCESS, response } }
    function failure(error) { return { type: scheduleConstants.SCHEDULE_DETAIL_FAILURE, error } }
}


function deleteSchedule(scheduleId, type, mid) {
    return dispatch => {
        dispatch(start());
        scheduleServices.deleteSchedule(scheduleId, mid)
            .then(
                response => {
                    dispatch(alertActions.success("Meeting Deleted Successfully!"));
                    dispatch(eventActions.meetingHistoryList({ type }))
                    dispatch(success(response));
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                    dispatch(failure(error.toString()));
                }
            );
    };

    function start(start) { return { type: scheduleConstants.SCHEDULE_DELETE_START } }
    function success(response) { return { type: scheduleConstants.SCHEDULE_DELETE_SUCCESS, response } }
    function failure(error) { return { type: scheduleConstants.SCHEDULE_DELETE_FAILURE, error } }
}


function cancelSchedule(scheduleId, mid) {
    return dispatch => {
        dispatch(start());
        scheduleServices.cancelSchedule(scheduleId, mid)
            .then(
                response => {
                    dispatch(alertActions.success("Meeting Cancel Successfully!"));
                    dispatch(success(response));
                    window.location.href = "/meeting_history"

                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                    dispatch(failure(error.toString()));
                }
            );
    };

    function start(start) { return { type: scheduleConstants.SCHEDULE_CANCEL_START } }
    function success(response) { return { type: scheduleConstants.SCHEDULE_CANCEL_SUCCESS, response } }
    function failure(error) { return { type: scheduleConstants.SCHEDULE_CANCEL_FAILURE, error } }
}