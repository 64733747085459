import React, { useEffect, useState } from 'react'
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, Col } from "react-bootstrap";
import exportFromJSON from 'export-from-json';
import queryString from "query-string";
import { FaFilter, FaVideo, FaDownload } from 'react-icons/fa';
import isEmpty from 'is-empty';

import VideoModal from './VideoRec'
import Layout from '../layoutSection';
import Table from '../layoutSection/table';
import { reportActions } from '../Redux/_actions';
import { durationTime, tsToDate } from '../helpers/helpers';
import FilterModel from './model'
// import { ExportToCsv } from "export-to-csv";
// import Spinner from '../components/UI/Spinner/Spinner';
// import FileSaver from "file-saver";
// import userconfigs from "../helpers/closures";

import './styles.scss';

const TableHeader = () => (
  <tr>
    <th>Title</th>
    <th>ID</th>
    <th>User Name</th>
    <th>Create Date</th>
    <th>Start Date</th>
    <th>End Date</th>
    <th>Duration</th>
    <th>Participants</th>
    <th>Recording</th>
    <th>Detailed Report</th>
  </tr>
)

const defaultValues = {
  title: '',
  meetId: '',
  fromDate: "",
  toDate: "",
}

const Report = (props) => {
  const dispatch = useDispatch()
  const notificationUpdate = useSelector(state => state.notification)
  const { reportList, loadingReport, loadingReportReg } = useSelector(state => state.report)
  const meetId = queryString.parse(props.location.search)?.meetId
  const [initialValues, setInitialValues] = useState({
    title: '',
    meetId: meetId || '',
    fromDate: "",
    toDate: "",
  })
  const [filterShow, setFilterShow] = useState({
    show: false,
    filter: meetId ? true : false,
  })
  const [type, setType] = useState('meeting')
  const [isVideoShow, setIsVideoShow] = useState(null)


  const toggleFilterModal = () => setFilterShow({ ...filterShow, show: !filterShow.show })
  const changeFilter = () => setFilterShow({ filter: !filterShow.filter, show: false })

  useEffect(() => {
    const params = initialValues
    params.type = type
    dispatch(reportActions.getReport(params));
    notificationUpdate.downloadRecording = false
  }, [dispatch, initialValues, type])

  const downloadCSV = async (data) => {
    data = data.map(res => ({
      name: res.meetingSummary.name,
      meetingID: res.meetingSummary.meetingID,
      username: res.meetingSummary.moderatorName,
      create_Date: res.meetingSummary.create_date ? tsToDate(res.meetingSummary.create_date) : '-',
      start_Date: res.StartDate ? tsToDate(res.StartDate) : '-',
      end_Date: res.EndDate ? tsToDate(res.EndDate) : '-',
      duration: (data?.StartDate && data?.EndDate) ? durationTime(data?.StartDate, data?.EndDate) : '-',
      participant: res.JoinedUsers,
    }
    ))
    const fileName = "generated";
    const exportType = 'csv';
    exportFromJSON({ data, fileName, exportType });
  };

  const handleSubmitFilter = (value) => {
    setInitialValues(value)
    changeFilter()
  }

  const mp4Download = (e, url, mID) => {
    fetch(url, {
      method: 'GET',
      headers: {},
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `recording_${mID}.mp4`,
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });

  }
  useEffect(() => {
    if (notificationUpdate.downloadRecording) {
      const params = initialValues
      params.type = type
      dispatch(reportActions.getReport(params))
      notificationUpdate.downloadRecording = false
    }
  }, [notificationUpdate])

  const GetReport = () => {
    if (loadingReport) {
      return <tr> <td colSpan={10}> <div className="text-center">Searching for results...</div> </td> </tr>
    }
    if (reportList?.meetingList?.length > 0) {
      return Object.values(reportList?.meetingList).map(data => (
        <tr>
          <td>{data?.meetingSummary?.name || '-'}</td>
          <td>{data?.meetingSummary?.meetingID || '-'}</td>
          <td>{data?.meetingSummary?.moderatorName || '-'}</td>
          <td>{data?.meetingSummary?.create_date ? tsToDate(data.meetingSummary.create_date) : '-'}</td>
          <td>{data?.StartDate ? tsToDate(data?.StartDate) : '-'}</td>
          <td>{data?.EndDate ? tsToDate(data.EndDate) : "-"}</td>
          <td>{(data?.StartDate && data?.EndDate) ? durationTime(data?.StartDate, data?.EndDate) : '-'}</td>
          <td>{data?.JoinedUsers || '-'}</td>
          <td>
            <button className={`btnBgLess ${data?.meetingRecording ? '' : 'notAllowed'}`} disabled={!data?.meetingRecording} onClick={() => setIsVideoShow({ meetingId: data?.meetingSummary?.meetingID, mSubID: data?.internalMeetingID })}>
              <FaVideo className={data?.meetingRecording ? "submitColor" : "text-muted"} />
            </button>
            <button className={`btnBgLess ${data?.mp4RecordingUrl ? '' : 'notAllowed'}`} disabled={!data?.mp4RecordingUrl} onClick={(e) => mp4Download(e, data?.mp4RecordingUrl, data?.meetingSummary?.meetingID)}>
              <FaDownload className={data?.mp4RecordingUrl ? "submitColor" : "text-muted"} />
            </button>

          </td>
          <td>
            <Dropdown>
              <Dropdown.Toggle className="headerBtn border-0 text-white submitSubColor" disabled>
                <span className='mr-2'>Export</span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="optionDropDown sideBar">
                <Dropdown.Item className="welcomeMsg" >
                  Usage Report
                </Dropdown.Item>
                {data?.meetingSummary?.only_registered && <Dropdown.Item className="welcomeMsg" onClick={() => dispatch(reportActions.getRegisterReport({ meetingId: data?.meetingSummary?.meetingID }))} >
                  {loadingReportReg === data?.meetingSummary?.meetingID ? "Loading.." : "Register Report"}
                </Dropdown.Item>}
                <Dropdown.Item className="welcomeMsg">
                  Poll Report
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </td>
        </tr>
      ))
    } else {
      return <tr> <td colSpan={10}> <div className="text-center">No Result</div> </td> </tr>
    }
  }

  const LeftSection = () => (
    <>
      <button className={`headerBtn pl-5 pr-5 border-0 mr-4 welcomeMsg  meetingBTn ${type === 'meeting' ? 'submitSubColor' : 'sideBar'}`} onClick={() => setType('meeting')} >Meeting</button>
      {/* <button className={`headerBtn pl-5 pr-5 border-0 welcomeMsg ${type === 'webinar' ? 'submitSubColor' : 'sideBar'}`} onClick={() => setType('webinar')}>Webinar</button> */}
    </>
  )

  return (
    <Layout active="Report" title={<LeftSection />}   >
      <div className='containerReport'>
        <div className='between  row reportsNote'>
          {
            (filterShow.filter && reportList?.TotalMeetings) ? <Col lg={6} md={6} sm={8}>
              <span className="text-warning d-block">
                {reportList?.TotalMeetings} Results Found
              </span>
            </Col>
              :
              <Col lg={6} md={6} sm={8}>
                <span className="text-warning d-block">Note:-</span>
                <span className="text_milky d-block f_size_13 mt-1 ml-4">1. Maximum report duration 1 month </span>
                <span className="text_milky d-block f_size_13 mt-1 ml-4">2. The report display information for meeting that ended at least 3 hours ago</span>
              </Col>
          }
          <Col lg={4} md={4} sm={4} className="text-center">
            {filterShow.filter && <button className="headerBtn pl-lg-3 pr-lg-3 mr-2 secondBgTheme text-white border-0 " onClick={() => {
              props.history.push('/reports')
              setInitialValues(defaultValues)
              changeFilter()
            }}>  Reset Filter </button>}
            <button className="headerBtn pl-lg-3 pr-lg-3 mr-2 secondBgTheme text-white border-0 " disabled={loadingReport} onClick={toggleFilterModal}> <FaFilter />  Filter </button>
            <button className="headerBtn pl-lg-4 pr-lg-4 submitBtnColor text-white border-0 " disabled={reportList?.length === 0} onClick={() => downloadCSV(reportList.meetingList)}> Download CSV </button>
          </Col>
        </div>
        <div className='mt-5'>
          <Table headers={<TableHeader />} className="borderRadius">
            <GetReport />
          </Table>
        </div>
        {
          filterShow.show && <FilterModel show={filterShow.show} toggle={toggleFilterModal} initialValues={initialValues} setInitialValues={(value) => handleSubmitFilter(value)} />
        }
        {
          !isEmpty(isVideoShow) && <VideoModal data={isVideoShow} toggle={() => setIsVideoShow(null)} />
        }
      </div>
    </Layout>
  )
}

export default Report