import React, { useEffect } from 'react'
import { Formik } from 'formik'
import { Row } from 'react-bootstrap'
import { FaTrash } from "react-icons/fa";
import * as Yup from "yup";
import isEmpty from 'is-empty';

import Layout from '../layoutSection'
import styles from "./style.module.scss"
import { InputFiled } from '../Common/authFile'
import { useDispatch, useSelector } from 'react-redux';
import { settingActions } from '../Redux/_actions/setting.actions';
import Spinner from "../components/UI/Spinner/Spinner";
import { MdCancel } from "react-icons/md";

const validation = Yup.object().shape({
  organisation_name: Yup.string().matches(/^[a-zA-Z ,:!.@\s]*$/, "Only alphabets are allowed for this field!"),
  url: Yup.string().matches(/^[a-zA-Z ,:!.@\s]*$/, "Only alphabets are allowed for this field!"),
  logo: Yup.mixed().test("fileSize", "The file is too large", (value) => {
    if (!value?.size) return true
    return value.size <= 1000000
  }),
  favicon: Yup.mixed().test("fileSize", "The file is too large", (value) => {
    if (!value?.size) return true
    return value.size <= 10000
  }),
})


const getBaseB4 = (event, setFieldValue) => {
  const result = event.target.files[0];
  const name = event.target.name;
  if (!isEmpty(result)) {
    setFieldValue(name, result)
    const reader = new FileReader();
    reader.readAsDataURL(result);
    reader.onloadend = () => {
      if (name === "logo") {
        setFieldValue("previewLogo", reader.result)
      } else {
        setFieldValue("previewFavicon", reader.result)
      }
    };
  }
}

const Setting = (props) => {
  const { jwt } = props
  const dispatch = useDispatch()
  const { loading, update } = useSelector(state => state.setting)
  const disable = !jwt?.plan_data || jwt?.plan_data?.plan_type !== "Business";

  const initialValues = {
    organisation_name: update?.organisation_name || '',
    url: update?.url || '',
    logo: update?.logo || '',
    favicon: update?.favicon || '',
    previewLogo: update?.logo || '',
    previewFavicon: update?.favicon || '',
  }

  useEffect(() => {
    if (!disable) {
      dispatch(settingActions.getBranding());
    }
  }, [])

  const submitHandler = (value) => {
    let formData = new FormData();
    if (!isEmpty(update) && update.logo === value.logo) {
      formData.append("logo", value.logo);
    } else if (!isEmpty(value.logo)) {
      formData.append("logo", value.logo, value.logo.name);
    }
    if (!isEmpty(update) && update.favicon === value.favicon) {
      formData.append("favicon", value.favicon);
    } else if (!isEmpty(value.favicon)) {
      formData.append("favicon", value.favicon, value.favicon.name);
    }
    formData.append("organisation_name", value.organisation_name);
    formData.append("url", value.url);
    dispatch(settingActions.updateBranding(formData));
  }

  const FormSection = () => {
    // if (loading && !update) {
    //   return (<div className="text-center">
    //     <Spinner />
    //   </div>)
    // }

    const deletedLogo = (setFieldValue, values, type) => {
      var subVariable;
      if (type === "logo") {
        subVariable = "previewLogo"
      } else {
        subVariable = "previewFavicon"
      }
      if (!update[type]) {
        setFieldValue(type, "");
        setFieldValue(subVariable, "");
      } else if (values[type] !== update[type]) {
        setFieldValue(type, update[type]);
        setFieldValue(subVariable, update[type]);
      } else {
        dispatch(settingActions.deleteBranding({ fieldName: type }))
      }
    }

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={(value) => submitHandler(value)}
        validationSchema={validation}
        enableReinitialize
      >
        {({ values, setFieldValue, handleSubmit, errors }) => (
          <>
            {
              (disable) &&
              <div className='text-danger text-center' > * Please upgrade your plan to customize your branding or talk to our customer care to assist you!  </div>
            }
            <Row className='d-flex justify-content-around'>
              <InputFiled
                name="organisation_name"
                label="Organization Name"
                placeholder="Enter the Organization Name"
                labelClassName="f_size_16 text-muted"
                containerClassName="w-75"
                className={` inputBrandName welcomeMsg bgColorBodyCard `}
                disabled={disable}
              />
              <button disabled={isEmpty(update?.organisation_name)} className={`headerBtn bgColorBodyCard  border-0 ${styles.deleteBtn} ${styles.deleteBtnWiHi}`} onClick={() => dispatch(settingActions.deleteBranding({ fieldName: "organisation_name" }))} >
                <FaTrash className='text-danger f_size_20 ' />
              </button>
            </Row>
            <Row className='d-flex justify-content-around'>
              <InputFiled
                name="url"
                label="URL"
                placeholder="Enter the URL"
                labelClassName="f_size_16 text-muted"
                containerClassName="w-75"
                className={` inputBrandName welcomeMsg bgColorBodyCard `}
                disabled={disable}
              />
              <button disabled={isEmpty(update?.url)} className={`headerBtn bgColorBodyCard  border-0 ${styles.deleteBtn} ${styles.deleteBtnWiHi}`} onClick={() => dispatch(settingActions.deleteBranding({ fieldName: "url" }))} >
                <FaTrash className='text-danger f_size_20 ' />
              </button>
            </Row>
            <div className={`d-flex justify-content-center align-items-center ${styles.uploadImagesSection}`}>
              <div className={`mr-5 ${styles.fontNewMargin} `}>
                <div className={styles.center}>
                  <label className='text-muted ml-3' >Logo</label>
                  <div className="text-danger f_size_11" >* logo  size must be 20kb - 1mb </div>
                </div>
                <div className='position-relative'>
                  {!isEmpty(values.previewLogo) && !errors?.logo && <button className={`btnBgLess ${styles.deleteBtnIcon}`} onClick={() => deletedLogo(setFieldValue, values, "logo")}  > <MdCancel className='welcomeMsg' /> </button>}
                  <input type="file" id="logo" name="logo" accept="image/png, image/jpg, image/jpeg" style={{ display: "none" }} onChange={(event) => getBaseB4(event, setFieldValue)} disabled={disable} />
                  <label htmlFor="logo" className={`bgColorBodyCard position-relative ${styles.uploadImg}  ${errors?.logo ? styles.error : ''}`}>
                    {!isEmpty(values.previewLogo) && !errors?.logo ?
                      <img src={values.previewLogo} alt="favicon" className={styles.imgFavicon} />
                      : <p className='text-muted mt-5 '>
                        Upload a logo for your Brand
                      </p>}
                    <div className={styles.uploadBtn}> {!isEmpty(values.previewLogo) && !errors?.logo ? "Change Logo" : "Upload Logo"} </div>
                  </label>
                  {
                    errors?.logo && <div className="text-danger f_size_11" >{errors.logo} </div>
                  }
                </div>
              </div>
              <div className={`mr-5 ${styles.fontNewMargin} `}>
                <div className={styles.center}>
                  <label className='text-muted ml-3' >Favicon</label>
                  <div className="text-danger f_size_11" >* favicon size  must be 1kb - 10kb </div>
                </div>
                <div className='position-relative'>
                  {!isEmpty(values.previewFavicon) && !errors?.favicon && <button className={`btnBgLess ${styles.deleteBtnIcon}`} onClick={() => deletedLogo(setFieldValue, values, "favicon")}> <MdCancel className='welcomeMsg' /> </button>}
                  <input type="file" id="faviconIcon" name="favicon" accept="image/ico" style={{ display: "none" }} onChange={(event) => getBaseB4(event, setFieldValue)} disabled={disable} />
                  <label htmlFor="faviconIcon" className={`bgColorBodyCard position-relative ${styles.uploadImg} ${errors?.favicon ? styles.error : ''}`}>
                    {!isEmpty(values.previewFavicon) && !errors?.favicon ?
                      <img src={values.previewFavicon} alt="favicon" className={styles.imgFavicon} />
                      : <p className='text-muted mt-5 '>
                        Upload a favicon for your Brand
                      </p>}
                    <div className={styles.uploadBtn}> {!isEmpty(values.previewFavicon) && !errors?.favicon ? "Change Favicon" : "Upload Favicon"} </div>
                  </label>
                  {
                    errors?.favicon && <div className="text-danger f_size_11" >{errors.favicon} </div>
                  }
                </div>
              </div>
            </div>
            <div className='text-center mt-3'>
              <button type='submit' disabled={loading || disable} className='headerBtn text-white submitBtnColor pl-5 pr-5 border-0' onClick={() => handleSubmit()} > {loading ? "...Updating" : "Update Settings"} </button>
            </div>
          </>
        )}
      </Formik>
    )

  }



  return (
    <Layout active="Setting" title="Change Branding" goBack>
      <div className={`sideBar w-100 ${styles.settingContainer}`}>
        <FormSection />
      </div>
    </Layout >
  )
}

export default Setting