import React, { useRef } from "react";
import { Input, FormGroup, Label, Row, Col } from "reactstrap";
import { useField, Field } from "formik";
import { range } from "lodash";

import "./styles.scss";

export const InputFiled = ({
  className,
  label,
  labelClassName,
  containerClassName,
  ...props
}) => {
  const [field, meta] = useField(props);
  return (
    <FormGroup
      className={`  mt-1 text-left formGroup  ${containerClassName || ""}`}
    >
      {label && (
        <Label className={`f_size_14 ml-3  ${labelClassName || "welcomeMsg"}`}>
          {label}
        </Label>
      )}
      <Input {...field} {...props} className={className || 'bg-light'} />
      {meta.touched && meta.error && (
        <div className="error text-danger f_size_13">{meta.error}</div>
      )}
    </FormGroup>
  );
};

export const CheckBox = ({ labelClassName, label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <FormGroup className="d-flex align-items-center">
      <Field type="checkbox" {...props} />
      <Label className={`title-label ml-2 ${labelClassName || ""}`}>
        {label}
      </Label>
    </FormGroup>
  );
};
