import { eventConstants } from '../_constants';
import { eventServices } from '../_services';
import { alertActions } from './';

export const eventActions = {
    eventList,
    addEventOnGoogle,
    limitEventList,
    meetingHistoryList,
    gAuthURL,
    gAuthToken,
    removeGoogleCalendarAuth,
    getRecordings
};

function gAuthURL() {
    return dispatch => {
        dispatch(start("start"));
        eventServices.gAuthURL()
            .then(
                events => {
                    dispatch(success(events));
                },
                error => {
                    // dispatch(alertActions.error(error.toString()));
                    dispatch(failure(error.toString()));
                }
            );
    };

    function start(start) { return { type: eventConstants.GAUTH_URL_START, start } }
    function success(events) { return { type: eventConstants.GAUTH_URL_SUCCESS, events } }
    function failure(error) { return { type: eventConstants.GAUTH_URL_FAILURE, error } }
}

function getRecordings(meetId) {
    return dispatch => {
        dispatch(start("start"));
        eventServices.getRecordingService(meetId)
            .then(
                events => {
                    dispatch(success(events));
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                    dispatch(failure(error.toString()));
                }
            );
    };

    function start(start) { return { type: eventConstants.GET_RECORDING_START, start } }
    function success(events) { return { type: eventConstants.GET_RECORDING_SUCCESS, events } }
    function failure(error) { return { type: eventConstants.GET_RECORDING_SUCCESS, error } }
}

function gAuthToken(code) {
    return dispatch => {
        dispatch(start("start"));
        eventServices.gAuthToken(code)
            .then(
                events => {
                    dispatch(success(events));
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                    dispatch(failure(error.toString()));
                }
            );
    };

    function start(start) { return { type: eventConstants.GAUTH_TOKEN_START, start } }
    function success(events) { return { type: eventConstants.GAUTH_TOKEN_SUCCESS, events } }
    function failure(error) { return { type: eventConstants.GAUTH_TOKEN_FAILURE, error } }
}

function removeGoogleCalendarAuth() {
    return dispatch => {
        dispatch(start({}));

        eventServices.removeGoogleCalendarAuth()
            .then(
                events => {
                    dispatch(alertActions.success(events.toString()));
                    dispatch(success(events));
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                    dispatch(failure(error.toString()));
                }
            );
    };

    function start(start) { return { type: eventConstants.REMOVE_GOOGLE_CALENDAR_START, start } }
    function success(events) { return { type: eventConstants.REMOVE_GOOGLE_CALENDAR_SUCCESS, events } }
    function failure(error) { return { type: eventConstants.REMOVE_GOOGLE_CALENDAR_FAILURE, error } }
}

function eventList() {
    return dispatch => {
        dispatch(start("start"));
        eventServices.eventList()
            .then(
                events => {
                    dispatch(success(events));
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                    dispatch(failure(error.toString()));
                }
            );
    };

    function start(start) { return { type: eventConstants.EVENT_START, start } }
    function success(events) { return { type: eventConstants.EVENT_SUCCESS, events, } }
    function failure(error) { return { type: eventConstants.EVENT_FAILURE, error } }
}


function limitEventList(limitCount) {
    return dispatch => {
        dispatch(start("start"));
        eventServices.limitEventList(limitCount)
            .then(
                events => {
                    dispatch(success(events));
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                    dispatch(failure(error.toString()));
                }
            );
    };

    function start(start) { return { type: eventConstants.EVENT_LIMIT_START, start } }
    function success(events) { return { type: eventConstants.EVENT_LIMIT_SUCCESS, events } }
    function failure(error) { return { type: eventConstants.EVENT_LIMIT_FAILURE, error } }
}


function addEventOnGoogle(eventId) {
    return dispatch => {
        dispatch(start(eventId));
        eventServices.addEventOnGoogle(eventId)
            .then(
                redirectURL => {
                    dispatch(alertActions.success("Schedule Successfully Sync on Google"));
                    dispatch(success(redirectURL, eventId));
                    // dispatch(eventActions.meetingHistoryList())
                    //const w = window.innerWidth;
                    //const h = window.innerHeight;
                    // let redir = window.open(redirectURL, "redir", "width="+w+",height="+h+",top=0,left=0,resizable=1,menubar=yes");
                    // redir.focus();
                    // window.location.href = redirectURL;
                    // setInterval(function () {
                    //   // if (redir.open) {
                    //         window.location.reload();
                    //   // }
                    // }, 3000);
                },
                error => {
                    dispatch(alertActions.error("Sorry, We are facing issue in fetching data from google, Please try again."));
                    dispatch(failure(error.toString()));
                }
            );
    };

    function start(start) { return { type: eventConstants.EVENT_START, start } }
    function success(events) { return { type: eventConstants.EVENT_SUCCESS, events, eventId } }
    function failure(error) { return { type: eventConstants.EVENT_FAILURE, error } }
}

function meetingHistoryList(pageVal) {
    return dispatch => {
        dispatch(start("start"));
        eventServices.meetingHistoryList(pageVal)
            .then(
                events => {
                    dispatch(success(events, pageVal));
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                    dispatch(failure(error.toString()));
                }
            );
    };

    function start(start) { return { type: eventConstants.MEETING_HISTORY_START, start } }
    function success(events, pageVal) { return { type: eventConstants.MEETING_HISTORY_SUCCESS, events, pageVal } }
    function failure(error) { return { type: eventConstants.MEETING_HISTORY_FAILURE, error } }
}