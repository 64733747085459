import React from 'react'

const Search = () => {
    return (  
        <div className="searchPanel">
            <input type="text" placeholder="Search goes here" className="search-formControl" />
            <button className="btnSearch"><img src="../images/icons/icon-search.svg" alt=""/></button>
        </div> 
               
    )
}

export default Search;