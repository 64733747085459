import { alertActions } from './';
import { settingService } from './../_services';
import { settingConstants } from './../_constants';
import userconfigs from '../../helpers/closures';


export const settingActions = {
    updateBranding,
    getBranding,
    deleteBranding
};

function getBranding() {
    return dispatch => {
        dispatch(request({}));
        settingService.GetBranding()
            .then(
                resp => {
                    dispatch(success(resp))
                },
                (error) => {
                    dispatch(alertActions.error(error.toString()))
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(resp) { return { type: settingConstants.BRANDING_GET_REQ } }
    function success(resp) { return { type: settingConstants.BRANDING_GET_SUCCESS, resp } }
    function failure(error) { return { type: settingConstants.BRANDING_GET_FAILURE, error } }
}




function updateBranding(params) {
    return dispatch => {
        dispatch(request({}));

        settingService.UpdateBranding(params)
            .then(
                resp => {
                    dispatch(alertActions.success("Branding update successfully"))
                    dispatch(settingActions.getBranding())
                    dispatch(success(resp))
                    if (resp.token) {
                        userconfigs.setItem('jwt', resp.token);
                        userconfigs.setItem('auth', resp.auth);
                    }
                    window.dispatchEvent(new Event('storage'))
                },
                (error) => {
                    dispatch(alertActions.error(error.toString()))
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(resp) { return { type: settingConstants.BRANDING_REQ } }
    function success(resp) { return { type: settingConstants.BRANDING_SUCCESS, resp } }
    function failure(error) { return { type: settingConstants.BRANDING_FAILURE, error } }
}

function deleteBranding(params) {
    return dispatch => {
        dispatch(request({}));

        settingService.DeleteBranding(params)
            .then(
                resp => {
                    dispatch(alertActions.success("field deleted successfully"))
                    dispatch(settingActions.getBranding())
                    dispatch(success(resp))
                    if (resp.token) {
                        userconfigs.setItem('jwt', resp.token);
                        userconfigs.setItem('auth', resp.auth);
                    }
                    window.dispatchEvent(new Event('storage'))
                },
                (error) => {
                    dispatch(alertActions.error(error.toString()))
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(resp) { return { type: settingConstants.BRANDING_REQ } }
    function success(resp) { return { type: settingConstants.BRANDING_SUCCESS, resp } }
    function failure(error) { return { type: settingConstants.BRANDING_FAILURE, error } }
}

