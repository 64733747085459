export const joinConstants = {
    JOIN_REQUEST: 'JOIN_REQUEST',
    JOIN_SUCCESS: 'JOIN_SUCCESS',
    JOIN_FAILURE: 'JOIN_FAILURE',


    HOST_JOIN_REQUEST: 'HOST_JOIN_REQUEST',
    HOST_JOIN_SUCCESS: 'HOST_JOIN_SUCCESS',
    HOST_JOIN_FAILURE: 'HOST_JOIN_FAILURE',

    ATTENDEE_JOIN_REQUEST: 'ATTENDEE_JOIN_REQUEST',
    ATTENDEE_JOIN_SUCCESS: 'ATTENDEE_JOIN_SUCCESS',
    ATTENDEE_JOIN_FAILURE: 'ATTENDEE_JOIN_FAILURE',
};
