export const teamConstants = {
    TEAM_GETALL_REQUEST: 'TEAM_GETALL_REQUEST',
    TEAM_GETALL_SUCCESS: 'TEAM_GETALL_SUCCESS',
    TEAM_GETALL_FAILURE: 'TEAM_GETALL_FAILURE',

    TEAM_ADD_REQUEST: 'TEAM_ADD_REQUEST',
    TEAM_ADD_SUCCESS: 'TEAM_ADD_SUCCESS',
    TEAM_ADD_FAILURE: 'TEAM_ADD_FAILURE',

    TEAM_EDIT_REQUEST: 'TEAM_EDIT_REQUEST',
    TEAM_EDIT_SUCCESS: 'TEAM_EDIT_SUCCESS',
    TEAM_EDIT_FAILURE: 'TEAM_EDIT_FAILURE',

    TEAM_BYID_REQUEST: 'TEAM_BYID_REQUEST',
    TEAM_BYID_SUCCESS: 'TEAM_BYID_SUCCESS',
    TEAM_BYID_FAILURE: 'TEAM_BYID_FAILURE',
};


