import { myconfig, siteTokens } from "../../config/config";
import axios from "axios";
import userconfigs from "../../helpers/closures";
import { generateToken } from "../../helpers/helpers";

import { handleResponse, errorCheck, handleJWTError } from './common'

export const webinarService = {
  schedule,
  webinarList,
  webinarDetail,
  webinarFields,
  update,
  register,
  addMoreParticipant,
  removeWebinarParticipant,
  addMoreHost,
  webinarInfo,
  addBranding,
  webinarBrand,
  cancelWebinar,
  deleteWebinar,
  removeWebinarBrand,
  deletewebinarBrand,
  webinarBits,
  joinWebinar,
  joinAttendeeWebinar,
  addToWebinarRoom,
  removeWebinarSpeaker,
  editSpeakerDetail,
  editSpeakerUpdate,
  getAttendees,
  reInviteUser
};

function schedule(fields) {
  let axiosRequestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: userconfigs.getItem("jwt"),
    },
  };

  //let loginPost = fields

  return axios
    .post(`${myconfig.apiURL}webinar/create`, fields, axiosRequestOptions)
    .then(handleResponse)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
        handleJWTError();
        // userconfigs.removeItem("jwt");
        // generateToken();
        // window.location.href = "/login";
      } else {
        return Promise.reject(errorCheck(error));
      }
      //return error.response.data.error;
    });
}

function webinarList(limit) {
  let axiosRequestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: userconfigs.getItem("jwt"),
    },
  };

  let postData = {};
  if (limit) {
    postData = limit;
  }
  return axios
    .post(`${myconfig.apiURL}webinar/list`, postData, axiosRequestOptions)
    .then(webinarHandleResponse)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
        handleJWTError();
        // userconfigs.removeItem("jwt");
        // generateToken();
        // window.location.href = "/login";
      } else {
        return Promise.reject(errorCheck(error));
      }
    });
}

function webinarDetail(webinarID) {
  let axiosRequestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: userconfigs.getItem("jwt"),
    },
  };

  return axios
    .get(`${myconfig.apiURL}webinar/detail/${webinarID}`, axiosRequestOptions)
    .then(handleResponse)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
        handleJWTError();
        // userconfigs.removeItem("jwt");
        // generateToken();
        // window.location.href = "/login";
      } else {
        return Promise.reject(errorCheck(error));
      }
    });
}

function getAttendees(webinarID) {
  let axiosRequestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: userconfigs.getItem("jwt"),
    },
  };

  return axios
    .get(
      `${myconfig.apiURL}webinar/download/attendee/csv/${webinarID}`,
      axiosRequestOptions
    )
    .then(handleResponse)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
        handleJWTError();
        // userconfigs.removeItem("jwt");
        // generateToken();
        // window.location.href = "/login";
      } else {
        return Promise.reject(errorCheck(error));
      }
    });
}

function webinarFields(webinarID) {
  let axiosRequestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: userconfigs.getItem("jwt"),
    },
  };

  return axios
    .get(
      `${myconfig.apiURL}webinar/register_form/get_webinar_registration_fields/${webinarID}`,
      axiosRequestOptions
    )
    .then(handleResponse)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
        handleJWTError();
        // userconfigs.removeItem("jwt");
        // generateToken();
        // window.location.href = "/login";
      } else {
        return Promise.reject(errorCheck(error));
      }
    });
}

function register(fields) {
  let axiosRequestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: userconfigs.getItem("jwt"),
    },
  };

  return axios
    .post(
      `${myconfig.apiURL}webinar/webinarRegister`,
      fields,
      axiosRequestOptions
    )
    .then(handleResponse)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
        handleJWTError();
        // userconfigs.removeItem("jwt");
        // generateToken();
        // window.location.href = "/login";
      } else {
        return Promise.reject(errorCheck(error));
      }
      //return error.response.data.error;
    });
}

function addMoreParticipant(formData) {
  let axiosRequestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: userconfigs.getItem("jwt"),
    },
  };

  return axios
    .post(`${myconfig.apiURL}webinar/add_invite`, formData, axiosRequestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
        handleJWTError();
        // userconfigs.removeItem("jwt");
        // generateToken();
        // window.location.href = "/login";
      } else {
        return Promise.reject(errorCheck(error));
      }
    });
}

function removeWebinarParticipant(id) {
  let axiosRequestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: userconfigs.getItem("jwt"),
    },
  };

  let postData = {
    id: id,
  };

  return axios
    .post(
      `${myconfig.apiURL}webinar/remove_participants`,
      postData,
      axiosRequestOptions
    )
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
        handleJWTError();
        // userconfigs.removeItem("jwt");
        // generateToken();
        // window.location.href = "/login";
      } else {
        return Promise.reject(errorCheck(error));
      }
    });
}

function addMoreHost(formData, multipart) {
  let axiosRequestOptions = {
    headers: {
      "Content-Type": multipart ? "multipart/form-data" : "application/json",
      Authorization: userconfigs.getItem("jwt"),
    },
  };

  return axios
    .post(`${myconfig.apiURL}webinar/add_host`, formData, axiosRequestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
        handleJWTError();
        // userconfigs.removeItem("jwt");
        // generateToken();
        // window.location.href = "/login";
      } else {
        return Promise.reject(errorCheck(error));
      }
    });
}

function removeWebinarSpeaker(speakerID) {
  let axiosRequestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: userconfigs.getItem("jwt"),
    },
  };

  let postData = {
    speakerID: speakerID,
  };

  return axios
    .post(
      `${myconfig.apiURL}webinar/remove_speaker`,
      postData,
      axiosRequestOptions
    )
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
        handleJWTError();
        // userconfigs.removeItem("jwt");
        // generateToken();
        // window.location.href = "/login";
      } else {
        return Promise.reject(errorCheck(error));
      }
    });
}

function editSpeakerUpdate(formData, multipart) {
  let axiosRequestOptions = {
    headers: {
      "Content-Type": multipart ? "multipart/form-data" : "application/json",
      Authorization: userconfigs.getItem("jwt"),
    },
  };

  return axios
    .post(
      `${myconfig.apiURL}webinar/speaker_update`,
      formData,
      axiosRequestOptions
    )
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
        handleJWTError();
        // userconfigs.removeItem("jwt");
        // generateToken();
        // window.location.href = "/login";
      } else {
        return Promise.reject(errorCheck(error));
      }
    });
}

function editSpeakerDetail(speakerID) {
  let axiosRequestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: userconfigs.getItem("jwt"),
    },
  };

  let postData = {
    speakerID: speakerID,
  };

  return axios
    .post(
      `${myconfig.apiURL}webinar/speaker_detail`,
      postData,
      axiosRequestOptions
    )
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
        handleJWTError();
        // userconfigs.removeItem("jwt");
        // generateToken();
        // window.location.href = "/login";
      } else {
        return Promise.reject(errorCheck(error));
      }
    });
}

function webinarInfo(webinarID) {
  let axiosRequestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: userconfigs.getItem("jwt"),
    },
  };

  return axios
    .get(`${myconfig.apiURL}webinar/info/${webinarID}`, axiosRequestOptions)
    .then(handleResponse)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
        handleJWTError();
        // userconfigs.removeItem("jwt");
        // generateToken();
        // window.location.href = "/login";
      } else {
        return Promise.reject(errorCheck(error));
      }
    });
}

function addBranding(type, fields) {
  let axiosRequestOptions = {
    headers: {
      "Content-Type":
        type === "image" ? "multipart/form-data" : "application/json",
      Authorization: userconfigs.getItem("jwt"),
    },
  };
  return axios
    .post(
      `${myconfig.apiURL}webinar/add_webinar_brand`,
      fields,
      axiosRequestOptions
    )
    .then(handleResponse)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
        handleJWTError();
        // userconfigs.removeItem("jwt");
        // generateToken();
        // window.location.href = "/login";
      } else {
        return Promise.reject(errorCheck(error));
      }
      //return error.response.data.error;
    });
}

function webinarBrand(webinarID) {
  let axiosRequestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: userconfigs.getItem("jwt"),
    },
  };
  if (webinarID === undefined) {
    return;
  }

  return axios
    .get(
      `${myconfig.apiURL}webinar/webinar_brand/${webinarID}`,
      axiosRequestOptions
    )
    .then(handleResponse)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
        handleJWTError();
        // userconfigs.removeItem("jwt");
        // generateToken();
        // window.location.href = "/login";
      } else {
        return Promise.reject(errorCheck(error));
      }
    });
}

function createWebinar(webinarObj) {
  let axiosRequestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: userconfigs.getItem("jwt"),
    },
  };

  let postData = {
    webinarObj,
  };

  return axios
    .post(
      `${myconfig.apiURL}webinar/remove_speaker`,
      postData,
      axiosRequestOptions
    )
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
        handleJWTError();
        // userconfigs.removeItem("jwt");
        // generateToken();
        // window.location.href = "/login";
      } else {
        return Promise.reject(errorCheck(error));
      }
    });
}

function deleteWebinar(webinarID) {
  let axiosRequestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: userconfigs.getItem("jwt"),
    },
  };

  let postData = {
    wid: webinarID,
  };
  return axios
    .post(
      `${myconfig.apiURL}webinar/deleteWebinar`,
      postData,
      axiosRequestOptions
    )
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
        handleJWTError();
        // userconfigs.removeItem("jwt");
        // generateToken();
        // window.location.href = "/login";
      } else {
        return Promise.reject(errorCheck(error));
      }
    });
}

function cancelWebinar(webinarID) {
  let axiosRequestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: userconfigs.getItem("jwt"),
    },
  };

  let postData = {
    wid: webinarID,
  };
  return axios
    .post(
      `${myconfig.apiURL}webinar/cancelWebinar`,
      postData,
      axiosRequestOptions
    )
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
        handleJWTError();
        // userconfigs.removeItem("jwt");
        // generateToken();
        // window.location.href = "/login";
      } else {
        return Promise.reject(errorCheck(error));
      }
    });
}

function removeWebinarBrand(webinarID, keyField) {
  let axiosRequestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: userconfigs.getItem("jwt"),
    },
  };

  let postData = {
    webinarID: webinarID,
    fieldKey: keyField,
  };
  return axios
    .post(
      `${myconfig.apiURL}webinar/remove_webinar_brand`,
      postData,
      axiosRequestOptions
    )
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
        handleJWTError();
        // userconfigs.removeItem("jwt");
        // generateToken();
        // window.location.href = "/login";
      } else {
        return Promise.reject(errorCheck(error));
      }
    });
}

function deletewebinarBrand(webinarID, keyField) {
  let axiosRequestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: userconfigs.getItem("jwt"),
    },
  };

  return axios
    .get(
      `${myconfig.apiURL}webinar/delete_webinar_brand/${webinarID}`,
      axiosRequestOptions
    )
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
        handleJWTError();
        // userconfigs.removeItem("jwt");
        // generateToken();
        // window.location.href = "/login";
      } else {
        return Promise.reject(errorCheck(error));
      }
    });
}

function webinarBits(wid, user_type, token) {
  let axiosRequestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: userconfigs.getItem("jwt"),
    },
  };

  let postData = {
    wid: wid,
    user_type: user_type,
  };
  if (token !== "undefiend") {
    postData.token = token;
  }
  return axios
    .post(
      `${myconfig.apiURL}webinar/webinarBits`,
      postData,
      axiosRequestOptions
    )
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
        handleJWTError();
        // userconfigs.removeItem("jwt");
        // generateToken();
        // window.location.href = "/login";
      } else {
        return Promise.reject(errorCheck(error));
      }
    });
}

function joinAttendeeWebinar(wid, name, user_type, token) {
  let axiosRequestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: userconfigs.getItem("jwt"),
    },
  };

  let joinPost = {
    wid: wid,
    name: name,
    userType: user_type,
    token: token,
  };
  // if(password !== "undefiend"){
  //     joinPost.pwd = password
  // }

  return axios
    .post(
      `${myconfig.apiURL}webinar/joinWebinar`,
      joinPost,
      axiosRequestOptions
    )
    .then(handleResponse)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
        handleJWTError();
        // userconfigs.removeItem("jwt");
        // generateToken();
        // window.location.href = "/login";
      } else {
        return Promise.reject(errorCheck(error));
      }
      //return error.response.data.error;
    });
}

function joinWebinar(wid, name, user_type, token) {
  let axiosRequestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: userconfigs.getItem("jwt"),
    },
  };

  let joinPost = {
    wid: wid,
    name: name,
    userType: user_type,
    token: token,
  };
  // if(password !== "undefiend"){
  //     joinPost.pwd = password
  // }

  return axios
    .post(
      `${myconfig.apiURL}webinar/joinWebinar`,
      joinPost,
      axiosRequestOptions
    )
    .then(handleResponse)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
        handleJWTError();
        // userconfigs.removeItem("jwt");
        // generateToken();
        // window.location.href = "/login";
      } else {
        return Promise.reject(errorCheck(error));
      }
      //return error.response.data.error;
    });
}

function addToWebinarRoom(wid, token, user_type, name, password) {
  let axiosRequestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: userconfigs.getItem("jwt"),
    },
  };

  let postData = {
    wid: wid,
    user_type: user_type,
    fingerprint: userconfigs.getItem("fp"),
  };
  if (token !== "undefiend") {
    postData.token = token;
  }
  if (name !== "undefiend") {
    postData.name = name;
  }
  if (password !== "undefiend") {
    postData.pwd = password;
  }
  return axios
    .post(
      `${myconfig.apiURL}webinar/addToWebinarRoom`,
      postData,
      axiosRequestOptions
    )
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
        handleJWTError();
        // userconfigs.removeItem("jwt");
        // generateToken();
        // window.location.href = "/login";
      } else {
        return Promise.reject(errorCheck(error));
      }
    });
}



function webinarHandleResponse(response) {
  if (response.status !== 200) {
    const error = (response && response.message) || response.statusText;
    return Promise.reject(error);
  }
  return response.data;
}


function update(fields) {
  let axiosRequestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: userconfigs.getItem("jwt"),
    },
  };

  return axios
    .post(
      `${myconfig.apiURL}webinar/webinar_update`,
      fields,
      axiosRequestOptions
    )
    .then(handleResponse)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
        handleJWTError();
        // userconfigs.removeItem("jwt");
        // generateToken();
        // window.location.href = "/login";
      } else {
        return Promise.reject(errorCheck(error));
      }
      //return error.response.data.error;
    });
}

function reInviteUser(meetingId, value) {

  let axiosRequestOptions = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': userconfigs.getItem('jwt'),
    }
  };

  let postData = {
    'meetingId': meetingId,
    'inviteeVal': value
  }
  return axios.post(`${myconfig.apiURL}webinar/reinvite`, postData, axiosRequestOptions)
    .then(handleResponse)
    .then(response => {
      return response;
    })
    .catch((error) => {
      if (error?.response?.status && (error.response.status === 408 || error.response.status === 753 || error.response.status === 520 || error.response.status === 925)) {
        handleJWTError();
        // userconfigs.removeItem("jwt");
        // generateToken();
        // window.location.href = "/login";
      } else {
        return Promise.reject(errorCheck(error));
      }
    });
}